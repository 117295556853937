import React from "react";
import SmallLoader from "../../shared/small-loader/small-loader";

function InvlidShootingDayNotification() {
  return (
    <div className="blade-container">
      <div className="ml-2">
        <SmallLoader />      
      </div>      
    </div>
  );
}

export default InvlidShootingDayNotification;
