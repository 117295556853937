import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../shared/loader";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import { fetchActors } from "./../actors/ducks/actions";
import {
  addCharacter,
  clearRedirect,
  initialiseCharacter,
  uploadImagesAndSaveCharacter,
  setName,
  getGender,
  fetchCharacterRate
} from "./ducks/actions";
import Form from "./form";

function AddCharacter(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const {
    addCharacter,
    uploadImagesAndSaveCharacter,
    fetchActors,
    initialiseCharacter,
    setName,
    getGender,
    character,
    errors,
    name,
    embedded, 
    sceneId,
    loadingRate,
    rate,
    fetchCharacterRate
  } = props;

  useEffect(() => {
    fetchActors(filmId);
    fetchCharacterRate(filmId);
  }, [filmId, fetchActors]);

  useEffect(() => {
    initialiseCharacter();
    if (name) {
      setName(name);
      getGender(name);
    }
  }, [name, initialiseCharacter, setName]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/characters/`;
      return <Redirect to={url} />;
    }
  }

  function onAddCharacter(character) {
    character.minimumAge = character.minimumAge ? +character.minimumAge : null;
    character.maximumAge = character.maximumAge ? +character.maximumAge : null;
    character.imageUrls = (character.imageUrls || []).filter((url) => url);
    character.sceneId = sceneId;
    if (character.ethnicities?.length) {
      character.ethnicityIds = character.ethnicities.map((m) => m.value);
    }

    if (character.skinColors?.length) {
      character.skinColorIds = character.skinColors.map((m) => m.value);
    }

    const addMode = !character.id;

    const files = character.pictures || [];

    if (files && files.length > 0) {
      uploadImagesAndSaveCharacter(character, files, addMode, filmId);
    } else {
      if (!character.imageUrls) {
        character.imageUrls = [];
      }
      addCharacter(filmId, character);
    }
  }

  if (props.loadingCharacter) {
    return <Loader />;
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/characters/add"
      loading={false}>
      <Form
        errors={errors}
        mode="add"
        characterId={null}
        initialValues={character || {}}
        loading={false}
        onSubmit={onAddCharacter}
        actors={props.actors}
        ethnicityTypes={props.ethnicityTypes}
        skinColors={props.skinColors}
        loadingRate={loadingRate}
        rate={rate}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { charactersState, actorsState, commonState } = state;
  const charactersRootState = charactersState.rootState;
  return {
    genderEnum: commonState.genderEnum,
    ethnicityTypes: commonState.ethnicityTypes,
    skinColors: commonState.skinColors,
    actors: actorsState.rootState.actors,
    errors: charactersRootState.errors,
    redirect: charactersRootState.redirect,
    loadingCharacter: charactersRootState.loadingCharacter,
    character: charactersRootState.character,
    rate: charactersRootState.rate,
    loadingRate: charactersRootState.loadingRate
  };
}

export default connect(mapStateToProps, {
  addCharacter,
  initialiseCharacter,
  setName,
  getGender,
  uploadImagesAndSaveCharacter,
  fetchActors,
  clearRedirect,
  fetchCharacterRate
})(AddCharacter);
