import { useEffect } from "react";
import { fetchBackgroundCharacterCostumes } from "./ducks/actions";
import { connect } from "react-redux";
import CharacterCostumes from "./character-costumes";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

const CharacterCostumesContainer = (props) => {
    const { loading, costumes, fetchBackgroundCharacterCostumes } = props;
    const backgroundCharacterId = props.characterId || props.match.params.backgroundCharacterId;
    const filmId = props.filmId || props.match.params.filmId;
    useEffect(() => {
        fetchBackgroundCharacterCostumes(filmId, backgroundCharacterId);
    }, [backgroundCharacterId]);

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/background-characters/:backgroundCharacterId/costumes"
            loading={loading}>
            <CharacterCostumes characterCostumes={costumes} filmId={filmId} loading={loading} />
        </ScrollIntoView>
    )
}

function mapStateToCostumes(state) {
    const { backgroundCharactersState } = state;
    const characterCostumesState = backgroundCharactersState.costumesState;
    return {
        errors: characterCostumesState.errors,
        costumes: characterCostumesState.costumes,
        loading: characterCostumesState.loading
    };
}

export default connect(mapStateToCostumes, {
    fetchBackgroundCharacterCostumes
})(CharacterCostumesContainer);