import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../../shared/modal/confirm";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../shared/loader";
import {
  clearRedirect,
  deleteSetDressing,
  fetchSetDressing,
  updateSetDressing,
  uploadImagesAndSaveSetDressing,
  fetchAllSceneSettings,
  changeSetDressingToProp
} from "./ducks/actions";
import Form from "./form";

function EditSetDressing(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const setDressingId = props.setDressingId || props.match.params.setDressingId;
  const {
    fetchSetDressing,
    updateSetDressing,
    changeSetDressingToProp,
    uploadImagesAndSaveSetDressing,
    deleteSetDressing,
    loadingSetDressing,
    setDressing,
    errors,
    fetchAllSceneSettings,
    allSceneSettings,
    embedded
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {
    fetchAllSceneSettings(filmId);
  }, []);

  useEffect(() => {
    if (invalidIds.indexOf(setDressingId) === -1) {
      fetchSetDressing(filmId, setDressingId);
    }
  }, [filmId, setDressingId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/set-dressings/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "prop") {
      props.clearRedirect();
      const url = `/films/${filmId}/props/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(setDressingId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderSetDressing(setDressing) {
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/set-dressings/:setDressingId/edit"
        loading={loadingSetDressing}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          setDressingId={setDressing.id}
          initialValues={setDressing}
          loading={loadingSetDressing}
          onSubmit={onUpdateSetDressing}
          handleDelete={onDeleteSetDressing}
          onChangeSetDressingToProp={onChangeSetDressingToProp}
          reduxFormValues={props.reduxFormValues}
          acquisitionMethodEnum={props.acquisitionMethodEnum}
          allSceneSettings={allSceneSettings}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateSetDressing(setDressing) {
    const files = setDressing.pictures || [];

    if ((files && files.length) > 0 || (setDressing.images && setDressing.images.length > 0)) {
      uploadImagesAndSaveSetDressing(setDressing, files, false, filmId);
    } else {
      updateSetDressing(filmId, setDressing);
    }
  }

  function onDeleteSetDressing(setDressing) {
    confirm(() => deleteSetDressing(filmId, setDressing), setDressing?.name);
  }

  const onChangeSetDressingToProp = (setDressing) => {
    confirm(() => changeSetDressingToProp(filmId, setDressing), null, `Are you sure you want to change ${setDressing.name} into a Prop?`, "Change Set Dressing");
  };

  return setDressing && !loadingSetDressing ? renderSetDressing(setDressing) : renderLoading();
}

function mapStateToSetDressings(state) {
  const { setDressingsState, commonState } = state;
  return {
    setDressing: setDressingsState.setDressing,
    errors: setDressingsState.errors,
    redirect: setDressingsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod,
    allSceneSettings: setDressingsState.sceneSettings
  };
}

export default
  connect(mapStateToSetDressings, {
    fetchSetDressing,
    updateSetDressing,
    deleteSetDressing,
    clearRedirect,
    uploadImagesAndSaveSetDressing,
    fetchAllSceneSettings,
    changeSetDressingToProp
  })(EditSetDressing);
