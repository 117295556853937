export default (props) =>
  <svg id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected" xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 21.516 19.97"  {...props}>
    <g id="Group_35" data-name="Group 35">
      <g id="Rectangle_12" data-name="Rectangle 12" fill="none" strokeWidth="1">
        <rect width="10" height="6" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="9" height="5" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_13" data-name="Rectangle 13" transform="translate(11.516)" fill="none" strokeWidth="1">
        <rect width="10" height="6" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="9" height="5" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_14" data-name="Rectangle 14" transform="translate(0 6.985)" fill="none" strokeWidth="1">
        <rect width="10" height="6" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="9" height="5" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_15" data-name="Rectangle 15" transform="translate(11.516 6.985)" fill="none" strokeWidth="1">
        <rect width="10" height="6" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="9" height="5" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_16" data-name="Rectangle 16" transform="translate(0 13.97)" fill="none" strokeWidth="1">
        <rect width="10" height="6" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="9" height="5" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_17" data-name="Rectangle 17" transform="translate(11.516 13.97)" fill="none" strokeWidth="1">
        <rect width="10" height="6" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="9" height="5" rx="0.5" fill="none" />
      </g>
    </g>
  </svg>
