import React from "react";
import { NavLink } from "react-router-dom";

const RolesList = ({ departmentId, roles, filmId, readOnly }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Roles</p>
      <ul className="menu-list">
        {(roles || []).map((role, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/departments/${departmentId}/roles/${role.id}/members`}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/departments/${departmentId}/roles/${role.id}`
                  );
                }}>
                {role.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default RolesList;
