import { useEffect } from "react";
import { fetchCharacterProps } from "./ducks/actions";
import { connect } from "react-redux";
import CharacterProps from "./character-props";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "../../../shared/loader";

const CharacterPropsContainer = (props) => {
    const { loading, characterProps, fetchCharacterProps } = props;
    const characterId = props.characterId || props.match.params.characterId;
    const filmId = props.filmId || props.match.params.filmId;
    useEffect(() => {
        fetchCharacterProps(filmId, characterId);
    }, [characterId]);

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/characters/:characterId/props"
            loading={loading}>
            <CharacterProps characterProps={characterProps} filmId={filmId} loading={loading} />
        </ScrollIntoView>
    )
}

function mapStateToProps(state) {
    const { charactersState } = state;
    const characterPropsState = charactersState.propsState;
    return {
        errors: characterPropsState.errors,
        characterProps: characterPropsState.characterProps,
        loading: characterPropsState.loading

    };
}

export default connect(mapStateToProps, {
    fetchCharacterProps
})(CharacterPropsContainer);