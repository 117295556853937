interface LoginState {
  loadingRegister: boolean;
  user: any;
  errors: any;
}

const defaultState: LoginState = {
  loadingRegister: false,
  user: {},
  errors: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "LOGIN_CLEAR_ERRORS": {
      return {
        ...state,
        loading: false,
        invitationErrors: null,
        errors: null,
        user: null
      };
    }

    case "LOGIN_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "LOGIN_FULFILLED": {
      return {
        ...state,
        invitationErrors: action.payload.data.invitationErrors,
        loading: false
      };
    }

    case "LOGIN_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        authenticated: false,
        errors: action.payload.response.data.errors,
        invitationErrors: action.payload.response.data.invitationErrors,
        loading: false
      };
    }

    case "AUTHENTICATED": {
      return {
        ...state,
        authenticated: true
      };
    }

    case "RESEND_EMAIL_CONFIRMATION_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "RESEND_EMAIL_CONFIRMATION_FULFILLED": {
      return {
        ...state,
        loading: false
      };
    }

    case "RESEND_EMAIL_CONFIRMATION_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        authenticated: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "CLEAR_LOGIN_REDIRECT": {
      return {
        ...state,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
