import React from "react";

const BoxWraper = (props: any) =>
{   
    const { noNavbarPadding } = props;
    const width = props.width ?? 5;
    return(
    <div className={`${!noNavbarPadding ? "navbar-padding" : ""}`}>
      <div className="container">
        <div className="columns is-centered">
            <div className={`column is-three-quarters-tablet is-${width+1}-desktop is-${width+1}-widescreen`}>
                <div className="box">
                    {props.children}
                </div>
            </div>
        </div>
      </div>
    </div>

)};

export default BoxWraper;