import { sortByName } from "../../../../shared/utility";
import { ADD_COSTUME_TO_SCENE } from "./actions";

const defaultState = {
  costume: {},
  costumes: [],
  loading: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_COSTUMES_IN_SCENE_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_COSTUMES_IN_SCENE_FULFILLED": {
      return {
        ...state,
        costumes: action.payload.data.costumes,
        loading: false,
        errors: null
      };
    }

    case ADD_COSTUME_TO_SCENE + "_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case ADD_COSTUME_TO_SCENE + "_FULFILLED": {
      const costume = action.payload.data.costume;
      const costumes = [...state.costumes, costume];
      sortByName(costumes);
      const { fromBreakdown } = action.meta;
      return {
        ...state,
        costumes,
        saving: false,
        errors: null,
        redirect: { to: fromBreakdown ? "breakdown" : "list" }
      };
    }

    case ADD_COSTUME_TO_SCENE + "_REJECTED": {
      return {
        ...state,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        character: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

function sortCharacters(characters) {
  characters.sort((a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });
}

export default reducer;
