import moment from "moment";
import Checkbox from "../../../shared/filmanize-checkbox";

const DaysOfWeek = ({ daysOfWeek, readOnly, onChange }: any) => {

    return (
        <div className="calendar-day-of-week-container">
            {daysOfWeek.map((dayOfWeek, idx) => (
                <div className="calendar-day-of-week" key={idx}>
                    <div className="calendar-day-of-week-checkbox">

                        <Checkbox
                            checked={dayOfWeek.selected}
                            disabled={readOnly}
                            readOnly={readOnly}
                            value={dayOfWeek.dayOfWeek}

                            onChange={(checked) => onChange(dayOfWeek.dayOfWeek, checked)} />
                    </div>
                    <div className="calendar-day-of-week-name">{moment().day(dayOfWeek.dayOfWeek).format("ddd")}</div>
                </div>

            ))}
        </div>
    )
};

export default DaysOfWeek;