import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export function fetchDepartments(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_DEPARTMENTS",
      payload: getApiClient().get(`/film/${filmId}/CrewDepartment`),
    });
  };
}

export function clearDepartment() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_DEPARTMENT"      
    });
  };
}

export function fetchDepartment(filmId, departmentId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_DEPARTMENT",
      payload: getApiClient().get(`/film/${filmId}/CrewDepartment/${departmentId}`),
    });
  };
}

export function updateDepartment(filmId, department) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_DEPARTMENT",
      payload: getApiClient().put(`/film/${filmId}/CrewDepartment/${department.id}`, department),
      meta: { department },
    })
      .then((response) => {
        dispatch(showInfo(`Updated department ${department.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating department ${department.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addDepartment(filmId, department) {
  department.mainImageUrl = null;
  department.imageUrls = [];

  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_DEPARTMENT",
      payload: getApiClient().post(`/film/${filmId}/CrewDepartment/`, department),
      meta: { department },
    })
      .then((response) => {
        dispatch(showInfo(`Added department ${department.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding department`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteDepartment(filmId, department) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_DEPARTMENT",
      payload: getApiClient().delete(`/film/${filmId}/CrewDepartment/${department.id}`),
      meta: { department },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted department ${department.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting department ${department.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
