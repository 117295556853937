import React from "react";
import { connect } from "react-redux";
import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import VisualEffect from "./visual-effect";
import Errors from "../../../shared/errors";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName
} from "./ducks/reducer";
import Onboarding from '../../../shared/onboarding/onboarding';
import Download from "../../../shared/download";

function Overview(props) {
  const {
    overview,
    filmId,
    errors,
    warnings,
    characterOrderBy,
    charactersOnboardingSteps,
    progress,
    download,
    clearPdf,
    onDownloadFile,
    aliasProp,
    unaliasProp
  } = props;

  const readOnly = overview.readOnly;

  function Charts() {
    return (
      <div className="columns">
        <div
          className={`${OnboardingStep1ClassName} circular-progress column`}>
          <FilmalityCircularProgress
            percentage={overview.propsAcquiredPercentage}
            title="Props Acquired"
          />
        </div>
      </div>
    );
  }

  function PropsList() {
    return (
      <table className={`${OnboardingStep3ClassName} table`}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Quantity</th>
            <th>Quantity Acquired</th>
            <th>Acquisition Method</th>
          </tr>
        </thead>
        <tbody>
          {(overview.props || []).map((prop, index) => {
            return (
              <VisualEffect
                key={index}
                index={index}
                prop={prop}
                filmId={filmId}
                readOnly={true}
                aliasProp={aliasProp}
                unaliasProp={unaliasProp} />
            );
          })}
        </tbody>
      </table>
    );
  }


  return (
    <div>
      <Charts />
      {/* <RenderHelpText /> */}
      {errors && <Errors errors={errors} />}
      {warnings && <Errors errors={warnings} isWarning={true} />}
      <div className="buttons">
        {characterOrderBy == "Name" && (
          <button
            className={`${OnboardingStep2ClassName} button`}
            onClick={props.orderCharactersByScenes}>
            Order By Number Of Scenes
          </button>
        )}

        {characterOrderBy == "Scenes" && (
          <button
            className={`${OnboardingStep2ClassName} button`}
            onClick={props.orderCharactersByName}>
            Order By Name
          </button>
        )}
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="columns">
          <div className="column">
            <PropsList />
          </div>
        </div>
      </DndProvider>
      <Download
        progress={progress}
        clearPdf={clearPdf}
        download={download}
        downloadFile={onDownloadFile}></Download>
      <Onboarding onboardingSteps={charactersOnboardingSteps} section="Characters" />
    </div>
  );
}

export default Overview;
