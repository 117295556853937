import { useState } from "react";
import UploadScript from "../../../film-scripts/upload-script";
import Versions from "./versions";
import WarningText from "../../../shared/warning-text";

const Upload = (props) => {
    const { versions,
        progress,
        setUploadError,
        uploadScript } = props;

    const [saving, setSaving] = useState(false);
    const isUploading = false;

    const onUpload = (formData) => {
        uploadScript(formData, props.history);
    };

    return (
        <div>
            <WarningText>
                Only upload a new version if you have not changed the scene numbers, otherwise the results will be unpredictable.
            </WarningText>
            <UploadScript isUploading={isUploading}
                setUploadError={setUploadError}
                progress={progress}
                saving={saving}
                setSaving={setSaving}
                onUpload={onUpload} />
            <Versions versions={versions} />
        </div>
    )
}

export default Upload;