import getApiClient from "../../../../shared/api";

export const SEARCH_SCENES_FOR_COSTUMES = "SEARCH_SCENES_FOR_COSTUMES";
export const SEARCH_SCENES_FOR_MAKEUP = "SEARCH_SCENES_FOR_MAKEUP";
export const SEARCH_SCENES_FOR_PROPS = "SEARCH_SCENES_FOR_PROPS";
export const SEARCH_SCENES_FOR_VISUAL_EFFECTS = "SEARCH_SCENES_FOR_VISUAL_EFFECTS";
export const SEARCH_SCENES_FOR_SET_DRESSING = "SEARCH_SCENES_FOR_SET_DRESSING";
export const SEARCH_SCENES_FOR_BACKGROUND_CHARACTER = "SEARCH_SCENES_FOR_BACKGROUND_CHARACTER";
export const SEARCH_SCENES_FOR_CHARACTER = "SEARCH_SCENES_FOR_CHARACTER";
export const SEARCH_SCENES_FOR_SCRIPT = "SEARCH_SCENES_FOR_SCRIPT";
export const SEARCH_SCENES_FOR_COMMERCIAL_SCRIPT = "SEARCH_SCENES_FOR_COMMERCIAL_SCRIPT";

export function searchScenes(
  typeToDispatch,
  filmId,
  params
) {

  const {
    sceneOrderFrom,
    sceneOrderTo,
    selectSearchedScenes
  } = params;

  const characterIds = params.characterIds?.map((x) => x.value);
  let sceneSettingIds = params.sceneSettingIds?.map((x) => x.value);
  if (params.sceneSettingId) {
    if (!sceneSettingIds) {
      sceneSettingIds = [params.sceneSettingId];
    }
  }
  const scriptDays = params.scriptDays?.map((x) => x.value);

  return (dispatch) => {
    let filters: string[] = [];

    if (characterIds?.length) {
      filters.push(`characterIds=${characterIds.join(",")}`);
    }

    if (sceneSettingIds?.length) {
      filters.push(`sceneSettingIds=${sceneSettingIds.join(",")}`);
    }

    if (sceneOrderFrom) {
      filters.push(`sceneOrderFrom=${sceneOrderFrom}`);
    }

    if (sceneOrderTo) {
      filters.push(`sceneOrderTo=${sceneOrderTo}`);
    }

    if (scriptDays?.length) {
      filters.push(`scriptDays=${scriptDays.join(",")}`);
    }

    dispatch({
      type: typeToDispatch,
      payload: getApiClient().get(
        `/film/${filmId}/scene/search?${filters.join("&")}`
      ),
      meta: { selectSearchedScenes }
    });
  };
}

export function searchScenes2(
  typeToDispatch,
  filmId,
  characterIds,
  sceneSettingIds,
  sceneOrderFrom,
  sceneOrderTo,
  selectSearchedScenes,
  scriptDays
) {
  return (dispatch) => {
    let filters: string[] = [];

    if (characterIds?.length) {
      filters.push(`characterIds=${characterIds.join(",")}`);
    }

    if (sceneSettingIds?.length) {
      filters.push(`sceneSettingIds=${sceneSettingIds.join(",")}`);
    }

    if (sceneOrderFrom) {
      filters.push(`sceneOrderFrom=${sceneOrderFrom}`);
    }

    if (sceneOrderTo) {
      filters.push(`sceneOrderTo=${sceneOrderTo}`);
    }

    if (scriptDays?.length) {
      filters.push(`scriptDays=${scriptDays.join(",")}`);
    }

    dispatch({
      type: typeToDispatch,
      payload: getApiClient().get(
        `/film/${filmId}/scene/search?${filters.join("&")}`
      ),
      meta: { selectSearchedScenes }
    });
  };
}