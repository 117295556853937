import React from "react";
import { useInView } from "react-intersection-observer";

const TimelineItem = ({title, description, first, last, completed}: any) => {    

  const options = { threshold: 1, triggerOnce: true, rootMargin: "0px 0px 0px 0px"  };
  const [ref, inView] = useInView(options);  

    return (       
        <div  className={`timeline-item ${first ? "first-timeline-item" : ""}`}>
          <div className={`timeline-marker ${first? "first-timeline-item" : ""} ${completed? "completed-timeline-item" : ""}`}></div>
          <div ref={ref} className={`timeline-content ${first ? "first-timeline-content" : ""} ${last ? "last-timeline-content" : ""} ${inView ? "timeline-item-in-view" : "" }`}>
            <p className="heading timeline-heading">{title}</p>
            <p className="timeline-description">{description}</p>
          </div>
        </div>      
    )
}

export default TimelineItem;