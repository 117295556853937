import React from "react";
import OverviewIcon from '../films/icons/overview';
import ScriptIcon from '../films/icons/script';
import ActorIcon from '../films/icons/actors';
import ShootingDaysIcon from '../films/icons/shooting-days';
import LocationsIcon from '../films/icons/locations';
import CollaboratorsIcon from '../films/icons/collaborators';
import BudgetIcon from '../films/icons/budget';
import Lozenger from './lozenger';
import TrustPilot from "./trust-pilot";

const Features = ({ windowWidth }: any) => {

  const overviewIcon = <OverviewIcon className="lozenger-icon" />;
  const scriptIcon = <ScriptIcon className="lozenger-icon" />;
  const shootingDaysIcon = <ShootingDaysIcon className="lozenger-icon" />;
  const actorIcon = <ActorIcon className="lozenger-icon" />;
  const locationIcon = <LocationsIcon className="lozenger-icon" />;
  const collaboratorsIcon = <CollaboratorsIcon className="lozenger-icon" />;
  const budgetIcon = <BudgetIcon className="lozenger-icon" />;

  const features = [
    {
      imageUrl: "/images/marketing/overview-on-laptop.png",
      icon: overviewIcon,
      title: "Overview",
      description: "Filmanize makes managing the production of your film simple by taking you from script to call sheet easily and efficiently, helping you find actors, track your budget and produce a suggested shooting schedule. Filmanize automatically generates your call sheet, saving you time on manual creation, and handles sending it out to cast and crew as well."
    },
    {
      imageUrl: "/images/marketing/script-on-laptop.png",
      icon: scriptIcon,
      title: "AI Script Breakdown",
      description: "Upload your script for an automatic AI-powered breakdown. Adjust any missed scene items directly in your script and easily add them to other scenes, making completing the script breakdown process efficient and intuitive."
    },

    {
      imageUrl: "/images/marketing/actor-on-laptop.png",
      icon: actorIcon,
      title: "Manage Your Actors",
      description: "Filmanize helps you match actors to your characters. Once you've added a character's details, you can search Mandy or StarNow for an actor who matches your criteria."
    },

    {
      imageUrl: "/images/marketing/location-on-laptop.png",
      icon: locationIcon,
      title: "Detailed Shooting Locations",
      description: "Easily add shooting locations using Filmanize's address lookup, which automatically finds the nearest hospital with an A&E department and help you locate convenient parking too.  All these details are automatically added to your call sheet."
    },

    {
      imageUrl: "/images/marketing/collaborators-on-laptop.png",
      icon: collaboratorsIcon,
      title: "Collaborate With Others",
      description: "Share your project with your filmmaking team by inviting them to join your film in Filmanize. Access can be granted as edit or read-only per section, on a per-film basis, so you're always in control."
    },

    {
      imageUrl: "/images/marketing/budget-on-laptop.png",
      icon: budgetIcon,
      title: "Budget Management",
      description: "Filmanize makes it easy to track your film's budget, giving you the option to use industry-standard rate cards, or your own, for cast and crew. The budget for any items such as props can also be added, then the number of shooting days on which these are needed is then used to calculate a budget for your film."
    },

    {
      imageUrl: "/images/marketing/shooting-days-on-laptop.png",
      icon: shootingDaysIcon,
      title: "Create Shooting Schedule",
      description: 'The "Smart Days" feature creates a provisional shooting schedule using scene length and the configurable number of pages you can shoot per day, which you can then finalise manually.'
    },
    {
      imageUrl: "/images/marketing/shoot-list-schedule-on-laptop.png",
      icon: shootingDaysIcon,
      title: "Optimise Shooting Days",
      description: "Each shooting day can be further broken down into time slots, allowing you to allocate shots to each slot and group similar shots together to maximise efficiency on your shoot. "
    },

    {
      imageUrl: "/images/marketing/call-sheet-on-laptop.png",
      icon: shootingDaysIcon,
      title: "Auto-generated Call Sheets",
      description: "Bring it all together and generate your call sheet. Filmanize uses the scenes that are going to be shot in that day to select the required actors and items, as well as the detailed weather forecast for your location. Select which sections you want and review the call sheets then send them out, either manually or using Filmanize's scheduled auto-send functionality."
    }
  ]

  return (
    <section id="features">
      <div className="lozengers">

        {features.map((feature: any, index: number) => {
          let color;
          if (index % 3 == 0) {
            color = "green"
          }
          else if (index % 3 == 1) {
            color = "grey"
          }
          else if (index % 3 == 2) {
            color = "purple"
          }

          const alignRight = index % 2;
          return <Lozenger key={index}
            backgroundColor={color}
            alignRight={alignRight}
            imageUrl={feature.imageUrl}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
            windowWidth={windowWidth} />
        })}

      </div>
    </section>
  );
};

export default Features;
