import ListCollaboratorsInRoleContainer from "./list-container";

import { PrivateRoute } from "../../../shared/private-route";

const UserRoutes = () => {
  return (
    <>
      <PrivateRoute
        exact
        path="/films/:filmId/collaborators/in-role/:roleId"
        component={ListCollaboratorsInRoleContainer}
      />
    </>
  );
};

export default UserRoutes;
