import getApiClient from "./../../../shared/api";

const url = "/film";

export function fetchOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_FILM_OVERVIEW",
      payload: getApiClient().get(`${url}/${id}/Overview`),
    });
  };
}
