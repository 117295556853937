import invitations from "../../../shared/invitations";
import { CLEAR_REGISTERED, REGISTER_GET_CURRENCIES, REGISTER_GET_LANGUAGES } from "./actions";

interface RegisterState {
  loadingRegister: boolean;
  loadingLanguages: boolean;
  loadingCountries: boolean;
  loadingCurrencies: boolean;
  user: any;
  countries: any[];
  languages: any[];
  currencies: any[];
  defaultCountryId: any;
  defaultLanguageId: any;
  defaultCurrencyId: any;
  referralCode: string | null;
  ref: any;
}

const defaultState: RegisterState = {
  loadingRegister: false,
  loadingLanguages: false,
  loadingCountries: false,
  loadingCurrencies: false,
  user: {},
  countries: [],
  languages: [],
  currencies: [],
  defaultCountryId: null,
  defaultLanguageId: null,
  defaultCurrencyId: null,
  referralCode: null,
  ref: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "REGISTER_CLEAR_FORM": {
      // const referralCode = invitations.getReferralCode();
      // const ref = localStorage.getItem("ref");
      // const user = { referrer: ref };
      return {
        ...state,
        //user: user,
        user: {},
        errors: null,
        registered: false,
        //referralCode,
        //ref
      };
    }

    case "REGISTER_GET_COUNTRIES_PENDING": {
      return {
        ...state,
        loadingCountries: true
      };
    }

    case "REGISTER_GET_COUNTRIES_FULFILLED": {
      return {
        ...state,
        countries: action.payload.data.countries,
        user: { ...state.user, countryId: action.payload.data.defaultId },
        defaultCountryId: action.payload.data.defaultId,
        loadingCountries: false
      };
    }

    case "REGISTER_GET_COUNTRIES_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingCountries: false
      };
    }

    case REGISTER_GET_LANGUAGES + "_PENDING": {
      return {
        ...state,
        loadingLanguages: true
      };
    }

    case REGISTER_GET_LANGUAGES + "_FULFILLED": {
      return {
        ...state,
        languages: action.payload.data.languages,
        user: { ...state.user, languageId: action.payload.data.defaultId },
        defaultLanguageId: action.payload.data.defaultId,
        loadingLanguages: false
      };
    }

    case REGISTER_GET_LANGUAGES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingLanguages: false
      };
    }

    case REGISTER_GET_CURRENCIES + "_PENDING": {
      return {
        ...state,
        loadingCurrencies: true
      };
    }


    case REGISTER_GET_CURRENCIES + "_FULFILLED": {
      return {
        ...state,
        currencies: action.payload.data.currencies,
        user: { ...state.user, currencyId: action.payload.data.defaultId },
        defaultCurrencyId: action.payload.data.defaultId,
        loadingCurrencies: false
      };
    }

    case REGISTER_GET_CURRENCIES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingCurrencies: false
      };
    }

    case "REGISTER_PENDING": {
      return {
        ...state,
        loadingRegister: true,
        errors: null
      };
    }

    case "REGISTER_FULFILLED": {
      localStorage.removeItem("ref");
      return {
        ...state,
        loadingRegister: false,
        errors: null,
        registered: true
      };
    }

    case "REGISTER_REJECTED": {
      debugger;
      let referralCode = state.referralCode;
      if (action.payload.response.data.invalidReferralCode) {
        invitations.removeReferralCode();
        referralCode = null;
      }

      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loadingRegister: false,
        referralCode: referralCode
      };
    }

    case "CLEAR_REGISTER_REDIRECT": {
      return {
        ...state,
        redirect: null
      };
    }

    case CLEAR_REGISTERED: {
      return {
        ...state,
        registered: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
