import React from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { OnboardingStep5ClassName } from './ducks/reducer';

function Character(props) {
  const { character, onChangeCharacterDailyRate, readOnly, index, filmId, saving } = props;
  return (
    <tr>
      <td><Link to={`/films/${filmId}/characters/${character.id}/edit`}>{character.name}</Link></td>
      <td className="budget-days-column">{character.days}</td>
      <td className={`budget-daily-rate-column ${index === 0 ? OnboardingStep5ClassName : ''}`}>
        <CurrencyInputContainer
          value={character.dailyBudget}
          onChange={(val) => onChangeCharacterDailyRate(character.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={character.total}
        />
      </td>
    </tr>
  );
}

export default Character;
