import { Link } from "react-router-dom";
import Loader from "../../../shared/loader";

const CharacterCostumes = ({ characterCostumes, filmId, loading }) => {
    return (
        <div>
            <h2 className="menu-label">Costumes</h2>
            {loading && <Loader />}
            {!loading && <>
                {!characterCostumes?.length && <div>None</div>}
                {(characterCostumes || []).map((costume: any, index: number) =>
                (
                    <div><Link to={`/films/${filmId}/costumes/${costume.id}`}>{costume.name}</Link></div>
                ))}
            </>}
        </div>
    )
}

export default CharacterCostumes;