import { Link } from "react-router-dom";
import Loader from "../../../shared/loader";

const BackgroundCharacterMakeups = ({ makeups, filmId, loading }) => {
    return (
        <div>
            <h2 className="menu-label">Makeup</h2>
            {loading && <Loader />}
            {!loading && <>
                {!makeups?.length && <div>None</div>}
                {(makeups || []).map((makeup: any, index: number) =>
                (
                    <div><Link to={`/films/${filmId}/makeup/${makeup.id}`}>{makeup.name}</Link></div>
                ))}
            </>}
        </div>
    )
}

export default BackgroundCharacterMakeups;