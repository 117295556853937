import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { addCollaborator, clearUser, clearRedirect, fetchCanAdd, fetchCollaboratorsRoles } from "./ducks/actions";
import Form from "./form";
import Loader from "../../shared/loader";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import WarningText from "../../shared/warning-text";

function AddUser(props) {
  const filmId = props.match.params.filmId;
  const { fetchCanAdd, clearUser, addUser, errors, user, canAdd, fetchCollaboratorsRoles, roles } = props;

  useEffect(() => {
    fetchCanAdd(filmId);
    fetchCollaboratorsRoles(filmId);
  }, [filmId]);

  useEffect(() => {
    clearUser();
  }, [filmId, clearUser]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/collaborators`;
      return <Redirect to={url} />;
    }
  }

  function onAddUser(user) {
    sanitiseDataTypes(user);
    addUser(filmId, user);
  }

  function sanitiseDataTypes(collaborator) {
  }

  if (props.loadingUser) {
    return <Loader />;
  }

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/collaborators/add"
      loading={false}>
      {canAdd &&
        <Form
          mode="add"
          errors={errors}
          userId={null}
          initialValues={user}
          loading={false}
          onSubmit={onAddUser}
          filmId={filmId}
          roles={roles}
          {...props}
        />}
      {!canAdd && <WarningText>You cannot add any more collaborators due to your pricing plan. Head to your <Link to={`/account`}>account</Link> section to upgrade your pricing plan.</WarningText>}
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { usersState, commonState } = state;
  return {
    user: usersState.user,
    roles: usersState.roles,
    canAdd: usersState.canAdd,
    loadingUser: usersState.loadingUser,
    errors: usersState.errors,
    redirect: usersState.redirect,
    userAccessEnum: commonState.userAccessEnum
  };
}

export default connect(mapStateToProps, {
  addUser: addCollaborator,
  clearUser,
  clearRedirect,
  fetchCanAdd,
  fetchCollaboratorsRoles
})(AddUser);
