import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { NavLink } from "react-router-dom";
import { ItemTypes } from "./constants";
import CanAccessNavLink from "../../../shared/can-access-nav-link";

function Character(props) {
  const {
    character,
    index,
    filmId,
    assignActor,
    unassignActor,
    aliasCharacter,
    unaliasCharacter,
    readOnly,
    canAccessBackgroundActors
  } = props;

  const dropActor = (item) => {
    assignActor(filmId, item, character);
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ACTOR,
    drop: (item: any, monitor) => dropActor(item.actor),
    canDrop: (item, monitor) => canDropActor(item, monitor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  const canDropActor = (item, monitor) => {
    return !readOnly && character.backgroundActors.findIndex(a => a.id === item.actor.id) === -1 && character.backgroundActors.length < character.quantity;
  };

  const getItemStyle = (isDragging) => ({
    background: isDragging ? "#947eed" : "#040f21"
  });

  return (
    <>
      <tr>
        <td>
          <NavLink to={`/films/${filmId}/background-characters/${character.id}/edit`}>
            {character.name}
          </NavLink>
        </td>
        <td>{character.quantity}</td>
        <td ref={drop} style={getItemStyle(isOver)}>
          {(character.backgroundActors || []).map((backgroundActor, index) => (
            <div key={index}>
              <CanAccessNavLink to={`/films/${filmId}/background-actors/${backgroundActor.id}/edit`} canAccess={canAccessBackgroundActors}>
                {backgroundActor.name}
              </CanAccessNavLink>
              &nbsp;
              {!readOnly && <a
                onClick={() =>
                  unassignActor(filmId, character, backgroundActor.id, backgroundActor.name)
                }
              >
                <i className="material-icons">cancel</i>
              </a>}
            </div>
          ))}
        </td>
        <td>
          {!character.backgroundActors?.length && (
            <a href={character.mandySearchUrl} target="_blank">
              Search Mandy
            </a>
          )}

          {(character.backgroundActors || []).map((backgroundActor, index) => (
            <div key={index}>
              {backgroundActor.mandyUrl && <a href={backgroundActor.mandyUrl} target="_blank">
                Mandy Profile
              </a>}
              {!backgroundActor.mandyUrl && <span>&nbsp;</span>}
            </div>
          ))}
        </td>
        <td>
          {!character.backgroundActors?.length && (
            <a href={character.starNowSearchUrl} target="_blank">
              Search StarNow
            </a>
          )}
          {(character.backgroundActors || []).map((backgroundActor, index) => (
            <div key={index}>
              {backgroundActor.starNowUrl && <a href={backgroundActor.starNowUrl} target="_blank">
                StarNow Profile
              </a>}
              {!backgroundActor.starNowUrl && <span>&nbsp;</span>}
            </div>
          ))}
        </td>
      </tr>
      {(character.aliases || []).map((alias) => (
        <tr key={alias.id}>
          <td colSpan={4}>
            <div style={{ marginLeft: 30 }}>
              {alias.name}
              &nbsp;
              {!readOnly && <a onClick={() => unaliasCharacter(filmId, alias, character)}>
                <i className="material-icons">cancel</i>
              </a>}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default Character;
