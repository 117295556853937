import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import './notification.scss';

const Notification = (props) => {
  const { icon, message, severity, dismissMessage, onLinkClick } = props;

  const onOnLinkClick = (event) => {
    event.currentTarget.blur();
    if (onLinkClick) {      
      onLinkClick(message.link);
      dismissMessage(message.id);
    }
  }

  const onClick = (event, dismiss) => {
    event.currentTarget.blur();
    if (dismiss) {      
      dismissMessage(message.id);
    }
  }

  const isWhatsNew = message?.link?.includes("change-log");

  return (
    <>
      <hr className="navbar-divider" />
      <div className="columns navbar-item navbar-notification">
        <div className="column">
          <div className={`user-notification-circle ${severity}`}>{icon}</div>
        </div>
        <div className="column">
          <div className="notification-data">
            <div className="navbar-notification date">
              <Moment format="DD MMM, YYYY HH:mm" local date={message.added} />
            </div>
            <div>
              {message.entityName}: {message.message}
            </div>
            <div>
            {message.link && <>
              { isWhatsNew && <a
                  className="text-link-on-white mr-1"                  
                  onClick={onOnLinkClick}>
                  {message.severity == 1 ? "Details" : "Fix"}
                </a>}
              {!isWhatsNew && <Link
                  className="text-link-on-white mr-1"
                  to={message.link}
                  onClick={(event) => {
                    onClick(event, message.severity == 1)}}>
                  {message.severity == 1 ? "Details" : "Fix"}
                </Link>}
            </>
                
            }
            {message.severity === 1 && dismissMessage && (
              <button
                className="button is-text text-link-on-white"
                onClick={() => dismissMessage(message.id)}>
                Dismiss
              </button>
            )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
