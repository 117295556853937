import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

const url = "/film";

export const IMPORT_MOVIE_MAGIC = "IMPORT_MOVIE_MAGIC";

export function fetchScenesShootingOrder(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_SHOOTING_ORDER",
      payload: getApiClient().get(`/film/${filmId}/scene/shootingOrder`)
    });
  };
}

export function smartShootingOrder(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_SMART_SHOOTING_ORDER",
      payload: getApiClient().get(`/film/${filmId}/scene/smartShootingOrder`)
    });
  };
}

export function deleteAllShootingDays(filmId) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_ALL_SHOOTING_DAYS",
      payload: getApiClient().delete(`/film/${filmId}/shootingDay/deleteAll`)
    }).then((response) => {
      dispatch(showInfo(`Deleted all shooting days`));
    })
      .catch((response) => {
        dispatch(showError(`Error deleteing all shooting days`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function resetSceneShootDays(filmId) {
  return (dispatch) => {
    dispatch({
      type: "RESET_SCENE_SHOOTS_DAYS",
      payload: getApiClient().put(`/film/${filmId}/scene/resetSceneShootDays`)
    }).then((response) => {
      dispatch(showInfo(`Reset all shoot days`));
    })
      .catch((response) => {
        dispatch(showError(`Error reseting all shoot days`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchScenesShootingOrderByDay(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_SHOOTING_ORDER_BY_DAY",
      payload: getApiClient().get(`/film/${filmId}/scene/shootingOrderByDay`)
    });
  };
}

export function smartScenesShootingOrderByDay(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_SHOOTING_ORDER_BY_DAY",
      payload: getApiClient().get(
        `/film/${filmId}/scene/smartShootingOrderByDay`
      )
    });
  };
}

export function updateScenesShootingOrder(filmId, scenes) {
  const command = { scenes };
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SCENES_SHOOTING_ORDER",
      payload: getApiClient().put(
        `/film/${filmId}/scene/shootingOrder`,
        command
      ),
      meta: { scenes }
    })
      .then((response) => {
        dispatch(showInfo(`Updated scene orders`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating scene orders`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function importMovieMagic(filmId, formData) {
  return (dispatch) => {
    dispatch({
      type: IMPORT_MOVIE_MAGIC,
      payload: getApiClient().post(`film/${filmId}/ShootingDay/ImportFromMovieMagic`, formData, {
        headers: {
          "content-type": `multipart/form-data`
        }
      })
    }).then((response) => {
      dispatch(showInfo(`Successfully imported Movie Magic Schedule`));
    })
      .catch((response) => {
        dispatch(showError(`Error importing Movie Magic Schedule`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addScenesShootingOrderDays(filmId, days) {
  const command = { days };
  return (dispatch) => {
    dispatch({
      type: "ADD_SCENES_SHOOTING_ORDER_DAYS",
      payload: getApiClient().post(
        `/film/${filmId}/scene/shootingOrderByDay`,
        command
      ),
      meta: { days }
    })
      .then((response) => {
        dispatch(showInfo(`Updated scene orders`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating scene orders`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateOrder(startIndex, endIndex) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_SCENES_SHOOTING_ORDER",
      meta: { startIndex, endIndex }
    });
  };
}

export function updateSceneOrder(
  startIndex,
  endIndex,
  sourceDay,
  destinationDay
) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_SCENES_SHOOTING_ORDER_DAY_SCENES",
      meta: { startIndex, endIndex, sourceDay, destinationDay }
    });
  };
}

export function updateDayOrder(startIndex, endIndex) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_SCENES_SHOOTING_ORDER_DAYS",
      meta: { startIndex, endIndex }
    });
  };
}

export function addShootingDay() {
  return (dispatch) => {
    dispatch({
      type: "ADD_SCENES_SHOOTING_ORDER_DAY"
    });
  };
}

export function deleteShootingDay(dayNumber) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SCENES_SHOOTING_ORDER_DAY",
      meta: { dayNumber }
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function downloadShootingDays(id) {
  return (dispatch) => {
    dispatch({
      type: "DOWNLOAD_SHOOTING_DAYS",
      payload: getApiClient().post(`${url}/${id}/shootingDay/download`, {}),
    });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SHOOTING_DAYS_PDF",
      meta: {},
    });
  };
}

export function clearDownload() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SHOOTING_DAYS_DOWNLOAD",
      meta: {},
    });
  };
}

