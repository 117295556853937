import formatBytes from "../../shared/formatBytes";
import './account.scss';
import FilmaizeSelect from "../../shared/filmanize-select";
import Errors from "../../shared/errors";
import confirm from "../../shared/modal/confirm";
import Moment from "react-moment";
import moment from "moment";

const Account = (props) => {
    const { account, pricingPlans, changePricingPlan, errors, update, pausePayment, resumePayment, cancelPayment, unCancelPayment } = props;

    const filmStorageUsedPercentage = Math.round((account.storageUsed / account.totalStorage) * 1000) / 10;
    const remainingStorage = account.totalStorage - account.storageUsed;

    const usedFilms = account.usedFilms;
    const maximumFilms = account.maximumFilms;
    const remainingFilms = maximumFilms ? maximumFilms - usedFilms : null;
    const filmsUsedPercentage = account.maximumFilms ? Math.round((usedFilms / maximumFilms) * 1000) / 10 : 0;

    const mappedPricingPlans = pricingPlans?.map((pricingPlan: any) => { return { label: pricingPlan.name, value: pricingPlan.pricingPlanType } });
    const selectedMappedPricingPlan = mappedPricingPlans?.find((pricingPlan: any) => pricingPlan.value == account.pricingPlanType);
    const selectedPricingPlan = pricingPlans?.find((pricingPlan: any) => pricingPlan.pricingPlanType == account.pricingPlanType);

    const billingDate = moment(account.nextBillingDate).format('LLL');

    const onChangePricingPlan = (pricingPlanType: any) => {
        changePricingPlan(pricingPlanType.value)
    };

    const onPausePayment = () => {
        confirm(() => pausePayment(), null, `Are you sure you wish to pause your subscription? You will be still be able to use the service until your next billing date, ${billingDate}.`, "Pause Subscription");
    }

    const onResumePayment = () => {
        confirm(() => resumePayment(), null, `Are you sure you wish to resume your subscription? The service will continue to collect money on your billing date, ${billingDate}.`, "Resume Subscription");
    }

    const onCancelPayment = () => {
        confirm(() => cancelPayment(), null, `Are you sure you wish to cancel your subscription? This will cause all of your data to be deleted. We suggest you pause your subscription instead as this will retain your data for up to a year, and then will be automatically deleted if not resumed. 
        
        If you do cancel, you will be still be able to use the service until your next billing date, ${billingDate}.`, "Cancel Subscription");
    }

    const onUnCancelPayment = () => {
        confirm(() => unCancelPayment(), null, `Are you sure you wish to resume your subscription? The service will continue to collect money on your billing date, ${billingDate}.`, "Resume Subscription");
    }

    return (
        <>
            <h2 className="menu-label">Account</h2>
            <Errors errors={errors} />
            <div>
                <label className="label mt-1">Storage used</label>
                <div className="level-item account-storage-progress-container" >
                    <div className="account-storage-progress" style={{ background: `linear-gradient(to right, #947eed ${filmStorageUsedPercentage}%, #162135 ${filmStorageUsedPercentage}%)` }}></div>
                    <div className="level-item progess-percentage account-storage-progress-percentage"><p>{filmStorageUsedPercentage}% </p></div>
                </div>

                <div className="progess-percentage">
                    <span className="this-film">Used: {formatBytes(account.storageUsed)}</span>
                    <span className="account-remaining-storage">Remaining: {formatBytes(remainingStorage)}</span>
                    <span className="account-total-storage">Total: {formatBytes(account.totalStorage)}</span>
                </div>
            </div>
            <div>
                <label className="label mt-1">Films used</label>

                <div className="level-item account-storage-progress-container" >
                    <div className="account-storage-progress" style={{ background: `linear-gradient(to right, #947eed ${filmsUsedPercentage}%, #162135 ${filmsUsedPercentage}%)` }}></div>
                    <div className="level-item progess-percentage account-storage-progress-percentage"><p>{filmsUsedPercentage}% </p></div>
                </div>

                <div className="progess-percentage">
                    <span className="this-film">Used: {usedFilms}</span>
                    <span className="total-storage">Remaining: {remainingFilms ? remainingFilms : "Unlimited"}</span>
                    <span className="account-total-storage">Total: {maximumFilms ? maximumFilms : "Unlimited"}</span>
                </div>
            </div>
            <div>
                <label className="label mt-1">Referral Url</label>
                {account.isTeacher && <p className="account-teacher">Your account is flagged as a teacher account and therefore any user using your referral link will me marked as a student.</p>}
                <a className="referral-url" href={account.referralUrl}>{account.referralUrl}</a>
                <p className="this-film progess-percentage">Referred Count: {account.referredUsersCount}</p>
            </div>
            <div className="mt-1">
                <label className="label">Pricing Plan</label>
                <FilmaizeSelect
                    value={selectedMappedPricingPlan}
                    isMulti={false}
                    options={mappedPricingPlans}
                    onChange={onChangePricingPlan}
                    placeholder="Pricing Plan"
                />
                <table className="table mt-1">
                    <tbody>
                        <tr>
                            <td>Maximum Films</td>
                            <td>{selectedPricingPlan?.maxFilms ? selectedPricingPlan?.maxFilms : "Unlimited"}</td>
                        </tr>
                        <tr>
                            <td>Maximum Bytes</td>
                            <td>{formatBytes(selectedPricingPlan?.maxBytes)}</td>
                        </tr>
                        <tr>
                            <td>Maximum Collaborators</td>
                            <td>{selectedPricingPlan?.maxCollaboratorsPerFilm ? selectedPricingPlan?.maxCollaboratorsPerFilm : "Unlimited"}</td>
                        </tr>
                        <tr>
                            <td>Price</td>
                            <td><span className="account-price-line-through"><span dangerouslySetInnerHTML={{ __html: selectedPricingPlan?.currencySymbol }}></span>{selectedPricingPlan?.costPerMonth}</span>
                                <span dangerouslySetInnerHTML={{ __html: selectedPricingPlan?.currencySymbol }}></span>0
                            </td>
                        </tr>
                    </tbody>
                </table>
                {account.isStudent && <p className="account-teacher">Your account is flagged as a student account and so benefits from reduced pricing.</p>}
                {account.nextBillingDate &&
                    <div>
                        <label className="label mt-1">Next Billing Date</label>
                        <Moment format="LLL">{account.nextBillingDate}</Moment>
                        <span className="referral-url this-film ml-1"><Moment fromNow>{account.nextBillingDate}</Moment></span>

                    </div>}
                <div className="mt-1">
                    <button className="button" onClick={update}>Update</button>
                    {account.canPausePayment && <button className="button ml-1" onClick={onPausePayment}>Pause Subscription</button>}
                    {account.canResumePayment && <button className="button ml-1" onClick={onResumePayment}>Resume Subscription</button>}
                    {account.canCancelPayment && <button className="button ml-1" onClick={onCancelPayment}>Cancel Subscription</button>}
                    {account.willBeCancelled && <button className="button ml-1" onClick={onUnCancelPayment}>Un Cancel Subscription</button>}
                </div>
            </div>
        </>
    )
}

export default Account;