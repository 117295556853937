export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="31.026" height="30.626" viewBox="0 0 24.034 24.032" {...props}>
    <g id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected" transform="translate(0.5 0.5)">
      <g id="Group_3844" data-name="Group 3844">
        <rect id="Rectangle_4233" data-name="Rectangle 4233" width="14.632" height="9.739" transform="translate(16.456 14.136) rotate(-180)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32857" data-name="Path 32857" d="M21.6,19.718,25.7,20.776a.108.108,0,0,0,.141-.092V10.428a.107.107,0,0,0-.141-.092L21.6,11.393a.528.528,0,0,0-.421.492v7.341A.528.528,0,0,0,21.6,19.718Z" transform="translate(-2.804 -6.289)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <rect id="Rectangle_4234" data-name="Rectangle 4234" width="1.926" height="7.259" transform="translate(16.474 5.637)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32858" data-name="Path 32858" d="M10.995,10.4V6.582H7.663L6.019,10.309" transform="translate(-2.804 -6.289)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32859" data-name="Path 32859" d="M14.134,8.247h3.561a1.007,1.007,0,0,0,1.033-.979h0a1.007,1.007,0,0,0-1.033-.979H14.134a1.036,1.036,0,0,0-.951.6.936.936,0,0,0-.081.381h0A1.007,1.007,0,0,0,14.134,8.247Z" transform="translate(-2.804 -6.289)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_715" data-name="Line 715" x2="1.747" transform="translate(8.275 1.056)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32860" data-name="Path 32860" d="M4.443,12.015,2.909,11.7c-.052-.011-.105.016-.105.054v3.005c0,.038.053.066.105.055L4.443,14.5c.07-.014.118-.059.118-.109V12.125C4.561,12.074,4.513,12.029,4.443,12.015Z" transform="translate(-2.804 -6.289)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <rect id="Rectangle_4235" data-name="Rectangle 4235" width="2.262" height="4.452" rx="1.041" transform="translate(6.616 11.492) rotate(180)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_716" data-name="Line 716" x1="6.691" y2="8.704" transform="translate(4.313 14.127)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_717" data-name="Line 717" x2="6.691" y2="8.704" transform="translate(7.402 14.127)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      </g>
    </g>
  </svg>
