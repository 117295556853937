import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  fetchSchedule,
  saveSchedule,
  changeDayOfWeek,
  updateEvent,
  addDays,
  deleteDay,
  deleteDays,
  setPotentialDaysWhereLocationsAvailable
} from "./ducks/actions";
import Scheduling from "./potential-dates";
import Loader from "../../shared/loader";
import Onboarding from "../../shared/onboarding/onboarding";

const SchedulingContainer = (props) => {
  const filmId = props.match.params.filmId;
  const { fetchSchedule,
    saveSchedule,
    loading,
    saving,
    daysOfWeek,
    changeDayOfWeek,
    updateEvent,
    addDays,
    deleteDay,
    deleteDays,
    days,
    readOnly,
    pristine,
    onboardingSteps,
    setPotentialDaysWhereLocationsAvailable } = props;

  useEffect(() => {
    fetchSchedule(filmId);
  }, [filmId]);

  const changeStartDate = () => {

  };

  const onSave = () => {
    saveSchedule(filmId, days.filter(d => !d.isLocation), daysOfWeek);
  }

  return (
    <ScrollIntoView
      className="blade-content wide"
      path="/films/:filmId/scheduling"
      loading={loading}>
      <h2 className="menu-label">Potential Dates</h2>
      {(loading || saving) && <Loader />}
      {!loading && <Scheduling
        filmId={filmId}
        daysOfWeek={daysOfWeek}
        changeDayOfWeek={changeDayOfWeek}
        days={days}
        readOnly={readOnly}
        updateEvent={updateEvent}
        addDays={addDays}
        deleteDay={deleteDay}
        deleteDays={deleteDays}
        saveDays={onSave}
        pristine={pristine}
        onboardingSteps={onboardingSteps}
        setPotentialDaysWhereLocationsAvailable={setPotentialDaysWhereLocationsAvailable} />}
    </ScrollIntoView>
  );
};

function mapStateToProps(state) {
  const { schedulingState } = state;
  const rootState = schedulingState.rootState;
  return {
    errors: rootState.errors,
    loading: rootState.loading,
    saving: rootState.saving,
    readOnly: rootState.readOnly,
    daysOfWeek: rootState.daysOfWeek,
    days: rootState.days,
    pristine: rootState.pristine,
    onboardingSteps: rootState.onboardingSteps
  };
}

export default connect(mapStateToProps,
  {
    fetchSchedule,
    saveSchedule,
    changeDayOfWeek,
    updateEvent,
    addDays,
    deleteDay,
    deleteDays,
    setPotentialDaysWhereLocationsAvailable
  })(SchedulingContainer);
