export default function mapPictures(entity) {
  entity.pictures = entity.images?.map((img) => {
    return {
      url: img.url,
      isPrimary: img.isMain,
      isUploaded: true
    };
  });

  entity.mainImageIndex = entity.images?.findIndex(i => i.isMain);
}
