import getApiClient from './../../../../../../shared/api';
import {
  showInfo,
  showError,
  clearNotifications,
} from './../../../../../../notifications/ducks/actions';
import {getActionToUploadImagesAndSaveEntity} from './../../../../../../shared/image-action-creator';

export function fetchFilmCrewMembers(filmId) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_FILM_CREW_MEMBERS',
      payload: getApiClient().get(`/film/${filmId}/CrewMember`),
    });
  };
}

export function clearCrewMember() {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_CREW_MEMBER',
    });
  };
}

export function fetchMembers(filmId, departmentId, crewRoleId) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_MEMBERS',
      payload: getApiClient().get(
        `/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${crewRoleId}/CrewMember`
      ),
    });
  };
}

export function fetchMember(filmId, departmentId, crewRoleId, memberId) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_SINGLE_MEMBER',
      payload: getApiClient().get(
        `/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${crewRoleId}/CrewMember/${memberId}`
      ),
    });
  };
}

export function updateMember(filmId, departmentId, crewRoleId, member) {
  return (dispatch) => {
    updateMemberWithImageUrls(
      dispatch,
      member,
      filmId,
      departmentId,
      crewRoleId
    );
  };
}

export function updateMemberWithImageUrls(
  dispatch,
  member,
  filmId,
  departmentId,
  crewRoleId
) {
  dispatch({
    type: 'UPDATE_SINGLE_MEMBER',
    payload: getApiClient().put(
      `/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${crewRoleId}/CrewMember/${member.id}`,
      member
    ),
    meta: {member},
  })
    .then((response) => {
      dispatch(
        showInfo(`Updated member ${member.firstName} ${member.lastName}`)
      );
    })
    .catch((response) => {
      dispatch(
        showError(
          `Error updating member ${member.firstName} ${member.lastName}`
        )
      );
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function addMember(filmId, departmentId, crewRoleId, member) {
  member.mainImageUrl = null;
  member.imageUrls = [];

  return (dispatch) => {
    addMemberWithImageUrls(dispatch, member, filmId, departmentId, crewRoleId);
  };
}
export function addMemberWithImageUrls(
  dispatch,
  member,
  filmId,
  departmentId,
  crewRoleId
) {
  dispatch({
    type: 'ADD_SINGLE_MEMBER',
    payload: getApiClient().post(
      `/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${crewRoleId}/CrewMember`,
      member
    ),
    meta: {member},
  })
    .then((response) => {
      dispatch(showInfo(`Added member ${member.firstName} ${member.lastName}`));
    })
    .catch((response) => {
      dispatch(showError(`Error adding member`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function assignRoleToMember(filmId, departmentId, crewRoleId, memberId) {
  return (dispatch) => {
    dispatch({
      type: 'ADD_SINGLE_MEMBER_TO_ROLE',
      payload: getApiClient().put(
        `/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${crewRoleId}/CrewMember/${memberId}/Assign`
      ),
      meta: {memberId},
    })
      .then((response) => {
        dispatch(showInfo(`Added member to role`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding member to role`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteMember(filmId, departmentId, crewRoleId, member) {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_SINGLE_MEMBER',
      payload: getApiClient().delete(
        `/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${crewRoleId}/CrewMember/${member.id}`
      ),
      meta: {member},
    })
      .then((response) => {
        dispatch(
          showInfo(`Deleted member ${member.firstName} ${member.lastName}`)
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error deleting member ${member.firstName} ${member.lastName}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_REDIRECT',
    });
  };
}

export const uploadImagesAndSaveCrewMember = getActionToUploadImagesAndSaveEntity(
  addMemberWithImageUrls,
  updateMemberWithImageUrls
);
