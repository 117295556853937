import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { addShootingDay } from "./ducks/actions";
import Form from "./form";

function AddShootingDay(props) {
  const { filmId, shootingdayId } = props.match.params;
  const { addShootingDay, errors } = props;

  function onAddShootingDay(shootingday) {
    sanitiseDataTypes(shootingday);
    addShootingDay(filmId, shootingday);
  }

  function sanitiseDataTypes(shootingday) { }

  if (props.redirect) {
    if (props.redirect.to === "edit") {
      const url = `/films/${filmId}/shooting-days/${props.redirect.shootingdayId}/edit`;
      return <Redirect to={url} />;
    }
    if (props.redirect.to === "schedule") {
      const url = `/films/${filmId}/shooting-days/${props.redirect.shootingdayId}/schedule`;
      return <Redirect to={url} />;
    }
  }

  return (
    <div className="blade-content wide">
      <Form
        mode="add"
        errors={errors}
        shootingdayId={null}
        initialValues={null}
        loading={false}
        onSubmit={onAddShootingDay}
        showNumberField={false}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { rootState } = state.shootingdaysState;
  return {
    errors: rootState.errors,
    redirect: rootState.redirect
  };
}

export default connect(mapStateToProps, {
  addShootingDay
})(AddShootingDay);
