import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../shared/loader";
import {
  fetchCollaboratorsOverview
} from "../ducks/actions";
import Overview from "./overview";

const OverviewContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchCollaboratorsOverview,    
    overview,
    loading,
    errors,
    warnings    
  } = props;

  useEffect(() => {
    fetchCollaboratorsOverview(filmId);

    return function cleanUp() {
      //clearCharacterOverview();
    };
  }, [filmId]);

  function renderOverview(overview) {
    
    const assingedCollaborators = overview.collaborators?.filter(
      (c) => c.status === 4
    ).length;
    const allCollaborators = overview.collaborators?.length;
    const collaboratorsAssignedPercentage =
      !allCollaborators || allCollaborators === 0
        ? 0
        : (assingedCollaborators / allCollaborators) * 100;
    
    let maximumCollaboratorsPercentage: number | null = null;    
    if (overview.maximumCollaborators) {
      maximumCollaboratorsPercentage =
      !allCollaborators || allCollaborators === 0
        ? 0
        : (allCollaborators / overview.maximumCollaborators) * 100;
    }

    return (
      <ScrollIntoView
        className="blade-content charcterOverview"
        path="/films/:filmId/collaborators/overview"
        loading={loading}>
        <h2 className="menu-label">Overview</h2>
        <Overview
          overview={overview}
          collaboratorsAssignedPercentage={collaboratorsAssignedPercentage}
          maximumCollaboratorsPercentage={maximumCollaboratorsPercentage}
          filmId={filmId}    
          errors={errors}
          warnings={warnings}
          ></Overview>
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !overview) && <Loader />}
      {!loading && overview && renderOverview(overview)}
    </>
  );
};

function mapStateToProps(state) {
  const { overview, loading } =
    state.usersState;
  return {    
    overview,
    loading    
  };
}

export default connect(mapStateToProps, {
  fetchCollaboratorsOverview
})(OverviewContainer);
