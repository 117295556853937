import { sortByName } from "../../../../shared/utility";
import { ADD_MAKEUP_TO_SCENE } from "./actions";

const defaultState = {
  makeup: {},
  makeups: [],
  loading: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_MAKEUPS_IN_SCENE_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_MAKEUPS_IN_SCENE_FULFILLED": {
      return {
        ...state,
        makeups: action.payload.data.makeups,
        loading: false,
        errors: null
      };
    }

    case ADD_MAKEUP_TO_SCENE + "_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case ADD_MAKEUP_TO_SCENE + "_FULFILLED": {
      const makeup = action.payload.data.makeup;
      const makeups = [...state.makeups, makeup];
      sortByName(makeups);
      const { fromBreakdown } = action.meta;
      return {
        ...state,
        makeups,
        saving: false,
        errors: null,
        redirect: { to: fromBreakdown ? "breakdown" : "list" }
      };
    }

    case ADD_MAKEUP_TO_SCENE + "_REJECTED": {
      return {
        ...state,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        character: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
