import React from 'react';
import Slider, { Range, SliderTooltip } from 'rc-slider';
import "rc-slider/assets/index.css";
import "./input-range.scss";
import { CSSProperties } from 'react';

const InputRange = (props: any) => {
    const { value, onChange, maxValue, minValue, disabled } = props;


      const onOnChange = (value) => {
        onChange({min: value[0], max: value[1]})
      };

      const handleStyle : CSSProperties[] = [{
        height: 16,
        width: 16,
        marginTop: -8,
        backgroundColor: "#947eed",
        border: 0
      },
      {
        height: 16,
        width: 16,
        marginTop: -8,
        backgroundColor: "#947eed",
        border: 0
      }];

      const Handle = Slider.Handle;

      const  handle = props => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value}`}                        
            visible={true}
            placement="top"            
          >
            <Handle value={props.value} style={{color: "red"}} {...restProps} />
          </SliderTooltip>
        );
      };

    return (
        <Range
          className="mt-3"
          defaultValue={[minValue, maxValue]}
          min={minValue}
          max={maxValue}        
          onChange={onOnChange}
          value={[+value.min, +value.max]}
          handleStyle={handleStyle}
          railStyle={{backgroundColor: "#040f21"}}
          trackStyle={[{backgroundColor: "#947eed"}]}
          handle={handle}
          allowCross={false}
          disabled={disabled}
      />
    )
}

export default InputRange;