import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchSceneBreakdown,
  downloadBreakdown,
  clearPdf,
  changeItem,
  changeCharacterToBackgroundCharacter,
  changeBackgroundCharacterToCharacter,
  changePropToSetDressing,
  changePropToCostume,
  changeSetDressingToProp,
  changeCostumeToProp,
  removeCharacterFromScene,
  removeBackgroundCharacterFromScene,
  removePropFromScene,
  removeSetDressingFromScene,
  removeCostumeFromScene,
  removeMakeupFromScene,
  removeVisualEffectFromScene
} from "./ducks/actions";
import Errors from "../../shared/errors";
import Loader from "../../shared/loader";
import confirm from "../../shared/modal/confirm";
import Scene from "./scene";

const EditContainer = (props) => {
  const filmId = props.match.params.filmId;
  const sceneId = props.match.params.sceneId;
  const {
    fetchSceneBreakdown,
    scene,
    loading,
    errors,
    readOnly,
    changeItem,
    changeCharacterToBackgroundCharacter,
    changeBackgroundCharacterToCharacter,
    changePropToSetDressing,
    changePropToCostume,
    changeSetDressingToProp,
    changeCostumeToProp,
    removeCharacterFromScene,
    removeBackgroundCharacterFromScene,
    removePropFromScene,
    removeSetDressingFromScene,
    removeCostumeFromScene,
    removeMakeupFromScene,
    removeVisualEffectFromScene,
    canAccessBackgroundCharacters,
    canAccessCharacters,
    canAccessCostumes,
    canAccessMakeups,
    canAccessProps,
    canAccessSetDressings,
    canAccessVisualEffects
  } = props;

  useEffect(() => {
    fetchSceneBreakdown(filmId, sceneId);
  }, [filmId, sceneId]);

  const onRemoveCharacterFromScene = (character) => {
    confirm(() => removeCharacterFromScene(filmId, sceneId, character.id), character.name, `Are you sure you want to remove ${character.name} from scene?`, "Remove");
  };

  const onRemoveBackgroundCharacterFromScene = (backgroundCharacter) => {
    confirm(() => removeBackgroundCharacterFromScene(filmId, sceneId, backgroundCharacter.id), backgroundCharacter.name, `Are you sure you want to remove ${backgroundCharacter.name} from scene?`, "Remove");
  };

  const onRemovePropFromScene = (prop) => {
    confirm(() => removePropFromScene(filmId, sceneId, prop.id), prop.name, `Are you sure you want to remove ${prop.name} from scene?`, "Remove");
  };

  const onRemoveSetDressingFromScene = (setDressing) => {
    confirm(() => removeSetDressingFromScene(filmId, sceneId, setDressing.id), setDressing.name, `Are you sure you want to remove ${setDressing.name} from scene?`, "Remove");
  };

  const onRemoveCostumeFromScene = (costume) => {
    confirm(() => removeCostumeFromScene(filmId, sceneId, costume.id), costume.name, `Are you sure you want to remove ${costume.name} from scene?`, "Remove");
  };

  const onRemoveMakeupFromScene = (makeup) => {
    confirm(() => removeMakeupFromScene(filmId, sceneId, makeup.id), makeup.name, `Are you sure you want to remove ${makeup.name} from scene?`, "Remove");
  };

  const onRemoveVisualEffectFromScene = (visualEffect) => {
    confirm(() => removeVisualEffectFromScene(filmId, sceneId, visualEffect.id), visualEffect.name, `Are you sure you want to remove ${visualEffect.name} from scene?`, "Remove");
  };

  function renderFilm(scene) {
    return (
      <div
        className="blade-content wider"
      >
        {errors && <Errors errors={errors} />}
        {loading && <Loader />}
        <Scene scene={scene}
          filmId={filmId}
          readOnly={readOnly}
          onDropItem={changeItem}
          changeCharacterToBackgroundCharacter={changeCharacterToBackgroundCharacter}
          changeBackgroundCharacterToCharacter={changeBackgroundCharacterToCharacter}
          changePropToSetDressing={changePropToSetDressing}
          changePropToCostume={changePropToCostume}
          changeSetDressingToProp={changeSetDressingToProp}
          changeCostumeToProp={changeCostumeToProp}
          deleteProp={onRemovePropFromScene}
          deleteCharacter={onRemoveCharacterFromScene}
          deleteBackgroundCharacter={onRemoveBackgroundCharacterFromScene}
          deleteSetDressing={onRemoveSetDressingFromScene}
          deleteCostume={onRemoveCostumeFromScene}
          deleteMakeup={onRemoveMakeupFromScene}
          deleteVisualEffect={onRemoveVisualEffectFromScene}
          canAccessBackgroundCharacters={canAccessBackgroundCharacters}
          canAccessCharacters={canAccessCharacters}
          canAccessCostumes={canAccessCostumes}
          canAccessMakeups={canAccessMakeups}
          canAccessProps={canAccessProps}
          canAccessSetDressings={canAccessSetDressings}
          canAccessVisualEffects={canAccessVisualEffects}
        />
      </div>
    );
  }

  return (
    <>

      {scene && renderFilm(scene)}
    </>
  );
};

function mapStateToProps(state) {
  return {
    errors: state.filmBreakdownState.errors,
    loading: state.filmBreakdownState.loading,
    scene: state.filmBreakdownState.scene,
    download: state.filmBreakdownState.download,
    progress: state.filmBreakdownState.progress,
    readOnly: state.filmBreakdownState.readOnly,
    canAccessBackgroundCharacters: state.filmBreakdownState.canAccessBackgroundCharacters,
    canAccessCharacters: state.filmBreakdownState.canAccessCharacters,
    canAccessCostumes: state.filmBreakdownState.canAccessCostumes,
    canAccessMakeups: state.filmBreakdownState.canAccessMakeups,
    canAccessProps: state.filmBreakdownState.canAccessProps,
    canAccessSetDressings: state.filmBreakdownState.canAccessSetDressings,
    canAccessVisualEffects: state.filmBreakdownState.canAccessVisualEffects,
  };
}

export default connect(mapStateToProps, {
  fetchSceneBreakdown,
  downloadBreakdown,
  clearPdf,
  changeItem,
  changeCharacterToBackgroundCharacter,
  changeBackgroundCharacterToCharacter,
  changePropToSetDressing,
  changePropToCostume,
  changeSetDressingToProp,
  changeCostumeToProp,
  removeCharacterFromScene,
  removeBackgroundCharacterFromScene,
  removePropFromScene,
  removeSetDressingFromScene,
  removeCostumeFromScene,
  removeMakeupFromScene,
  removeVisualEffectFromScene
})(EditContainer);
