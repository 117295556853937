import { Link } from "react-router-dom";
import Loader from "../../../shared/loader";

const CharacterProp = ({ characterProps, filmId, loading }) => {
    return (
        <div>
            <h2 className="menu-label">Prop</h2>
            {loading && <Loader />}
            {!loading && <>
                {!characterProps?.length && <div>None</div>}
                {(characterProps || []).map((prop: any, index: number) =>
                (
                    <div><Link to={`/films/${filmId}/props/${prop.id}`}>{prop.name}</Link></div>
                ))}
            </>}
        </div>
    )
}

export default CharacterProp;