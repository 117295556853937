import React from "react";
import { Link, Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import FormErrors from "../../../shared/form-errors";
import Errors from "../../../shared/errors";
import {
  renderSectionGroup,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField
} from "../../../shared/form-helpers";
import required from "../../../shared/validation";
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";
import FilmanizeRadio from "../../../shared/filmanize-radio";
import './collabortator-role.scss';
import { OnboardingStep1ClassName } from "./ducks/reducer";
import Onboarding from "../../../shared/onboarding/onboarding";

function CollaboratorRoleForm(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    handleDelete,
    mode,
    submitFailed,
    invalid,
    userAccessEnum,
    canEdit,
    sections,
    onboardingSteps,
    filmId,
    anyCollaboratorsInRole
  } = props;

  const thisRole = props.initialValues || {};
  const readOnly = thisRole.readOnly;

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Role</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Role</h2>}
      {errors && <Errors errors={errors} />}
      {mode === "edit" && anyCollaboratorsInRole && <div className="mb-1"> <Link to={`/films/${filmId}/collaborators/in-role/${thisRole.id}`}>Collaborators in role</Link></div>}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="field-body">
          <Field
            name="name"
            label="Name"
            component={renderTextField}
            validate={[required]}
            required
            readOnly={readOnly}
          />
        </div>

        <Field
          name="sectionPermissions"
          label="Sections"
          component={renderSectionGroup}
          readOnly={readOnly}
          sections={sections}
          headerClass={OnboardingStep1ClassName}
        />


        {!readOnly && (
          <div className="buttons sticky-buttons">
            <button
              type="submit"
              className={
                submitting
                  ? "button is-primary is-loading"
                  : "button is-primary"
              }
              disabled={pristine || submitting}>
              Save
            </button>
            <button
              type="button"
              className="button is-secondary"
              disabled={pristine || submitting}
              onClick={reset}>
              Reset Values
            </button>
            {/* !anyCollaboratorsInRole && */}
            {mode === "edit" && !readOnly && (
              <>
                <button
                  type="button"
                  className="button is-text"
                  disabled={submitting || !props.initialValues}
                  onClick={handleDelete}>
                  Delete
                </button>
              </>
            )}
          </div>
        )}
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
      <Onboarding onboardingSteps={onboardingSteps} section="Collaborator Role" />
    </>
  );
}

export default reduxForm({
  form: "collaboratorRoleForm",
  enableReinitialize: true
})(CollaboratorRoleForm);
