import getApiClient from "./../../../../shared/api";

export const FETCH_FILM_CREW_MEMBERS_OVERVIEW = "FETCH_FILM_CREW_MEMBERS_OVERVIEW";

export function fetchFilmCrewMembersOverview(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_FILM_CREW_MEMBERS_OVERVIEW,
      payload: getApiClient().get(`/film/${filmId}/CrewMember/Overview`),
    });
  };
}