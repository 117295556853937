import React from "react";

import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import InputSlider from "../../shared/controls/input-slider";
import CurrencyDisplay from "../../shared/currency-display";
import CurrencyInputContainer from "../../shared/currency-input-container";

function BackgroundCharacter(props) {
  const {
    item,
    onChangeBuget,
    onChangeDays,
    maxDays,
    hasShootingDays,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <tr>
      <td><Link to={`/films/${filmId}/background-characters/${item.id}/edit`}>{item.name}</Link></td>
      <td className="budget-days-column">
        {hasShootingDays ? (
          item.days
        ) : (
          <InputSlider
            step={1}
            formatLabel={(value) => `${value}`}
            draggableTrack={false}
            allowSameValues={false}
            maxValue={maxDays}
            minValue={1}
            value={item.days}
            onChange={(val) => onChangeDays(item.id, val)}
            onChangeComplete={(args) => console.log(args)}
            disabled={readOnly || saving}
          />
        )}
      </td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={item.dailyBudget}
          onChange={(val) => onChangeBuget(item.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-centered">{item.quantity}</td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={item.total}
        />
      </td>
    </tr>
  );
}

export default BackgroundCharacter;
