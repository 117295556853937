import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { UPLOAD_CREW, UPLOAD_CREW_CLEAR_ERRORS } from "./actions";

interface ActionState {
  actors: any[];
  loading: boolean;
  actor: any;
  imageFiles: any[];
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  isUploading: boolean
}

export const OnboardingStep1ClassName = 'actor-upload-onboarding-step-1';
export const OnboardingStep2ClassName = 'actor-upload-onboarding-step-2';

const defaultState: ActionState = {
  actors: [],
  loading: false,
  actor: {},
  imageFiles: [],
  readOnly: false,
  isUploading: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Upload',
      content: `        
      <p>Use this to import crew from other systems.</p>
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Download',
      content: `        
      <p>Download the example file to see how you need to format the file for upload.</p>
      `
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case UPLOAD_CREW + "_PENDING": {
      return {
        ...state,
        loading: true,
        isUploading: true,
        errors: null,
        warnings: null
      };
    }

    case UPLOAD_CREW + "_FULFILLED": {
      return {
        ...state,
        actors: action.payload.data.actors,
        readOnly: action.payload.data.readOnly,
        loading: false,
        isUploading: false,
        errors: null,
        warnings: action.payload.data.warnings,
        showSmartDays: false
      };
    }

    case UPLOAD_CREW + "_REJECTED": {
      return {
        ...state,
        loading: false,
        isUploading: false,
        errors: action.payload.response.data.errors,
        warnings: action.payload.response.data.warnings
      };
    }

    case UPLOAD_CREW_CLEAR_ERRORS: {
      return {
        ...state,
        errors: null,
        warnings: null
      };
    }

    default:
      return state;
  }
};

export default reducer;

