import { Field, reduxForm } from "redux-form";
import { useTranslation } from 'react-i18next';
import { CirclePicker } from 'react-color';
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {  
  renderCircleColorPicker,
  renderSingleReactSelect,
  renderTextField
} from "./../../shared/form-helpers";
import required from "./../../shared/validation";

function UpdateUserForm(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    submitFailed,
    invalid,
    countries,
    languages,
    currencies
  } = props;

  const { i18n } = useTranslation();

  const countryOptions = (countries || []).map((country) => {
    return { value: country.id, label: country.name };
  });

  const languageOptions = (languages || []).map((language) => {
    return { value: language.id, label: language.name };
  });

  const currencyOptions =(currencies || []).map((currency) => {    
    return { value: currency.id, label: currency.name };
  });

const onChangeLanguageHandler = (value) => {  
  const language = languages.find((language: any) => language.id === value);
  i18n.changeLanguage(language.code);
};

  return (
    <>
      <h2 className="menu-label">Update Details</h2>
      {errors && <Errors errors={errors} />}

      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="emailAddress"
          label="Email Address"
          component={renderTextField}
          validate={[required]}
          required
        />
        <div className="field-body">
          <Field
            name="firstName"
            label="First Name"
            component={renderTextField}
            validate={[required]}
            required
          />
          <Field
            name="lastName"
            label="Last Name"
            component={renderTextField}
            validate={[required]}
            required
          />
        </div>

        <Field
          name="countryId"
          label="County"
          component={renderSingleReactSelect}
          options={countryOptions}
          validate={[required]}
          required></Field>

        <Field
          name="languageId"
          label="Language"
          component={renderSingleReactSelect}
          onChange={onChangeLanguageHandler}
          options={languageOptions}
          validate={[required]}
          required></Field>

        <Field
          name="currencyId"
          label="Currency"
          component={renderSingleReactSelect}
          options={currencyOptions}
          validate={[required]}
          required></Field>

        <Field
          name="profileBackgroundColor"
          label="Profile Background Color"
          component={renderCircleColorPicker}
          ></Field>
        
        <div className="buttons">
          <button
            type="submit"
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={pristine || submitting}>
            Update
          </button>
          <button
            type="button"
            className="button is-secondary"
            disabled={pristine || submitting}
            onClick={reset}>
            Reset Values
          </button>
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true
})(UpdateUserForm);
