import React from "react";
import { NavLink } from "react-router-dom";

const BackgroundCharactersList = ({ backgroundCharacters, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Background Characters</p>

      <ul className="menu-list">  
      <li>
          <NavLink
            to={`/films/${filmId}/background-characters/overview`}
            activeClassName="is-active"
          >
            Overview
          </NavLink>
        </li>
        <hr />   
        {(backgroundCharacters || []).map((backgroundCharacter, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/background-characters/${backgroundCharacter.id}/edit`}
                activeClassName="is-active"
                title={backgroundCharacter.name}
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/background-characters/${backgroundCharacter.id}/`
                  );
                }}
              >
                {backgroundCharacter.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default BackgroundCharactersList;
