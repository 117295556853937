import React from "react";
import { NavLink } from "react-router-dom";
import Errors from "./../../shared/errors";

function ConfirmEmailForm(props) {
  const {    
    errors,
    enabled
  } = props;
  
  return (
    <>
        <h2 className="menu-label">Confirm Email</h2>
        {errors && <Errors errors={errors} />}
        {!errors && 
        <div>
          {enabled && 
            <p>Thanks for confirming your email address – you're ready to start using Filmanize. Please login below.</p>
          }
          {!enabled && 
            <p>Thanks for confirming your email address – you'll be ready to start using Filmanize once your account has been approved.</p>
          }
          <NavLink to="/login">Login</NavLink>
        </div>}      
    </>
  );
}

export default ConfirmEmailForm;
