import React from "react";
import { NavLink } from "react-router-dom";

const PropsList = ({ filmId, sceneId, sceneMakeups }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Makeup in scene</p>
      <ul className="menu-list">
        {(sceneMakeups || []).map((makeup, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/makeup/${makeup.id}/edit`}
                activeClassName="is-active"
              >
                {makeup.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default PropsList;
