import getApiClient from "./../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../notifications/ducks/actions";

export const FETCH_SCHEDULE_PROGRESS = "FETCH_SCHEDULE_PROGRESS";
export const FETCH_SCHEDULE = "FETCH_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const SCHEDULING_CHANGE_DAY_OF_WEEK = "SCHEDULING_CHANGE_DAY_OF_WEEK";
export const SCHEDULING_UPDATE_EVENT = "SCHEDULING_UPDATE_EVENT";
export const SCHEDULING_ADD_EVENTS = "SCHEDULING_ADD_EVENTS"
export const SCHEDULING_DELETE_EVENT = "SCHEDULING_DELETE_EVENT";
export const SCHEDULING_DELETE_EVENTS = "SCHEDULING_DELETE_EVENTS"
export const SET_POTENTIAL_DAYS_WHERE_LOCATIONS_AVAILABLE = "SET_POTENTIAL_DAYS_WHERE_LOCATIONS_AVAILABLE";

export function fetchScheduleProgress(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SCHEDULE_PROGRESS,
      payload: getApiClient().get(`/film/${filmId}/scheduling/progress`),
    });
  };
}

export function fetchSchedule(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SCHEDULE,
      payload: getApiClient().get(`/film/${filmId}/scheduling`),
    });
  };
}

export function saveSchedule(filmId, days, daysOfWeek) {
  const command = { days, daysOfWeek };
  return (dispatch) => {
    dispatch({
      type: UPDATE_SCHEDULE,
      payload: getApiClient().put(`/film/${filmId}/scheduling`, command),
    })
      .then((response) => {
        dispatch(showInfo(`Updated schedule`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating schedule`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changeDayOfWeek(dayOfWeek, selected) {
  return (dispatch) => {
    dispatch({
      type: SCHEDULING_CHANGE_DAY_OF_WEEK,
      meta: { dayOfWeek, selected }
    })
  }
};

export function updateEvent(plainEventObject) {
  return (dispatch) => {
    dispatch({
      type: SCHEDULING_UPDATE_EVENT,
      plainEventObject
    })
  }
};

export function addDays(startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SCHEDULING_ADD_EVENTS,
      meta: { startDate, endDate }
    })
  }
};

export function deleteDay(dayId) {
  return (dispatch) => {
    dispatch({
      type: SCHEDULING_DELETE_EVENT,
      meta: { dayId }
    })
  }
};

export function deleteDays(startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SCHEDULING_DELETE_EVENTS,
      meta: { startDate, endDate }
    })
  }
};

export function setPotentialDaysWhereLocationsAvailable() {
  return (dispatch) => {
    dispatch({
      type: SET_POTENTIAL_DAYS_WHERE_LOCATIONS_AVAILABLE
    })
  }
};
