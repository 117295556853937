import React from "react";
import InputSlider from "../../shared/controls/input-slider";
import NumberFormat from "react-number-format";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function Makeup(props) {
  const {
    makeup,
    onChangeMakeupBuget,
    onChangeMakeupDays,
    maxDays,
    hasShootingDays,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <tr>
      <td><Link to={`/films/${filmId}/makeup/${makeup.id}/edit`}>{makeup.name}</Link></td>
      <td className="budget-days-column">
        {hasShootingDays ? (
          makeup.days
        ) : (
          <InputSlider
            step={1}
            formatLabel={(value) => `${value}`}
            draggableTrack={false}
            allowSameValues={false}
            maxValue={maxDays}
            minValue={1}
            value={makeup.days}
            onChange={(val) => onChangeMakeupDays(makeup.id, val)}
            onChangeComplete={(args) => console.log(args)}
            disabled={readOnly || saving}
          />
        )}
      </td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={makeup.dailyBudget}
          onChange={(val) => onChangeMakeupBuget(makeup.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-centered">{makeup.quantity}</td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={makeup.total}
        />
      </td>
    </tr>
  );
}

export default Makeup;
