import { connect } from "react-redux";
import BoxWraper from "../box-wrapper";
import {
  fetchAccount,
  fetchUserPricingPlans,
  changePricingPlan,
  updateAccount,
  pausePayment,
  resumePayment,
  cancelPayment,
  unCancelPayment
} from "./ducks/actions";

import Account from "./account";
import { useEffect } from "react";
import Loader from "../../shared/loader";

const AccountContainer = (props) => {
  const {
    fetchAccount,
    fetchPricingPlans,
    changePricingPlan,
    updateAccount,
    loading,
    updating,
    errors,
    account,
    pricingPlans,
    pausePayment,
    resumePayment,
    cancelPayment,
    unCancelPayment
  } = props;

  useEffect(() => {
    fetchAccount();
    fetchPricingPlans();
  }, []);

  const onChangePricingPlan = (pricingPlanType: any) => {
    changePricingPlan(pricingPlanType)
  };

  const onUpdate = () => {
    updateAccount(account);
  };


  return (
    <BoxWraper width={5}>
      {(loading || updating) && <Loader />}
      {!loading &&
        <Account
          errors={errors}
          account={account}
          pricingPlans={pricingPlans}
          changePricingPlan={onChangePricingPlan}
          update={onUpdate}
          pausePayment={pausePayment}
          resumePayment={resumePayment}
          cancelPayment={cancelPayment}
          unCancelPayment={unCancelPayment} />}
    </BoxWraper>
  )
}

function mapStateToProps(state) {
  const { accountState } = state;
  return {
    account: accountState.accountState.account,
    pricingPlans: accountState.accountState.pricingPlans,
    errors: accountState.accountState.errors,
    loading: accountState.accountState.loading,
    updating: accountState.accountState.updating
  };
}

export default connect(mapStateToProps, {
  fetchAccount,
  fetchPricingPlans: fetchUserPricingPlans,
  changePricingPlan,
  updateAccount,
  pausePayment,
  resumePayment,
  cancelPayment,
  unCancelPayment
})(AccountContainer);