import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "./../../shared/loader";
import confirm from "../../shared/modal/confirm";
import { fetchLocationsWithSubLocations } from "./../locations/ducks/actions";
import {
  clearRedirect,
  clearSceneSetting,
  deleteSceneSetting,
  fetchSceneSetting,
  initAddLocationForm,
  initEditLocationForm,
  updateSceneSetting,
  uploadImagesAndSaveSceneSetting
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function EditSceneSetting(props) {
  const { filmId, sceneSettingId } = props.match.params;
  const {
    fetchSceneSetting,
    clearSceneSetting,
    uploadImagesAndSaveSceneSetting,
    addSceneSetting,
    updateSceneSetting,
    deleteSceneSetting,
    fetchLocationsWithSubLocations,
    loading,
    loadingSceneSetting,
    sceneSetting,
    errors
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {    
    if (invalidIds.indexOf(sceneSettingId) === -1) {
      fetchSceneSetting(filmId, sceneSettingId);      
      fetchLocationsWithSubLocations(filmId);
    }
  }, [filmId, sceneSettingId]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/scene-settings/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(sceneSettingId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderSceneSetting(sceneSetting) {
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/scene-settings/:sceneSettingId/edit"
        loading={loadingSceneSetting}>
        <Form
          errors={errors}
          mode="edit"
          scenesettingId={sceneSetting.id}
          initialValues={sceneSetting}
          loading={loadingSceneSetting}
          onSubmit={onUpdateSceneSetting}
          handleDelete={onDeleteSceneSetting}
          filmId={filmId}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateSceneSetting(scenesetting) {
    scenesetting.gender = +scenesetting.gender; // Converting string to int
    scenesetting.minimumAge = scenesetting.minimumAge
      ? +scenesetting.minimumAge
      : null;
    scenesetting.maximumAge = scenesetting.maximumAge
      ? +scenesetting.maximumAge
      : null;

    const addMode = !scenesetting.id;

    const files = scenesetting.pictures || [];

    if ((files && files.length) > 0 || (scenesetting.images && scenesetting.images.length > 0)) {
      uploadImagesAndSaveSceneSetting(scenesetting, files, addMode, filmId);
    } else {
      if (!scenesetting.images) {
        scenesetting.images = [];
      }

      if (addMode) addSceneSetting(filmId, scenesetting);
      else updateSceneSetting(filmId, scenesetting);
    }
  }

  function onDeleteSceneSetting(scenesetting) {
    confirm(() => deleteSceneSetting(filmId, scenesetting), scenesetting?.name);
  }

  return sceneSetting?.id && !loadingSceneSetting
    ? renderSceneSetting(sceneSetting)
    : renderLoading();
}

function mapStateToProps(state) {
  const { sceneSettingsState, locationsState } = state;
  return {
    sceneSetting: sceneSettingsState.rootState.sceneSetting,
    loadingSceneSetting: sceneSettingsState.rootState.loadingSceneSetting,
    locationsWithSubLocations: locationsState.rootState.locationsWithSubLocations,
    errors: sceneSettingsState.rootState.errors,
    redirect: sceneSettingsState.rootState.redirect
  };
}

export default connect(mapStateToProps, {
  fetchSceneSetting,
  clearSceneSetting,
  uploadImagesAndSaveSceneSetting,
  updateSceneSetting,
  deleteSceneSetting,
  fetchLocationsWithSubLocations,
  initAddLocationForm,
  initEditLocationForm,
  clearRedirect
})(EditSceneSetting);
