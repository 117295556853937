import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export function fetchCharactersInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHARACTERS_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/characters/IncludeOffScene`),
    });
  };
}

export function fetchCharactersInSceneNotOffScreen(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHARACTERS_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/characters`),
    });
  };
}

export function clearCharacterInScene() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_CHARACTER_IN_SCENE",
    });
  };
}

export function fetchCharacter(filmId, sceneId, characterId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_CHARACTER_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/Character/${characterId}`),
    });
  };
}

export function updateCharacter(filmId, sceneId, character) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_CHARACTER_IN_SCENE",
      payload: getApiClient().put(`/film/${filmId}/Scene/${sceneId}/Character/${character.characterId}`, character),
      meta: { character },
    })
      .then((response) => {
        dispatch(showInfo(`Updated character in scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating character in scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addCharacter(filmId, sceneId, character, fromBreakdown) {
  character.mainImageUrl = null;
  character.imageUrls = [];

  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_CHARACTER_TO_SCENE",
      payload: getApiClient().post(`/film/${filmId}/Scene/${sceneId}/Character/${character.characterId}`, character),
      meta: { character, fromBreakdown },
    })
      .then((response) => {
        dispatch(showInfo(`Added character to scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding character`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteCharacter(filmId, sceneId, character) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_CHARACTER_IN_SCENE",
      payload: getApiClient().delete(`/film/${filmId}/Scene/${sceneId}/Character/${character.characterId}`),
      meta: { character },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted character from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting character from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
