import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../../shared/loader";
import { fetchProps } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListPropsContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchProps, loading, propItems, errors, readOnly } = props;

  useEffect(() => {
    fetchProps(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderProps(propItems, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/props"
        loading={loading}>
        <List propItems={propItems} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/props/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Prop
        </NavLink>}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderProps(propItems, filmId);
}

function mapStateToProps(state) {
  const { props, loading, errors, readOnly } = state.propsState;
  return { propItems: props, loading, errors, readOnly };
}

export default connect(mapStateToProps, {
  fetchProps
})(ListPropsContainer);
