import React from "react";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

const Home = (props) => {
  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/availability"
      loading={false}>
      <h2 className="menu-label">Scheduling</h2>
      <div className="notification">
        Coming soon. This section will allow actors and crew to enter their availability, which will enable you to schedule the shoot for each scene.
      </div>
    </ScrollIntoView>
  );
};

export default Home;
