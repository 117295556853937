interface ConfirmEmailState {
  loading: boolean;
  user: any;
  enabled: boolean;
}

const defaultState: ConfirmEmailState = {
  loading: false,
  user: {},
  enabled: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
 
    case "CONFIRM_EMAIL_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }
    
    case "CONFIRM_EMAIL_FULFILLED": {   
      return {
        ...state,      
        loading: false,
        errors: null,
        enabled: action.payload.data.enabled
      };
    }

    case "CONFIRM_EMAIL_REJECTED": {        
      return {
        ...state,      
        loading: false,
        errors: action.payload.response.data.errors        
      };
    }

    default:
      return state;
  }
};

export default reducer;
