import React from "react";
import { NavLink } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

const PropsList = ({ filmId, sceneId, sceneEquipmentCategories }) => {
  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/scenes/:sceneId/equipment"
    >
      <p className="menu-label">Scene Equipment</p>
      <table className="table">
        <thead>
          <tr>
            <th>Equipment</th>
          </tr>
        </thead>
        <tbody>

          {(sceneEquipmentCategories || []).map((equipmentCategory, idx) => {
            return (
              <>
                <tr>
                  <td>{equipmentCategory.categoryName}</td>
                </tr>
                {equipmentCategory.equipment.map((equipment, equipmentIndex) => {
                  return (
                    <tr key={equipmentIndex}>
                      <td>
                        <NavLink
                          to={`/films/${filmId}/equipment-category/${equipmentCategory.categoryId}/equipment/${equipment.id}/edit`}
                          activeClassName="is-active"
                        >
                          {equipment.name}
                        </NavLink>
                      </td>
                    </tr>
                  )
                })}
              </>
            );
          })}
        </tbody>
      </table>
    </ScrollIntoView>
  );
};

export default PropsList;
