import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { FETCH_RISK_ASSESSMENTS, 
         UPLOAD_RISK_ASSESSMENT, 
         UPLOAD_DAILY_RISK_ASSESSMENT, 
         UPLOAD_RISK_ASSESSMENT_ERROR, 
         DELETE_RISK_ASSESSMENT,
         DELETE_DAILY_RISK_ASSESSMENT } from "./actions";

interface TasksState {
  riskAssessments: any[];   
  dailyRiskAssessments: any[];
  loading: boolean;
  saving: boolean;  
  readOnly: boolean; 
  errors: any,
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'risk-assessment-onboarding-step-1';
export const OnboardingStep2ClassName = 'risk-assessment-onboarding-step-2';

const defaultState: TasksState = {
  loading: false,
  saving: false,
  readOnly: false,
  riskAssessments: [],
  dailyRiskAssessments: [],
  errors: null,
  onboardingSteps: [      
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Risk Assessments',
      content: `        
      <p>The risk assessments will be included in the call sheet email to actors and crew. The every day ones will be sent for each day and will appear in this section for each day.</p>
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'This Day Risk Assessments',
      content: `        
      <p>This day risk assessents are ones that are specific to this day.</p>
      `
    }
  ]
};


const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_RISK_ASSESSMENTS + "_PENDING": {
      return {
        ...state,
        riskAssessments: null,
        loading: true,
        errors: null,
      };
    }

    case FETCH_RISK_ASSESSMENTS + "_FULFILLED": {            
      return {
        ...state,
        riskAssessments: action.payload.data.riskAssessments,
        dailyRiskAssessments: action.payload.data.dailyRiskAssessments,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null,
      };
    }

    case FETCH_RISK_ASSESSMENTS + "_REJECTED": {
      return {
        ...state,    
        riskAssessments: null,
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    case UPLOAD_RISK_ASSESSMENT + "_PENDING": {
      return {
        ...state,    
        loading: true,
        errors: null
      };
    }

    case UPLOAD_RISK_ASSESSMENT + "_FULFILLED": {
      const riskAssessments = Array.from(state.riskAssessments);
      const riskAssessment = action.payload.data.riskAssessment;
      const found = riskAssessments.find(f => f.id === riskAssessment.id);
      if (found == null) {
        riskAssessments.push(riskAssessment);
        riskAssessments.sort((a, b) => {
          return a.fileName.toLowerCase().localeCompare(b.fileName.toLowerCase());
        });
      } else {
        found.fileSize = riskAssessment.fileSize;
      }

      return {
        ...state,
        riskAssessments: riskAssessments,
        loading: false,
        errors: null,
      };
    }

    case UPLOAD_RISK_ASSESSMENT + "_REJECTED": {
      return {
        ...state,    
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    case UPLOAD_DAILY_RISK_ASSESSMENT + "_PENDING": {
      return {
        ...state,    
        loading: true,
        errors: null
      };
    }

    case UPLOAD_DAILY_RISK_ASSESSMENT + "_FULFILLED": {
      const dailyRiskAssessments = Array.from(state.dailyRiskAssessments);
      const riskAssessment = action.payload.data.riskAssessment;
      const found = dailyRiskAssessments.find(f => f.id === riskAssessment.id);
      if (found == null) {
        dailyRiskAssessments.push(riskAssessment);
        dailyRiskAssessments.sort((a, b) => {
          return a.fileName.toLowerCase().localeCompare(b.fileName.toLowerCase());
        });
      } else {
        found.fileSize = riskAssessment.fileSize;
      }

      return {
        ...state,
        dailyRiskAssessments,
        loading: false,
        errors: null,
      };
    }

    case UPLOAD_DAILY_RISK_ASSESSMENT + "_REJECTED": {
      return {
        ...state,    
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    case UPLOAD_RISK_ASSESSMENT_ERROR: {
      return {
        ...state,
        script: null,
        errors: action.meta.errors
      };
    }

    case DELETE_RISK_ASSESSMENT + "_PENDING": {
      return {
        ...state,        
        loading: true,
        errors: null,
      };
    }

    case DELETE_RISK_ASSESSMENT + "_FULFILLED": {            
      const { fileId } = action.meta;
      const riskAssessments = Array.from(state.riskAssessments);
      const fileIndex = riskAssessments.findIndex(f => f.id === fileId);
      riskAssessments.splice(fileIndex, 1);
      return {
        ...state,
        riskAssessments: riskAssessments,
        loading: false,
        errors: null,
      };
    }

    case DELETE_RISK_ASSESSMENT + "_REJECTED": {
      return {
        ...state,            
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    case DELETE_DAILY_RISK_ASSESSMENT + "_PENDING": {
      return {
        ...state,        
        loading: true,
        errors: null,
      };
    }

    case DELETE_DAILY_RISK_ASSESSMENT + "_FULFILLED": {            
      const { fileId } = action.meta;      
      const dailyRiskAssessments = Array.from(state.dailyRiskAssessments);
      const fileIndex = dailyRiskAssessments.findIndex(f => f.id === fileId);
      dailyRiskAssessments.splice(fileIndex, 1);
      return {
        ...state,
        dailyRiskAssessments,
        loading: false,
        errors: null,
      };
    }

    case DELETE_DAILY_RISK_ASSESSMENT + "_REJECTED": {
      return {
        ...state,            
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    default:
      return state;
  }
};

export default reducer;
