import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../shared/modal/confirm";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../shared/loader";
import {
  clearRedirect,
  deleteProp,
  fetchProp,
  updateProp,
  uploadImagesAndSaveProp
} from "./ducks/actions";
import Form from "./form";

function EditProp(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const visualEffectId = props.visualEffectId || props.match.params.visualEffectId;

  const {
    fetchProp,
    updateProp,
    uploadImagesAndSaveProp,
    deleteProp,
    clearRedirect,
    loadingProp,
    visualEffect,
    errors,
    embedded
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {
    if (invalidIds.indexOf(visualEffectId) === -1) {
      fetchProp(filmId, visualEffectId);
    }
  }, [filmId, visualEffectId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      clearRedirect();
      const url = `/films/${filmId}/visual-effects/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "set-dressing") {
      props.clearRedirect();
      const url = `/films/${filmId}/set-dressings/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "costume") {
      props.clearRedirect();
      const url = `/films/${filmId}/costumes/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(visualEffectId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderProp(prop) {
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/visual-effects/:visualEffectId/edit"
        loading={loadingProp}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          propId={prop.id}
          initialValues={prop}
          loading={loadingProp}
          onSubmit={onUpdateProp}
          handleDelete={onDeleteProp}
          reduxFormValues={props.reduxFormValues}
          acquisitionMethodEnum={props.acquisitionMethodEnum}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateProp(prop) {
    const files = prop.pictures || [];

    if ((files && files.length) > 0 || (prop.images && prop.images.length > 0)) {
      uploadImagesAndSaveProp(prop, files, false, filmId);
    } else {
      updateProp(filmId, prop);
    }
  }

  function onDeleteProp(visualEffect) {
    confirm(() => deleteProp(filmId, visualEffect), visualEffect?.name);
  }

  return visualEffect && !loadingProp ? renderProp(visualEffect) : renderLoading();
}

function mapStateToProps(state) {
  const { visualEffectsState, commonState } = state;
  return {
    visualEffect: visualEffectsState.visualEffect,
    errors: visualEffectsState.errors,
    redirect: visualEffectsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod
  };
}

export default connect(mapStateToProps, {
  fetchProp,
  updateProp,
  deleteProp,
  clearRedirect,
  uploadImagesAndSaveProp
})(EditProp);
