import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {
  renderCheckbox,
  renderSelectField,
  renderSingleReactSelect,
  renderTextField
} from "./../../shared/form-helpers";
import required from "./../../shared/validation";
import WarningText from "../../shared/warning-text";
import ReCaptureInfo from "../recapture-info";

const validate = (values) => {
  const errors: any = {};
  if (!values.agree) {
    errors.agree = "Required";
  }

  return errors;
};

function RegisterForm(props) {
  const {
    handleSubmit,
    countries,
    languages,
    currencies,
    pristine,
    reset,
    submitting,
    errors,
    submitFailed,
    invalid,
    showReferrer
  } = props;

  const countryOptions = (countries || []).map((item) => {
    return { value: item.id, label: item.name };
  });

  const languageOptions = (languages || []).map((item) => {
    return { value: item.id, label: item.name };
  });

  const currencyOptions = (currencies || []).map((currency) => {
    return { value: currency.id, label: currency.name };
  });

  return (
    <>
      <h2 className="menu-label">Register</h2>
      {errors && <Errors errors={errors} />}
      <WarningText>
        Filmanize is currently in beta, which means there might be a few issues here and there. If you do spot any, then please use the contact form in the help menu to make us aware of the issue and we will endeavour to fix it as soon as possible.
      </WarningText>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="emailAddress"
          label="Email Address"
          component={renderTextField}
          validate={[required]}
          required
        />
        <div className="field-body">
          <Field
            name="firstName"
            label="First Name"
            component={renderTextField}
            validate={[required]}
            required
          />
          <Field
            name="lastName"
            label="Last Name"
            component={renderTextField}
            validate={[required]}
            required
          />
        </div>

        <div className="field-body">
          <Field
            name="password"
            label="Password"
            type="password"
            component={renderTextField}
            validate={[required]}
            required
          />
          <Field
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            component={renderTextField}
            validate={[required]}
            required
          />
        </div>

        {showReferrer &&
          <Field
            name="referrer"
            label="Referrer"
            component={renderTextField}
            validate={[required]}
            required
            infoText="This is the name of the person or website that gave you a link to this site. We use referrers to evaluate users for Filmanize's beta programme."
          />
        }

        <Field
          name="countryId"
          label="County"
          component={renderSingleReactSelect}
          options={countryOptions}
          validate={[required]}
          required />

        <Field
          name="languageId"
          label="Language"
          component={renderSingleReactSelect}
          options={languageOptions}
          validate={[required]}
          required />

        <Field
          name="currencyId"
          label="Currency"
          component={renderSingleReactSelect}
          options={currencyOptions}
          validate={[required]}
          required />

        <div className="mb-1">
          <Field
            name="emailNewsletter"
            label={
              <span>Receive email newsletter</span>
            }
            component={renderCheckbox}
          />
        </div>

        <Field
          name="agree"
          label={
            <span>I agree to the terms and conditions and privacy policy</span>
          }
          component={renderCheckbox}
          validate={[required]}
          required
        />
        <Link to={"/terms-and-conditions"} target="_blank">
          Terms and conditions
        </Link>
        <br />
        <Link to={"/privacy-policy"} target="_blank">
          Privacy Policy
        </Link>



        <div className="buttons mt-1">
          <button
            type="submit"
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={pristine || submitting}>
            Register
          </button>
          <button
            type="button"
            className="button is-secondary"
            disabled={pristine || submitting}
            onClick={reset}>
            Reset Values
          </button>
        </div>
        <ReCaptureInfo />
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true,
  validate: validate
})(RegisterForm);
