import * as React from "react";
import CurrencyInput from "./currency-input";

const FilmanizeCurrencyField: React.FC<any> = ({
  label,
  placeholder,
  required,
  value,
  className,
  readOnly,
  disabled,
  onChange,
  currencySymbol
}) => {

  if (!placeholder) {
    placeholder = label;
  }

  return (
    <div className={`field ${className}`}>
      <label className={"label " + (readOnly || disabled ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="field-body">
        <CurrencyInput
          placeholder={label}
          label={label}
          currencySymbol={currencySymbol}
          value={value}
          onChange={onChange}
          disabled={disabled || readOnly}
        />
      </div>
    </div>
  );
};

export default FilmanizeCurrencyField;
