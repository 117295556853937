import React from "react";
import { confirmAlert } from "react-confirm-alert";

export default function Confirm(
  onConfirm,
  name,
  deleteMessage: string | null = null,
  title: string | null = null,
  onNo: any = null
) {
  
  const message = deleteMessage
    ? deleteMessage
    : `Are you sure you want to delete ${name}?`;
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="modal is-active">
          <div className="modal-background" onClick={onClose}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{title ? title : "Delete"}</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => onClose()}></button>
            </header>
            <section className="modal-card-body">{message}</section>
            <footer className="modal-card-foot">
              {onConfirm && (
                <>
                  <button
                    className="button is-primary"
                    onClick={() => {
                      onConfirm();
                      onClose();
                    }}>
                    Yes
                  </button>
                  <button className="button" onClick={() => { 
                    if (onNo) {
                      onNo();
                    }
                    onClose();}}>
                    No
                  </button>
                </>
              )}
              {!onConfirm && (
                <button className="button" onClick={() => onClose()}>
                  Ok
                </button>
              )}
            </footer>
          </div>
        </div>
      );
    }
  });
}
