import getApiClient from "../../../../shared/api";

export const DOWNLOAD_SHOOTING_DAY_CATERING = "DOWNLOAD_SHOOTING_DAY_CATERING";
export const CLEAR_SHOOTING_DAY_CATERING_PDF = "CLEAR_SHOOTING_DAY_CATERING_PDF";

export function fetchShootingDayCatering(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_CATERING",
      payload: getApiClient().get(`/film/${filmId}/ShootingDay/${shootingDayId}/Catering`),
    });
  };
}


export function downloadShootingDayCatering(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: DOWNLOAD_SHOOTING_DAY_CATERING,
      payload: getApiClient().post(`/film/${filmId}/ShootingDay/${shootingDayId}/Catering/Download`, {}),
    });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SHOOTING_DAY_CATERING_PDF,
      meta: {},
    });
  };
}