import { UPLOAD_CREW } from "../../upload/ducks/actions";
import { sortByName } from "./../../../../shared/utility";

const defaultState = {
  departments: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "CLEAR_DEPARTMENT": {
      return {
        ...state,
        errors: null
      };
    }

    case "FETCH_DEPARTMENTS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_DEPARTMENTS_FULFILLED": {
      return {
        ...state,
        departments: action.payload.data.departments,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_DEPARTMENT_PENDING": {
      return {
        ...state,
        department: null,
        loadingDepartment: true,
        errors: null
      };
    }

    case "FETCH_SINGLE_DEPARTMENT_FULFILLED": {
      return {
        ...state,
        department: action.payload.data,
        loadingDepartment: false,
        errors: null
      };
    }

    case "UPDATE_SINGLE_DEPARTMENT_PENDING": {
      return {
        ...state,
        department: action.payload,
        loadingDepartment: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_DEPARTMENT_FULFILLED": {
      const department = {
        ...action.meta.department,
        ...action.payload.data.department
      };
      const departments: any = state.departments.map(
        (c: any, index: number) => {
          if (c.id === department.id) {
            return { ...c, ...department };
          } else {
            return c;
          }
        }
      );
      sortByName(departments);
      return {
        ...state,
        department,
        departments,
        loadingDepartment: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_DEPARTMENT_REJECTED": {
      return {
        ...state,
        department: action.meta.department,
        errors: action.payload.response.data.errors,
        loadingDepartment: false
      };
    }

    case "ADD_SINGLE_DEPARTMENT_PENDING": {
      return {
        ...state,
        department: action.payload,
        errors: null,
        loadingDepartment: true
      };
    }

    case "ADD_SINGLE_DEPARTMENT_FULFILLED": {
      const department: any = action.payload.data.crewDepartment;

      let departments = [department, ...state.departments];
      sortByName(departments);
      return {
        ...state,
        department,
        departments,
        loadingDepartment: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_DEPARTMENT_REJECTED": {
      return {
        ...state,
        department: action.meta.department,
        errors: action.payload.response.data.errors,
        loadingDepartment: false
      };
    }

    case UPLOAD_CREW + "_FULFILLED": {
      const newDepartments: any = action.payload.data.departments;
      let departments = [...state.departments, ...newDepartments];
      sortByName(departments);
      return {
        ...state,
        departments
      };
    }

    case "DELETE_SINGLE_DEPARTMENT_PENDING": {
      return {
        ...state,
        department: action.payload,
        loadingDepartment: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_DEPARTMENT_FULFILLED": {
      const department = action.meta.department;
      const departments: any = state.departments.filter(
        (c: any, index: number) => {
          return c.id !== department.id;
        }
      );

      return {
        ...state,
        department: null,
        departments,
        loadingDepartment: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_DEPARTMENT_REJECTED": {
      return {
        ...state,
        department: action.meta.department,
        errors: action.payload.response.data.errors,
        loadingDepartment: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        department: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
