import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { NavLink } from "react-router-dom";
import { ItemTypes } from "./constants";
import SceneSettingLocation from "./scene-setting-location";

function SceneSetting(props) {
  const {
    sceneSetting,
    index,
    filmId,
    assignLocation,
    unassignLocation,
    replaceLocation,
    aliasSceneSetting,
    unaliasSceneSetting,
    readOnly,
    canAccessShootingLocations
  } = props;

  const hasAliases = (sceneSetting.aliases || []).length > 0;

  const dropLocation = (item) => {
    assignLocation(filmId, item, sceneSetting);
  };

  const sceneSettingDropped = (alias) => {
    aliasSceneSetting(filmId, alias, sceneSetting);
  };

  const canDropLocation = (item, monitor) => {
    const existingLocation = sceneSetting.locations?.find(
      (l) =>
        l.id === item.location.id &&
        l.subLocation?.id === item.location.subLocation?.id
    );
    console.log("canDropLocation", !existingLocation);
    return !existingLocation;
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.LOCATION,
    drop: (item: any, monitor) => dropLocation(item.location),
    canDrop: (item, monitor) => canDropLocation(item, monitor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  const [{ isOverAdd }, dropAdd] = useDrop({
    accept: ItemTypes.LOCATION,
    drop: (item: any, monitor) => dropLocation(item.location),
    canDrop: (item, monitor) => canDropLocation(item, monitor),
    collect: (monitor) => ({
      isOverAdd: !!monitor.isOver()
    })
  });

  const canDropSceneSetting = (item, monitor) => {
    return item.sceneSetting.id != sceneSetting.id;
  };

  const canDragSceneSetting = (monitor) => {
    return !readOnly && !hasAliases;
  };

  const [{ isOverSceneSetting }, dropSceneSetting] = useDrop({
    accept: ItemTypes.SCENE_SETTING,
    drop: (item: any, monitor) => sceneSettingDropped(item.sceneSetting),
    canDrop: (item, monitor) => canDropSceneSetting(item, monitor),
    collect: (monitor) => ({
      isOverSceneSetting: !!monitor.isOver()
    })
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.SCENE_SETTING, sceneSetting },
    canDrag: (monitor) => canDragSceneSetting(monitor),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const getItemStyle = (isDragging) => ({
    background: isDragging ? "#947eed" : "#040f21"
  });

  const getItemDropStyle = (isDragging) => ({
    background: isDragging ? "#947eed" : "#040f21",
    width: "100%"
  });

  return (
    <>
      <tr
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: !readOnly && !hasAliases ? "grab" : ""
        }}>
        <td ref={dropSceneSetting} style={getItemStyle(isOverSceneSetting)}>
          {!readOnly && !hasAliases && (
            <i className="material-icons dark">drag_indicator</i>
          )}
          <NavLink
            to={`/films/${filmId}/scene-settings/${sceneSetting.id}/edit`}>
            {sceneSetting.name}
          </NavLink>
        </td>
        <td>
          <div>
            {(sceneSetting.locations || []).map((location, index) => {
              let locationName = location.name;
              if (location.subLocation?.name) {
                locationName += " - " + location.subLocation.name;
              }
              return (
                <SceneSettingLocation
                  className={index > 0 ? "mt-05" : ""}
                  location={location}
                  assignLocation={assignLocation}
                  unassignLocation={unassignLocation}
                  replaceLocation={replaceLocation}
                  canAccessShootingLocations={canAccessShootingLocations}
                  {...props}
                />
              );
            })}
          </div>
          {(!sceneSetting.locations || sceneSetting.locations.length === 0) && (
            <div ref={dropAdd} style={getItemDropStyle(isOverAdd)}>
              &nbsp;
            </div>
          )}
        </td>
      </tr>
      {(sceneSetting.aliases || []).map((alias) => (
        <tr key={alias.id}>
          <td colSpan={4}>
            <div style={{ marginLeft: 30 }}>
              {alias.name}
              &nbsp;
              {!readOnly && (
                <a
                  onClick={() =>
                    unaliasSceneSetting(filmId, alias, sceneSetting)
                  }>
                  <i className="material-icons">cancel</i>
                </a>
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default SceneSetting;
