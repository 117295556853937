import getApiClient from "./../../../../shared/api";

export const FETCH_BACKGROUND_CHARACTER_PROPS = "FETCH_BACKGROUND_CHARACTER_PROPS";

export function fetchCharacterProps(filmId, characterId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_BACKGROUND_CHARACTER_PROPS,
      payload: getApiClient().get(`/film/${filmId}/backgroundCharacter/${characterId}/props`)
    });
  };
}
