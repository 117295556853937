import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import Images from "../../../shared/images";
import Errors from "./../../../shared/errors";
import {
  renderCurrencyField,
  renderReactSelect,
  renderSelectField,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField,
  renderUrlField
} from "./../../../shared/form-helpers";
import required, { maxDecimalValue } from "../../../shared/validation";
import FormErrors from "../../../shared/form-errors";

let PropForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    onChangePropToSetDressing,
    onChangePropToCostume,
    pristine,
    reset,
    submitting,
    errors,
    acquisitionMethodEnum,
    allCharacters,
    allBackgroundCharacters,
    mode,
    acquisitionMethodValue,
    submitFailed,
    invalid,
    filmId
  } = props;

  const thisProp = props.initialValues || {};
  const readOnly = thisProp.readOnly;

  const [editUrl, setEditUrl] = useState(false);

  const characterOptions = allCharacters?.map((c) => {
    return { label: c.name, value: c.id };
  });

  const characterSelectedOptions = thisProp.characters?.map((c) => {
    return characterOptions?.find((x) => x.value === c.id);
  });

  const backgroundCharacterOptions = allBackgroundCharacters?.map((c) => {
    return { label: c.name, value: c.id };
  });

  const backgroundCharacterSelectedOptions = thisProp.backgroundCharacters?.map((c) => {
    return backgroundCharacterOptions?.find((x) => x.value === c.id);
  });

  const acquisitionMethodOptions = (acquisitionMethodEnum || []).map((item) => { return { value: item.value, label: item.name } });

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Prop</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Prop</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="characters"
              label="Character"
              component={renderReactSelect}
              options={characterOptions}
              selectedOptions={characterSelectedOptions}
              readOnly={readOnly}
            />

            <Field
              name="backgroundCharacters"
              label="Background Characters"
              component={renderReactSelect}
              options={backgroundCharacterOptions}
              selectedOptions={backgroundCharacterSelectedOptions}
              readOnly={readOnly}
            />

            <Field
              name="link"
              label="Link"
              type="url"
              component={renderUrlField}
              readOnly={readOnly}
              setEditUrl={setEditUrl}
              editUrl={editUrl}
            />

            <Field
              name="description"
              label="Description"
              component={renderTextAreaField}
              readOnly={readOnly}></Field>

            <Field
              name="acquisitionMethod"
              label="Acquisition Method"
              component={renderSingleReactSelect}
              options={acquisitionMethodOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>

            {acquisitionMethodValue != 3 && acquisitionMethodValue != 4 &&
              <Field
                name="buyBudget"
                label="Budget"
                component={renderCurrencyField}
                validate={[maxDecimalValue]}
                readOnly={readOnly}></Field>
            }
            {acquisitionMethodValue == 3 &&
              <Field
                name="dailyHireBudget"
                label="Daily Budget"
                component={renderCurrencyField}
                validate={[maxDecimalValue]}
                readOnly={readOnly}></Field>
            }
            <div className="field-body">
              <Field
                name="quantity"
                type="number"
                label="Quantity Required"
                component={renderTextField}
                parse={(value) => Number(value)}
                validate={[required, maxDecimalValue]}
                required
                readOnly={readOnly}
              />
              {acquisitionMethodValue != 4 &&
                <Field
                  name="quantityAcquired"
                  type="number"
                  label="Quantity Acquired"
                  parse={(value) => Number(value)}
                  component={renderTextField}
                  validate={[maxDecimalValue]}
                  readOnly={readOnly}
                />
              }
            </div>

            {!readOnly && <div className="buttons mt-2 sticky-buttons">
              <button
                type="submit"
                className="button is-primary"
                disabled={(pristine || submitting) && !thisProp.enableSave}>
                Save
              </button>
              {mode === "edit" && (
                <>
                  <div>
                    <button
                      type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => handleDelete(thisProp)}>
                      Delete Prop
                    </button>
                  </div>
                  <div>
                    <button type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => onChangePropToSetDressing(thisProp)}>Change to Set Dressing</button>
                    <button type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => onChangePropToCostume(thisProp)}>Change to Costume</button>
                  </div>
                </>
              )}
            </div>}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        </div>

        <div className="column">
          <Images change={props.change} initialValues={props.initialValues} readOnly={readOnly} filmId={filmId} />
        </div>
      </div>
    </>
  );
}

PropForm = reduxForm({
  form: "editPropForm",
  enableReinitialize: true
})(PropForm);

// Decorate with connect to read form values
const selector = formValueSelector('editPropForm'); // <-- same as form name
const PropFormConnect = connect(
  state => {
    // can select values individually
    const acquisitionMethodValue = selector(state, 'acquisitionMethod')

    return {
      acquisitionMethodValue
    }
  }
)(PropForm)

export default PropFormConnect
