import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../shared/loader";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  addBackgroundCharacter,
  clearRedirect,
  setName,
  getGender,
  uploadImagesAndSaveBackgroundCharacter,
  initialiseBackgroundCharacter,
  fetchBackgroundCharacterRate,
  fetchBackgroundActorsWithEmailAddresses
} from "./ducks/actions";
import Form from "./form";

function AddBackgroundCharacter(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const {
    addBackgroundCharacter,
    uploadImagesAndSaveBackgroundCharacter,
    initialiseBackgroundCharacter,
    fetchBackgroundCharacterRate,
    fetchBackgroundActorsWithEmailAddresses,
    backgroundCharacter,
    backgroundActors,
    errors,
    warnings,
    setName,
    getGender,
    name,
    embedded,
    sceneId,
    loadingRate,
    rate
  } = props;

  useEffect(() => {
    fetchBackgroundCharacterRate(filmId);
    fetchBackgroundActorsWithEmailAddresses(filmId);
  }, [filmId]);

  useEffect(() => {
    initialiseBackgroundCharacter();
    if (name) {
      setName(name);
      getGender(name);
    }
  }, [name]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/background-characters/`;
      return <Redirect to={url} />;
    }
  }

  function onUpdateBackgroundCharacter(backgroundCharacter) {
    backgroundCharacter.minimumAge = backgroundCharacter.minimumAge ? +backgroundCharacter.minimumAge : null;
    backgroundCharacter.maximumAge = backgroundCharacter.maximumAge ? +backgroundCharacter.maximumAge : null;
    backgroundCharacter.imageUrls = (backgroundCharacter.imageUrls || []).filter((url) => url);
    backgroundCharacter.sceneId = sceneId;
    if (backgroundCharacter.ethnicities?.length) {
      backgroundCharacter.ethnicityIds = backgroundCharacter.ethnicities.map((m) => m.value);
    }

    if (backgroundCharacter.skinColors?.length) {
      backgroundCharacter.skinColorIds = backgroundCharacter.skinColors.map((m) => m.value);
    }

    backgroundCharacter.backgroundActorIds = backgroundCharacter.backgroundActors?.map((m) => m.value || m);

    const addMode = !backgroundCharacter.id;

    const files = backgroundCharacter.pictures || [];

    if (files && files.length > 0) {
      uploadImagesAndSaveBackgroundCharacter(backgroundCharacter, files, addMode, filmId);
    } else {
      if (!backgroundCharacter.imageUrls) {
        backgroundCharacter.imageUrls = [];
      }
      addBackgroundCharacter(filmId, backgroundCharacter);
    }
  }

  if (props.loadingBackgroundCharacter) {
    return <Loader />;
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/background-characters/add"
      loading={false}>
      <Form
        errors={errors}
        warnings={warnings}
        mode="add"
        initialValues={backgroundCharacter || {}}
        loading={false}
        onSubmit={onUpdateBackgroundCharacter}
        backgroundActors={backgroundActors}
        ethnicityTypes={props.ethnicityTypes}
        skinColors={props.skinColors}
        loadingRate={loadingRate}
        rate={rate}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { backgroundCharactersState, backgroundActorsState, commonState } = state;
  const backgroundCharactersRootState = backgroundCharactersState.rootState;
  return {
    genderEnum: commonState.genderEnum,
    ethnicityTypes: commonState.ethnicityTypes,
    skinColors: commonState.skinColors,
    backgroundActors: backgroundCharactersRootState.backgroundActors,
    errors: backgroundCharactersRootState.errors,
    warnings: backgroundCharactersRootState.warnings,
    redirect: backgroundCharactersRootState.redirect,
    loadingBackgroundCharacter: backgroundCharactersRootState.loadingBackgroundCharacter,
    backgroundCharacter: backgroundCharactersRootState.backgroundCharacter,    
    loadingRate: backgroundCharactersRootState.loadingRate,
    rate: backgroundCharactersRootState.rate
  };
}

export default connect(mapStateToProps, {
  addBackgroundCharacter,
  uploadImagesAndSaveBackgroundCharacter,
  initialiseBackgroundCharacter,
  setName,
  getGender,
  fetchBackgroundCharacterRate,
  fetchBackgroundActorsWithEmailAddresses,
  clearRedirect
})(AddBackgroundCharacter);
