import { ADMIN_DISABLE_USER, ADMIN_FETCH_USER, ADMIN_UPDATE_USER, FETCH_ADMIN_USER_PRICING_PLANS } from "./actions";

interface UsersState {
  loading: boolean;
  user: any;
  pricingPlans: any[];
}

const defaultState: UsersState = {
  loading: false,
  user: null,
  pricingPlans: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case ADMIN_FETCH_USER + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case ADMIN_FETCH_USER + "_FULFILLED": {
      return {
        ...state,
        user: action.payload.data,
        loading: false
      };
    }

    case ADMIN_FETCH_USER + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FETCH_ADMIN_USER_PRICING_PLANS + "_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case FETCH_ADMIN_USER_PRICING_PLANS + "_FULFILLED": {
      return {
        ...state,
        pricingPlans: action.payload.data.pricingPlans,
        loading: false
      };
    }

    case FETCH_ADMIN_USER_PRICING_PLANS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case ADMIN_DISABLE_USER + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case ADMIN_DISABLE_USER + "_FULFILLED": {
      return {
        ...state,
        loading: false
      };
    }

    case ADMIN_DISABLE_USER + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case ADMIN_UPDATE_USER + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case ADMIN_UPDATE_USER + "_FULFILLED": {
      return {
        ...state,
        user: action.meta.user,
        loading: false,
        errors: null
      };
    }

    case ADMIN_UPDATE_USER + "_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
