import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { FETCH_SCENES_ORDER, REORDER_SCENES_ORDER, UPDATE_SCENES_ORDER } from "./actions";

interface ShootingDayOrderState {
  scenes: any[];
  loading: boolean;
  selectedSceneIds: string[];
  pristine: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'scene-order-onboarding-step-1';
export const OnboardingStep2ClassName = 'scene-order-onboarding-step-2';
export const OnboardingStep3ClassName = 'scene-order-onboarding-step-3';
export const OnboardingStep4ClassName = 'scene-order-onboarding-step-4';

const defaultState: ShootingDayOrderState = {
  scenes: [],
  loading: true,
  selectedSceneIds: [],
  pristine: true,
  readOnly: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scenes Order',
      content: `Drag scenes into the order they appear in the script.`,
      disableBeacon: true
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_SCENES_ORDER + "_PENDING": {
      return {
        ...state,
        loading: true,
        scenes: [],
        errors: null
      };
    }

    case FETCH_SCENES_ORDER + "_FULFILLED": {
      return {
        ...state,
        scenes: action.payload.data.scenes,
        readOnly: action.payload.data.readOnly,
        canAccessSceneSettings: action.payload.data.canAccessSceneSettings,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case FETCH_SCENES_ORDER + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case REORDER_SCENES_ORDER: {
      let scenes = Array.from(state.scenes);
      scenes = reorderScenes(
        scenes,
        action.meta.startIndex,
        action.meta.endIndex
      );

      return {
        ...state,
        scenes: scenes,
        pristine: false
      };

    }
    case UPDATE_SCENES_ORDER + "_PENDING": {
      return {
        ...state,
        loadingScene: true,
        errors: null
      };
    }

    case UPDATE_SCENES_ORDER + "_FULFILLED": {
      return {
        ...state,
        loadingScene: false,
        errors: null,
        pristine: true
      };
    }

    case UPDATE_SCENES_ORDER + "_REJECTED": {
      return {
        ...state,
        days: action.meta.days,
        errors: action.payload.response.data.errors,
        loadingScene: false
      };
    }

    case "SCENES_SCRIPT_DAY_TOGGLE_SELECTION_IN_GROUP": {
      const sceneId = action.meta.sceneId;

      const selectedSceneIds: any[] = state.selectedSceneIds;
      const index: number = selectedSceneIds.indexOf(sceneId);

      // if not selected - add it to the selected items
      let shallow: any[] = [];
      if (index === -1) {
        shallow = [...selectedSceneIds, sceneId];
      } else {
        // it was previously selected and now needs to be removed from the group
        shallow = [...selectedSceneIds];
        shallow.splice(index, 1);
      }

      return {
        ...state,
        selectedSceneIds: shallow
      };
    }

    case "SCENES_SCRIPT_DAY_SET_DRAGGING_SCENE_ID": {
      return {
        ...state,
        draggingSceneId: action.meta.sceneId
      };
    }

    case "SCENES_SCRIPT_DAY_UNSELECT_ALL": {
      return {
        ...state,
        selectedSceneIds: []
      };
    }

    case "SCENES_SCRIPT_DAY_MULTI_SELECT_TO": {
      return state;
    }

    default:
      return state;
  }
};

const reorderScenes = (list, sourceIndex, destinationIndex) => {
  const result: any[] = Array.from(list);
  result[sourceIndex].scriptDayOrder = destinationIndex + 1;

  if (sourceIndex > destinationIndex) {
    for (let i = destinationIndex; i < sourceIndex; i++) {
      result[i].scriptDayOrder++;
    }
  }

  if (sourceIndex < destinationIndex) {
    for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
      result[i].scriptDayOrder--;
    }
  }

  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

export default reducer;
