import { FETCH_SCRIPT_VERSIONS, INITIALISE_UPDATE_SCRIPT, UPLOAD_SCRIPT_VERSION } from "./actions";

interface ActionState {
  loading: boolean;
  readOnly: boolean;
  progress: any;
  completed: boolean;
  versions: any[];
}

export const OnboardingStep1ClassName = 'upload-script-onboarding-step-1';
export const OnboardingStep2ClassName = 'upload-script-onboarding-step-2';

const defaultState: ActionState = {
  loading: true,
  readOnly: false,
  progress: null,
  completed: false,
  versions: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_SCRIPT_VERSIONS + "_PENDING": {
      return {
        ...state,
        loading: true

      };
    }

    case FETCH_SCRIPT_VERSIONS + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        versions: action.payload.data.versions
      };
    }

    case FETCH_SCRIPT_VERSIONS + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case UPLOAD_SCRIPT_VERSION + "_PENDING": {
      return {
        ...state,
        loading: true,
        completed: false,
        progress: { progress: null, message: 'Uploading...' }
      };
    }

    case UPLOAD_SCRIPT_VERSION + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        completed: true,
        progress: null
      };
    }

    case UPLOAD_SCRIPT_VERSION + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors,
        progress: null
      };
    }

    case "UPDATE_SCRIPT_UPLOAD_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case INITIALISE_UPDATE_SCRIPT: {
      return {
        ...state,
        errors: null,
        progress: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
