import OnboardingType from "../../../shared/onboarding/onboarding-type";
import { sortByName } from "../../../shared/utility";
import { FETCH_COLLABORATORS_OVERVIEW, FETCH_COLLABORATOR_ROLES_FOR_COLLABORATOR, FETCH_COLLABORTAROR_CAN_ADD } from "./actions";
import { UPDATE_COLLABORATOR_EMAILING_STATUS } from "../../../middleware/collaboratorSignalRMiddleware";

interface ActionState {
  canAdd: boolean;
  users: any[];
  roles: any[];
  loading: boolean;
  user: any;
  imageFiles: any[];
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  overview: any;
}

export const OnboardingStep1ClassName = 'collaborators-onboarding-step-1';
export const OnboardingStep2ClassName = 'collaborators-onboarding-step-2';

const defaultState: ActionState = {
  users: [],
  roles: [],
  canAdd: false,
  loading: true,
  user: {},
  imageFiles: [],
  readOnly: false,
  overview: {},
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Add Collaborator',
      content: `Add other users here to help collaborate on your film. Users can be assigned different access levels, so you're always in control.`,
      disableBeacon: true
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Collaborator Roles',
      content: `Add roles to define what sections collaborates have access to on your film.`,
      disableBeacon: true,
      nextButtonText: 'Get Started'
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_USERS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_USERS_FULFILLED": {
      return {
        ...state,
        users: action.payload.data.users,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_COLLABORATORS_OVERVIEW + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_COLLABORATORS_OVERVIEW + "_FULFILLED": {
      return {
        ...state,
        overview: action.payload.data,
        loading: false,
        errors: null
      };
    }

    case FETCH_COLLABORATORS_OVERVIEW + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data.errors
      };
    }

    case FETCH_COLLABORATOR_ROLES_FOR_COLLABORATOR + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_COLLABORATOR_ROLES_FOR_COLLABORATOR + "_FULFILLED": {
      return {
        ...state,
        roles: action.payload.data.roles,
        loading: false,
        errors: null
      };
    }

    case FETCH_COLLABORATOR_ROLES_FOR_COLLABORATOR + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data.errors
      };
    }

    case "CLEAR_USER": {
      return {
        ...state,
        loading: false,
        user: null,
        errors: null
      };
    }

    case "FETCH_SINGLE_USER_PENDING": {
      return {
        ...state,
        user: null,
        loadingUser: true
      };
    }

    case "FETCH_SINGLE_USER_FULFILLED": {
      let user = action.payload.data;

      return {
        ...state,
        user,
        errors: action.payload.data.errors,
        loadingUser: false
      };
    }

    case FETCH_COLLABORTAROR_CAN_ADD + "_PENDING": {
      return {
        ...state,
        user: null,
        loadingUser: true
      };
    }

    case FETCH_COLLABORTAROR_CAN_ADD + "_FULFILLED": {
      const canAdd = action.payload.data.canAddCollaborator;

      return {
        ...state,
        canAdd,
        loadingUser: false,
        errors: null
      };
    }

    case "UPDATE_SINGLE_USER_PENDING": {
      return {
        ...state,
        user: action.payload,
        loadingUser: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_USER_FULFILLED": {
      const user = { ...action.meta.user, ...action.payload.data.user };
      const users: any = state.users.map((c: any, index: number) => {
        if (c.id === user.id) {
          return { ...c, ...user };
        } else {
          return c;
        }
      });
      sortByName(users);
      return {
        ...state,
        user,
        users,
        loadingUser: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_USER_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loadingUser: false
      };
    }

    case "ADD_SINGLE_USER_PENDING": {
      return {
        ...state,
        errors: null,
        loadingUser: true
      };
    }

    case "ADD_SINGLE_USER_FULFILLED": {
      const user: any = action.payload.data.user;

      let users = [user, ...state.users];
      sortByName(users);
      return {
        ...state,
        user,
        users,
        loadingUser: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_USER_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loadingUser: false
      };
    }

    case "RESEND_INVITE_PENDING": {
      return {
        ...state,
        errors: null,
        loadingUser: true
      };
    }

    case "RESEND_INVITE_FULFILLED": {

      return {
        ...state,
        loadingUser: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "RESEND_INVITE_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loadingUser: false
      };
    }

    case "DELETE_SINGLE_USER_PENDING": {
      return {
        ...state,
        user: action.payload,
        loadingUser: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_USER_FULFILLED": {
      const user = action.meta.user;
      const users: any = state.users.filter((c: any, index: number) => {
        return c.id !== user.id;
      });

      return {
        ...state,
        user: null,
        users,
        loadingUser: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    // case "USER_PARSE_MANDY_URL_PENDING": {
    //   return {
    //     ...state,
    //     user: { ...state.user, parsingUrl: true },
    //     parsingUrl: true
    //   };
    // }

    // case "USER_PARSE_MANDY_URL_FULFILLED": {
    //   const response = action.payload.data;
    //   if (!response.success) {
    //     return state;
    //   }

    //   const user: any = {
    //     mandyResponse: response,
    //     mandyUrl: action.meta.url
    //   };

    //   return {
    //     ...state,
    //     user: { ...state.user, ...user, parsingUrl: false },
    //     errors: null
    //   };
    // }

    // case "USER_PARSE_MANDY_URL_REJECTED": {
    //   return {
    //     ...state,
    //     errors: action.payload.response.data.errors,
    //     user: { ...state.user, parsingUrl: false },
    //     parsingUrl: false
    //   };
    // }

    // case "USER_PARSE_STARNOW_URL_PENDING": {
    //   return {
    //     ...state,
    //     user: { ...state.user, parsingUrl: true },
    //     parsingUrl: true
    //   };
    // }

    // case "USER_PARSE_STARNOW_URL_FULFILLED": {
    //   const response = action.payload.data;
    //   if (!response.success) {
    //     return state;
    //   }

    //   const user: any = {
    //     starNowResponse: response,
    //     starNowUrl: action.meta.url
    //   };

    //   return {
    //     ...state,
    //     user: { ...state.user, ...user, parsingUrl: false }
    //   };
    // }

    // case "USER_PARSE_STARNOW_URL_REJECTED": {
    //   return {
    //     ...state,
    //     user: { ...state.user, parsingUrl: false },
    //     errors: action.payload.response.data.errors
    //   };
    // }

    case "DELETE_SINGLE_USER_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loadingUser: false
      };
    }

    case "ADD_USER_IMAGE_FILES": {
      let mainImageUrl = state.user.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.imageFiles[0].name;
      }

      return {
        ...state,
        imageFiles: [...state.imageFiles, ...action.meta.imageFiles],
        user: { ...state.user, mainImageUrl: mainImageUrl }
      };
    }

    case "ADD_USER_IMAGE_URL": {
      let user: any = { ...state.user };
      let mainImageUrl = state.user.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.url;
      }

      if (user?.imageUrls) {
        user = {
          ...user,
          imageUrls: [...user?.imageUrls, action.meta.url],
          mainImageUrl: mainImageUrl
        };
      } else {
        user = {
          ...user,
          imageUrls: [action.meta.url],
          mainImageUrl: mainImageUrl
        };
      }
      return {
        ...state,
        user: user
      };
    }

    case "DELETE_USER_IMAGE_URL": {
      let imageUrls: any = state.user.imageUrls;
      imageUrls = imageUrls.filter((item) => {
        return item !== action.meta.url;
      });
      return {
        ...state,
        user: { ...state.user, imageUrls: imageUrls }
      };
    }

    case "DELETE_USER_IMAGE_FILE": {
      let imageFiles = state.imageFiles.filter((file) => {
        return file.name !== action.meta.fileName;
      });
      return {
        ...state,
        imageFiles: imageFiles
      };
    }

    case "USER_MAIN_IMAGE_CHANGED": {
      return {
        ...state,
        user: { ...state.user, mainImageUrl: action.meta.url }
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        user: null,
        redirect: null
      };
    }

    case UPDATE_COLLABORATOR_EMAILING_STATUS: {
      const { collaboratorId, status, statusDescription, statusChangedDate } = action.meta;
      const user = { ...state.user };
      if (state.user && state.user.id == collaboratorId) {
        user.emailingStatus = status;
        user.emailingStatusDescription = statusDescription;
      }

      const overview = { ...state.overview };
      if (state.overview && state.overview.collaborators) {
        const collaborator = state.overview.collaborators.find(c => c.id === collaboratorId);
        if (collaborator) {
          collaborator.emailingStatus = status;
          collaborator.emailingStatusDescription = statusDescription;
        }
      }

      return {
        ...state,
        user,
        overview
      };
    }

    default:
      return state;
  }
};

export default reducer;
