import { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import confirm from "../../shared/modal/confirm";
import {
  fetchUserFilms,
  exportFilm
} from "./ducks/actions";
import Form from "./form";
import auth from "../../shared/auth";

function UserFilms(props) {
  const {
    fetchUserFilms,
    exportFilm,
    loading,
    errors,
    films
  } = props;

  const userId = props.match.params.userId;

  useEffect(() => {
    fetchUserFilms(userId);
  }, [userId]);

  if (loading) {
    return <Loader />;
  }

  function onUpdateDetails(user) {

  }

  function showFile(blob, filmName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should    
    var newBlob = new Blob([blob], { type: "txt/json" });

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;

    link.download = filmName + ".json";
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  const onExportFilm = (film) => {
    const url = (process.env.REACT_APP_API_URL || (window as any).apiUrl) + "film"
    fetch(`${url}/${film.id}/export`, {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + auth.getToken()
      })
    })
      .then((r) => r.blob())
      .then((blob) => {
        showFile(blob, film.name);
      })
  };

  const onDownloadScipt = (film: any) => {

  };

  return (
    <div className="blade-content wideaf">
      <Form
        onSubmit={onUpdateDetails}
        exportFilm={onExportFilm}
        downloadScript={onDownloadScipt}
        errors={errors}
        loading={false}
        films={films}
        initialValues={films}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { adminState } = state;
  return {
    films: adminState.adminUserFilmsState.films,
    errors: adminState.adminUserFilmsState.errors,
    loading: adminState.adminUserFilmsState.loading
  };
}

export default connect(mapStateToProps, {
  fetchUserFilms,
  exportFilm
})(UserFilms);
