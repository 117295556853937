import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import {
  fetchUsers  
} from "./ducks/actions";
import Form from "./form";

function Register(props) {
  const {    
    fetchUsers,    
    loading,
    errors,
    users
  } = props;

  useEffect(() => {
    fetchUsers();
  }, []);
  
  if (loading) {
    return <Loader />;
  }

  const refresh = () =>{
    fetchUsers();
  };

  return (
    <div className="blade-content wideaf">
      <Form
        errors={errors}        
        loading={false}
        initialValues={{users : users}}
        refresh={refresh}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { adminState } = state;
  return {    
    users: adminState.adminUsersState.users,
    errors: adminState.adminUsersState.errors,
    loading: adminState.adminUsersState.loading
  };
}

export default connect(mapStateToProps, {
  fetchUsers  
})(Register);
