import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../shared/loader";
import {
  fetchPropOverview,
  downloadPropsOverview,
  clearPdf,
  aliasProp,
  unaliasProp
} from "./ducks/actions";
import Overview from "./overview";

const OverviewContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchPropOverview,
    downloadPropsOverview,
    overview,
    loading,
    errors,
    warnings,
    progress,
    download,
    clearPdf,
    aliasProp,
    unaliasProp
  } = props;

  useEffect(() => {
    fetchPropOverview(filmId);
  }, [filmId, fetchPropOverview]);

  const onDownloadFile = () => {
    downloadPropsOverview(filmId);
  };

  function renderOverview(overview) {

    return (
      <ScrollIntoView
        className="blade-content wider"
        path="/films/:filmId/visual-effects/overview"
        loading={loading}>
        <h2 className="menu-label">Overview</h2>
        <Overview
          overview={overview}
          filmId={filmId}
          errors={errors}
          warnings={warnings}
          progress={progress}
          download={download}
          clearPdf={clearPdf}
          onDownloadFile={onDownloadFile}
          aliasProp={aliasProp}
          unaliasProp={unaliasProp}
        ></Overview>
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !overview) && <Loader />}
      {!loading && overview && renderOverview(overview)}
    </>
  );
};

function mapStateToProps(state) {
  const { errors, warnings, overview, loading, progress,
    download } =
    state.propsOverviewState;
  return {
    errors,
    warnings,
    overview,
    loading,
    progress,
    download
  };
}

export default connect(mapStateToProps, {
  fetchPropOverview,
  downloadPropsOverview,
  clearPdf,
  aliasProp,
  unaliasProp
})(OverviewContainer);
