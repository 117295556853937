import React, { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import FilmaizeSelect from "../../shared/filmanize-select";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {
  renderSelectField,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField
} from "./../../shared/form-helpers";
import required from "./../../shared/validation";
import ReCaptureInfo from "../../account/recapture-info";

function ContactForm(props) {
  const {
    handleSubmit,
    countries,
    pristine,
    reset,
    submitting,
    errors,
    submitFailed,
    invalid,
    sent,
    isOnWhite,
    showPageSections,
    pageSections,
    change,
    userEmailAddress,
    userFirstName,
    userLastName,
    selectedPageSection
  } = props;

  const location = useLocation();
  useEffect(() => {
    if (!selectedPageSection) {
      pageSections.forEach((pageSection) => {
        if (matchPath(location.pathname, { path: pageSection.path, exact: true })) {
          change("section", pageSection.name);
        }
      });
    }
  }, [location, pageSections]);

  useEffect(() => {
    if (selectedPageSection) {
      change("section", selectedPageSection);
    }
  }, [selectedPageSection, pageSections]);

  useEffect(() => {
    change("emailAddress", userEmailAddress);
  }, [userEmailAddress]);

  useEffect(() => {
    change("firstName", userFirstName);
  }, [userFirstName]);

  useEffect(() => {
    change("lastName", userLastName);
  }, [userLastName]);

  const inputClassName = isOnWhite ? "input-white-background" : "";

  const pageSectionOptions = pageSections.map((pageSection) => {
    return { value: pageSection.name, label: pageSection.name };
  });

  return (
    <>
      <h2 className="menu-label">Contact</h2>
      {errors && <Errors errors={errors} />}
      {sent && <div>Thank you for contacting Filmanize. We'll be in contact with you as soon as we can to help.</div>}
      {!sent && <form autoComplete="off" onSubmit={handleSubmit}>

        <Field
          name="emailAddress"
          label="Email Address"
          component={renderTextField}
          className={inputClassName}
          validate={[required]}
          required
        />
        <div className="field-body">
          <Field
            name="firstName"
            label="First Name"
            component={renderTextField}
            className={inputClassName}
            validate={[required]}
            required
          />
          <Field
            name="lastName"
            label="Last Name"
            component={renderTextField}
            className={inputClassName}
            validate={[required]}
            required
          />
        </div>
        {showPageSections && <Field
          name="section"
          label="Section"
          component={renderSingleReactSelect}
          includeEmpty={true}
          className={inputClassName}
          options={pageSectionOptions}
        />}
        <Field
          name="message"
          label="Message"
          component={renderTextAreaField}
          className={inputClassName}
          validate={[required]}
          required
        >
          {(countries || []).map((item, i) => {
            return (
              <option value={item.id} key={i}>
                {item.name}
              </option>
            );
          })}
        </Field>

        <div className="buttons mt-1">
          <button
            type="submit"
            className={`button is-primary ${isOnWhite ? "is-on-white" : ""} ${submitting ? "is-loading" : ""}`}
            disabled={pristine || submitting}>
            Submit question
          </button>
          <button
            type="button"
            className={`button is-secondary ${isOnWhite ? "is-on-white" : ""}`}
            disabled={pristine || submitting}
            onClick={reset}>
            Reset Values
          </button>
        </div>
        <ReCaptureInfo />
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>}
    </>
  );
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true
})(ContactForm);
