import getApiClient from "../../../../shared/api";

export const FETCH_CHANGE_LOG = "FETCH_CHANGE_LOG";
export const FETCH_CHANGE_LOGS = "FETCH_CHANGE_LOGS";

export function fetchChangeLog(id) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CHANGE_LOG ,
      payload: getApiClient().get(`/changeLog/${id}`)
    });
  };
}

export function fetchChangeLogs(continuationToken, id) {
  return (dispatch) => {

    let continuationTokenQuery = "";
    if (continuationToken != null) {
      continuationTokenQuery = `continuationToken=${continuationToken}`;
    }

    let idQuery = "";
    if (id != null) {      
      idQuery = `${continuationTokenQuery ? "&" : ""}id=${id}`;
    }

    dispatch({
      type: FETCH_CHANGE_LOGS,
      payload: getApiClient().get(`/changeLog?${continuationTokenQuery}${idQuery}`),
      meta: { append: continuationToken != null}
    });
  };
}