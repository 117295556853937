import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../shared/loader";
import { fetchChangeLogs } from "./ducks/actions";
import AddIcon from "../../films/icons/add";
import List from "./list";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListCostumesContainer(props) {  
  const { fetchChangeLogs, loading, changeLogs, errors, readOnly } = props;

  useEffect(() => {
    fetchChangeLogs();
  }, [fetchChangeLogs]);

  function renderLoading() {
    return <Loader />;
  }

  function renderChangeLogs(changeLogs) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/admin/change-logs">
        <List changeLogs={changeLogs} />
        {!readOnly && !window.location.pathname.endsWith("add") && (
          <NavLink
            to={`/admin/change-logs/add`}
            activeClassName={
              "is-active " + window.location.pathname.endsWith("add")
                ? "disabled"
                : ""
            }
            className="button is-transparent is-add">
            <AddIcon className="white-icon" />
            Add Change Log
          </NavLink>
        )}
      </ScrollIntoView>
    ); 
  }

  return loading ? renderLoading() : renderChangeLogs(changeLogs);
}

function mapStateToProps(state) {
  const { changeLogs, loading, errors, readOnly } = state.adminChangeLogState;
  return { changeLogs, loading, errors, readOnly };
}

export default connect(mapStateToProps, {
  fetchChangeLogs
})(ListCostumesContainer);
