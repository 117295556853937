import { UPDATE_SMART_BREAKDOWN_PROGRESS } from "../../../../middleware/signalRMiddleware";
import { INITIALISE_UPDATE_SCRIPT, SMART_BREAKDOWN } from "./actions";

interface ActionState {
  loading: boolean;
  readOnly: boolean;
  progress: any;
  completed: boolean;
  versions: any[];
}

export const OnboardingStep1ClassName = 'upload-script-onboarding-step-1';
export const OnboardingStep2ClassName = 'upload-script-onboarding-step-2';

const defaultState: ActionState = {
  loading: true,
  readOnly: false,
  progress: null,
  completed: false,
  versions: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case SMART_BREAKDOWN + "_PENDING": {
      return {
        ...state,
        loading: true,
        completed: false,
        progress: { progress: null, message: 'Preparing...' }
      };
    }

    case SMART_BREAKDOWN + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        completed: true,
        progress: null
      };
    }

    case SMART_BREAKDOWN + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors,
        progress: null
      };
    }

    case INITIALISE_UPDATE_SCRIPT: {
      return {
        ...state,
        errors: null,
        progress: null
      };
    }

    case UPDATE_SMART_BREAKDOWN_PROGRESS: {
      return {
        ...state,
        errors: null,
        progress: action.meta.progress
      };
    }

    default:
      return state;
  }
};

export default reducer;
