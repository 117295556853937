import getApiClient from "../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export function fetchShootingDays(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTINGDAYS",
      payload: getApiClient().get(`/film/${filmId}/ShootingDay`)
    });
  };
}

export function fetchShootingDay(filmId, shootingdayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_SHOOTINGDAY",
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingdayId}`
      )
    });
  };
}

export function updateShootingDay(filmId, shootingday) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_SHOOTINGDAY",
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingday.id}`,
        shootingday
      ),
      meta: { shootingday }
    })
      .then((response) => {
        dispatch(showInfo(`Updated shootingday ${shootingday.date}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating shootingday ${shootingday.date}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addShootingDay(filmId, shootingday) {
  shootingday.mainImageUrl = null;
  shootingday.imageUrls = [];

  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_SHOOTINGDAY",
      payload: getApiClient().post(`/film/${filmId}/ShootingDay/`, shootingday),
      meta: { shootingday }
    })
      .then((response) => {
        dispatch(showInfo(`Added shootingday ${shootingday.date}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding shootingday`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteShootingDay(filmId, shootingday) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_SHOOTINGDAY",
      payload: getApiClient().delete(
        `/film/${filmId}/ShootingDay/${shootingday.id}`
      ),
      meta: { shootingday }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted shootingday ${shootingday.date}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting shootingday ${shootingday.date}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}
