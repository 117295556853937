const defaultState = {
  authFailed: false
};

const reducer = (state = defaultState, action: any = {}) => {
  if (
    action.type.endsWith("_REJECTED") &&
    action.payload?.response?.status === 401
  ) {
    return {
      ...state,      
      authFailed: true
    };
  } else {
    return state;
  }
};

export default reducer;
