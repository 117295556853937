import auth from "../../../shared/auth";
import { UPDATE_USER_GET_LANGUAGES } from "./actions";

interface RegisterState {
  loading: boolean;
  user: any;  
  countries: any[];
  languages: any[];
}

const defaultState: RegisterState = {
  loading: false,
  user: {},
  countries: [],
  languages: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "UPDATE_USER_GET_COUNTRIES_FULFILLED" : {
      return {
        ...state,
        countries: action.payload.data.countries
      };
    }

    case "UPDATE_USER_GET_COUNTRIES_REJECTED" : {
      return {
        ...state,
        errors: action.payload.response.data.errors,
      };
    }

    case UPDATE_USER_GET_LANGUAGES + "_FULFILLED" : {      
      return {
        ...state,
        languages: action.payload.data.languages
      };
    }

    case UPDATE_USER_GET_LANGUAGES + "_REJECTED" : {
      return {
        ...state,
        errors: action.payload.response.data.errors,
      };
    }

    case "FETCH_USER_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_USER_FULFILLED": {
      return {
        ...state,
        user: action.payload.data,        
        loading: false
      };
    }

    case "FETCH_USER_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }
 
    case "UPDATE_USER_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "UPDATE_USER_FULFILLED": {   
      if (action.payload.data.token) {
        auth.updateToken(action.payload.data.token, action.payload.data.tokenExpires);
      }
      
      return {
        ...state,   
        user: action.meta.user,   
        loading: false,
        errors: null
      };
    }

    case "UPDATE_USER_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
