import { FETCH_USER_COUNTRIES_BY_REGISTRATION_DATE } from "./actions";

interface UsersState {
  loading: boolean;
  countries: any[];
}

const defaultState: UsersState = {
  loading: false,
  countries: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_USER_COUNTRIES_BY_REGISTRATION_DATE + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_USER_COUNTRIES_BY_REGISTRATION_DATE + "_FULFILLED": {
      return {
        ...state,
        countries: action.payload.data.countries,
        loading: false
      };
    }

    case FETCH_USER_COUNTRIES_BY_REGISTRATION_DATE + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
