import React from "react";
import { NavLink } from "react-router-dom";

function Location(props) {
  const {
    location,
    filmId,
    readOnly
  } = props;

  return (
    <>
      <tr>
        <td>
          {location.number}
        </td>
        <td>          
          <NavLink to={`/films/${filmId}/locations/${location.id}/edit`}>
            {location.name}
          </NavLink>
        </td>
        <td>
          {location.address.addressLines.join(", ")}
        </td>        
        <td>
          {location.parkingAddress?.addressLines.join(", ")}
        </td>        
      </tr>
    </>
  );
}

export default Location;
