import React from "react";
import { NavLink } from "react-router-dom";

const LocationsList = ({ locations, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Shooting Locations</p>
      <ul className="menu-list">
      <li>
          <NavLink
            to={`/films/${filmId}/locations/overview`}
            activeClassName="is-active">
            Overview
          </NavLink>
        </li>
        <hr />

        {(locations || []).map((location, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/locations/${location.id}/edit`}
                activeClassName="is-active"
                title={location.name}
              >
                {location.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default LocationsList;
