import { NavLink } from "react-router-dom";
import InputMask from 'react-input-mask';
import FilmanizeSelectField from "../../shared/filmanize-select-field";
import TextInput from "../../shared/text-input";
import TextAreaInput from "../../shared/text-area-input";
import confirm from "../../shared/modal/confirm";

const CommercialScriptSceneShot = ({ shot,
  provided,
  getItemStyle,
  snapshot,
  readOnly,
  filmId,
  characters,
  shotSizeEnum,
  cameraMovementEnum,
  addScriptField,
  removeScriptField,
  setShotSize,
  setCameraMovements,
  setDuration,
  setDescription,
  setFieldText,
  setFieldCharacter,
  removeShot }) => {

  const onSetCameraMovements = (value) => {
    setCameraMovements(shot.id, value.map(v => v.value));
  };

  const VoiceOverFieldType = "Voice Over";
  const MusicFieldType = "Music";
  const TitleFieldType = "Title";
  const SoundEffectFieldType = "Sound Effect";
  const DialogueFieldType = "Dialogue";

  const onDurationChangeHandler = (event) => {
    setDuration(shot.id, event.target.value)
  };

  const onSetShotSize = (shotSize) => {
    setShotSize(shot.id, shotSize);
  };

  const onAddVoiceOver = () => {
    addScriptField(VoiceOverFieldType, shot.id);
  };

  const onAddMusic = () => {
    addScriptField(MusicFieldType, shot.id);
  };

  const onAddTitle = () => {
    addScriptField(TitleFieldType, shot.id);
  };

  const onAddSoundEffect = () => {
    addScriptField(SoundEffectFieldType, shot.id);
  };

  const onRemoveScriptField = (fieldId, fieldType) => {
    confirm(() => removeScriptField(fieldId, shot.id), fieldType);
  };

  const onAddDialogue = () => {
    addScriptField(DialogueFieldType, shot.id);
  }

  const onFieldChange = (fieldId, text) => {
    setFieldText(shot.id, fieldId, text);
  };

  const onDescriptionChange = (text) => {
    setDescription(shot.id, text);
  };

  const onCharacterChange = (fieldId, characterId) => {
    setFieldCharacter(shot.id, fieldId, characterId);
  };

  const cameraMovementOptions = cameraMovementEnum?.map((m) => {
    return { label: m.name, value: m.value };
  });

  const cameraMovementSelectedOptions = shot.cameraMovements?.map((m) => {
    return cameraMovementOptions?.find((x) => x.value === m);
  });

  const shotSizeOptions = (shotSizeEnum || []).map((item) => { return { value: item.value, label: item.name } });

  const characterOptions = (characters || []).map((item) => { return { value: item.id, label: item.name } });

  return (
    <tr
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(
        snapshot.isDragging,
        provided.draggableProps.style
      )}
    >
      <td className="number-column">
        {!readOnly && <i className="material-icons">
          drag_indicator
        </i>}
        <NavLink
          to={`/films/${filmId}/scenes/${shot.sceneId}/edit`}
        >
          {shot.number}
        </NavLink>
        <div className="mt-1">
          <a><i className="material-icons" onClick={() => removeShot(shot.id)}>delete</i></a>
        </div>
      </td>
      <td>
        {(shot.fields || []).map(field => (
          <div className="text-field-container" key={field.type}>

            <div className="text-field">
              <label className="label">{field.type}</label>
              {field.type === DialogueFieldType &&
                <FilmanizeSelectField
                  value={field.characterId}
                  //label="Character"
                  placeholder="Character"
                  isMulti={false}
                  options={characterOptions}
                  className="react-select-control"
                  classNamePrefix="react-select"
                  onChange={(value) => onCharacterChange(field.id, value)}
                  isDisabled={readOnly}
                />}
              <TextInput onChange={(text) => onFieldChange(field.id, text)} value={field.text} />
            </div>
            <a><i className="material-icons" onClick={() => onRemoveScriptField(field.id, field.type)}>delete</i></a>
          </div>
        ))}
        {!shot.fields.some(f => f.type === VoiceOverFieldType) && <div className="mb-1"> <button className="button" onClick={onAddVoiceOver}>Add Voice Over</button></div>}
        {!shot.fields.some(f => f.type === MusicFieldType) && <div className="mb-1"><button className="button" onClick={onAddMusic}>Add Music</button></div>}
        {!shot.fields.some(f => f.type === TitleFieldType) && <div className="mb-1"><button className="button" onClick={onAddTitle}>Add Title</button></div>}
        {!shot.fields.some(f => f.type === SoundEffectFieldType) && <div className="mb-1"><button className="button" onClick={onAddSoundEffect}>Add Sound Effect</button></div>}
        <div className="mb-1"><button className="button" onClick={onAddDialogue}>Add Dialog</button></div>
      </td>
      <td>
        <FilmanizeSelectField
          value={shot.shotSize}
          label="Shot Size"
          isMulti={false}
          options={shotSizeOptions}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={onSetShotSize}
          isDisabled={readOnly}
        />
        <FilmanizeSelectField
          value={cameraMovementSelectedOptions}
          label="Camera Movements"
          isMulti={true}
          options={cameraMovementOptions}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={onSetCameraMovements}
          isDisabled={readOnly}
        />
        <TextAreaInput label="Description" onChange={onDescriptionChange} value={shot.description} />
      </td>
      <td className="duration-column">
        <InputMask mask="99:99" maskChar="" className="input" placeholder="00:00" value={shot.duration} onChange={onDurationChangeHandler} />
      </td>

    </tr>
  )
}

export default CommercialScriptSceneShot;