import memoizeOne from "memoize-one";
import { Droppable } from "react-beautiful-dnd";
import SceneShot from "./sceneShot";
import {
  OnboardingStep2ClassName,
} from "./ducks/reducer";
import Scene from "./scene";

const Scenes = ({
  scenes,
  filmId,
  shootingdayId,
  selectedShotIds,
  draggingShotId,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  readOnly,
  canAccessShots
}) => {

  type SceneIdMap = {
    [sceneId: string]: true;
  };

  const getSelectedMap = memoizeOne((selectedSceneIds: string[]) =>
    selectedSceneIds.reduce(
      (previous: SceneIdMap, current: string): SceneIdMap => {
        previous[current] = true;
        return previous;
      },
      {}
    )
  );

  return (
    <>
      <table className={`table ${OnboardingStep2ClassName}`}>
        <thead>
          <tr>
            <th>Shot</th>
            <th>Lens</th>
            <th>Movements</th>
            <th>Actors</th>
            <th>Duration</th>
          </tr>
        </thead>

        {(scenes || []).map((scene, index) => (
          <Scene
            scene={scene}
            index={index}
            filmId={filmId}
            shootingdayId={shootingdayId}
            selectedShotIds={selectedShotIds}
            draggingShotId={draggingShotId}
            toggleSelection={toggleSelection}
            toggleSelectionInGroup={toggleSelectionInGroup}
            multiSelectTo={multiSelectTo}
            readOnly={readOnly}
            canAccessShots={canAccessShots} />

        ))}
      </table>
    </>
  );
};

export default Scenes;
