import { useEffect, useState } from "react";
import Download from "../../../shared/download";
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import SearchScenesForm from "../../scenes/search/search-scenes-form";
import { connect } from "react-redux";
import ScriptDownloadScenesForm from "./scenes-form";

import {
    fetchAllScenes,
    fetchAllCharacters,
    fetchAllSceneSettings,
    fetchScenesAssignedToProp,
    clearSceneSearchResults,
    setSceneAssignedToProp
} from "./ducks/actions";
import {
    searchScenes,
    SEARCH_SCENES_FOR_SCRIPT
} from "../../scenes/search/ducks/actions";

import ElementSelection from "./element-selection";

const DownloadScript = (props: any) => {
    const { progress,
        clearPdf,
        download,
        downloadFile,
        onClose,
        fetchAllCharacters,
        fetchAllSceneSettings,
        searchScenes,
        clearSceneSearchResults,
        errors,
        filmId,
        allCharacters,
        allSceneSettings,
        searchingScenes,
        selectedSceneIds,
        loadingSceneSettings,
        allScenes,
        searchedScenes,
        selectSearchedScenes,
        resetPdf,
        reduxFormValues } = props;

    const [showSceneSelect, setShowSceneSelect] = useState<boolean>(false);
    const [includeTitle, setIncludeTitle] = useState<boolean>(false);
    const [showMarkup, setShowMarkup] = useState<boolean>(false);
    const [includeDialog, setIncludeDialog] = useState<boolean>(true);
    const [includeAction, setIncludeAction] = useState<boolean>(true);
    const [includeCharacter, setIncludeCharacter] = useState<boolean>(true);
    const [includeParenthetical, setIncludeParenthetical] = useState<boolean>(true);
    const [includeTransition, setIncludeTransition] = useState<boolean>(true);
    const [includeGeneral, setIncludeGeneral] = useState<boolean>(true);

    const [fetchedData, setFetchedData] = useState<boolean>(false);

    useEffect(() => {
        resetPdf();
        setFetchedData(false);
    }, [])

    useEffect(() => {
        if (showSceneSelect && !fetchedData) {
            setFetchedData(true);
            fetchAllCharacters(filmId);
            fetchAllSceneSettings(filmId);
        }
    }, [filmId, showSceneSelect]);

    function onSearchScenes(params) {
        resetPdf();
        searchScenes(
            SEARCH_SCENES_FOR_SCRIPT,
            filmId,
            params
        );
    }

    const onChangeIncludeDialog = (value) => {
        resetPdf();
        setIncludeDialog(value);
    }

    const onChangeIncludeAction = (value) => {
        resetPdf();
        setIncludeAction(value);
    }

    const onChangeIncludeCharacter = (value) => {
        resetPdf();
        setIncludeCharacter(value);
    }

    const onChangeIncludeParenthetical = (value) => {
        resetPdf();
        setIncludeParenthetical(value);
    }

    const onChangeIncludeTransition = (value) => {
        resetPdf();
        setIncludeTransition(value);
    }

    const onChangeIncludeGeneral = (value) => {
        resetPdf();
        setIncludeGeneral(value);
    }

    const onSetIncludeTitle = () => {
        resetPdf();
        setIncludeTitle(!includeTitle);
    };

    const onSetShowMarkup = () => {
        resetPdf();
        setShowMarkup(!showMarkup);
    };

    const onSetShowSceneSelect = () => {
        resetPdf();
        setShowSceneSelect(!showSceneSelect);
    };

    const onDownloadFile = () => {
        const selectedSceneIds = showSceneSelect ? reduxFormValues.selectedSceneIds : [];
        downloadFile(includeTitle, showMarkup, includeDialog, includeAction, includeCharacter, includeParenthetical, includeTransition, includeGeneral, !showSceneSelect, selectedSceneIds);
    };

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/script/download"
            loading={false}>
            <div>
                <h2 className="menu-label">Download</h2>
                <button className="button" onClick={onSetShowSceneSelect}>{showSceneSelect ? "All" : "Select"} Scenes</button>
                {showSceneSelect && <div className="mb-1">
                    <div className={`box mt-1 mb-1`}>
                        <SearchScenesForm
                            errors={errors}
                            mode="edit"
                            filmId={filmId}
                            onSubmit={onSearchScenes}
                            allCharacters={allCharacters}
                            allSceneSettings={allSceneSettings}
                            searchingScenes={searchingScenes}
                            scenes={allScenes}
                            showCharacters={true}
                            showSceneSettings={true}
                        />
                    </div>

                    <ScriptDownloadScenesForm
                        errors={errors}
                        mode="edit"
                        filmId={filmId}
                        initialValues={{ selectedSceneIds: selectedSceneIds }}
                        loading={loadingSceneSettings}
                        allScenes={allScenes}
                        reduxFormValues={reduxFormValues}
                        searchedSceneIds={searchedScenes?.map((s) => s.id)}
                        selectSearchedScenes={selectSearchedScenes}
                        clearSceneSearchResults={clearSceneSearchResults}
                    />
                </div>}

                <div className={`box mt-1`}>
                    <ElementSelection includeDialog={includeDialog}
                        onChangeIncludeDialog={onChangeIncludeDialog}
                        includeAction={includeAction}
                        onChangeIncludeAction={onChangeIncludeAction}
                        includeCharacter={includeCharacter}
                        onChangeIncludeCharacter={onChangeIncludeCharacter}
                        includeParenthetical={includeParenthetical}
                        onChangeIncludeParenthetical={onChangeIncludeParenthetical}
                        includeTransition={includeTransition}
                        onChangeIncludeTransition={onChangeIncludeTransition}
                        includeGeneral={includeGeneral}
                        onChangeIncludeGeneral={onChangeIncludeGeneral} />
                </div>
                <div className={`box mt-1`}>
                    <FilmanizeCheckbox label="Include title page" checked={includeTitle} onChange={onSetIncludeTitle} />
                    <FilmanizeCheckbox label="Show highlighting" checked={showMarkup} onChange={onSetShowMarkup} />
                </div>
                <Download
                    progress={progress}
                    clearPdf={clearPdf}
                    download={download}
                    downloadFile={onDownloadFile} />
                <div className="mt-1">
                    <button onClick={onClose} className="button">Close</button>
                </div>
            </div>
        </ScrollIntoView>
    )
};

function mapStateToProps(state) {
    const downloadScriptState = state.scriptState.downloadScriptState;
    const { form } = state;
    return {
        allCharacters: downloadScriptState.characters,
        allSceneSettings: downloadScriptState.sceneSettings,
        selectedSceneIds: downloadScriptState.selectedSceneIds,
        loadingSceneSettings: downloadScriptState.loadingSceneSettings,
        readOnly: downloadScriptState.readOnly,
        searchedScenes: downloadScriptState.searchedScenes,
        selectSearchedScenes: downloadScriptState.selectSearchedScenes,
        searchingScenes: downloadScriptState.searchingScenes,
        errors: downloadScriptState.errors,
        reduxFormValues: form?.scriptDownloadScenesForm?.values
    };
}

export default connect(mapStateToProps, {
    fetchAllScenes,
    fetchAllCharacters,
    fetchAllSceneSettings,
    fetchScenesAssignedToProp,
    searchScenes,
    clearSceneSearchResults,
    setSceneAssignedToProp
})(DownloadScript);