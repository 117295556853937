import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {  
  renderTextField
} from "./../../shared/form-helpers";
import required from "./../../shared/validation";

function LoginForm(props) {
  const {
    handleSubmit,
    onResendEmailConfirmation,
    pristine,
    reset,
    submitting,    
    errors,    
    invitationErrors,
    submitFailed,
    invalid,
    emailAddressValue,
    isLoggedIn
  } = props;
  
const onClickResendEmailConfirmation = () => {
  onResendEmailConfirmation(emailAddressValue);
};

  const emailAddressNotConfirmedErrorMessage = "Email Address not confirmed. Please click the link in the email that was sent to you.";

  let emailAddressNotConfirmed = false;
  if (errors) {    
    var globalErrors = errors[""];
    if (globalErrors) {
      let emailAddressNotConfirmedIndex = globalErrors.indexOf(emailAddressNotConfirmedErrorMessage)
      if (emailAddressNotConfirmedIndex != -1) {
        emailAddressNotConfirmed = true;
      }
    }
  }

  return (        
    <>
              <h2 className="menu-label">Login</h2>

              {errors && <Errors errors={errors} messageToIgnore={emailAddressNotConfirmedErrorMessage} />}
              {invitationErrors && <Errors errors={invitationErrors} />}
              {emailAddressNotConfirmed && <div className="content">
                  <ul className="notification is-danger">            
                        <li>{emailAddressNotConfirmedErrorMessage}</li>
                        <button
                        type="button"
                        className={"button"}
                        onClick={onClickResendEmailConfirmation}>
                        Resend Email Confirmation
                      </button>      
                  </ul>
                </div>}

              {isLoggedIn && <div>You are already logged in. Go to <NavLink to={'/films'}>films</NavLink></div>}

              {!invitationErrors && !isLoggedIn && <form autoComplete="off" onSubmit={handleSubmit}>     
            
                    <Field
                        name="emailAddress"
                        label="Email Address"
                        component={renderTextField}
                        validate={[required]}
                        required
                      />           

                      <Field
                        name="password"
                        label="Password"
                        type="password"
                        component={renderTextField}
                        validate={[required]}
                        required
                      />
                  
                    <div className="buttons">
                      <button
                        type="submit"
                        className={
                          submitting
                            ? "button is-primary is-loading"
                            : "button is-primary"
                        }
                        disabled={submitting}>
                        Login
                      </button>
                      <button
                        type="button"
                        className="button is-secondary"
                        disabled={submitting}
                        onClick={reset}>
                        Reset Values
                      </button>            
                    </div>
                    <NavLink to={`/register`} className="mt-1">                              
                          Register
                        </NavLink>
                        
                        <NavLink to={{ pathname: '/forgot-password', state: { emailAddress: emailAddressValue } }} className="ml-1 mt-1">
                          Forgot password
                        </NavLink>

                    <FormErrors submitFailed={submitFailed} invalid={invalid} />            
                          
              </form>}
        </>
  );
}

let LoginForm2 = reduxForm({
  form: "loginForm",
  enableReinitialize: true
})(LoginForm);

const selector = formValueSelector('loginForm') // <-- same as form name
LoginForm2 = connect(
  state => {
    // can select values individually
    const emailAddressValue = selector(state, 'emailAddress')    
    return {
      emailAddressValue
    }
  }
)(LoginForm2)


export default LoginForm2;