import { getDate } from "date-fns";
import moment from "moment";
import confirm from "../../../../shared/modal/confirm";
import { CALENDAR_UPDATE_DATE, UPDATE_SHOOTING_CALENDAR } from "./actions";

interface ShootingDayOrderState {
  scenes: any[];
  loading: boolean;
  saving: boolean;
  days: any[];
  ignoreStartDateChange: boolean;
  daysOfWeek: any;
  pristine: boolean;
  readOnly: boolean;
  onboardingSteps: any[];
  allowChangingDayOrder: boolean;
}

export const OnboardingStep1ClassName = 'shooting-day-calendar-onboarding-step-1';
export const OnboardingStep2ClassName = 'shooting-day-calendar-onboarding-step-2';
export const OnboardingStep3ClassName = 'shooting-day-calendar-onboarding-step-3';
export const OnboardingStep4ClassName = 'shooting-day-calendar-onboarding-step-4';

const defaultState: ShootingDayOrderState = {
  scenes: [],
  days: [],
  loading: true,
  saving: false,
  ignoreStartDateChange: false,
  daysOfWeek: {},
  pristine: true,
  readOnly: false,
  allowChangingDayOrder: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Working Days',
      content: `        
      <p>Select which days of the week you will be filming on.</p>
      <p>This will move the days in the calendar automatically to skip these days and also stop you accidently putting a shooting day on one of these days.</p>      
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Calendar',
      content: `        
        <p>Drag and drop the days to the desired date. If you move a lowered number day, all the subsequent dates will move as well, respecting the working days. 
        Therefore it is recommend that you start with the first day, and then move subsequent days if not appropriate.</p>
        <p>You can click on a day to see what is being shot on that day, which will appear underneath the calendar. You can also update the date directly there, so if you want to move
        the day many months into the future, this might be quicker than dragging between many months.</p>
        <p>You cannot move dates for which call sheets have been sent. If you need to change the date, then use the cancel option on the call sheet page to inform all actors and crew that it has been cancelled. Then you will be able to move the date.</p>
      `
    }
  ]
};

const updateDay = (state, dayId, date, allowChangingDayOrder) => {
  let days = Array.from(state.days);
  const potentialDates: any = days.filter((d: any) => d.isPotentialDate);

  days = days.filter((d: any) => !d.isPotentialDate);
  var dayIndex = days.findIndex(
    (day: any) => day.id == dayId
  );
  var day: any = days[dayIndex];
  const newDate = new Date(date);
  var a = moment(day.start);
  var b = moment(newDate);
  const dayDifference = b.diff(a, "days");
  day.start = newDate;
  day.manual = true;

  const futurePotentialDates: any = potentialDates.filter((d: any) => d.start > newDate);

  // if last day  
  if (dayIndex == days.length - 1 || allowChangingDayOrder) {
    return [...days, ...potentialDates];
  }

  const hasPotentialDays = potentialDates.length > 0;
  const totalPotentialDays = futurePotentialDates.length;

  const nextDay: any = days[dayIndex + 1];
  // if there is a day on this new day then shift them all forwards
  // if the next day's date is now less than this date, then move
  if (nextDay.start <= day.start) {
    let currentDay = new Date(day.start);
    if (hasPotentialDays && dayIndex + 1 < totalPotentialDays) {
      currentDay = new Date(futurePotentialDates[0].start.getTime());
    } else {
      currentDay.setDate(currentDay.getDate() + 1);
    }
    let potentialDatesCount = 0;
    for (let i = dayIndex + 1; i < days.length; i++) {
      let nextDay: any = days[i];

      if (hasPotentialDays) {
        if (potentialDatesCount < totalPotentialDays) {
          currentDay = new Date(futurePotentialDates[potentialDatesCount].start.getTime());
          potentialDatesCount++;
        }
        else {
          //currentDay.setDate(lastPotentialDay.getDate() + daysOverPotentialDayCount);

          while (!state.daysOfWeek[currentDay.getDay()].selected) {
            //currentDay = moment(currentDay).add(1, 'd').toDate();
            currentDay.setDate(currentDay.getDate() + 1);
          }

        }
      } else {
        while (!state.daysOfWeek[currentDay.getDay()].selected) {
          //currentDay = moment(currentDay).add(1, 'd').toDate();
          currentDay.setDate(currentDay.getDate() + 1);
        }
      }

      // if (!nextDay.manual) {
      //   nextDay.start = new Date(currentDay);
      // } else if (dayDifference > 0) {              
      //     const nextDayDate = new Date(nextDay.start);
      //     nextDayDate.setDate(nextDayDate.getDate() + dayDifference);
      //     nextDay.start = nextDayDate;              
      // }


      nextDay.start = new Date(currentDay);

      currentDay.setDate(currentDay.getDate() + 1);
    }
  }

  return [...days, ...potentialDates];
}

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "UPDATE_EVENT": {
      const plainEventObject = action.meta.plainEventObject;
      const allowChangingDayOrder = action.meta.allowChangingDayOrder;
      const days = updateDay(state, plainEventObject.id, plainEventObject.start, allowChangingDayOrder);

      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case CALENDAR_UPDATE_DATE: {
      const days = updateDay(state, action.meta.dayId, action.meta.newDate, action.meta.allowChangingDayOrder);

      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case "CHANGE_START_DATE": {
      if (true || state.ignoreStartDateChange) {
        return state;
      }
      const days = Array.from(state.days);
      const startDate = new Date(action.meta.date);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      days.forEach((day, index) => {
        const tomorrow = new Date(startDate);
        tomorrow.setDate(tomorrow.getDate() + index);
        day.start = tomorrow;
      });
      return {
        ...state,
        days: days
      };
    }

    case "CHANGE_DAY_OF_WEEK": {
      var daysOfWeek = { ...state.daysOfWeek };

      const dayKeys = Object.keys(daysOfWeek);
      let selectedDaysOfWeek = 0;
      dayKeys.forEach(dayKey => {
        if (daysOfWeek[dayKey].selected) {
          selectedDaysOfWeek++;
        }
      });

      if (action.meta.selected) {
        selectedDaysOfWeek++;
      } else {
        selectedDaysOfWeek--;
      }

      if (selectedDaysOfWeek === 0) {
        return { ...state };
      }

      var dayOfWeek: any = daysOfWeek[+action.meta.dayOfWeek];
      dayOfWeek.selected = action.meta.selected;

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const days = Array.from(state.days).sort((a, b) => a.number - b.number);

      if (days.length === 0) {
        return {
          ...state,
          daysOfWeek: daysOfWeek,
          pristine: false
        };
      }

      // find any days on days of week

      let daysOnDaysOfWeekNotAllowed = days.filter(d => !d.isPotentialDate && !daysOfWeek[d.start.getDay()].selected && d.editable);
      const daysLength = days.length;
      let notAllowedCount = 0
      let noSolutionFound = false;
      while (daysOnDaysOfWeekNotAllowed.length > 0) {

        daysOnDaysOfWeekNotAllowed.forEach(day => {
          let nextDay = addDay(day.start);
          while (!daysOfWeek[nextDay.getDay()].selected) {
            nextDay = addDay(nextDay);
          }

          day.start = nextDay;

          //if nextDay conatains an event, add a day to that
          let sameDayEvent = days.find(d => !d.isPotentialDate && d.start.toISOString() == nextDay.toISOString() && d.id != day.id);
          while (sameDayEvent) {
            nextDay = addDay(sameDayEvent.start);
            if (!sameDayEvent.editable) {
              noSolutionFound = true;
              break;
            }
            sameDayEvent.start = nextDay;

            sameDayEvent = days.find(d => !d.isPotentialDate && d.start.toISOString() == nextDay.toISOString() && d.id != sameDayEvent.id);
          }
        });

        if (noSolutionFound) {
          break;
        }

        daysOnDaysOfWeekNotAllowed = days.filter(d => !d.isPotentialDate && !daysOfWeek[d.start.getDay()].selected && d.editable);
        notAllowedCount++;
        if (notAllowedCount > daysLength) {
          noSolutionFound = true;
          break;
        }
      }

      if (noSolutionFound) {
        confirm(null, null, "No solution found, cannot disable this day of week.", "Error");
        return { ...state };
      }

      // if (daysOnDaysOfWeekNotAllowed.length === 0) {
      //   return {
      //     ...state,
      //     daysOfWeek: daysOfWeek,
      //     pristine: false
      //   };
      // }

      // if (daysAfterToday.length > 0)
      // {
      //   let currentDay = new Date(daysAfterToday[0].start);
      //   days.forEach((day, index) => {
      //     if (day.start >= today) {
      //       while (!daysOfWeek[currentDay.getDay()].selected) {
      //         currentDay.setDate(currentDay.getDate() + 1);
      //       }

      //       day.start = new Date(currentDay);
      //       currentDay.setDate(currentDay.getDate() + 1);
      //     }
      //   });
      // }

      return {
        ...state,
        daysOfWeek: daysOfWeek,
        days: days,
        pristine: false
      };
    }

    case "FETCH_SHOOTING_CALEDAR_PENDING": {
      return {
        ...state,
        loading: true,
        days: [],
        errors: null
      };
    }

    case "FETCH_SHOOTING_CALEDAR_FULFILLED": {
      let days = action.payload.data.days;
      days.forEach((day) => {
        day.allDay = true;
        day.start = new Date(day.start);
        day.manual = false;
        day.title = `Day ${day.number}`;
      });

      let potentialDates = action.payload.data.potentialDates;
      potentialDates.forEach(d => {
        d.start = new Date(d.start);
      });

      potentialDates.forEach((d) => {
        days.push({ id: d.id, display: 'background', start: new Date(d.start), allDay: true, backgroundColor: "#00ffce", borderColor: "#00ffce", textColor: "#040f21", editable: false, isPotentialDate: true })
      });

      let daysOfWeekArray = action.payload.data.daysOfWeek;
      let daysOfWeekDictionary = {};
      daysOfWeekArray.forEach((dayOfWeek) => {
        daysOfWeekDictionary[dayOfWeek.dayOfWeek] = dayOfWeek;
      });

      return {
        ...state,
        days: days,
        daysOfWeek: daysOfWeekDictionary,
        potentialDates: potentialDates,
        readOnly: action.payload.data.readOnly,
        allowChangingDayOrder: action.payload.data.allowChangingDayOrder,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case "FETCH_SHOOTING_CALEDAR_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "ADD_SCENES_SHOOTING_ORDER_DAY": {
      const days = Array.from(state.days);
      days.push({ number: days.length + 1, scenes: [] });
      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case UPDATE_SHOOTING_CALENDAR + "_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case UPDATE_SHOOTING_CALENDAR + "_FULFILLED": {
      const days = Array.from(state.days);
      const returnedDays = action.payload.data.days;

      days.forEach((day) => {
        const foundDay = returnedDays.find(d => d.id === day.id);
        if (foundDay) {
          day.start = new Date(foundDay.date);
          day.number = foundDay.number;
          day.title = `Day ${day.number}`;
        }
      });
      return {
        ...state,
        days: days,
        saving: false,
        errors: null,
        pristine: true
      };
    }

    case UPDATE_SHOOTING_CALENDAR + "_REJECTED": {
      return {
        ...state,
        days: action.meta.days,
        errors: action.payload.response.data.errors,
        saving: false
      };
    }

    default:
      return state;
  }
};

function addDay(date) {
  var result = new Date(date);
  result.setDate(result.getDate() + 1);
  return result;
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default reducer;
