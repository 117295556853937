import Progress from "../../../shared/progress";

const SendPotentialDates = ({
  sendSchedule, onSendToSelf, progress, readOnly }) => {

  return (
    <div className="mb-1">

      {!readOnly && <div className="buttons mt-1">
        <button className="button mt-1" onClick={sendSchedule}>Send to actors and crew</button>
        <button className="button mt-1" onClick={onSendToSelf}>Send to self</button>
      </div>}
      {progress && progress.progress < 100 && <Progress progress={progress} />}

    </div>
  );
};


export default SendPotentialDates;
