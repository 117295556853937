import Moment from "react-moment";
import formatBytes from "../../../shared/formatBytes";

const Versions = (props) => {
    const { versions } = props;
    return (
        <div>
            {versions?.length > 0 &&
                <div>
                    <label className="label">Versions</label>
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>File Name</th>
                                <th>Uploaded</th>
                                <th>Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {versions.map((version: any) => (
                                <tr>
                                    <td>{version.version}</td>
                                    <td>{version.fileName}</td>
                                    <td><Moment format="DD/MM/YYYY HH:ss">{version.added}</Moment></td>
                                    <td>{formatBytes(version.fileSize)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default Versions;