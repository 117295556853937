import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Errors from "./../../shared/errors";
import { renderCurrencyField, renderTextField, renderTextAreaField, renderUrlField, renderCheckbox, renderSingleReactSelect } from "./../../shared/form-helpers";
import required, { maxDecimalValue } from "./../../shared/validation";
import { connect } from "react-redux";

let EquipmentForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    acquisitionMethods,
    acquisitionMethodValue
  } = props;
  const thisEquipment = props.initialValues || {};
  const readOnly = thisEquipment.readOnly;
  const acquisitionMethodOptions = (acquisitionMethods || []).map((item) => { return { value: item.value, label: item.name } });

  const [editUrl, setEditUrl] = useState(false);

  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="name"
          label="Name"
          component={renderTextField}
          validate={[required]}
          required
          readOnly={readOnly}
        />

        <Field
          name="notes"
          label="Notes"
          component={renderTextAreaField}
          readOnly={readOnly}
        />

        <div className="mb-1">
          <Field
            name="required"
            label="Required"
            component={renderCheckbox}
            readOnly={readOnly}
          />
        </div>

        <Field
          name="link"
          label="Link"
          component={renderUrlField}
          setEditUrl={setEditUrl}
          editUrl={editUrl}
          readOnly={readOnly}
        />

        <Field
          name="acquisitionMethod"
          label="Acquisition Method"
          component={renderSingleReactSelect}
          options={acquisitionMethodOptions}
          validate={[required]}
          required
          readOnly={readOnly}>
        </Field>

        {acquisitionMethodValue != 3 && acquisitionMethodValue != 4 &&
          <Field
            name="buyBudget"
            label="Buy Budget"
            component={renderCurrencyField}
            validate={[maxDecimalValue]}
            readOnly={readOnly}></Field>
        }

        {acquisitionMethodValue == 3 &&
          <>
            < Field
              name="dailyRate"
              label="Daily Rate"
              component={renderCurrencyField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="threeDayRate"
              label="Three Day Rate"
              component={renderCurrencyField}
              readOnly={readOnly}
            />

            <Field
              name="weeklyRate"
              label="Weekly Rate"
              component={renderCurrencyField}
              readOnly={readOnly}
            />

            <Field
              name="twoWeeklyRate"
              label="Two Weekly Rate"
              component={renderCurrencyField}
              readOnly={readOnly}
            />

            <Field
              name="deliveryAndCollectionCost"
              label="Delivery And Collection Cost"
              component={renderCurrencyField}
              readOnly={readOnly}
            />
          </>}

        {!readOnly && <div className="buttons sticky-buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !thisEquipment}
              onClick={() => handleDelete(thisEquipment)}>
              Delete
            </button>
          )}
        </div>}
      </form>
    </>
  );
}

// export default reduxForm({
//   form: "equipmentForm",
//   enableReinitialize: true
// })(EquipmentForm);


EquipmentForm = reduxForm({
  form: "equipmentForm",
  enableReinitialize: true
})(EquipmentForm);

// Decorate with connect to read form values
const selector = formValueSelector('equipmentForm'); // <-- same as form name
const EquipmentFormConnect = connect(
  state => {
    // can select values individually
    const acquisitionMethodValue = selector(state, 'acquisitionMethod')

    return {
      acquisitionMethodValue
    }
  }
)(EquipmentForm)

export default EquipmentFormConnect
