import React from "react";
import { Switch, Route } from "react-router-dom";
import CharacterMenu from "./menu";
import ListBackgroundCharactersContainer from "./list-container";
import AddBackgroundCharacter from "./add";
import EditBackgroundCharacter from "./edit";
import { PrivateRoute } from "../../../shared/private-route";

// import MemberRoutes from "./members/routes";

const CharacterRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/background-characters"
        component={ListBackgroundCharactersContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/background-characters/add"
        component={AddBackgroundCharacter}
      />
      {/* <Route path="/films/:filmId/scenes/:sceneId/characters/:characterId" component={CharacterMenu} /> */}
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/background-characters/:backgroundCharacterId/edit"
        component={EditBackgroundCharacter}
      />
      {/* <MemberRoutes /> */}
    </>
  );
};

export default CharacterRoutes;
