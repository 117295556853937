import { Link } from "react-router-dom";
import CkEditorContent from "../../../shared/controls/ckeditor-content";
import Moment from "react-moment";

function ListFeatureRequestsRow(props) {
  const {
    featureRequest
  } = props;

  const description = featureRequest.description + (featureRequest.description?.length === 50 ? "..." : "");

  return (

    <tr>
      <td className="feature-request-list-table-title">
        <Link className="" to={`/feature-request/${featureRequest.id}`}>{featureRequest.title}</Link>
        <div className="feature-request-list-table-description">
          <CkEditorContent content={description} />
        </div>
      </td>
      <td className="feature-request-line-votes">
        <div className="feature-request-list-icons-container">
          <i className="material-icons feature-request-comment-icon" title="Votes">thumb_up</i>&nbsp;{featureRequest.totalVotes}&nbsp;&nbsp;&nbsp;
          <i className="material-icons feature-request-comment-icon" title="Comments">comment</i>&nbsp;{featureRequest.totalComments}
          <div className="ml-1">
            {featureRequest.status >= 5 && <i className="material-icons" title="Completed">check</i>}
            {featureRequest.status === 0 && <i className="material-icons" title="New">star</i>}
            {featureRequest.status === 2 && <i className="material-icons" title="On Roadmap">menu</i>}
            {featureRequest.status === 3 && <i className="material-icons" title="In Progress">more_horiz</i>}
          </div>
          <div className="ml-05">
            {featureRequest.priority === 0 && <i className="material-icons" title="Low Priority">arrow_downward</i>}
            {featureRequest.priority === 1 && <i className="material-icons" title="Medium Priority">remove</i>}
            {featureRequest.priority === 2 && <i className="material-icons" title="High Priority">arrow_upward</i>}
          </div>
        </div>
        <div className="feature-request-list-date">
          <span><Moment fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{featureRequest?.edited}</Moment></span>
          {/* {featureRequest?.submitted && !featureRequest?.edited && !featureRequest?.completed && <span className="ml-1"><Moment fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{featureRequest?.submitted}</Moment></span>}
          {featureRequest?.completed && <span><Moment fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{featureRequest?.completed}</Moment></span>}
          {featureRequest?.edited && !featureRequest?.completed && <span><Moment fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{featureRequest?.edited}</Moment></span>} */}
        </div>
      </td>
      {/* <td className="feature-request-line-votes">
        <i className="material-icons" title="Votes">thumb_up</i>&nbsp;{featureRequest.totalVotes}&nbsp;&nbsp;&nbsp;
      </td>
      <td className="feature-request-line-votes">
        <i className="material-icons feature-request-comment-icon" title="Comments">comment</i>&nbsp;{featureRequest.totalComments}
      </td>
      <td className="feature-request-line-votes">
        {featureRequest.status >= 5 && <i className="material-icons feature-request-comment-icon" title="Completed">check</i>}
        {featureRequest.status === 0 && <i className="material-icons feature-request-comment-icon" title="New">star</i>}
        {featureRequest.status === 2 && <i className="material-icons feature-request-comment-icon" title="On Roadmap">menu</i>}
        {featureRequest.status === 3 && <i className="material-icons feature-request-comment-icon" title="In Progress">more_horiz</i>}
      </td>
      <td className="feature-request-line-votes">
        {featureRequest.priority === 0 && <i className="material-icons feature-request-comment-icon" title="Low Priority">arrow_downward</i>}
        {featureRequest.priority === 1 && <i className="material-icons feature-request-comment-icon" title="Medium Priority">remove</i>}
        {featureRequest.priority === 2 && <i className="material-icons feature-request-comment-icon" title="High Priority">arrow_upward</i>}
      </td> */}
    </tr>
  );
}

export default ListFeatureRequestsRow;