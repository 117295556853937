import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchEquipmentInScene } from "./ducks/actions";
import List from "./list";

function ListCostumesContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchEquipmentInScene, loading, sceneEquipmentCategories, errors } = props;

  useEffect(() => {
    fetchEquipmentInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(sceneEquipmentCategories, filmId, sceneId) {
    return (

      <List sceneEquipmentCategories={sceneEquipmentCategories} filmId={filmId} sceneId={sceneId} />

    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneEquipmentCategories, filmId, sceneId);
}

function mapStateToCostumes(state) {
  return {
    sceneEquipmentCategories: state.scenesState.equipmentState.equipmentCategories,
    loading: state.scenesState.equipmentState.loading,
    errors: state.scenesState.equipmentState.errors
  };
}

export default connect(mapStateToCostumes, {
  fetchEquipmentInScene
})(ListCostumesContainer);
