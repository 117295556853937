import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export function fetchBackgroundCharactersInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BACKGROUND_CHARACTERS_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/BackgroundCharacters`),
    });
  };
}

export function clearBackgroundCharacterInScene() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_BACKGROUND_CHARACTER_IN_SCENE",
    });
  };
}

export function fetchBackgroundCharacter(filmId, sceneId, backgroundCharacterId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_BACKGROUND_CHARACTER_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/BackgroundCharacter/${backgroundCharacterId}`),
    });
  };
}

export function updateBackgroundCharacter(filmId, sceneId, backgroundCharacter) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_BACKGROUND_CHARACTER_IN_SCENE",
      payload: getApiClient().put(`/film/${filmId}/Scene/${sceneId}/BackgroundCharacter/${backgroundCharacter.backgroundCharacterId}`, backgroundCharacter),
      meta: { backgroundCharacter },
    })
      .then((response) => {
        dispatch(showInfo(`Updated background character in scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating background character in scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addBackgroundCharacter(filmId, sceneId, backgroundCharacter, fromBreakdown) {
  backgroundCharacter.mainImageUrl = null;
  backgroundCharacter.imageUrls = [];

  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_BACKGROUND_CHARACTER_TO_SCENE",
      payload: getApiClient().post(`/film/${filmId}/Scene/${sceneId}/BackgroundCharacter/${backgroundCharacter.characterId}`, backgroundCharacter),
      meta: { backgroundCharacter, fromBreakdown },
    })
      .then((response) => {
        dispatch(showInfo(`Added background character to scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding background character`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteBackgroundCharacter(filmId, sceneId, backgroundCharacter) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_BACKGROUND_CHARACTER_IN_SCENE",
      payload: getApiClient().delete(`/film/${filmId}/Scene/${sceneId}/BackgroundCharacter/${backgroundCharacter.backgroundCharacterId}`),
      meta: { backgroundCharacter },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted background character from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting background character from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
