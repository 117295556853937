import memoizeOne from "memoize-one";
import { Droppable } from "react-beautiful-dnd";
import SceneShot from "./sceneShot";
import { formatTime, formatTimeFromMinutes, formatTimeFromSeconds } from "../../../shared/format-time";

const Scene = ({
  scene,
  index,
  filmId,
  shootingdayId,
  selectedShotIds,
  draggingShotId,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  readOnly,
  canAccessShots
}) => {

  type SceneIdMap = {
    [sceneId: string]: true;
  };

  const getSelectedMap = memoizeOne((selectedSceneIds: string[]) =>
    selectedSceneIds.reduce(
      (previous: SceneIdMap, current: string): SceneIdMap => {
        previous[current] = true;
        return previous;
      },
      {}
    )
  );

  const totalDuration = scene.shots.reduce(function (a, b) {
    return a + b.estimatedShootingDurationMinutes;
  }, 0)
  return (
    <>    <Droppable
      droppableId={scene.id}
      key={scene.id}
      type={"shot" + scene.sceneSetting?.id}
      isDropDisabled={true}>
      {(provided, snapshot) => (
        <tbody
          key={index}
          {...provided.droppableProps}
          ref={provided.innerRef}>
          <tr>
            <th colSpan={5} className="table-heading">
              Scene {scene.number} - {scene.interiorExterior}{" "}
              {scene.dayNight} {scene.sceneSetting?.name}{" "}
              {scene.sceneSetting.location ? " - " : ""}
              {scene.sceneSetting.location?.name}{" "}
              {scene.sceneSetting.subLocation
                ? "(" +
                scene.sceneSetting.subLocation.name +
                ")"
                : ""}
            </th>
          </tr>
          {scene.shots && scene.shots.length == 0 && (
            <tr>
              <td colSpan={5}>No more shots</td>
            </tr>
          )}

          {(scene.shots || []).map((shot, index) => {
            const selectionMap = getSelectedMap(
              selectedShotIds || []
            );
            const isSelected = selectionMap
              ? selectionMap[shot.shotId]
              : false;
            const isGhosting =
              isSelected &&
              draggingShotId &&
              draggingShotId !== shot.shotId;
            /*
            console.log("isSelected " + shot.shotId, isSelected);
            console.log("draggingShotId", draggingShotId);
            console.log("isGhosting " + shot.shotId, isGhosting);
            */
            return (
              <SceneShot
                filmId={filmId}
                shootingdayId={shootingdayId}
                shot={shot}
                index={index}
                key={shot.shotId}
                isSelected={isSelected}
                isGhosting={isGhosting}
                selectionCount={(selectedShotIds || []).length}
                toggleSelection={toggleSelection}
                toggleSelectionInGroup={toggleSelectionInGroup}
                multiSelectTo={multiSelectTo}
                readOnly={readOnly}
                canAccessShots={canAccessShots}></SceneShot>
            );
          })}
          {provided.placeholder}
          {totalDuration > 0 && <tr>
            <td colSpan={4} className="has-text-right">
              Total Shot Shooting Duration
            </td>
            <td>{formatTimeFromMinutes(totalDuration)}</td>

          </tr>}

          {scene.estimatedShootingDuration && <tr>
            <td colSpan={4} className="has-text-right">
              Estimated Scene Shooting Duration
            </td>
            <td>{formatTime(scene.estimatedShootingDuration)}</td>

          </tr>}
        </tbody>
      )}
    </Droppable>
    </>
  );
};

export default Scene;
