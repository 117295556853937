import getApiClient, { getAnnonymousApiClient } from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export function setEmailAddress(emailAddress) {
  return (dispatch) => {
    dispatch({
      type: "SET_FORGOT_PASSWORD_EMAIL_ADDDRESS",
      meta: { emailAddress }
    });
  };
}

export function forgotPassword(user) {
  return (dispatch) => {
    dispatch({
      type: "FORGOT_PASSWORD",
      payload: getAnnonymousApiClient().post(`/account/ForgotPassword`, user),
      meta: { user }
    }).then((response) => {      
      dispatch(showInfo(`Successfully sent email`));
    })
    .catch((response) => {
      dispatch(showError(`Error sending email`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}