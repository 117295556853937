import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

export function fetchShootingDayCrew(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_CREW",
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Crew`
      )
    });
  };
}

export function updateShootingDayCrew(filmId, shootingDayId, crew) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SHOOTING_DAY_CREW",
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Crew`,
        crew
      ),
      meta: { crew }
    })
      .then((response) => {
        dispatch(showInfo(`Updated Shooting Day Crew`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day Crew`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      })
      .then(() => {
        dispatch({
          type: "RESET_SHOOTING_DAY_CREW"
        });
      });
  };
}
