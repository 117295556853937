import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../../shared/modal/confirm";
import Loader from "../../../shared/loader";
import {
  clearRedirect,
  deleteCostume,
  fetchCostume,
  updateCostume,
  uploadImagesAndSaveCostume,
  fetchAllCharacters,
  fetchAllBackgroundCharacters,
  changeCostumeToProp
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function EditCostume(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const costumeId = props.costumeId || props.match.params.costumeId;

  const {
    fetchCostume,
    fetchAllCharacters,
    fetchAllBackgroundCharacters,
    updateCostume,
    changeCostumeToProp,
    uploadImagesAndSaveCostume,
    deleteCostume,
    loadingCostume,
    costume,
    errors,
    embedded,
    allCharacters,
    allBackgroundCharacters
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {
    if (invalidIds.indexOf(costumeId) === -1) {
      fetchCostume(filmId, costumeId);
      fetchAllCharacters(filmId);
      fetchAllBackgroundCharacters(filmId);
    }
  }, [filmId, costumeId]);

  useEffect(() => {
    if (costume && allCharacters) {
      costume.characters = [];
      costume.characterIds?.forEach(characterId => {
        const character = allCharacters.find((c) => c.id === characterId);
        if (character) {
          costume.characters.push({ value: characterId, label: character.name });
        }
      });
    }
  }, [costume, allCharacters]);

  useEffect(() => {
    if (costume && allBackgroundCharacters) {
      costume.backgroundCharacters = [];
      costume.backgroundCharacterIds?.forEach(backgroundCharacterId => {
        const backgroundCharacter = allBackgroundCharacters.find((c) => c.id === backgroundCharacterId);
        if (backgroundCharacter) {
          costume.backgroundCharacters.push({ value: backgroundCharacterId, label: backgroundCharacter.name })
        }
      });
    }
  }, [costume, allBackgroundCharacters]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/costumes/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "prop") {
      props.clearRedirect();
      const url = `/films/${filmId}/props/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(costumeId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderCostume(costume) {
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/costumes/:costumeId/edit"
        loading={loadingCostume}>
        {costume && <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          costumeId={costume.id}
          initialValues={costume}
          loading={loadingCostume}
          onSubmit={onUpdateCostume}
          onChangeCostumeToProp={onChangeCostumeToProp}
          handleDelete={onDeleteCostume}
          reduxFormValues={props.reduxFormValues}
          acquisitionMethodEnum={props.acquisitionMethodEnum}
          allCharacters={allCharacters}
          allBackgroundCharacters={allBackgroundCharacters}
        />}
      </ScrollIntoView>
    );
  }

  function onUpdateCostume(costume) {
    costume.characterIds = costume.characters?.map((x) => x.value || x);
    costume.backgroundCharacterIds = costume.backgroundCharacters?.map((x) => x.value || x);
    const files = costume.pictures || [];

    if ((files && files.length) > 0 || (costume.images && costume.images.length > 0)) {
      uploadImagesAndSaveCostume(costume, files, false, filmId);
    } else {
      updateCostume(filmId, costume);
    }
  }

  function onDeleteCostume(costume) {
    confirm(() => deleteCostume(filmId, costume), costume?.name);
  }

  const onChangeCostumeToProp = (costume) => {
    confirm(() => changeCostumeToProp(filmId, costume), null, `Are you sure you want to change ${costume.name} into a Prop?`, "Change Costume");
  };

  return costume && !loadingCostume ? renderCostume(costume) : renderLoading();
}

function mapStateToCostumes(state) {
  const { costumesState, commonState } = state;
  return {
    costume: costumesState.costume,
    errors: costumesState.errors,
    redirect: costumesState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod,
    allCharacters: costumesState.characters,
    allBackgroundCharacters: costumesState.backgroundCharacters
  };
}

export default
  connect(mapStateToCostumes, {
    fetchCostume,
    updateCostume,
    deleteCostume,
    clearRedirect,
    uploadImagesAndSaveCostume,
    fetchAllCharacters,
    fetchAllBackgroundCharacters,
    changeCostumeToProp
  })(EditCostume);
