import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../shared/loader";
import { fetchActors } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListActorsContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchActors, loading, actors, errors, readOnly } = props;

  useEffect(() => {
    fetchActors(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderActors(actors, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/actors"
        loading={loading}>
        <List actors={actors} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/actors/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Actor
        </NavLink>}
        {/*
        <NavLink
          to={`/films/${filmId}/actors/add-from-mandy`}
          activeClassName="is-active"
          className="button is-text is-small mt-1">
          <i className="material-icons">add</i>
          Add from Mandy.com
        </NavLink>
        <NavLink
          to={`/films/${filmId}/actors/add-from-starnow`}
          activeClassName="is-active"
          className="button is-text is-small mt-1">
          <i className="material-icons">add</i>
          Add from StarNow
        </NavLink>
        */}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderActors(actors, filmId);
}

function mapStateToProps(state) {
  const actorsState = state.actorsState.rootState;
  return {
    actors: actorsState.actors,
    readOnly: actorsState.readOnly,
    loading: actorsState.loading,
    errors: actorsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchActors
})(ListActorsContainer);
