import getApiClient from "./../../../../shared/api";
import { connection } from "../../../../middleware/messageSignalRMiddleware";
import { HubConnectionState } from "@microsoft/signalr";

export const FETCH_AVAILABILITY = "FETCH_AVAILABILITY";

export function fetchAvailability(filmId, refetch) {
  if (connection.state === HubConnectionState.Disconnected) {
    connection.start().then(() => connection.invoke('SubscribeToFilm', filmId));
  }

  return (dispatch) => {
    dispatch({
      type: FETCH_AVAILABILITY,
      payload: getApiClient().get(`/film/${filmId}/scheduling/availability`),
      meta: { refetch }
    });
  };
}
