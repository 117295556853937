import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchShootingDayShotList, sendShotListToCrew, sendShotListToSelf } from "./ducks/actions";
import EditShotListForm from "./edit-form";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";

function ListShootingDayCrewContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const { fetchShootingDayShotList, sendShotListToCrew, sendShotListToSelf, loading, shotList, errors } = props;

  useEffect(() => {
    fetchShootingDayShotList(filmId, shootingdayId);
  }, [fetchShootingDayShotList, filmId, shootingdayId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderShootingDays(shotList, filmId) {
    return (
      <div className="blade-content wider">
        <p className="menu-label">Shot List</p>
        <EditShotListForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ shotList }}
          loading={loading}
          sendShotListToSelf={sendShotListToSelf}
          sendShotListToCrew={sendShotListToCrew}
          {...props}
        />
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(shotList, filmId);
}

function mapStateToProps(state) {
  return {
    shootingDay: state.shootingdaysState.rootState.shootingday,
    shotList: state.shootingdaysState.shotListState.shotList,
    loading: state.shootingdaysState.shotListState.loading,
    errors: state.shootingdaysState.shotListState.errors,
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayShotList,
  sendShotListToCrew,
  sendShotListToSelf
})(ListShootingDayCrewContainer);
