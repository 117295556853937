import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

export const UPLOAD_RISK_ASSESSMENT = "UPLOAD_RISK_ASSESSMENT";
export const UPLOAD_DAILY_RISK_ASSESSMENT = "UPLOAD_DAILY_RISK_ASSESSMENT";
export const FETCH_RISK_ASSESSMENTS = "FETCH_RISK_ASSESSMENTS";
export const UPLOAD_RISK_ASSESSMENT_ERROR = "UPLOAD_RISK_ASSESSMENT_ERROR";
export const DELETE_RISK_ASSESSMENT = "DELETE_RISK_ASSESSMENT";
export const DELETE_DAILY_RISK_ASSESSMENT = "DELETE_DAILY_RISK_ASSESSMENT";

export function fetchRiskAssessments(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_RISK_ASSESSMENTS,
      payload: getApiClient().get(`/film/${filmId}/RiskAssessment/${shootingDayId}`),
    });
  };
}

export function uploadRiskAssessment(formData, filmId) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_RISK_ASSESSMENT,
      payload: getApiClient().post(`/film/${filmId}/RiskAssessment`, formData, {
        headers: {
          "content-type": `multipart/form-data`
        }
      })
    }).then((response) => {        
      dispatch(showInfo(`Successfully uploaded risk assessment`));                
    })
    .catch((response) => {
      dispatch(showError(`Error uploading risk assessment`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}

export function uploadDailyRiskAssessment(formData, filmId) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_DAILY_RISK_ASSESSMENT,
      payload: getApiClient().post(`/film/${filmId}/RiskAssessment/UploadDaily`, formData, {
        headers: {
          "content-type": `multipart/form-data`
        }
      })
    }).then((response) => {        
      dispatch(showInfo(`Successfully uploaded daily risk assessment`));                
    })
    .catch((response) => {
      dispatch(showError(`Error uploading daily risk assessment`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}

export function deleteRiskAssessment(filmId, fileId) {
  return (dispatch) => {
    dispatch({
      type: DELETE_RISK_ASSESSMENT,
      payload: getApiClient().delete(`/film/${filmId}/RiskAssessment/${fileId}`),
      meta: { fileId }
    }).then((response) => {        
      dispatch(showInfo(`Successfully deleted risk assessment`));                
    })
    .catch((response) => {
      dispatch(showError(`Error deleting risk assessment`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}

export function deleteDailyRiskAssessment(filmId, fileId) {
  return (dispatch) => {
    dispatch({
      type: DELETE_DAILY_RISK_ASSESSMENT,
      payload: getApiClient().delete(`/film/${filmId}/RiskAssessment/Daily/${fileId}`),
      meta: { fileId }
    }).then((response) => {        
      dispatch(showInfo(`Successfully deleted daily risk assessment`));                
    })
    .catch((response) => {
      dispatch(showError(`Error deleting daily risk assessment`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}

export function setUploadError(error) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_RISK_ASSESSMENT_ERROR,
      meta: { error }
    });
  };
}