import Tasks from "./container";
import { PrivateRoute } from "../../shared/private-route";

const TaskRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/tasks"
        component={Tasks}
      />
      
    </>
  );
};

export default TaskRoutes;
