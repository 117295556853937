import React from "react";
import { Link, Prompt } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import {
  renderCheckbox,
  renderTimeField
} from "./../../../shared/form-helpers";
import CanAccessLink from "../../../shared/can-access-link";

function ShootingDayBackgroundActorsForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    errors,
    readOnly,
    filmId,
    canAccessBackgroundActors
  } = props;
  const thisList = props.initialValues || {};
  const backgroundActors = { backgroundActors: thisList };

  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <FieldArray name="backgroundActors" component={actorsListForm} readOnly={readOnly} filmId={filmId} canAccessBackgroundActors={canAccessBackgroundActors} />
        <br />
        {!readOnly && <div className="buttons sticky-buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}
          >
            Save
          </button>
        </div>}
      </form>
    </>
  );
}

function actorsListForm({ fields, meta: { error }, readOnly, filmId, canAccessBackgroundActors }) {
  return (
    <div>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Name</th>
            <th>Call time</th>
            <th>On set time</th>
          </tr>
        </thead>
        <tbody>
          {(fields || []).map((actor, idx, records) => {
            return (
              <tr key={idx}>
                <td>
                  <Field
                    name={`${actor}.selected`}
                    component={renderCheckbox}
                    checked={`${actor}.selected`}
                    readOnly={readOnly}
                  />
                </td>
                <td><CanAccessLink to={`/films/${filmId}/background-actors/${records.get(idx).backgroundActorId}/edit`} canAccess={canAccessBackgroundActors}>{records.get(idx).fullName}</CanAccessLink></td>
                <td>
                  <Field
                    name={`${actor}.callTime`}
                    type="time"
                    component={renderTimeField}
                    step="300"
                    readOnly={readOnly}
                  />
                </td>
                <td>
                  <Field
                    name={`${actor}.onSetTime`}
                    type="time"
                    component={renderTimeField}
                    step="300"
                    readOnly={readOnly}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default reduxForm({
  form: "shootingDayBackgroundActorsForm",
  enableReinitialize: true
})(ShootingDayBackgroundActorsForm);
