import React, { useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import FilmaizeSelect from "../filmanize-select";
import { GoogleAddressParser } from "./google-address-parser";
import FilmaizeText from "../filmanize-text";

const LocationSearchInput = (props) => {

  const { countries, defaultCountryId, whatThreeWordsLookup, label } = props;

  const [address, setAddress] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState<any>(null);
  const [countryCode, setCountryCode] = React.useState<string>("gb");
  const [whatThreeWords, setWhatThreeWords] = React.useState("");

  const countryOptions = (countries || []).map((item) => {
    return { value: item.id, label: item.name };
  });

  useEffect(() => {
    if (defaultCountryId) {
      const foundCountryOption = countryOptions.find((c: any) => c.value == defaultCountryId)
      if (foundCountryOption) {
        setSelectedCountry(foundCountryOption);
      }

      const foundCountry = countries.find((c: any) => c.id == defaultCountryId)
      if (foundCountry) {
        setCountryCode(foundCountry.code);
      }
    }
  }, [defaultCountryId]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    const name = value.split(",")[0];
    const address = new GoogleAddressParser(
      name,
      results[0].address_components,
      latLng.lat,
      latLng.lng,
      results[0].place_id
    ).result();

    props.onUpdatePosition(latLng);
    props.onUpdateAddress(address);
    setAddress(value);
  };

  const searchWhatThreeWords = async (e) => {
    e.preventDefault();
    whatThreeWordsLookup(whatThreeWords);
  }

  const onSetAddress = (value) => {
    setAddress(value);
  };

  const searchOptions = {
    componentRestrictions: { country: [countryCode] }
  };

  const onChangeCountry = (country) => {
    const foundCountry = countries.find((c: any) => c.id == country.value)
    if (foundCountry) {
      setCountryCode(foundCountry.code);
    }

    setSelectedCountry(country);
  };



  const readOnly = props.readOnly;

  return (
    <div>

      <PlacesAutocomplete
        value={address}
        onChange={onSetAddress}
        onSelect={handleSelect}
        searchOptions={searchOptions}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="field">
            <label className="label">{label ? label : "Search"}</label>
            <input
              className="control input"
              {...getInputProps({
                placeholder: "Start typing any part of the address to search..."
              })}
            />
            {loading ? <div>...loading</div> : null}

            {suggestions.map((suggestion, idx) => {
              const style = {
                backgroundColor: suggestion.active ? "#00FFCE" : "#222E44",
                color: suggestion.active ? "#040F21" : "#ffffff",
                padding: "0.4rem"
              };

              return (
                <div key={idx} {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        )}
      </PlacesAutocomplete>
      <FilmaizeSelect
        value={selectedCountry}
        placeholder="Country"
        isMulti={false}
        options={countryOptions}
        className="react-select-control"
        classNamePrefix="react-select"
        onChange={onChangeCountry}
        isDisabled={readOnly}
      />

      {/* <div className="mt-2">
        <FilmaizeText label="What Three Words" value={whatThreeWords} onChange={setWhatThreeWords} />
        <button className="button" onClick={searchWhatThreeWords}>Find What Three Words</button>
      </div> */}
    </div>
  );
};

export default LocationSearchInput;
