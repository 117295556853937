import React from "react";
import { NavLink } from "react-router-dom";

const RolesList = ({ filmId, sceneId, backgroundCharacters }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Background Characters in scene</p>
      <ul className="menu-list">
        {(backgroundCharacters || []).map((backgroundCharacter, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/scenes/${sceneId}/background-characters/${backgroundCharacter.id}/edit`}
                activeClassName="is-active"
              >
                {backgroundCharacter.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default RolesList;
