import { FETCH_CHANGE_LOG, FETCH_CHANGE_LOGS } from './actions';

interface ActionState {
  changeLogs: any[];
  loading: boolean;
  changeLog: any;  
  continuationToken: string | null; 
}

const defaultState: ActionState = {
  changeLogs: [],
  loading: true,  
  changeLog: {},
  continuationToken: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_CHANGE_LOGS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }
    
    case FETCH_CHANGE_LOGS + "_FULFILLED": {
      let newChangeLogs: any[] = [];
      if (action.meta.append) {
        newChangeLogs = [...state.changeLogs, ...action.payload.data.changeLogs];
      } else {
        newChangeLogs = action.payload.data.changeLogs;
      }
      return {
        ...state,
        changeLogs: newChangeLogs,
        continuationToken: action.payload.data.continuationToken,        
        loading: false,
        errors: null
      };
    }

    case FETCH_CHANGE_LOG + "_PENDING" : {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_CHANGE_LOG + "_FULFILLED": {           
      return {
        ...state,
        changeLog: action.payload.data,
        loading: false,
        errors: null
      };
    }

    
    
    default:
      return state;
  }
};

export default reducer;
