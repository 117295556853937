import { FETCH_DAY_OUT_OF_DAYS, UPDATE_SHOOTING_DAY_ACTOR_ORDER, UPDATE_SHOOTING_DAY_SHOW_OFF_DAYS, DOOD_ACTOR_ORDER_NAME, DOWNLOAD_DAY_OUT_OF_DAYS, CLEAR_DOWNLOAD_DAY_OUT_OF_DAYS, DOWNLOADED_DAY_OUT_OF_DAYS, UPDATE_SHOOTING_DAY_DOOD_CREW_ORDER, CHECK_SHOOTING_DAYS, UPDATE_SHOOTING_DAY_DOOD_LOCATIONS_ORDER, DOWNLOAD_DAY_OUT_OF_DAYS_ERROR, UPDATE_SHOOTING_DAY_SHOW_CHARACTER } from "./actions";

interface DayOutOfDaysState {
  actors: any[];
  backgroundActors: any[];
  characters: any[];
  backgroundCharacters: any[];
  crewMembers: any[];
  locations: any[],
  days: any[];
  hasDates: boolean;
  hasOffDays: boolean;
  showOffDays: boolean;
  showCharacters: boolean;
  actorOrder: string;
  loading: boolean;
  readOnly: boolean;
  saving: boolean;
  download: any;
  progress: any;
  shootingDayErrors: any[];
  canAccessActors: boolean;
  canAccessBackgroundActors: boolean;
  canAccessCrew: boolean;
  includeActors: boolean,
  includeBackgroundActors: boolean,
  includeCrew: boolean,
  includePhoneNumbers: boolean,
  includeLocations: boolean
}

const defaultState: DayOutOfDaysState = {
  actors: [],
  backgroundActors: [],
  characters: [],
  backgroundCharacters: [],
  crewMembers: [],
  locations: [],
  days: [],
  shootingDayErrors: [],
  hasDates: false,
  hasOffDays: false,
  showOffDays: false,
  showCharacters: false,
  actorOrder: DOOD_ACTOR_ORDER_NAME,
  loading: true,
  readOnly: false,
  saving: false,
  download: null,
  progress: null,
  canAccessActors: false,
  canAccessBackgroundActors: false,
  canAccessCrew: false,
  includeActors: true,
  includeBackgroundActors: true,
  includeCrew: true,
  includePhoneNumbers: true,
  includeLocations: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_DAY_OUT_OF_DAYS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }

    case FETCH_DAY_OUT_OF_DAYS + "_FULFILLED": {
      return {
        ...state,
        actors: action.payload.data.actors,
        backgroundActors: action.payload.data.backgroundActors,
        characters: action.payload.data.characters,
        backgroundCharacters: action.payload.data.backgroundCharacters,
        crewMembers: action.payload.data.crewMembers,
        locations: action.payload.data.locations,
        days: action.payload.data.days,
        hasDates: action.payload.data.hasDates,
        hasOffDays: action.payload.data.hasOffDays,
        showOffDays: action.payload.data.showOffDays,
        shootingDaySettingsId: action.payload.data.shootingDaySettingsId,
        showCharacters: action.payload.data.showCharacters,
        actorOrder: action.payload.data.actorOrder,
        crewOrder: action.payload.data.crewOrder,
        locationOrder: action.payload.data.locationOrder,
        canAccessActors: action.payload.data.canAccessActors,
        canAccessBackgroundActors: action.payload.data.canAccessBackgroundActors,
        canAccessCharacters: action.payload.data.canAccessCharacters,
        canAccessBackgroundCharacters: action.payload.data.canAccessBackgroundCharacters,
        canAccessCrew: action.payload.data.canAccessCrew,
        canAccessLocations: action.payload.data.canAccessLocations,
        includeCharacters: action.payload.data.includeCharacters,
        includeBackgroundCharacters: action.payload.data.includeBackgroundCharacters,
        includeActors: action.payload.data.includeActors,
        includeBackgroundActors: action.payload.data.includeBackgroundActors,
        includeCrew: action.payload.data.includeCrew,
        includePhoneNumbers: action.payload.data.includePhoneNumbers,
        includeLocations: action.payload.data.includeLocations,
        loading: false,
        errors: null,
      };
    }

    case FETCH_DAY_OUT_OF_DAYS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload?.response?.data?.errors,
        loading: false,
      };
    }

    case UPDATE_SHOOTING_DAY_SHOW_OFF_DAYS + "_PENDING": {
      return {
        ...state,
        saving: true,
      };
    }

    case UPDATE_SHOOTING_DAY_SHOW_OFF_DAYS + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        errors: null,
      };
    }

    case UPDATE_SHOOTING_DAY_SHOW_OFF_DAYS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload?.response?.data?.errors,
        saving: false,
      };
    }

    case UPDATE_SHOOTING_DAY_ACTOR_ORDER + "_PENDING": {
      return {
        ...state,
        saving: true,
      };
    }

    case UPDATE_SHOOTING_DAY_ACTOR_ORDER + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        errors: null,
      };
    }

    case UPDATE_SHOOTING_DAY_ACTOR_ORDER + "_REJECTED": {
      return {
        ...state,
        errors: action.payload?.response?.data?.errors,
        saving: false,
      };
    }

    case UPDATE_SHOOTING_DAY_SHOW_CHARACTER + "_PENDING": {
      return {
        ...state,
        saving: true,
      };
    }

    case UPDATE_SHOOTING_DAY_SHOW_CHARACTER + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        errors: null,
      };
    }

    case UPDATE_SHOOTING_DAY_SHOW_CHARACTER + "_REJECTED": {
      return {
        ...state,
        errors: action.payload?.response?.data?.errors,
        saving: false,
      };
    }

    case UPDATE_SHOOTING_DAY_DOOD_CREW_ORDER + "_PENDING": {
      return {
        ...state,
        saving: true,
      };
    }

    case UPDATE_SHOOTING_DAY_DOOD_CREW_ORDER + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        errors: null,
      };
    }

    case UPDATE_SHOOTING_DAY_DOOD_CREW_ORDER + "_REJECTED": {
      return {
        ...state,
        errors: action.payload?.response?.data?.errors,
        saving: false,
      };
    }

    case UPDATE_SHOOTING_DAY_DOOD_LOCATIONS_ORDER + "_PENDING": {
      return {
        ...state,
        saving: true,
      };
    }

    case UPDATE_SHOOTING_DAY_DOOD_LOCATIONS_ORDER + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        errors: null,
      };
    }

    case UPDATE_SHOOTING_DAY_DOOD_LOCATIONS_ORDER + "_REJECTED": {
      return {
        ...state,
        errors: action.payload?.response?.data?.errors,
        saving: false,
      };
    }

    case DOWNLOAD_DAY_OUT_OF_DAYS + "_PENDING": {
      return {
        ...state,
        loading: false,
        errors: null,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case DOWNLOAD_DAY_OUT_OF_DAYS + "_FULFILLED": {
      return state;
    }

    case DOWNLOAD_DAY_OUT_OF_DAYS + "_COMPLETE": {
      return {
        ...state,
        loading: false,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case DOWNLOADED_DAY_OUT_OF_DAYS: {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    case CLEAR_DOWNLOAD_DAY_OUT_OF_DAYS: {
      return {
        ...state,
        download: null
      };
    }

    case DOWNLOAD_DAY_OUT_OF_DAYS + "_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case DOWNLOAD_DAY_OUT_OF_DAYS + "_ERRORED": {
      return {
        ...state,
        progress: null,
        errors: action.meta.errors
      };
    }

    case CHECK_SHOOTING_DAYS + "_PENDING": {
      return {
        ...state,
        saving: true,
        shootingDayErrors: []
      };
    }

    case CHECK_SHOOTING_DAYS + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        shootingDayErrors: action.payload.data.shootingDayErrors,
        errors: null
      };
    }

    case CHECK_SHOOTING_DAYS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload?.response?.data?.errors,
        saving: false,
      };
    }

    case DOWNLOAD_DAY_OUT_OF_DAYS_ERROR: {
      return {
        ...state,
        errors: { "": [action.meta.error] }
      };
    }

    default:
      return state;
  }
};

export default reducer;
