import { Link } from "react-router-dom";

  const HelpPanelButton = ({
    buttonIcon = null,
    buttonText = null,
    buttonLink = null,
    onButtonClick = () => {},
    children = null
  }: {
    buttonIcon?: any;
    buttonText?: any;
    buttonLink?: any;
    onButtonClick?: () => void;
    children?: any;
  }) => {
    return (
      <div className="help-panel-button">
        {buttonLink && !children ? (
          <Link to={buttonLink} onClick={onButtonClick}>
            {buttonIcon ? (
              <div className="help-panel-button__icon">{buttonIcon}</div>
            ) : null}
            {buttonText}
          </Link>
        ) : (
          <button type="button" onClick={onButtonClick}>
            {buttonIcon ? (
              <div className="help-panel-button__icon">{buttonIcon}</div>
            ) : null}
            {buttonText}
          </button>
        )}
      </div>
    );
  };

  export default HelpPanelButton;