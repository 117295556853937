import * as React from 'react'

export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.802" viewBox="0 0 16 15.802" {...props}>
    <g id="Icon_Edit" data-name="Icon / Edit">
      <path id="Path_32777" data-name="Path 32777" d="M-110.909,3.164l1.569,1.569a.718.718,0,0,1,0,1.013l-.672.672-5.776,5.776a.238.238,0,0,1-.141.068l-2.426.282a.238.238,0,0,1-.265-.254l.185-2.531a.241.241,0,0,1,.069-.151l6.444-6.444A.718.718,0,0,1-110.909,3.164Z" transform="translate(123.656 -2.599)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_539" data-name="Line 539" x2="2.525" y2="2.525" transform="translate(11.175 1.237)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      <path id="Path_32778" data-name="Path 32778" d="M-115.68,2.6h-6.783a1.193,1.193,0,0,0-1.193,1.194V16.207a1.193,1.193,0,0,0,1.193,1.194h12.614a1.193,1.193,0,0,0,1.193-1.194V9.782" transform="translate(123.656 -2.599)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
    </g>
  </svg>

