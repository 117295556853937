import getApiClient from "./../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "./../../../../notifications/ducks/actions";

export const ADD_COSTUME_TO_SCENE = "ADD_COSTUME_TO_SCENE";

export function fetchCostumesInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_COSTUMES_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/costumes`),
    });
  };
}

export function addCostumeToScene(filmId, sceneId, costume, fromBreakdown) {
  return (dispatch) => {
    dispatch({
      type: ADD_COSTUME_TO_SCENE,
      payload: getApiClient().post(`/film/${filmId}/scene/${sceneId}/costume/${costume.costumeId}`),
      meta: { fromBreakdown }
    })
      .then((response) => {
        dispatch(showInfo(`Added costume to scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding costume to scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
