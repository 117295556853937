import { combineReducers } from "redux";
import appReducer from "./app/ducks/reducer";
import errorReducer from "./app/ducks/error-reducer";
import notificationReducer from "./notifications/ducks/reducer";
import filmsReducer from "./films/film/ducks/reducer";
import filmBreakdownReducer from "./films/breakdown/ducks/reducer";
import charactersReducer from "./films/characters/ducks/reducer";
import backgroundCharactersReducer from "./films/background-characters/ducks/reducer";
import sceneSettingsReducer from "./films/scene-settings/ducks/reducer";
import scenesReducer from "./films/scenes/ducks/reducer";
import locationsReducer from "./films/locations/ducks/reducer";
import shotsReducer from "./films/scenes/shots/ducks/reducer";
import takesReducer from "./films/scenes/shots/takes/ducks/reducer";
import actorsReducer from "./films/actors/ducks/reducer";
import backgroundActorsReducer from "./films/background-actors/ducks/reducer";
import shootingdayReducer from "./films/shooting-days/ducks/reducer";
import departmentsReducer from "./films/crew/departments/ducks/reducer";
import crewRolesReducer from "./films/crew/departments/roles/ducks/reducer";
import crewMembersReducer from "./films/crew/departments/roles/members/ducks/reducer";
import crewMembersOverviewReducer from "./films/crew/overview/ducks/reducer";
import crewMembersUploadReducer from "./films/crew/upload/ducks/reducer";
import scriptsReducer from "./film-scripts/ducks/reducer";
import shootingOrderReducer from "./films/shooting-days/shooting-order/ducks/reducer";
import shootingCalendarReducer from "./films/shooting-days/calendar/ducks/reducer";
import filmOverviewReducer from "./films/overview/ducks/reducer";
import propsReducer from "./films/accessories/props/ducks/reducer";
import propsOverviewReducer from "./films/accessories/props/overview/ducks/reducer";
import costumesReducer from "./films/accessories/costumes/ducks/reducer";
import costumesOverviewReducer from "./films/accessories/costumes/overview/ducks/reducer";
import makeupReducer from "./films/accessories/makeup/ducks/reducer";
import setDressingsReducer from "./films/accessories/setDressings/ducks/reducer";
import setDressingsOverviewReducer from "./films/accessories/setDressings/overview/ducks/reducer";
import budgetReducer from "./films/budget/ducks/reducer";
import userSettingsReducer from "./user/ducks/reducer";
import userCommunicationPreferencesReducer from "./user/communication-preferences/ducks/reducer";
import accountReducer from "./account/reducer";
import contactReducer from "./static/contact/ducks/reducer";
import collaboratorsReducer from "./films/collaborators/ducks/reducer";
import collaboratorRolesReducer from "./films/collaborators/roles/ducks/reducer";
import collaboratorsInRoleReducer from "./films/collaborators/in-role/ducks/reducer";
import invitationReducer from "./films/invitation/ducks/reducer";
import adminReducer from "./admin/reducer";
import scriptReducer from "./films/script/ducks/reducer";
import commercialScriptReducer from "./films/commercial-script/ducks/reducer";
import adminChangeLogReducer from "./admin/change-log/ducks/reducer";
import changeLogReducer from "./change-log/ducks/reducer";
import adminFaqReducer from "./admin/faq/ducks/reducer";
import faqReducer from "./app/help-panel/faqs/ducks/reducer";
import equipmentReducer from "./films/equipment/ducks/reducer";
import equipmentScenesReducer from "./films/equipment/scenes/ducks/reducer";
import equipmentCategoryReducer from "./films/equipment/euipment-category/ducks/reducer";
import featureRequestReducer from "./app/feature-request/ducks/reducer";
import marketingReducer from "./marketing/ducks/reducer";
import confirmCallSheetReducer from "./films/shooting-days/confirm/ducks/reducer";
import tasksReducer from "./films/tasks/ducks/reducer";
import schedulingReducer from "./films/scheduling/ducks/reducer";
import importFilmReducer from "./import-film/ducks/reducer";
import visualEffectsReducer from "./films/visual-effects/ducks/reducer";
import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
  commonState: appReducer,
  errorState: errorReducer,
  notificationState: notificationReducer,
  filmsState: filmsReducer,
  filmBreakdownState: filmBreakdownReducer,
  charactersState: charactersReducer,
  backgroundCharactersState: backgroundCharactersReducer,
  sceneSettingsState: sceneSettingsReducer,
  scenesState: scenesReducer,
  shotsState: shotsReducer,
  takesState: takesReducer,
  locationsState: locationsReducer,
  actorsState: actorsReducer,
  backgroundActorsState: backgroundActorsReducer,
  departmentsState: departmentsReducer,
  crewRolesState: crewRolesReducer,
  crewMembersState: crewMembersReducer,
  crewMembersOverviewState: crewMembersOverviewReducer,
  crewMembersUploadState: crewMembersUploadReducer,
  scriptsState: scriptsReducer,
  shootingdaysState: shootingdayReducer,
  shootingOrderState: shootingOrderReducer,
  shootingCalendarState: shootingCalendarReducer,
  filmOverviewState: filmOverviewReducer,
  propsState: propsReducer,
  propsOverviewState: propsOverviewReducer,
  costumesState: costumesReducer,
  costumesOverviewState: costumesOverviewReducer,
  makeupState: makeupReducer,
  setDressingsState: setDressingsReducer,
  setDressingsOverviewState: setDressingsOverviewReducer,
  form: formReducer,
  budgetState: budgetReducer,
  userSettingsState: userSettingsReducer,
  userCommunicationPreferencesState: userCommunicationPreferencesReducer,
  accountState: accountReducer,
  contactState: contactReducer,
  usersState: collaboratorsReducer,
  collaboratorRolesState: collaboratorRolesReducer,
  collaboratorsInRoleState: collaboratorsInRoleReducer,
  invitationState: invitationReducer,
  adminState: adminReducer,
  scriptState: scriptReducer,
  commercialScriptState: commercialScriptReducer,
  adminChangeLogState: adminChangeLogReducer,
  changeLogState: changeLogReducer,
  adminFaqState: adminFaqReducer,
  faqState: faqReducer,
  equipmentState: equipmentReducer,
  equipmentCategoryState: equipmentCategoryReducer,
  equipmentScenesState: equipmentScenesReducer,
  featureRequestState: featureRequestReducer,
  marketingState: marketingReducer,
  confirmCallSheetState: confirmCallSheetReducer,
  tasksState: tasksReducer,
  schedulingState: schedulingReducer,
  importFilmState: importFilmReducer,
  visualEffectsState: visualEffectsReducer
});

export default rootReducer;
