import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../shared/loader";
import { fetchScenes } from "./ducks/actions";
import List from "./list";

function ListScenesContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchScenes, loading, scenes, errors, readOnly } = props;

  useEffect(() => {
    fetchScenes(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderScenes(scenes, filmId) {
    return (
      <div className="blade-menu">
        <List scenes={scenes} filmId={filmId} />
      </div>
    );
  }

  return renderScenes(scenes, filmId);
}

function mapStateToProps(state) {
  const rootState = state.filmBreakdownState;
  return {
    scenes: rootState.scenes,
    readOnly: rootState.readOnly,
    loading: rootState.loading,
    errors: rootState.errors
  };
}

export default connect(mapStateToProps, {
  fetchScenes
})(ListScenesContainer);
