import React from "react";
import { Link, Prompt } from "react-router-dom";
import { reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import Download from "../../../shared/download";

function ShootingDayCateringForm(props) {
  const {
    filmId,
    downloadShootingDayCatering,
    pristine,
    errors,
    progress,
    clearPdf,
    download
  } = props;
  const catering = props.initialValues.catering || {};
  return (
    <div>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}

      <table className="table">
        <thead>
          <tr>
            <th>Requirement</th>
            <th className="has-text-centered">Count</th>
          </tr>
        </thead>
        <tbody>
          {(catering.cateringList || []).map((requirement, idx) => {
            return (
              <tr key={idx}>
                <td>{requirement.requirementDescription}</td>
                <td className="has-text-centered">
                  {requirement.requirementCount}
                </td>
              </tr>
            );
          })}
          {catering.drinks?.length > 0 && <>
            <tr>
              <td colSpan={2}><div className="has-text-weight-bold">Drinks</div></td>
            </tr>
            {(catering.drinks || []).map((drink, idx) => {
              return (
                <tr key={idx}>
                  <td>{drink.name}</td>
                  <td className="has-text-centered">
                    {drink.count}
                  </td>
                </tr>
              );
            })}
          </>}
          {catering.milkTypes?.length > 0 && <>
            <tr>
              <td colSpan={2}><div className="has-text-weight-bold">Milk Types</div></td>
            </tr>

            {(catering.milkTypes || []).map((milkType, idx) => {
              return (
                <tr key={idx}>
                  <td>{milkType.name}</td>
                  <td className="has-text-centered">
                    {milkType.count}
                  </td>
                </tr>
              );
            })}
          </>}
        </tbody>
      </table>

      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Name</th>
            <th>Requirements</th>
            <th>Details</th>
            <th>Drinks</th>
            <th>Milk Types</th>
          </tr>
        </thead>
        <tbody>
          {(catering.individualRequirements || []).map((requirement, idx) => {
            let type;
            switch (requirement.personType) {
              case "Actor":
                type = "actors";
                break;
              case "BackgroundActor":
                type = "background-actors";
                break;
              case "CrewMember":
                type = `departments/${requirement.crewDepartmentId}/roles/${requirement.crewRoleId}/members`;
                break;
            }

            const url = `/films/${filmId}/${type}/${requirement.personId}/edit`;
            return (
              <tr key={idx}>
                <td><Link to={url}>{requirement.name}</Link></td>
                <td>{requirement.dietaryRequirements}</td>
                <td>{requirement.dietaryRequirementDetails}</td>
                <td>{requirement.drinks}</td>
                <td>{requirement.milkTypes}</td>
              </tr>
            );
          })}
        </tbody>
      </table>


      <div className="sticky-buttons">
        <Download
          progress={progress}
          clearPdf={clearPdf}
          download={download}
          className={`mb-1}`}
          downloadFile={downloadShootingDayCatering} />
      </div>
    </div>
  );
}

export default reduxForm({
  form: "shootingDayCateringForm",
  enableReinitialize: true
})(ShootingDayCateringForm);
