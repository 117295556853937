import React, { Component } from "react";
import GoogleMapReact, { fitBounds } from 'google-map-react'
import mapStyles from "./map-styles";
import LocationPin from "./location-pin";
import './map.scss';
import { useState } from "react";
import { useEffect } from "react";

const Map = (props: any) => {
  const { location,     
          locationMarkers,
          hospitalMarkers,
          parkingMarkers,
          onHospitalClick,
          onParkingClick,
          selectedHospital,
          selectedCarPark, 
          width,
          height } = props;

  const apiKey = (window as any).googleApiKey;

  const [zoom, setZoom] = useState(15);
  const [center, setCenter] = useState(); //location || locationMarkers[0]?.position || { lat: 51.5, lng: 0 }

  useEffect(() => {    
    if (location) {      
      setCenter(location);
      setZoom(13);
    } else if (locationMarkers[0]?.position) {
      setCenter(locationMarkers[0]?.position);
    }
  }, [location, locationMarkers]);

  const getMapOptions = (maps) => {
    return {
        streetViewControl: true,
        scaleControl: true,
        fullscreenControl: true,
        styles: mapStyles,
        gestureHandling: "greedy",
        disableDoubleClickZoom: true,        
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.TOP_LEFT,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },
        zoomControl: true,
        clickableIcons: false
    };
}

const fitToBounds = ({ map, maps }) => {
    const bounds = new maps.LatLngBounds(); // need handler incase `google` not yet available
    
  let mapMarkers :any[] = [];
  if (locationMarkers) {
    mapMarkers = mapMarkers.concat(locationMarkers.map((location: any) => location.position));
  }

  if (hospitalMarkers) {
    mapMarkers = mapMarkers.concat(hospitalMarkers.map((location: any) => location.position));
  }

  if (parkingMarkers) {
    mapMarkers = mapMarkers.concat(parkingMarkers.map((location: any) => location.position));
  }

  if (location) {
    mapMarkers.push(location)  ;
  }
  
  mapMarkers.forEach(marker => {
      bounds.extend(new maps.LatLng(marker.lat, marker.lng));
  });

  const newBounds = {
    ne: {
        lat: bounds.getNorthEast().lat(),
        lng: bounds.getNorthEast().lng()
    },
    sw: {
        lat: bounds.getSouthWest().lat(),
        lng: bounds.getSouthWest().lng()
    }
  };

  const size = {
    width: width ?? 768,
    height: height ?? 364,
  };

  if (newBounds.ne.lat === newBounds.sw.lat && newBounds.ne.lng === newBounds.sw.lng) {

  } else {
    const {center, zoom} = fitBounds(newBounds, size);
    setZoom(zoom);
    setCenter(center);
  }
  
};

const onOnHospitalClick = (location: any)  => {
  if (onHospitalClick && hospitalMarkers.length > 1) {
    onHospitalClick(location);
  }
};

const onOnParkingClick = (location: any)  => {
  if (onParkingClick && parkingMarkers.length > 1) {
    onParkingClick(location);
  }
};

const hospitalsClickable = (onHospitalClick && hospitalMarkers.length > 1);

const parkingClickable = (onParkingClick && parkingMarkers.length > 1);

  return  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey, v: '3.31', }}
        options={getMapOptions}
        defaultCenter={center}
        defaultZoom={zoom}
        center={center}
        zoom={zoom}        
        onGoogleApiLoaded={fitToBounds}
        yesIWantToUseGoogleMapApiInternals
      >
        {(hospitalMarkers || []).map((location: any, index:number) => (
          <LocationPin
            key={index}
            lat={location.position.lat}
            lng={location.position.lng}            
            image={`HospitalMarker${location.selected ? 'Selected' : ''}.png`}
            onClick={() => onOnHospitalClick(location)}
            clickable={hospitalsClickable}
          />
        ))}

        {(parkingMarkers || []).map((location: any, index:number) => (
          <LocationPin
            key={index}
            lat={location.position.lat}
            lng={location.position.lng}            
            image={`ParkingMarker${location.place_id === selectedCarPark?.place_id ? 'Selected' : ''}.png`}
            onClick={() => onOnParkingClick(location)}
            clickable={parkingClickable}
          />
        ))}
        
       {location && <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={"1"}
            image="FilmMarker.png"
            clickable={false}
          />}

        {(locationMarkers || []).map((location: any, index:number) => (
          <LocationPin
            key={index}
            lat={location.position.lat}
            lng={location.position.lng}
            image={`FilmMarker${location.number}.png`}
            clickable={false}
          />
        ))} 
      </GoogleMapReact>
    </div>
  </div>

}

export default Map;


/*
declare const google: any;

class Map extends Component<any, any> {
  private map: React.RefObject<GoogleMap>;

  constructor(props: any) {
    super(props);

    this.map = React.createRef();
  }

  onMarkerDragEnd = (event) => {
    if (this.props.readOnly) {
      return;
    }

    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    this.props.updateMaker({ lat: newLat, lng: newLng });
  };

  componentDidMount() {
    this.fitToBounds();
  }

  fitToBounds = () => {
    var markerBounds = new google.maps.LatLngBounds();

    if (this.props.location != null) {
      const center = {
        lat: this.props.location.lat,
        lng: this.props.location.lng
      };
      markerBounds.extend(center);
    }

    if (this.props.locationMarkers && this.props.locationMarkers.length > 0) {
      this.props.locationMarkers.forEach((marker) => {
        markerBounds.extend(marker.position);
      });
    }

    if (this.props.hospitalMarkers && this.props.hospitalMarkers.length > 0) {
      this.props.hospitalMarkers.forEach((marker) => {
        markerBounds.extend(marker.position);
      });
    }

    if (this.props.parkingMarkers && this.props.parkingMarkers.length > 0) {
      this.props.parkingMarkers.forEach((marker) => {
        markerBounds.extend(marker.position);
      });
    }

    this.map.current?.fitBounds(markerBounds);
    var zoom = this.map.current?.getZoom();
  };

  render() {
    let center = { lat: 0, lng: 0 };
    if (this.props.location != null) {
      center = { lat: this.props.location.lat, lng: this.props.location.lng };
    }

    const contents = [];
    if (this.props.zoomMap) {
      this.fitToBounds();
      this.props.resetZoom();
    }
    const selectedHospital = this.props.selectedHospital;
    const selectedCarPark = this.props.selectedCarPark;
    const readOnly = this.props.readOnly;
    return (
      <GoogleMap
        ref={this.map}
        zoom={15}
        center={center}
        onClick={this.props.onClick}
        defaultOptions={{ styles: mapStyles, minZoom: 4, maxZoom: 18 }}>
        {contents.map((c) => {
          return c;
        })}
        {this.props.location != null && (
          <Marker
            draggable={!readOnly}
            onDragEnd={this.onMarkerDragEnd}
            position={center}
            icon={{ url: "/images/FilmMarker.png" }}></Marker>
        )}
        {this.props.locationMarkers
          ? this.props.locationMarkers.map((marker, i) => (
              <Marker
                key={i}
                position={marker.position}
                icon={{ url: "/images/FilmMarker" + marker.number + ".png" }}
              />
            ))
          : null}
        {this.props.hospitalMarkers
          ? this.props.hospitalMarkers.map((marker, i) => (
              <Marker
                key={i}
                position={marker.position}
                onClick={() => {
                  this.props.setSelectedHospital(marker);
                }}
                icon={{ url: "/images/HospitalMarker.png" }}
              />
            ))
          : null}
        {this.props.parkingMarkers
          ? this.props.parkingMarkers.map((marker, i) => (
              <Marker
                key={i}
                position={marker.position}
                onClick={() => {
                  this.props.setSelectedCarPark(marker);
                }}
                icon={{ url: "/images/ParkingMarker.png" }}
              />
            ))
          : null}

        {selectedHospital && (
          <InfoWindow
            position={{
              lat: selectedHospital.latitude,
              lng: selectedHospital.longitude
            }}
            options={{ pixelOffset: new google.maps.Size(0, -60) }}
            onCloseClick={() => {
              this.props.setSelectedHospital(null);
            }}>
            <div>
              <p>
                <b>{selectedHospital.organisationName}</b>
              </p>
              <p>{selectedHospital.address1}</p>
              {selectedHospital.address2 && <p>{selectedHospital.address2}</p>}
              {selectedHospital.address3 && <p>{selectedHospital.address3}</p>}
              <p>{selectedHospital.city}</p>
              <p>{selectedHospital.county}</p>
              <p>{selectedHospital.postcode}</p>
            </div>
          </InfoWindow>
        )}
        {selectedCarPark && (
          <InfoWindow
            position={{
              lat: selectedCarPark.position.latitude,
              lng: selectedCarPark.position.longitude
            }}
            options={{ pixelOffset: new google.maps.Size(0, -60) }}
            onCloseClick={() => {
              this.props.setSelectedCarPark(null);
            }}>
            <div>
              <p>
                <b>{selectedCarPark.name}</b>
              </p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  }
}

export default withGoogleMap(Map);
*/
