import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const FETCH_EQUIPMENT_CATEGORIES = "FETCH_EQUIPMENT_CATEGORIES";
export const FETCH_EQUIPMENT_CATEGORY = "FETCH_EQUIPMENT_CATEGORY";
export const ADD_SINGLE_EQUIPMENT_CATEGORY = "ADD_SINGLE_EQUIPMENT_CATEGORY";
export const UPDATE_SINGLE_EQUIPMENT_CATEGORY = "UPDATE_SINGLE_EQUIPMENT_CATEGORY";
export const DELETE_SINGLE_EQUIPMENT_CATEGORY = "DELETE_SINGLE_EQUIPMENT_CATEGORY";
export const CLEAR_EQUIPMENT_CATEGORY = "CLEAR_EQUIPMENT_CATEGORY";

export function fetchEquipmentCategories(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_EQUIPMENT_CATEGORIES,
      payload: getApiClient().get(`/film/${filmId}/EquipmentCategory`),
    });
  };
}

export function clearEquipmentCategory() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EQUIPMENT_CATEGORY
    });
  };
}

export function fetchEquipmentCategory(filmId, categoryId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_EQUIPMENT_CATEGORY,
      payload: getApiClient().get(`/film/${filmId}/EquipmentCategory/${categoryId}`),
    });
  };
}

export function addEquipmentCategory(filmId, equipmentCategory) {  
  return (dispatch) => {
    dispatch({
      type: ADD_SINGLE_EQUIPMENT_CATEGORY,
      payload: getApiClient().post(`/film/${filmId}/EquipmentCategory`, equipmentCategory),
      meta: { equipmentCategory },
    })
      .then((response) => {
        dispatch(showInfo(`Added equipment catagory ${equipmentCategory.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding equipment catagory ${equipmentCategory.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateEquipmentCategory(filmId, equipmentCategory) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SINGLE_EQUIPMENT_CATEGORY,
      payload: getApiClient().put(`/film/${filmId}/EquipmentCategory/${equipmentCategory.id}`, equipmentCategory),
      meta: { equipmentCategory },
    })
      .then((response) => {
        dispatch(showInfo(`Updated equipment catagory ${equipmentCategory.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating equipment catagory ${equipmentCategory.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteEquipmentCategory(filmId, categoryId, equipmentCategory) {
  return (dispatch) => {
    dispatch({
      type: DELETE_SINGLE_EQUIPMENT_CATEGORY,
      payload: getApiClient().delete(`/film/${filmId}/EquipmentCategory/${categoryId}`),
      meta: { equipmentCategory },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted equipment catagory ${equipmentCategory.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting equipment catagory ${equipmentCategory.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
