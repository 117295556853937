export const timeDiffMinutes = (startTime, endTime) => {
    if (!startTime || !endTime) {
        return null;
    }

    var days = endTime.days - startTime.days;
    var hours = (endTime.hours - startTime.hours) + days * 24;
    var minutes = (endTime.minutes - startTime.minutes) + hours * 60;

    return minutes;
};

export function formatTimeFromSeconds(seconds) {
    if (!seconds) {
        return null;
    }
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return [
        h > 9 ? h : '0' + h,
        m > 9 ? m : '0' + m,
        s > 9 ? s : '0' + s
    ].filter(Boolean).join(':');
}

export function formatTimeFromMinutes(minutes) {
    if (!minutes) {
        return null;
    }

    const negative = minutes < 0;
    minutes = Math.abs(minutes);
    const h = Math.floor(minutes / 60);
    const m = Math.round(minutes % 60);
    return (negative ? '-' : '') + [
        h > 9 ? h : '0' + h,
        m > 9 ? m : '0' + m
    ].filter(Boolean).join(':');
}

export function formatTime(time) {
    if (!time) {
        return null;
    }
    const h = time.hours % 24;
    const m = time.minutes;
    let value = [
        h > 9 ? h : '0' + h,
        m > 9 ? m : '0' + m,
    ].filter(Boolean).join(':');

    if (time.days > 0) {
        value += ' +1';
    }
    return value;
}

export const parseTimeToMinutes = (time: any): number => {
    if (!time) {
        return 0;
    }

    let totalMinutes: number = 0;
    const days = time.days;
    const hours = time.hours;
    const minutes = time.minutes;
    totalMinutes += days * 1440;
    totalMinutes += hours * 60;
    totalMinutes += minutes;

    return totalMinutes;
}