import React from "react";
import { NavLink } from "react-router-dom";

const ScenesList = ({ scenes, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Breakdown</p>
      <ul className="menu-list">
        <NavLink
          to={`/films/${filmId}/breakdown/overview`}
          activeClassName="is-active"
          isActive={(match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/breakdown/overview`
            );
          }}>
          Overview
        </NavLink>
        <hr />
        {(scenes || []).map((scene, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/breakdown/scenes/${scene.id}`}
                activeClassName="is-active"
                title={scene.number + ". " + scene.settingName}
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/breakdown/scenes/${scene.id}`
                  );
                }}>
                {scene.number}. {scene.settingName}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ScenesList;
