import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import Images from "../../../shared/images";
import Errors from "../../../shared/errors";
import {
  renderSelectField,
  renderTextAreaField,
  renderTextField,
  renderReactSelect,
  renderCheckbox,
  renderCurrencyField
} from "../../../shared/form-helpers";
import required, { maxDecimalValue } from "../../../shared/validation";
import FormErrors from "../../../shared/form-errors";

let MakeupForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    acquisitionMethodEnum,
    allCharacters,
    mode,
    acquisitionMethodValue,
    submitFailed,
    invalid,
    filmId,
    allBackgroundCharacters
  } = props;
  const thisMakeup = props.initialValues || {};
  const readOnly = thisMakeup.readOnly;
  const characterOptions = allCharacters?.map((c) => {
    return { label: c.name, value: c.id };
  });

  const characterSelectedOptions = thisMakeup.characters?.map((c) => {
    return characterOptions?.find((x) => x.value === c.id);
  });

  const backgroundCharacterOptions = allBackgroundCharacters?.map((c) => {
    return { label: c.name, value: c.id };
  });

  const backgroundCharacterSelectedOptions = thisMakeup.backgroundCharacters?.map((c) => {
    return backgroundCharacterOptions?.find((x) => x.value === c.id);
  });


  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Makeup</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Makeup</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="characters"
              label="Character"
              component={renderReactSelect}
              options={characterOptions}
              selectedOptions={characterSelectedOptions}
              //validate={[required]}

              readOnly={readOnly}
            />

            <Field
              name="backgroundCharacters"
              label="Background Characters"
              component={renderReactSelect}
              options={backgroundCharacterOptions}
              selectedOptions={backgroundCharacterSelectedOptions}
              //validate={[required]}

              readOnly={readOnly}
            />

            <Field
              name="description"
              label="Description"
              component={renderTextAreaField}
              readOnly={readOnly}></Field>

            <Field
              name="dailyBudget"
              label="Daily Budget"
              component={renderCurrencyField}
              validate={[maxDecimalValue]}
              readOnly={readOnly}></Field>

            {!readOnly && <div className="buttons mt-2 sticky-buttons">
              <button
                type="submit"
                className="button is-primary"
                disabled={pristine || submitting}>
                Save
              </button>
              {mode === "edit" && (
                <button
                  type="button"
                  className="button is-text"
                  disabled={submitting || !props.initialValues}
                  onClick={() => handleDelete(thisMakeup)}>
                  Delete Makeup
                </button>
              )}
            </div>}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        </div>
        <div className="column">
          <Images change={props.change} initialValues={props.initialValues} readOnly={readOnly} filmId={filmId} />
        </div>
      </div>
    </>
  );
}

MakeupForm = reduxForm({
  form: "editMakeupForm",
  enableReinitialize: true
})(MakeupForm);

// Decorate with connect to read form values
const selector = formValueSelector('editMakeupForm'); // <-- same as form name
const MakeupFormConnect = connect(
  state => {
    // can select values individually
    const acquisitionMethodValue = selector(state, 'acquisitionMethod')

    return {
      acquisitionMethodValue
    }
  }
)(MakeupForm)

export default MakeupFormConnect
