import Checkbox from "../../../shared/filmanize-checkbox";

const ElementSelection = (props: any) => {
        const { includeDialog,
                onChangeIncludeDialog,
                includeAction,
                onChangeIncludeAction,
                includeCharacter,
                onChangeIncludeCharacter,
                includeParenthetical,
                onChangeIncludeParenthetical,
                includeTransition,
                onChangeIncludeTransition,
                includeGeneral,
                onChangeIncludeGeneral } = props;
        return (
                <div className="field">
                        <Checkbox checked={includeDialog}
                                onChange={onChangeIncludeDialog}
                                label="Include Dialog" />
                        <Checkbox checked={includeAction}
                                onChange={onChangeIncludeAction}
                                label="Include Action" />
                        <Checkbox checked={includeCharacter}
                                onChange={onChangeIncludeCharacter}
                                label="Include Character" />
                        <Checkbox checked={includeParenthetical}
                                onChange={onChangeIncludeParenthetical}
                                label="Include Parenthetical" />
                        <Checkbox checked={includeTransition}
                                onChange={onChangeIncludeTransition}
                                label="Include Transition" />
                        <Checkbox checked={includeGeneral}
                                onChange={onChangeIncludeGeneral}
                                label="Include General" />
                </div>
        )
};

export default ElementSelection;