import getApiClient from "./../../../../shared/api";
import { showInfo, showError, showWarning, clearNotifications } from "./../../../../notifications/ducks/actions";

export const UPLOAD_CREW = "UPLOAD_CREW";
export const UPLOAD_CREW_CLEAR_ERRORS = "UPLOAD_CREW_CLEAR_ERRORS";

export function uploadCrew(filmId, formData) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_CREW,
      payload: getApiClient().post(`film/${filmId}/CrewMember/Upload`, formData, {
        headers: {
          "content-type": `multipart/form-data`
        }
      })
    })
      .then((response) => {
        dispatch(showInfo(`Successfully uploaded actors`));
      })
      .catch((response) => {
        dispatch(showError(`Error uploading actors`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearErrors() {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_CREW_CLEAR_ERRORS,
    });
  };
}

