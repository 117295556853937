import { IMPORT_FILM_ERROR, IMPORT_FILM, IMPORT_FILM_CLEAR_REDIRECT } from "./actions";

export const OnboardingStep1ClassName = 'script-upload-onboarding-step-1';
export const OnboardingStep2ClassName = 'script-upload-onboarding-step-2';
export const OnboardingStep3ClassName = 'script-upload-onboarding-step-3';

const defaultState: any = {
  actorUnionSelectedValue: null,
  crewUnionSelectedValue: null,
  currencyId: null,
  languageId: null,
  budget: null,
  useAutoBreakdown: true,
  uploading: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Film Budget',
      content: `        
        <p>The film budget is used to apply the correct actor and crew rates for budgeting purposes. </p>
        <p>It will also determine which crew are automatically selected as required.</p>
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Use Smart Breakdown',
      content: `<p>This will use GPT-4 to attempt to breakdown your script automatically. It is currently experimental so may produce random results. If you use it and it doesn't work, you can always delete the film and reupload without this selected.</p>
                <p>If your script contains anything that GPT deems inappropriate then that part will not be broken down.</p>
                <p>This may take some time as GPT-4 is not very fast, depending on the number of scenes in the script.</p>`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Upload',
      content: `Click "Choose a script..." to upload your script file, or drag and drop the file into the dotted area.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "INITIALISE_SCRIPT_UPLOAD": {
      return {
        ...state,
        script: null,
        uploadingStatus: null,
        errors: null,
        progress: null,
        budget: null
      };
    }

    case IMPORT_FILM + "_PENDING": {
      return {
        ...state,
        uploadingStatus: "uploading",
        uploading: true,
        progress: { progress: null }
      };
    }

    case IMPORT_FILM + "_FULFILLED": {
      const filmId = action.payload.data.film.id;
      return {
        ...state,
        uploading: false,
        redirect: { to: `/films/${filmId}/overview` }
      };
    }

    case IMPORT_FILM + "_REJECTED": {
      return {
        ...state,
        script: null,
        errors: action.payload.response.data.errors,
        uploading: false,
        uploadingStatus: "failed"
      };
    }

    case IMPORT_FILM_ERROR: {
      return {
        ...state,
        script: null,
        errors: { "": [action.meta.error] }
      };
    }

    case IMPORT_FILM_CLEAR_REDIRECT: {
      return {
        ...state,
        redirect: null
      }
    }

    default:
      return state;
  }
};

export default reducer;
