import { createRef, useEffect, useState } from "react";
import FilmaizeSelect from "../../../shared/filmanize-select";
import Select from "../../../shared/select";
import SmallLoader from "../../../shared/small-loader/small-loader";
import Question from "./question";
import QuestionSkeleton from "./question-skeleton";

const Faqs = (props) => {  
    const {     
      faqs,
      moreToLoad,
      loadMore, 
      id, 
      loading,
      onAskAQuestion,
      pageSections,
      onPageSectionChange,
      selectedPageSection
     } = props;

   const [ignoreScroll, setIgnoreScroll] = useState<Boolean>(false);

    const refs = faqs.reduce((acc, value) => {
        acc[value.id] = createRef();
        return acc;
      }, {});

    let whatsNewElement;

    useEffect(() => {      
      setIgnoreScroll(false);
    }, [id]);

    useEffect(() => {
        if (!ignoreScroll && id && faqs.length && whatsNewElement) {          
            scrollToSection(id);
        }
    }, [id, JSON.stringify(faqs), whatsNewElement, refs[0]?.current?.offsetTop, ignoreScroll]);
    
    const scrollToSection = (id) => {        
        if (refs[id]?.current) {          
          //scriptElement.scrollTo(0, refs[id].current.offsetTop - 491);
          refs[id].current.scrollIntoView({ behavior: 'smooth', block: 'start' });          
        }        
      };

      const pageSectionOptions = pageSections.map((pageSection) => {
        return { value: pageSection.name, label: pageSection.name };
      });

      const selectedPageSectionValue = pageSectionOptions.find((pageSection) => pageSection.value === selectedPageSection);

    const onLoadMore = () => {
      setIgnoreScroll(true);
      loadMore();
    };

    return (
    
    <div ref={(elem) => (whatsNewElement = elem)} className="faq-container">
      <div className="field">
        <label className="label">Section</label>
        <FilmaizeSelect label="Section" value={selectedPageSectionValue} options={pageSectionOptions} onChange={onPageSectionChange} />
      </div>            
      {loading && <QuestionSkeleton  />}
      {(faqs || []).map((faq: any, index: number) => <Question key={index} reference={refs[faq.id]} faq={faq} noNavbarPadding={index > 0}/>)}

      {moreToLoad &&<div className="container">
        <div className="columns is-centered">
          <div className="column is-three-quarters-tablet is-6-desktop is-6-widescreen">
             <button className={`button is-center ${loading ? "is-loading" : ""}`} onClick={onLoadMore}>Load more</button> 
          </div>
        </div>
      </div>}

      {!loading && faqs && faqs.length === 0 && <div>
          There aren't any questions yet.
      </div>}
      <div className="mt-1">
        <a className="link-on-white" onClick={onAskAQuestion}>Ask a question</a>
      </div>

    </div>
    )
}

export default Faqs;