export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="26.5" height="22.5" viewBox="0 0 22 19"  {...props}>
    <g id="Icon_Navigation_Characters" data-name="Icon / Navigation / Characters" transform="translate(0.5 0.5)">
      <g id="Group_31" data-name="Group 31">
        <rect id="Rectangle_10" data-name="Rectangle 10" width="21" height="18" fill="none" strokeLinejoin="round" strokeWidth="1" />
        <g id="Group_30" data-name="Group 30" transform="translate(5.3 8.982)">
          <path id="Path_43" data-name="Path 43" d="M18.49,16.831a3.5,3.5,0,1,1-6.464.055" transform="translate(-9.855 -16.831)" fill="none" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_44" data-name="Path 44" d="M9.454,26.175A7.369,7.369,0,0,1,20.05,26c.079.079.157.16.232.243" transform="translate(-9.454 -17.538)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        </g>
      </g>
      <path id="Path_45" data-name="Path 45" d="M18.946,15.309c-.853-.753-.662-5.239-.662-5.239a.847.847,0,0,0-1.035-.757l-1.107.3a4.358,4.358,0,0,1-2.313-.016l-.939-.264a.847.847,0,0,0-1.046.754s.124,3.818-.659,5.225" transform="translate(-4.412 -4.928)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <path id="Path_46" data-name="Path 46" d="M9.67,15.764c2.389,1.571,8.248,1.276,10.147.1" transform="translate(-4.141 -6.639)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
    </g>
  </svg>
