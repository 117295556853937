import React from "react";
import "./errors.scss";

const FormErrors = (props) => {
  const { submitFailed, invalid } = props;
  return (
    <>
      {submitFailed && invalid && (
        <p className="help is-error">Please correct the errors above</p>
      )}
    </>
  );
};

export default FormErrors;
