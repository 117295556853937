import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../../shared/private-route";
import ListSetDressingsContainer from "./list-container";
import AddSetDressing from "./add";

const SetDressingRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/set-dressings"
        component={ListSetDressingsContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/set-dressings/add"
        component={AddSetDressing}
      />
    </>
  );
};

export default SetDressingRoutes;
