import { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import AnonymousPage from "../../../account/anonymous-pages/anonymous-page";
import BoxWraper from "../../../account/box-wrapper";
import Errors from "../../../shared/errors";
import Loader from "../../../shared/loader";
import {
  fetchPotentialDates,
  toggleAvailability,
  deleteDays,
  updateAvailableDates,
  addUserEvents,
  setPotentialDaysToUnavailableIfUserEvent
} from "./ducks/actions";
import PotentialDates from "./potential-dates";

const ConfirmCallSheet = (props) => {
  const languageCode = props.match.params.languageCode;
  const token = props.match.params.token;
  const { fetchPotentialDates,
    toggleAvailability,
    deleteDays,
    updateAvailableDates,
    dates,
    loading,
    saving,
    user,
    errors,
    addUserEvents,
    setPotentialDaysToUnavailableIfUserEvent,
    saved,
    onboardingSteps } = props;

  const [importedUserEvents, setImportedUserEvents] = useState<boolean>(false);

  useEffect(() => {
    fetchPotentialDates(token);
  }, [token]);

  const onUpdateAvailableDates = () => {
    updateAvailableDates(token, dates.filter((date) => !date.userEvent && date.available).map((date) => ({ id: date.id, date: date.start, available: date.available })));
  }

  var gapi = window.gapi;
  var google = window.google;
  /* 
    Update with your own Client Id and Api key 
  */
  var CLIENT_ID = "600475027681-t3an8t65n0oh6cam11j2k67qk70voooj.apps.googleusercontent.com";
  var API_KEY = "AIzaSyBqFNJDa_TbcsJ7SbjZpamgshWRpQR4_gc";
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
  var SCOPES = "https://www.googleapis.com/auth/calendar.events.readonly";

  const handleGetGoogleCalendar = () => {
    gapi.load('client', initializeGapiClient);

    async function initializeGapiClient() {
      await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
      });



      const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });

      tokenClient.callback = async (resp) => {
        if (resp.error !== undefined) {
          return;
          throw (resp.error);
        }

        gapi.client.calendar.events.list({
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime'
        }).then(response => {
          setImportedUserEvents(true);
          const events = response.result.items;
          addUserEvents(events);
          console.log('EVENTS: ', events)
        })
      };

      if (gapi.client.getToken() === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({ prompt: 'consent' });
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({ prompt: '' });
      }
    }
  }

  return (
    <AnonymousPage>
      <BoxWraper width={5}>
        <Suspense fallback={<Loader />}>
          <Errors errors={errors} />
          {(saving || loading) && <Loader />}

          {!errors && <PotentialDates
            user={user}
            loading={loading}
            languageCode={languageCode}
            dates={dates}
            toggleAvailability={toggleAvailability}
            deleteDays={deleteDays}
            updateAvailableDates={onUpdateAvailableDates}
            handleGetGoogleCalendar={handleGetGoogleCalendar}
            saving={saving}
            saved={saved}
            setPotentialDaysToUnavailableIfUserEvent={setPotentialDaysToUnavailableIfUserEvent}
            importedUserEvents={importedUserEvents}
            onboardingSteps={onboardingSteps} />}
        </Suspense>
      </BoxWraper>
    </AnonymousPage>
  )
};

function mapStateToProps(state) {
  const { schedulingState } = state;
  const potentialDatesState = schedulingState.potentialDatesState;
  return {
    loading: potentialDatesState.loading,
    user: potentialDatesState.user,
    errors: potentialDatesState.errors,
    dates: potentialDatesState.dates,
    saving: potentialDatesState.saving,
    saved: potentialDatesState.saved,
    onboardingSteps: potentialDatesState.onboardingSteps
  };
}

export default connect(mapStateToProps, {
  fetchPotentialDates,
  toggleAvailability,
  deleteDays,
  updateAvailableDates,
  addUserEvents,
  setPotentialDaysToUnavailableIfUserEvent
})(ConfirmCallSheet);