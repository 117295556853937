import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../shared/loader";
import { fetchSceneSettings } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list";

function ListContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchSceneSettings, loading, sceneSettings, errors, readOnly } = props;

  useEffect(() => {
    fetchSceneSettings(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderList(sceneSettings, filmId) {
    return (
      <div className="blade-menu">
        <List sceneSettings={sceneSettings} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/scene-settings/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Scene Setting
        </NavLink>}
      </div>
    );
  }

  return loading ? renderLoading() : renderList(sceneSettings, filmId);
}

function mapStateToProps(state) {
  const { sceneSettingsState } = state;
  return {
    sceneSettings: sceneSettingsState.rootState.sceneSettings,
    readOnly: sceneSettingsState.rootState.readOnly,
    loading: sceneSettingsState.rootState.loading,
    errors: sceneSettingsState.rootState.errors
  };
}

export default connect(mapStateToProps, {
  fetchSceneSettings
})(ListContainer);
