import React, { useEffect } from "react";
import { connect } from "react-redux";
import Errors from "./../../../shared/errors";
import Loader from "./../../../shared/loader";
import Settings from "./form";
import {
  fetchShootingDaySettings,
  updateShootingDaySettings
} from "./ducks/actions";
import Onboarding from '../../../shared/onboarding/onboarding';

function ShootingDaySettingsContainer(props) {
  const filmId = props.match.params.filmId;
  const {
    fetchShootingDaySettings,
    errors,
    loading,
    shootingDaySettings,
    readOnly,
    updateShootingDaySettings,
    onboardingSteps,
    callSheetSectionEnum,
    weatherSectionEnum
  } = props;

  useEffect(() => {
    fetchShootingDaySettings(filmId);
  }, []);

  function onUpdateShootingDaySettings(shootingDaySettings) {    
    updateShootingDaySettings(filmId, shootingDaySettings);
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderScenes() {
    return (
      <>
          <div className="blade-content wide">
            <Settings            
              initialValues={shootingDaySettings}  
              readOnly={readOnly}    
              errors={errors}
              onSubmit={onUpdateShootingDaySettings}     
              callSheetSectionEnum={callSheetSectionEnum}
              weatherSectionEnum={weatherSectionEnum}
            />
            <Onboarding onboardingSteps={onboardingSteps} section="Shooting Day Settings"/>
          </div>        
      </>
    );
  }

  return loading ? renderLoading() : renderScenes();
}

function mapStateToProps(state) {
  const settingsState = state.shootingdaysState.settingsState;
  return {
    shootingDaySettings: settingsState.shootingDaySettings,    
    loading: settingsState.loading,
    errors: settingsState.errors,
    onboardingSteps: settingsState.onboardingSteps,
    callSheetSectionEnum: state.commonState.callSheetSectionEnum,
    weatherSectionEnum: state.commonState.weatherSectionEnum,
  };
}

export default connect(mapStateToProps, {
  fetchShootingDaySettings,
  updateShootingDaySettings
})(ShootingDaySettingsContainer);
