import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../../shared/loader";
import InvlidShootingDayNotification from "../invalid-shooting-day-notification";
import { fetchShootingDayEquipment, updateShootingDayEquipment } from "./ducks/actions";
import EditEquipmentForm from "./edit-form";

function ListShootingDayCrewContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchShootingDayEquipment,
    updateShootingDayEquipment,
    loading,
    equipmentCategories,
    errors,
    readOnly
  } = props;

  useEffect(() => {
    fetchShootingDayEquipment(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function renderLoading() {
    return <Loader />;
  }

  function onUpdateShootingDayEquipmentCategories(equipmentCatagories) {    
    updateShootingDayEquipment(filmId, shootingdayId, equipmentCatagories);
  }

  function renderShootingDays(equipmentCategories, filmId) {
    return (
      <div className="blade-content wide">
        <p className="menu-label">Equipment</p>
        <EditEquipmentForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ equipmentCategories }}
          loading={loading}
          onSubmit={onUpdateShootingDayEquipmentCategories}
          readOnly={readOnly}
          {...props}
        />
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(equipmentCategories, filmId);
}

function mapStateToProps(state) {
  const { rootState, equipmentState } = state.shootingdaysState;
  return {
    shootingDay: rootState.shootingday,
    readOnly: equipmentState.readOnly,
    equipmentCategories: equipmentState.equipmentCategories,
    loading: equipmentState.loading,
    saved: equipmentState.saved,
    errors: equipmentState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayEquipment,
  updateShootingDayEquipment
})(ListShootingDayCrewContainer);
