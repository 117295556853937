import * as React from 'react'

export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.41" height="16.854" viewBox="0 0 20.41 16.854" {...props}>
    <g id="Icon_Upload" data-name="Icon / Upload" transform="translate(0.5 0.5)">
      <path id="Path_32686" data-name="Path 32686" d="M142.211-30.563h2.161v0c.032,0,.064,0,.1,0a4.491,4.491,0,0,0,4.494-4.489,4.491,4.491,0,0,0-4.494-4.489,4.5,4.5,0,0,0-.712.062,4.849,4.849,0,0,0-4.833-4.469,4.852,4.852,0,0,0-4.852,4.852,4.873,4.873,0,0,0,.027.506,4.007,4.007,0,0,0-.51-.036,4.036,4.036,0,0,0-4.036,4.036,4.036,4.036,0,0,0,4.036,4.036c.071,0,.14-.007.209-.01h2.376" transform="translate(-129.552 43.946)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      <path id="Path_32687" data-name="Path 32687" d="M146.322-21.979l2.938-2.938,2.938,2.938" transform="translate(-139.556 32.595)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      <line id="Line_507" data-name="Line 507" y2="8.177" transform="translate(9.705 7.678)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </g>
  </svg>
