import React, { useEffect, useState } from "react";
import { Link, Prompt } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import {
  renderCheckbox,
  renderTimeField
} from "./../../../shared/form-helpers";
import CanAccessLink from "../../../shared/can-access-link";
import FilmaizeSelect from "../../../shared/filmanize-select";
import Time from "../../../shared/time";

function ShootingDayActorsForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    errors,
    readOnly,
    filmId,
    canAccessActors,
    allActors,
    onAddShootingDayActor,
    adding,
    callTime,
    onSetTime
  } = props;
  const thisList = props.initialValues || [];

  const allActorsOptions = allActors.filter(actor => !thisList?.actors?.map(a => a.actorId).includes(actor.id)).map((actor) => { return { label: actor.name, value: actor.id } });
  const [selectedActor, setSelectedActor] = useState<any>();
  const [addActorCallTime, setAddActorCallTime] = useState<any>({ hours: 9, minutes: 0, days: 0 });
  const [addActorOnSetTime, setAddActorOnSetTime] = useState<any>({ hours: 10, minutes: 0, days: 0 });

  useEffect(() => {
    if (callTime) {
      setAddActorCallTime(callTime);
    }
  }, [callTime]);

  useEffect(() => {
    if (onSetTime) {
      setAddActorOnSetTime(onSetTime);
    }
  }, [onSetTime]);

  const handleAddShootingDayActor = (e) => {
    e.preventDefault();
    if (selectedActor) {
      onAddShootingDayActor({ actorId: selectedActor.value, callTime: addActorCallTime, onSetTime: addActorOnSetTime });
      setSelectedActor(null);
    }
  };

  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <FieldArray name="actors" component={actorsListForm} readOnly={readOnly} filmId={filmId} canAccessActors={canAccessActors} />

        {allActorsOptions?.length > 0 && <div className="mt-1">
          <label className="label">Add Actor</label>
          <div className="add-actor-container">
            <FilmaizeSelect className="add-actor-list" options={allActorsOptions} value={selectedActor} onChange={setSelectedActor} />
            <span className="add-actor-time">Call Time</span>
            <Time
              className="ml-1"
              value={addActorCallTime}
              //label={label}              
              onChange={setAddActorCallTime}
              readOnly={readOnly}
              minuteGap={5}

            />
            <span className="add-actor-time">On Set Time</span>
            <Time
              className="ml-1"
              value={addActorOnSetTime}
              //label={label}              
              onChange={setAddActorOnSetTime}
              readOnly={readOnly}
              minuteGap={5}

            />
            <button className={`button add-actor-button ml-1 ${adding ? "is-loading" : ""}`} onClick={handleAddShootingDayActor}>Add</button>
          </div>
        </div>}

        {!readOnly && <div className="buttons mt-1 sticky-buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}
          >
            Save
          </button>
        </div>}
      </form>
    </>
  );
}

function actorsListForm({ fields, meta: { error }, readOnly, filmId, canAccessActors }) {
  return (
    <div>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Number</th>
            <th>Name</th>
            <th>Characters</th>
            <th>Start</th>
            <th>Finish</th>
            <th>Call time</th>
            <th>On set time</th>
          </tr>
        </thead>
        <tbody>
          {(fields || []).map((actor, idx, records) => {
            const currentRecord = records.get(idx);
            return (
              <tr key={idx}>
                <td>
                  <Field
                    name={`${actor}.selected`}
                    component={renderCheckbox}
                    checked={`${actor}.selected`}
                    readOnly={readOnly}
                  />
                </td>
                <td>{currentRecord.number}</td>
                <td><CanAccessLink to={`/films/${filmId}/actors/${currentRecord.actorId}/edit`} canAccess={canAccessActors} id={currentRecord.actorId}>{currentRecord.fullName}</CanAccessLink></td>
                <td>
                  {currentRecord.characters?.map((character, index) => (
                    <>
                      <CanAccessLink key={index} to={`/films/${filmId}/characters/${character.characterId}/edit`} canAccess={canAccessActors}>{character.characterName}</CanAccessLink>
                      {index < currentRecord.characters.length - 1 && <span>,&nbsp;</span>}
                    </>
                  ))}

                </td>
                <td>{currentRecord.start ? "Yes" : "No"}</td>
                <td>{currentRecord.finish ? "Yes" : "No"}</td>
                <td>
                  <Field
                    name={`${actor}.callTime`}
                    type="time"
                    component={renderTimeField}
                    step="300"
                    readOnly={readOnly}
                  />
                </td>
                <td>
                  <Field
                    name={`${actor}.onSetTime`}
                    type="time"
                    component={renderTimeField}
                    step="300"
                    readOnly={readOnly}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {error && <div className="error">{error}</div>}



    </div>
  );
}

export default reduxForm({
  form: "shootingDayActorsForm",
  enableReinitialize: true
})(ShootingDayActorsForm);
