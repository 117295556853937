import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { v4 as uuidv4 } from 'uuid';
import { reorderScenes, sortScenesByNumber } from "../../../../shared/utility";
import { IMPORT_MOVIE_MAGIC } from "./actions";

interface ShootingDayOrderState {
  scenes: any[];
  scenesWithoutDays: any[];
  loading: boolean;
  days: any[];
  download: any;
  pristine: boolean;
  showSmartDays: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  smartDaysOnboardingSteps: OnboardingType[];
  errors: any;
  warnings: any;
  isUploading: boolean;
}

export const OnboardingStep1ClassName = 'shooting-order-onboarding-step-1';
export const OnboardingStep2ClassName = 'shooting-order-onboarding-step-2';
export const OnboardingStep3ClassName = 'shooting-order-onboarding-step-3';
export const OnboardingStep4ClassName = 'shooting-order-onboarding-step-4';
export const OnboardingStep5ClassName = 'shooting-order-onboarding-step-5';
export const OnboardingStep6ClassName = 'shooting-order-onboarding-step-6';
export const OnboardingStep7ClassName = 'shooting-order-onboarding-step-7';
export const OnboardingStep8ClassName = 'shooting-order-onboarding-step-8';
export const OnboardingStep9ClassName = 'shooting-order-onboarding-step-9';

export const SmartDaysOnboardingStep1ClassName = 'shooting-order-smart-days-onboarding-step-1';
export const SmartDaysOnboardingStep2ClassName = 'shooting-order-smart-days-onboarding-step-2';

const defaultState: ShootingDayOrderState = {
  scenes: [],
  days: [],
  scenesWithoutDays: [],
  loading: false,
  isUploading: false,
  download: null,
  pristine: true,
  showSmartDays: false,
  readOnly: false,
  warnings: null,
  errors: null,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Smart Days',
      content: `        
      <p>To create your shooting days, first click Smart Days. This will
      order your scenes by Location, and then by Scene Setting, to
      create an efficient shooting order. For this reason it works
      best if you have locations entered for scene settings. You can
      drag and drop scenes to manually adjust scene and day order.</p>
      <p>To use this, you will need to have entered the page length
      for each scene, so the system knows how many days, or part of
      day, it will take to shoot.</p>
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Scene Order',
      content: `<p>
      <p>Smart Days will then take the scene order, and put the scenes into
      days, based on the number of shoot days it will take to shoot the
      scene. You can adjust the number, or part of a day, a scene will
      take to shoot when editing the scene. You can also change the
      number of script pages that can be shot in a day in your
      <a href="/user/settings">user settings</a>. </p>
      <p>If a Scene will take more than one day to shoot, it will split the Scene over
      multiple days. When no more scenes will fit within a day, then it
      will create a new day. If the location changes, then it will also
      create a new day.</p>
      <p>If you want to reset you can click
      the Smart Days button again. </p>
    </p>`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Scenes',
      content: `You can drag and drop scenes between days.
                `
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Add Day',
      content: `Or add new days.`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Days',
      content: `You can also reorder the days themselves, or delete them once they are empty
                `
    },
    {
      target: '.' + OnboardingStep6ClassName,
      title: 'Delete All',
      content: ` <p>
      Once you have days, then the budget will be based on those days,
      and the only way to reduce the budgeted days is to remove days
      from the schedule, or use delete all.
    </p>`
    },
    {
      target: '.' + OnboardingStep7ClassName,
      title: 'Reset Scene Shooting Days',
      content: `<p>Use this if you change the number of pages that can be shot in a day within your user settings. It will update the Shoot Days on all of the scenes. 
                If you had entered any Shoot Days manually, then these will be overwritten, so use with caution.</p>
                `
    },
    {
      target: '.' + OnboardingStep8ClassName,
      title: 'Save',
      content: `
    <p>
      Once you save the days, then the Calender option will appear to be
      able to assign dates to the days.
    </p>`
    }
  ],
  smartDaysOnboardingSteps: [
    {
      target: '.' + SmartDaysOnboardingStep1ClassName,
      title: 'Smart Days',
      content: `        
      To create your shooting days, first click Smart Days. This will
      order your scenes by Location, and then by Scene Setting, to
      create an efficient shooting order. For this reason it works
      best if you have locations entered for scene settings. You can
      drag and drop scenes to manually adjust scene and day order. To
      use this, you will need to have entered the page length
      for each scene, so the system knows how many days, or part of
      day, it will take to shoot.
      `
    },
    {
      target: '.' + SmartDaysOnboardingStep2ClassName,
      title: 'Default settings',
      content: `        
      Before clicking Smart Days, you might want to head to Settings where you can set the default crew, actor and shooting call times that will be applied to all the days created. 
      You will be able to change individual days, once created.
      `
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SCENES_SHOOTING_ORDER_PENDING": {
      return {
        ...state,
        loading: true,
        days: [],
        errors: null,
        warnings: null
      };
    }

    case "FETCH_SCENES_SHOOTING_ORDER_FULFILLED": {
      const scenesWithoutDays = action.payload.data.scenesWithoutDays;
      sortScenesByNumber(scenesWithoutDays)
      return {
        ...state,
        scenes: action.payload.data.scenes,
        hasDayOrder: action.payload.data.hasDayOrder,
        hasPageLengths: action.payload.data.hasPageLengths,
        days: action.payload.data.days,
        loading: false,
        errors: null,
        pristine: true,
        showSmartDays: action.payload.data.hasPageLengths,
        readOnly: action.payload.data.readOnly,
        scenesWithoutDays: scenesWithoutDays
      };
    }

    case "FETCH_SCENES_SHOOTING_ORDER_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "FETCH_SCENES_SMART_SHOOTING_ORDER_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }

    case "FETCH_SCENES_SMART_SHOOTING_ORDER_FULFILLED": {
      return {
        ...state,
        scenes: action.payload.data.scenes,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null,
        showSmartDays: true
      };
    }

    case "FETCH_SCENES_SMART_SHOOTING_ORDER_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "FETCH_SCENES_SHOOTING_ORDER_BY_DAY_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }

    case "FETCH_SCENES_SHOOTING_ORDER_BY_DAY_FULFILLED": {
      return {
        ...state,
        days: action.payload.data.days,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SCENES_SHOOTING_ORDER_BY_DAY_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    case IMPORT_MOVIE_MAGIC + "_PENDING": {
      return {
        ...state,
        loading: true,
        isUploading: true,
        errors: null,
        warnings: null
      };
    }

    case IMPORT_MOVIE_MAGIC + "_FULFILLED": {
      const scenesWithoutDays = action.payload.data.scenesWithoutDays;
      sortScenesByNumber(scenesWithoutDays)
      return {
        ...state,
        days: action.payload.data.shootingDays,
        scenesWithoutDays: scenesWithoutDays,
        readOnly: action.payload.data.readOnly,
        loading: false,
        isUploading: false,
        errors: null,
        warnings: action.payload.data.warnings,
        showSmartDays: false
      };
    }

    case IMPORT_MOVIE_MAGIC + "_REJECTED": {
      return {
        ...state,
        loading: false,
        isUploading: false,
        errors: action.payload.response.data.errors,
        warnings: action.payload.response.data.warnings
      };
    }

    case "REORDER_SCENES_SHOOTING_ORDER": {
      const orderedScenes = reorderScenes(
        state.scenes,
        action.meta.startIndex,
        action.meta.endIndex,
      );
      return {
        ...state,
        scenes: orderedScenes,
        pristine: false
      };
    }

    case "REORDER_SCENES_SHOOTING_ORDER_DAYS": {
      const orderedScenes = reorderDays(
        state.days,
        action.meta.startIndex,
        action.meta.endIndex,
      );
      return {
        ...state,
        days: orderedScenes,
        pristine: false
      };
    }

    case "REORDER_SCENES_SHOOTING_ORDER_DAY_SCENES": {
      if (action.meta.destinationDay === "droppable") {
        return state;
      }

      const newDays = Array.from(state.days);
      const startIndex = action.meta.startIndex;
      const endIndex = action.meta.endIndex;

      if (action.meta.destinationDay === "newScene") {
        if (action.meta.sourceDay === "newScene") {
          return state;
        }

        // take scene out of day

        const newScenesWithoutDays = Array.from(state.scenesWithoutDays);

        let sourceDay = newDays.find((day) => {
          return day.number === +action.meta.sourceDay;
        });

        const sourceScenes = Array.from(sourceDay.scenes);
        const original = sourceScenes.splice(action.meta.startIndex, 1);
        const newSourceDay = { ...sourceDay, scenes: sourceScenes };

        const sourceDayIndex = newDays.findIndex((day) => {
          return day.number === +action.meta.sourceDay;
        });

        newDays.splice(sourceDayIndex, 1);

        newScenesWithoutDays.splice(endIndex, 0, original[0]);

        // reorder newScenesWithoutDays
        sortScenesByNumber(newScenesWithoutDays);

        const days = [...newDays, newSourceDay];
        sortDays(days);
        return {
          ...state,
          days: days,
          scenesWithoutDays: newScenesWithoutDays,
          pristine: false
        };
      }

      if (action.meta.sourceDay === "newScene") {
        const newScenesWithoutDays = Array.from(state.scenesWithoutDays);
        const sourceScene = newScenesWithoutDays[startIndex];
        newScenesWithoutDays.splice(startIndex, 1);

        const destinationDay = newDays.find((day) => {
          return day.number === +action.meta.destinationDay;
        });

        const destinationScenes = Array.from(destinationDay.scenes);
        destinationScenes.splice(action.meta.endIndex, 0, sourceScene);
        const newDestinationDay = { ...destinationDay, scenes: destinationScenes };
        const destinationDayIndex = newDays.findIndex((day) => {
          return day.number === +action.meta.destinationDay;
        });

        newDays.splice(destinationDayIndex, 1);
        const days = [...newDays, newDestinationDay];
        sortDays(days);
        return {
          ...state,
          days: days,
          scenesWithoutDays: newScenesWithoutDays,
          pristine: false
        };
      }

      let sourceDay = newDays.find((day) => {
        return day.number === +action.meta.sourceDay;
      });

      if (action.meta.sourceDay === action.meta.destinationDay) {
        sourceDay.scenes = reorderScenes(
          sourceDay.scenes,
          action.meta.startIndex,
          action.meta.endIndex,
        );

        const sourceDayIndex = newDays.findIndex((day) => {
          return day.number === +action.meta.sourceDay;
        });

        newDays.splice(sourceDayIndex, 1);

        const days = [...newDays, sourceDay];
        sortDays(days);
        return {
          ...state,
          days: days,
          pristine: false
        };
      }


      const destinationDay = newDays.find((day) => {
        return day.number === +action.meta.destinationDay;
      });

      const destinationDayIndex = newDays.findIndex((day) => {
        return day.number === +action.meta.destinationDay;
      });

      newDays.splice(destinationDayIndex, 1);

      const sourceDayIndex = newDays.findIndex((day) => {
        return day.number === +action.meta.sourceDay;
      });

      newDays.splice(sourceDayIndex, 1);

      const sourceScenes = Array.from(sourceDay.scenes);
      const original = sourceScenes.splice(action.meta.startIndex, 1);
      const newSource = { ...sourceDay, scenes: sourceScenes };

      const destinationScenes = Array.from(destinationDay.scenes);
      destinationScenes.splice(action.meta.endIndex, 0, original[0]);
      const newDestination = { ...destinationDay, scenes: destinationScenes };
      const days = [...newDays, newSource, newDestination];
      sortDays(days);
      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case "ADD_SCENES_SHOOTING_ORDER_DAY": {
      const days = Array.from(state.days);
      days.push({ number: days.length + 1, scenes: [], new: true, id: uuidv4() });
      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case "DELETE_SCENES_SHOOTING_ORDER_DAY": {
      const days = Array.from(state.days);
      const dayToDeleteIndex = days.findIndex(
        (d) => d.number == action.meta.dayNumber,
      );
      if (dayToDeleteIndex >= 0) {
        days.splice(dayToDeleteIndex, 1);
      }

      for (let i = dayToDeleteIndex; i < days.length; i++) {
        days[i].number--;
      }

      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case "UPDATE_SCENES_SHOOTING_ORDER_PENDING": {
      return {
        ...state,
        scene: action.payload,
        loadingScene: true,
        errors: null,
      };
    }

    case "UPDATE_SCENES_SHOOTING_ORDER_FULFILLED": {
      return {
        ...state,
        scenes: action.meta.scenes,
        loadingScene: false,
        errors: null,
        pristine: true,
        showSmartDays: true
      };
    }

    case "UPDATE_SCENES_SHOOTING_ORDER_REJECTED": {
      return {
        ...state,
        scenes: action.meta.scenes,
        errors: action.payload.response.data.errors,
        loadingScene: false,
      };
    }

    case "ADD_SCENES_SHOOTING_ORDER_DAYS_PENDING": {
      return {
        ...state,
        loadingScene: true,
        errors: null,
      };
    }

    case "ADD_SCENES_SHOOTING_ORDER_DAYS_FULFILLED": {
      const days = Array.from(state.days);
      const updatedDays = action.payload.data.shootingDays;
      days.forEach((day: any) => {
        const updatedDay = updatedDays.find(d => d.id == day.id);
        if (updatedDay) {
          day.number = updatedDay.number;
          day.date = updatedDay.date;
          day.new = false;
        }
      });
      return {
        ...state,
        days,
        loadingScene: false,
        errors: null,
        pristine: true
      };
    }

    case "ADD_SCENES_SHOOTING_ORDER_DAYS_REJECTED": {
      return {
        ...state,
        days: action.meta.days,
        errors: action.payload.response.data.errors,
        loadingScene: false,
      };
    }

    case "DELETE_ALL_SHOOTING_DAYS_PENDING": {
      return {
        ...state,
        loading: true,
        warnings: null,
        errors: null
      };
    }

    case "DELETE_ALL_SHOOTING_DAYS_FULFILLED": {
      return {
        ...state,
        days: [],
        loading: false,
        loadingScene: false,
        showSmartDays: true
      };
    }

    case "DELETE_ALL_SHOOTING_DAYS_REJECTED": {
      return {
        ...state,
        loading: false,
        loadingScene: false,
        errors: action.payload.response.data.errors
      };
    }

    case "RESET_SCENE_SHOOTS_DAYS_PENDING": {
      return {
        ...state,
        loading: true,
        warnings: null,
        errors: null
      };
    }

    case "RESET_SCENE_SHOOTS_DAYS_FULFILLED": {
      return {
        ...state,
        loadingScene: false,
        loading: false,
        warnings: null,
        errors: null
      };
    }

    case "RESET_SCENE_SHOOTS_DAYS_REJECTED": {
      return {
        ...state,
        loadingScene: false,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "DOWNLOAD_SHOOTING_DAYS_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case "DOWNLOAD_SHOOTING_DAYS_PENDING": {
      return {
        ...state,
        loading: false,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case "DOWNLOAD_SHOOTING_DAYS_FULFILLED": {
      return state;
    }


    case "DOWNLOAD_SHOOTING_DAYS_COMPLETE": {
      return {
        ...state,
        loading: false,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case "DOWNLOAD_SHOOTING_DAYS_ERRORED": {
      return {
        ...state,
        progress: null,
        errors: action.meta.errors
      };
    }

    case "CLEAR_SHOOTING_DAYS_PDF": {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    case "CLEAR_SHOOTING_DAYS_DOWNLOAD": {
      return {
        ...state,
        download: null
      };
    }

    default:
      return state;
  }
};

const reorderDays = (list, sourceIndex, destinationIndex) => {
  const result: any[] = Array.from(list);
  if (result[destinationIndex].fixed) {
    return result;
  }

  result[sourceIndex].number = destinationIndex + 1;
  if (sourceIndex > destinationIndex) {
    for (let i = destinationIndex; i < sourceIndex; i++) {
      result[i].number++;
    }
  }

  if (sourceIndex < destinationIndex) {
    for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
      result[i].number--;
    }
  }

  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};


function sortDays(days) {
  days.sort((a, b) => {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
  });
}

export default reducer;
