import React from "react";
import { Route } from "react-router-dom";
import ListVisualEffectsContainer from "./list-container";
import AddVisualEffect from "./add";
import EditVisualEffect from "./edit";
import VisualEffectScenes from "./scenes";
import VisualEffectMenu from "./menu";
import Overview from "./overview/container";
import { PrivateRoute } from "../../shared/private-route";

const VisualEffectsRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/visual-effects"
        component={ListVisualEffectsContainer}
      />
      <PrivateRoute path="/films/:filmId/visual-effects/:visualEffectId" component={VisualEffectMenu} />
      <PrivateRoute exact path="/films/:filmId/visual-effects/add" component={AddVisualEffect} />
      <PrivateRoute exact path="/films/:filmId/visual-effects/overview" component={Overview} />
      <PrivateRoute
        exact
        path="/films/:filmId/visual-effects/:visualEffectId"
        component={EditVisualEffect}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/visual-effects/:visualEffectId/edit"
        component={EditVisualEffect}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/visual-effects/:visualEffectId/scenes"
        component={VisualEffectScenes}
      />
    </>
  );
};

export default VisualEffectsRoutes;
