import React from "react";
import { PrivateRoute } from "../shared/private-route";
import UserSettingsPage from "./index";
import UserSCommunicationPreferencesPage from "./communication-preferences/index";

const UserRoutes = () => {
  return (
    <>
      <PrivateRoute exact path="/user/settings" component={UserSettingsPage} />
      <PrivateRoute exact path="/user/communication-preferences" component={UserSCommunicationPreferencesPage} />
    </>
  );
};

export default UserRoutes;
