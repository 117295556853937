import React from "react";
import CommercialScript from "./index";
import { PrivateRoute } from "../../shared/private-route";

const CommercialScriptRoutes = () => {
  return (
    <>
      <PrivateRoute path="/films/:filmId/commercial-script" component={CommercialScript} />      
    </>
  );
};

export default CommercialScriptRoutes;
