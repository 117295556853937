import React from "react";

const Social = ({imageName, link, title, Icon} : any) => {    

    return (
        <a href={link} target="_blank" title={title}>
            <div className="social">{Icon && <Icon />}{!Icon && <img width="28px" src={`/images/socials/${imageName}.svg`}/>}</div>
        </a>
    )
}

export default Social;