import React from "react";
import ComingSoon from "./coming-soon";
import ListCategoriesContainer from "./euipment-category/list-categories-container";
import ListEquipmentContainer from "./list-equipment-container";
import AddEquipment from "./add";
import EditEquipment from "./edit";
import AddEquipmentCategory from "./euipment-category/add";
import EditEquipmentCategory from "./euipment-category/edit";
import EquipmentScenes from "./scenes/scenes";
import EquipmentMenu from "./menu";
import { PrivateRoute } from "../../shared/private-route";

const SceneSettingsRoutes = () => {
  return (
    <>
      <PrivateRoute path="/films/:filmId/equipment-category" component={ListCategoriesContainer} />
      <PrivateRoute path="/films/:filmId/equipment-category/add" component={AddEquipmentCategory} />
      <PrivateRoute path="/films/:filmId/equipment-category/:equipmentCategoryId/edit" component={EditEquipmentCategory} />

      <PrivateRoute path="/films/:filmId/equipment-category/:categoryId/equipment" component={ListEquipmentContainer} />
      <PrivateRoute path="/films/:filmId/equipment-category/:categoryId/equipment/:equipmentId" component={EquipmentMenu} />
      <PrivateRoute path="/films/:filmId/equipment-category/:categoryId/equipment/add" component={AddEquipment} />
      <PrivateRoute path="/films/:filmId/equipment-category/:categoryId/equipment/:equipmentId/edit" component={EditEquipment} />

      <PrivateRoute
        exact
        path="/films/:filmId/equipment-category/:categoryId/equipment/:equipmentId/scenes"
        component={EquipmentScenes}
      />
    </>
  );
};

export default SceneSettingsRoutes;
