import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./root-reducer";
import { signalRMiddleware } from "./middleware/signalRMiddleware";
import { signal as collaboratorSignal } from './middleware/collaboratorSignalRMiddleware';
import { signal as filmTaskSignal } from './middleware/filmTaskSignalRMiddleware';
import { signal as messageSignal } from './middleware/messageSignalRMiddleware';

const middleware = composeWithDevTools(applyMiddleware(promise, thunk, signalRMiddleware, collaboratorSignal, filmTaskSignal, messageSignal));

export default createStore(rootReducer, middleware);
