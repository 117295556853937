import React from "react";
import { Route } from "react-router-dom";
import Overview from "./overview-container";
import { PrivateRoute } from "../../shared/private-route";
import ListScenesContainer from "./list-container";
import SceneContainer from "./scene-container";

const BreakdownRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/breakdown"
        component={ListScenesContainer}
      />
      <PrivateRoute
        path="/films/:filmId/breakdown/overview"
        component={Overview}
      />
      <PrivateRoute
        path="/films/:filmId/breakdown/scenes/:sceneId"
        component={SceneContainer}
      />
    </>
  );
};

export default BreakdownRoutes;
