import getApiClient from "./../../../../shared/api";
import { showInfo, showError, showWarning, clearNotifications } from "./../../../../notifications/ducks/actions";

export function fetchActorNumbers(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ACTOR_ORDER",
      payload: getApiClient().get(`/film/${filmId}/actor/order`),
    });
  };
}

export function updateActorOrder(sourceIndex, destinationIndex) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_ACTOR_ORDER",
      meta: { sourceIndex, destinationIndex },
    });
  };
}

export function saveActorOrder(filmId: any, actors: any[]) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_ACTOR_ORDER",
      payload: getApiClient().put(`/film/${filmId}/actor/order`, { actors }),
      meta: { actors }
    })
    .then((response) => {
      dispatch(showInfo(`Updated actor order`));
      
      if (response.value.data.hasWarnings) {
        dispatch(showWarning(`Warning updating actor order`));
      }
    })
    .catch((response) => {        
      dispatch(showError(`Error updating actor order`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}

