import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../shared/loader";
import { fetchCharacters } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list";

function ListCharactersContainer(props) {
  const { filmId } = props.match.params;
  const { fetchCharacters, loading, characters, errors, readOnly } = props;

  useEffect(() => {
    fetchCharacters(filmId);
  }, [filmId, fetchCharacters]);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(characters, filmId) {
    return (
      <div className="blade-menu">
        <List characters={characters} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && (
          <NavLink
            to={`/films/${filmId}/characters/add`}
            activeClassName={
              "is-active " + window.location.pathname.endsWith("add")
                ? "disabled"
                : ""
            }
            className="button is-transparent is-add">
            <AddIcon className="white-icon" />
            Add Character
          </NavLink>
        )}
      </div>
    );
  }

  return loading ? renderLoading() : renderCharacters(characters, filmId);
}

function mapStateToProps(state) {
  const rootState = state.charactersState.rootState;
  return {
    characters: rootState.characters,
    readOnly: rootState.readOnly,
    loading: rootState.loading,
    errors: rootState.errors
  };
}

export default connect(mapStateToProps, {
  fetchCharacters
})(ListCharactersContainer);
