import React from "react";
import NumberFormat from "react-number-format";
import CurrencyDisplay from "../../shared/currency-display";
import HelpText from "../../shared/help-text";
import Prop from "./prop";

function Props(props) {
  const {
    budget,
    onChangePropBuget,
    onChangePropDays,
    showHideProps,
    propsVisible,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <div>
      <div className="columns subtitle is-4 mt-1">
        <div className="column">
          <h4
            className="subtitle is-4 pointer"
            onClick={() => showHideProps(!propsVisible)}>
            <button className="button is-text is-small">
              {!propsVisible && <i className="material-icons">add</i>}
              {propsVisible && <i className="material-icons">remove</i>}
            </button>
            Props
          </h4>
        </div>
        <div className="column budget-header-number">
          {!propsVisible && (
            <CurrencyDisplay
              value={budget.propsTotal}
            />
          )}
        </div>
      </div>
      {propsVisible && (
        <>
          <HelpText>
            A dash - in days means it is a prop you are buying or making, so
            does not depend on the number of days.
          </HelpText>

          <table className="table budget-table">
            <thead>
              <tr>
                <th>Name</th>
                <th className="budget-days-column">Days</th>
                <th className="budget-daily-rate-column">Budget</th>
                <th className="has-text-centered">Quantity</th>
                <th className="has-text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {(budget?.props || []).map((prop, i) => {
                return (
                  <Prop
                    key={i}
                    prop={prop}
                    onChangePropBuget={onChangePropBuget}
                    onChangePropDays={onChangePropDays}
                    maxDays={budget.days}
                    totalScenes={budget.totalScenes}
                    hasShootingDays={budget.hasShootingDays}
                    readOnly={readOnly}
                    filmId={filmId}
                    saving={saving}
                  />
                );
              })}
              <tr>
                <td colSpan={4}></td>
                <td className="has-text-right">
                  <CurrencyDisplay
                    value={budget.propsTotal}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default Props;
