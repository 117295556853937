import getApiClient from "./../../../../shared/api";

export function fetchAllScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENES_FOR_SCRIPT",
      payload: getApiClient().get(`/film/${filmId}/scene`)
    });
  };
}

export function fetchScenesAssignedToProp(filmId, propId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_ASSIGNED_TO_SCRIPT",
      payload: getApiClient().get(
        `/film/${filmId}/Prop/${propId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedToProp(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SET_SCENE_ASSIGNED_TO_SCRIPT",
      meta: { sceneId }
    });
  };
}

export function fetchAllCharacters(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_CHARACTERS_FOR_SCRIPT",
      payload: getApiClient().get(`/film/${filmId}/character`)
    });
  };
}

export function fetchAllSceneSettings(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENE_SETTINGS_FOR_SCRIPT",
      payload: getApiClient().get(`/film/${filmId}/scenesetting`)
    });
  };
}

export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_SCENES_FOR_SCRIPT"
    });
  };
}
