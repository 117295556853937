import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

import getApiClient from "./../../../../shared/api";

export const SMART_BREAKDOWN = "SMART_BREAKDOWN";
export const UPLOAD_SCRIPT_VERSION_ERROR = "UPLOAD_SCRIPT_VERSION_ERROR";
export const INITIALISE_UPDATE_SCRIPT = "INITIALISE_UPDATE_SCRIPT";

export function smartBreakdown(filmId) {
  return (dispatch) => {
    dispatch({
      type: SMART_BREAKDOWN,
      payload: getApiClient().put(`/film/${filmId}/script/smartBreakdown`, {})
    }).then((response) => {
      dispatch(showInfo(`Successfully ran smart breakdown`));
    })
      .catch((response) => {
        dispatch(showError(`Error running smart breakdown`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function setUploadError(error) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_VERSION_ERROR,
      meta: { error }
    });
  };
}

export function clearScriptUpload() {
  return (dispatch) => {
    dispatch({
      type: INITIALISE_UPDATE_SCRIPT
    });
  };
}