import { FETCH_CHARACTER_COSTUMES } from "./actions";

interface CharacterPropsState {
  costumes: any[];
  loading: boolean;
  readOnly: boolean;
}

const defaultState: CharacterPropsState = {
  costumes: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_CHARACTER_COSTUMES + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_CHARACTER_COSTUMES + "_FULFILLED": {
      const costumes = action.payload.data.costumes;
      return {
        ...state,
        costumes,
        loading: false
      };
    }

    case FETCH_CHARACTER_COSTUMES + "_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;