import * as React from 'react'

export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.738" viewBox="0 0 20 15.738" {...props} title="Off Screen">
    <g id="Icon_Off_Screen" data-name="Icon / Off Screen" transform="translate(1 0.707)">
      <g id="Path_32768" data-name="Path 32768" transform="translate(50.144 -2.838)" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path d="M-50.144,10.006h0c5.516-6.931,12.419-7.015,17.974-.218l.026.032-.1.135C-37.729,17-44.654,17.019-50.144,10.006Z" stroke="none" />
        <path d="M -41.21613311767578 15.25190734863281 C -38.10593032836914 15.25190734863281 -34.99519348144531 13.48559761047363 -32.24800109863281 9.954998970031738 L -32.14400100708008 9.819998741149902 L -32.17000198364258 9.787999153137207 C -34.91698837280273 6.426836013793945 -37.99348831176758 4.748376369476318 -41.06901550292969 4.748376369476318 C -44.21286773681641 4.748376369476318 -47.3557014465332 6.502426147460938 -50.14400100708008 10.00599956512451 C -47.40570449829102 13.50393867492676 -44.31118774414063 15.25190734863281 -41.21613311767578 15.25190734863281 M -41.21612930297852 16.25190925598145 C -44.68888092041016 16.25190925598145 -48.04837036132813 14.30525875091553 -50.93141937255859 10.6224193572998 C -51.21670150756836 10.25798892974854 -51.21464920043945 9.745419502258301 -50.92645263671875 9.383289337158203 C -47.99266052246094 5.696898937225342 -44.58401107788086 3.748379230499268 -41.06901168823242 3.748379230499268 C -37.63151168823242 3.748379230499268 -34.28652954101563 5.618019104003906 -31.39570045471191 9.155179023742676 L -31.36789131164551 9.189409255981445 C -31.07549095153809 9.54927921295166 -31.0688419342041 10.06294918060303 -31.35181045532227 10.43027877807617 L -31.455810546875 10.56527900695801 C -34.35157012939453 14.28682899475098 -37.72561264038086 16.25190925598145 -41.21612930297852 16.25190925598145 Z" stroke="none" fill="#fff" />
      </g>
      <g id="Ellipse_50" data-name="Ellipse 50" transform="translate(5.419 3.7)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <circle cx="3.462" cy="3.462" r="3.462" stroke="none" />
        <circle cx="3.462" cy="3.462" r="3.962" fill="none" />
      </g>
      <line id="Line_537" data-name="Line 537" y1="14.324" x2="14.324" transform="translate(1.777)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
    </g>
  </svg>
