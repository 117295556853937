import getApiClient from "../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export const ADMIN_FETCH_USER_FILMS = "ADMIN_FETCH_USER_FILMS";
export const ADMIN_EXPORT_FILM = "ADMIN_EXPORT_FILM";
export const UPDATE_FILM_SCRIPT_URLS = "UPDATE_FILM_SCRIPT_URLS";

export function fetchUserFilms(userId) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_FETCH_USER_FILMS,
      payload: getApiClient().get(`/admin/user/${userId}/films`)
    });
  };
}

const url = "/film";

function showFile(blob, filmName) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  debugger;
  var newBlob = new Blob([blob], { type: "txt/json" });

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;

  link.download = filmName + ".json";
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

export function exportFilm(film) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_EXPORT_FILM,
      meta: { film },
      payload: getApiClient().post(`${url}/${film.id}/export`, {}),
    })
      .then((r) => {
        debugger;
        return r.value.data.blob();
      })
      .then((blob) => {
        debugger;
        showFile(blob, film.name);
      })
      .catch((response) => {
        dispatch(showError(`Error exporting film ${film.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}