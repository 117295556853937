import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import * as QueryString from "query-string"
import {
  confirmEmail
} from "./ducks/actions";
import Form from "./form";
import BoxWraper from "../box-wrapper";
import AnonymousPage from "../anonymous-pages/anonymous-page";


function Login(props) {
  const {    
    confirmEmail,
    loading,
    errors,
    enabled   
  } = props;

  const params = QueryString.parse(props.location.search);      
  const userId = params.userId;
  const token = params.token;

  useEffect(() => {
    confirmEmail(userId, token);
  }, []);
  
  return (
    <AnonymousPage>      
      <BoxWraper>
      {loading && <Loader />}
      {!loading && 
        <Form
          errors={errors}        
          loading={false}      
          enabled={enabled}  
          {...props}
        />}
      </BoxWraper>
    </AnonymousPage>
  );
}

function mapStateToProps(state) {
  const { accountState } = state;
  return {    
    errors: accountState.confirmEmailState.errors,
    loading: accountState.confirmEmailState.loading,
    enabled: accountState.confirmEmailState.enabled
  };
}

export default connect(mapStateToProps, {
  confirmEmail
})(Login);
