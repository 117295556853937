export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.394" height="32.183" viewBox="0 0 17.792 22.619" {...props}>
    <g id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected" transform="translate(0.5 0.5)">
      <g id="Icon_Navigation_Script" data-name="Icon / Navigation / Script">
        <path id="Path_32632" data-name="Path 32632" d="M21.039,22.473H4.852A.146.146,0,0,1,4.7,22.33V1A.146.146,0,0,1,4.852.854h11.9a.116.116,0,0,1,.118.115l.005,3.856a.144.144,0,0,0,.146.142l3.948,0a.21.21,0,0,1,.212.208V22.33A.145.145,0,0,1,21.039,22.473Z" transform="translate(-4.705 -0.854)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_469" data-name="Line 469" x2="8" transform="translate(4.7 9.076)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_504" data-name="Line 504" x2="8" transform="translate(4.7 11.97)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_505" data-name="Line 505" x2="8" transform="translate(4.7 14.864)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_470" data-name="Line 470" x2="2.577" transform="translate(7.198 6.181)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_471" data-name="Line 471" x2="1.545" transform="translate(0.379 10.809)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_472" data-name="Line 472" x2="1.545" transform="translate(0.379 7.628)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_473" data-name="Line 473" x2="1.545" transform="translate(0.379 4.428)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_474" data-name="Line 474" x2="1.545" transform="translate(0.379 14.01)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_475" data-name="Line 475" x2="1.545" transform="translate(0.379 17.21)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32634" data-name="Path 32634" d="M23.5.854" transform="translate(-10.805 -0.854)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_476" data-name="Line 476" x2="4" y2="4" transform="translate(12.585 0.354)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      </g>
    </g>
  </svg>
