import { sortByName } from "./../../../../shared/utility";
import { DELETE_SINGLE_EQUIPMENT_CATEGORY, FETCH_EQUIPMENT_CATEGORIES, ADD_SINGLE_EQUIPMENT_CATEGORY, UPDATE_SINGLE_EQUIPMENT_CATEGORY, FETCH_EQUIPMENT_CATEGORY, CLEAR_EQUIPMENT_CATEGORY } from "./actions";

const defaultState = {
  equipmentCategories: [],
  loading: false,
  readOnly: false,    
  equipmentCategory: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "CLEAR_DEPARTMENT": {
      return {
        ...state,        
        errors: null
      };
    }
    
    case  FETCH_EQUIPMENT_CATEGORIES + "_PENDING": {
      return {
        ...state,
        loading: true,
        categories: [],
        errors: null
      }; 
    }

    case FETCH_EQUIPMENT_CATEGORIES + "_FULFILLED": {      
      return {
        ...state,
        equipmentCategories: action.payload.data.equipmentCategories,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null 
      };
    }

    case "FETCH_EQUIPMENTS_PENDING": {
      return {
        ...state,
        equipments: [],
        loading: true,
        errors: null
      };
    }

    case "FETCH_EQUIPMENTS_FULFILLED": {
      return {
        ...state,
        equipments: action.payload.data.equipments,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null 
      };
    }

    case FETCH_EQUIPMENT_CATEGORY + "_PENDING": {
      return {
        ...state,
        equipmentCategory: null,
        loading: true,
        errors: null
      };
    }

    case FETCH_EQUIPMENT_CATEGORY + "_REJECTED": {
      return {
        ...state,        
        loading: false,        
        errors: action.payload.response.data.errors
      };
    }

    case FETCH_EQUIPMENT_CATEGORY + "_FULFILLED": {
      return {
        ...state,
        equipmentCategory: action.payload.data,
        loading: false,
        errors: null
      };
    }

    case ADD_SINGLE_EQUIPMENT_CATEGORY + "_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case ADD_SINGLE_EQUIPMENT_CATEGORY + "_FULFILLED": {
      const equipmentCategory: any = action.payload.data.equipmentCategory;
      let equipmentCategories = [equipmentCategory, ...state.equipmentCategories];
      sortByName(equipmentCategories);
      return {
        ...state,
        equipmentCategories,
        loading: false,
        errors: null,
        redirect: { to: "list", categoryId: equipmentCategory.id  }
      };
    }

    case ADD_SINGLE_EQUIPMENT_CATEGORY + "_REJECTED": {
      return {
        ...state,
        equipmentCategory: action.meta.equipmentCategory,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case UPDATE_SINGLE_EQUIPMENT_CATEGORY + "_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case UPDATE_SINGLE_EQUIPMENT_CATEGORY + "_FULFILLED": {

      const equipmentCategory = { ...action.meta.equipmentCategory, ...action.payload.data.equipmentCategory };
      const equipmentCategories: any = state.equipmentCategories.map((c: any, index: number) => {
        if (c.id === equipmentCategory.id) {
          return { ...c, ...equipmentCategory };
        } else {
          return c;
        }
      });
      
      sortByName(equipmentCategories);
      return {
        ...state,
        equipmentCategories: equipmentCategories,
        equipmentCategory: equipmentCategory,
        loading: false,
        errors: null,
        redirect: { to: "list", categoryId: equipmentCategory.id  }
      };
    }

    case UPDATE_SINGLE_EQUIPMENT_CATEGORY + "_REJECTED": {
      return {
        ...state,
        equipmentCategory: action.meta.equipmentCategory,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case DELETE_SINGLE_EQUIPMENT_CATEGORY + "_FULFILLED": {
      const equipmentCategory = action.meta.equipmentCategory;
      const equipmentCategories: any = state.equipmentCategories.filter((c: any, index: number) => {
        return c.id !== equipmentCategory.id;
      });
      return {
        ...state,
        equipmentCategories,
        loading: false,
        redirect: { to: "list" }
      };
    }

    case DELETE_SINGLE_EQUIPMENT_CATEGORY + "_REJECTED": {
      return {
        ...state,        
        equipment: action.meta.equipment,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case CLEAR_EQUIPMENT_CATEGORY: {
      return {
        ...state,
        equipmentCategory: {}
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        department: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
