import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

export const SEND_DIETARY_REQUIREMENTS = "SEND_DIETARY_REQUIREMENTS";
export const SEND_DIETARY_REQUIREMENTS_TO_PERSON = "SEND_DIETARY_REQUIREMENTS_TO_PERSON";
export const FETCH_PEOPLE_DETAILS_PROGRESS = "FETCH_PEOPLE_DETAILS_PROGRESS";
export const UPDATE_SEND_PERSON_DETAILS_PROGRESS = "UPDATE_SEND_PERSON_DETAILS_PROGRESS";
export const UPDATE_PERSON_DETAILS_EMAIL_STATUS = "UPDATE_PERSON_DETAILS_EMAIL_STATUS";
export const UPDATE_EXCLUDE_DRINK = "UPDATE_EXCLUDE_DRINK";

export function sendDietaryRequirements(filmId) {
  return (dispatch) => {
    dispatch({
      type: SEND_DIETARY_REQUIREMENTS,
      payload: getApiClient().put(
        `/film/${filmId}/Scheduling/SendDietaryRequirements`, {}
      ),
      meta: {}
    });
    // .then((response) => {
    //   dispatch(showInfo(`Sent Dietary Requirements email`));
    // })
    // .catch((response) => {
    //   dispatch(showError(`Error sending Dietary Requirements email`));
    // })
    // .then((response) => {
    //   dispatch(clearNotifications());
    // });
  };
}

export function sendDietaryRequirementsToPerson(filmId, personId, personType) {
  return (dispatch) => {
    dispatch({
      type: SEND_DIETARY_REQUIREMENTS_TO_PERSON,
      payload: getApiClient().put(
        `/film/${filmId}/Scheduling/SendDietaryRequirementsToPerson`, { personId, personType }
      ),
      meta: { personId, personType }
    });
  };
}

export function fecthPeopleDetailsProgress(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PEOPLE_DETAILS_PROGRESS,
      payload: getApiClient().get(`/film/${filmId}/PersonMessages`),
    });
  };
}

export function updateExludeDrink(filmId, shootingDaySettingsId, exclude) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_EXCLUDE_DRINK,
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDaySettingsId}/ExcludeDrinks`, { exclude }
      ),
      meta: { exclude }
    });
  };
}
