import { FETCH_PRICES, LOG_ANONYMOUS_ERROR } from "./actions";

const defaultState: any = {
  currencySymbol: "$",
  prices: [0, 10, 25, 60]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_PRICES + "_FULFILLED": {
      return {
        ...state,
        prices: action.payload.data.prices,
        currencySymbol: action.payload.data.currencySymbol
      };
    }

    case LOG_ANONYMOUS_ERROR + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case LOG_ANONYMOUS_ERROR + "_FULFILLED": {
      return {
        ...state,
        loading: false
      }
    }

    case LOG_ANONYMOUS_ERROR + "_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
