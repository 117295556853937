import './list.scss';
import ListFeatureRequestsRow from "./row";

function ListFeatureRequests(props) {
  const {
    featureRequests,
    loading,
    hasSearched
  } = props;

  return (
    <div>
      {featureRequests?.length === 0 && !loading && hasSearched && <div>None found</div>}

      <table className="feature-request-list-table">
        <tbody>
          {(featureRequests || []).map((featureRequest: any, index: number) => (
            <ListFeatureRequestsRow key={featureRequest.id} featureRequest={featureRequest} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ListFeatureRequests;