import getApiClient from "./../../../../shared/api";

export const FETCH_CHARACTER_MAKEUPS = "FETCH_CHARACTER_MAKEUPS";

export function fetchCharacterMakeups(filmId, characterId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CHARACTER_MAKEUPS,
      payload: getApiClient().get(`/film/${filmId}/character/${characterId}/makeups`)
    });
  };
}
