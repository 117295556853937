import { useEffect } from "react";
import { fetchCharacterMakeups } from "./ducks/actions";
import { connect } from "react-redux";
import CharacterMakeups from "./character-makeups";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

const CharacterMakeupsContainer = (props) => {
    const { loading, makeups, fetchCharacterMakeups } = props;
    const characterId = props.characterId || props.match.params.characterId;
    const filmId = props.filmId || props.match.params.filmId;
    useEffect(() => {
        fetchCharacterMakeups(filmId, characterId);
    }, [characterId]);

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/characters/:characterId/makeups"
            loading={loading}>
            <CharacterMakeups makeups={makeups} filmId={filmId} loading={loading} />
        </ScrollIntoView>
    )
}

function mapStateToCostumes(state) {
    const { charactersState } = state;
    const makeupState = charactersState.makeupState;
    return {
        errors: makeupState.errors,
        makeups: makeupState.makeups,
        loading: makeupState.loading
    };
}

export default connect(mapStateToCostumes, {
    fetchCharacterMakeups
})(CharacterMakeupsContainer);