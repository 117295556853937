import React from "react";
import { NavLink } from "react-router-dom";

const SetDressingsList = ({ setDressingItems, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Set Dressing</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/set-dressings/overview`}
            activeClassName="is-active">
            Overview
          </NavLink>
        </li>
        <hr />
        {setDressingItems?.map((setDressing, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/set-dressings/${setDressing.id}/edit`}
                title={setDressing.name}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/set-dressings/${setDressing.id}`
                  );
                }}>
                {setDressing.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default SetDressingsList;
