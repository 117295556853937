import React from "react";
import Invitation from "./invitation";
import { Route } from "react-router-dom";

const UserRoutes = () => {
  return (
    <>
      <Route exact path="/invitation/:data/:referralCode" component={Invitation} />
    </>
  );
};

export default UserRoutes;
