import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

export const SHOT_LIST_INCLUDE_IMAGES = "SHOT_LIST_INCLUDE_IMAGES";
export const SHOT_LIST_INCLUDE_EQUIPMENT = "SHOT_LIST_INCLUDE_EQUIPMENT";

export function fetchShootingDaysShotListSchedule(filmId, shootingdayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTINGDAY_SHOT_LIST_SCHEDULE",
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingdayId}/ShotListSchedule`
      )
    });
  };
}

export function fetchScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTINGDAY_SCENES",
      payload: getApiClient().get(`/film/${filmId}/Scene`)
    });
  };
}

export function fetchSceneDetails(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTINGDAY_SCENE_DETAILS",
      payload: getApiClient().get(
        `/film/${filmId}/Scene/${sceneId}/WithLocationAndActors`
      )
    });
  };
}

// /api/film/{filmId}/Scene/{id}/WithLocationAndActors

// export function fetchShootingDayScheduleEvent(filmId, shootingdayId, eventId) {
//   return (dispatch) => {
//     dispatch({
//       type: "FETCH_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_LIST",
//       payload: getApiClient().get(`/film/${filmId}/ShootingDay/${shootingdayId}/ScheduleEvent`),
//     });
//   };
// }

export function updateShotOrder(
  sourceIndex,
  destinationIndex,
  sourceDroppableId,
  destinationDroppableId
) {
  return (dispatch) => {
    dispatch({
      type: "SHOOTINGDAY_SCHEDULE_EVENT_UPDATE_SHOT_ORDER",
      meta: {
        sourceIndex,
        destinationIndex,
        sourceDroppableId,
        destinationDroppableId
      }
    });
  };
}

export function deleteShot(timeSlot, sceneId, shotId) {
  return (dispatch) => {
    dispatch({
      type: "SHOOTINGDAY_SCHEDULE_EVENT_DELETE_SHOT",
      meta: {
        timeSlot,
        sceneId,
        shotId
      }
    });
  };
}

export function updateShotListSchedule(
  shotListSchedule,
  filmId,
  shootingdayId,
  includeImages,
  includeEquipment
) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_SHOT_LIST_SCHEDULE",
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingdayId}/ShotListSchedule/`,
        { timeSlots: shotListSchedule, includeImages, includeEquipment }
      ),
      meta: { shotListSchedule, includeImages, includeEquipment }
    })
      .then((response) => {
        dispatch(showInfo(`Updated shooting day shot list`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating shooting day shot list`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateShootingDayScheduleEvent(
  filmId,
  shootingdayId,
  scheduleEvent
) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT",
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingdayId}/Schedule/Event/${scheduleEvent.id}`,
        scheduleEvent
      ),
      meta: { scheduleEvent }
    })
      .then((response) => {
        dispatch(
          showInfo(`Updated shooting day event @ ${scheduleEvent.time}`)
        );
      })
      .catch((response) => {
        dispatch(
          showError(`Error updating shooting day event @ ${scheduleEvent.time}`)
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addShootingDayScheduleEvent(
  filmId,
  shootingdayId,
  scheduleEvent
) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT",
      payload: getApiClient().post(
        `/film/${filmId}/ShootingDay/${shootingdayId}/Schedule/Event`,
        scheduleEvent
      ),
      meta: { scheduleEvent }
    })
      .then((response) => {
        dispatch(showInfo(`Added shootingday event at ${scheduleEvent.time}`));

        if (scheduleEvent.type === 1) {
          dispatch({
            type: "SET_SHOOTING_DAY_SCHEDULE_VALID"
          });
        }
      })
      .catch((response) => {
        dispatch(showError(`Error adding shootingday event`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteShootingDayScheduleEvent(
  filmId,
  shootingdayId,
  scheduleEvent
) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT",
      payload: getApiClient().delete(
        `/film/${filmId}/ShootingDay/${shootingdayId}/Schedule/Event/${scheduleEvent.id}`
      ),
      meta: { shootingdayId, scheduleEvent }
    })
      .then((response) => {
        dispatch(
          showInfo(`Deleted shooting day event @ ${scheduleEvent.time}`)
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error deleting  shooting day event @ ${scheduleEvent.time}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateOrder(startIndex, endIndex) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_SCENES_SCRIPT_DAYS",
      meta: { startIndex, endIndex }
    });
  };
}

export function updateSceneOrder(
  startIndex,
  endIndex,
  sourceDay,
  destinationDay
) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_SCENES_SCRIPT_DAY_SCENES",
      meta: { startIndex, endIndex, sourceDay, destinationDay }
    });
  };
}

export function updateTimeSlotOrder(startIndex, endIndex) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_SCENES_SCRIPT_DAYS",
      meta: { startIndex, endIndex }
    });
  };
}

export function addTimeSlot(startTime, endTime, sceneSetting, scene) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SHOT_LIST_SCHEDULE_TIME_SLOT",
      meta: { startTime, endTime, sceneSetting, scene }
    });
  };
}

export function timeSlotStartTimeChanged(oldTime, newTime, description) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SHOT_LIST_SCHEDULE_TIME_SLOT",
      meta: { oldTime, newTime, description }
    });
  };
}

export function addCrewTimeSlot(startTime, endTime, description, mealTimeSlot) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SHOT_LIST_SCHEDULE_CREW_TIME_SLOT",
      meta: { startTime, endTime, description, mealTimeSlot }
    });
  };
}

export function crewEventDescriptionChanged(description) {
  return (dispatch) => {
    dispatch({
      type: "CREW_TIME_SLOT_DESCRIPTION_CHANGED",
      meta: { description }
    });
  };
}

export function mealTimeSlotChanged(mealTimeSlot) {
  return (dispatch) => {
    dispatch({
      type: "CREW_TIME_SLOT_MEAL_CHANGED",
      meta: { mealTimeSlot }
    });
  };
}

export function deleteTimeSlot(timeSlotStart) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SHOT_LIST_SCHEDULE_TIME_SLOT",
      meta: { timeSlotStart }
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function setDraggingSceneId(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_SET_DRAGGING_SCENE_ID",
      meta: { sceneId }
    });
  };
}

export function unselectAll() {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_UNSELECT_ALL"
    });
  };
}

export function startTimeChange(time) {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_START_TIME_CHANGED"
    });
  };
}

export function selectShots(sceneSetting, scene, lens, movement, actor) {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_SELECT_SHOTS",
      meta: { sceneSetting, scene, lens, movement, actor }
    });
  };
}

export function selectAllShots(sceneSetting) {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_SELECT_ALL_SHOTS",
      meta: { sceneSetting }
    });
  };
}

export function selectNoShots() {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_UNSELECT_ALL"
    });
  };
}

export function toggleSelection(shotId) {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_TOGGLE_SELECTION",
      meta: { shotId }
    });
  };
}

export function toggleSelectionInGroup(shotId) {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_TOGGLE_SELECTION_IN_GROUP",
      meta: { shotId }
    });
  };
}

export function multiSelectTo(shotId) {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_MULTI_SELECT_TO",
      meta: { shotId }
    });
  };
}

export function setDraggingShotId(shotId) {
  return (dispatch) => {
    dispatch({
      type: "SHOT_LIST_SCHEDULE_SET_DRAGGING_SHOTID",
      meta: { shotId }
    });
  };
}

export function downloadShotList(id, shootingDayId, includeImages, includeEquipment) {
  return (dispatch) => {
    dispatch({
      type: "DOWNLOAD_SHOT_LIST",
      payload: getApiClient().post(`/film/${id}/shotList/${shootingDayId}/download`, { includeImages, includeEquipment }),
    });
  };
}

export function emailShotList(id, shootingDayId, includeImages, includeEquipment) {
  return (dispatch) => {
    dispatch({
      type: "EMAIL_SHOT_LIST",
      payload: getApiClient().post(`/film/${id}/shotList/${shootingDayId}/ToSelf`, { includeImages, includeEquipment })
    }).then((response) => {
      dispatch(showInfo(`Emailed shot list`));
    })
      .catch((response) => {
        dispatch(showError(`Error emailing shot list`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });;
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SHOT_LIST_PDF",
      meta: {},
    });
  };
}

export function clearDownload() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SHOT_LIST_DOWNLOAD"
    });
  };
}

export function includeImagesChanged(includeImages) {
  return (dispatch) => {
    dispatch({
      type: SHOT_LIST_INCLUDE_IMAGES,
      meta: { includeImages }
    });
  };
}


export function includeEquipmentChanged(includeEquipment) {
  return (dispatch) => {
    dispatch({
      type: SHOT_LIST_INCLUDE_EQUIPMENT,
      meta: { includeEquipment }
    });
  };
}