import { useTranslation } from 'react-i18next';
import SmallLoader from "../../../shared/small-loader/small-loader";
import Map from "../../../shared/Map/Map";
import { useEffect } from 'react';
import ShootingDayWeatherForm from '../../shooting-days/weather/edit-form';
import WarningText from '../../../shared/warning-text';

const ConfirmCallSheet = (props) => {
    const { user, loading, languageCode } = props;
    const { t, i18n } = useTranslation(['confirm']);


    useEffect(() => {
        if (languageCode) {
            i18n.changeLanguage(languageCode);
        }
    }, [languageCode, i18n]);

    useEffect(() => {
        if (!languageCode && !loading && user) {
            i18n.changeLanguage(user.filmLanguageCode);
        }
    }, [loading, user, i18n]);

    const locationMarkers: any[] = (user?.locations || []).map((location) => {
        return {
            number: location.number,
            position: {
                lat: location.address.latitude,
                lng: location.address.longitude
            }
        };
    });

    let parkingMarkers: any[] = [];
    user?.locations?.forEach((location) => {
        if (location.parkingAddress) {
            parkingMarkers.push({
                position: {
                    lat: location.parkingAddress.latitude,
                    lng: location.parkingAddress.longitude
                }
            });
        }
    });

    return (
        <div>
            <h2 className="menu-label">{t("Confirm Call Sheet")}</h2>
            <div className="mb-1">
                {t("Thank you for confirming receipt of your call sheet")}
            </div>
            {loading && <span>{t("Confirming")}... <SmallLoader /></span>}
            {!loading &&
                <>
                    {user.cancelled && <WarningText className='mb-1'><strong>This shooting day has now been cancelled</strong></WarningText>}
                    <label className="label">{user.filmName}</label>
                    <table className="table">
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th className="has-text-weight-bold">{t("Status")}</th>
                            <td>{t("Confirmed")}</td>
                        </tr>

                        <>
                            <tr>
                                <th className="has-text-weight-bold">{t("Name")}</th>
                                <td>{user.name}</td>
                            </tr>
                            <tr>
                                <th className="has-text-weight-bold">{t("Date")}</th>
                                <td>{user.date}</td>
                            </tr>
                            <tr>
                                <th className="has-text-weight-bold">{t("Day")}</th>
                                <td>{t("Day of days", { day: user.day, totalDays: user.totalDays })}</td>
                            </tr>
                            <tr>
                                <th className="has-text-weight-bold">{t("Call time")}</th>
                                <td>{user.callTime}</td>
                            </tr>
                        </>
                    </table>
                </>}
            {!loading && !user.cancelled &&
                <div className="call-sheet-confirm">
                    <label className="label mt-1">{t("Locations")}</label>

                    <table className="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>{t("No")}</th>
                                <th>{t("Address")}</th>
                                <th>{t("Parking")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(user.locations || []).map((location, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{location.number}</td>
                                        <td>
                                            <p>{location.name}</p>
                                            {location.address.addressLines.map((line, lineIdx) => {
                                                return <p key={lineIdx}>{line}</p>;
                                            })}
                                            <p>{location.address.postCode}</p>
                                            {location.address.whatThreeWords && (
                                                <p>/// {location.address.whatThreeWords}</p>
                                            )}
                                            {location.address.latitude != null && location.address.longitude != null && <div><a className="button mt-1" target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${location.address.latitude},${location.address.longitude}`}>{t("Directions")}</a> </div>}
                                        </td>
                                        <td>
                                            <p>{location.parkingDetails}</p>
                                            {location.parkingAddress &&
                                                location.parkingAddress.addressLines.map(
                                                    (line, lineIdx) => {
                                                        return <p key={lineIdx}>{line}</p>;
                                                    }
                                                )}
                                            <p>
                                                {location.parkingAddress &&
                                                    location.parkingAddress.postCode}
                                            </p>
                                            {location.parkingAddress &&
                                                location.parkingAddress.whatThreeWords && (
                                                    <p>/// {location.parkingAddress.whatThreeWords}</p>
                                                )}
                                            {location.parkingAddress?.latitude != null && location.parkingAddress?.longitude != null && <div><a className="button mt-1" target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${location.parkingAddress.latitude},${location.parkingAddress.longitude}`}>{t("Directions")}</a> </div>}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <Map
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        locationMarkers={locationMarkers}
                        hospitalMarkers={[]}
                        parkingMarkers={parkingMarkers}
                        setSelectedHospital={() => { }}
                        setSelectedCarPark={() => { }}
                        width={620}
                        height={450}></Map>

                    <label className="label mt-1">{t("Weather")}</label>
                    <ShootingDayWeatherForm weather={user.weather} />
                </div>}

        </div>
    )
};

export default ConfirmCallSheet;