import { FETCH_CHARACTER_PROPS } from "./actions";

interface CharacterPropsState {
  characterProps: any[];
  loading: boolean;
  readOnly: boolean;
}

const defaultState: CharacterPropsState = {
  characterProps: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_CHARACTER_PROPS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_CHARACTER_PROPS + "_FULFILLED": {
      const props = action.payload.data.props;
      return {
        ...state,
        characterProps: props,
        loading: false
      };
    }

    case FETCH_CHARACTER_PROPS + "_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;