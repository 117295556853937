import React from "react";
import NumberFormat from "react-number-format";
import CurrencyDisplay from "../../shared/currency-display";
import HelpText from "../../shared/help-text";
import SetDressing from "./set-dressing";

function SetDressings(setDressings) {
  const {
    budget,
    onChangeSetDressingBuget,
    onChangeSetDressingDays,
    showHideSetDressings,
    setDressingsVisible,
    readOnly,
    filmId,
    saving
  } = setDressings;

  return (
    <div>
      <div className="columns subtitle is-4 mt-1">
        <div className="column">
          <h4
            className="subtitle is-4 pointer"
            onClick={() => showHideSetDressings(!setDressingsVisible)}>
            <button className="button is-text is-small">
              {!setDressingsVisible && <i className="material-icons">add</i>}
              {setDressingsVisible && <i className="material-icons">remove</i>}
            </button>
            Set Dressings
          </h4>
        </div>
        <div className="column budget-header-number">
          {!setDressingsVisible && (
            <CurrencyDisplay
              value={budget.setDressingsTotal}
            />
          )}
        </div>
      </div>
      {setDressingsVisible && (
        <>
          <HelpText>
            A dash - in days means it is a set dressing you are buying or
            making, so does not depend on the number of days.
          </HelpText>

          <table className="table budget-table">
            <thead>
              <tr>
                <th>Name</th>
                <th className="budget-days-column">Days</th>
                <th className="budget-daily-rate-column">Budget</th>
                <th>Quantity</th>
                <th className="has-text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {(budget?.setDressings || []).map((setDressing, i) => {
                return (
                  <SetDressing
                    key={i}
                    setDressing={setDressing}
                    onChangeSetDressingBuget={onChangeSetDressingBuget}
                    onChangeSetDressingDays={onChangeSetDressingDays}
                    maxDays={budget.days}
                    totalScenes={budget.totalScenes}
                    hasShootingDays={budget.hasShootingDays}
                    readOnly={readOnly}
                    filmId={filmId}
                    saving={saving}
                  />
                );
              })}
              <tr>
                <td colSpan={4}></td>
                <td className="has-text-right">
                  <CurrencyDisplay
                    value={budget.setDressingsTotal}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default SetDressings;
