import { toast } from "react-toastify";

export function renderNotification(notification) {
  if (!notification) {
    return null;
  } else if (notification.type === "info") {
    toast.success(notification.message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  } else if (notification.type === "error") {
    toast.error(notification.message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  } else if (notification.type === "warning") {
    toast.warn(notification.message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
}
