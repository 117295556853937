import { ADMIN_FETCH_USER_FILMS } from "./actions";

interface UsersState {
  loading: boolean;
  films: any[];
}

const defaultState: UsersState = {
  loading: false,
  films: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case ADMIN_FETCH_USER_FILMS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case ADMIN_FETCH_USER_FILMS + "_FULFILLED": {
      return {
        ...state,
        films: action.payload.data.films,
        loading: false
      };
    }

    case ADMIN_FETCH_USER_FILMS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
