import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  addVisualEffect,
  clearVisualEffect,
  clearRedirect,
  uploadImagesAndSaveProp,
  setName
} from "./ducks/actions";
import Form from "./form";

function AddProp(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const { addVisualEffect, visualEffect, errors, uploadImagesAndSaveProp, setName, name, embedded, clearVisualEffect, sceneId } = props;

  useEffect(() => {
    clearVisualEffect();
  }, []);

  useEffect(() => {
    if (name) {
      setName(name);
    }
  }, [name]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/visual-effects/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "scenes") {
      props.clearRedirect();
      const url = `/films/${filmId}/visual-effects/${props.redirect.id}/scenes/`;
      return <Redirect to={url} />;
    }
  }

  function onAddVisualEffect(prop) {
    prop.sceneId = sceneId;
    const files = prop.pictures || [];
    if (files && files.length > 0) {
      uploadImagesAndSaveProp(prop, files, true, filmId);
    } else {
      if (!prop.imageUrls) {
        prop.imageUrls = [];
      }
      addVisualEffect(filmId, prop);
    }
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/visual-effects/add"
      loading={false}>
      <Form
        errors={errors}
        propId={null}
        initialValues={visualEffect}
        loading={false}
        onSubmit={onAddVisualEffect}
        {...props}
        mode="add"
        reduxFormValues={props.reduxFormValues}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { visualEffectsState, commonState } = state;

  return {
    visualEffect: visualEffectsState.visualEffect,
    errors: visualEffectsState.errors,
    redirect: visualEffectsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod
  };
}

export default connect(mapStateToProps, {
  addVisualEffect,
  clearVisualEffect,
  clearRedirect,
  uploadImagesAndSaveProp,
  setName
})(AddProp);
