import React from "react";

const TextAreaInput = (props) => {
  const { label, required, value, onChange, readOnly } = props;
  const onOnChange = (e) => {
    onChange(e.target.value);
  }
  return (
    <div className="field">
      <label className="label">
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="control">
        <textarea
          className="textarea"        
          placeholder={label}
          value={value}
          onChange={onOnChange}
          disabled={readOnly}          
        />
      </div>
    </div>
  );
};

export default TextAreaInput;
