import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { addFilm, clearRedirect, fetchFilmDefaults, getCountries } from "./ducks/actions";
import { getUnions } from "../../app/ducks/actions";
import FilmForm from "./form";
import Errors from "../../shared/errors";
import Loader from "../../shared/loader";
import BoxWraper from "../../account/box-wrapper";

const AddFilmContainer = (props) => {
  const { addFilm,
    fetchFilmDefaults,
    film,
    defaults,
    loading,
    errors,
    currencies,
    userCurrencyId,
    languages,
    getCountries,
    countries,
    defaultCountryId,
    userLanguageId,
    loadingOnBoarding,
    getUnions,
    actorUnions,
    crewUnions } = props;

  const filmId = props.match.params.id;

  const [saving, setSaving] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [imageFileName, setImageFileName] = useState<any>();

  useEffect(() => {
    getUnions(filmId);
    fetchFilmDefaults();
    getCountries();
  }, []);

  useEffect(() => {
    setSaving(loading);
  }, [loading]);

  function onAddFilm(film) {
    film.budget = film.budget ? +film.budget : null;
    film.length = film.length ? +film.length : null;
    film.frameRate = film.frameRate ? +film.frameRate : null;
    film.filmType = +film.filmType;
    film.status = +film.status;
    film.automaticallySendCallSheets = film.automaticallySendCallSheets ?? false;
    film.hoursBeforeToSendCallSheet = +film.hoursBeforeToSendCallSheet;

    if (!film.aspectRatioId) {
      film.aspectRatioId = null;
    }

    setSaving(true);
    addFilm(film, image);
  }

  if (props.redirect && film != null) {
    props.clearRedirect();
    const url = `/films/${film.id}/edit`;
    return <Redirect to={url} />;
  }

  function renderFilm() {
    return (
      <BoxWraper width={5}>
        <h2 className="menu-label">Add Film</h2>
        {errors && <Errors errors={errors} />}
        <FilmForm onSubmit={onAddFilm}
          currencies={currencies}
          userCurrencyId={userCurrencyId}
          {...props}
          initialValues={{ currencyId: userCurrencyId, languageId: userLanguageId, countryId: defaultCountryId, filmType: 1, status: 1 }}
          languages={languages}
          countries={countries}
          actorUnions={actorUnions}
          crewUnions={crewUnions}
          setImage={setImage}
          imageFileName={imageFileName}
          canAddProductionCompanyLogo={defaults?.canAddProductionCompanyLogo}
          canChangeFilmType={true} />
      </BoxWraper>
    );
  }

  function areLookupsReady() {
    return (
      props.aspectRatios?.length &&
      props.filmTypes?.length &&
      props.filmStatuses?.length &&
      props.genreTypes?.length &&
      props.frameRates?.length &&
      props.resolutions?.length
    );
  }

  if (!areLookupsReady() || loadingOnBoarding) {
    return <Loader />;
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && renderFilm()}
    </>
  );
};

function mapStateToProps(state) {
  const { filmsState, commonState } = state;
  return {
    errors: filmsState.errors,
    loading: filmsState.loading,
    film: filmsState.film,
    defaults: filmsState.defaults,
    redirect: filmsState.redirect,
    initialValues: filmsState.film,
    aspectRatios: commonState.aspectRatios,
    filmTypes: commonState.filmTypeEnum,
    filmStatuses: commonState.filmStatusEnum,
    genreTypes: commonState.genreTypes,
    frameRates: commonState.frameRates,
    resolutions: commonState.resolutions,
    currencies: commonState.currencies,
    userCurrencyId: commonState.userCurrencyId,
    languages: commonState.languages,
    countries: filmsState.countries,
    defaultCountryId: filmsState.defaultCountryId,
    userLanguageId: commonState.userLanguageId,
    loadingOnBoarding: commonState.loadingOnBoarding,
    actorUnions: commonState.actorUnions,
    crewUnions: commonState.crewUnions
  };
}

export default connect(mapStateToProps, { addFilm, clearRedirect, getUnions, fetchFilmDefaults, getCountries })(
  AddFilmContainer,
);
