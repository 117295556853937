import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../shared/loader";
import confirm from "../../shared/modal/confirm";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  updateFaq,
  fetchFaq,
  deleteFaq,
  clearRedirect,
  getLanguages,
  translateFaq,
  addLanguage,
  removeLanguage,
  changeLanguage,
  changeLanguageQuestion,
  changeLanguageAnswer  
} from "./ducks/actions";
import Form from "./form";

function EditFaq(props) {  
  const { updateFaq,    
    errors,
    faq,
    fetchFaq,
    deleteFaq,
    loading,
    redirect,
    clearRedirect,    
    pageSections,
    getLanguages,
    languages,
    translateFaq,
    addLanguage,
    removeLanguage,
    changeLanguage,
    changeLanguageQuestion,
    changeLanguageAnswer,
    pristine
     } = props;

  const faqId = props.match.params.faqId;
  const section = props.match.params.section;

  useEffect(() => {
    fetchFaq(faqId, section);   
    getLanguages(); 
  }, []);

  if (redirect) {
    if (redirect.to === "list") {
      clearRedirect();
      const url = `/admin/faqs`;
      return <Redirect to={url} />;
    } 
  }

  const onUpdateFaq = (e) => {
    e.preventDefault();    
    updateFaq(faqId, faq, section);    
  };

  const onDeleteFaq = (faq) => {
    confirm(() => deleteFaq(faqId, faq, section), faq?.question);
  };

  const onTranlate = (languageCode) => {
    const englishLanguage = faq.languages.find(language => language.languageCode === "en");
    translateFaq(englishLanguage, languageCode);
  };

  const onAddLanguage = () => { 
    addLanguage();
  };

  const onRemoveLanguage = (languageId) => { 
    removeLanguage(languageId);
  };

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/admin/faqs/add"
      loading={false}>
      {loading && <Loader/> }
      {!loading && <Form
        errors={errors}     
        pristine={pristine}   
        faq={faq}
        pageSections={pageSections}
        loading={loading}
        handleSubmit={onUpdateFaq}
        handleDelete={onDeleteFaq}
        mode="edit"
        faqId={faqId}        
        languages={languages}
        reduxFormValues={props.reduxFormValues}       
        onTranlate={onTranlate} 
        addLanguage={onAddLanguage}
        removeLanguage={onRemoveLanguage}
        changeLanguage={changeLanguage}
        changeLanguageQuestion={changeLanguageQuestion}
        changeLanguageAnswer={changeLanguageAnswer}
      />}
    </ScrollIntoView>
  );
}

function mapStateToFaqs(state) {
  const { adminFaqState, commonState } = state;
  return {
    faq: adminFaqState.faq,
    pristine: adminFaqState.pristine,
    errors: adminFaqState.errors,
    redirect: adminFaqState.redirect,
    loading: adminFaqState.loading,
    languages: adminFaqState.languages,
    pageSections: state.commonState.pageSections,
  };
}

export default connect(mapStateToFaqs, {
  updateFaq,
  fetchFaq,
  deleteFaq,
  clearRedirect,
  getLanguages,
  translateFaq,
  addLanguage,
  removeLanguage,
  changeLanguage,
  changeLanguageQuestion,
  changeLanguageAnswer  
})(EditFaq);
