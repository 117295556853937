import React from "react";
import { Link } from "react-router-dom";
import OffScreen from "../icons/off-screen";
import NoDialog from "../icons/no-dialog";
import { ItemTypes } from "./constants";
import { useDrag } from "react-dnd";

const ScriptItem = ({ index, item, label, readOnly, url, itemType, canDrag, deleteItem }) => {

  const canDragActor = (monitor) => {
    return !readOnly && canDrag;
  };

  const [{ isDragging }, drag] = useDrag({
    item: { type: itemType, item },
    canDrag: (monitor) => canDragActor(monitor),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const onDelete = () => {
    deleteItem(item);
  };

  console.log("item", item);

  return (
    <li key={item.id} ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: (readOnly || !canDrag) ? "" : "grab"
      }}>
      <div className="breakdown-script-item-container">
        <div className="breakdown-script-item-drag">{(!readOnly && canDrag) && <i className="material-icons dark">drag_indicator</i>}</div>
        <div className="breakdown-item-name" title={item.name}>
          <Link to={{ pathname: `${url}${item.id}/edit`, state: { fromBreakdown: true } }}>{item.name}</Link>
          {label === "Characters" && item.onlyOffScene &&
            <OffScreen className="small-icon breakdown-scene" />
          }
          {label === "Characters" && !item.hasDialog &&
            <NoDialog className="small-icon breakdown-scene" />
          }
        </div>
        <div className="breakdown-script-item-delete">
          {deleteItem &&
            <a onClick={onDelete}><i className="material-icons">cancel</i></a>}
        </div>
      </div>
    </li>
  );
};

export default ScriptItem;
