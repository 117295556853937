import getApiClient, { getAnnonymousApiClient } from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export function resetPassword(user, emailAddress, token) {
  const payload = { ...user, emailAddress, token }
  return (dispatch) => {
    dispatch({
      type: "RESET_PASSWORD",
      payload: getAnnonymousApiClient().post(`/account/ResetPassword`, payload),
      meta: { user }
    }).then((response) => {      
      dispatch(showInfo(`Successfully sent email`));
    })
    .catch((response) => {
      dispatch(showError(`Error sending email`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}