import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";
import { connection } from "../../../../middleware/messageSignalRMiddleware";
import { HubConnectionState } from "@microsoft/signalr";

export const FETCH_PERSON_POTENTIAL_DATES = "FETCH_PERSON_POTENTIAL_DATES";
export const SEND_POTENTIAL_DATES = "SEND_POTENTIAL_DATES";
export const SEND_POTENTIAL_DATES_TO_PERSON = "SEND_POTENTIAL_DATES_TO_PERSON";
export const SEND_POTENTIAL_DATES_TO_SELF = "SEND_POTENTIAL_DATES_TO_PERSON";

export function fetchAvailability(filmId) {
  if (connection.state === HubConnectionState.Disconnected) {
    connection.start().then(() => connection.invoke('SubscribeToFilm', filmId));
  }
  return (dispatch) => {
    dispatch({
      type: FETCH_PERSON_POTENTIAL_DATES,
      payload: getApiClient().get(`/film/${filmId}/scheduling/PersonPotentialDates`),
    });
  };
}

export function sendPotentialDatesToPerson(filmId, personId, personType) {
  return (dispatch) => {
    dispatch({
      type: SEND_POTENTIAL_DATES_TO_PERSON,
      payload: getApiClient().put(
        `/film/${filmId}/Scheduling/SendPotentialDatesToPerson`, { personId, personType }
      ),
      meta: { personId, personType }
    });
  };
}

export function sendPotentialDatesToSelf(filmId) {
  return (dispatch) => {
    dispatch({
      type: SEND_POTENTIAL_DATES_TO_SELF,
      payload: getApiClient().put(
        `/film/${filmId}/Scheduling/SendPotentialDatesToPerson`, { sendToSelf: true }
      ),
      meta: { sendToSelf: true }
    });
  };
}


export function sendPotentialDates(filmId) {
  return (dispatch) => {
    dispatch({
      type: SEND_POTENTIAL_DATES,
      payload: getApiClient().post(`/film/${filmId}/scheduling/send`, {}),
    });
  };
}

