import getApiClient from "./../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../notifications/ducks/actions";
import auth from "../../../shared/auth";
import invitations from "../../../shared/invitations";


export function invitation(data, referralCode) {
  if (auth.isAuthenticated()) {
    return (dispatch) => {
      dispatch({
        type: "USER_INVITATION",
        payload: getApiClient().put(`/invitation`, { data }),
      }).then((response) => {
        dispatch(showInfo(`Added film`));
      })
        .catch((response) => {
          dispatch(showError(`Error adding film`));
        })
        .then((response) => {
          dispatch(clearNotifications());
        });;
    };
  } else {
    invitations.addInvitation(data);
    invitations.addReferralCode(referralCode);

    return (dispatch) => {
      dispatch({
        type: "USER_INVITATION_NOT_LOGGED_IN",
        meta: { data }
      });
    };
  }
}