import { Route } from "react-router-dom";
import ViewChangeLogContainer from "./view-container";
import ListChangeLogsContainer from "./list-container";

const ChangeLogRoutes = () => {
  return (
    <>      
      <Route
        exact
        path="/change-log/:changeLogId"
        component={ViewChangeLogContainer}
      />
      <Route
        exact
        path="/change-logs"
        component={ListChangeLogsContainer}
      />
    </>
  );
};

export default ChangeLogRoutes;
