import React from "react";

const Progress = (props) => {
  const {
    progress,
    className
  } = props;
  return (
    <>
      {progress && (
        <div>
          <div className="level progress-level">
            <div className="level-left">
              {progress?.progress &&
                <div className="level-item progess-percentage">
                  <p>{progress?.progress}%</p>
                </div>
              }
              <div className="level-item">
                <progress
                  className={`progress ${className} ` + (progress?.failed ? "is-danger" : progress?.progress === 100 ? "progress-complete" : "")}
                  value={progress?.progress}
                  max="100">
                  {progress?.progress}
                </progress>
              </div>
            </div>
          </div>
          <p>{progress?.message}</p>
        </div>
      )}
    </>
  );
};

export default Progress;