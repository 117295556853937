import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import { fetchShootingDayCatering, downloadShootingDayCatering, clearPdf } from "./ducks/actions";
import ShootingDayCateringForm from "./edit-form";

function ListShootingDayCateringContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const { fetchShootingDayCatering,
    downloadShootingDayCatering,
    loading,
    catering,
    errors,
    progress,
    download,
    clearPdf } = props;

  useEffect(() => {
    fetchShootingDayCatering(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function renderLoading() {
    return <Loader />;
  }

  const onDownloadShootingDayCatering = () => {
    downloadShootingDayCatering(filmId, shootingdayId);
  };

  function renderShootingDays(weather, filmId) {
    return (
      <div className="blade-content wider">
        <p className="menu-label">Catering</p>
        <ShootingDayCateringForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ catering }}
          loading={loading}
          downloadShootingDayCatering={onDownloadShootingDayCatering}
          progress={progress}
          clearPdf={clearPdf}
          download={download}
        />
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(catering, filmId);
}

function mapStateToProps(state) {
  return {
    shootingDay: state.shootingdaysState.rootState.shootingday,
    catering: state.shootingdaysState.cateringState.catering,
    loading: state.shootingdaysState.cateringState.loading,
    errors: state.shootingdaysState.cateringState.errors,
    download: state.shootingdaysState.cateringState.download,
    progress: state.shootingdaysState.cateringState.progress
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayCatering,
  downloadShootingDayCatering,
  clearPdf
})(ListShootingDayCateringContainer);
