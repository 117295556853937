import React from "react";
import { Route } from "react-router-dom";
import ListSceneSettingsContainer from "./list-container";
import EditSceneSetting from "./edit";
import AddSceneSetting from "./add";
import OverviewContainer from "./overview/container";
import { PrivateRoute } from "../../shared/private-route";

const SceneSettingsRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scene-settings"
        component={ListSceneSettingsContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scene-settings/add"
        component={AddSceneSetting}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scene-settings/overview"
        component={OverviewContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scene-settings/:sceneSettingId"
        component={EditSceneSetting}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scene-settings/:sceneSettingId/edit"
        component={EditSceneSetting}
      />
    </>
  );
};

export default SceneSettingsRoutes;
