import React, { useEffect } from "react";
import _ from 'lodash';
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  fetchCommercialScript,
  fetchCharacters,
  updateCommercialScript,
  addShotToScene,
  removeShotFromScene,
  addScriptFieldToShot,
  removeScriptFieldFromShot,
  sceneSettingChange,
  interiorExteriorChange,
  dayNightChange,
  setShotSize,
  setCameraMovements,
  setDuration,
  setDescription,
  setFieldText,
  setFieldCharacter
} from "./ducks/actions";
import Loader from "./../../shared/loader";
import { fetchSceneSettings } from "./../scene-settings/ducks/actions";
import './commercial-script.scss';
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName
} from "./ducks/reducer";
import Onboarding from '../../shared/onboarding/onboarding';
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import CommercialScriptScene from "./scene";
import { Link, Prompt } from "react-router-dom";
import Errors from "../../shared/errors";

interface AppProps { }

const Script: React.FC<AppProps> = (props: any) => {
  const {
    fetchCommercialScript,
    updateCommercialScript,
    fetchCharacters,
    fetchSceneSettings,
    addShotToScene, 
    removeShotFromScene,
    addScriptFieldToShot,    
    removeScriptFieldFromShot,
    sceneSettingChange,
    interiorExteriorChange,
    dayNightChange,
    setShotSize,
    setCameraMovements,
    characters,
    setDuration,
    setDescription,
    setFieldText,
    setFieldCharacter,
    loading,
    saving,
    scenes,
    totalDuration,
    readOnly,    
    scriptOnboardingSteps,
    shotSizeEnum,
    cameraMovementEnum,
    sceneSettings,
    interiorExteriorEnum,
    dayNightEnum,
    errors,
    pristine
  } = props;

  const filmId = props.match.params.filmId;

  useEffect(() => {
    fetchCommercialScript(filmId);
    fetchSceneSettings(filmId);
    fetchCharacters(filmId);
  },[filmId]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    borderRadius: "5px",
    //margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? "#222E44" : "#040F21",
    color: isDragging ? "white" : "white",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getDayStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    //margin: `0 0 ${grid}px 0`,
    borderRadius: "5px",
    // change background colour if dragging
    background: isDragging ? "#222E44" : "#947eed",
    color: isDragging ? "white" : "#182231",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type == "scene") {
     
     
    } else if (result.type == "day") {
     
    }
  };

  
  const deleteShootingDay = (dayNumber) => {

  };

  const addShot = (sceneId) => {
    addShotToScene(sceneId);
  };

  const onAddScriptField = (fieldType, sceneId, shotId) => {    
    addScriptFieldToShot(fieldType, sceneId, shotId);
  }

  const save = () => {    
    updateCommercialScript(filmId, scenes)
  };

  return (
    <>
      {/* onContextMenu={handleOnContextMenu} */}
      <ScrollIntoView
        className={`blade-content widest`}
        path="/films/:filmId/commercial-script"
        loading={loading}>
        
        {loading || saving && <Loader/>}
        <Prompt when={!pristine} message="" />
        <h2 className="menu-label">Commercial Script</h2>
        <Errors errors={errors} />
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="day">
          {(provided, snapshot) => (
            <table
              className={`table commercial-script no-last-border is-fullwidth ${OnboardingStep2ClassName}`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <thead>
                <tr>
                  <th className="number-column"></th>
                  <th>Script</th>
                  <th>Visual</th>
                  <th className="duration-column">Duration</th>
                </tr>
              </thead>

              {(scenes || []).map((scene, index) => (
                <Draggable
                  key={scene.number}
                  draggableId={scene.number + ""}
                  index={index}
                  isDragDisabled={readOnly}
                >
                  {(draggableProvided, draggableSapshot) => (
                    <Droppable droppableId={scene.number + ""} type="scene">
                      {(provided, snapshot) => (
                        
                        <CommercialScriptScene key={index} 
                                scene={scene}
                                sceneSettings={sceneSettings}
                                characters={characters}
                                interiorExteriorEnum={interiorExteriorEnum}
                                dayNightEnum ={dayNightEnum}
                                provided={provided}
                                draggableProvided={draggableProvided}
                                getDayStyle={getDayStyle}
                                draggableSapshot={draggableSapshot}
                                readOnly={readOnly}
                                getItemStyle={getItemStyle}
                                filmId={filmId}
                                shotSizeEnum={shotSizeEnum}
                                cameraMovementEnum={cameraMovementEnum}                                
                                addScriptField={onAddScriptField}
                                removeScriptField={removeScriptFieldFromShot}
                                addShot={addShot}
                                removeShot={removeShotFromScene}
                                sceneSettingChange={sceneSettingChange}
                                interiorExteriorChange={interiorExteriorChange}
                                dayNightChange={dayNightChange}
                                setShotSize={setShotSize}
                                setCameraMovements={setCameraMovements}
                                setDuration={setDuration}
                                setDescription={setDescription}
                                setFieldText={setFieldText}
                                setFieldCharacter={setFieldCharacter} />
                      )}
                    </Droppable>
                  )}
                </Draggable>
              ))}
              <tr>
                <td colSpan={3} className="commercial-script-total-duration">Total Duration</td>
                <td>{totalDuration}</td>
              </tr>
            </table>
          )}
        </Droppable>
      </DragDropContext>
      <Link to={`/films/${filmId}/scenes/add`} className="button">Add Scene</Link>
      <div>
        <button className={`button mt-1 ${saving ? "is-loading" : ""}`} onClick={save} disabled={pristine}>Save</button>
      </div>
      </ScrollIntoView>
      <Onboarding onboardingSteps={scriptOnboardingSteps} section="Script" />

    </>
  )
}


function mapStateToProps(state) {
  const commercialScriptState = state.commercialScriptState.rootState;
  const { commonState, sceneSettingsState } = state;
  return {
    scenes: commercialScriptState.scenes,    
    totalDuration: commercialScriptState.totalDuration,
    characters: commercialScriptState.characters,
    readOnly: commercialScriptState.readOnly,  
    errors: commercialScriptState.errors,
    loading: commercialScriptState.loading,    
    saving: commercialScriptState.saving,
    scriptOnboardingSteps: commercialScriptState.scriptOnboardingSteps,
    reloadScript: commercialScriptState.reloadScript,
    editMode: commercialScriptState.editMode,
    download: commercialScriptState.download,
    progress: commercialScriptState.progress,
    pristine: commercialScriptState.pristine,
    shotSizeEnum: commonState.shotSizeEnum,
    cameraMovementEnum: commonState.cameraMovementEnum,
    dayNightEnum: commonState.dayNightEnum,
    interiorExteriorEnum: commonState.interiorExteriorEnum,
    sceneSettings: sceneSettingsState.rootState.sceneSettings,
  };
}

export default connect(mapStateToProps, {
  fetchCommercialScript,
  fetchCharacters,
  updateCommercialScript,
  fetchSceneSettings,
  addShotToScene,
  removeShotFromScene,
  addScriptFieldToShot,
  removeScriptFieldFromShot,
  sceneSettingChange,
  interiorExteriorChange,
  dayNightChange,
  setShotSize,
  setCameraMovements,
  setDuration,
  setDescription,
  setFieldText,
  setFieldCharacter
})(Script);