const SmallProgress = (props) => {
  const {
    progress,
    showPercentage,
    failed,
    className
  } = props;

  const progressColor = failed ? "#f75a65" : progress == 100 ? "#00ffce" : "#947eed";

  return (
    <>
      {progress !== null && (
        <div className="level-item account-storage-progress-container" >
          <div className="account-storage-progress" style={{ background: `linear-gradient(to right, ${progressColor} ${progress}%, #162135 ${progress}%)`}}></div> 
          {showPercentage > 0 && <div className="level-item progess-percentage account-storage-progress-percentage"><p>{progress}% </p></div> }
        </div>
      )}
    </>
  );
};

export default SmallProgress;