import { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import AnonymousPage from "../../../account/anonymous-pages/anonymous-page";
import BoxWraper from "../../../account/box-wrapper";
import Errors from "../../../shared/errors";
import Loader from "../../../shared/loader";
import {
    fecthDietaryRequirements,
    fetchDietaryRequirementsEnum,
    updateDietaryRequirements,
    fetchDrinksAndMilkTypes
} from "./ducks/actions";
import Form from "./form";

const DietaryRequirementsContainer = (props) => {
    const languageCode = props.match.params.languageCode;
    const token = props.match.params.token;
    const { fecthDietaryRequirements,
        fetchDietaryRequirementsEnum,
        fetchDrinksAndMilkTypes,
        updateDietaryRequirements,
        loading,
        loadingEnums,
        saving,
        saved,
        dietaryRequirements,
        dietaryRequirementEnum,
        drinks,
        milkTypes,
        errors } = props;

    useEffect(() => {
        fetchDietaryRequirementsEnum();
        fetchDrinksAndMilkTypes();
        fecthDietaryRequirements(token);
    }, [token]);

    let personDietaryRequirements: any[] = [];
    (dietaryRequirementEnum || []).forEach((item) => {
        if ((item.value & dietaryRequirements.dietaryRequirements) === item.value) {
            personDietaryRequirements.push(item.value);
        }
    });

    dietaryRequirements.dietaryRequirementsArray = personDietaryRequirements;

    const onUpdateDietaryRequirements = (dietaryRequirements) => {
        let dietaryRequirement = 0;
        dietaryRequirements.dietaryRequirementsArray.forEach((item) => {
            dietaryRequirement = dietaryRequirement | item;
        });

        dietaryRequirements.dietaryRequirements = dietaryRequirement;
        updateDietaryRequirements(token, dietaryRequirements);
    };

    return (
        <AnonymousPage>
            <BoxWraper width={5}>
                <Suspense fallback={<Loader />}>
                    <Errors errors={errors} />
                    <Form
                        initialValues={dietaryRequirements}
                        dietaryRequirements={dietaryRequirements}
                        dietaryRequirementEnum={dietaryRequirementEnum}
                        drinks={drinks}
                        milkTypes={milkTypes}
                        onSubmit={onUpdateDietaryRequirements}
                        loading={loading}
                        loadingEnums={loadingEnums}
                        saving={saving}
                        saved={saved}
                        languageCode={languageCode}
                        showDrinks={dietaryRequirements.showDrinks} />
                </Suspense>
            </BoxWraper>
        </AnonymousPage>
    )
};

function mapStateToProps(state) {
    const { schedulingState } = state;
    const dieataryRequirementsState = schedulingState.dietaryRequirementsState;
    return {
        loading: dieataryRequirementsState.loading,
        loadingEnums: dieataryRequirementsState.loadingEnums,
        saving: dieataryRequirementsState.saving,
        saved: dieataryRequirementsState.saved,
        dietaryRequirements: dieataryRequirementsState.dietaryRequirements,
        errors: dieataryRequirementsState.errors,
        dietaryRequirementEnum: dieataryRequirementsState.dietaryRequirementEnum,
        drinks: dieataryRequirementsState.drinks,
        milkTypes: dieataryRequirementsState.milkTypes
    };
}

export default connect(mapStateToProps, {
    fecthDietaryRequirements,
    fetchDietaryRequirementsEnum,
    updateDietaryRequirements,
    fetchDrinksAndMilkTypes
})(DietaryRequirementsContainer);
