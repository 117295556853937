const defaultState = {
  days: [],
  loading: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SCENE_SHOOTING_DAYS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SCENE_SHOOTING_DAYS_FULFILLED": {
      return {
        ...state,
        shootingDays: action.payload.data.shootingDays,
        loading: false,
        errors: null
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        character: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
