import getApiClient from "../../../../shared/api";
import { showInfo, showError, clearNotifications } from "../../../../notifications/ducks/actions";

export const ADD_SHOOTING_DAY_ACTORS = "ADD_SHOOTING_DAY_ACTORS";

export function fetchShootingDayActors(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_ACTORS",
      payload: getApiClient().get(`/film/${filmId}/ShootingDay/${shootingDayId}/Actors`),
    });
  };
}

export function updateShootingDayActor(filmId, shootingDayId, actors) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SHOOTING_DAY_ACTORS",
      payload: getApiClient().put(`/film/${filmId}/ShootingDay/${shootingDayId}/Actors`, actors),
      meta: { actors },
    })
      .then((response) => {
        dispatch(showInfo(`Updated Shooting Day Actors`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day Actor`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addShootingDayActor(filmId, shootingDayId, actor) {
  return (dispatch) => {
    dispatch({
      type: ADD_SHOOTING_DAY_ACTORS,
      payload: getApiClient().post(`/film/${filmId}/ShootingDay/${shootingDayId}/Actors`, actor),
      meta: { actor },
    })
      .then((response) => {
        dispatch(showInfo(`Added Shooting Day Actor`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding Shooting Day Actor`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
