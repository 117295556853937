import { Droppable } from 'react-beautiful-dnd';
import Task from './task';

const Column = ({ column, 
                  index, 
                  tasks, 
                  addTask, 
                  taskTextChanged, 
                  taskTextFinishedEditing, 
                  editTask, 
                  deleteTask,
                  showMenuOnTaskId,
                  showMenuOnTask,
                  collaborators,
                  assignTask,
                  readOnly }) => { 

  const onAddTask = () => {
    addTask(column.id);
  };

  const onTaskTextFinishedEditing = (task) => {
    taskTextFinishedEditing(column.id, task);
  };

  const onDeleteTask = (task, index) => {
    deleteTask(column.id, task, index);
  };
     

    return (
      <div className='tasks-column'>
        <div className='menu-label'>{column.name}</div>
        {index === 0 && !readOnly && <button className='button mb-1' onClick={onAddTask}>Add Task</button>}
        <Droppable droppableId={column.id}>
            {provided => (
                <div className='task-list' ref={provided.innerRef} {...provided.droppableProps}>                    
                    {tasks.map((task, index) => 
                          <Task key={task.id} 
                                task={task} 
                                index={index} 
                                textChanged={taskTextChanged} 
                                textFinishedEditing={onTaskTextFinishedEditing}
                                editTask={editTask} 
                                deleteTask={(task) => onDeleteTask(task, index)}
                                showMenuOnTaskId={showMenuOnTaskId}
                                showMenuOnTask={showMenuOnTask}
                                collaborators={collaborators}
                                assignTask={assignTask}
                                readOnly={readOnly}
                            />)}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
      </div>
    );  
}
 
export default Column;