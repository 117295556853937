import getApiClient from "../../shared/api";

export const FETCH_CHANGE_LOG = "FETCH_CHANGE_LOG";
export const FETCH_CHANGE_LOGS = "FETCH_CHANGE_LOGS";

export function fetchChangeLog(id) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CHANGE_LOG ,
      payload: getApiClient().get(`/changeLog/${id}`)
    });
  };
}

export function fetchChangeLogs(continuationToken) {
  return (dispatch) => {

    let continuationTokenQuery = "";
    if (continuationToken != null) {
      continuationTokenQuery = `continuationToken=${continuationToken}`;
    }

    dispatch({
      type: FETCH_CHANGE_LOGS,
      payload: getApiClient().get(`/changeLog?${continuationTokenQuery}`),
      meta: { append: continuationToken != null}
    });
  };
}