import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import {
  renderSingleReactSelect
} from "./../../../shared/form-helpers";
import required from "./../../../shared/validation";

function SetDressingForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    setDressings
  } = props;

  const thisSetDressing = props.initialValues || {};
  const readOnly = thisSetDressing.readOnly;

  const setDressingOptions = setDressings?.map((e) => {
    return { label: e.name, value: e.id };
  });

  const noSetDressings = !setDressingOptions?.length;

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add set dressing to scene</h2>}
      {mode === "edit" && (
        <h2 className="menu-label">Edit set dressing in scene</h2>
      )}
      {errors && <Errors errors={errors} />}
      {noSetDressings && <div>All set dressings already added to scene</div>}

      {!noSetDressings &&
        <form autoComplete="off" onSubmit={handleSubmit}>
          {mode === "add" && (
            <Field
              name="setDressingId"
              label="Set Dressing"
              component={renderSingleReactSelect}
              value={thisSetDressing.setDressingId}
              validate={[required]}
              options={setDressingOptions}
              required
              readOnly={readOnly}>
            </Field>
          )}
          {mode === "edit" && (
            <div className="field">
              <label className="label">
                Set Dressings
              </label>
              <div className="control">
                {thisSetDressing.name}
              </div>
            </div>
          )}

          {!readOnly && <div className="buttons">
            <button
              type="submit"
              className="button is-primary"
              disabled={pristine || submitting}>
              Save
            </button>

            {mode === "edit" && (
              <button
                type="button"
                className="button is-text"
                disabled={submitting || !props.initialValues}
                onClick={() => handleDelete(thisSetDressing)}>
                Remove from scene
              </button>
            )}
          </div>}
        </form>}
    </>
  );
}

export default reduxForm({
  form: "SetDressingForm",
  enableReinitialize: true
})(SetDressingForm);
