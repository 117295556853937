import { combineReducers } from "redux";
import { v4 as uuidv4 } from 'uuid';
import downloadScriptReducer from '../download-script/ducks/reducer';

import { DOWNLOAD_SCRIPT } from "../../../middleware/signalRMiddleware";
import {
  CLEAR_DOWNLOAD_SCRIPT,
  COMMERCIAL_SCRIPT_ADD_SHOT_TO_SCENE,
  COMMERCIAL_SCRIPT_ADD_FEILD_TO_SHOT,
  FETCH_COMMERCIAL_SCRIPT,
  FETCH_ALL_CHARACTERS_FOR_COMMERCIAL_SCRIPT,
  QUEUE_DOWNLOAD_SCRIPT,
  RESET_DOWNLOAD_SCRIPT,
  SCRIPT_EDIT_MODE,
  UPDATE_COMMERCIAL_SCRIPT,
  COMMERCIAL_SCRIPT_CHANGE_SCENE_SETTING,
  COMMERCIAL_SCRIPT_CHANGE_INTERIOR_EXTERIOR,
  COMMERCIAL_SCRIPT_CHANGE_DAY_NIGHT,
  COMMERCIAL_SCRIPT_CHANGE_SHOT_SIZE,
  COMMERCIAL_SCRIPT_CHANGE_CAMERA_MOVEMENTS,
  COMMERCIAL_SCRIPT_CHANGE_DURATION,
  COMMERCIAL_SCRIPT_CHANGE_DESCRIPTION,
  COMMERCIAL_SCRIPT_CHANGE_FIELD_TEXT,
  COMMERCIAL_SCRIPT_REMOVE_FEILD_FROM_SHOT,
  COMMERCIAL_SCRIPT_CHANGE_FIELD_CHARACTER,
  COMMERCIAL_SCRIPT_REMOVE_SHOT_FROM_SCENE
} from "./actions";
import { formatTimeFromSeconds } from "../../../shared/format-time";

interface ScenesState {
  scenes: any[];
  totalDuration: string;
  script: any[];
  characters: any[];
  editedScript: any[];
  scene: any;
  loading: boolean;
  saving: boolean;
  readOnly: boolean;
  selectedItem: any;
  selectScenes: any;
  characterNames: any[];
  propNames: any[];
  backgroundCharacterNames: any[];
  costumeNames: any[];
  makeupNames: any[];
  setDressingNames: any[];
  propId: any;
  setDressingId: any
  makeupId: any;
  costumeId: any;
  itemId: any;
  mouseDown: boolean;
  reloadScript: boolean;
  scriptOnboardingSteps: any[];
  editMode: boolean;
  download: any;
  progress: any;
  pristine: boolean;
}

export const OnboardingStep1ClassName = 'script-onboarding-step-1';
export const OnboardingStep2ClassName = 'script-onboarding-step-2';
export const OnboardingStep3ClassName = 'script-onboarding-step-3';
export const OnboardingStep4ClassName = 'script-onboarding-step-4';

const defaultState: ScenesState = {
  scenes: [],
  totalDuration: "00:00",
  script: [],
  characters: [],
  editedScript: [],
  scene: {},
  loading: false,
  saving: false,
  readOnly: false,
  selectedItem: null,
  selectScenes: null,
  characterNames: [],
  propNames: [],
  backgroundCharacterNames: [],
  costumeNames: [],
  makeupNames: [],
  setDressingNames: [],
  propId: null,
  setDressingId: null,
  makeupId: null,
  costumeId: null,
  itemId: null,
  mouseDown: false,
  reloadScript: false,
  editMode: false,
  download: null,
  progress: null,
  pristine: true,
  scriptOnboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scenes',
      content: `Click on a scene to jump to it within the script.`,
      placement: 'right-start',
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Script Breakdown',
      content: `<p>Highlight text in the script that you want to breakdown. A pop-up menu will appear allowing you to flag the highlighted text as costume, make up, prop, set dressing, character or background character.</p>
                <p>You can also click on an existing item to edit it's details.</p>`
    }
    ,
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Edit Script',
      content: `<p>Select this option to make minor amends to the script. In this mode you will no longer be able to highlight words to breakdown.</p>
                <p>Use with caution as it is very experimental.</p>`
    }
  ]
};

const calculateTotalDurations = (scenes) => {
  let totalSeconds = 0;
  scenes.forEach(scene => {
    let totalSceneSeconds = 0;
    scene.shots.forEach(shot => {
      if (shot.duration) {
        var splitDuration = shot.duration.split(":");
        if (splitDuration.length > 1) {
          const minutes = +splitDuration[0];
          const seconds = +splitDuration[1];
          totalSceneSeconds += minutes * 60;
          totalSceneSeconds += seconds;
        }
      }
    });

    scene.totalDuration = formatTimeFromSeconds(totalSceneSeconds);
    totalSeconds += totalSceneSeconds;
  });

  return formatTimeFromSeconds(totalSeconds);
};


const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_COMMERCIAL_SCRIPT + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_COMMERCIAL_SCRIPT + "_FULFILLED": {
      const scenes = action.payload.data.scenes;
      const totalDuration = calculateTotalDurations(scenes);
      return {
        ...state,
        scenes: scenes,
        totalDuration: totalDuration,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case FETCH_COMMERCIAL_SCRIPT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FETCH_ALL_CHARACTERS_FOR_COMMERCIAL_SCRIPT + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_ALL_CHARACTERS_FOR_COMMERCIAL_SCRIPT + "_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        loading: false,
        errors: null
      };
    }

    case FETCH_ALL_CHARACTERS_FOR_COMMERCIAL_SCRIPT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_SCENE_SETTING: {
      const sceneId = action.meta.sceneId;
      const sceneSettingId = action.meta.sceneSettingId;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      scene.sceneSettingId = sceneSettingId;

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_INTERIOR_EXTERIOR: {
      const sceneId = action.meta.sceneId;
      const interiorExterior = action.meta.interiorExterior;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      scene.interiorExterior = interiorExterior;

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_DAY_NIGHT: {
      const sceneId = action.meta.sceneId;
      const dayNight = action.meta.dayNight;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      scene.dayNight = dayNight;

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_ADD_SHOT_TO_SCENE: {
      const sceneId = action.meta.sceneId;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      if (!scene.shots) {
        scene.shots = [];
      }

      scene.shots.push({ id: uuidv4(), number: scene.shots.length + 1, fields: [] });

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_REMOVE_SHOT_FROM_SCENE: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);

      const shotIndexToDelete = scene.shots.findIndex(s => s.id === shotId);
      scene.shots.splice(shotIndexToDelete, 1);

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_ADD_FEILD_TO_SHOT: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const fieldType = action.meta.fieldType;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      const shot = scene.shots.find(s => s.id === shotId);

      shot.fields.push({ id: uuidv4(), type: fieldType });

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_REMOVE_FEILD_FROM_SHOT: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const fieldId = action.meta.fieldId;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      const shot = scene.shots.find(s => s.id === shotId);
      const fieldIndex = shot.fields.findIndex(f => f.id === fieldId);
      shot.fields.splice(fieldIndex, 1);

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_SHOT_SIZE: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const shotSize = action.meta.shotSize;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      const shot = scene.shots.find(s => s.id === shotId);
      shot.shotSize = shotSize;

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_CAMERA_MOVEMENTS: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const cameraMovements = action.meta.cameraMovements;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      const shot = scene.shots.find(s => s.id === shotId);
      shot.cameraMovements = cameraMovements;

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_DURATION: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const duration = action.meta.duration;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      const shot = scene.shots.find(s => s.id === shotId);
      shot.duration = duration;
      const totalDuration = calculateTotalDurations(scenes);
      return {
        ...state,
        scenes,
        totalDuration,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_DESCRIPTION: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const description = action.meta.description;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      const shot = scene.shots.find(s => s.id === shotId);
      shot.description = description;

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_FIELD_TEXT: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const fieldId = action.meta.fieldId;
      const text = action.meta.text;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      const shot = scene.shots.find(s => s.id === shotId);
      const field = shot.fields.find(f => f.id === fieldId);
      field.text = text;

      return {
        ...state,
        scenes,
        pristine: false
      };
    }

    case COMMERCIAL_SCRIPT_CHANGE_FIELD_CHARACTER: {
      const sceneId = action.meta.sceneId;
      const shotId = action.meta.shotId;
      const fieldId = action.meta.fieldId;
      const characterId = action.meta.characterId;
      const scenes = Array.from(state.scenes);
      const scene = scenes.find(s => s.id === sceneId);
      const shot = scene.shots.find(s => s.id === shotId);
      const field = shot.fields.find(f => f.id === fieldId);
      field.characterId = characterId;

      return {
        ...state,
        scenes,
        pristine: false
      };
    }


    case UPDATE_COMMERCIAL_SCRIPT + "_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case UPDATE_COMMERCIAL_SCRIPT + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        errors: null,
        editMode: false,
        pristine: true
      };
    }

    case UPDATE_COMMERCIAL_SCRIPT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        saving: false
      };
    }

    default:
      return state;
  }
};


export default combineReducers({
  rootState: reducer,
  downloadScriptState: downloadScriptReducer
});
