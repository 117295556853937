import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "./../../../shared/loader";
import Onboarding from '../../../shared/onboarding/onboarding';
import {
  fetchSetDressing,
  assignScenesToSetDressing,
  clearRedirect,
  fetchAllScenes,
  fetchAllSceneSettings,
  fetchScenesAssignedToSetDressing,
  findInScript,
  clearSceneSearchResults,
  setSceneAssignedToSetDressing
} from "./ducks/actions";
import Form from "./scenes-form";
import {
  searchScenes,
  SEARCH_SCENES_FOR_SET_DRESSING
} from "../../scenes/search/ducks/actions";
import SearchScenesForm from "../../scenes/search/search-scenes-form";
import ScriptSearch from "../../script/search/script-search";
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from './ducks/reducer';

function SetDressingScenes(props) {
  const setDressingId = props.setDressingId || props.match.params.setDressingId;
  const filmId = props.filmId || props.match.params.filmId;
  const {
    fetchAllScenes,
    setDressing,
    fetchSetDressing,
    fetchScenesAssignedToSetDressing,
    assignScenesToSetDressing,
    setSceneAssignedToSetDressing,
    loadingSetDressing,
    loadingScenes,
    allScenes,
    searchScenes,
    findInScript,
    clearSceneSearchResults,
    errors,
    readOnly,
    sceneId,
    embedded,
    onboardingSteps,
    loadingSceneSettings,
    findingInScript,
    searchingScenes
  } = props;

  const invalidIds = ["add", "scenes"];

  // const allSelectedScenes = (props.selectedSceneIds || []).concat(
  //   props.searchedScenes?.map((s) => s.id)
  // );

  useEffect(() => {
    fetchSetDressing(filmId, setDressingId);
    if (!sceneId) {
      fetchScenesAssignedToSetDressing(filmId, setDressingId);
    }
  }, [filmId, setDressingId]);

  useEffect(() => {
    if (setDressing?.sceneSettingId) {
      fetchAllScenes(filmId, setDressing.sceneSettingId);
    }
  }, [filmId, setDressingId, setDressing]);

  useEffect(() => {
    if (sceneId) {
      setSceneAssignedToSetDressing(sceneId);
    }
  }, [sceneId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/set-dressings/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(setDressingId) > -1) {
    return null;
  }

  const onFindInScript = (e) => {
    e.preventDefault();
    findInScript(filmId, setDressing.name);
  };

  function renderLoading() {
    return <Loader />;
  }

  function renderForm() {
    return (
      <div className="blade-content">
        <h2 className="menu-label">Set Dressing Scenes</h2>
        {!readOnly && <div className={`box mt-1 ${OnboardingStep1ClassName}`}>
          <SearchScenesForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            setDressingId={setDressingId}
            loading={loadingSetDressing}
            onSubmit={onSearchScenes}
            findInScript={onFindInScript}
            allCharacters={props.allCharacters}
            allSceneSettings={props.allSceneSettings}
            setDressingName={setDressing?.name}
            searchingScenes={searchingScenes}
            scenes={allScenes}
            showCharacters={false}
            showScriptDays={false}
            showSceneSettings={false}
          />
        </div>}
        {!readOnly && <div className={`box mt-1 ${OnboardingStep2ClassName}`}>
          <ScriptSearch filmId={filmId} text={setDressing?.name} typeToDispatch="SET_DRESSING_SEARCH_SCRIPT" findingInScript={findingInScript} />
        </div>}
        {/* {loadingSceneSettings && <div>loadingSceneSettings</div>}
        {loadingSetDressing && <div>loadingSetDressing</div>}
        {loadingScenes && <div>loadingScenes</div>} */}
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          setDressingId={setDressingId}
          initialValues={{ selectedSceneIds: props.selectedSceneIds }}
          loading={loadingSceneSettings || loadingSetDressing || loadingScenes}
          onSubmit={onUpdateSetDressingScenes}
          reduxFormValues={props.reduxFormValues}
          allScenes={allScenes}
          searchedSceneIds={props.searchedScenes?.map((s) => s.id)}
          selectSearchedScenes={props.selectSearchedScenes}
          clearSceneSearchResults={clearSceneSearchResults}
          readOnly={readOnly}
        />
        <Onboarding onboardingSteps={onboardingSteps} section="Set Dressing Scenes" />
      </div>
    );
  }

  function onSearchScenes(params) {
    params.sceneSettingId = setDressing.sceneSettingId;
    searchScenes(
      SEARCH_SCENES_FOR_SET_DRESSING,
      filmId,
      params
    );
  }

  function onUpdateSetDressingScenes(propScenes) {
    assignScenesToSetDressing(filmId, setDressingId, propScenes.selectedSceneIds);
  }

  return allScenes ? renderForm() : renderLoading();
}

function mapStateToSetDressings(state) {
  const { setDressingsState, form } = state;
  return {
    setDressing: setDressingsState.setDressing,
    allScenes: setDressingsState.scenes,
    allCharacters: setDressingsState.characters,
    allSceneSettings: setDressingsState.sceneSettings,
    selectedSceneIds: setDressingsState.selectedSceneIds,
    loadingSceneSettings: setDressingsState.loadingSceneSettings,
    loadingScenes: setDressingsState.loadingScenes,
    readOnly: setDressingsState.readOnly,
    searchedScenes: setDressingsState.searchedScenes,
    selectSearchedScenes: setDressingsState.selectSearchedScenes,
    findingInScript: setDressingsState.findingInScript,
    searchingScenes: setDressingsState.searchingScenes,
    errors: setDressingsState.errors,
    redirect: setDressingsState.redirect,
    onboardingSteps: setDressingsState.onboardingSteps,
    reduxFormValues: form?.setDressingScenesForm?.values
  };
}

export default
  connect(mapStateToSetDressings, {
    fetchSetDressing,
    fetchAllScenes,
    fetchScenesAssignedToSetDressing,
    setSceneAssignedToSetDressing,
    clearRedirect,
    assignScenesToSetDressing,
    searchScenes,
    findInScript,
    clearSceneSearchResults
  })(SetDressingScenes);
