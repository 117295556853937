import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Loader from "./../../shared/loader";
import { fetchLocations } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchLocations, loading, locations, errors, readOnly } = props;

  useEffect(() => {
    fetchLocations(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderList(locations, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/locations"
        loading={loading}>
        <List locations={locations} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/locations/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Location
        </NavLink>}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderList(locations, filmId);
}

function mapStateToProps(state) {
  const locationsState = state.locationsState.rootState;
  return {
    locations: locationsState.locations,
    readOnly: locationsState.readOnly,
    loading: locationsState.loading,
    errors: locationsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchLocations
})(ListContainer);
