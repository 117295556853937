import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as QueryString from "query-string"
import BoxWraper from "../../../account/box-wrapper";
import Errors from "../../../shared/errors";
import FilmaizeSelect from "../../../shared/filmanize-select";
import Loader from "../../../shared/loader";
import {
  searchFeatureRequests,
  fetchFeatureRequests,
  fetchFeatureRequestStatuses
} from "../ducks/actions";
import List from "./list";

const FeedbackContainer = (props) => {
  const {
    searchFeatureRequests,
    featureRequests,
    featureRequestsLoading,
    pageSections,
    errors,
    fetchFeatureRequestStatuses,
    statuses } = props;

  const params = QueryString.parse(props.location.search);
  const parameterStatuses: any = params.statuses;
  const parameterOrderBy: any = params.orderBy;
  const parameterOrderByDirection: any = params.orderByDirection;
  const parameterStartDate: any = params.startDate;
  const parameterEndDate: any = params.endDate;

  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [searchString, setSearchString] = useState<any>();
  const [selectedStatuses, setSelectedStatuses] = useState<any>();
  const [orderBy, setOrderBy] = useState<string>(parameterOrderBy ?? "submitted");
  const [orderByDirection, setOrderByDirection] = useState<string>(parameterOrderByDirection ?? "desc");
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [starDate, setStartDate] = useState<any>(parameterStartDate);
  const [endDate, setEndDate] = useState<any>(parameterEndDate);

  useEffect(() => {
    fetchFeatureRequestStatuses();
  }, []);

  // useEffect(() => {
  //   if (parameterOrderBy || parameterOrderByDirection) {
  //     onSearch();
  //   }
  // }, [parameterOrderBy, parameterOrderByDirection]);

  const onChangeSection = (sectionOption: any) => {
    setSelectedSection(sectionOption.value);
  };

  const onChangeStatus = (statusOptions: any) => {
    setSelectedStatuses(statusOptions);
  };

  const onChangeOrderBy = (sectionOption: any) => {
    setOrderBy(sectionOption.value);
  };

  const onChangeOrderByDirection = (sectionOption: any) => {
    setOrderByDirection(sectionOption.value);
  };

  const pageSectionOptions = pageSections.map((pageSection) => {
    return { value: pageSection.name, label: pageSection.name };
  });

  const statusOptions = (statuses || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  useEffect(() => {
    if (selectedStatuses?.length && (parameterStatuses || parameterOrderBy || parameterOrderByDirection || parameterEndDate || parameterStartDate)) {
      onSearch();
    }
  }, [selectedStatuses?.length]);

  useEffect(() => {
    if (parameterStatuses) {
      const splitStatuses = parameterStatuses.split(",").map(function (item) {
        return parseInt(item, 10);
      });

      setSelectedStatuses(statusOptions.filter(s => splitStatuses.includes(s.value)));
    } else {
      setSelectedStatuses(statusOptions.filter(s => s.value < 5));
    }

  }, [statusOptions?.length]);

  pageSectionOptions.unshift({ value: null, label: "All" });

  const selectedPageSectionOption = pageSectionOptions.find((section: any) => section.value === selectedSection);
  const orderByOptions = [
    { value: "submitted", label: "Created" },
    { value: "edited", label: "Edited" },
    { value: "priority", label: "Priority" },
    { value: "status", label: "Status" }
  ];

  const selectedorderByOption = orderByOptions.find((section: any) => section.value === orderBy);

  const orderByDirectionOptions = [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" }
  ];

  const selectedorderByDirectionOption = orderByDirectionOptions.find((section: any) => section.value === orderByDirection);

  const onSearch = () => {
    searchFeatureRequests(selectedSection, searchString, selectedStatuses?.map(s => s.value), orderBy, orderByDirection, starDate, endDate);
    setHasSearched(true);
  };

  const viewAll = () => {
    searchFeatureRequests(null, null, null, orderBy, orderByDirection);
    setHasSearched(true);
  };

  const onSearchChange = (e: any) => {
    setSearchString(e.target.value);
  };

  return (
    <BoxWraper width={5}>
      {featureRequestsLoading && <Loader />}
      <h2 className="menu-label">Feature Requests</h2>
      <Errors errors={errors} />
      <div className="mb-1">
        Section
        <FilmaizeSelect
          value={selectedPageSectionOption}
          placeholder="Section"
          isMulti={false}
          options={pageSectionOptions}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={onChangeSection}
        />
      </div>
      <div className="mb-1">
        <div className={`field`}>
          <label className={"label"}>
            Text
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Search text"
              value={searchString}
              onChange={onSearchChange}
            />
          </div>
        </div>
      </div>
      <div className="mb-1">
        Statuses
        <FilmaizeSelect
          value={selectedStatuses}
          placeholder="Section"
          isMulti={true}
          options={statusOptions}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={onChangeStatus}
        />
      </div>
      <div className="mb-1">
        Order By
        <FilmaizeSelect
          value={selectedorderByOption}
          placeholder="Order By"
          isMulti={false}
          options={orderByOptions}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={onChangeOrderBy}
        />
      </div>

      <div className="mb-1">
        Order By Direction
        <FilmaizeSelect
          value={selectedorderByDirectionOption}
          placeholder="Order By Direction"
          isMulti={false}
          options={orderByDirectionOptions}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={onChangeOrderByDirection}
        />
      </div>
      <div className="mb-1">
        {/* <button className="button mr-1" onClick={viewAll}>View All Incomplete</button> */}
        <button className="button mr-1" onClick={onSearch}>Search</button>
        <Link className="button" to={`/feature-request/add`}>Add</Link>
      </div>

      <List featureRequests={featureRequests} loading={featureRequestsLoading} hasSearched={hasSearched} />

    </BoxWraper>
  );

}


function mapStateToChangeLogs(state) {
  const { featureRequestState } = state;
  return {
    featureRequests: featureRequestState.featureRequests,
    featureRequestsLoading: featureRequestState.featureRequestsLoading,
    featureRequestSaving: featureRequestState.featureRequestSaving,
    featureRequestSent: featureRequestState.featureRequestSent,
    featureRequestErrors: featureRequestState.featureRequestErrors,
    errors: featureRequestState.errors,
    pageSections: state.commonState.pageSections,
    activeSection: state.commonState.activeSection,
    statuses: featureRequestState.statuses
  };
}

export default connect(mapStateToChangeLogs, {
  searchFeatureRequests,
  fetchFeatureRequests,
  fetchFeatureRequestStatuses
})(FeedbackContainer);
