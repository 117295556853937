import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../shared/modal/confirm";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import Onboarding from '../../shared/onboarding/onboarding';
import Loader from "./../../shared/loader";
import {
  addImageFiles,
  addImageUrl,
  clearRedirect,
  deleteActor,
  deleteImageFile,
  deleteImageUrl,
  fetchActor,
  mainImageUrlChanged,
  updateActor,
  uploadImagesAndSaveActor
} from "./ducks/actions";
import Form from "./form";

function ActorsContainer(props) {
  const { filmId, actorId } = props.match.params;
  const {
    fetchActor,
    fetchActorSettings,
    updateActor,
    deleteActor,
    loadingActor,
    actor,
    errors,
    warnings,
    addImageFiles,
    addImageUrl,
    imageFiles,
    uploadImagesAndSaveActor,
    deleteImageUrl,
    deleteImageFile,
    mainImageUrlChanged,
    onboardingSteps,
    drinks,
    milkTypes
  } = props;

  useEffect(() => {
    if (!actorId.startsWith("add") && !actorId.startsWith("upload")) {
      fetchActor(filmId, actorId);
    }
  }, [filmId, actorId]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/actors/`;
      return <Redirect to={url} />;
    }
  }

  if (actorId.startsWith("add") || actorId.startsWith("order") || actorId.startsWith("upload")) {
    return null;
  }

  const onAddImagesFiles = (files) => {
    addImageFiles(files);
  };

  const onAddImagesUrl = (url) => {
    addImageUrl(url);
  };

  function renderLoading() {
    return <Loader />;
  }

  function renderActor(actor) {
    let dietaryRequirements: any[] = [];
    (props.dietaryRequirementEnum || []).forEach((item) => {
      if ((item.value & actor.dietaryRequirements) === item.value) {
        dietaryRequirements.push(item.value);
      }
    });

    actor.dietaryRequirementsArray = dietaryRequirements;
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/actors/:actorId/edit"
        loading={loadingActor}>
        <Form
          errors={errors}
          warnings={warnings}
          mode="edit"
          filmId={filmId}
          actorId={actor.id}
          initialValues={actor}
          loading={loadingActor}
          onSubmit={onUpdateActor}
          handleDelete={onDeleteActor}
          onAddImageFiles={onAddImagesFiles}
          onAddImageUrl={onAddImagesUrl}
          onDeleteImageUrl={deleteImageUrl}
          onDeleteImageFile={deleteImageFile}
          imageFiles={imageFiles}
          mainImageUrl={actor.mainImageUrl}
          onMainImageUrlChanged={mainImageUrlChanged}
          drinks={drinks}
          milkTypes={milkTypes}
          {...props}
        />
        <Onboarding onboardingSteps={onboardingSteps} section="Actor" />
      </ScrollIntoView>
    );
  }

  function onUpdateActor(actor) {
    sanitiseDataTypes(actor);

    const files = actor.pictures || [];

    if ((files && files.length) > 0 || (actor.images && actor.images.length > 0)) {
      uploadImagesAndSaveActor(actor, files, false, filmId);
    } else {
      updateActor(filmId, actor);
    }
  }

  function onDeleteActor(actor) {
    confirm(() => deleteActor(filmId, actor), actor?.firstName + " " + actor?.lastName);
  }

  function sanitiseDataTypes(actor) {
    actor.number = +actor.number;
    actor.gender = +actor.gender;
    actor.heightCentimeters = actor.heightCentimeters
      ? +actor.heightCentimeters
      : null;
    actor.weightKilograms = actor.weightKilograms
      ? +actor.weightKilograms
      : null;

    let dietaryRequirement = 0;
    actor.dietaryRequirementsArray.forEach((item) => {
      dietaryRequirement = dietaryRequirement | item;
    });

    actor.dietaryRequirements = dietaryRequirement;
    if (actor.ethnicityId === "") {
      actor.ethnicityId = null;
    }

    if (actor.skinColorId === "") {
      actor.skinColorId = null;
    }
  }

  return actor && !loadingActor ? renderActor(actor) : renderLoading();
}

function mapStateToProps(state) {
  const { commonState } = state;
  const actorsState = state.actorsState.rootState;
  return {
    actor: actorsState.actor,
    loadingActor: actorsState.loadingActor,
    errors: actorsState.errors,
    warnings: actorsState.warnings,
    onboardingSteps: actorsState.onboardingSteps,
    genderEnum: commonState.genderEnum,
    ethnicityEnum: commonState.ethnicityEnum,
    dietaryRequirementEnum: commonState.dietaryRequirementEnum,
    skinColors: commonState.skinColors,
    ethnicityTypes: commonState.ethnicityTypes,
    imageFiles: actorsState.imageFiles,
    redirect: actorsState.redirect,
    drinks: commonState.drinks,
    milkTypes: commonState.milkTypes
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchActor,
    updateActor,
    deleteActor,
    addImageFiles,
    uploadImagesAndSaveActor,
    deleteImageUrl,
    deleteImageFile,
    mainImageUrlChanged,
    addImageUrl,
    clearRedirect
  })(ActorsContainer)
);
