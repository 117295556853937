import {
  FETCH_COLLABORTARORS_IN_ROLES
} from "./actions";

interface ActionState {
  collaborators: any[];
  loading: boolean;
  readOnly: boolean;
}

const defaultState: ActionState = {
  collaborators: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_COLLABORTARORS_IN_ROLES + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_COLLABORTARORS_IN_ROLES + "_FULFILLED": {
      return {
        ...state,
        collaborators: action.payload.data.collaborators,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_COLLABORTARORS_IN_ROLES + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }

    default:
      return state;
  }
};

export default reducer;
