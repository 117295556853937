import React from "react";
import { Prompt } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import Errors from "../../../shared/errors";
import {
  renderCheckbox
} from "../../../shared/form-helpers";
import "./shooting-days-equipment.scss";
import CanAccessLink from "../../../shared/can-access-link";

function ShootingDayEquipmentForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    saved,
    readOnly
  } = props;
  const { filmId } = props.match.params;
  const thisList = props.initialValues || {};
  const equipmentCategories = { equipmentCategories: thisList };

  if (saved) {
    reset();
  }

  return (
    <div className="shooting-days-equipment">
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="">
          <table className="table ">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Equipment</th>
              </tr>
            </thead>
            <tbody>
              <FieldArray
                name="equipmentCategories"
                component={renderEquipmentCategory}
                filmId={filmId}
                readOnly={readOnly} />
            </tbody>
          </table>
        </div>
        <br />
        {!readOnly && <div className="buttons sticky-buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}
          >
            Save
          </button>
        </div>}
      </form>
    </div>
  );
}

function renderEquipmentCategory({ fields, meta: { error }, readOnly, filmId }) {
  return (
    <>
      {(fields || []).map((equipmentCategory, idx, records) => {
        return (
          <>
            <tr key={idx} >
              <td colSpan={2}>
                <span className="dept-name">
                  <CanAccessLink to={`/films/${filmId}/equipment-category/${records.get(idx).equipmentCategoryId}/edit`} canAccess={true}>{records.get(idx).equipmentCategoryName}</CanAccessLink>
                </span>
              </td>
            </tr>
            <FieldArray
              name={`${equipmentCategory}.equipments`}
              component={renderEquipment}
              equimentCategoryId={records.get(idx).equipmentCategoryId}
              readOnly={readOnly}
              filmId={filmId}
            />
          </>
        );
      })}
    </>
  );
}

function renderEquipment({ fields, meta: { error }, readOnly, filmId, equimentCategoryId }) {
  return (
    <>
      {(fields || []).map((role, idx, records) => {
        return (
          <tr key={idx}>
            <td>
              <Field
                name={`${role}.selected`}
                component={renderCheckbox}
                checked={`${role}.selected`}
                readOnly={readOnly}
              />
            </td>
            <td><CanAccessLink to={`/films/${filmId}/equipment-category/${equimentCategoryId}/equipment/${records.get(idx).equipmentId}/edit`} canAccess={true}>{records.get(idx).equipmentName}</CanAccessLink></td>
          </tr>
        );
      })}
    </>
  );
}

export default reduxForm({
  form: "shootingDayEquipmentForm",
  enableReinitialize: true
})(ShootingDayEquipmentForm);
