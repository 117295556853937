import React from "react";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function VisualEffect(visualEffects) {
  const {
    visualEffect,
    onChangeVisualEffectBuget,
    readOnly,
    filmId,
    saving
  } = visualEffects;

  return (
    <tr>
      <td><Link to={`/films/${filmId}/visual-effects/${visualEffect.id}/edit`}>{visualEffect.name}</Link></td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={
            visualEffect.hire ? visualEffect.dailyBudget : visualEffect.budget
          }
          onChange={(val) => onChangeVisualEffectBuget(visualEffect.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={visualEffect.total}
        />
      </td>
    </tr>
  );
}

export default VisualEffect;
