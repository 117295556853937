import getApiClient from "../../../../shared/api";
import { showInfo, showError, clearNotifications } from "../../../../notifications/ducks/actions";

export const FETCH_COLLABORTAROR_ROLES = "FETCH_COLLABORTAROR_ROLES";
export const FETCH_COLLABORTAROR_ROLE_SECTIONS = "FETCH_COLLABORTAROR_ROLE_SECTIONS";
export const FETCH_COLLABORTAROR_ROLE = "FETCH_COLLABORTAROR_ROLE";
export const UPDATE_COLLABORTAROR_ROLE = "UPDATE_COLLABORTAROR_ROLE";
export const ADD_COLLABORTAROR_ROLE = "ADD_COLLABORTAROR_ROLE";
export const DELETE_COLLABORTAROR_ROLE = "DELETE_COLLABORTAROR_ROLE";
export const CLEAR_COLLABORTAROR_ROLE = "CLEAR_COLLABORTAROR_ROLE";
export const CLEAR_COLLABORTAROR_ROLE_REDIRECT = "CLEAR_COLLABORTAROR_ROLE_REDIRECT";

export function fetchCollaboratorRoles(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COLLABORTAROR_ROLES,
      payload: getApiClient().get(`/film/${filmId}/collaboratorRole`),
    });
  };
}

export function fetchSections(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COLLABORTAROR_ROLE_SECTIONS,
      payload: getApiClient().get(`/film/${filmId}/collaboratorRole/sections`),
    });
  };
}

export function fetchCollaboratorRole(filmId, collaboratorRoleId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COLLABORTAROR_ROLE,
      payload: getApiClient().get(`/film/${filmId}/collaboratorRole/${collaboratorRoleId}`),
    });
  };
}

export function updateCollaboratorRole(filmId, collaboratorRole) {
  return (dispatch) => {

    dispatch({
      type: UPDATE_COLLABORTAROR_ROLE,
      payload: getApiClient().put(`/film/${filmId}/collaboratorRole/${collaboratorRole.id}`, collaboratorRole),
      meta: { role: collaboratorRole },
    })
      .then((response) => {
        dispatch(showInfo(`Updated collaborator role ${collaboratorRole.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating collaborator role ${collaboratorRole.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addCollaboratorRole(filmId, collaboratorRole) {
  return (dispatch) => {
    dispatch({
      type: ADD_COLLABORTAROR_ROLE,
      payload: getApiClient().post(`/film/${filmId}/collaboratorRole/`, collaboratorRole),
      meta: { role: collaboratorRole },
    })
      .then((response) => {
        dispatch(showInfo(`Added collaborator role ${collaboratorRole.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding collaborator role ${collaboratorRole.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteCollaboratorRole(filmId, collaboratorRole) {
  return (dispatch) => {
    dispatch({
      type: DELETE_COLLABORTAROR_ROLE,
      payload: getApiClient().delete(`/film/${filmId}/collaboratorRole/${collaboratorRole.id}`),
      meta: { role: collaboratorRole },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted collaborator role ${collaboratorRole.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting collaborator role ${collaboratorRole.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearCollaboratorRole() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_COLLABORTAROR_ROLE,
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: CLEAR_COLLABORTAROR_ROLE_REDIRECT,
    });
  };
}
