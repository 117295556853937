import { Link } from "react-router-dom";
import AnonymousPage from "../anonymous-pages/anonymous-page";
import BoxWraper from "../box-wrapper";

function PaymentComplete(props) {

  const pricingPlan = +props.match.params.pricingPlan;

  return (
    <AnonymousPage>
      <BoxWraper width={5}>

        <p>Thank you for your payment. You will shortly receive an email confirming your payment.</p>
        <p><Link to={'/login'}>Login</Link></p>

      </BoxWraper>
    </AnonymousPage>
  );
}

export default PaymentComplete;
