import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import Loader from "./../../shared/loader";
import confirm from "../../shared/modal/confirm";
import { GoogleAddressParser } from "../../shared/Map/google-address-parser";
import {
  addLocation,
  clearLocation,
  clearRedirect,
  deleteLocation,
  fetchCarParks,
  fetchHospitals,
  fetchLocation,
  fetchLocations,
  initAddLocationForm,
  initEditLocationForm,
  resetZoomMap,
  setCarParkAddress,
  setCarParks,
  setLatLng,
  setNoCarParksFound,
  setSelectedCarPark,
  setSelectedHospital,
  updateLocation,
  uploadImagesAndSaveLocation,
  updateEvent,
  addDays,
  deleteDay,
  deleteDays,
  changeDayOfWeek,
  getCountries
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

declare const google: any;

function EditLocationContainer(props) {
  const { filmId, locationId } = props.match.params;
  const { hash } = useLocation();
  const {
    fetchLocation,
    uploadImagesAndSaveLocation,
    updateLocation,
    deleteLocation,
    loadingLocation,
    location = {},
    errors,
    fetchHospitals,
    hospitals,
    selectedHospital,
    setSelectedHospital,
    carParks,
    setCarParks,
    noCarParks,
    setNoCarParksFound,
    setSelectedCarPark,
    selectedCarPark,
    resetZoomMap,
    zoomMap,
    setCarParkAddress,
    showCarParkList,
    showHospitalList,
    clearLocation,
    onboardingSteps,
    availableDates,
    daysOfWeek,
    updateEvent,
    addDays,
    deleteDay,
    deleteDays,
    changeDayOfWeek,
    showAvailability,
    getCountries,
    countries,
    defaultCountryId
  } = props;

  const invalidIds = ["add", "overview"];

  const [searched, setSearched] = useState(false);
  /*
  useEffect(() => {
    const script = document.createElement('script');
    const apiKey = (window as any).googleApiKey;
    const googleScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&v=beta&map_ids=398122f045f5fc9d`;
    script.src = googleScriptUrl;
    script.async = false;
  
    document.head.appendChild(script);
  
    return () => {
      document.head.removeChild(script);
    }
  }, []);
*/

  useEffect(() => {
    getCountries(filmId);
  }, [filmId]);

  useEffect(() => {
    if (invalidIds.indexOf(locationId) === -1) {
      fetchLocation(filmId, locationId);
    }
  }, [filmId, locationId]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/locations/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(locationId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderForm(location) {
    if (
      location &&
      location.address &&
      Array.isArray(location.address.addressLines)
    ) {
      location.address.addressLines = location.address.addressLines.join("\n");
    }
    return (
      <ScrollIntoView
        className="blade-content wider"
        path="/films/:filmId/locations/:locationId/edit"
        loading={loadingLocation}>
        <Form
          errors={errors}
          mode="edit"
          locationId={location.id}
          initialValues={{ ...location }}
          loading={loadingLocation}
          onSubmit={onUpdateLocation}
          handleDelete={onDeleteLocation}
          onUpdateLatLng={onUpdateLatLng}
          onFetchHospitals={onFetchHospitals}
          hospitals={hospitals}
          onSetSelectedHospital={setSelectedHospital}
          selectedHospital={selectedHospital}
          showCarParkList={showCarParkList}
          showHospitalList={showHospitalList}
          onFindParking={onFindParking}
          carParks={carParks}
          noCarParks={noCarParks}
          onSetSelectedCarPark={onSetSelectedCarPark}
          selectedCarPark={selectedCarPark}
          zoomMap={zoomMap}
          resetZoom={resetZoomMap}
          searched={searched}
          setSearched={setSearched}
          filmId={filmId}
          onboardingSteps={onboardingSteps}
          availableDates={availableDates}
          daysOfWeek={daysOfWeek}
          updateEvent={updateEvent}
          addDays={addDays}
          deleteDay={deleteDay}
          deleteDays={deleteDays}
          changeDayOfWeek={changeDayOfWeek}
          showAvailability={showAvailability}
          hash={hash}
          countries={countries}
          defaultCountryId={defaultCountryId}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onFetchHospitals({ address: { latitude, longitude, countryCode } }) {
    if (!props.fetchingHospitalList) {
      fetchHospitals(latitude, longitude, countryCode);
    }
  }

  function onSetSelectedCarPark(carParkId) {
    const carPark = carParks.find((c) => c.place_id === carParkId);
    onPlaceDetails(carParkId);
    setSelectedCarPark(carPark);
  }

  const onFindParking = ({ address: { latitude, longitude } }) => {
    var request = {
      location: { lat: latitude, lng: longitude },
      radius: "1600",
      rankby: "distance",
      type: ["parking"]
    };
    let service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.nearbySearch(request, nearbySearchCallback);
  };

  const nearbySearchCallback = (results, status) => {
    if (status === "ZERO_RESULTS") {
      setNoCarParksFound();
    } else if (status === google.maps.places.PlacesServiceStatus.OK) {
      const carParks = results.map((carPark) => {
        return {
          place_id: carPark.place_id,
          name: carPark.name,
          position: {
            latitude: carPark.geometry.location.lat(),
            longitude: carPark.geometry.location.lng()
          }
        };
      });
      setCarParks(carParks);
    }
  };

  const onPlaceDetails = (place_id: string) => {
    var request = {
      placeId: place_id,
      fields: ["name", "address_component", "geometry"]
    };
    let service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails(request, placeDetailsCallback);

    function placeDetailsCallback(results, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const address = new GoogleAddressParser(
          results.name,
          results.address_components,
          results.geometry.location.lat(),
          results.geometry.location.lng(),
          place_id
        ).result();
        setCarParkAddress(address);
        console.log("details results", address);
      }
    }
  };

  function onUpdateLocation(location) {
    sanitiseDataTypes(location);

    const addMode = !location.id;

    const files = location.pictures || [];
    const justAvailableDates = availableDates.filter(d => !d.isPotentialDate);
    if ((files && files.length) > 0 || (location.images && location.images.length > 0)) {
      uploadImagesAndSaveLocation(location, files, addMode, filmId, justAvailableDates);
    } else {
      if (!location.imageUrls) {
        location.imageUrls = [];
      }
      updateLocation(filmId, location, justAvailableDates);
    }
  }

  function onDeleteLocation(location) {
    confirm(() => deleteLocation(filmId, location), location.name);
  }

  function onUpdateLatLng(lat, lng) {
    props.setLatLng(lat, lng);
  }

  function sanitiseDataTypes(location) {
    location.interiorExterior = +location.interiorExterior;
  }

  return location?.id && !loadingLocation
    ? renderForm(location)
    : renderLoading();
}

function mapStateToProps(state) {
  const locationsState = state.locationsState.rootState;

  return {
    locations: locationsState.locations,
    location: locationsState.location,
    loading: locationsState.loading,
    loadingLocation: locationsState.loadingLocation,
    interiorExteriorEnum: state.commonState.interiorExteriorEnum,
    errors: locationsState.errors,
    hospitals: locationsState.hospitals,
    selectedHospital: locationsState.selectedHospital,
    carParks: locationsState.carParks,
    noCarParks: locationsState.noCarParks,
    selectedCarPark: locationsState.selectedCarPark,
    zoomMap: locationsState.zoomMap,
    showHospitalList: locationsState.showHospitalList,
    showCarParkList: locationsState.showCarParkList,
    fetchingHospitalList: locationsState.fetchingHospitalList,
    fetchingCarParkList: locationsState.fetchingCarParkList,
    redirect: locationsState.redirect,
    onboardingSteps: locationsState.onboardingSteps,
    availableDates: locationsState.availableDates,
    daysOfWeek: locationsState.daysOfWeek,
    showAvailability: locationsState.showAvailability,
    countries: locationsState.countries,
    defaultCountryId: locationsState.defaultCountryId
  };
}

export default connect(mapStateToProps, {
  fetchLocation,
  uploadImagesAndSaveLocation,
  addLocation,
  updateLocation,
  fetchLocations,
  deleteLocation,
  fetchHospitals,
  setSelectedHospital,
  fetchCarParks,
  setCarParks,
  setNoCarParksFound,
  setSelectedCarPark,
  resetZoomMap,
  setCarParkAddress,
  clearLocation,
  setLatLng,
  initAddLocationForm,
  initEditLocationForm,
  clearRedirect,
  updateEvent,
  addDays,
  deleteDay,
  deleteDays,
  changeDayOfWeek,
  getCountries
})(EditLocationContainer);
