import axios from "axios";
import auth from "./auth";

const getToken = () => {
  const token = (<any>window).crap ? auth.getToken() + "x" : auth.getToken();
  return token;
};

const addIntercept = (axios) => {
  axios.interceptors.request.use(async (config) => {
    const user = auth.getUser();
    var expires = new Date(user.expires);

    if (config.url != "/account/refresh") {
      let dateTime: Date = new Date();
      const refreshThreshold = new Date(dateTime.getTime() + (1000 * 60 * 5));
      var refreshToken = user.refreshToken;
      if (refreshToken && refreshThreshold > expires) {
        try {
          let data = await axios.post('/account/refresh', { accessToken: getToken(), refreshToken }, {
            headers: { Authorization: "" }
          });
          auth.setToken(data.data);
          const newConfig = {
            ...config,
            headers: { ...config.headers, Authorization: "Bearer " + data.data.token }
          };
          return newConfig;

        } catch (err) {
          auth.logout();
          window.location.href = '/login';
          //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
          const newConfig = {
            ...config,
            isAuthError: true
          };
          return newConfig;
        }
      }
    }

    return config;
  }, (err) => {
    debugger;
    console.log("error in getting ", err);
    window.location.href = '/login';
  });
};

const addResponseIntercept = (axios) => axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  if (error?.response?.status == 403) {
    const errors = { "": ["Access Denied"] };
    error.response.data = { errors: errors };
  }
  // Do something with response error
  return Promise.reject(error);
});

const getApiClient = (ignoreSessoinToken = false) => {
  let headers: any = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getToken()
  };

  if (!ignoreSessoinToken) {
    let sessionToken = (window as any).sessionToken;
    if (sessionToken) {
      headers.sessionToken = sessionToken;
    }
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || (window as any).apiUrl,
    headers
  });
  addIntercept(instance);
  addResponseIntercept(instance);
  return instance
};

export const getAnnonymousApiClient = () => {
  let headers: any = {
    "Content-Type": "application/json"
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || (window as any).apiUrl,
    headers
  });

  return instance
};

export default getApiClient;
