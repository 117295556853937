import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../../shared/loader";
import { fetchCostumes } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListCostumesContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchCostumes, loading, costumes, errors, readOnly } = props;

  useEffect(() => {
    fetchCostumes(filmId);
  }, [filmId, fetchCostumes]);

  function renderLoading() {
    return <Loader />;
  }

  function renderCostumes(costumes, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/costumes">
        <List costumes={costumes} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && (
          <NavLink
            to={`/films/${filmId}/costumes/add`}
            activeClassName={
              "is-active " + window.location.pathname.endsWith("add")
                ? "disabled"
                : ""
            }
            className="button is-transparent is-add">
            <AddIcon className="white-icon" />
            Add Costume
          </NavLink>
        )}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderCostumes(costumes, filmId);
}

function mapStateToProps(state) {
  const { costumes, loading, errors, readOnly } = state.costumesState;
  return { costumes, loading, errors, readOnly };
}

export default connect(mapStateToProps, {
  fetchCostumes
})(ListCostumesContainer);
