import { NavLink } from "react-router-dom";
function SetDressing(props) {
  const {
    prop,
    filmId
  } = props;

  return (
    <>
      <tr>
        <td>
          <NavLink to={`/films/${filmId}/set-dressings/${prop.id}/edit`}>
            {prop.name}
          </NavLink>
        </td>
        <td>
          {prop.quantity}
        </td>
        <td>
          {prop.quantityAcquired}
        </td>
        <td>
          {prop.acquisitionMethodDescription}
        </td>
      </tr>
    </>
  );
}

export default SetDressing;
