import React from "react";

const TimelineHeader = ({title}: any) => {    

    return (       
      <header className="timeline-header">
        <span className="tag is-medium is-light timeline-tag">{title}</span>
      </header>
    )
}

export default TimelineHeader;