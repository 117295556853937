import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../../shared/private-route";
import ListPropsContainer from "./list-container";
import AddProp from "./add";

const PropRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/props"
        component={ListPropsContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/props/add"
        component={AddProp}
      />
    </>
  );
};

export default PropRoutes;
