import { Link } from "react-router-dom";
import Moment from "react-moment";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
//import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonCard = (props) => {
  
      return ( 
        <SkeletonTheme baseColor="#040f21" highlightColor="#222e44">               
      

                 <div className="columns is-multiline">
          {[0,1,2]
            .map((item, index) => (              
              <div className="card" key={index}>

                  <header className="card-header">
                    <div className="card-header-title is-size-5 film-name">
                    <p className="film-name">
                      <Skeleton width={`80%`}/>
                    </p>
                    <div>
                      <p className="" style={{display: "inline"}}>
                        <Skeleton width={`25%`} inline={true}/>
                        <Skeleton width={`40%`} />
                      </p>                      
                    </div>     
                    </div>                    
                                
                  
                  </header>
                <div className="card-content">
                  <div className="content">
                    <p className="is-size-7">
                      <Skeleton width={`40%`} />
                    </p>
                    <p className="is-size-7">
                      <Skeleton width={`90%`} />
                    </p>
                  </div>
                </div>
                <footer className="card-footer">   
                  <a style={{width: "100%"}} className="manage-film-button ml-1">                    
                    <Skeleton  height={30} width={`100%`} />
                  </a>             
                </footer>
              </div>
            ))}
        </div> 
      
      </SkeletonTheme>    
                );              
  
};

export default SkeletonCard;