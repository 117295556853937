import auth from "../../../shared/auth";
import { CANCEL_PAYMENT, FETCH_USER_PRICING_PLANS, PAUSE_PAYMENT, RESUME_PAYMENT, UNCANCEL_PAYMENT, UPDATE_USER_ACCOUNT } from "./actions";

interface AccountState {
  loading: boolean;
  updating: boolean;
  account: any;
  pricingPlans: any[];
}

const defaultState: AccountState = {
  loading: false,
  updating: false,
  account: {},
  pricingPlans: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_ACCOUNT_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case "FETCH_ACCOUNT_FULFILLED": {
      return {
        ...state,
        account: action.payload.data,
        loading: false
      };
    }

    case "FETCH_ACCOUNT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FETCH_USER_PRICING_PLANS + "_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case FETCH_USER_PRICING_PLANS + "_FULFILLED": {
      return {
        ...state,
        pricingPlans: action.payload.data.pricingPlans,
        loading: false
      };
    }

    case FETCH_USER_PRICING_PLANS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "CHANGE_PRICING_PLAN": {
      return {
        ...state,
        account: { ...state.account, pricingPlanType: action.payload }
      };
    }

    case UPDATE_USER_ACCOUNT + "_PENDING": {
      return {
        ...state,
        updating: true,
        errors: null
      };
    }

    case UPDATE_USER_ACCOUNT + "_FULFILLED": {
      return {
        ...state,
        account: { ...action.meta.account, ...action.payload.data },
        updating: false,
        errors: null
      };
    }

    case UPDATE_USER_ACCOUNT + "_REJECTED": {
      return {
        ...state,
        account: action.meta.account,
        errors: action.payload.response.data.errors,
        updating: false
      };
    }

    case PAUSE_PAYMENT + "_PENDING": {
      return {
        ...state,
        errors: null,
        updating: true
      };
    }

    case PAUSE_PAYMENT + "_FULFILLED": {
      return {
        ...state,
        account: { ...state.account, canPausePayment: false, canResumePayment: true, canCancelPayment: false },
        updating: false
      };
    }

    case PAUSE_PAYMENT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        updating: false
      };
    }

    case RESUME_PAYMENT + "_PENDING": {
      return {
        ...state,
        errors: null,
        updating: true
      };
    }

    case RESUME_PAYMENT + "_FULFILLED": {
      return {
        ...state,
        account: { ...state.account, canPausePayment: true, canResumePayment: false, canCancelPayment: true },
        updating: false
      };
    }

    case RESUME_PAYMENT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        updating: false
      };
    }

    case CANCEL_PAYMENT + "_PENDING": {
      return {
        ...state,
        errors: null,
        updating: true
      };
    }

    case CANCEL_PAYMENT + "_FULFILLED": {
      return {
        ...state,
        account: { ...state.account, canPausePayment: false, canResumePayment: false, canCancelPayment: false, willBeCancelled: true },
        updating: false
      };
    }

    case CANCEL_PAYMENT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        updating: false
      };
    }

    case UNCANCEL_PAYMENT + "_PENDING": {
      return {
        ...state,
        errors: null,
        updating: true
      };
    }

    case UNCANCEL_PAYMENT + "_FULFILLED": {
      return {
        ...state,
        account: { ...state.account, canPausePayment: true, canResumePayment: false, canCancelPayment: true, willBeCancelled: false },
        updating: false
      };
    }

    case UNCANCEL_PAYMENT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        updating: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
