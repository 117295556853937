import { IGNORE_USERS, NOT_APPROVE_USERS } from "./actions";

interface RegisterState {
  loading: boolean;
  users: any[];
}

const defaultState: RegisterState = {
  loading: false,
  users: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_UNAPPROVED_USERS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_UNAPPROVED_USERS_FULFILLED": {
      return {
        ...state,
        users: action.payload.data.users,
        loading: false
      };
    }

    case "FETCH_UNAPPROVED_USERS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "APPROVE_USERS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "APPROVE_USERS_FULFILLED": {
      const userIds = action.meta.userIds;
      var users = [...state.users];
      users = users.filter(user => userIds.indexOf(user.id) === -1);

      return {
        ...state,
        users: users,
        loading: false,
        errors: null
      };
    }

    case "APPROVE_USERS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case NOT_APPROVE_USERS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case NOT_APPROVE_USERS + "_FULFILLED": {
      debugger;
      const userIds = action.meta.userIds;
      var users = [...state.users];
      users = users.filter(user => userIds.indexOf(user.id) === -1);

      return {
        ...state,
        users: users,
        loading: false,
        errors: null
      };
    }

    case NOT_APPROVE_USERS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case IGNORE_USERS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case IGNORE_USERS + "_FULFILLED": {
      debugger;
      const userIds = action.meta.userIds;
      var users = [...state.users];
      users = users.filter(user => userIds.indexOf(user.id) === -1);

      return {
        ...state,
        users: users,
        loading: false,
        errors: null
      };
    }

    case IGNORE_USERS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
