import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { CardElement } from "@stripe/react-stripe-js";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";

import './payment.scss';
import FilmaizeSelect from "../../shared/filmanize-select";
import FilmaizeText from "../../shared/filmanize-text";

const validate = (values) => {
  const errors: any = {};
  if (!values.agree) {
    errors.agree = "Required";
  }

  return errors;
};

function RegisterForm(props) {
  const {
    handleSubmit,
    pricingPlans,
    currencies,
    submitting,
    errors,
    submitFailed,
    invalid,
    defaultCurrencyId,
    defaultPricingPlan,
    checkDiscountCode,
    discountCodeDescription,
    checkingCode,
    firstMonthPrice,
    selectedPricingPlan,
    setSelectedPricingPlan
  } = props;

  const cardElementOptions = {
    style: {
      base: {
        color: "#ffffff",
        fontSize: "16px",
        "::placeholder": {
          color: "#34435b"
        }
      },
      invalid: {
        color: "#ee5f5b",
        iconColor: "#ee5f5b"
      },
      complete: {
        iconColor: "#00ffce"
      }
    }
  };

  const [selectedCurrencyId, setSelectedCurrencyId] = useState<any>(defaultCurrencyId);
  const [currencySymbol, setCurrencySymbol] = useState<any>();
  const [price, setPrice] = useState<any>();
  const [discountCode, setDiscountCode] = useState<any>();

  useEffect(() => {
    if (!selectedCurrencyId || selectedPricingPlan === null) {
      return
    }

    updateCost(selectedPricingPlan, selectedCurrencyId);

  }, [selectedCurrencyId, selectedPricingPlan]);

  const pricingPlansOptions = pricingPlans?.map((pricingPlan: any) => { return { label: pricingPlan.name, value: pricingPlan.pricingPlanType } });

  const selectedMappedPricingPlan = pricingPlansOptions?.find((pricingPlan: any) => pricingPlan.value == selectedPricingPlan);

  const currencyOptions = currencies?.map((currency: any) => { return { label: currency.name, value: currency.id } });
  const selectedMappedCurrency = currencyOptions?.find((currency: any) => currency.value == selectedCurrencyId);

  const onChangePricingPlan = (pricingPlanType: any) => {
    setSelectedPricingPlan(pricingPlanType.value);
  };

  const onChangeCurrency = (currencyId: any) => {
    setSelectedCurrencyId(currencyId.value);
  };

  const updateCost = (pricingPlanType, currencyId) => {
    const pricingPlan = pricingPlans?.find((pricingPlan: any) => pricingPlan.pricingPlanType == pricingPlanType);
    const currencyCostPerMonth = pricingPlan.currencyCostPerMonth.find((costPerMonth) => costPerMonth.currencyId === currencyId)
    setCurrencySymbol(currencyCostPerMonth.currencySymbol);
    setPrice(+currencyCostPerMonth.price);
  }

  const onSubmit = () => {
    handleSubmit(selectedPricingPlan, selectedCurrencyId, discountCode);
  }

  const onSetDiscountCode = (code: string) => {
    setDiscountCode(code.toUpperCase());
  };

  const onCheckDiscountCode = (e) => {
    e.preventDefault();
    checkDiscountCode(discountCode, selectedPricingPlan, selectedCurrencyId);
  }

  return (
    <>
      <h2 className="menu-label">Pay</h2>
      {errors && <Errors errors={errors} />}

      <form autoComplete="off">
        <div>
          {/* {pricingPlans?.map((pricingPlan, index) => (
            <div key={index}>{pricingPlan.name}</div>
          ))} */}
          <label className="label">Pricing Plan</label>
          <FilmaizeSelect
            className="mb-1"
            value={selectedMappedPricingPlan}
            isMulti={false}
            options={pricingPlansOptions}
            onChange={onChangePricingPlan}
            placeholder="Pricing Plan"
          />

          <label className="label">Currency</label>
          <FilmaizeSelect
            className="mb-1"
            value={selectedMappedCurrency}
            isMulti={false}
            options={currencyOptions}
            onChange={onChangeCurrency}
            placeholder="Currency"
          />
          <div className="payment-discount">
            <FilmaizeText
              className="discount-code"
              label="Dicount Code"
              value={discountCode}
              onChange={onSetDiscountCode}
            />
            <button className={`button ${checkingCode ? "is-loading" : ""}`} onClick={onCheckDiscountCode} disabled={!discountCode}>Check</button>
          </div>
          {discountCodeDescription && <div className="mb-1 discount-description is-size-7">{discountCodeDescription}</div>}
        </div>

        <div className="mb-1">Monthly Price: <span dangerouslySetInnerHTML={{ __html: currencySymbol }}></span>{price}</div>
        {firstMonthPrice !== null && <div className="mb-1">To pay now: <span dangerouslySetInnerHTML={{ __html: currencySymbol }}></span>{firstMonthPrice}</div>}
        {price > 0 && <>
          <label className="label">Credit Card</label>
          <div className="card-element-container mt-1">
            <CardElement options={cardElementOptions} />
          </div>
          <div className="buttons">
            <button
              type="button"
              onClick={onSubmit}
              className={
                submitting ? "button is-primary is-loading" : "button is-primary"
              }
              disabled={submitting}>
              Pay
            </button>
          </div>

          <a href="https://stripe.com" target="_blank"><img className="stripe-logo" src="/images/Powered by Stripe - white.svg"></img></a>
        </>}


        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true,
  validate: validate
})(RegisterForm);
