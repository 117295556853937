import React from "react";
import { Route } from "react-router-dom";
import TermsAndConditionsRoute from "../static/terms-and-conditions/index";
import PrivacyPolicyRoute from "../static/privacy-policy/index";
import CookiesPolicyRoute from "../static/cookies-policy/index";

const StaticRoutes = () => {
  return (
    <>
      {/* <Route exact path="/" component={HomeRoute} /> */}
      <Route exact path="/terms-and-conditions" component={TermsAndConditionsRoute} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyRoute} />
      <Route exact path="/cookies-policy" component={CookiesPolicyRoute} />
    </>);
};

export default StaticRoutes;
