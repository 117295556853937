import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../shared/loader";
import { fetchShootingDays } from "./ducks/actions";
import List from "./list";

function ListShootingDaysContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchShootingDays, loading, shootingdays, hasDays, errors, loadingShootingDayList, canAccessMessages } = props;

  useEffect(() => {
    fetchShootingDays(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderShootingDays(shootingdays, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/shooting-days"
        loading={loading}>
        <List shootingdays={shootingdays} filmId={filmId} hasDays={hasDays} loadingShootingDayList={loadingShootingDayList} canAccessMessages={canAccessMessages} />
        {false && !window.location.pathname.endsWith("add") &&
          <NavLink
            to={`/films/${filmId}/shooting-days/add`}
            activeClassName={
              "is-active " + window.location.pathname.endsWith("add")
                ? "disabled"
                : ""
            }
            className="button is-text is-small">
            <i className="material-icons">add</i>
            Add ShootingDay
          </NavLink>
        }
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderShootingDays(shootingdays, filmId);
}

function mapStateToProps(state) {
  const { rootState } = state.shootingdaysState;
  return {
    shootingdays: rootState.shootingdays,
    loadingShootingDayList: rootState.loadingShootingDayList,
    canAccessMessages: rootState.canAccessMessages,
    hasDays: rootState.hasDays,
    loading: rootState.loading,
    errors: rootState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDays
})(ListShootingDaysContainer);
