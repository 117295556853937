import { sortByName } from "./../../../../../shared/utility";

const defaultState = {
  roles: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "CLEAR_ROLE": {
      return {
        ...state,
        errors: null
      };
    }

    case "FETCH_ROLES_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_ROLES_FULFILLED": {
      return {
        ...state,
        roles: action.payload.data.crewRoles,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_ROLE_PENDING": {
      return {
        ...state,
        role: null,
        loadingRole: true
      };
    }

    case "FETCH_SINGLE_ROLE_FULFILLED": {
      return {
        ...state,
        role: action.payload.data,
        loadingRole: false,
        errors: null
      };
    }

    case "UPDATE_SINGLE_ROLE_PENDING": {
      return {
        ...state,
        role: action.payload,
        loadingRole: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_ROLE_FULFILLED": {
      const role = { ...action.meta.role, ...action.payload.data.crewRole };
      const roles: any = state.roles.map((c: any, index: number) => {
        if (c.id === role.id) {
          return { ...c, ...role };
        } else {
          return c;
        }
      });
      sortByName(roles);
      return {
        ...state,
        role,
        roles,
        loadingRole: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_ROLE_REJECTED": {
      return {
        ...state,
        role: action.meta.role,
        errors: action.payload.response.data.errors,
        loadingRole: false
      };
    }

    case "ADD_SINGLE_ROLE_PENDING": {
      return {
        ...state,
        role: action.payload,
        errors: null,
        loadingRole: true
      };
    }

    case "ADD_SINGLE_ROLE_FULFILLED": {
      const role: any = action.payload.data.crewRole;

      let roles = [role, ...state.roles];
      sortByName(roles);
      return {
        ...state,
        role,
        roles,
        loadingRole: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_ROLE_REJECTED": {
      return {
        ...state,
        role: action.meta.role,
        errors: action.payload.response.data.errors,
        loadingRole: false
      };
    }

    case "DELETE_SINGLE_ROLE_PENDING": {
      return {
        ...state,
        role: action.payload,
        loadingRole: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_ROLE_FULFILLED": {
      const role = action.meta.role;
      const roles: any = state.roles.filter((c: any, index: number) => {
        return c.id !== role.id;
      });

      return {
        ...state,
        role: null,
        roles,
        loadingRole: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_ROLE_REJECTED": {
      return {
        ...state,
        role: action.meta.role,
        errors: action.payload.response.data.errors,
        loadingRole: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        role: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
