import React, { useState } from "react";
import Price from "./price";
import PricesTermsAndConitions from "./prices-t-and-c";
import './prices.scss';

const Prices = ({ prices, currencySymbol, registerUrl }: any) => {
    return (
        <section id="pricing">
            <div className="is-centered prices">
                <div className="marketing-titles prices-title">Our pricing plans</div>
                <div className="prices-container-container">
                    <div className="prices-container">
                        {/* <div className="columns">
                        <div className="column is-one-quarter is-half-tablet"> */}
                        <Price
                            pricingPlan={0}
                            name="Free"
                            description="To try it out"
                            price={prices[0]}
                            currency={currencySymbol}
                            includes={["1 film", "3 users per film", "500MB storage"]}
                            registerUrl={registerUrl} />
                        <Price
                            pricingPlan={1}
                            name="No-budget"
                            description="For the basics"
                            price={prices[1]}
                            currency={currencySymbol}
                            includes={["2 active films", "10 users per film", "1GB storage"]}
                            registerUrl={registerUrl} />
                        <Price
                            pricingPlan={2}
                            name="Indie"
                            description="For teams"
                            price={prices[2]}
                            currency={currencySymbol}
                            includes={["5 active films", "Unlimited users per film", "5GB storage"]}
                            registerUrl={registerUrl} />
                        <Price
                            pricingPlan={3}
                            name="Studio"
                            description="For production companies"
                            price={prices[3]}
                            currency={currencySymbol}
                            includes={["10 active films", "Unlimited users per film", "10GB storage"]}
                            registerUrl={registerUrl} />
                    </div>
                </div>
                <PricesTermsAndConitions />
            </div>
        </section>
    )
}

export default Prices;