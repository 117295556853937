import { reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import "./risk-assessment.scss";
import RiskAssessments from "./risk-assessments";
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from "./ducks/reducer";

function RiskAssessmentForm(props) {
  const { errors,
    riskAssessments,
    dailyRiskAssessments,
    uploadRiskAssessment,
    uploadDailyRiskAssessment,
    setUploadError,
    deleteRiskAssessment,
    deleteDailyRiskAssessment,
    shootingDayId,
    readOnly } = props;

  return (
    <div className="risk-assessments">
      {errors && <Errors errors={errors} />}
      {!errors &&
        <div>
          <RiskAssessments
            label="Every Day"
            riskAssessments={riskAssessments}
            onUploadRiskAssessment={uploadRiskAssessment}
            setUploadError={setUploadError}
            deleteRiskAssessment={deleteRiskAssessment}
            onBoardingClass={OnboardingStep1ClassName}
            readOnly={readOnly} />
          <div className="mt-1">
            <RiskAssessments
              label="This Day"
              riskAssessments={dailyRiskAssessments}
              onUploadRiskAssessment={uploadDailyRiskAssessment}
              setUploadError={setUploadError}
              deleteRiskAssessment={deleteDailyRiskAssessment}
              shootingDayId={shootingDayId}
              onBoardingClass={OnboardingStep2ClassName}
              readOnly={readOnly} />
          </div>
        </div>
      }
    </div>
  );
}

export default reduxForm({
  form: "riskAssessmentForm",
  enableReinitialize: true
})(RiskAssessmentForm);
