import * as React from "react";
import Select from "react-select";

export const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "7px"
    },
    "::-webkit-scrollbar-track": {
      background: "transparent"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#495D7A"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#495D7A"
    }
  }),
  input: (base) => ({
    ...base,
    color: "white"
  }),
  container: (base, isFocused, isSelected) => ({
    ...base,
    "border-bottom": "none", //isFocused ||  isSelected ? "1px solid #627795": "2px solid #00ffce"
    "&:hover": {
      "border-bottom": "none"
    }
  }),
  control: (base, state) => ({
    ...base,
    //"border-bottom": '1px solid #627795',
    // This line disable the blue border
    boxShadow:
      state.isFocused || state.isSelected
        ? "inset 0 -2px 0 #00ffce"
        : "inset 0 -1px 0 #627795",
    //boxShadow: 'none',

    "&:hover": {
      //"border-bottom": state.isFocused || state.isSelected ? "none" : '1px solid #627795',
      boxShadow:
        state.isFocused || state.isSelected
          ? "inset 0 -2px 0 #00ffce"
          : "inset 0 -1px 0 #627795"
      //boxShadow: "inset 0 -2px 0 #00ffce"
    }
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
    hyphens: "auto",
    // kill the gap
    marginTop: "3px",
    textAlign: "left",
    // prevent menu to scroll y
    wordWrap: "break-word"
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
    "margin-left": 0
  }),
  valueContainer: (base) => ({
    ...base,
    "padding-left": 0,
    "padding-bottom": "2px",
    "padding-top": "2px"
  }),
  option: (styles) => ({ minHeight: "2em", ...styles }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: 0
  })
};

const FilmaizeSelect: React.FC<any> = (props) => {
  return (
    <Select
      {...props}
      isDisabled={props.isDisabled || props.disabled}
      styles={styles}
      className={`react-select-control ${props.className}`}
      classNamePrefix="react-select"
      components={{
        IndicatorSeparator: () => null
      }}
    />
  );
};

export default FilmaizeSelect;
