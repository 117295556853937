import React from "react";
import { Route } from "react-router-dom";
import ListCharactersContainer from "./list-container";
import AddCharacter from "./add";
import EditCharacter from "./edit";
import { PrivateRoute } from "../../../shared/private-route";

// import MemberRoutes from "./members/routes";

const CharacterRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/characters"
        component={ListCharactersContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/characters/add"
        component={AddCharacter}
      />
      {/* <Route path="/films/:filmId/scenes/:sceneId/characters/:characterId" component={CharacterMenu} /> */}
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/characters/:characterId/edit"
        component={EditCharacter}
      />
      {/* <MemberRoutes /> */}
    </>
  );
};

export default CharacterRoutes;
