import getApiClient from "../../../../shared/api";

export function findInScript(
  filmId,
  text,
  searchDialog,
  searchAction,
  searchCharacter,
  searchParenthetical,
  typeToDispatch
) {

  let filters: string[] = [];

  if (searchDialog) {
    filters.push(`searchDialog=true`);
  }

  if (searchAction) {
    filters.push(`searchAction=true`);
  }

  if (searchCharacter) {
    filters.push(`searchCharacter=true`);
  }

  if (searchParenthetical) {
    filters.push(`searchParenthetical=true`);
  }

  return (dispatch) => {
    dispatch({
      type: typeToDispatch,
      payload: getApiClient().get(
        `/film/${filmId}/script/search?text=${text}&${filters.join("&")}`
      ),
      meta: { selectSearchedScenes: true }
    });
  };
}
