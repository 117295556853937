import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import AnonymousPage from "../anonymous-pages/anonymous-page";
import BoxWraper from "../box-wrapper";
import {
  changePassword,
  clearForm
} from "./ducks/actions";
import Form from "./form";

function Login(props) {
  const {    
    changePassword,
    clearForm,
    loading,
    errors,
    user,
    submitted
  } = props;

  useEffect(() => {    
    clearForm();
  }, []);

  function onChangePassword(user) {    
    changePassword(user)
  }

  return (
      <BoxWraper>
        {loading && <Loader />}
      {!loading && 
        <Form
          errors={errors}        
          loading={false}
          onSubmit={onChangePassword}     
          initialValues={user}   
          submitted={submitted}
          {...props}
        />}
      </BoxWraper>
  );
}

function mapStateToProps(state) {
  const { accountState } = state;
  return {    
    user: accountState.changePasswordState.user,
    errors: accountState.changePasswordState.errors,
    loading: accountState.changePasswordState.loading,
    submitted: accountState.changePasswordState.submitted
  };
}

export default connect(mapStateToProps, {
  changePassword,
  clearForm
})(Login);
