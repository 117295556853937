import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../../shared/errors";
import {
  renderCheckbox,
  renderCurrencyField,
  renderTextField
} from "./../../../../shared/form-helpers";
import required, { maxDecimalValue } from "./../../../../shared/validation";

function CrewRoleForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode
  } = props;
  const thisRole = props.initialValues || {};
  const readOnly = thisRole.readOnly;

  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="name"
          label="Role Name"
          component={renderTextField}
          validate={[required]}
          required
          readOnly={readOnly}
        />

        <div className="field">
          <Field
            name="phoneNumberRequired"
            label="Phone Number Required"
            component={renderCheckbox}
            readOnly={readOnly}
          />
        </div>

        <Field
          name="dailyRate"
          label="Daily Rate"
          component={renderCurrencyField}
          validate={[maxDecimalValue]}
          readOnly={readOnly}
        />

        <Field
          label="Prep Days"
          name="prepDays"
          component={renderTextField}
          type="number"
          readOnly={readOnly}
        />

        <Field
          name="required"
          label="Required"
          component={renderCheckbox}
          readOnly={readOnly}
        />

        {!readOnly && <div className="buttons mt-1 sticky-buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisRole)}>
              Delete
            </button>
          )}
        </div>}
      </form>
    </>
  );
}

export default reduxForm({
  form: "crewRoleForm",
  enableReinitialize: true
})(CrewRoleForm);
