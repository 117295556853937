import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchPropsInScene } from "./ducks/actions";
import List from "./list";
import AddIcon from "../../icons/add";
import { Link } from "react-router-dom";

function ListPropsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchPropsInScene, loading, sceneProps, errors, readOnly } = props;

  useEffect(() => {
    fetchPropsInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(props, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List props={props} filmId={filmId} sceneId={sceneId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <Link
          to={`/films/${filmId}/scenes/${sceneId}/props/add`}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Prop
        </Link>}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneProps, filmId, sceneId);
}

function mapStateToProps(state) {
  return {
    sceneProps: state.scenesState.propState.props,
    loading: state.scenesState.propState.loading,
    errors: state.scenesState.propState.errors,
    readOnly: state.scenesState.propState.readOnly
  };
}

export default connect(mapStateToProps, {
  fetchPropsInScene
})(ListPropsContainer);
