import React from "react";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import { renderCheckbox } from "./../../shared/form-helpers";
import FilmanizeCheckbox from "../../shared/filmanize-checkbox";

let UnapprovedUsersForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    submitFailed,
    invalid,
    usersValue,
    approveUsers,
    notApproveUsers,
    ignoreUsers,
    showNotApproved,
    setShowNotApproved
  } = props;

  const onApproveUsers = (e) => {
    e.preventDefault();
    approveUsers(usersValue);
  };

  const onNotApproveUsers = (e) => {
    e.preventDefault();
    notApproveUsers(usersValue);
  };

  const onIgnoreUsers = (e) => {
    e.preventDefault();
    ignoreUsers(usersValue);
  };

  return (
    <>
      <h2 className="menu-label">Unapproved Users</h2>
      {errors && <Errors errors={errors} />}

      <form autoComplete="off">
        <div className="mb-1">
          <FilmanizeCheckbox label="Show not approved" checked={showNotApproved} onChange={setShowNotApproved} />
        </div>
        <FieldArray name="users" component={usersListForm} />

        <div className="buttons mt-1">
          <button
            onClick={onApproveUsers}
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={pristine || submitting}>
            Approve
          </button>
          <button
            className={
              submitting ? "button is-secondary is-loading" : "button is-primary"
            }
            onClick={onNotApproveUsers}
            disabled={pristine || submitting}>
            Not Approve
          </button>
          <button
            className={
              submitting ? "button is-secondary is-loading" : "button is-primary"
            }
            onClick={onIgnoreUsers}
            disabled={pristine || submitting}>
            Ignore
          </button>
          <button
            type="button"
            className="button is-secondary"
            disabled={pristine || submitting}
            onClick={reset}>
            Reset Values
          </button>
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

function usersListForm({ fields, meta: { error } }) {
  return (
    <div>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Approve</th>
            <th>Registerd</th>
            <th>Email</th>
            <th>Name</th>
            <th>Referrer</th>
            <th>Country</th>
            <th>IP Address</th>
            <th>Email Confirmed</th>
            <th>Pricing Plan</th>
          </tr>
        </thead>
        <tbody>
          {(fields || []).map((user, idx, records) => {
            return (
              <tr key={idx}>
                <td>
                  <Field
                    name={`${user}.selected`}
                    component={renderCheckbox}
                    checked={`${user}.selected`}
                  />
                </td>
                <td>{records.get(idx).registeredDate}</td>
                <td>{records.get(idx).emailAddress}</td>
                <td>{records.get(idx).name}</td>
                <td>{records.get(idx).referrer}</td>
                <td>{records.get(idx).country}</td>
                <td>{records.get(idx).ipAddress}</td>
                <td>{records.get(idx).emailConfirmed ? "Yes" : "No"}</td>
                <td>{records.get(idx).pricingPlan}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {error && <div className="error">{error}</div>}
    </div>
  );
}

// export default reduxForm({
//   form: "registerForm",
//   enableReinitialize: true
// })(UnapprovedUsersForm);

UnapprovedUsersForm = reduxForm({
  form: "unapprovedUsersForm",
  enableReinitialize: true
})(UnapprovedUsersForm);

// Decorate with connect to read form values
const selector = formValueSelector('unapprovedUsersForm'); // <-- same as form name
const UnapprovedUsersFormConnect = connect(
  state => {
    // can select values individually
    const usersValue = selector(state, 'users')

    return {
      usersValue
    }
  }
)(UnapprovedUsersForm)

export default UnapprovedUsersFormConnect