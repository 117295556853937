import React from "react";
import { NavLink } from "react-router-dom";

const ActorsList = ({ actors, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Background Actors</p>
      <ul className="menu-list">
        {(actors || []).map((actor, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/background-actors/${actor.id}/edit`}
                title={actor.number + ". " + actor.name}
                activeClassName="is-active">
                {actor.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ActorsList;
