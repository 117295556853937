import React from "react";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import { renderTextField } from "./../../shared/form-helpers";
import required from "./../../shared/validation";

function ForgotPasswordForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    errors,
    submitFailed,
    invalid,
    submitted
  } = props;

  return (
    <>
        <h2 className="menu-label">Forgot Password</h2>
        {errors && <Errors errors={errors} />}

        {submitted && <div>Please check your email for next steps</div>}
        {!submitted && (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="emailAddress"
              label="Email Address"
              component={renderTextField}
              validate={[required]}
              required
            />
            <div className="buttons">
              <button
                type="submit"
                className={
                  submitting
                    ? "button is-primary is-loading"
                    : "button is-primary"
                }
                disabled={submitting}>
                Forgot Password
              </button>
            </div>
            <NavLink to={`/login`} className="mt-1">
              Login
            </NavLink>

            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        )}
    </>
  );
}

export default reduxForm({
  form: "loginForm",
  enableReinitialize: true
})(ForgotPasswordForm);
