import React from "react";
import InputSlider from "../../shared/controls/input-slider";
import NumberFormat from "react-number-format";
import CurrencyInputContainer from "../../shared/currency-input-container";
import Checkbox from "../../shared/filmanize-checkbox";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function CrewMemeber(props) {
  const {
    crew,
    departmentId,
    onChangeRequired,
    onChangeDailyRate,
    onChangeCrewDays,
    days,
    hasShootingDays,
    readOnly,
    filmId,
    saving,
    crewHavePrepDays
  } = props;

  const label = <Link to={`/films/${filmId}/departments/${departmentId}/roles/${crew.id}/edit`}>{crew.name}</Link>;

  return (
    <tr>
      <td>
        {!readOnly && (
          <Checkbox
            checked={crew.required}
            onChange={(value) => onChangeRequired(departmentId, crew.id, value)}
            label={label}
            disabled={saving}
          />
        )}
        {readOnly && <span>{crew.name}</span>}
      </td>
      {crewHavePrepDays && <td>{crew.prepDays}</td>}
      <td className="budget-days-column">
        {hasShootingDays ? (
          crew.days
        ) : (
          <InputSlider
            step={1}
            formatLabel={(value) => `${value}`}
            draggableTrack={false}
            allowSameValues={false}
            maxValue={days}
            minValue={1}
            value={crew.days}
            onChange={(val) => onChangeCrewDays(departmentId, crew.id, val, crew.prepDays)}
            disabled={readOnly || saving}
          />
        )}
      </td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={crew.dailyRate}
          onChange={(val) => onChangeDailyRate(departmentId, crew.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={crew.total}
        />
      </td>
    </tr>
  );
}

export default CrewMemeber;
