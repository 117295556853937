import getApiClient from "../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export const ADMIN_FETCH_USER = "ADMIN_FETCH_USER";
export const ADMIN_DISABLE_USER = "ADMIN_DISABLE_USER";
export const ADMIN_UPDATE_USER = "ADMIN_UPDATE_USER";
export const FETCH_ADMIN_USER_PRICING_PLANS = "FETCH_ADMIN_USER_PRICING_PLANS";

export function fetchUser(userId) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_FETCH_USER,
      payload: getApiClient().get(`/admin/user/${userId}`)
    });
  };
}

export function fetchPricingPlans() {
  return (dispatch) => {
    dispatch({
      type: FETCH_ADMIN_USER_PRICING_PLANS,
      payload: getApiClient().get(`/lookup/UserPricingPlans`)
    });
  };
}

export function disableUser(userId) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_DISABLE_USER,
      payload: getApiClient().put(`/admin/users/disable`, { userIdToDisable: userId }),
      meta: { userId }
    }).then((response) => {
      dispatch(showInfo(`Successfully disabled user`));
    })
      .catch((response) => {
        dispatch(showError(`Error disabling user`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateUser(user) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_UPDATE_USER,
      payload: getApiClient().put(`/admin/user/${user.id}`, user),
      meta: { user }
    }).then((response) => {
      dispatch(showInfo(`Successfully updated user`));
    })
      .catch((response) => {
        dispatch(showError(`Error updating user`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}