import getApiClient from "./../../../shared/api";
import { showInfo, showError, clearNotifications, showWarning } from "./../../../notifications/ducks/actions";
import { getActionToUploadImagesAndSaveEntity } from "./../../../shared/image-action-creator";

export function fetchBackgroundActors(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BACKGROUND_ACTORS",
      payload: getApiClient().get(`/film/${filmId}/backgroundActor`),
    });
  };
}

export function clearActor() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_BACKGROUND_ACTOR",
    });
  };
}

export function fetchBackgroundActor(filmId, actorId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_BACKGROUND_ACTOR",
      payload: getApiClient().get(`/film/${filmId}/backgroundActor/${actorId}`),
    });
  };
}

export function updateBackgroundActor(filmId, actor) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_BACKGROUND_ACTOR",
      payload: getApiClient().put(`/film/${filmId}/backgroundActor/${actor.id}`, actor),
      meta: { actor },
    })
      .then((response) => {
        dispatch(showInfo(`Updated background actor ${actor.firstName} ${actor.lastName}`));

        if (response.value.data.hasWarnings) {
          dispatch(showWarning(`Warning updating background actor ${actor.firstName} ${actor.lastName}`));
        }
      })
      .catch((response) => {
        dispatch(showError(`Error updating background actor ${actor.firstName} ${actor.lastName}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

function addBackgroundActorWithImageUrls(dispatch, actor, filmId) {
  dispatch({
    type: "ADD_SINGLE_BACKGROUND_ACTOR",
    payload: getApiClient().post(`/film/${filmId}/backgroundActor/`, actor),
    meta: { actor },
  })
    .then((response) => {
      dispatch(showInfo(`Added background actor ${actor.firstName} ${actor.lastName}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function updateBackgroundActorWithImageUrls(dispatch, actor, filmId) {
  dispatch({
    type: "UPDATE_SINGLE_BACKGROUND_ACTOR",
    payload: getApiClient().put(`/film/${filmId}/backgroundActor/${actor.id}`, actor),
    meta: { actor },
  })
    .then((response) => {
      dispatch(showInfo(`Updated background actor ${actor.firstName} ${actor.lastName}`));

      if (response.value.data.hasWarnings) {
        dispatch(showWarning(`Warning updating background actor ${actor.firstName} ${actor.lastName}`));
      }
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export const uploadImagesAndSaveActor = getActionToUploadImagesAndSaveEntity(
  addBackgroundActorWithImageUrls,
  updateBackgroundActorWithImageUrls
);

export function addBackgroundActor(filmId, actor) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_BACKGROUND_ACTOR",
      payload: getApiClient().post(`/film/${filmId}/backgroundActor/`, actor),
      meta: { actor },
    })
      .then((response) => {
        dispatch(showInfo(`Added actor ${actor.firstName} ${actor.lastName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding background actor`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteBackgroundActor(filmId, actor) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_BACKGROUND_ACTOR",
      payload: getApiClient().delete(`/film/${filmId}/backgroundActor/${actor.id}`),
      meta: { actor },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted background actor ${actor.firstName} ${actor.lastName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting background actor ${actor.firstName} ${actor.lastName}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addImageFiles(imageFiles) {
  return (dispatch) => {
    dispatch({
      type: "ADD_BACKGROUND_ACTOR_IMAGE_FILES",
      meta: { imageFiles },
    });
  };
}

export function addImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "ADD_BACKGROUND_ACTOR_IMAGE_URL",
      meta: { url },
    });
  };
}

export function deleteImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_BACKGROUND_ACTOR_IMAGE_URL",
      meta: { url },
    });
  };
}

export function deleteImageFile(fileName) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_BACKGROUND_ACTOR_IMAGE_FILE",
      meta: { fileName },
    });
  };
}

export function mainImageUrlChanged(url) {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_ACTOR_MAIN_IMAGE_CHANGED",
      meta: { url },
    });
  };
}

export function parseMandyUrl(url) {
  url = encodeURI(url);
  const parsingApiUrl = `/WebPageParser/Mandy?Url=${url}`;
  return parseUrlFromExternalSource("BACKGROUND_ACTOR_PARSE_MANDY_URL", url, parsingApiUrl);
}

export function parseStarNowUrl(url) {
  url = encodeURI(url);
  const parsingApiUrl = `/WebPageParser/StarNow?Url=${url}`;
  return parseUrlFromExternalSource("BACKGROUND_ACTOR_PARSE_STARNOW_URL", url, parsingApiUrl);
}

function parseUrlFromExternalSource(actionType, url, parsingApiUrl) {
  //url = encodeURI(url);
  return (dispatch) => {
    dispatch({
      type: actionType,
      payload: getApiClient().get(parsingApiUrl),
      meta: { url },
    });
    // .then((response) => {
    //   if (response.value.data.success) {
    //     dispatch(showInfo(`Parsed actor ${response.value.data.firstName} ${response.value.data.lastName}`));
    //   } else {
    //     dispatch(showError(`Error parsing actor: ${response.value.data.errors[""][0]}`));
    //   }
    // })
    // .catch((response) => {
    //   dispatch(showError(`Error parsing actor`));
    // })
    // .then((response) => {
    //   dispatch(clearNotifications());
    // });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
