import { sortByName } from "./../../../shared/utility";
import { CLEAR_EQUIPMENT, DELETE_SINGLE_EQUIPMENT, FETCH_EQUIPMENT_ACQUISITION_METHODS, NEW_EQUIPMENT } from "./actions";

const defaultState = {
  categories: [],
  loading: false,
  loadingEquipment: false,
  readOnly: false,
  equipments: [],
  acquisitionMethods: [],
  equipment: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_EQUIPMENTS_PENDING": {
      return {
        ...state,
        equipments: [],
        loading: true,
        errors: null
      };
    }

    case CLEAR_EQUIPMENT: {
      return {
        ...state,
        equipment: { required: true }
      };
    }

    case NEW_EQUIPMENT: {
      return {
        ...state,
        equipment: { required: true, acquisitionMethod: 3 }
      };
    }

    case FETCH_EQUIPMENT_ACQUISITION_METHODS + "_PENDING": {
      return {
        ...state,
        loading: true
      }
    }

    case FETCH_EQUIPMENT_ACQUISITION_METHODS + "_FULFILLED": {
      return {
        ...state,
        acquisitionMethods: action.payload.data,
        loading: false
      }
    }

    case FETCH_EQUIPMENT_ACQUISITION_METHODS + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      }
    }

    case "FETCH_EQUIPMENTS_FULFILLED": {
      return {
        ...state,
        equipments: action.payload.data.equipments,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_EQUIPMENT_PENDING": {
      return {
        ...state,
        equipment: null,
        loadingEquipment: true,
        errors: null
      };
    }

    case "FETCH_EQUIPMENT_REJECTED": {
      return {
        ...state,
        loadingEquipment: false,
        errors: action.payload.response.data.errors
      };
    }

    case "FETCH_EQUIPMENT_FULFILLED": {
      return {
        ...state,
        equipment: action.payload.data,
        loadingEquipment: false,
        errors: null
      };
    }

    case "ADD_SINGLE_EQUIPMENT_PENDING": {
      return {
        ...state,
        errors: null,
        loadingDepartment: true
      };
    }

    case "ADD_SINGLE_EQUIPMENT_FULFILLED": {
      const equipment: any = action.payload.data.equipment;
      let equipments = [equipment, ...state.equipments];
      sortByName(equipments);
      return {
        ...state,
        equipment,
        equipments,
        loadingDepartment: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_EQUIPMENT_REJECTED": {
      return {
        ...state,
        equipment: action.meta.equipment,
        errors: action.payload.response.data.errors,
        loadingDepartment: false
      };
    }

    case "UPDATE_SINGLE_EQUIPMENT_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case "UPDATE_SINGLE_EQUIPMENT_FULFILLED": {

      const equipment = { ...action.meta.equipment, ...action.payload.data.equipment };
      const equipments: any = state.equipments.map((c: any, index: number) => {
        if (c.id === equipment.id) {
          return { ...c, ...equipment };
        } else {
          return c;
        }
      });

      sortByName(equipments);
      return {
        ...state,
        equipments,
        equipment,
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_EQUIPMENT_REJECTED": {
      return {
        ...state,
        equipment: action.meta.equipment,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case DELETE_SINGLE_EQUIPMENT + "_FULFILLED": {
      const equipment = action.meta.equipment;
      const equipments: any = state.equipments.filter((c: any, index: number) => {
        return c.id !== equipment.id;
      });
      return {
        ...state,
        equipments,
        loading: false,
        redirect: { to: "list" }
      };
    }

    case DELETE_SINGLE_EQUIPMENT + "_REJECTED": {
      return {
        ...state,
        equipment: action.meta.equipment,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        department: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
