import FilmanizeCheckbox from "../../shared/filmanize-checkbox";

const CommunicationPreference = (props) => {
  const { label, preference, onChange } = props;
  return (
    <tr>
      <td>{label}</td>
      <td><div className="communication-preferences-checkbox"><FilmanizeCheckbox checked={preference?.includes(1)} onChange={() => onChange(1)} /></div></td>
      <td><div className="communication-preferences-checkbox"><FilmanizeCheckbox checked={preference?.includes(2)} onChange={() => onChange(2)} /></div></td>
    </tr>
  );
};

export default CommunicationPreference;