import React, { CSSProperties } from "react";
import Combobox from "react-widgets/lib/Combobox";
import Select from "react-select";
import { CirclePicker } from 'react-color';
import InputRange from "./controls/input-range";
//import "react-input-range/lib/css/index.css";
import { createNumberMask } from "redux-form-input-masks";
import NumberFormat from "react-number-format";
import Checkbox from "./filmanize-checkbox";
import CurrencyInputContainer from "./currency-input-container";
import FilmaizeSelect from "./filmanize-select";
import Time from "./time";
import CkEditor from "./controls/ckeditor";
import SmallLoader from "./small-loader/small-loader";
import FilmanizeRadio from "./filmanize-radio";
import CkEditorContent from "./controls/ckeditor-content";
import './form-helpers.scss';

export const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  required,
  readOnly,
  infoText,
  className,
  ...custom
}) => {
  return (
    <div className={`field ${className}`}>
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
        {infoText && (
          <span className="ml-1 material-icons" data-tool-tip={infoText}>
            info
          </span>
        )}
      </label>
      <div className="control">
        <input
          className="input"
          type="text"
          placeholder={label}
          {...input}
          {...custom}
          disabled={readOnly}
        />
      </div>

      {renderFormHelper({ touched, error })}
    </div>
  );
};

export const renderCurrencyField = ({
  label,
  input: { value, onChange },
  meta: { touched, invalid, error },
  required,
  readOnly,
  infoText,
  loading,
  ...custom
}) => {
  return (
    <div className="field">
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
        {infoText && (
          <span className="material-icons" data-tool-tip={infoText}>
            info
          </span>
        )}
      </label>
      <div className="control">
        {loading && <SmallLoader />}
        {!loading && <CurrencyInputContainer
          placeholder={label}
          value={value}
          onChange={(value) => onChange(value)}
          readOnly={readOnly}
        />}
      </div>

      {renderFormHelper({ touched, error })}
    </div>
  );
};

const timeSeparatorStyle: CSSProperties = {
  marginTop: "0.4rem"
};

const eigthStyle: CSSProperties = {
  marginTop: "0.6rem"
};

export const renderLocationSelect = ({
  label,
  input,
  options,
  meta: { touched, invalid, error },
  required,
  rest,
  readOnly,
  ...custom
}) => {
  const parse = (event) => {
    return JSON.parse(event.target.value);
  };
  return (
    <div className="field">
      {label && (
        <label className={"label " + (readOnly ? "is-disabled" : "")}>
          {label}
          {required ? <span className="required">&nbsp;*</span> : null}
        </label>
      )}
      <div className="field-body">
        <div className="field">
          <div className="select">
            <select
              onBlur={(event) => input.onBlur(parse(event))}
              onChange={(event) => input.onChange(parse(event))}
              disabled={readOnly}
              {...rest}>
              <option
                value={JSON.stringify({
                  locationId: null,
                  subLocationId: null
                })}
              />
              {(options || []).map((location, index) => {
                let locationName = location.name;
                if (location.subLocation) {
                  locationName += " - " + location.subLocation?.name;
                }
                return (
                  <option
                    key={index}
                    value={JSON.stringify({
                      locationId: location.id,
                      subLocationId: location.subLocation?.id
                    })}
                    selected={
                      input.value?.id == location.id &&
                      input.value.subLocation?.id == location.subLocation?.id
                    }>
                    {locationName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderTimeField = ({
  label,
  input,
  meta: { touched, invalid, error },
  required,
  readOnly,
  minuteGap,
  canAddDay,
  ...custom
}) => {
  return (
    <Time
      label={label}
      value={input.value}
      onChange={input.onChange}
      required={required}
      readOnly={readOnly}
      minuteGap={minuteGap}
      canAddDay={canAddDay}
      reduxHelper={renderFormHelper({ touched, error })}
    />
  );
};

export const renderDateField = ({
  label,
  input,
  meta: { touched, invalid, error },
  required,
  readOnly,
  ...custom
}) => {
  return (
    <div className="field">
      {label && (
        <label className={"label " + (readOnly ? "is-disabled" : "")}>
          {label}
          {required ? <span className="required">&nbsp;*</span> : null}
        </label>
      )}
      <input type="date" className="input" value={input.value} onChange={input.onChange} disabled={readOnly} />
    </div>
  );
};

export const renderPageNumberField = ({
  label,
  input,
  meta: { touched, invalid, error },
  required,
  readOnly,
  ...custom
}) => {
  const eighthOptions = () => {
    const items: any[] = [];
    for (let eighth = 0; eighth <= 8; eighth++) {
      items.push(
        <option key={eighth} value={eighth}>
          {eighth}/8
        </option>
      );
    }

    return items;
  };

  const eighthOptions2 = () => {
    const items: any[] = [];
    for (let eighth = 0; eighth <= 8; eighth++) {
      items.push({ value: eighth, label: eighth + "/8" });
    }

    return items;
  };

  let wholeNumber = input.value ? Math.floor(input.value) : undefined;
  let eighth = input.value ? (input.value % 1) * 8 : undefined;

  const onChangeEighth = (event) => {
    let eigtht = event.value;
    if (wholeNumber) {
      return input.onChange(wholeNumber + eigtht / 8.0);
    } else {
      return input.onChange(eigtht / 8.0);
    }
  };

  let selectEighth = {};
  const selectedEighthOption: any = eighthOptions2().find(
    (x) => x.value === eighth
  );
  if (selectedEighthOption) {
    selectEighth = selectedEighthOption;
  }

  return (
    <div className="field">
      {label && (
        <label className={"label " + (readOnly ? "is-disabled" : "")}>
          {label}
          {required ? <span className="required">&nbsp;*</span> : null}
        </label>
      )}
      <div className="field-body">
        <input
          className="input page-whole-number-width"
          type="number"
          placeholder={label}
          value={wholeNumber}
          onChange={(event) => {
            let whole = +event.target.value;
            return input.onChange(whole + (input.value % 1));
          }}
          disabled={readOnly}
        />
        &nbsp;
        <div className="page-eigtht-width">
          <FilmaizeSelect
            value={selectEighth}
            isMulti={false}
            options={eighthOptions2()}
            onChange={onChangeEighth}
            placeholder=""
            isDisabled={readOnly}
          />
        </div>
      </div>

      {renderFormHelper({ touched, error })}
    </div>
  );
};

export const renderUrlField = ({
  label,
  input,
  meta: { touched, invalid, error },
  required,
  editUrl,
  setEditUrl,
  disabled,
  readOnly,
  ...custom
}) => {
  const empty = !input.value;
  if (empty && !editUrl) {
    setEditUrl(true);
  }
  return (
    <div className="field">
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div >
        <div className="control" style={{ width: "100%" }}>
          {(empty || editUrl) && (
            <input
              className="input"
              type="text"
              placeholder={label}
              disabled={readOnly}
              {...input}
              {...custom}
            />
          )}
          {!empty && !editUrl && (
            <a href={input.value} target="_blank">
              {input.value}
            </a>
          )}
        </div>
        <div className="control">
          {!disabled && !readOnly && !empty && (
            <button
              type="button"
              className="button is-small is-text"
              onClick={() => {
                setEditUrl(!editUrl);
              }}>
              {editUrl ? <p>Done</p> : <p>Edit</p>}
            </button>
          )}
        </div>
      </div>

      {renderFormHelper({ touched, error })}
    </div>
  );
};

export const renderTextAreaField = ({
  label,
  input,
  meta: { touched, invalid, error },
  required,
  readOnly,
  className,
  ...custom
}) => (
  <div className={`field ${className}`}>
    <label className={"label " + (readOnly ? "is-disabled" : "")}>
      {label}
      {required ? <span className="required">&nbsp;*</span> : null}
    </label>
    <div className="control">
      <textarea
        className="textarea"
        placeholder={label}
        {...input}
        {...custom}
        disabled={readOnly}
        height="80"></textarea>
    </div>

    {renderFormHelper({ touched, error })}
  </div>
);

export const renderCkEditorField = ({
  label,
  input,
  meta: { touched, invalid, error },
  required,
  readOnly,
  className,
  ...custom
}) => {
  return (
    <div className={`field ${className}`}>
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="control">
        {input && !readOnly && <CkEditor value={input.value} onChange={input.onChange} />}
        {readOnly && <CkEditorContent content={input.value} />}
      </div>

      {renderFormHelper({ touched, error })}
    </div>
  )
};

export const renderCheckbox = ({
  input,
  label,
  required,
  disabled,
  readOnly,
  className,
  meta: { touched, error },
  ...custom
}) => (
  <>
    <Checkbox
      checked={input.value}
      onChange={(value) => input.onChange(value)}
      disabled={disabled}
      readOnly={readOnly}
      label={label}
      required={required}
      className={className}
    />
    {renderFormHelper({ touched, error })}
  </>
);

export const renderRadioButton = ({ input, label, name, required, readOnly, value }) => (
  <FilmanizeRadio disabled={readOnly} text={label} checked={input.value ? true : false} onChange={input.onChange} value={input.value} />
);

export const renderAddressField = ({
  value,
  meta: { touched, invalid, error },
  required
}) => {
  //debugger;
  return (
    <div className="field">
      <label className="label">
        Address{required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="control">
        <input
          className="input"
          type="text"
          placeholder="Address Lines"
          value={value.addressLines}
        />
      </div>

      {renderFormHelper({ touched, error })}
    </div>
  );
};

export const renderHiddenInput = ({
  label,
  input,
  meta: { touched, invalid, error },
  required,
  ...custom
}) => (
  <input
    className="input"
    type="hidden"
    placeholder={label}
    {...input}
    {...custom}
  />
);

// export const renderCheckbox = ({ input, label }) => (
//   <div>
//     <FormControlLabel
//       control={<Checkbox checked={input.value ? true : false} onChange={input.onChange} />}
//       label={label}
//     />
//   </div>
// );

// export const radioButton = ({ input, ...rest }) => (
//   <FormControl fullWidth>
//     <RadioGroup {...input} {...rest}>
//       <FormControlLabel value="female" control={<Radio />} label="Female" />
//       <FormControlLabel value="male" control={<Radio />} label="Male" />
//       <FormControlLabel value="other" control={<Radio />} label="Other" />
//     </RadioGroup>
//   </FormControl>
// );

export const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <p className="help is-error">{error}</p>;
  }
};

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  required,
  readOnly,
  ...custom
}) => {
  return (
    <div className="field">
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="control">
        <div className="select">
          <select {...input} {...custom} disabled={readOnly}>
            {children}
          </select>
        </div>
        {renderFormHelper({ touched, error })}
      </div>
    </div>
  );
};

export const renderCombobox = ({
  input,
  data,
  valueField,
  textField,
  placeholder,
  onSelect,
  meta: { touched, error, warning }
}) => {
  return (
    <>
      <Combobox
        {...input}
        data={data}
        placeholder={placeholder}
        valueField={valueField}
        textField={textField}
        onChange={(e) =>
          input.onChange(typeof e === "string" ? e : e[valueField])
        }
        onSelect={onSelect}
        onBlur={() => input.onBlur()}
      />
      {touched &&
        ((error && <span className="form-field-error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </>
  );
};

export const renderCheckboxGroup = ({
  label,
  required,
  name,
  options,
  input,
  textProperty,
  valueProperty,
  oneInEachRow,
  selectedOptions,
  readOnly,
  disabled,
  className,
  meta: { touched, error }
}) => {
  return (
    <div className={`field ${className ? className : ""}`}>
      <label className={"label " + ((readOnly || disabled) ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="field-body">
        {!options?.length && <div>No options</div>}
        {options?.length > 0 && (
          <div className="checkbox-items-container">
            {(options || []).map((option, index) => {
              let checked = false;
              if (Array.isArray(input.value)) {
                checked = !!input.value.find((val) => {
                  return option[valueProperty || "value"] === val;
                });
                // console.log(index + " : " + checked);
              }

              return (
                <span key={index}>
                  {readOnly && checked && (
                    <>
                      <div className="mr-1">
                        {option[textProperty || "name"]}
                      </div>
                    </>
                  )}
                  {!readOnly && (
                    <>
                      <Checkbox
                        checked={checked}
                        onChange={(value) => {
                          const newValue = [...input.value];
                          if (value) {
                            newValue.push(option[valueProperty || "value"]);
                          } else {
                            newValue.splice(
                              newValue.indexOf(
                                option[valueProperty || "value"]
                              ),
                              1
                            );
                          }

                          return input.onChange(newValue);
                        }}
                        disabled={readOnly || disabled}
                        readOnly={readOnly}
                        label={option[textProperty || "name"]}
                      />
                    </>
                  )}
                  {/* {oneInEachRow && (!readOnly || (readOnly && checked)) && <br />}                   */}
                </span>
              );
            })}
          </div>
        )}
      </div>
      {renderFormHelper({ touched, error })}
    </div>
  );
};

export const renderMilkTypesCheckboxGroup = ({
  label,
  required,
  name,
  options,
  input,
  textProperty,
  valueProperty,
  oneInEachRow,
  selectedOptions,
  readOnly,
  disabled,
  className,
  meta: { touched, error },
  showDairy
}) => {

  const veganOptions = options.filter(m => m.vegan);
  const dairyOptions = options.filter(m => !m.vegan);

  console.log("dairyOptions", dairyOptions);
  return (
    <div className={`field ${className ? className : ""}`}>
      <label className={"label " + ((readOnly || disabled) ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="field-body">
        {!options?.length && <div>No options</div>}
        {dairyOptions?.length > 0 && (
          <div className="">
            {showDairy && <div className="mb-1">
              {(dairyOptions || []).map((option, index) => {
                return (
                  <CheckBoxItem
                    input={input}
                    option={option}
                    index={index}
                    valueProperty={valueProperty}
                    textProperty={textProperty}
                    readOnly={readOnly}
                    disabled={disabled}
                  />

                );
              })}
            </div>}
            {veganOptions?.length > 0 && (
              <div>
                {(veganOptions || []).map((option, index) => {
                  return (
                    <CheckBoxItem
                      input={input}
                      option={option}
                      index={index}
                      valueProperty={valueProperty}
                      textProperty={textProperty}
                      readOnly={readOnly}
                      disabled={disabled}
                    />

                  );
                })}
              </div>
            )}

          </div>
        )}


      </div>
      {renderFormHelper({ touched, error })}
    </div>
  );
};

const CheckBoxItem = ({ input, option, index, valueProperty, textProperty, readOnly, disabled }) => {
  let checked = false;
  if (Array.isArray(input.value)) {
    checked = !!input.value.find((val) => {
      return option[valueProperty || "value"] === val;
    });
    // console.log(index + " : " + checked);
  }

  return (
    <span key={index}>
      {readOnly && checked && (
        <>
          <div className="mr-1">
            {option[textProperty || "name"]}
          </div>
        </>
      )}
      {!readOnly && (
        <>
          <Checkbox
            checked={checked}
            onChange={(value) => {
              const newValue = [...input.value];
              if (value) {
                newValue.push(option[valueProperty || "value"]);
              } else {
                newValue.splice(
                  newValue.indexOf(
                    option[valueProperty || "value"]
                  ),
                  1
                );
              }

              return input.onChange(newValue);
            }}
            disabled={readOnly || disabled}
            readOnly={readOnly}
            label={option[textProperty || "name"]}
          />
        </>
      )}
    </span>
  );
}

export const renderDietaryRequirements = ({
  label,
  required,
  name,
  options,
  input,
  textProperty,
  valueProperty,
  oneInEachRow,
  selectedOptions,
  readOnly,
  disabled,
  className,
  meta: { touched, error },
  dietaryRequirementDetailsValue
}) => {
  return (
    <div className={`field ${className ? className : ""}`}>
      <label className={"label " + ((readOnly || disabled) ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="field-body dietary-requirements">
        {!options?.length && <div>No options</div>}
        {options?.length > 0 && (
          <>
            <div className="mb-1">
              <Checkbox label="None" checked={input.value?.length == 0 && !dietaryRequirementDetailsValue} onChange={(value) => {
                const newValue = [...input.value];
                if (value) {
                  return input.onChange([]);
                } else {
                  return input.onChange([]);
                }

                return input.onChange(newValue);
              }} />
            </div>
            <div className="checkbox-items-container">

              {(options || []).map((option, index) => {
                let checked = false;
                if (Array.isArray(input.value)) {
                  checked = !!input.value.find((val) => {
                    return option[valueProperty || "value"] === val;
                  });
                  // console.log(index + " : " + checked);
                }

                return (
                  <span key={index}>
                    {readOnly && checked && (
                      <>
                        <div className="mr-1">
                          {option[textProperty || "name"]}
                        </div>
                      </>
                    )}
                    {!readOnly && (
                      <>
                        <Checkbox
                          checked={checked && input.value?.indexOf(0) == -1}
                          onChange={(value) => {
                            const newValue = [...input.value];
                            if (value) {
                              newValue.push(option[valueProperty || "value"]);
                            } else {
                              newValue.splice(
                                newValue.indexOf(option[valueProperty || "value"]),
                                1
                              );
                            }

                            return input.onChange(newValue);
                          }}
                          disabled={readOnly || disabled}
                          readOnly={readOnly}
                          label={option[textProperty || "name"]}
                        />
                      </>
                    )}
                    {/* {oneInEachRow && (!readOnly || (readOnly && checked)) && <br />}                   */}
                  </span>
                );
              })}
            </div>
          </>
        )}
      </div>
      {renderFormHelper({ touched, error })}
    </div>
  );
};
export const renderSectionGroup = ({
  label,
  required,
  name,
  sections,
  input,
  textProperty,
  valueProperty,
  oneInEachRow,
  selectedOptions,
  readOnly,
  disabled,
  className,
  headerClass,
  meta: { touched, error }
}) => {

  const setAll = (value) => {

    const newValue = { ...input.value };
    sections.forEach((sectionName) => {
      newValue[sectionName] = value;
    })

    return input.onChange(newValue);
  }

  return (
    <>
      <table className="table mt-2 collaborator-role-table">
        <thead>
          <tr className={headerClass}>
            <td>Section</td>
            <td className="has-text-centered is-clickable" onClick={() => setAll(0)}>Deny</td>
            <td className="has-text-centered is-clickable" onClick={() => setAll(1)}>Read Only</td>
            <td className="has-text-centered is-clickable" onClick={() => setAll(2)}>Edit</td>
          </tr>
        </thead>
        <tbody>
          {(sections || []).map((sectionName, index) => {
            let sectionValue = input.value[sectionName] ?? 0;

            return (

              <tr key={index}>
                <td>{sectionName}</td>
                <td className="has-text-centered"><FilmanizeRadio disabled={readOnly} checked={sectionValue == 0} onChange={() => {
                  const newValue = { ...input.value };
                  newValue[sectionName] = 0;
                  return input.onChange(newValue);
                }} value={0} /></td>
                <td className="has-text-centered"><FilmanizeRadio disabled={readOnly} checked={sectionValue == 1} onChange={() => {
                  const newValue = { ...input.value };
                  newValue[sectionName] = 1;
                  return input.onChange(newValue);
                }} value={1} /></td>
                <td className="has-text-centered"><FilmanizeRadio disabled={readOnly} checked={sectionValue == 2} onChange={() => {
                  const newValue = { ...input.value };
                  newValue[sectionName] = 2;
                  return input.onChange(newValue);
                }} value={2} /></td>
              </tr>

            );
          })}
        </tbody>
      </table>
      {renderFormHelper({ touched, error })}
    </>
  );
};

export const renderReactSelect = ({
  label,
  required,
  name,
  options,
  input,
  textProperty,
  valueProperty,
  selectedOptions,
  readOnly,
  isMulti = true,
  meta: { touched, error }
}) => {
  const styles: any = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "7px"
      },
      "::-webkit-scrollbar-track": {
        background: "transparent"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#495D7A"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#495D7A"
      }
    }),
    input: (base) => ({
      ...base,
      color: "white"
    }),
    container: (base, isFocused, isSelected) => ({
      ...base,
      "border-bottom": "none", //isFocused ||  isSelected ? "1px solid #627795": "2px solid #00ffce"
      "&:hover": {
        "border-bottom": "none"
      }
    }),
    control: (base, state) => ({
      ...base,
      //"border-bottom": '1px solid #627795',
      // This line disable the blue border
      boxShadow:
        state.isFocused || state.isSelected
          ? "inset 0 -2px 0 #00ffce"
          : "inset 0 -1px 0 #627795",
      //boxShadow: 'none',

      "&:hover": {
        //"border-bottom": state.isFocused || state.isSelected ? "none" : '1px solid #627795',
        boxShadow:
          state.isFocused || state.isSelected
            ? "inset 0 -2px 0 #00ffce"
            : "inset 0 -1px 0 #627795"
        //boxShadow: "inset 0 -2px 0 #00ffce"
      }
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
      hyphens: "auto",
      // kill the gap
      marginTop: "3px",
      textAlign: "left",
      // prevent menu to scroll y
      wordWrap: "break-word"
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
      "margin-left": 0
    }),
    valueContainer: (base) => ({
      ...base,
      "padding-left": 0
    }),
    placeholder: (base) => ({
      ...base,
      color: "red"
    })
  };

  let selectValue = {};
  if (isMulti) {
    selectValue = input.value;
  } else {
    const genderSelectedOption: any = options.find(
      (x) => x.value == input.value
    );
    if (genderSelectedOption) {
      selectValue = genderSelectedOption;
    }
  }

  return (
    <div className="field">
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="field-body">
        <Select
          value={selectValue}
          isMulti={isMulti}
          styles={styles}
          name="colors"
          options={options}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={(newValue) => {
            return input.onChange(newValue);
          }}
          isDisabled={readOnly}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </div>
      {renderFormHelper({ touched, error })}
    </div>
  );
};

export const renderMultiReactSelect = ({
  label,
  required,
  name,
  options,
  input,
  textProperty,
  valueProperty,
  selectedOptions,
  readOnly,
  isMulti = true,
  meta: { touched, error }
}) => {
  const styles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "7px"
      },
      "::-webkit-scrollbar-track": {
        background: "transparent"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#495D7A"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#495D7A"
      }
    }),
    input: (base) => ({
      ...base,
      color: "white"
    }),
    container: (base, isFocused, isSelected) => ({
      ...base,
      "border-bottom": "none", //isFocused ||  isSelected ? "1px solid #627795": "2px solid #00ffce"
      "&:hover": {
        "border-bottom": "none"
      }
    }),
    control: (base, state) => ({
      ...base,
      //"border-bottom": '1px solid #627795',
      // This line disable the blue border
      boxShadow:
        state.isFocused || state.isSelected
          ? "inset 0 -2px 0 #00ffce"
          : "inset 0 -1px 0 #627795",
      //boxShadow: 'none',

      "&:hover": {
        //"border-bottom": state.isFocused || state.isSelected ? "none" : '1px solid #627795',
        boxShadow:
          state.isFocused || state.isSelected
            ? "inset 0 -2px 0 #00ffce"
            : "inset 0 -1px 0 #627795"
        //boxShadow: "inset 0 -2px 0 #00ffce"
      }
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
      hyphens: "auto",
      // kill the gap
      marginTop: "3px",
      textAlign: "left",
      // prevent menu to scroll y
      wordWrap: "break-word"
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
      "margin-left": 0
    }),
    valueContainer: (base) => ({
      ...base,
      "padding-left": 0
    }),
    placeholder: (base) => ({
      ...base,
      color: "red"
    })
  };

  const selectValue = input.value == "" ? null : input.value?.map((e) => {
    return options?.find((x) => x.value == e);
  });

  const onChange = (newValue) => {
    input.onChange(newValue?.map((value) => value.value || value) ?? []);
  };

  return (
    <div className="field">
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="field-body">
        <Select
          value={selectValue}
          isMulti={isMulti}
          //style={styles}
          name="colors"
          options={options}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={onChange}
          isDisabled={readOnly}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </div>
      {renderFormHelper({ touched, error })}
    </div>
  );
};

export const renderSingleReactSelect = ({
  label,
  required,
  options,
  input,
  includeEmpty,
  emptyName,
  className,
  readOnly,
  placeholder,
  meta: { touched, error }
}) => {
  let selectValue = null;
  const selectedOption: any = options?.find((x) => x.value === input.value);
  if (selectedOption) {
    selectValue = selectedOption;
  }

  if (includeEmpty) {
    if (!options?.find((x) => x.value === null)) {
      options?.splice(0, 0, { value: null, label: emptyName ?? "  " });
    }
  }

  const onChange = (e) => {
    input.onChange(e.value);
  };

  return (
    <div className={`field ${className}`}>
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="field-body">
        <FilmaizeSelect
          value={selectValue}
          placeholder={placeholder ?? label}
          isMulti={false}
          options={options}
          className="react-select-control"
          classNamePrefix="react-select"
          onChange={onChange}
          isDisabled={readOnly}
        />
      </div>
      {renderFormHelper({ touched, error })}
    </div>
  );
};

export const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error },
  required
}) => (
  <div className="field">
    <label className={"label "}>
      {label}
      {required ? <span className="required">&nbsp;*</span> : null}
    </label>
    <div className="control">
      <input {...input} type={type} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderImage = (arg) => {
  const {
    input,
    label,
    type,
    value,
    meta: { touched, error },
    ...custom
  } = arg;
  console.log(arg);
  return (
    <div className="field">
      <label className={"label"}>{label}</label>
      <div className="control">
        <img src={input.value} {...custom} />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

export const renderFileInputField = ({
  input,
  index,
  name,
  label,
  type,
  meta: { touched, error }
}) => (
  <div className="field">
    <label className="label">{label}</label>
    <div className="control">
      <input
        type="file"
        name={input.name}
        onChange={(e) => {
          console.log(e.target.files);
          return input.onChange(e.target.files);
        }}
      />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderInputRangeField = ({
  input,
  label,
  type,
  meta: { touched, error },
  required,
  readOnly,
  quickSelection,
  setAgeRange
}) => (
  <div className="field mt-1 mb-1">
    <label className={"label " + (readOnly ? "is-disabled" : "")}>
      {label}
      {required ? <span className="required">&nbsp;*</span> : null}
    </label>
    <div className="control">
      {quickSelection && !readOnly && (
        <div className="mb-1">
          <button
            className="button tag age-range-shortcut"
            style={{ textTransform: "none" }}
            onClick={(e) => setAgeRange(e, 5, 12)}>
            Child
          </button>
          <button
            className="button tag age-range-shortcut"
            style={{ textTransform: "none" }}
            onClick={(e) => setAgeRange(e, 13, 19)}>
            Teenager
          </button>
          <button
            className="button tag age-range-shortcut"
            style={{ textTransform: "none" }}
            onClick={(e) => setAgeRange(e, 20, 29)}>
            20s
          </button>
          <button
            className="button tag age-range-shortcut"
            style={{ textTransform: "none" }}
            onClick={(e) => setAgeRange(e, 30, 39)}>
            30s
          </button>
          <button
            className="button tag age-range-shortcut"
            style={{ textTransform: "none" }}
            onClick={(e) => setAgeRange(e, 40, 49)}>
            40s
          </button>
          <button
            className="button tag age-range-shortcut"
            style={{ textTransform: "none" }}
            onClick={(e) => setAgeRange(e, 50, 59)}>
            50s
          </button>
          <button
            className="button tag age-range-shortcut"
            style={{ textTransform: "none" }}
            onClick={(e) => setAgeRange(e, 60, 75)}>
            Senior
          </button>
          <button
            className="button tag age-range-shortcut"
            style={{ textTransform: "none" }}
            onClick={(e) => setAgeRange(e, 75, 100)}>
            Elderly
          </button>
        </div>
      )}
      <InputRange
        maxValue={100}
        minValue={0}
        formatLabel={(value) => `${value}`}
        allowSameValues={true}
        value={input.value}
        disabled={readOnly}
        labelContainer="inputRangeLabelContainer"
        {...input}
      //onChange={(value) => this.setState({ value4: value })}
      //onChangeComplete={(value) => console.log(value)}
      />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);


export const renderCircleColorPicker = ({
  label,
  required,
  input,
  className,
  readOnly,
  meta: { touched, error }
}) => {
  const onChange = (color) => {
    input.onChange(color.hex);
  };

  return (
    <div className={`field ${className}`}>
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="field-body">
        <CirclePicker
          color={input.value}
          colors={["#00ffce", "#f44336", "#ee5f5b", "#9c27b0", "#947eed", "#3f51b5", "#2196f3", "#5bc0de", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
          onChangeComplete={onChange} />

      </div>
      {renderFormHelper({ touched, error })}
    </div>
  );
};