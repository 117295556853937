import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../../../shared/modal/confirm";
import Loader from "./../../../../shared/loader";
import {
  clearRedirect,
  deleteRole,
  fetchRole,
  updateRole
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../../shared/scroll-into-view/scroll-into-view";

function RolesContainer(props) {
  const { filmId, departmentId, roleId } = props.match.params;
  const {
    fetchRole,
    fetchRoleSettings,
    updateRole,
    deleteRole,
    loadingRole,
    role,
    errors
  } = props;

  useEffect(() => {
    if (roleId !== "add") {
      fetchRole(filmId, departmentId, roleId);
    }
  }, [filmId, roleId, departmentId, fetchRole]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/departments/${departmentId}/roles`;
      return <Redirect to={url} />;
    }
  }

  if (roleId == "add") {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderRole(role) {
    return (
      <ScrollIntoView
        className="blade-content"
        path="/films/:filmId/departments/:departmentId/roles/:roleId/edit"
        loading={loadingRole}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          roleId={role.id}
          initialValues={role}
          loading={loadingRole}
          onSubmit={onUpdateRole}
          handleDelete={onDeleteRole}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateRole(role) {
    sanitiseDataTypes(role);
    updateRole(filmId, departmentId, role);
  }

  function onDeleteRole(role) {
    confirm(() => deleteRole(filmId, departmentId, role), role.name);
  }

  function sanitiseDataTypes(role) {}

  return props.role ? renderRole(props.role) : renderLoading();
}

function mapStateToProps(state) {
  return {
    role: state.crewRolesState.role,
    redirect: state.crewRolesState.redirect,
    errors: state.crewRolesState.errors
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchRole,
    updateRole,
    deleteRole,
    clearRedirect
  })(RolesContainer)
);
