interface ActionState {
  loading: boolean;
  filmId: any;
  sentData: boolean;
}

const defaultState: ActionState = {
    loading: true,
    filmId: {},
    sentData: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "USER_INVITATION_NOT_LOGGED_IN": {
      return {
        ...state,
        loggedIn: false,
        loading: false
      };
    }

    case "USER_INVITATION_PENDING": {
      return {
        ...state,
        loading: true,
        loggedIn: true,
        errors: null,
        sentData: true
      };
    }

    case "USER_INVITATION_FULFILLED": {
      return {
        ...state,
        loading: false,
        filmId: action.payload.data.filmId,
        errors: null
      };
    }

    case "USER_INVITATION_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors,
        filmId: action.payload.response.data.filmId
      };
    }

    default:
      return state;
  }
};

export default reducer;
