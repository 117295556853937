import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

import getApiClient from "./../../../../shared/api";

export const UPLOAD_SCRIPT_VERSION = "UPLOAD_SCRIPT_VERSION";
export const UPLOAD_SCRIPT_VERSION_ERROR = "UPLOAD_SCRIPT_VERSION_ERROR";
export const INITIALISE_UPDATE_SCRIPT = "INITIALISE_UPDATE_SCRIPT";
export const FETCH_SCRIPT_VERSIONS = "FETCH_SCRIPT_VERSIONS";

export function fetchVersions(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SCRIPT_VERSIONS,
      payload: getApiClient().get(`/film/${filmId}/script/versions`)
    });
  };
}


export function uploadScript(filmId, formData) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_VERSION,
      payload: getApiClient().put(`/film/${filmId}/script/upload`, formData, {
        headers: {
          "content-type": `multipart/form-data`
        }
      })
    }).then((response) => {
      dispatch(showInfo(`Successfully uploaded script`));
    })
      .catch((response) => {
        dispatch(showError(`Error uploading script`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function setUploadError(error) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_VERSION_ERROR,
      meta: { error }
    });
  };
}

export function clearScriptUpload() {
  return (dispatch) => {
    dispatch({
      type: INITIALISE_UPDATE_SCRIPT
    });
  };
}