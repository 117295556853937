import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../shared/loader";
import {  
  fetchChangeLogs  
} from "./ducks/actions";
import List from './list';

function ViewChangeLogContainer(props) {  
  const { 
    errors,
    changeLogs,
    fetchChangeLogs,
    continuationToken,
    loading    
     } = props;


  useEffect(() => {
    fetchChangeLogs(null);    
  }, []);

  const onLoadMore = () => {
    fetchChangeLogs(continuationToken);
  };

  return (    
    <div>
        {loading && <Loader />}
        <List changeLogs={changeLogs} moreToLoad={continuationToken != null} loadMore={onLoadMore}/>
    </div>
  );
}

function mapStateToChangeLogs(state) {
  const { changeLogState, commonState } = state;
  return {
    changeLogs: changeLogState.changeLogs,
    continuationToken: changeLogState.continuationToken,
    errors: changeLogState.errors,    
    loading: changeLogState.loading
  };
}

export default connect(mapStateToChangeLogs, {
  fetchChangeLogs
})(ViewChangeLogContainer);
