import { Link } from "react-router-dom";

const Collaborator = (props: any) => {
    const { collaborator, filmId } = props;

    return (
        <tr>
            <td><Link to={`/films/${filmId}/collaborators/${collaborator.id}/edit`}>{collaborator.name}</Link></td>
            <td className={`${(collaborator.status >= 2 && collaborator.status <= 3) ? "error-text" : ""}`}>{collaborator.statusDescription}</td>
            <td className={`${(collaborator.emailingStatus >= 3 && collaborator.emailingStatus <= 5) ? "error-text" : ""}`}>{collaborator.emailingStatusDescription}</td>
            <td>{collaborator.roles.join(", ")}</td>
        </tr>
    )
};

export default Collaborator;