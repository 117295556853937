export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="26.892" height="26.294" viewBox="0 0 24.522 23.965" {...props}>
    <g id="Group_3838" data-name="Group 3838" transform="translate(0.509 0.5)">
      <g id="Group_3837" data-name="Group 3837">
        <path id="Path_32841" data-name="Path 32841" d="M258.815,28.387a1.067,1.067,0,0,1-.506-.137l-6.163-3.339a.882.882,0,0,0-.486,0L245.5,28.249a.889.889,0,0,1-1.126-.141.962.962,0,0,1-.2-.785l1.027-6.934a.811.811,0,0,0-.167-.526l-4.585-4.794a.88.88,0,0,1,.518-1.54l6.635-1.067a.751.751,0,0,0,.421-.309L251.1,6.008a.865.865,0,0,1,1.615,0l3.073,6.145a.752.752,0,0,0,.42.309l6.636,1.067a.88.88,0,0,1,.517,1.54l-4.584,4.794a.812.812,0,0,0-.168.526l1.027,6.934a.962.962,0,0,1-.2.785A.806.806,0,0,1,258.815,28.387Z" transform="translate(-240.151 -5.453)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      </g>
      <circle id="Ellipse_69" data-name="Ellipse 69" cx="3.22" cy="3.22" r="3.22" transform="translate(8.532 8.347)" fill="none" strokeLinejoin="round" strokeWidth="1" />
      <path id="Path_32842" data-name="Path 32842" d="M246.915,23.26a6.792,6.792,0,0,1,9.763-.164c.073.073.144.148.214.224" transform="translate(-240.151 -5.453)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </g>
  </svg>
