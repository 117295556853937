import React, { useCallback } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import {
  contact,
  clearContact
} from "./ducks/actions";
import Form from "./form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function Contact(props) {
  const {
    contact,
    loading,
    errors,
    user,
    sent,
    isOnWhite,
    showPageSections,
    pageSections,
    inApp,
    userEmailAddress,
    userFirstName,
    userLastName,
    selectedPageSection
  } = props;

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async (user) => {
    debugger;
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('contact');

    await onContact(user, token);

  }, [executeRecaptcha]);

  function onContact(user, reCaptchaToken) {
    contact(user, inApp, reCaptchaToken)
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="blade-content wide">
      <Form
        errors={errors}
        loading={false}
        initialValues={user}
        onSubmit={handleReCaptchaVerify}
        sent={sent}
        isOnWhite={isOnWhite}
        showPageSections={showPageSections}
        pageSections={pageSections}
        userEmailAddress={userEmailAddress}
        userFirstName={userFirstName}
        userLastName={userLastName}
        selectedPageSection={selectedPageSection}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { contactState, commonState } = state;
  return {
    user: contactState.user,
    errors: contactState.errors,
    loading: contactState.loading,
    sent: contactState.sent,
    pageSections: commonState.pageSections
  };
}

export default connect(mapStateToProps, {
  contact,
  clearContact
})(Contact);
