import {
  clearNotifications,
  showError,
  showInfo
} from "./../../../notifications/ducks/actions";
import getApiClient from "./../../../shared/api";
import { getActionToUploadImagesAndSaveEntity } from "./../../../shared/image-action-creator";

export const ADD_SINGLE_VISUAL_EFFECT = "ADD_SINGLE_VISUAL_EFFECT";
export const ASSIGN_SCENES_TO_VISUAL_EFFECT = "ASSIGN_SCENES_TO_VISUAL_EFFECT";
export const DELETE_SINGLE_VISUAL_EFFECT = "DELETE_SINGLE_VISUAL_EFFECT";
export const UPDATE_SINGLE_VISUAL_EFFECT = "UPDATE_SINGLE_VISUAL_EFFECT";

export function fetchVisualEffects(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_VISUAL_EFFECTS",
      payload: getApiClient().get(`/film/${filmId}/visualEffect`)
    });
  };
}

export function clearVisualEffect() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_VISUAL_EFFECT"
    });
  };
}

export function setName(name) {
  return (dispatch) => {
    dispatch({
      type: "VISUAL_EFFECT_SET_NAME",
      meta: { name }
    });
  };
}

export function fetchNextPropNumber(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NEXT_VISUAL_EFFECT_NUMBER"
    });
  };
}

export function fetchProp(filmId, visualEffectId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_VISUAL_EFFECT",
      payload: getApiClient().get(`/film/${filmId}/visualEffect/${visualEffectId}`)
    });
  };
}

export function updateProp(filmId, visualEffect) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_VISUAL_EFFECT",
      payload: getApiClient().put(`/film/${filmId}/visualEffect/${visualEffect.id}`, visualEffect),
      meta: { visualEffect }
    })
      .then((response) => {
        dispatch(showInfo(`Updated visualEffect ${visualEffect.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating visualEffect ${visualEffect.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

function addPropWithImageUrls(dispatch, visualEffect, filmId) {
  dispatch({
    type: ADD_SINGLE_VISUAL_EFFECT,
    payload: getApiClient().post(`/film/${filmId}/visualEffect/`, visualEffect),
    meta: { visualEffect }
  })
    .then((response) => {
      dispatch(showInfo(`Added visualEffect ${visualEffect.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function updatePropWithImageUrls(dispatch, visualEffect, filmId) {
  dispatch({
    type: "UPDATE_SINGLE_VISUAL_EFFECT",
    payload: getApiClient().put(`/film/${filmId}/visualEffect/${visualEffect.id}`, visualEffect),
    meta: { visualEffect }
  })
    .then((response) => {
      dispatch(showInfo(`Updated visualEffect ${visualEffect.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export const uploadImagesAndSaveProp = getActionToUploadImagesAndSaveEntity(
  addPropWithImageUrls,
  updatePropWithImageUrls
);

export function addVisualEffect(filmId, visualEffect) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_VISUAL_EFFECT",
      payload: getApiClient().post(`/film/${filmId}/visualEffect/`, visualEffect),
      meta: { visualEffect }
    })
      .then((response) => {
        dispatch(showInfo(`Added visualEffect ${visualEffect.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding visualEffects`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteProp(filmId, visualEffect) {
  return (dispatch) => {
    dispatch({
      type: DELETE_SINGLE_VISUAL_EFFECT,
      payload: getApiClient().delete(`/film/${filmId}/visualEffect/${visualEffect.id}`),
      meta: { visualEffect }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted visualEffect ${visualEffect.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting visualEffect ${visualEffect.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addImageFiles(imageFiles) {
  return (dispatch) => {
    dispatch({
      type: "ADD_VISUAL_EFFECT_IMAGE_FILES",
      meta: { imageFiles }
    });
  };
}

export function addImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "ADD_VISUAL_EFFECT_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_VISUAL_EFFECT_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageFile(fileName) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_VISUAL_EFFECT_IMAGE_FILE",
      meta: { fileName }
    });
  };
}

export function mainImageUrlChanged(url) {
  return (dispatch) => {
    dispatch({
      type: "VISUAL_EFFECT_MAIN_IMAGE_CHANGED",
      meta: { url }
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function fetchAllScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENES_FOR_VISUAL_EFFECTS",
      payload: getApiClient().get(`/film/${filmId}/scene`)
    });
  };
}

export function fetchScenesAssignedToProp(filmId, visualEffectId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_ASSIGNED_TO_VISUAL_EFFECTS",
      payload: getApiClient().get(
        `/film/${filmId}/visualEffect/${visualEffectId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedToVisualEffect(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SET_SCENE_ASSIGNED_TO_VISUAL_EFFECTS",
      meta: { sceneId }
    });
  };
}

export function assignScenesToVisualEffect(filmId, visualEffectId, sceneIds) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_SCENES_TO_VISUAL_EFFECT,
      payload: getApiClient().put(
        `/film/${filmId}/visualEffect/${visualEffectId}/AssignToScenes`,
        { sceneIds }
      ),
      meta: { visualEffectId }
    })
      .then((response) => {
        dispatch(showInfo(`Assigned visualEffects to Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error assigning visualEffects to Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchAllCharacters(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_CHARACTERS_FOR_VISUAL_EFFECTS",
      payload: getApiClient().get(`/film/${filmId}/character`)
    });
  };
}

export function fetchAllSceneSettings(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENE_SETTINGS_FOR_VISUAL_EFFECTS",
      payload: getApiClient().get(`/film/${filmId}/scenesetting`)
    });
  };
}

export function searchScenes(
  filmId,
  characterIds,
  sceneSettingIds,
  sceneNumberFrom,
  sceneNumberTo,
  selectSearchedScenes
) {
  return (dispatch) => {
    let filters: string[] = [];

    if (characterIds?.length) {
      filters.push(`characterIds=${characterIds.join(",")}`);
    }

    if (sceneSettingIds?.length) {
      filters.push(`sceneSettingIds=${sceneSettingIds.join(",")}`);
    }

    if (sceneNumberFrom) {
      filters.push(`sceneFrom=${sceneNumberFrom}`);
    }

    if (sceneNumberTo) {
      filters.push(`sceneTo=${sceneNumberTo}`);
    }

    dispatch({
      type: "SEARCH_SCENES_FOR_VISUAL_EFFECTS",
      payload: getApiClient().get(
        `/film/${filmId}/scene/search?${filters.join("&")}`
      ),
      meta: { selectSearchedScenes }
    });
  };
}

export function findInScript(
  filmId,
  propName
) {
  return (dispatch) => {
    dispatch({
      type: "SEARCH_SCRIPT_FOR_VISUAL_EFFECTS",
      payload: getApiClient().get(
        `/film/${filmId}/script/search/${propName}`
      ),
      meta: { selectSearchedScenes: true }
    });
  };
}

export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_SCENES_FOR_VISUAL_EFFECTS"
    });
  };
}
