import React from "react";
import NumberFormat from "react-number-format";
import CurrencyDisplay from "../../shared/currency-display";
import CrewMemeber from "./crew-member";

function Crew(props) {
  const {
    budget,
    sumCrew,
    onChangeRequired,
    onChangeDailyRate,
    onChangeCrewDays,
    calcDays,
    sumDay,
    sumDepartments,
    showHideCrew,
    crewVisible,
    readOnly,
    filmId,
    saving,
    crewHavePrepDays
  } = props;

  return (
    <div>
      <div className="columns subtitle is-4 mt-1">
        <div className="column">
          <h4
            className="subtitle is-4 pointer"
            onClick={() => showHideCrew(!crewVisible)}>
            <button className="button is-text is-small">
              {!crewVisible && <i className="material-icons">add</i>}
              {crewVisible && <i className="material-icons">remove</i>}
            </button>
            Crew
          </h4>
        </div>
        <div className="column budget-header-number">
          {!crewVisible && (
            <CurrencyDisplay
              value={sumDepartments(budget?.departments)}
            />
          )}
        </div>
      </div>
      {crewVisible && (
        <table className="table budget-table">
          <thead>
            <tr>
              <th>Name</th>
              {crewHavePrepDays && <th className="budget-prep-days-column">Prep</th>}
              <th className="budget-days-column">Days</th>
              <th className="budget-daily-rate-column">Daily Rate</th>
              <th className="has-text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {(budget?.departments || []).map((department) => {
              return (
                <>
                  <tr>
                    <th colSpan={crewHavePrepDays ? 4 : 3}>{department.name}</th>
                    <th className="has-text-right">
                      <CurrencyDisplay
                        value={department.total}
                      />
                    </th>
                  </tr>

                  {(department.crew || []).map((crew, i) => {
                    return (
                      <CrewMemeber
                        key={i}
                        crew={crew}
                        departmentId={department.id}
                        onChangeRequired={onChangeRequired}
                        onChangeDailyRate={onChangeDailyRate}
                        onChangeCrewDays={onChangeCrewDays}
                        days={budget.days}
                        calcDays={calcDays}
                        sumDay={sumDay}
                        hasShootingDays={budget.hasShootingDays}
                        readOnly={readOnly}
                        filmId={filmId}
                        saving={saving}
                        crewHavePrepDays={crewHavePrepDays} />
                    );
                  })}
                </>
              );
            })}
            <tr>
              <td colSpan={3}></td>
              <td className="has-text-right">
                <CurrencyDisplay
                  value={budget?.crewTotal}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Crew;
