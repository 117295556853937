import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../../shared/loader";
import { fetchDepartments } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListDepartmentsContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchDepartments, loading, departments, errors, readOnly } = props;

  useEffect(() => {
    fetchDepartments(filmId);
  }, [filmId, fetchDepartments]);

  function renderLoading() {
    return <Loader />;
  }

  function renderDepartments(departments, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/departments"
        loading={loading}>
        <List departments={departments} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && (
          <NavLink
            to={`/films/${filmId}/departments/add`}
            activeClassName={
              "is-active " + window.location.pathname.endsWith("add")
                ? "disabled"
                : ""
            }
            className="button is-transparent is-add">
            <AddIcon className="white-icon" />
            Add Department
          </NavLink>
        )}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderDepartments(departments, filmId);
}

function mapStateToProps(state) {
  return {
    departments: state.departmentsState.departments,
    readOnly: state.departmentsState.readOnly,
    loading: state.departmentsState.loading,
    errors: state.departmentsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchDepartments
})(ListDepartmentsContainer);
