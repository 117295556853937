import React, { useEffect } from "react";
import { connect } from "react-redux";
import { submit } from 'redux-form';
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import {
  getCallSheetDates,
  clearPdf,
  clearDownload,
  generatePdf,
  sendCallSheetToMembers,
  sendCallSheetToSelf,
  sendCallSheetToPerson,
  downloadCallSheet,
  fetchShootingDayCallSheetSettings,
  updateShootingDayCallSheetSettings,
  cancelShootingDay,
  submitForm
} from "./ducks/actions";

import Moment from "react-moment";
import "moment-timezone";
import HelpText from "../../../shared/help-text";
import Form from './form';
import Onboarding from '../../../shared/onboarding/onboarding';
import { Link } from "react-router-dom";
import confirm from "../../../shared/modal/confirm";

function CallSheet(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    dates,
    downloadCallSheet,
    sendCallSheetToMembers,
    sendCallSheetToSelf,
    sendCallSheetToPerson,
    clearPdf,
    clearDownload,
    loading,
    sending,
    saving,
    errors,
    progress,
    download,
    downloadProgress,
    shootingDaySettings,
    updateShootingDayCallSheetSettings,
    onboardingSteps,
    callSheetSectionEnum,
    weatherSectionEnum,
    readOnly,
    fetchShootingDayCallSheetSettings,
    dispatch,
    cancelShootingDay,
    canAccessActors,
    canAccessBackgroundActors,
    canAccessCrew,
    hasLocation
  } = props;

  useEffect(() => {
    //clearDownload();
    //getCallSheetDates(filmId, shootingdayId);
  }, [filmId]);

  useEffect(() => {
    clearDownload();
    fetchShootingDayCallSheetSettings(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function onUpdateShootingDayCallSheetSettings(shootingDaySettings) {
    updateShootingDayCallSheetSettings(filmId, shootingdayId, shootingDaySettings).then(() => {
      if (shootingDaySettings.sendToSelf) {
        onSendCallSheetToSelf();
      } else if (shootingDaySettings.sendToPerson) {
        const { personId, personType } = shootingDaySettings.sendToPerson;
        onSendPerson(personId, personType);
      } else if (shootingDaySettings.sendToMembers) {
        onSendCallSheetToMembers();
      }

    });
    clearDownload();
  }

  function renderLoading() {
    return <Loader />;
  }

  function onSendCallSheetToSelf() {
    sendCallSheetToSelf(filmId, shootingdayId);
  }

  function onDownloadCallSheet() {
    downloadCallSheet(filmId, shootingdayId);
  }

  function onSendCallSheetToMembers() {
    if (shootingDaySettings.hasPeopleWithoutEmailAddress) {
      confirm(() => sendCallSheetToMembers(filmId, shootingdayId), null, "Some people have no email addresses, see list below. Do you want to send to the ones that have?", "Missing Email Addresses");
    } else {
      confirm(() => sendCallSheetToMembers(filmId, shootingdayId), null, "Are you sure you want to send call sheet email to all actors and crew?", "Confirmation");
    }
  }

  const onSendPerson = (personId, personType) => {
    sendCallSheetToPerson(filmId, shootingdayId, personId, personType);
  };

  const onCancelShootingDay = () => {
    cancelShootingDay(filmId, shootingdayId);
  };

  function renderShootingDays() {
    return (
      <div className="blade-content wide700">
        {(sending || saving) && <Loader />}
        <p className="menu-label">Call Sheet</p>
        {dates && (
          <div>
            {!dates?.callSheetSend && !dates?.callSheetSentDateTime && (
              <HelpText>
                Call sheet will not automatically be sent. This can be set under <Link to={`/films/${filmId}/edit`}>Film</Link>.
              </HelpText>
            )}
            {dates?.callSheetSend && !dates?.callSheetSentDateTime && (
              <div>
                Call sheet will be sent:{" "}
                <Moment
                  format="DD MMM, YYYY HH:mm"
                  tz="UTC"
                  date={dates.callSheetSend}
                />
              </div>
            )}
            {dates?.callSheetSentDateTime && (
              <div className="mb-1">
                Call sheet was sent:{" "}
                <Moment
                  format="DD MMM, YYYY HH:mm"
                  date={dates.callSheetSentDateTime}
                />
              </div>
            )}
          </div>
        )}
        {shootingDaySettings?.cancelled && (
          <div className="mb-1">
            Shooting Day was cancelled:{" "}
            <Moment
              format="DD MMM, YYYY HH:mm"
              date={shootingDaySettings?.cancelledDateTime}
            />
          </div>
        )}
        <Form initialValues={shootingDaySettings}
          readOnly={readOnly}
          errors={errors}
          onSubmit={onUpdateShootingDayCallSheetSettings}
          callSheetSectionEnum={callSheetSectionEnum}
          weatherSectionEnum={weatherSectionEnum}
          downloadProgress={downloadProgress}
          clearPdf={clearPdf}
          download={download}
          onDownloadCallSheet={onDownloadCallSheet}
          onSendCallSheetToSelf={onSendCallSheetToSelf}
          onSendCallSheetToMembers={onSendCallSheetToMembers}
          dates={dates}
          hasLocation={hasLocation}
          progress={progress}
          filmId={filmId}
          onSendPerson={onSendPerson}
          shootingDaySettings={shootingDaySettings}
          onCancelShootingDay={onCancelShootingDay}
          cancelled={shootingDaySettings?.cancelled ?? false}
          canCancel={shootingDaySettings?.canCancel ?? false}
          canAccessActors={canAccessActors}
          canAccessBackgroundActors={canAccessBackgroundActors}
          canAccessCrew={canAccessCrew}
        />
        <Onboarding onboardingSteps={onboardingSteps} section="Call Sheet" />

      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays();
}

function mapStateToProps(state) {
  const { callSheetState, rootState } = state.shootingdaysState;
  return {
    shootingDay: rootState.shootingday,
    date: rootState?.shootingday?.date,
    pdfContent: callSheetState.pdfContent,
    dates: callSheetState.dates,
    hasLocation: callSheetState.hasLocation,
    loading: callSheetState.loading,
    sending: callSheetState.sending,
    saving: callSheetState.saving,
    progress: callSheetState.progress,
    download: callSheetState.download,
    errors: callSheetState.errors,
    readOnly: callSheetState.readOnly,
    downloadProgress: callSheetState.downloadProgress,
    shootingDaySettings: callSheetState.shootingDaySettings,
    onboardingSteps: callSheetState.onboardingSteps,
    callSheetSectionEnum: state.commonState.callSheetSectionEnum,
    weatherSectionEnum: state.commonState.weatherSectionEnum,
    canAccessActors: callSheetState.canAccessActors,
    canAccessBackgroundActors: callSheetState.canAccessBackgroundActors,
    canAccessCrew: callSheetState.canAccessCrew
  };
}

export default connect(mapStateToProps, {
  getCallSheetDates,
  generatePdf,
  sendCallSheetToMembers,
  sendCallSheetToSelf,
  clearPdf,
  clearDownload,
  downloadCallSheet,
  fetchShootingDayCallSheetSettings,
  updateShootingDayCallSheetSettings,
  sendCallSheetToPerson,
  cancelShootingDay
})(CallSheet);
