import OnboardingType from "../../shared/onboarding/onboarding-type";

export const OnboardingStep1ClassName = 'user-settings-onboarding-step-1';
export const OnboardingStep2ClassName = 'user-settings-onboarding-step-2';

const defaultState = {
  onboardingSteps: [      
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Script Pages Per Day',
      content: `This is the number of pages in your script that you can shoot in a day. This typically ranges from 3 to 6, depending on the level of crew you have.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_USER_SETTINGS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_USER_SETTINGS_FULFILLED": {
      return {
        ...state,
        userSettings: action.payload.data,
        loading: false
      };
    }

    case "FETCH_USER_SETTINGS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "UPDATE_USER_SETTINGS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "UPDATE_USER_SETTINGS_FULFILLED": {
      return {
        ...state,
        loading: false,
        userSettings: action.meta.userSettings
      };
    }

    case "UPDATE_USER_SETTINGS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
