import React from "react";
import PageToEighth from "../../../shared/eighth";
import Pages from "../../../shared/pages";
import "./shooting-day-schedule.scss";

const ScheduleEventList = ({
  shootingDayScheduleEvents,
  totalPages,
  filmId,
  shootingDayId,
  onDeleteEvent,
  onSelectEvent,
}) => {
  return (
    <aside className="menu">
      <p className="menu-label">Schedule</p>

      <table className="table is-narrow is-fullwidth shooting-day-schedule">
        <thead>
          <tr>
            <th>Time</th>
            <th>Scene</th>
            <th>Description</th>
            <th>Pages</th>
            <th>Shots</th>
            <th>Location</th>
            <th>Actors</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {(shootingDayScheduleEvents || []).map((ev, idx) => {
            return (
              <tr key={idx}>
                <th>{ev.time}</th>
                <td>{ev.sceneNumber}</td>
                <td>{ev.description || ev.sceneSettingName}</td>
                <td>
                  <Pages start={ev.pages?.start} end={ev.pages?.end}></Pages>
                </td>
                <td>{shotsToString((ev.shots || []).map((s) => s.number))}</td>
                <td>{ev.locationName}</td>
                <td>{(ev.actorNames || []).join(", ")}</td>
                <td>
                  <a
                    onClick={() => onSelectEvent(ev)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="material-icons">edit</i>
                  </a>
                </td>
                <td>
                  <a
                    onClick={() => onDeleteEvent(ev)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="material-icons">delete</i>
                  </a>
                </td>
              </tr>
            );
          })}
          <tr>
            <td></td>
            <td></td>
            <th className="has-text-right">Total</th>
            <th>
              <PageToEighth page={totalPages}></PageToEighth>
            </th>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </aside>
  );
};

const pageToEighth = (page) => {
  if (!page) {
    return null;
  }
  const wholePage = Math.floor(Math.abs(page));
  const fraction = page - wholePage;
  let eighth: any = null;
  if (fraction > 0) {
    eighth = Math.ceil(fraction / 0.125);
    eighth = eighth + "/8";
  }

  return (
    <>
      <span>{wholePage}</span>
      {eighth ? <span className="eighth">&nbsp;{eighth}</span> : null}
    </>
  );
};

const shotsToString = (myArray) => {
  return myArray.join(", ");

  var first;
  var last;
  var text = "";

  /*following code sorts the array low to high*/

  for (var i = 0; i < myArray.length; i++) {
    for (var counter2 = 0; counter2 < myArray.length; counter2++) {
      if (myArray[counter2] > myArray[counter2 + 1]) {
        var movingDown = myArray[counter2 + 1];
        var movingUp = myArray[counter2];

        myArray[counter2] = movingDown;
        myArray[counter2 + 1] = movingUp;
      }
    }
  }

  /*Following coded removes duplicates from sorted string*/
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i] == myArray[i + 1]) {
      myArray.splice(i + 1, 1);
      i--;
    }
  }

  /*Following code sets first and last vars to first number in array. While loop checks for consecutive number: if number is +1 then copied to var last as it is part
of consecutive range. WHile loop keeps checking and incrementing i until comparions is n longer +1 meaning number must not be part of this range - loop breaks. If statemnt
selects 2 cases: if first and last are the same, no range of numbers detected so copy first and add a comma. If First and last not the same range detected so copy first, 
add a dash and copy last and add a comma*/

  for (var i = 0; i < myArray.length; i++) {
    first = myArray[i];
    last = myArray[i];
    while (myArray[i + 1] - myArray[i] == 1) {
      last = myArray[i + 1];
      i++;
    }
    if (first == last) {
      text += last + ", ";
    } else if (first != last) {
      text += first + "-" + last;
    }
    if (i < myArray.length - 1) {
      text += ", ";
    }
  }
  return text;
};

export default ScheduleEventList;
