import * as React from 'react'

export default (props) =>
<svg xmlns="http://www.w3.org/2000/svg" width="19.452" height="21.103" viewBox="0 0 19.452 21.103" {...props}>
  <g id="noun-loud-speaker-4143848" transform="translate(-189.63 -106.011)">
    <path id="Path_32909" data-name="Path 32909" d="M204.286,133.013a2.43,2.43,0,0,0,.166-2.3,2.377,2.377,0,0,0-2.337-1.359,2.446,2.446,0,0,0-1.1.377l-2.024-2.008q-.591-.6-1.189-1.189h0a.513.513,0,0,0-.8.1q-2.537,4.993-5.071,9.956l-.679,1.325h0a3.617,3.617,0,0,0-1.6,2.564,3.519,3.519,0,0,0,4.525,3.794l1.834,1.834a.52.52,0,0,0,.72,0l1.658-1.658a.52.52,0,0,0,0-.72l-1.332-1.335,8.906-4.653,1.416-.737a.513.513,0,0,0,.1-.8Zm-2.194-2.636h.36c-.078,0,.054,0,.078.017l.122.027.126.041.044.017h0a2.169,2.169,0,0,1,.214.119.218.218,0,0,1,.109.078c.01.027-.092-.078,0,0l.061.051a.943.943,0,0,1,.187.2s-.054-.075-.024-.027l.044.061.071.112.061.115a.217.217,0,0,0,0,.031,2.156,2.156,0,0,1,.078.265.643.643,0,0,0,0,.068v.071a1.58,1.58,0,0,1,0,.255v.037h0a1.036,1.036,0,0,1-.031.143l-.041.139-.02.058-1.8-1.783.078-.031a1.809,1.809,0,0,1,.278-.065Zm-5.815,8.8a3.577,3.577,0,0,0-3.611-1.773l3.913-7.7,7.044,7.044.707.707-7.432,3.876-.238.126a3.525,3.525,0,0,0-.384-2.283Zm-4.052,4.063a2.527,2.527,0,0,1-.408-4.477.425.425,0,0,0,.16-.092c.082-.044.16-.088.248-.126h0a2.562,2.562,0,0,1,2.925.764,2.531,2.531,0,0,1,.173,2.891.4.4,0,0,0-.058.1,2.7,2.7,0,0,1-.3.38,2.57,2.57,0,0,1-2.738.564Zm5.1.853-.938.938-1.213-1.213h0a3.8,3.8,0,0,0,.52-.418,3.587,3.587,0,0,0,.394-.489h.037Zm7.965-7.133-7.269-7.256-.951-.951.092-.177.421-.825,8.7,8.7Z" transform="translate(0 -19.138)" />
    <path id="Path_32910" data-name="Path 32910" d="M442.908,144.217a.53.53,0,0,0,.36-.15l.744-.744c.034-.034.068-.071.105-.105a.509.509,0,0,0,0-.72.516.516,0,0,0-.36-.15.524.524,0,0,0-.36.15l-.849.849a.524.524,0,0,0-.149.36.51.51,0,0,0,.51.509Z" transform="translate(-237.437 -34.134)" />
    <path id="Path_32911" data-name="Path 32911" d="M397.661,108.542a.516.516,0,0,0,.51-.509v-1.512a.51.51,0,0,0-1.019,0v1.512A.516.516,0,0,0,397.661,108.542Z" transform="translate(-194.935)" />
    <path id="Path_32912" data-name="Path 32912" d="M470.682,202.38h-1.508a.51.51,0,0,0,0,1.019h1.508a.513.513,0,0,0,.509-.509A.52.52,0,0,0,470.682,202.38Z" transform="translate(-262.11 -90.524)" />
  </g>
</svg>
