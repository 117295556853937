import React from "react";
import { NavLink } from "react-router-dom";

const CollaboratorRolesList = ({ users, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Collaborator Roles</p>
      <ul className="menu-list">
        {(users || []).map((user, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/collaborators/collaborator-roles/${user.id}/edit`}
                title={user.name}
                activeClassName="is-active">
                {user.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default CollaboratorRolesList;
