import OnboardingType from "../../../../shared/onboarding/onboarding-type";

interface ShootingDayOrderState {
  scenes: any[];
  loading: boolean;
  days: any[];
  selectedSceneIds: string[];
  pristine: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'scene-script-days-onboarding-step-1';
export const OnboardingStep2ClassName = 'scene-script-days-onboarding-step-2';
export const OnboardingStep3ClassName = 'scene-script-days-onboarding-step-3';
export const OnboardingStep4ClassName = 'scene-script-days-onboarding-step-4';

const defaultState: ShootingDayOrderState = {
  scenes: [],
  days: [],
  loading: true,
  selectedSceneIds: [],
  pristine: true,
  readOnly: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scenes',
      content: `Drag scenes between days to change it's Script Day.`,
      disableBeacon: true
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Add Day',
      content: `Click 'Add Day' to create a new script day.`,
      disableBeacon: true
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Days',
      content: `You can also re-order the days by dragging them.`,
      disableBeacon: true
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Delete Day',
      content: `Deleting a day will move all scenes in that day to the end of the previous one.`,
      disableBeacon: true,
      nextButtonText: 'Get Started'
    },
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SCENES_SCRIPT_DAYS_PENDING": {
      return {
        ...state,
        loading: true,
        days: [],
        errors: null
      };
    }

    case "FETCH_SCENES_SCRIPT_DAYS_FULFILLED": {
      return {
        ...state,
        days: action.payload.data.days,
        readOnly: action.payload.data.readOnly,
        canAccessSceneSettings: action.payload.data.canAccessSceneSettings,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case "FETCH_SCENES_SCRIPT_DAYS_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "REORDER_SCENES_SCRIPT_DAYS": {
      const orderedScenes = reorderDays(
        state.days,
        action.meta.startIndex,
        action.meta.endIndex
      );
      return {
        ...state,
        days: orderedScenes,
        pristine: false
      };
    }

    case "REORDER_SCENES_SCRIPT_DAY_SCENES": {
      if (
        action.meta.destinationDay === "droppable" ||
        action.meta.destinationDay === "null"
      ) {
        return state;
      }

      const sourceDayNumber =
        action.meta.sourceDay === "null" ? null : +action.meta.sourceDay;
      const destinationDayNumber =
        action.meta.destinationDay === "null"
          ? null
          : +action.meta.destinationDay;

      let sourceDay = state.days.find((day) => {
        return day.number === sourceDayNumber;
      });

      if (action.meta.sourceDay === action.meta.destinationDay) {
        sourceDay.scenes = reorderScenes(
          sourceDay.scenes,
          action.meta.startIndex,
          action.meta.endIndex
        );

        const newDays = [...state.days];
        const sourceDayIndex = state.days.findIndex((day) => {
          return day.number === sourceDayNumber;
        });

        newDays.splice(sourceDayIndex, 1);

        const days = [...newDays, sourceDay];
        sortDays(days);
        return {
          ...state,
          days: days,
          pristine: false
        };
      }

      const newDays = Array.from(state.days);
      const destinationDay = newDays.find((day) => {
        return day.number === destinationDayNumber;
      });

      const destinationDayIndex = newDays.findIndex((day) => {
        return day.number === destinationDayNumber;
      });

      newDays.splice(destinationDayIndex, 1);

      // descrement scriptDayOrder in source day for scenes after startIndex
      //action.meta.startIndex,
      //action.meta.endIndex,
      for (
        let i = action.meta.startIndex + 1;
        i < sourceDay.scenes.length;
        i++
      ) {
        sourceDay.scenes[i].scriptDayOrder--;
      }

      // increment all scriptDayOrder on destination day scenes after startIndex
      for (
        let i = action.meta.endIndex;
        i < destinationDay.scenes.length;
        i++
      ) {
        destinationDay.scenes[i].scriptDayOrder++;
      }

      const sourceDayIndex = newDays.findIndex((day) => {
        return day.number === sourceDayNumber;
      });

      newDays.splice(sourceDayIndex, 1);

      const sourceScenes = Array.from(sourceDay.scenes);
      const original = sourceScenes.splice(action.meta.startIndex, 1);
      const newDestinationScene: any = original[0];

      // set scriptDayOrder on source scene to be startIndex + 1
      newDestinationScene.scriptDayOrder = action.meta.endIndex + 1;
      const newSource = { ...sourceDay, scenes: sourceScenes };

      const destinationScenes = Array.from(destinationDay.scenes);
      destinationScenes.splice(action.meta.endIndex, 0, newDestinationScene);
      const newDestination = { ...destinationDay, scenes: destinationScenes };
      const days = [...newDays, newSource, newDestination];
      sortDays(days);
      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case "ADD_SCENES_SCRIPT_DAY": {
      const days = Array.from(state.days);
      days.push({
        number: days.filter((d) => d.number).length + 1,
        scenes: []
      });
      return {
        ...state,
        days: days
      };
    }

    case "DELETE_SCENES_SCRIPT_DAY": {
      const days = Array.from(state.days);
      const dayToDeleteIndex = days.findIndex(
        (d) => d.number === action.meta.dayNumber
      );
      if (dayToDeleteIndex >= 0) {
        const dayToDelete = days[dayToDeleteIndex];
        if (dayToDelete.scenes.length > 0) {
          let previousDay = days[dayToDeleteIndex - 1];
          var scriptDayOrder = previousDay.scenes.length + 1;
          dayToDelete.scenes.forEach((scene) => {
            scene.scriptDayOrder = scriptDayOrder;
            previousDay.scenes.push(scene);
            scriptDayOrder++;
          });
        }

        days.splice(dayToDeleteIndex, 1);

        if (action.meta.dayNumber) {
          for (let i = dayToDeleteIndex; i < days.length; i++) {
            days[i].number--;
          }
        }
      }

      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case "UPDATE_SCENES_SHOOTING_SCRIPT_DAYS_PENDING": {
      return {
        ...state,
        loadingScene: true,
        errors: null
      };
    }

    case "UPDATE_SCENES_SHOOTING_SCRIPT_DAYS_FULFILLED": {
      return {
        ...state,
        days: action.meta.days,
        loadingScene: false,
        errors: null,
        pristine: true
      };
    }

    case "UPDATE_SCENES_SHOOTING_SCRIPT_DAYS_REJECTED": {
      return {
        ...state,
        days: action.meta.days,
        errors: action.payload.response.data.errors,
        loadingScene: false
      };
    }

    case "SCENES_SCRIPT_DAY_TOGGLE_SELECTION_IN_GROUP": {
      const sceneId = action.meta.sceneId;

      const selectedSceneIds: any[] = state.selectedSceneIds;
      const index: number = selectedSceneIds.indexOf(sceneId);

      // if not selected - add it to the selected items
      let shallow: any[] = [];
      if (index === -1) {
        shallow = [...selectedSceneIds, sceneId];
      } else {
        // it was previously selected and now needs to be removed from the group
        shallow = [...selectedSceneIds];
        shallow.splice(index, 1);
      }

      return {
        ...state,
        selectedSceneIds: shallow
      };
    }

    case "SCENES_SCRIPT_DAY_SET_DRAGGING_SCENE_ID": {
      return {
        ...state,
        draggingSceneId: action.meta.sceneId
      };
    }

    case "SCENES_SCRIPT_DAY_UNSELECT_ALL": {
      return {
        ...state,
        selectedSceneIds: []
      };
    }

    case "SCENES_SCRIPT_DAY_MULTI_SELECT_TO": {
      return state;
    }

    default:
      return state;
  }
};

const reorderScenes = (list, sourceIndex, destinationIndex) => {
  const result: any[] = Array.from(list);
  result[sourceIndex].scriptDayOrder = destinationIndex + 1;

  if (sourceIndex > destinationIndex) {
    for (let i = destinationIndex; i < sourceIndex; i++) {
      result[i].scriptDayOrder++;
    }
  }

  if (sourceIndex < destinationIndex) {
    for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
      result[i].scriptDayOrder--;
    }
  }

  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

const reorderDays = (list, sourceIndex, destinationIndex) => {
  const result: any[] = Array.from(list);
  result[sourceIndex].number = result[destinationIndex].number;

  if (sourceIndex > destinationIndex) {
    for (let i = destinationIndex; i < sourceIndex; i++) {
      if (result[i].number) {
        result[i].number++;
      }
    }
  }

  if (sourceIndex < destinationIndex) {
    for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
      if (result[i].number) {
        result[i].number--;
      }
    }
  }

  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

function sortScenes(scenes) {
  scenes.sort((a, b) => {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
  });
}

function sortDays(days) {
  days.sort((a, b) => {
    if (!a.number) return -1;
    if (!b.number) return 1;
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
  });
}

export default reducer;
