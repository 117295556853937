import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function PropMenu(props) {
  const { filmId, equipmentId, categoryId } = props.match.params;
  const { equipment } = props;
  if (equipmentId === "add") {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Equipment</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/equipment-category/${categoryId}/equipment/${equipmentId}/edit`}
            activeClassName="is-active">
            Edit Equipment
          </NavLink>
        </li>
        {equipment?.canAccessScenes &&
          <li>
            <NavLink
              to={`/films/${filmId}/equipment-category/${categoryId}/equipment/${equipmentId}/scenes/`}
              activeClassName="is-active">
              Scenes
            </NavLink>
          </li>
        }
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const { equipmentState } = state;
  return {
    equipment: equipmentState.equipment
  };
}

export default connect(mapStateToProps, {
})(PropMenu);
