import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {
  renderCurrencyField,
  renderInputRangeField,
  renderReactSelect,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField
} from "./../../shared/form-helpers";
import Images from "./../../shared/images";
import required, { maxDecimalValue } from "./../../shared/validation";
import { createBackStageUrl, createMandyUrl, createStarNowUrl } from '../characters/search-url-generator';

let BackgroundCharacterForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    submitting,
    genderEnum,
    backgroundActors,
    mode,
    errors,
    warnings,
    ethnicityTypes,
    submitFailed,
    invalid,
    filmId,
    ageRangeValue,
    genderValue,
    ethnicitiesValue,
    loadingRate,
    rate,
    handleChangeBackgroundCharacterToCharacter
  } = props;

  const thisBackgroundCharacter = props.initialValues || {};
  const readOnly = thisBackgroundCharacter?.readOnly;
  const [mandySearchUrl, setMandySearchUrl] = useState<string>("");
  const [starNowSearchUrl, setStarNowSearchUrl] = useState<string>("");
  const [backStageSearchUrl, setBackStageSearchUrl] = useState<string>("");

  useEffect(() => {
    if (!loadingRate) {
      props.change("dailyBudget", rate);
    }
  }, [rate, loadingRate]);

  const ethnicityOptions = ethnicityTypes?.map((e) => {
    return { label: e.name, value: e.id };
  });

  const backgroundActorOptions = backgroundActors?.map((s) => {
    return { label: s.name, value: s.id };
  });

  thisBackgroundCharacter.ethnicityIds =
    thisBackgroundCharacter.ethnicityIds?.map((s) => {
      return ethnicityOptions?.find((x) => x.value === s);
    });

  const genderOptions = (genderEnum || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  useEffect(() => {
    setMandySearchUrl(createMandyUrl(ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, thisBackgroundCharacter.countryMandyId));
    setStarNowSearchUrl(createStarNowUrl(ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, thisBackgroundCharacter.countryMandyId));
    setBackStageSearchUrl(createBackStageUrl(ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, thisBackgroundCharacter.countryMandyId));
  }, [ageRangeValue,
    genderValue,
    ethnicitiesValue]);


  const onSetAgeRange = (e, min, max) => {
    e.preventDefault();
    props.change("ageRange", { min, max });
  };

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && (
        <h2 className="menu-label">Add Background Character</h2>
      )}
      {mode === "edit" && (
        <h2 className="menu-label">Edit Background Character</h2>
      )}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          {warnings && <Errors errors={warnings} isWarning={true} />}

          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="description"
              label="Description"
              component={renderTextAreaField}
              readOnly={readOnly}
            />

            <Field
              name="quantity"
              label="Quantity"
              type="number"
              parse={(value) => Number(value)}
              component={renderTextField}
              validate={[required, maxDecimalValue]}
              required
              readOnly={readOnly}
            />

            <div className="field-body">
              <Field
                name="gender"
                label="Gender"
                component={renderSingleReactSelect}
                isMulti={false}
                options={genderOptions}
                validate={[required]}
                required
                readOnly={readOnly}></Field>
            </div>

            <Field
              name="ageRange"
              label="Age Range"
              component={renderInputRangeField}
              quickSelection={true}
              setAgeRange={onSetAgeRange}
              readOnly={readOnly}
            />

            <Field
              label="Ethnicity"
              name="ethnicities"
              component={renderReactSelect}
              options={ethnicityOptions}
              readOnly={readOnly}
            />

            <Field
              name="dailyBudget"
              label="Daily Budget"
              component={renderCurrencyField}
              validate={[maxDecimalValue]}
              readOnly={readOnly}
              loading={loadingRate}
            />

            {!thisBackgroundCharacter.actorId && (
              <div>
                <div>
                  <a
                    href={mandySearchUrl}
                    target="_blank">
                    Search Mandy
                  </a>
                  <br />
                  <a
                    href={starNowSearchUrl}
                    target="_blank">
                    Search StarNow
                  </a>
                  <br />
                  <a href={backStageSearchUrl} target="_blank">
                    Search Backstage
                  </a>
                </div>
              </div>
            )}

            <br />
            <Field
              name="backgroundActors"
              label="Background Actors"
              value={thisBackgroundCharacter.actorIds}
              component={renderReactSelect}
              options={backgroundActorOptions}
              readOnly={readOnly}></Field>

            {!readOnly && (
              <div className="buttons mt-2 sticky-buttons">
                <button
                  type="submit"
                  className={
                    submitting
                      ? "button is-primary is-loading"
                      : "button is-primary"
                  }
                  disabled={pristine || submitting}>
                  Save
                </button>
                {mode === "edit" && (
                  <>
                    <button
                      type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => handleDelete(thisBackgroundCharacter)}>
                      Delete Background Character
                    </button>
                    <button
                      type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => handleChangeBackgroundCharacterToCharacter(thisBackgroundCharacter)}>
                      Change to Character
                    </button>
                  </>
                )}
              </div>
            )}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        </div>
        <div className="column">
          <Images
            change={props.change}
            initialValues={props.initialValues}
            readOnly={readOnly}
            filmId={filmId}
          />
        </div>
      </div>
    </>
  );
}

BackgroundCharacterForm = reduxForm({
  form: "editBackgroundCharacterForm",
  enableReinitialize: true
})(BackgroundCharacterForm);

// Decorate with connect to read form values
const selector = formValueSelector('editBackgroundCharacterForm'); // <-- same as form name
const CharacterFormConnect = connect(
  state => {
    // can select values individually
    const ageRangeValue = selector(state, 'ageRange');
    const genderValue = selector(state, 'gender');
    const ethnicitiesValue = selector(state, 'ethnicities');

    return {
      ageRangeValue,
      genderValue,
      ethnicitiesValue
    }
  }
)(BackgroundCharacterForm)

export default CharacterFormConnect