const defaultState = {
  films: [],
  film: {},
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_SINGLE_FILM_OVERVIEW_PENDING": {
      return {
        ...state,
        loading: true,
        overview: { charactersAssignedPercentage: 0, sceneSettingsAssignedPercentage: 0, daysShotPercentage: 0 }
      };
    }

    case "FETCH_SINGLE_FILM_OVERVIEW_FULFILLED": {
      return {
        ...state,
        loading: false,
        overview: action.payload.data
      };
    }

    default:
      return state;
  }
};

export default reducer;
