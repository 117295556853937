import React from "react";
import NumberFormat from "react-number-format";
import CurrencyDisplay from "../../shared/currency-display";
import HelpText from "../../shared/help-text";
import Costume from "./costume";

function Costumes(props) {
  const {
    budget,
    onChangeCostumeBuget,
    onChangeCostumeDays,
    showHideCostumes,
    costumesVisible,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <div>
      <div className="columns subtitle is-4 mt-1">
        <div className="column">
          <h4
            className="subtitle is-4 pointer"
            onClick={() => showHideCostumes(!costumesVisible)}>
            <button className="button is-text is-small">
              {!costumesVisible && <i className="material-icons">add</i>}
              {costumesVisible && <i className="material-icons">remove</i>}
            </button>
            Costumes
          </h4>
        </div>
        <div className="column budget-header-number">
          {!costumesVisible && (
            <CurrencyDisplay
              value={budget.costumesTotal}
            />
          )}
        </div>
      </div>
      {costumesVisible && (
        <>
          <HelpText>
            A dash - in days means it is a costume you are buying or making, so
            does not depend on the number of days.
          </HelpText>
          <table className="table budget-table">
            <thead>
              <tr>
                <th>Name</th>
                <th className="budget-days-column">Days</th>
                <th className="budget-daily-rate-column">Budget</th>
                <th className="has-text-centerd">Quantity</th>
                <th className="has-text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {(budget?.costumes || []).map((costume, i) => {
                return (
                  <Costume
                    key={i}
                    costume={costume}
                    onChangeCostumeBuget={onChangeCostumeBuget}
                    onChangeCostumeDays={onChangeCostumeDays}
                    maxDays={budget.days}
                    totalScenes={budget.totalScenes}
                    hasShootingDays={budget.hasShootingDays}
                    readOnly={readOnly}
                    filmId={filmId}
                    saving={saving}
                  />
                );
              })}
              <tr>
                <td colSpan={4}></td>
                <td className="has-text-right">
                  <CurrencyDisplay
                    value={budget.costumesTotal}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default Costumes;
