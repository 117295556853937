interface RegisterState {
  loading: boolean;
  user: any;
  countries: any[];
  sent: boolean;
}

const defaultState: RegisterState = {
  loading: false,
  user: {},
  countries: [],
  sent: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "CONTACT_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        sent: false
      };
    }

    case "CONTACT_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        sent: true
      };
    }

    case "CONTACT_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "CONTACT_CLEAR": {
      return {
        ...state,
        user: {},
        sent: false,
        errors: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
