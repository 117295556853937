import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "./../../../shared/loader";
import confirm from "../../../shared/modal/confirm";
import { fetchCharactersInSceneNotOffScreen } from "./../characters/ducks/actions";
import { fetchBackgroundCharactersInScene } from "./../background-characters/ducks/actions";
import {
  clearRedirect,
  deleteShot,
  fetchFilmFramesPerSecond,
  fetchShot,
  updateShot,
  uploadImagesAndSaveShot
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function EditShot(props) {
  const { filmId, sceneId, shotId } = props.match.params;
  const {
    fetchShot,
    updateShot,
    deleteShot,
    loadingShot,
    shot,
    errors,
    fetchCharactersInSceneNotOffScreen,
    fetchBackgroundCharactersInScene,
    cameraMovementEnum,
    uploadImagesAndSaveShot,
    backgroundCharacters
  } = props;

  useEffect(() => {
    fetchShot(filmId, sceneId, shotId);
    fetchFilmFramesPerSecond(filmId);
    fetchCharactersInSceneNotOffScreen(filmId, sceneId);
    fetchBackgroundCharactersInScene(filmId, sceneId);
  }, [filmId, sceneId, shotId]);

  useEffect(() => {
    if (shot && shot.movements && cameraMovementEnum) {

      let movements: any[] = [];
      shot.movements.forEach(movementId => {
        const movment = cameraMovementEnum.find((c) => c.value === (movementId?.value ? movementId.value : movementId));
        if (movment != null) {
          movements.push({ value: movementId, label: movment.name })
        } else {
          alert("Error!");
        }
      });
      shot.movements = movements;
    }
  }, [shot, cameraMovementEnum]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/scenes/${sceneId}/shots`;
      return <Redirect to={url} />;
    }
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderShot(shot) {
    return (
      <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/scenes/:sceneId/shots/:shotId/edit"
      loading={loadingShot}>
        <Form
          errors={errors}
          mode="edit"
          filmId
          sceneId
          shotId
          initialValues={shot}
          characters={props.characters}
          loading={loadingShot}
          onSubmit={onUpdateShot}
          handleDelete={onDeleteShot}
          backgroundCharacters={backgroundCharacters}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateShot(shot) {
    sanitiseDataTypes(shot);
    shot.movements = shot.movements?.map((m) => m.value || m);

    const files = shot.pictures || [];

    if ((files && files.length) > 0 || (shot.images && shot.images.length > 0)) {
      uploadImagesAndSaveShot(shot, files, false, filmId, sceneId);
    } else {
      updateShot(filmId, sceneId, shot);
    }
  }

  function onDeleteShot(shot) {
    confirm(() => deleteShot(filmId, sceneId, shot), shot?.number);
  }

  function sanitiseDataTypes(shot) {
    shot.number = +shot.number;
    shot.shotSize = shot.shotSize ? +shot.shotSize : null;
    shot.lensFocalLength = shot.lensFocalLength ? +shot.lensFocalLength : null;
    shot.frameRate = shot.frameRate ? +shot.frameRate : null;
  }

  return shot && !loadingShot ? renderShot(shot) : renderLoading();
}

function mapStateToProps(state) {
  const { shotsState, scenesState, commonState } = state;
  return {
    shots: shotsState.shots,
    shot: shotsState.shot,
    loadingShot: shotsState.loadingShot,
    shotSizeEnum: commonState.shotSizeEnum,
    cameraMovementEnum: commonState.cameraMovementEnum,
    focalLengths: commonState.focalLengths,
    frameRates: commonState.slowMotionFrameRates,
    characters: scenesState.characterState.characters,
    backgroundCharacters: scenesState.backgroundCharacterState.backgroundCharacters,
    errors: shotsState.errors,
    redirect: shotsState.redirect
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchShot,
    updateShot,
    deleteShot,
    fetchFilmFramesPerSecond,
    fetchCharactersInSceneNotOffScreen,
    fetchBackgroundCharactersInScene,
    clearRedirect,
    uploadImagesAndSaveShot
  })(EditShot)
);
