import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export function clearForm() {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_PASSWORD_CLEAR_FORM",
    });
  };
}

export function changePassword(user) {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: getApiClient().put(`/account/ChangePassword`, user),
      meta: { user }
    }).then((response) => {      
      dispatch(showInfo(`Successfully changed password`));
    })
    .catch((response) => {
      dispatch(showError(`Error changing password`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}