import React, { useEffect } from "react";
import { Link, Prompt } from "react-router-dom";
import { reduxForm } from "redux-form";
import Map from "../../../shared/Map/Map";
import Errors from "./../../../shared/errors";
import What3Words from "./w3w";
import CanAccessLink from "../../../shared/can-access-link";

function ShootingDayLocationsForm(props) {
  const {
    pristine,
    errors,
    filmId,
    canAccessShootingLocations
  } = props;
  const locations = props.initialValues.locations || {};

  const hospitalMarkers: any[] = locations.locations.filter((location) => location.nearestHospitalAddress).map((location) => {
    return {
      position: {
        lat: location.nearestHospitalAddress.latitude,
        lng: location.nearestHospitalAddress.longitude
      }
    };
  });

  let parkingMarkers: any[] = [];
  locations.locations.forEach((location) => {
    if (location.parkingAddress) {
      parkingMarkers.push({
        position: {
          lat: location.parkingAddress.latitude,
          lng: location.parkingAddress.longitude
        }
      });
    }
  });

  const locationMarkers: any[] = locations.locations.map((location) => {
    return {
      number: location.number,
      position: {
        lat: location.address.latitude,
        lng: location.address.longitude
      }
    };
  });

  return (
    <div>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      {(!locations.locations || locations.locations.length === 0) && (
        <div className="notification">No Locations assigned yet</div>
      )}
      {locations.locations && locations.locations.length > 0 && (
        <div>
          <Map
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            locationMarkers={locationMarkers}
            hospitalMarkers={hospitalMarkers}
            parkingMarkers={parkingMarkers}
            setSelectedHospital={() => { }}
            setSelectedCarPark={() => { }}></Map>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>No.</th>
                <th>Address</th>
                <th>Parking</th>
                <th>Nearest Hospital</th>
              </tr>
            </thead>
            <tbody>
              {(locations.locations || []).map((location, idx) => {
                return (
                  <tr key={idx}>
                    <td><CanAccessLink to={`/films/${filmId}/locations/${location.locationId}/edit`} canAccess={canAccessShootingLocations}>{location.number}</CanAccessLink></td>
                    <td>
                      <p><CanAccessLink to={`/films/${filmId}/locations/${location.locationId}/edit`} canAccess={canAccessShootingLocations}>{location.name}</CanAccessLink></p>
                      {location.address.addressLines.map((line, lineIdx) => {
                        return <p key={lineIdx}>{line}</p>;
                      })}
                      <p>{location.address.postCode}</p>
                      {location.address.whatThreeWords && (
                        <What3Words what3Words={location.address.whatThreeWords} />
                      )}
                    </td>
                    <td>
                      <p>{location.parkingDetails}</p>
                      {location.parkingAddress &&
                        location.parkingAddress.addressLines.map(
                          (line, lineIdx) => {
                            return <p key={lineIdx}>{line}</p>;
                          }
                        )}
                      <p>
                        {location.parkingAddress &&
                          location.parkingAddress.postCode}
                      </p>
                      {location.parkingAddress &&
                        location.parkingAddress.whatThreeWords && (
                          <What3Words what3Words={location.parkingAddress.whatThreeWords} />
                        )}
                    </td>
                    <td>
                      {location.nearestHospitalAddress && <>{location.nearestHospitalAddress.addressLines.map(
                        (line, lineIdx) => {
                          return <p key={lineIdx}>{line}</p>;
                        }
                      )}
                        <p>{location.nearestHospitalAddress.postCode}</p>
                        {location.nearestHospitalAddress.whatThreeWords && (
                          <p>
                            <What3Words what3Words={location.nearestHospitalAddress.whatThreeWords} />
                          </p>
                        )}
                      </>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default reduxForm({
  form: "shootingDayCateringForm",
  enableReinitialize: true
})(ShootingDayLocationsForm);
