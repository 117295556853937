import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, matchPath, NavLink, Switch, useLocation, withRouter } from "react-router-dom";
import { toast } from "react-toastify"
import { ErrorBoundary } from "react-error-boundary";
import "react-toastify/dist/ReactToastify.css";
import { version } from "./../../package.json";
import { renderNotification } from "./../notifications/render-notification";
import CookieConsent from "../shared/cookie-consent";
import FilmsList from "./../films/index";
import {
  fetchApiVersion,
  fetchEnums,
  fetchLookups,
  logout,
  dismissMessage,
  dismissAllMessages,
  setActiveOnboarding,
  setActiveOnboardingStep,
  setOnboardingComplete,
  updateOnboardingComplete,
  fetchUserOnboarding,
  logError
} from "./ducks/actions";
import AppRoutes from "./routes";
import SessionExpiredModal from "../shared/modal/session-expired-modal";
import { getAppInsights } from "./telemetry-service";
import TelemetryProvider from "./telemetry-provider";
import auth from "../shared/auth";
import QuestionMarkIcon from "../films/icons/question-mark";
import LightbulbIcon from "../films/icons/lightbulb";
import IconWithBadge from "../shared/icon-with-badge";
import confirm from "../shared/modal/confirm";
import { PrivateRoute } from "../shared/private-route";
import Joyride from "react-joyride";
import OnboardingTooltip from "../shared/onboarding/onboarding-tooltip";
import HelpPanel from "./help-panel/help-panel";
import UnauthenticatedMenu from "./unauthticated-menu";
import AdminMenu from "./admin-menu";
import UserMessages from "./user-messages";
import { useDocumentTitle } from "../shared/setDocumentTitle";
import TopMenu from "./top-menu";
import BoxWraper from "../account/box-wrapper";
import WarningText from "../shared/warning-text";
import ErrorText from "../shared/error-text";
import { ErrorFallback } from "../shared/error-fallback";

const App = (props) => {
  const {
    fetchApiVersion,
    fetchEnums,
    fetchLookups,
    notification,
    history,
    logout,
    authenticated,
    userFilm,
    dismissMessage,
    dismissAllMessages,
    activeOnboarding,
    activeOnboardingStep,
    setActiveOnboarding,
    setActiveOnboardingStep,
    setOnboardingComplete,
    updateOnboardingComplete,
    filmOnboardingSteps,
    completedUserOnboarding,
    fetchUserOnboarding,
    pageSections,
    onboardingComplete,
    generalUserMessages,
    userName,
    userFirstName,
    userLastName,
    userEmailAddress,
    profileBackgroundColor,
    logError
  } = props;

  const [mobileMenu, setMobileMenu] = useState(false);
  const [helpActive, setHelpActive] = useState(false);
  const [whatsNewId, setWhatsNewId] = useState<string | null>(null);
  const [helpSubMenuActive, setHelpSubMenuActive] = useState<any>();
  const location = useLocation();
  useEffect(() => {
    toast.configure();
    fetchApiVersion();
  }, [toast, fetchApiVersion]);

  useEffect(() => {
    if (authenticated) {
      // There is a problem, each response is rending the component again
      // Need to combine somehow
      console.log("Fetch Enums");
      fetchEnums();

      //fetchLookups();
    }
  }, [authenticated]);

  useEffect(() => {
    if (props.genderEnum?.length) {
      // There is a problem, each response is rending the component again
      // Need to combine somehow
      //fetchEnums();
      console.log("Fetch Lookups");
      fetchLookups();
    }
  }, [props.genderEnum?.length]);

  useEffect(() => {
    if (authenticated) {
      fetchUserOnboarding();
    }
  }, [fetchUserOnboarding, authenticated]);

  useEffect(() => {
    if (completedUserOnboarding) {
      updateOnboardingComplete(completedUserOnboarding, false);
    }

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      if (Notification.requestPermission) {
        Notification.requestPermission();
      }
    }
  }, []);

  // This scrolls to the right to bring the new blade into the viewport
  history.listen((location, action) => {
    let pageFound = false;
    if (pageSections) {
      pageSections.forEach((pageSection: any) => {
        if (matchPath(location.pathname, { path: pageSection.path, exact: true })) {
          document.title = "Filmanize - " + pageSection.name;
          pageFound = true;
        }
      });
    }

    if (!pageFound) {
      document.title = "Filmanize";
    }

    if (location === "/films") {
      return;
    }

    setHelpActive(false);
  });

  const onLogout = () => {
    auth.logout();
    logout(props.history);
  };

  if (notification) {
    renderNotification(notification);
  }

  const onDismissMessage = (messageId) => {
    dismissMessage(messageId);
  };

  const onDismissAllMessages = () => {
    const filmId = getFilmId();
    dismissAllMessages(filmId);
  };

  const onMessageLinkClick = (link: string) => {
    if (link.includes("change-log")) {
      setHelpActive(true);
      const slashIndex = link.lastIndexOf("/");
      const id = link.substring(slashIndex + 1, link.length);
      setWhatsNewId(id);
      setHelpSubMenuActive("whats-new");
    }
  }

  const getFilmId = () => {
    let re = new RegExp("/films/([^/]+)/");
    let matches = props.location.pathname.match(re);
    if (matches == null) {
      return null;
    }
    if (matches.length > 1) {
      return matches[1];
    }

    return null;
  };

  function allLookupsLoaded() {
    if (!authenticated) {
      return true;
    }

    return (
      props.aspectRatios?.length &&
      props.slowMotionFrameRates?.length &&
      props.resolutions?.length &&
      props.focalLengths?.length &&
      props.genderEnum?.length &&
      props.interiorExteriorEnum?.length &&
      props.dayNightEnum?.length &&
      props.shotSizeEnum?.length &&
      props.cameraMovementEnum?.length &&
      props.filmTypeEnum?.length &&
      props.filmStatusEnum?.length &&
      props.dietaryRequirementEnum?.length &&
      props.frameRates?.length &&
      props.skinColors?.length &&
      props.ethnicityTypes?.length &&
      props.userAccessEnum?.length &&
      props.userName
    );
  }

  if (!allLookupsLoaded()) {
    console.log("Lookups not loaded");
    return null;
  }

  const allMessages = [...(generalUserMessages || []), ...(userFilm?.messages || [])];

  allMessages.sort((a, b) => {
    if (a.added > b.added) return -1;
    else if (a.added > b.added) return 1;
    return 0;
  });

  const burgerClick = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleGuideResetClick = () => {
    let hasOnboarding = false;
    pageSections.forEach((pageSection) => {
      if (matchPath(location.pathname, { path: pageSection.path, exact: true })) {
        if (pageSection.hasOnboarding) {
          hasOnboarding = true;
          if (onboardingComplete === null) {
            setOnboardingComplete([]);
          } else {
            let copy = [...onboardingComplete];
            const index = copy.indexOf(pageSection.name)
            if (index >= 0) {
              copy.splice(index, 1);
              setOnboardingComplete(copy);
            }
          }
        }
      }
    });

    if (!hasOnboarding) {
      confirm(null, null, "No help guides are available for this page yet. Please checkout the FAQs or contact us with your question.", "Help Guides");
    } else {
      setHelpActive(false);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action } = data;

    if (action === "close") {
      debugger;
      updateOnboardingComplete(activeOnboarding);
      setActiveOnboarding(null);
    }
  };

  const handleHelpButtonClick = () => {
    setHelpActive(!helpActive);

    if (!helpActive) {
      setHelpSubMenuActive(false);
    }
  };

  const onLogError = (error: Error, info) => {
    logError(location.pathname, error.message, error.stack, info.componentStack);
  };


  let appInsights = null;

  return (
    // <TelemetryProvider
    //   instrumentationKey={props.applicationInsightsKey}
    //   after={() => {
    //     appInsights = getAppInsights();
    //   }}
    // >
    <>

      {props.authFailed && false && <SessionExpiredModal />}
      <TopMenu history={history}
        authenticated={authenticated}
        userFilm={userFilm}
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        handleGuideResetClick={handleGuideResetClick}
        handleHelpButtonClick={handleHelpButtonClick}
        onDismissAllMessages={onDismissAllMessages}
        allMessages={allMessages}
        onDismissMessage={onDismissMessage}
        onMessageLinkClick={onMessageLinkClick}
        burgerClick={burgerClick}
        onLogout={onLogout}
        userName={userName}
        userFirstName={userFirstName}
        userLastName={userLastName}
        userEmailAddress={userEmailAddress}
        profileBackgroundColor={profileBackgroundColor}
      />
      <button
        className={`help-panel-bg-close ${helpActive ? "active" : ""}`}
        type="button"
        onClick={() => setHelpActive(false)}></button>

      <HelpPanel
        helpActive={helpActive}
        helpSubMenuActive={helpSubMenuActive}
        setHelpSubMenuActive={setHelpSubMenuActive}
        handleHelpButtonClick={handleHelpButtonClick}
        setHelpActive={setHelpActive}
        setOnboardingComplete={setOnboardingComplete}
        whatsNewId={whatsNewId}
      />
      <Switch>
        <PrivateRoute exact path="/films" component={FilmsList} />
        <section className="section main-content">
          <ErrorBoundary fallback={ErrorFallback}
            onError={onLogError}>
            <AppRoutes />
          </ErrorBoundary>
        </section>
      </Switch>
      <CookieConsent />
      <footer className="footer">
        <div>
          <div className="content has-text-centered">
            <p>Copyright &copy; Film Tools Ltd 2020. All Rights Reserved. Company Registration 12507793. VAT GB 345 1054 29.</p>
          </div>
        </div>
      </footer>
      {/* </TelemetryProvider> */}
      {activeOnboarding === "Films" &&
        activeOnboardingStep >= 2 &&
        activeOnboardingStep < filmOnboardingSteps.length ? (
        <Joyride
          steps={filmOnboardingSteps}
          stepIndex={activeOnboardingStep}
          continuous={true}
          scrollToFirstStep={true}
          tooltipComponent={OnboardingTooltip}
          callback={handleJoyrideCallback}
        />
      ) : null}

    </>
  );
};

function mapState(state, props) {
  const { commonState, notificationState, errorState, loginState } = state;

  if (!commonState.apiVersion) {
    return {};
  }


  const {
    aspectRatios,
    slowMotionFrameRates,
    resolutions,
    focalLengths,
    genderEnum,
    interiorExteriorEnum,
    dayNightEnum,
    shotSizeEnum,
    cameraMovementEnum,
    filmTypeEnum,
    filmStatusEnum,
    dietaryRequirementEnum,
    userAccessEnum,
    frameRates,
    skinColors,
    ethnicityTypes
  } = commonState;

  return {
    apiVersion: commonState.apiVersion.version,
    userName: commonState.user?.name,
    userFirstName: commonState.user?.firstName,
    userLastName: commonState.user?.lastName,
    userEmailAddress: commonState.user?.emailAddress,
    profileBackgroundColor: commonState.user?.profileBackgroundColor,
    generalUserMessages: commonState.generalUserMessages,
    notification: notificationState,
    aspectRatios,
    slowMotionFrameRates,
    resolutions,
    focalLengths,
    genderEnum,
    interiorExteriorEnum,
    dayNightEnum,
    shotSizeEnum,
    cameraMovementEnum,
    filmTypeEnum,
    filmStatusEnum,
    dietaryRequirementEnum,
    frameRates,
    skinColors,
    ethnicityTypes,
    userAccessEnum,
    authFailed: errorState.authFailed,
    authenticated: commonState.authenticated,
    userFilm: state.filmsState?.userFilm,
    activeOnboarding: state.commonState.activeOnboarding,
    activeOnboardingStep: state.commonState.activeOnboardingStep,
    filmOnboardingSteps: state.filmsState.filmOnboardingSteps,
    userSettingsState: state.commonState.userSettingsState,
    completedUserOnboarding: state.commonState.user?.completedUserOnboarding,
    feedbackFormErrors: state.commonState.feedbackFormErrors,
    featureRequests: state.commonState.featureRequests,
    featureRequestLoading: state.commonState.featureRequestLoading,
    featureRequestErrors: state.commonState.featureRequestErrors,
    pageSections: state.commonState.pageSections,
    onboardingComplete: state.commonState.onboardingComplete
  };
}

export default withRouter(
  connect(mapState, {
    fetchApiVersion,
    fetchEnums,
    fetchLookups,
    logout,
    dismissMessage,
    dismissAllMessages,
    setActiveOnboarding,
    setActiveOnboardingStep,
    setOnboardingComplete,
    updateOnboardingComplete,
    fetchUserOnboarding,
    logError
  })(App)
);
