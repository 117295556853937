export const prices = [
    {
        company: "Filmanize",
        price: 12
    },
    {
        company: "Filmustage",
        price: 17.14
    },
    {
        company: "Set Hero",
        price: 17.14
    },
    {
        company: `Producer`,
        price: 22.56
    },
    {
        company: "Studio Binder",
        price: 26.17
    },
    {
        company: "Dramatify",
        price: 27
    },
    {
        company: "Celtx",
        price: 58.55
    },
    {
        company: "Movie Magic",
        price: 71.87
    },
    {
        company: "Yamdu",
        price: 186.38
    }
];