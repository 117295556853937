import getApiClient from "../../../../shared/api";
import { showInfo, showError, clearNotifications } from "../../../../notifications/ducks/actions";

export function fetchShootingDayBackgroundActors(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_BACKGROUND_ACTORS",
      payload: getApiClient().get(`/film/${filmId}/ShootingDay/${shootingDayId}/BackgroundActors`),
    });
  };
}

export function updateShootingDayBackgroundActor(filmId, shootingDayId, actors) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SHOOTING_DAY_BACKGROUND_ACTORS",
      payload: getApiClient().put(`/film/${filmId}/ShootingDay/${shootingDayId}/BackgroundActors`, actors),
      meta: { actors },
    })
      .then((response) => {
        dispatch(showInfo(`Updated Shooting Day Background Actors`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day Background Actors`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
