import React, { useState, useEffect } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import QuestionMarkIcon from "../../films/icons/question-mark";
import PlayIcon from "../../films/icons/play";
import LightbulbIcon from "../../films/icons/lightbulb";
import confirm from "../../shared/modal/confirm";
import SpeechBubbleIcon from "../../films/icons/speech-bubble";
import WhatsNewIcon from "../../films/icons/whats-new";
import LeaveFeedbackContainer from "../leave-feedback/container";
import ContactContainer from "../../static/contact/container";
import { clearContact } from "../../static/contact/ducks/actions";
import { connect } from "react-redux";
import HelpPanelButton from "./help-panel-button";
import HelpPanelLink from "./help-panel-link";
import packageInfo from "../../../package.json";
import './help-panel.scss';
import DiscordIcon from "../../shared/icons/socials/discord";
import WhatsNewContainer from "./whats-new/whats-new-container";
import FaqContainer from "./faqs/faqs-container";

const HelpPanel = (props) => {
  const {
    helpActive,
    helpSubMenuActive,
    setHelpSubMenuActive,
    handleHelpButtonClick,
    setHelpActive,
    setOnboardingComplete,
    clearContact,
    pageSections,
    onboardingComplete, 
    apiVersion,
    whatsNewId,
    userFirstName,
    userLastName,
    userEmailAddress
  } = props;

  useEffect(() => {
    if (helpSubMenuActive === "contact") {
      clearContact();
    }
  }, [helpSubMenuActive, clearContact]);

  const location = useLocation();

  useEffect(() => {
    setSelectedPageSection(null);
  }, [location]);

  const [selectedPageSection, setSelectedPageSection] = useState<any>();

  const onAskAQuestion = () => {
    setHelpSubMenuActive("contact")
  };
  
  const HelpPanelFAQ = ({
    question = null,
    children = null
  }: {
    question?: any;
    children?: any;
  }) => {
    const [faqActive, setFaqActive] = useState(false);

    const handleButtonClick = () => {
      setFaqActive(!faqActive);
    };

    return (
      <div className={`help-panel-faq ${faqActive ? "active" : ""}`}>
        <button
          className="help-panel-faq__question"
          type="button"
          onClick={handleButtonClick}>
          <div>{question}</div>
          <div className="help-panel-faq__question-icon"></div>
        </button>
        <div className="help-panel-faq__answer">{children}</div>
      </div>
    );
  };

  return (
    <div
      className={`help-panel ${helpActive ? "active" : ""} ${
        helpSubMenuActive ? "sub-menu-active" : ""
      }`}>
      <div className="help-panel__header">
        <div className="help-panel__header-left">
          <button type="button" onClick={() => setHelpSubMenuActive(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 16.1">
              <path d="M14.8 7.3H2.6l5.7-5.7c.3-.3.3-.8 0-1.1S7.5.2 7.2.5l-7 7c0 .1-.1.2-.1.3-.1.1-.1.2-.1.3s0 .2.1.3c0 .1.1.2.2.2l7 7c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L2.6 8.8h12.2c.4 0 .8-.3.8-.8s-.4-.7-.8-.7z" />
            </svg>
          </button>
          <h2>Help</h2>
        </div>
        <button
          type="button"
          className="help-panel__close"
          onClick={handleHelpButtonClick}></button>
      </div>
      <div className="help-panel__body-wrap">
        <div className="help-panel__body">
          <HelpPanelLink
            buttonIcon={<PlayIcon />}
            buttonText="Video Tutorials"
            buttonLink='https://www.youtube.com/watch?v=eOror_QjPZM&list=PLEGzR_hAtu4Jxatzc52ewuIuzgUHZIAKU'
            onButtonClick={() => setHelpActive(false)}>
          </HelpPanelLink>

          <HelpPanelButton
            buttonIcon={<QuestionMarkIcon />}
            buttonText="Frequently Asked Questions"
            onButtonClick={() => setHelpSubMenuActive("faq")}>
            <div>Sub Menu</div>
          </HelpPanelButton>

          <HelpPanelButton
            buttonIcon={<LightbulbIcon />}
            buttonText="Turn on our guided onboarding"
            onButtonClick={() => {
              //TODO: Use same code from src/app/index.tsx              
              let hasOnboarding = false;
              pageSections.forEach((pageSection) => {
                if (matchPath(location.pathname, { path: pageSection.path, exact: true })) {
                  if (pageSection.hasOnboarding) {
                    hasOnboarding = true;
                    if (onboardingComplete === null) {
                      setOnboardingComplete([]);
                    } else {
                      let copy = [...onboardingComplete];
                      const index = copy.indexOf(pageSection.name)
                      if (index >= 0) {            
                        copy.splice(index, 1);
                        setOnboardingComplete(copy);
                      }
                    }
                  }
                }
              });

              if (!hasOnboarding) {
                confirm(null, null, "No help guides are available for this page yet. Please checkout the FAQs or contact us with your question.", "Help Guides");
              } else {
                setHelpActive(false);
              }

            }}></HelpPanelButton>

          <HelpPanelButton
            buttonIcon={<QuestionMarkIcon />}
            buttonText="Ask a question"
            onButtonClick={() =>
              setHelpSubMenuActive("contact")
            }></HelpPanelButton>

          <HelpPanelButton
            buttonIcon={<SpeechBubbleIcon />}
            buttonText="Feature Request"
            onButtonClick={() =>
              setHelpSubMenuActive("feedback")
            }></HelpPanelButton>

          <HelpPanelButton
            buttonIcon={<WhatsNewIcon />}
            buttonText="What's New"
            onButtonClick={() =>
              setHelpSubMenuActive("whats-new")
            }>
          </HelpPanelButton>

          <HelpPanelLink
            buttonIcon={<DiscordIcon width={20} />}
            buttonText="Join the discussion on Discord"
            buttonLink='https://discord.gg/4G4E87Y5E4'
            onButtonClick={() => setHelpActive(false)}>
          </HelpPanelLink>
          <div className="help-panel-version">
            Version API: {apiVersion} &nbsp; UI: {packageInfo.version}
          </div>
        </div>

        {helpSubMenuActive === "faq" && <div
          className={`help-panel-sub-menu ${
            helpSubMenuActive === "faq" ? "active" : ""
          }`}>
          <div className="help-panel-sub-menu__header">
            <div className="help-panel-sub-menu__icon">
              <QuestionMarkIcon />
            </div>
            <h3>Frequently Asked Questions</h3>
          </div>
          <FaqContainer onAskAQuestion={onAskAQuestion} selectedPageSection={selectedPageSection} setSelectedPageSection={setSelectedPageSection} />
        
        
{/* 
          <HelpPanelFAQ question="How do I…?">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
              vehicula neque velit, porta suscipit nulla efficitur vitae. Aenean
              tincidunt magna nec lorem volutpat congue. Aliquam tortor arcu,
              dignissim non leo a, aliquam ultrices dolor. Aenean maximus
              ullamcorper tortor. Curabitur pulvinar posuere placerat. Donec
              sapien elit.
            </p>
          </HelpPanelFAQ> */}

        </div>
        }

        {helpSubMenuActive === "contact" && <div
          className={`help-panel-sub-menu ${
            helpSubMenuActive === "contact" ? "active" : ""
          }`}>
          <div className="help-panel-sub-menu__header">
            <div className="help-panel-sub-menu__icon">
              <QuestionMarkIcon />
            </div>
            <h3>Ask a question</h3>
          </div>
          <ContactContainer 
              isOnWhite={true} 
              showPageSections={true} 
              inApp={true} 
              userFirstName={userFirstName}
              userLastName={userLastName}
              userEmailAddress={userEmailAddress} 
              selectedPageSection={selectedPageSection} />
        </div>}

        {helpSubMenuActive === "feedback" && <div
          className={`help-panel-sub-menu ${
            helpSubMenuActive === "feedback" ? "active" : ""
          }`}>
          <div className="help-panel-sub-menu__header">
            <div className="help-panel-sub-menu__icon">
              <SpeechBubbleIcon />
            </div>
            <h3>Feature Request</h3>
          </div>

          <LeaveFeedbackContainer />
          
        </div> }

        {helpSubMenuActive === "whats-new" && <div
          className={`help-panel-sub-menu ${
            helpSubMenuActive === "whats-new" ? "active" : ""
          }`}>
          <div className="help-panel-sub-menu__header">
            <div className="help-panel-sub-menu__icon">
              <WhatsNewIcon />
            </div>
            <h3>What's New</h3>
          </div>
          <WhatsNewContainer id={whatsNewId} />      
        </div>}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { contactState, commonState } = state;
  return {
    user: contactState.user,
    errors: contactState.errors,
    loading: contactState.loading,
    sent: contactState.sent,
    pageSections: state.commonState.pageSections,
    onboardingComplete: state.commonState.onboardingComplete,
    apiVersion: commonState.apiVersion?.version,
    userFirstName: commonState.user?.firstName,
    userLastName: commonState.user?.lastName,
    userEmailAddress: commonState.user?.emailAddress,
  };
}

export default connect(mapStateToProps, {
  clearContact
})(HelpPanel);
