import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchMakeupsInScene } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import { Link } from "react-router-dom";

function ListMakeupsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchMakeupsInScene, loading, sceneMakeups, errors, readOnly } = props;

  useEffect(() => {
    fetchMakeupsInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(sceneMakeups, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List sceneMakeups={sceneMakeups} filmId={filmId} sceneId={sceneId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <Link
          to={`/films/${filmId}/scenes/${sceneId}/makeup/add`}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Makeup
        </Link>}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneMakeups, filmId, sceneId);
}

function mapStateToMakeups(state) {
  return {
    sceneMakeups: state.scenesState.makeupState.makeups,
    loading: state.scenesState.makeupState.loading,
    errors: state.scenesState.makeupState.errors,
    readOnly: state.scenesState.makeupState.readOnly
  };
}

export default connect(mapStateToMakeups, {
  fetchMakeupsInScene
})(ListMakeupsContainer);
