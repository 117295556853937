import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import {
  fetchShootingDayBackgroundActors,
  updateShootingDayBackgroundActor
} from "./ducks/actions";
import EditActorsListForm from "./edit-form";

function ListShootingDayActorsContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchShootingDayBackgroundActors,
    updateShootingDayBackgroundActor,
    backgroundActors,
    loading,
    errors,
    readOnly,
    canAccessBackgroundActors
  } = props;

  useEffect(() => {
    fetchShootingDayBackgroundActors(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function renderLoading() {
    return <Loader />;
  }

  function onUpdateShootingDayBackgroundActors(actors) {
    console.log(actors);
    updateShootingDayBackgroundActor(filmId, shootingdayId, actors);
  }

  function renderShootingDays(backgroundActors, filmId) {
    return (
      <div className="blade-content wider">
        <p className="menu-label">Background Actors</p>
        {!backgroundActors?.length && (
          <div className="notification">No background actors required</div>
        )}
        {!!backgroundActors?.length && (
          <EditActorsListForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            initialValues={{ backgroundActors: backgroundActors }}
            loading={loading}
            onSubmit={onUpdateShootingDayBackgroundActors}
            readOnly={readOnly}
            canAccessBackgroundActors={canAccessBackgroundActors}
            {...props}
          />
        )}
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(backgroundActors, filmId);
}

function mapStateToProps(state) {
  return {
    shootingDay: state.shootingdaysState.rootState.shootingday,
    readOnly: state.shootingdaysState.backgroundActorsState.readOnly,
    canAccessBackgroundActors: state.shootingdaysState.backgroundActorsState.canAccessBackgroundActors,
    backgroundActors: state.shootingdaysState.backgroundActorsState.backgroundActors,
    loading: state.shootingdaysState.backgroundActorsState.loading,
    errors: state.shootingdaysState.backgroundActorsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayBackgroundActors,
  updateShootingDayBackgroundActor
})(ListShootingDayActorsContainer);
