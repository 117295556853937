import React from "react";
import "./card-badge.scss";

const CardBadge = (props) => {
  const { count, severity } = props;

  let messageClass;
  if (severity === 3) {
    //messageCount = errorMessageCount;
    messageClass = "error";
  } else if (severity === 2) {
    //messageCount = warningMessageCount;
    messageClass = "warning";
  } else if (severity === 1) {
    //messageCount = infoMessageCount;
    messageClass = "info";
  }

  return (
      <div
        className={`badge ${messageClass} ${
          count === null || count === 0 ? "no-notificatons" : ""
        }`}>
        {count ?? ""}
      </div>    
  );
};

export default CardBadge;
