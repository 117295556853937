import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchSceneShootingDays } from "./ducks/actions";
import List from "./list";

function ListPropsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchSceneShootingDays, loading, shootingDays, errors } = props;

  useEffect(() => {
    fetchSceneShootingDays(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(shootingDays, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List shootingDays={shootingDays} filmId={filmId} sceneId={sceneId} />        
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(shootingDays, filmId, sceneId);
}

function mapStateToProps(state) {
  return {
    shootingDays: state.scenesState.shootingDaysState.shootingDays,
    loading: state.scenesState.shootingDaysState.loading,
    errors: state.scenesState.shootingDaysState.errors
  };
}

export default connect(mapStateToProps, {
  fetchSceneShootingDays
})(ListPropsContainer);
