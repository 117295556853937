const CkEditorContent = (props) => {
  const { content, className } = props;

  const createMarkup = (content) => {
    return { __html: content };
  }

  return (
    <span className={`ckeditor-content ${className}`} dangerouslySetInnerHTML={createMarkup(content)}></span>
  )
}

export default CkEditorContent