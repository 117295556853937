import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { fetchCharacters } from "./../../characters/ducks/actions";
import {
  addCharacter,
  clearCharacterInScene,
  clearRedirect,
} from "./ducks/actions";
import Form from "./form";

function AddCharacter(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    fetchCharacters,
    addCharacter,
    clearCharacterInScene,
    errors,
    sceneCharacters,
    characters,
    character
  } = props;

  const [fromBreakdown, setFromBreakdown] = useState<boolean>(false);
  useEffect(() => {
    setFromBreakdown(props.location?.state?.fromBreakdown);
  }, [props.location?.state?.fromBreakdown]);

  useEffect(() => {
    fetchCharacters(filmId);
    clearCharacterInScene();
  }, []);

  if (props.redirect) {
    props.clearRedirect();
    if (props.redirect.to == "list") {
      const url = `/films/${filmId}/scenes/${sceneId}/characters`;
      return <Redirect to={url} />;
    } else if (props.redirect.to == "breakdown") {
      const url = `/films/${filmId}/breakdown/scenes/${sceneId}`;
      return <Redirect to={url} />;
    }
  }

  function onAddCharacter(character) {
    sanitiseDataTypes(character);
    addCharacter(filmId, sceneId, character, fromBreakdown);
  }

  function sanitiseDataTypes(character) {
  }

  const sceneCharacterIds = sceneCharacters.map(p => p.id);
  const nonSceneCharacters = characters.filter((prop: any) => !sceneCharacterIds.includes(prop.id));

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/scenes/:sceneId/characters/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        characterId={null}
        initialValues={character}
        characters={nonSceneCharacters}
        loading={false}
        onSubmit={onAddCharacter}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    characters: state.charactersState.rootState.characters,
    sceneCharacters: state.scenesState.characterState.characters,
    character: state.scenesState.characterState.character,
    errors: state.scenesState.characterState.errors,
    redirect: state.scenesState.characterState.redirect,
  };
}

export default connect(mapStateToProps, {
  addCharacter,
  fetchCharacters,
  clearCharacterInScene,
  clearRedirect,
})(AddCharacter);
