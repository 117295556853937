interface UsersState {
  loading: boolean;
  users: any[];  
}

const defaultState: UsersState = {
  loading: false,
  users: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
  
    case "FETCH_USERS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_USERS_FULFILLED": {
      return {
        ...state,
        users: action.payload.data.users,        
        loading: false
      };
    }

    case "FETCH_USERS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }
 
    default:
      return state;
  }
};

export default reducer;
