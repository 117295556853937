import { useEffect } from "react";
import { fetchBackgroundCharacterMakeups } from "./ducks/actions";
import { connect } from "react-redux";
import BackgroundCharacterMakeups from "./character-makeups";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

const BackgroundCharacterMakeupsContainer = (props) => {
    const { loading, makeups, fetchBackgroundCharacterCostumes } = props;
    const backgroundCharacterId = props.characterId || props.match.params.backgroundCharacterId;
    const filmId = props.filmId || props.match.params.filmId;
    useEffect(() => {
        fetchBackgroundCharacterCostumes(filmId, backgroundCharacterId);
    }, [backgroundCharacterId]);

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/background-characters/:backgroundCharacterId/makeups"
            loading={loading}>
            <BackgroundCharacterMakeups makeups={makeups} filmId={filmId} loading={loading} />
        </ScrollIntoView>
    )
}

function mapStateToCostumes(state) {
    const { backgroundCharactersState } = state;
    const makeupState = backgroundCharactersState.makeupState;
    return {
        errors: makeupState.errors,
        makeups: makeupState.makeups,
        loading: makeupState.loading
    };
}

export default connect(mapStateToCostumes, {
    fetchBackgroundCharacterCostumes: fetchBackgroundCharacterMakeups
})(BackgroundCharacterMakeupsContainer);