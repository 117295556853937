export default (props) => <svg xmlns="http://www.w3.org/2000/svg" width="36" height="28" viewBox="0 0 36 28" {...props}>
  <defs>
    <clipPath id="clipPath">
      <rect id="Rectangle_4318" data-name="Rectangle 4318" width="36" height="28" fill="#fff" />
    </clipPath>
  </defs>
  <g id="Discord-Logo-White" clipPath="url(#clipPath)">
    <path id="Path_32954" data-name="Path 32954" d="M30.7,2.7A29.909,29.909,0,0,0,23.321.414.112.112,0,0,0,23.2.47a20.835,20.835,0,0,0-.919,1.888,27.611,27.611,0,0,0-8.292,0A19.108,19.108,0,0,0,13.058.47a.116.116,0,0,0-.119-.056A29.826,29.826,0,0,0,5.557,2.7a.106.106,0,0,0-.049.042A30.61,30.61,0,0,0,.15,23.389a.124.124,0,0,0,.047.085,30.076,30.076,0,0,0,9.056,4.578.117.117,0,0,0,.127-.042A21.5,21.5,0,0,0,11.234,25a.115.115,0,0,0-.063-.16,19.806,19.806,0,0,1-2.829-1.348A.116.116,0,0,1,8.33,23.3c.19-.142.38-.291.562-.44a.112.112,0,0,1,.117-.016,21.449,21.449,0,0,0,18.227,0,.112.112,0,0,1,.119.014c.182.15.372.3.563.442a.116.116,0,0,1-.01.193,18.586,18.586,0,0,1-2.831,1.347.116.116,0,0,0-.061.161,24.137,24.137,0,0,0,1.851,3.012.115.115,0,0,0,.127.043,29.976,29.976,0,0,0,9.071-4.578.116.116,0,0,0,.047-.083A30.409,30.409,0,0,0,30.75,2.747.092.092,0,0,0,30.7,2.7ZM12.12,19.268a3.486,3.486,0,0,1-3.26-3.656,3.467,3.467,0,0,1,3.26-3.655,3.448,3.448,0,0,1,3.259,3.655A3.467,3.467,0,0,1,12.12,19.268Zm12.051,0a3.486,3.486,0,0,1-3.259-3.656,3.467,3.467,0,0,1,3.259-3.655,3.448,3.448,0,0,1,3.26,3.655A3.458,3.458,0,0,1,24.171,19.268Z" transform="translate(0 -0.201)" />
  </g>
</svg>
