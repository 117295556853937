import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../shared/loader";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import { fetchLocationsWithSubLocations } from "./../locations/ducks/actions";
import {
  addSceneSetting,
  clearRedirect,
  uploadImagesAndSaveSceneSetting
} from "./ducks/actions";
import Form from "./form";

function AddSceneSetting(props) {
  const filmId = props.match.params.filmId;
  const {
    addSceneSetting,
    uploadImagesAndSaveSceneSetting,
    fetchLocationsWithSubLocations,
    clearRedirect,
    scenesetting,
    errors
  } = props;

  useEffect(() => {
    fetchLocationsWithSubLocations(filmId);
  }, []);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      clearRedirect();
      return <Redirect to={`/films/${filmId}/scene-settings/`} />;
    }
  }

  function onAddSceneSetting(sceneSetting) {
    const files = sceneSetting.pictures || [];

    if (files && files.length > 0) {
      uploadImagesAndSaveSceneSetting(sceneSetting, files, true, filmId);
    } else {
      if (!sceneSetting.images) {
        sceneSetting.images = [];
      }
      addSceneSetting(filmId, sceneSetting);
    }
  }

  if (props.loadingSceneSetting) {
    return <Loader />;
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/scene-settings/add"
      loading={false}>
      <Form
        errors={errors}
        mode={"add"}
        scenesettingId={null}
        initialValues={scenesetting || {}}
        loading={false}
        onSubmit={onAddSceneSetting}
        locationsWithSubLocations={props.locationsWithSubLocations}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { sceneSettingsState, locationsState } = state;
  return {
    scenesetting: sceneSettingsState.rootState.sceneSetting,
    locationsWithSubLocations: locationsState.rootState.locationsWithSubLocations,
    errors: sceneSettingsState.rootState.errors,
    redirect: sceneSettingsState.rootState.redirect,
    loadingSceneSetting: sceneSettingsState.rootState.loadingSceneSetting
  };
}

export default connect(mapStateToProps, {
  addSceneSetting,
  fetchLocationsWithSubLocations,
  clearRedirect,
  uploadImagesAndSaveSceneSetting
})(AddSceneSetting);
