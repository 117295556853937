import * as React from 'react'

export default (props) => 
<svg xmlns="http://www.w3.org/2000/svg" width="10.002" height="15.429" viewBox="0 0 10.002 15.429"  {...props}>
  <g id="Group_3940" data-name="Group 3940" transform="translate(-47 -20.32)">
    <path id="Path_32877" data-name="Path 32877" d="M56.971,24.778a5,5,0,0,1-3.619,5.389.775.775,0,0,0-.577.731v.962a.77.77,0,0,1-1.54,0V30.706a2.3,2.3,0,0,1,1.694-2.04,3.464,3.464,0,0,0-.539-6.775,3.415,3.415,0,0,0-2.695.847,3.5,3.5,0,0,0-1.155,2.579.857.857,0,0,1-.346.654A.784.784,0,0,1,47,25.317a5.1,5.1,0,0,1,1.655-3.734,5.008,5.008,0,0,1,3.888-1.232A5.069,5.069,0,0,1,56.971,24.778Z" fill="#fff"/>
    <path id="Path_32878" data-name="Path 32878" d="M59.309,56.631a.77.77,0,1,1-1.078,0A.744.744,0,0,1,59.309,56.631Z" transform="translate(-6.765 -22.191)" fill="#fff"/>
  </g>
</svg>

