import { NavLink } from "react-router-dom";

const CanAccessNavLink = ({ to, children, canAccess }) => {

    return (
        <>
            {canAccess && <NavLink to={to}>
                {children}
            </NavLink>}
            {!canAccess && <>{children}</>}
        </>
    );
};

export default CanAccessNavLink;