export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="28.01" height="28.605" viewBox="0 0 23.322 23.065" {...props}>
    <g id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected" transform="translate(0.533 0.508)">
      <g id="Group_3364" data-name="Group 3364" transform="translate(-2.382 -7.401)">
        <path id="Path_5592" data-name="Path 5592" d="M18.273,13.615a6.943,6.943,0,0,1,2.211,4.172,7.026,7.026,0,0,1-.672,4.1c-.03.062,3.239,2.611,3.239,2.611h0l.034-.056a11.057,11.057,0,0,0-2.526-14.282C20.52,10.129,18.23,13.575,18.273,13.615Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_5593" data-name="Path 5593" d="M18.09,8.278a11.063,11.063,0,0,0-6.351-.689c-.059.011,1.018,4.007,1.069,4a6.811,6.811,0,0,1,3.546.436Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_5594" data-name="Path 5594" d="M13.429,25.37a6.853,6.853,0,0,1-2.862-13.112A26.207,26.207,0,0,0,9.19,8.393a10.938,10.938,0,0,0,4.24,21.064,11.056,11.056,0,0,0,7.609-3.017L18.192,23.5A6.9,6.9,0,0,1,13.429,25.37Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
    </g>
  </svg>
