import { Field, reduxForm } from "redux-form";
import Errors from "../../../../shared/errors";
import FormErrors from "../../../../shared/form-errors";
import {    
  renderTextAreaField,
  renderCkEditorField  
} from "../../../../shared/form-helpers";

let AddFeatureRequestCommentForm = (props: any) => { 

  const {
    handleSubmit,
    pristine,
    submitFailed,
    invalid,
    featureRequestSaving,
    onCancel,
    errors
  } = props;
  
  return (
    <>
      <Errors errors={errors} />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="comment"
          component={renderCkEditorField}          
          label="Comment"
          required ></Field>

        <div className="buttons mt-2">
          <button
            type="submit"
            className={`button is-primary is-on-white ${featureRequestSaving ? "is-loading" : ""}`}
            disabled={pristine || featureRequestSaving}>
            Submit
          </button>
          <button className="button" onClick={onCancel}>
            Cancel
          </button>
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

AddFeatureRequestCommentForm = reduxForm({
  form: "addFeatureRequestCommentForm",
  enableReinitialize: true
})(AddFeatureRequestCommentForm);

  
export default AddFeatureRequestCommentForm