import getApiClient from "./../../../../shared/api";

export const FETCH_EQUIPMENT_IN_SCENE = "FETCH_EQUIPMENT_IN_SCENE";

export function fetchEquipmentInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_EQUIPMENT_IN_SCENE,
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/equipment`),
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
