import React from "react";
import AddFaq from "./add";
import EditFaq from "./edit";
import ListFaq from "./list-container";
import { AdminRoute } from "../../shared/admin-route";

const FaqRoutes = () => {
  return (
    <>
      <AdminRoute
        exact
        path="/admin/faqs"
        component={ListFaq}
      />

      <AdminRoute
        exact
        path="/admin/faqs/add"
        component={AddFaq}
      />

      <AdminRoute
        exact
        path="/admin/faqs/:section/:faqId/edit"
        component={EditFaq}
      />
    </>
  );
};

export default FaqRoutes;
