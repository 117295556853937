import React from "react";
import ReactDOM from "react-dom";

const UserConfirmation = (message, callback) => {
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  container.classList.add("modal");
  container.classList.add("is-active");
  document.body.appendChild(container);

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  const displayMessage = message ? message : "It looks like you might have some unsaved changes! Are you sure you want to continue?";
  ReactDOM.render(
    <div className="">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Discard unsaved changes?</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => closeModal(false)}></button>
        </header>
        <section className="modal-card-body">
          {displayMessage}
        </section>
        <footer className="modal-card-foot">
          <button
            className="button"
            onClick={() => {
              closeModal(true);
            }}>
            Discard Changes
          </button>
          <button className="button" onClick={() => closeModal(false)}>
            Cancel
          </button>
        </footer>
      </div>
    </div>,
    container
  );
};

export default UserConfirmation;
