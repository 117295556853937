import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export const UPDATE_USER_GET_LANGUAGES = "UPDATE_USER_GET_LANGUAGES";

export function getCountries() {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_USER_GET_COUNTRIES",
      payload: getApiClient().get(`/lookup/countries`),
    });
  };
}

export function getLanguages() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_GET_LANGUAGES,
      payload: getApiClient().get(`/lookup/languages`),
    });
  };
}

export function fetchUser() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER",
      payload: getApiClient().get(`/user`)
    });
  };
}

export function updateUser(user) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_USER",
      payload: getApiClient().put(`/user`, user),
      meta: { user }
    }).then((response) => {
      dispatch(showInfo(`Successfully updated user details`));
    })
    .catch((response) => {
      dispatch(showError(`Error updating user details`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}
