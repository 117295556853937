import getApiClient, { getAnnonymousApiClient } from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";
import invitations from "../../../shared/invitations";

export function login(user, history, origin) {

  return (dispatch) => {
    user.invitations = invitations.getInvitations();
    dispatch({
      type: "LOGIN",
      payload: getAnnonymousApiClient().post(`/account/login`, user),
      meta: { user }
    }).then((response) => {
      //TODO: Work out why this happens 2 or more times      
      invitations.removeInvitations();
      if (!response.value.data.hasInvitationErrors) {
        if (origin) {
          history.push(origin);
        } else {
          history.push('/films');
        }
      }
    })
      .catch((response) => {
        dispatch(showError(`Error logging in`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearErrors() {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_CLEAR_ERRORS",
    });
  };
}

export function resendEmailConfirmation(emailAddress) {
  return (dispatch) => {
    dispatch({
      type: "RESEND_EMAIL_CONFIRMATION",
      payload: getAnnonymousApiClient().post(`/account/ResendEmailConfirmation`, { emailAddress })
    }).then((response) => {
      dispatch(showInfo(`Successfully resent email confirmation`));
    })
      .catch((response) => {
        dispatch(showError(`Error sending email confirmation`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_LOGIN_REDIRECT",
    });
  };
}
