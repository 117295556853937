import { getAnnonymousApiClient } from "../../shared/api";

export const FETCH_PRICES = "FETCH_PRICES";
export const LOG_ANONYMOUS_ERROR = "LOG_ANONYMOUS_ERROR";

export function fetchPrices() {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRICES,
      payload: getAnnonymousApiClient().get("/Marketing/Prices")
    });
  };
}

export function logError(location, errorMessage, stack, info) {
  return (dispatch) => {
    dispatch({
      type: LOG_ANONYMOUS_ERROR,
      payload: getAnnonymousApiClient().post("error/AnonymousLog", {
        location,
        errorMessage,
        stack,
        info
      })
    });
  };
}
