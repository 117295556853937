import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function CostumeMenu(props) {
  const { filmId, costumeId } = props.match.params;
  const { costume } = props;
  const invalidIds = ["add", "overview"];

  if (invalidIds.indexOf(costumeId) > -1) {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Costume</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/costumes/${costumeId}/edit`}
            activeClassName="is-active">
            Edit Costume
          </NavLink>
        </li>
        {costume?.canAccessScenes &&
          <li>
            <NavLink
              to={`/films/${filmId}/costumes/${costumeId}/scenes/`}
              activeClassName="is-active">
              Scenes
            </NavLink>
          </li>}
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const { costumesState } = state;
  return {
    costume: costumesState.costume
  };
}

export default connect(mapStateToProps, {
})(CostumeMenu);
