import React from "react";
import { reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";

function ShootingDayShotListForm(props) {
  const { filmId, shootingdayId } = props.match.params;
  const { errors, sendShotListToCrew, sendShotListToSelf } = props;
  const shotList = props.initialValues.shotList.shotListGroups || {};

  let shotListData: any = null;

  function onGeneratePdf() {
    sendShotListToSelf(filmId, shootingdayId);
  }

  function onSendShotListToCrew() {
    sendShotListToCrew(filmId, shootingdayId);
  }

  shotListData = (
    <div>
      {(shotList || []).map((shotGroup, idx, records) => {
        return (
          <div key={idx}>
            <h3>{shotGroup.shotLists[0].locationName}</h3>

            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Scene</th>
                  <th>Shot</th>
                  <th>Shot Size</th>
                  <th>Lens</th>
                  <th>Movements</th>
                  <th>Actors</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {(shotGroup.shotLists || []).map((shot, idx, records) => {
                  return (
                    <tr key={idx}>
                      <td>{shot.sceneNumber}</td>
                      <td>{shot.shotNumber}</td>
                      <td>{shot.shotSizeDescription}</td>
                      <td>{shot.lensFocalLength}</td>
                      <td>{shot.movements}</td>
                      <td>{(shot.actors || []).join(", ")}</td>
                      <td>{shot.notes}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
      <br />
      <button className="button is-primary mb-1" onClick={onGeneratePdf}>
        Email Shot List to yourself
      </button>
      <br />
      <button
        className="button is-primary mt-1"
        onClick={onSendShotListToCrew}
        disabled={true}
      >
        Email Shot List to camera department crew
      </button>
    </div>
  );

  return (
    <div className="shooting-days-shot-list">
      {errors && <Errors errors={errors} />}
      {shotListData}
    </div>
  );
}

export default reduxForm({
  form: "shootingDayShotListForm",
  enableReinitialize: true
})(ShootingDayShotListForm);
