import getApiClient from "./../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications,
  showWarning
} from "../../../../notifications/ducks/actions";
import axios from "axios";

const url = "/film";

declare const google: any;

export function fetchLocationOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_LOCATION_OVERVIEW",
      payload: getApiClient().get(`${url}/${id}/Location/Overview`)
    });
  };
}

export function clearLocationOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_LOCATION_OVERVIEW"
    });
  };
}


export function fetchDistances(latitude: number, longitude: number) {
  const googleUrl = "https://maps.googleapis.com/maps/api/";
  const googleDistanceMatrixUrl = googleUrl + "distancematrix/";

  const apiKey = (window as any).googleApiKey;

  const distanceMatricService = new google.maps.DistanceMatrixService();

  var origin1 = new google.maps.LatLng(55.930385, -3.118425);
var origin2 = 'Greenwich, England';
var destinationA = 'Stockholm, Sweden';
var destinationB = new google.maps.LatLng(50.087692, 14.421150);
debugger;

  distanceMatricService.getDistanceMatrix(
    {
      origins: [origin1, origin2],
      destinations: [destinationA, destinationB],
      travelMode: 'DRIVING',
      //transitOptions: TransitOptions,
      //drivingOptions: DrivingOptions,
      //unitSystem: UnitSystem,
      avoidHighways: Boolean,
      avoidTolls: Boolean,
    }, callback);
  
  function callback(response, status) {
    debugger;
    // See Parsing the Results for
    // the basics of a callback function.
    return (dispatch) => {
      dispatch({
        type: "FETCH_LOCATION_OVERVIEW_DISTANCES",
        payload: response //axios.get(googleDistanceMatrixUrl + "json?origins=40.6655101%2C-73.89188969999998&destinations=40.659569%2C-73.933783%7C40.729029%2C-73.851524%7C40.6860072%2C-73.6334271%7C40.598566%2C-73.7527626&units=imperial&key=" + apiKey, 
        //{ headers: { "Content-Type": "application/json", 'Access-Control-Allow-Origin': "*" }})
      });
    };  
  }

   
}
