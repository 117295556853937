import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Badge from "../shared/card-badge";
import confirm from "../shared/modal/confirm";

const FilmCard = (props) => {
  const {
    film,
    deleteFilm
  } = props;

  let filmName = film.name;
  let tooLong = false;
  const maxLength = 50;
  if (filmName.length > maxLength) {
    filmName = filmName.substring(0, maxLength) + " ...";
    tooLong = true;
  }

  const lastModifiedUtc = moment.utc(film.lastModified)
  const lastModifiedLocal = moment(lastModifiedUtc).local();
  var lastModified = lastModifiedLocal.format("Do MMMM YYYY, HH:mm:ss");

  //withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm"

  const setFilmDetails = (filmId: any) => {

  };

  const onDeleteFilm = (e) => {
    e.preventDefault();
    confirm(() => deleteFilm(film), film.name);
  }

  const linkText = film.status === "Archived" ? "View" : "Manage";

  return (
    <div className={`card${film.isExample ? " onboarding-step-5" : ""}`} key={film.id}>
      <header className="card-header">

        <div className="card-header-title is-size-5 film-name">
          <p
            className="film-name"
            title={film.name}>
            {filmName}
          </p>
          {film.status && (
            <p className="tag is-purple mt-05 mr-1">{film.status}</p>
          )}
          {(film.roleNames || []).map(roleName => (
            <p className="tag is-light mt-05 mr-1">
              {roleName}
            </p>
          ))}
        </div>
        <Badge count={film.messageCount} severity={film.messageSeverity} />
      </header>
      <div className="card-content">
        <div className="content">
          {film.budget && (
            <p className="is-size-7">
              {film.currencySymbol}{film.budget.toLocaleString()}
            </p>
          )}
          {film.lastModified && (
            <p className="is-size-7" title={lastModified}>
              Last modified:&nbsp;
              <Moment fromNow >{film.lastModified}</Moment>, by&nbsp;
              {film.lastModifiedBy}
            </p>
          )}
        </div>
      </div>
      <footer className="card-footer">
        <Link
          to={"/films/" + film.id + (film.canViewOverview ? "/overview" : "")}
          onClick={() => setFilmDetails(film.id)}
          className="manage-film-button ml-1 mt-1 button is-transparent">
          {linkText} Film
        </Link>
        <button className="manage-film-button ml-1 mt-1 button is-transparent" style={{ maxWidth: "4rem" }} onClick={onDeleteFilm}>Delete</button>
      </footer>
    </div>
  );

};

export default FilmCard;