import getApiClient, { getAnnonymousApiClient } from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";
import invitations from "../../../shared/invitations";

export const PAYMENT_GET_CURRENCIES = "PAYMENT_GET_CURRENCIES";
export const CLEAR_PAYMENT = "CLEAR_PAYMENT";
export const CLEAR_PAYMENT_REDIRECT = "CLEAR_PAYMENT_REDIRECT;"
export const CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_PAYING = "PAYMENT_PAYING";
export const FETCH_PRICING_PLANS = "FETCH_PRICING_PLANS";
export const CHECK_DISCOUNT_CODE = "CHECK_DISCOUNT_CODE";

export function getCurrencies() {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_GET_CURRENCIES,
      payload: getApiClient().get(`/lookup/Currencies`),
    });
  };
}

export function fetchPricingPlans() {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRICING_PLANS,
      payload: getApiClient().get(`/lookup/PricingPlans`)
    });
  };
}

export function checkDiscountCode(code: string, pricingPlan: number, currencyId: string) {
  return (dispatch) => {
    dispatch({
      type: CHECK_DISCOUNT_CODE,
      payload: getApiClient().get(`/lookup/CheckDiscount/${code}/${pricingPlan}/${currencyId}`)
    });
  };
}

export function getPaymentIntent(pricingPlan, currencyId, paymentMethodId, discountCode) {
  return (dispatch) => {
    dispatch({
      type: CREATE_PAYMENT_INTENT,
      payload: getApiClient().post(`/payment`, { pricingPlan, currencyId, paymentMethodId, discountCode }),
    });
  };
}

export function clearForm() {
  return (dispatch) => {
    dispatch({
      type: "REGISTER_CLEAR_FORM",
    });
  };
}

export function payment(payment) {
  return (dispatch) => {
    dispatch({
      type: "PAYMENT",
      payload: getApiClient().post(`/payment`, payment),
      meta: { user: payment }
    }).then((response) => {
      invitations.removeReferralCode();
      dispatch(showInfo(`Successfully paid`));
    })
      .catch((response) => {
        dispatch(showError(`Error paying`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PAYMENT_REDIRECT,
    });
  };
}

export function clearRegistered() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PAYMENT,
    });
  };
}

export function setError(error) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_ERROR,
      payload: error
    });
  };
}

export function setPaying(paying) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_PAYING,
      payload: paying
    });
  };
}