import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export const FETCH_USER_PRICING_PLANS = "FETCH_USER_PRICING_PLANS";
export const PAUSE_PAYMENT = "PAUSE_PAYMENT";
export const RESUME_PAYMENT = "RESUME_PAYMENT";
export const CANCEL_PAYMENT = "CANCEL_PAYMENT";
export const UNCANCEL_PAYMENT = "UNCANCEL_PAYMENT";
export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT";

export function fetchAccount() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ACCOUNT",
      payload: getApiClient().get(`/account/details`)
    });
  };
}

export function fetchUserPricingPlans() {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_PRICING_PLANS,
      payload: getApiClient().get(`/lookup/UserPricingPlans`)
    });
  };
}

export function changePricingPlan(pricingPlanType: any) {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_PRICING_PLAN",
      payload: pricingPlanType
    });
  };
}

export function updateAccount(account) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_ACCOUNT,
      payload: getApiClient().put(`/account`, account),
      meta: { account }
    }).then((response) => {
      dispatch(showInfo(`Successfully updated account`));
    })
      .catch((response) => {
        dispatch(showError(`Error updating account`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function pausePayment() {
  return (dispatch) => {
    dispatch({
      type: PAUSE_PAYMENT,
      payload: getApiClient().post(`/payment/pause`, {}),
    });
  };
}

export function resumePayment() {
  return (dispatch) => {
    dispatch({
      type: RESUME_PAYMENT,
      payload: getApiClient().post(`/payment/resume`, {}),
    });
  };
}

export function cancelPayment() {
  return (dispatch) => {
    dispatch({
      type: CANCEL_PAYMENT,
      payload: getApiClient().post(`/payment/cancel`, {}),
    });
  };
}

export function unCancelPayment() {
  return (dispatch) => {
    dispatch({
      type: UNCANCEL_PAYMENT,
      payload: getApiClient().post(`/payment/uncancel`, {}),
    });
  };
}