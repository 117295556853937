import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { sortByName } from "../../../../shared/utility";
import { ALIAS_PROP, CLEAR_DOWNLOAD_PROPS_OVERVIEW, DOWNLOAD_PROPS_OVERVIEW, FETCH_PROP_OVERVIEW, SAVE_ALIAS_PROP, SAVE_REMOVE_ALIAS_PROP, UNALIAS_PROP } from "./actions";

interface PropOverviewState {
  overview: any;
  loading: boolean;
  readOnly: boolean;
  propsOnboardingSteps: OnboardingType[];
  download: any;
  progress: any;
}

export const OnboardingStep1ClassName = 'props-overview-onboarding-step-1';
export const OnboardingStep2ClassName = 'props-overview-onboarding-step-2';
export const OnboardingStep3ClassName = 'props-overview-onboarding-step-3';
export const OnboardingStep4ClassName = 'props-overview-onboarding-step-4';

const defaultState: PropOverviewState = {
  overview: {},
  loading: true,
  readOnly: false,
  download: null,
  progress: null,
  propsOnboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Completed Props',
      content: `This shows the number of props with a complete set of information.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_PROP_OVERVIEW + "_PENDING": {
      return {
        ...state,
        loading: true,
        overview: null,
        download: null,
        errors: null
      };
    }

    case FETCH_PROP_OVERVIEW + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        overview: action.payload.data,
        errors: null
      };
    }


    case DOWNLOAD_PROPS_OVERVIEW + "_PENDING": {
      return {
        ...state,
        loading: false,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case DOWNLOAD_PROPS_OVERVIEW + "_FULFILLED": {
      return state;
    }

    case DOWNLOAD_PROPS_OVERVIEW + "_COMPLETE": {
      return {
        ...state,
        loading: false,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case CLEAR_DOWNLOAD_PROPS_OVERVIEW: {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    case DOWNLOAD_PROPS_OVERVIEW + "_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case DOWNLOAD_PROPS_OVERVIEW + "_ERRORED": {
      return {
        ...state,
        progress: null,
        errors: action.meta.errors
      };
    }

    default:
      return state;
  }
};

export default reducer;
