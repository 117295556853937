import { connect } from "react-redux";
import {  
    fetchFeatureRequests,
    addFeatureRequestComment,
    clearSent,
    setShowAddComment
  } from "../../../feature-request/ducks/actions";
import Form from "./form";
import { useEffect, useState } from "react";
import Loader from "../../../../shared/loader";

const AddCommentContainer = (props) => {
 const {
  featureRequestId,            
        featureRequestSaving, 
        featureRequestSent, 
        featureRequestErrors,         
        addFeatureRequestComment,
        clearSent,
        setShowAddComment,
        showAddComment,
        featureRequestCommentSaving } = props;

 const handleFeedbackFormSubmit = (comment) => {    
    addFeatureRequestComment(featureRequestId, comment);
 };

 const [initialValues, setInitialValues] = useState<any>();

  useEffect(() => {
    clearSent();
  }, 
  []);

  useEffect(() => {
    if (!showAddComment) {
      setInitialValues({});
    }      
  }, 
  [showAddComment]);

  const onAddComment = () => {
    setShowAddComment(true);
   };

   const onCancel = () => {
    setShowAddComment(false);
   }

 return (    
    <div>
        {featureRequestCommentSaving && <Loader />}
       {!showAddComment && <button className="button" onClick={onAddComment}>Add Comment</button>}
        {showAddComment && 
        <Form errors={featureRequestErrors}
            featureRequestSaving={featureRequestSaving}
            featureRequestSent={featureRequestSent}            
            onSubmit={handleFeedbackFormSubmit}
            onCancel={onCancel}
            showAddComment={showAddComment}
            initialValues={initialValues} />      
        }
    </div>
  );
}

function mapStateToChangeLogs(state) {
    const { featureRequestState } = state;
    return {
        featureRequests : featureRequestState.featureRequests,
        emptyFeatureRequest : featureRequestState.emptyFeatureRequest,
        featureRequestsLoading: featureRequestState.featureRequestsLoading,
        featureRequestCommentSaving: featureRequestState.featureRequestCommentSaving,    
        featureRequestSent: featureRequestState.featureRequestSent,
        featureRequestErrors: featureRequestState.featureRequestErrors,
        showAddComment: featureRequestState.showAddComment
    };
  }
  
  export default connect(mapStateToChangeLogs, {
    fetchFeatureRequests,
    addFeatureRequestComment,
    clearSent,
    setShowAddComment
  })(AddCommentContainer);
  