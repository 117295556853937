import React from "react";
import { Route } from "react-router-dom";
import ListMakeupsContainer from "./list-container";
import AddMakeup from "./add";
import EditMakeup from "./edit";
import MakeupScenes from "./scenes";
import MakeupMenu from "./menu";
import { PrivateRoute } from "../../../shared/private-route";

const MakeupRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/makeup"
        component={ListMakeupsContainer}
      />
      <PrivateRoute
        path="/films/:filmId/makeup/:makeupId"
        component={MakeupMenu}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/makeup/add"
        component={AddMakeup}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/makeup/:makeupId"
        component={EditMakeup}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/makeup/:makeupId/edit"
        component={EditMakeup}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/makeup/:makeupId/scenes"
        component={MakeupScenes}
      />
    </>
  );
};

export default MakeupRoutes;
