import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "./../../../shared/loader";
import { fetchCharactersInScene } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";

function ListCharactersContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchCharactersInScene, loading, characters, errors, readOnly } = props;

  useEffect(() => {
    fetchCharactersInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(characters, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List characters={characters} filmId={filmId} sceneId={sceneId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <Link
          to={`/films/${filmId}/scenes/${sceneId}/characters/add`}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Character
        </Link>}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(characters, filmId, sceneId);
}

function mapStateToProps(state) {
  return {
    characters: state.scenesState.characterState.characters,
    readOnly: state.scenesState.characterState.readOnly,
    loading: state.scenesState.characterState.loading,
    errors: state.scenesState.characterState.errors
  };
}

export default connect(mapStateToProps, {
  fetchCharactersInScene
})(ListCharactersContainer);
