import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import Errors from "../shared/errors";

import "./index.scss";
import { useState } from "react";
import { Prompt, Redirect, useHistory } from "react-router-dom";
import FilmanizeSelect, { styles } from "../shared/filmanize-select";
import Onboarding from '../shared/onboarding/onboarding';
import { OnboardingStep1ClassName, OnboardingStep2ClassName, OnboardingStep3ClassName } from './ducks/reducer';
import BoxWraper from "../account/box-wrapper";
import Loader from "../shared/loader";
import FilmanizeSelectField from "../shared/filmanize-select-field";
import UploadScript from "./upload-script";
import FilmanizeCheckbox from "../shared/filmanize-checkbox";
import FilmanizeCurrencyField from "../shared/filmanize-curreny-field";

interface IProps {
  uploadStatus: string;
}

export const acceptedFileTypes = [".fdx", ".cxscript", ".celtx", ".fountain", ".highland"];

const UploadForm = (props: any) => {

  const {
    loading,
    uploadScript,
    uploadStatus,
    setUploadError,
    errors,
    progress,
    clearRedirect,
    onboardingSteps,
    currencies,
    userCurrencyId,
    loadingOnBoarding,
    actorUnions,
    crewUnions,
    actorUnionSelectedValue,
    setActorUnion,
    crewUnionSelectedValue,
    setCrewUnion,
    currencyId,
    setCurrencyId,
    languages,
    languageId,
    userLanguageId,
    setLanguageId,
    setBudget,
    budget,
    setUseAutopBreakdown,
    useAutoBreakdown,
    isInProgress
  } = props;

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (isInProgress) {
      setSaving(isInProgress);
    }
  }, [isInProgress]);

  useEffect(() => {
    if (userCurrencyId && currencyId === null) {
      setCurrencyId(userCurrencyId);
    }
  }, [userCurrencyId]);

  useEffect(() => {
    if (userLanguageId && languageId === null) {
      setLanguageId(userLanguageId);
    }
  }, [userLanguageId]);

  useEffect(() => {
    if (actorUnions && actorUnions.length > 0 && actorUnionSelectedValue == null) {
      setActorUnion(actorUnions[0].value);
    }
  }, [JSON.stringify(actorUnions)]);

  useEffect(() => {
    if (crewUnions && crewUnions.length > 0 && crewUnionSelectedValue == null) {
      setCrewUnion(crewUnions[0].value);
    }
  }, [JSON.stringify(crewUnions)]);

  const onBudgetChange = (event) => {
    setBudget(event.floatValue);
  };

  const onBudgetComboChange = (value) => {
    setBudget(value);
  };

  const onBudgetComboSelect = (value) => {
    setBudget(value);
  };

  const onCurrencyChange = (event) => {
    setCurrencyId(event.value);
  };

  const onLanguageChange = (value) => {
    setLanguageId(value);
  };

  const onActorUnionChange = (value) => {
    setActorUnion(value);
  };

  const onCrewUnionChange = (value) => {
    setCrewUnion(value);
  };

  const onSetUseAutoBreakdown = (value) => {
    setUseAutopBreakdown(value);
  };

  const onCreateOption = (inputValue) => {
    //debugger;
    setBudget(Number(inputValue.replace(/[^0-9.-]+/g, "")));
    return { value: 100000, label: inputValue };
  };

  const getNewOptionData = (inputValue) => {
    const value = Number(inputValue.replace(/[^0-9.-]+/g, ""));
    //setBudget();
    return { value: value, label: inputValue };
  };

  const currencyOptions = (currencies || []).map((item) => { return { value: item.id, label: item.name } });
  const currency = currencyOptions.find((c) => c.value == currencyId);
  const currencySymbol = currencies.find((c) => c.id == currencyId)?.symbol;

  const languageOptions = (languages || []).map((item) => { return { value: item.id, label: item.name } });

  const actorUnionOptions = (actorUnions || []).map((item) => { return { value: item.value, label: item.name } });
  const crewUnionOptions = (crewUnions || []).map((item) => { return { value: item.value, label: item.name } });

  const budgets = [
    { value: 0, label: currencySymbol + "0" },
    { value: 100000, label: currencySymbol + "100,000" },
    { value: 1000000, label: currencySymbol + "1,000,000" },
    { value: 1500000, label: currencySymbol + "1,500,000" },
    { value: 3000000, label: currencySymbol + "3,000,000" },
    { value: 4000000, label: currencySymbol + "4,000,000" },
    { value: 5000000, label: currencySymbol + "5,000,000" },
    { value: 6000000, label: currencySymbol + "6,000,000" },
    { value: 8500000, label: currencySymbol + "8,500,000" },
    { value: 15000000, label: currencySymbol + "15,000,000" },
    { value: 30000000, label: currencySymbol + "30,000,000+" }
  ];

  const Input = (props) => {
    console.log(props);
    return (
      <NumberFormat
        className="input input-in-select"
        thousandSeparator={true}
        prefix={currencySymbol}
        decimalScale={2}
        isNumericString={true}
        //value={props.selectProps.value?.value}
        onChange={onBudgetChange}
        onValueChange={onBudgetChange}
        {...props}
      />
    );
  };

  const onUpload = (formData) => {
    formData.append(
      "model",
      JSON.stringify({
        filmBudget: budget,
        currencyId: currencyId,
        languageId: languageId,
        crewUnion: crewUnionSelectedValue === 0 ? null : crewUnionSelectedValue,
        actorUnion: actorUnionSelectedValue === 0 ? null : actorUnionSelectedValue,
        useAutoBreakdown
      })
    );
    uploadScript(formData, props.history);
  };

  if (props.redirect) {
    if (props.redirect.to) {
      clearRedirect();
      const url = props.redirect.to;
      return <Redirect to={url} />;
    }
  }

  return (
    <>
      <BoxWraper>
        {loadingOnBoarding && <Loader />}
        {errors && <Errors errors={errors} />}
        <Prompt when={saving} message="You need to wait for the film to finish uploading." />
        <>
          <h2 className="menu-label">Upload Script</h2>
          <div>
            <label className="label">Currency</label>
            <FilmanizeSelect
              options={currencyOptions}
              value={currency}
              onChange={(newValue) => {
                return onCurrencyChange(newValue);
              }}
              disabled={saving}
            />
            <FilmanizeSelectField
              className="mt-1"
              label="Language"
              options={languageOptions}
              value={languageId}
              onChange={onLanguageChange}
              disabled={saving}
            />
            {actorUnionOptions.length > 0 &&
              <FilmanizeSelectField
                className="mt-1"
                label="Actor Union"
                includeEmpty={true}
                emptyName="None"
                emptyValue={0}
                options={actorUnionOptions}
                value={actorUnionSelectedValue}
                onChange={onActorUnionChange}
                disabled={saving}
              />}
            {crewUnionOptions.length > 0 &&
              <FilmanizeSelectField
                className="mt-1"
                label="Crew Union"
                includeEmpty={true}
                emptyName="None"
                emptyValue={0}
                options={crewUnionOptions}
                value={crewUnionSelectedValue}
                onChange={onCrewUnionChange}
                disabled={saving}
              />
            }


            <div className={` mt-1 ${OnboardingStep1ClassName}`}>

              <FilmanizeCurrencyField
                label="Film Budget"
                value={budget}
                currencySymbol={currencySymbol}
                onChange={onBudgetComboSelect}
                disabled={saving}
              />
            </div>


            {/* <Creatable  
                styles={styles}
                placeholder={"Select or enter"}
                className="react-select-control"
                components={{ Input, IndicatorSeparator: () => null }}
                classNamePrefix="react-select"
                options={budgets}
                getNewOptionData={getNewOptionData}
                //onCreateOption={onCreateOption}
                onChange={(newValue) => {
                  return onBudgetComboSelect(newValue);
                }}/>             */}
            <div className={`mt-1 ${OnboardingStep2ClassName}`}>
              <FilmanizeCheckbox label="Use Smart Breakdown" onChange={onSetUseAutoBreakdown} checked={useAutoBreakdown} disabled={saving} />
            </div>

            <UploadScript
              isUploading={saving}
              setUploadError={setUploadError}
              progress={progress}
              clearRedirect={clearRedirect}
              saving={saving}
              setSaving={setSaving}
              onUpload={onUpload}
              disabled={loading}
              onboardingSteps={onboardingSteps}
            />

          </div>
        </>

      </BoxWraper>
      <Onboarding onboardingSteps={onboardingSteps} section="Script Upload" />
    </>
  );
};

export default UploadForm;