import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function SetDressingMenu(props) {
  const { filmId, setDressingId } = props.match.params;
  const { setDressing } = props;
  const invalidIds = ["add", "overview"];
  if (invalidIds.indexOf(setDressingId) > -1) {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Set Dressing</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/set-dressings/${setDressingId}/edit`}
            activeClassName="is-active">
            Edit Set Dressing
          </NavLink>
        </li>
        {setDressing?.canAccessScenes &&
          <li>
            <NavLink
              to={`/films/${filmId}/set-dressings/${setDressingId}/scenes/`}
              activeClassName="is-active">
              Scenes
            </NavLink>
          </li>
        }
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const { setDressingsState } = state;
  return {
    setDressing: setDressingsState.setDressing
  };
}

export default connect(mapStateToProps, {
})(SetDressingMenu);
