import React from "react";
import { useInView } from "react-intersection-observer";
import BulletPoint from "./bullet-point";
import './bullet-point.scss';
import ScriptIcon from '../films/icons/script';
import ShootingDaysIcon from '../films/icons/shooting-days';
import CrewIcon from '../films/icons/crew';
import LocationsIcon from '../films/icons/locations';
import CollaboratorsIcon from '../films/icons/collaborators';
import BudgetIcon from '../films/icons/budget';
import CharacterIcon from '../films/icons/characters';
import BackgroundCharacterIcon from '../films/icons/background-characters';
import BreakdownCharacterIcon from '../films/icons/breakdown';
import CostumeIcon from '../films/icons/costumes';
import MakeupIcon from '../films/icons/makeup';
import PropIcon from '../films/icons/props';
import SceneSettingIcon from '../films/icons/scene-settings';
import SetDressingIcon from '../films/icons/set-dressings';
import ActorIcon from '../films/icons/actors';
import EquipmentIcon from '../films/icons/equipment';
import TasksIcon from '../films/icons/tasks';
import SchedulingIcon from '../films/icons/scheduling';
import VisualEffectsIcon from '../films/icons/visual-effects';
import ScenesIcon from '../films/icons/scenes';
import BackgroundActorsIcon from '../films/icons/background-actors';

const BulletPoints = () => {
    //const options = { threshold: 1, rootMargin: "-120px 0px -120px 0px", triggerOnce: true };
    //const [ref, inView] = useInView(options);

    const scriptIcon = <ScriptIcon className="bullet-point-icon" />;
    const shootingDayIcon = <ShootingDaysIcon className="bullet-point-icon" />;
    const crewIcon = <CrewIcon className="bullet-point-icon" />;
    const locationsIcon = <LocationsIcon className="bullet-point-icon" />;
    const collaboratorsIcon = <CollaboratorsIcon className="bullet-point-icon" />;
    const budgetIcon = <BudgetIcon className="bullet-point-icon" />;
    const characterIcon = <CharacterIcon className="bullet-point-icon" />;
    const backgroundCharacterIcon = <BackgroundCharacterIcon className="bullet-point-icon" />;
    const breakdownCharacterIcon = <BreakdownCharacterIcon className="bullet-point-icon" />;
    const costumeIcon = <CostumeIcon className="bullet-point-icon" />;
    const makeupIcon = <MakeupIcon className="bullet-point-icon" />;
    const propIcon = <PropIcon className="bullet-point-icon" />;
    const sceneSettingIcon = <SceneSettingIcon className="bullet-point-icon" />;
    const setDressingIcon = <SetDressingIcon className="bullet-point-icon" />;
    const actorIcon = <ActorIcon className="bullet-point-icon" />;
    const equipmentIcon = <EquipmentIcon className="bullet-point-icon" />;
    const tasksIcon = <TasksIcon className="bullet-point-icon" />;
    const schedulingIcon = <SchedulingIcon className="bullet-point-icon" />;
    const visualEffectsIcon = <VisualEffectsIcon className="bullet-point-icon" />;
    const scenesIcon = <ScenesIcon className="bullet-point-icon" />;
    const backgroundActorsIcon = <BackgroundActorsIcon className="bullet-point-icon" />;

    return (
        <div className="marketing-bullet-points">
            <div className="marketing-titles marketing-bullet-points-title">
                Everything you need for a seamless film production
            </div>

            <div className={`marketing-bullet-points-container `}>
                <BulletPoint icon={scriptIcon} description="Upload and analyse scripts" />
                <BulletPoint icon={breakdownCharacterIcon} description="Script breakdown" />
                <BulletPoint icon={sceneSettingIcon} description="Scene settings" />
                <BulletPoint icon={scenesIcon} description="Scenes" />
                <BulletPoint icon={shootingDayIcon} description="Manage shooting schedules" />
                <BulletPoint icon={schedulingIcon} description="Availability" />
                <BulletPoint icon={crewIcon} description="Manage crew" />
                <BulletPoint icon={locationsIcon} description="Find locations" />
                <BulletPoint icon={collaboratorsIcon} description="Collaborate with others" />
                <BulletPoint icon={tasksIcon} description="Tasks" />
                <BulletPoint icon={equipmentIcon} description="Manage equipment" />
                <BulletPoint icon={budgetIcon} description="Monitor budget" />
                <BulletPoint icon={characterIcon} description="Characters" />
                <BulletPoint icon={backgroundCharacterIcon} description="Background characters" />
                <BulletPoint icon={actorIcon} description="Find actors" />
                <BulletPoint icon={backgroundActorsIcon} description="Background actors" />
                <BulletPoint icon={costumeIcon} description="Costumes" />
                <BulletPoint icon={makeupIcon} description="Makeup" />
                <BulletPoint icon={propIcon} description="Props" />
                <BulletPoint icon={setDressingIcon} description="Set dressings" />
                <BulletPoint icon={visualEffectsIcon} description="Visual Effects" />
            </div>
        </div>
    )
}

export default BulletPoints;