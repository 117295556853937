import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import {
  renderCheckbox,
  renderSingleReactSelect
} from "./../../../shared/form-helpers";
import required from "./../../../shared/validation";

function CharacterForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    characters
  } = props;
  const thisCharacter = props.initialValues || {};
  const readOnly = thisCharacter.readOnly;

  const characterOptions = characters?.map((e) => {
    return { label: e.name, value: e.id };
  });

  const noCharacters = !characterOptions?.length;

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add character to scene</h2>}
      {mode === "edit" && (
        <h2 className="menu-label">Edit character in scene</h2>
      )}
      {errors && <Errors errors={errors} />}
      {noCharacters && <div>All characters already added to scene</div>}
      {!noCharacters && <form autoComplete="off" onSubmit={handleSubmit}>
        {mode === "add" && (
          <Field
            name="characterId"
            label="Character"
            component={renderSingleReactSelect}
            value={thisCharacter.characterId}
            validate={[required]}
            options={characterOptions}
            required
            readOnly={readOnly}>
          </Field>
        )}
        {mode === "edit" && (
          <div className="field">
            <label className="label">
              Character
            </label>
            <div className="control">
              {thisCharacter.characterName}
            </div>
          </div>
        )}

        <div className="field">
          <Field
            name="hasDialog"
            label="Has dialog?"
            component={renderCheckbox}
            readOnly={readOnly}
          />
        </div>

        <div className="field">
          <Field
            name="onlyOffScene"
            label="Only off-scene"
            component={renderCheckbox}
            readOnly={readOnly}
          />
        </div>

        {!readOnly && <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisCharacter)}>
              Remove from scene
            </button>
          )}
        </div>}
      </form>}
    </>
  );
}

export default reduxForm({
  form: "CharacterForm",
  enableReinitialize: true
})(CharacterForm);
