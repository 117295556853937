import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "./../../../notifications/ducks/actions";

export const UPDATE_SCRIPT = "UPDATE_SCRIPT";
export const SCRIPT_EDIT_MODE = "SCRIPT_EDIT_MODE";
export const SCRIPT_SPLIT_MODE = "SCRIPT_SPLIT_MODE";
export const DOWNLOADED_SCRIPT = "DOWNLOADED_SCRIPT";
export const QUEUE_DOWNLOAD_SCRIPT = "QUEUE_DOWNLOAD_SCRIPT";
export const CLEAR_DOWNLOAD_SCRIPT = "CLEAR_DOWNLOAD_SCRIPT";
export const RESET_DOWNLOAD_SCRIPT = "RESET_DOWNLOAD_SCRIPT";
export const SHOW_SPLIT_SCENE = "SHOW_SPLIT_SCENE";
export const SPLIT_SCRIPT = "SPLIT_SCRIPT";
export const UNSPLIT_SCRIPT = "UNSPLIT_SCRIPT";
export const SHOW_MOVE_SPLIT_SCENE = "SHOW_MOVE_SPLIT_SCENE";
export const FINISH_MOVING_SPLIT_SCENE = "FINISH_MOVING_SPLIT_SCENE";

export function fetchScript(filmId, clear = true) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCRIPT",
      payload: getApiClient().get(`/film/${filmId}/script`),
      meta: { clear }
    });
  };
}

export function updateScript(filmId, script) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SCRIPT,
      payload: getApiClient().put(`/film/${filmId}/script`,
        JSON.stringify({ scenes: script })),
    })
      .then((response) => {
        dispatch(showInfo(`Successfully saved script`));
      })
      .catch((response) => {
        dispatch(showError(`Error saving script`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function splitScriptScene(filmId, scriptSceneId, elementIndex, newSceneNumber) {
  return (dispatch) => {
    dispatch({
      type: SPLIT_SCRIPT,
      payload: getApiClient().put(`/film/${filmId}/script/split`,
        JSON.stringify({ scriptSceneId, elementIndex, newSceneNumber })),
    })
      .then((response) => {
        dispatch(showInfo(`Successfully split scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error splitting scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function unSplitScriptScene(filmId, scriptSceneId) {
  return (dispatch) => {
    dispatch({
      type: SPLIT_SCRIPT,
      payload: getApiClient().put(`/film/${filmId}/script/unsplit`,
        JSON.stringify({ scriptSceneId })),
    })
      .then((response) => {
        dispatch(showInfo(`Successfully joined scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error joining scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function finishMovingSplitScene(filmId, scriptSceneId, elementIndex, fromScriptSceneId) {
  return (dispatch) => {
    dispatch({
      type: FINISH_MOVING_SPLIT_SCENE,
      payload: getApiClient().put(`/film/${filmId}/script/move`,
        JSON.stringify({ scriptSceneId, elementIndex, fromScriptSceneId })),
      meta: { scriptSceneId, elementIndex }
    })
      .then((response) => {
        dispatch(showInfo(`Sucessfully moved scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error moving scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function setSelectedItem(item) {
  return (dispatch) => {
    dispatch({
      type: "SCRIPT_SET_ITEM",
      meta: { item }
    });
  };
}

export function clearReloadScript() {
  return (dispatch) => {
    dispatch({
      type: "SCRIPT_CLEAR_RELOAD",
    });
  };
}

export function resetClearForm() {
  return (dispatch) => {
    dispatch({
      type: "SCRIPT_RESET_CLEAR_FORM"
    });
  };
}

export function editItem(item, id) {
  return (dispatch) => {
    dispatch({
      type: "SCRIPT_EDIT_ITEM",
      meta: { item, id }
    });
  };
}

export function editElement(text, sceneId, elementIndex) {
  return (dispatch) => {
    dispatch({
      type: "SCRIPT_EDIT_ELEMENT",
      meta: { text, sceneId, elementIndex }
    })
      .then((response) => {
        dispatch(showInfo(`Successfully updated script`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating script`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function setEditMode(editMode) {
  return (dispatch) => {
    dispatch({
      type: SCRIPT_EDIT_MODE,
      meta: { editMode }
    });
  };
}

export function setSplitMode(splitMode) {
  return (dispatch) => {
    dispatch({
      type: SCRIPT_SPLIT_MODE,
      meta: { splitMode }
    });
  };
}

export function downloadScript(filmId, includeTitle, showMarkup, includeDialog, includeAction, includeCharacter, includeParenthetical, includeTransition, includeGeneral, allScenes, selectedSceneIds) {
  return (dispatch) => {
    dispatch({
      type: QUEUE_DOWNLOAD_SCRIPT,
      payload: getApiClient().post(`/film/${filmId}/Script/download`,
        JSON.stringify({ includeTitle, showMarkup, includeDialog, includeAction, includeCharacter, includeParenthetical, includeTransition, includeGeneral, allScenes, selectedSceneIds })),
    });
  };
}

export function pdfDownloaded() {
  return (dispatch) => {
    dispatch({
      type: DOWNLOADED_SCRIPT
    });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DOWNLOAD_SCRIPT
    });
  };
}

export function resetPdf() {
  return (dispatch) => {
    dispatch({
      type: RESET_DOWNLOAD_SCRIPT
    });
  };
}

export function showSplitScene(sceneId, elementIndex, show) {
  return (dispatch) => {
    dispatch({
      type: SHOW_SPLIT_SCENE,
      meta: { sceneId, elementIndex, show }
    });
  };
}

export function showMoveSplitScene(scriptSceneId, sceneNumber) {
  return (dispatch) => {
    dispatch({
      type: SHOW_MOVE_SPLIT_SCENE,
      meta: { scriptSceneId, sceneNumber }
    });
  };
}
