import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { sortByName } from "../../../../shared/utility";

interface SceneSettingOverviewState {
  overview: any;
  loading: boolean;  
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'scene-settings-overview-onboarding-step-1';
export const OnboardingStep2ClassName = 'scene-settings-overview-onboarding-step-2';

const defaultState: SceneSettingOverviewState = {
  overview: {},
  loading: true,
  onboardingSteps: [      
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Updating Scene Settings',
      content: `        
        <p>Link duplicate Scene Settings (e.g. 'Bank', 'The Bank') by dragging the duplicate's name onto the preferred name to create a link.</p>
        <p>Made a mistake? Click <i class="material-icons">cancel</i> to unassign.</p>
      `,
      disableBeacon: true      
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Assign Locations to Scene Settings',
      content: `<p>Assign Locations to Scene Settings by dragging the Location's name from the list on the right into the 'Location's Name' column for the Scene Setting.</p>
                <p>You can also replace the Location for a Scene Setting by dragging the new Location's name from the list on the right onto the existing Location name.</p>
                <p>Made a mistake? Click <i class="material-icons">cancel</i> to unassign.</p>`,
      disableBeacon: true,
      nextButtonText: 'Get Started'
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SCENE_SETTING_OVERVIEW_PENDING": {
      return {
        ...state,
        loading: true,
        overview: null,
        errors: null
      };
    }

    case "FETCH_SCENE_SETTING_OVERVIEW_FULFILLED": {
      return {
        ...state,
        loading: false,
        overview: action.payload.data,
        errors: null
      };
    }

    case "CLEAR_SCENE_SETTING_OVERVIEW": {
      return {
        ...state,
        loading: false,
        overview: null,
        errors: null
      };
    }

    case "ASSIGN_LOCATION_TO_SCENE_SETTING": {
      const location = action.meta.location;
      let sceneSettings = [...state.overview.sceneSettings];
      const sceneSetting = sceneSettings.find(
        (c) => c.id == action.meta.sceneSetting.id
      );

      if (!sceneSetting.locations) {
        sceneSetting.locations = [];
      }

      sceneSetting.locations.push(location);
      
      let overview = { ...state.overview, sceneSettings };
      return {
        ...state,
        overview: overview
      };
    }

    case "UNASSIGN_LOCATION_TO_SCENE_SETTING": {
      const location = action.meta.location;
      let sceneSettings = [...state.overview.sceneSettings];
      const sceneSetting = sceneSettings.find(
        (c) => c.id == action.meta.sceneSettingId
      );

      var locationToRemoveIndex = sceneSetting.locations.findIndex((l) => l.id === location.id && l.subLocation?.id === location.subLocationId)
      sceneSetting.locations.splice(locationToRemoveIndex, 1);
      
      let overview = { ...state.overview, sceneSettings };
      return {
        ...state,
        overview: overview
      };
    }

    case "REPLACE_LOCATION_ON_SCENE_SETTING": {
      const curentLocation = action.meta.curentLocation;
      const newLocation = action.meta.newLocation;
      let sceneSettings = [...state.overview.sceneSettings];
      const sceneSetting = sceneSettings.find(
        (c) => c.id == action.meta.sceneSetting.id
      );

      if (!sceneSetting.locations) {
        sceneSetting.locations = [];
      }

      const currentIndex = sceneSetting.locations.findIndex(l => l.id === curentLocation.id && l.subLocation?.id === curentLocation.subLocation?.id);
      sceneSetting.locations.splice(currentIndex, 1);
      sceneSetting.locations.splice(currentIndex, 0, newLocation);
      
      let overview = { ...state.overview, sceneSettings };
      return {
        ...state,
        overview: overview
      };
    }

    case "ALIAS_SCENE_SETTING": {
      const aliasSceneSetting = action.meta.aliasSceneSetting;
      const mainSceneSetting = action.meta.mainSceneSetting;
      let sceneSettings = [...state.overview.sceneSettings];
      const sceneSetting = sceneSettings.find(
        (c) => c.id == mainSceneSetting.id
      );
      const aliasSceneSettingIndex = sceneSettings.findIndex(
        (c) => c.id == aliasSceneSetting.id
      );

      sceneSetting.aliases = [
        ...sceneSetting.aliases,
        aliasSceneSetting
      ];
      sceneSettings[aliasSceneSettingIndex].aliases = [];

      sceneSettings.splice(aliasSceneSettingIndex, 1);

      let overview = { ...state.overview, sceneSettings };
      return {
        ...state,
        overview: overview
      };
    }

    case "UNALIAS_SCENE_SETTING": {
      const aliasSceneSetting = action.meta.aliasSceneSetting;
      const mainSceneSetting = action.meta.mainSceneSetting;
      let sceneSettings = [...state.overview.sceneSettings];
      const sceneSetting = sceneSettings.find(
        (c) => c.id == mainSceneSetting.id
      );

      const aliasSceneSettingIndex = sceneSetting.aliases.findIndex(
        (a) => a.id == aliasSceneSetting.id
      );
      sceneSetting.aliases.splice(aliasSceneSettingIndex, 1);

      sceneSettings.push(aliasSceneSetting);

      sortByName(sceneSettings);

      let overview = { ...state.overview, sceneSettings };
      return {
        ...state,
        overview: overview
      };
    }

    case "SAVE_UNASSIGN_LOCATION_TO_SCENE_SETTING_PENDING": {
      return state;
    }

    case "SAVE_UNASSIGN_LOCATION_TO_SCENE_SETTING_FULFILLED": {
      return {
        ...state,
        errors: null
      };
    }

    case "SAVE_UNASSIGN_LOCATION_TO_SCENE_SETTING_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }

    case "SAVE_ASSIGN_LOCATION_TO_SCENE_SETTING_PENDING": {
      return state;
    }

    case "SAVE_ASSIGN_LOCATION_TO_SCENE_SETTING_FULFILLED": {
      return {
        ...state,
        errors: null
      };
    }

    case "SAVE_ASSIGN_LOCATION_TO_SCENE_SETTING_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }

    case "SAVE_REPLACE_LOCATION_IN_SCENE_SETTING_REJECTED" : {
      return state;
    }

    case "SAVE_REPLACE_LOCATION_IN_SCENE_SETTING_FULFILLED" : {
      return {
        ...state,
        errors: null
      };
    }

    case "SAVE_REPLACE_LOCATION_IN_SCENE_SETTING_REJECTED" : {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }

    case "SAVE_ALIAS_SCENE_SETTING": {
      return state;
    }

    case "SAVE_REMOVE_ALIAS_SCENE_SETTING": {
      return state;
    }

    default:
      return state;
  }
};

export default reducer;
