import React, { useEffect } from "react";
import { connect } from "react-redux";
import UserSettingsForm from "./form";
import { fetchUserSettings, updateUserSettings } from "./ducks/actions";
import Loader from "../shared/loader";
import Onboarding from '../shared/onboarding/onboarding';
import BoxWraper from "../account/box-wrapper";

const UserSettings = (props: any) => {
  const {
    fetchUserSettings,
    updateUserSettings,
    userSettings,
    loading,
    errors,
    onboardingSteps,
    weightUnitEnum,
    temperatureUnitEnum,
    windSpeedUnitEnum
  } = props;

  useEffect(() => {
    fetchUserSettings();
  }, [fetchUserSettings]);

  function onUpdateUserSettings(userSettings) {
    updateUserSettings(userSettings);
  }

  return (
    <>
      <BoxWraper>
        {loading && <Loader />}
        {!loading && (
          <UserSettingsForm
            initialValues={userSettings}
            onSubmit={onUpdateUserSettings}
            errors={errors}
            onboardingSteps={onboardingSteps}
            weightUnitEnum={weightUnitEnum}
            temperatureUnitEnum={temperatureUnitEnum}
            windSpeedUnitEnum={windSpeedUnitEnum}
            {...props}
          />
        )}
      </BoxWraper>
    </>
  );
};

const mapStateToProps = function (state) {
  const { userSettingsState, commonState } = state;

  return {
    userSettings: userSettingsState.userSettings,
    errors: userSettingsState.errors,
    loading: userSettingsState.loading,
    onboardingSteps: userSettingsState.onboardingSteps,
    weightUnitEnum: commonState.weightUnitEnum,
    heightUnitEnum: commonState.heightUnitEnum,
    distanceUnitEnum: commonState.distanceUnitEnum,
    temperatureUnitEnum: commonState.temperatureUnitEnum,
    windSpeedUnitEnum: commonState.windSpeedUnitEnum
  };
};

export default connect(mapStateToProps, {
  fetchUserSettings,
  updateUserSettings
})(UserSettings);
