import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import memoizeOne from "memoize-one";
import DeleteIcon from "../../icons/delete";
import EditIcon from "../../icons/edit";
import TickIcon from "../../icons/tick";
import "./shot-list-schedule.scss";
import Shot from "./shot";
import Time from "../../../shared/time";
import confirm from "../../../shared/modal/confirm";
import { OnboardingStep4ClassName, OnboardingStep5ClassName } from "./ducks/reducer";
import { formatTime, parseTimeToMinutes, formatTimeFromSeconds, formatTimeFromMinutes } from "../../../shared/format-time";

const TimeSlot = ({
  filmId,
  shootingdayId,
  index,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  readOnly,
  timeSlot,
  deleteTimeSlot,
  draggingShotId,
  selectedShotIds,
  deleteShot,
  crewCallHour,
  estimatedWrapHour,
  estimatedWrapTime,
  timeSlotStartTimeChanged,
  nextTimeSlot,
  canAccessScenes,
  canAccessShots
}) => {

  const [editMode, setEditMode] = useState(false);
  const [time, setTime] = useState(timeSlot.startTime);
  const [description, setDescription] = useState(timeSlot.description);

  const onEditTimeSlot = () => {
    setTime(timeSlot.startTime);
    setDescription(timeSlot.description);
    setEditMode(true);
  };

  type SceneIdMap = {
    [sceneId: string]: true;
  };

  const getSelectedMap = memoizeOne((selectedSceneIds: string[]) =>
    selectedSceneIds.reduce(
      (previous: SceneIdMap, current: string): SceneIdMap => {
        previous[current] = true;
        return previous;
      },
      {}
    )
  );

  const doneEditing = () => {
    timeSlotStartTimeChanged(timeSlot.startTime, time, description);
    setEditMode(false);
  }

  const onStartTimeChanged = (newValue) => {
    setTime(newValue);
  };

  const onDeleteTimeSlot = (startTime) => {
    confirm(() => deleteTimeSlot(startTime), formatTime(startTime));
  };

  let firstSceneTimeSlot = true;

  const totalDurationMinutes = timeSlot.totalDurationMinutes;

  const endTime = nextTimeSlot?.startTime ?? estimatedWrapTime;

  const startTimeSlotTotalMinutes = parseTimeToMinutes(timeSlot?.startTime) ?? 0;
  const endTimeSlotTotalMinutes = parseTimeToMinutes(endTime) ?? 0;
  const tooLong = (totalDurationMinutes > (endTimeSlotTotalMinutes - startTimeSlotTotalMinutes));


  return (

    <Droppable
      droppableId={timeSlot.startTime.days + ":" + timeSlot.startTime.hours + ":" + timeSlot.startTime.minutes}
      type={
        timeSlot.type == 1
          ? "shot" + timeSlot.sceneSetting?.id
          : "crew"}
    >
      {(innerProvided, snapshot) => (
        <tbody
          key={index}
          {...innerProvided.droppableProps}
          ref={innerProvided.innerRef}>
          <tr className="table-heading">
            <td colSpan={5} className="time-slot-heading">
              {!editMode &&
                <p>
                  {formatTime(timeSlot.startTime)}{" "}
                  {timeSlot.type === 1
                    ? (timeSlot.scene?.number ? timeSlot.scene?.number + " " : "") + timeSlot.sceneSetting?.name + (timeSlot.sceneSetting?.location ? " - " + timeSlot.sceneSetting?.location?.name + (timeSlot.sceneSetting?.subLocation ? " (" + timeSlot.sceneSetting?.subLocation.name + ")" : "") : "")
                    : timeSlot.description}
                </p>}
              {editMode && <><Time
                label=""
                onChange={onStartTimeChanged}
                value={time}
                startTime={crewCallHour}
                endTime={estimatedWrapHour}
                canAddDay={estimatedWrapHour > 23} />
                {timeSlot.type !== 1 && <input type="text" className="input" value={description} onChange={(e) => setDescription(e.target.value)} />}
              </>}
            </td>
            <td colSpan={2} className="has-text-right">

              {(!readOnly && !editMode) && <span><a
                onClick={() =>
                  onEditTimeSlot()
                }>
                <EditIcon className={`white-icon ${index == 0 ? OnboardingStep4ClassName : ""}`} />
              </a> <a
                onClick={() =>
                  onDeleteTimeSlot(timeSlot.startTime)
                }>
                  <DeleteIcon className={`white-icon ${index == 0 ? OnboardingStep5ClassName : ""}`} />
                </a>
              </span>}
              {editMode &&
                <a onClick={doneEditing}>
                  <TickIcon className="white-icon" />
                </a>
              }
            </td>
          </tr>
          {(timeSlot.shots || []).map((shot, shotIndex) => {
            const selectionMap = getSelectedMap(
              selectedShotIds || []
            );
            //console.log("selectionMap", selectionMap);
            const isSelected = selectionMap
              ? selectionMap[shot.shotId]
                ? true
                : false
              : false;
            //console.log(shot.sceneId);
            //console.log(shot.shotId, isSelected);
            const isGhosting: boolean =
              isSelected &&
              Boolean(draggingShotId) &&
              draggingShotId !== shot.shotId;
            const firstScene = firstSceneTimeSlot;
            if (firstSceneTimeSlot) {
              firstSceneTimeSlot = false;
            }
            return (
              <Shot
                filmId={filmId}
                shootingdayId={shootingdayId}
                shot={shot}
                index={shotIndex}
                key={shot.shotId}
                timeSlot={timeSlot.startTime}
                isSelected={isSelected}
                isGhosting={isGhosting}
                selectionCount={
                  (selectedShotIds || []).length
                }
                toggleSelection={toggleSelection}
                toggleSelectionInGroup={
                  toggleSelectionInGroup
                }
                multiSelectTo={multiSelectTo}
                deleteShot={deleteShot}
                readOnly={readOnly}
                showOnboarding={shotIndex === 0 && firstScene}
                canAccessScenes={canAccessScenes}
                canAccessShots={canAccessShots}></Shot>
            );
          })}
          {innerProvided.placeholder}
          {timeSlot.type === 1 && totalDurationMinutes > 0 && <tr>
            <td></td>
            <td></td>
            <td></td>
            <td colSpan={2} className="has-text-right">

              Total Duration

            </td>
            <td className={`${tooLong ? "is-danger" : ""}`}>{formatTimeFromMinutes(totalDurationMinutes)}</td>
            <td></td>
          </tr>}
        </tbody>
      )}
    </Droppable>
  );
};

export default TimeSlot;
