import { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../../shared/loader";
import { fetchCollaboratorsInRoles } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";

import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListUsersContainer(props) {
  const { filmId, roleId } = props.match.params;
  const { fetchCollaboratorsInRoles, loading, collaborators, errors, readOnly, onboardingSteps } = props;

  useEffect(() => {
    fetchCollaboratorsInRoles(filmId, roleId);
  }, [filmId, fetchCollaboratorsInRoles]);

  function renderRoles(collaborators, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/collaborators/in-role/:roleId"
        loading={loading}>
        {loading && <Loader />}
        {!loading && <List collaborators={collaborators} filmId={filmId} />}


      </ScrollIntoView>
    );
  }

  return renderRoles(collaborators, filmId);
}

function mapStateToProps(state) {
  const { collaboratorsInRoleState } = state;
  return {
    collaborators: collaboratorsInRoleState.collaborators,
    readOnly: collaboratorsInRoleState.readOnly,
    loading: collaboratorsInRoleState.loading,
    errors: collaboratorsInRoleState.errors
  };
}

export default connect(mapStateToProps, {
  fetchCollaboratorsInRoles
})(ListUsersContainer);
