import { sortByName } from "./../../../shared/utility";
import { combineReducers } from "redux";
import overviewReducer from "./../overview/ducks/reducer";
import mapPictures from "../../../shared/image-mapper";

interface SceneSettingOverviewState {
  sceneSetting: any;
  sceneSettings: any[];
  loading: boolean;
  readOnly: boolean;
}

const defaultState: SceneSettingOverviewState = {
  sceneSetting: {},
  sceneSettings: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SCENESETTINGS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "CLEAR_SCENESETTING": {
      return {
        ...state,
        errors: null,
        sceneSetting: null,
        loading: false
      };
    }

    case "FETCH_SCENESETTINGS_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        readOnly: action.payload.data.readOnly,
        loading: false
      };
    }

    case "ALIAS_SCENE_SETTING": {
      const { aliasSceneSetting } = action.meta;
      const sceneSettings = Array.from(state.sceneSettings);
      const aliasIndex = sceneSettings.findIndex(s => s.id === aliasSceneSetting.id);
      sceneSettings.splice(aliasIndex, 1);

      return {
        ...state,
        sceneSettings: sceneSettings
      };
    }

    case "UNALIAS_SCENE_SETTING": {
      const { aliasSceneSetting } = action.meta;
      const sceneSettings = Array.from(state.sceneSettings);
      sceneSettings.push(aliasSceneSetting);
      sortByName(sceneSettings);

      return {
        ...state,
        sceneSettings
      };
    }

    case "FETCH_SINGLE_SCENESETTING_PENDING": {
      return {
        ...state,
        sceneSetting: null,
        loadingSceneSetting: true
      };
    }

    case "FETCH_SINGLE_SCENESETTING_FULFILLED": {
      let sceneSetting = action.payload.data;
      sceneSetting.originalLocation = sceneSetting.location;
      sceneSetting.location = sceneSetting.location;

      mapPictures(sceneSetting);

      return {
        ...state,
        sceneSetting,
        loadingSceneSetting: false,
        errors: null
      };
    }



    case "UPDATE_SINGLE_SCENESETTING_PENDING": {
      return {
        ...state,
        sceneSetting: action.payload,
        loadingSceneSetting: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_SCENESETTING_FULFILLED": {
      const sceneSetting = {
        ...action.meta.sceneSetting,
        ...action.payload.data.sceneSetting
      };
      const sceneSettings: any = state.sceneSettings.map(
        (c: any, index: number) => {
          if (c.id === sceneSetting.id) {
            return { ...c, ...sceneSetting };
          } else {
            return c;
          }
        }
      );

      sortByName(sceneSettings);

      return {
        ...state,
        sceneSetting,
        sceneSettings,
        loadingSceneSetting: false,
        errors: null
        //redirect: { to: "list" },
      };
    }

    case "UPDATE_SINGLE_SCENESETTING_REJECTED": {
      return {
        ...state,
        sceneSetting: action.meta.sceneSetting,
        errors: action.payload.response.data.errors,
        loadingSceneSetting: false
      };
    }

    case "ADD_SINGLE_SCENESETTING_PENDING": {
      return {
        ...state,
        sceneSetting: action.meta.scenesetting,
        errors: null,
        loadingSceneSetting: true
      };
    }

    case "ADD_SINGLE_SCENESETTING_FULFILLED": {
      const sceneSetting: any = {
        ...state.sceneSetting,
        ...action.payload.data.sceneSetting
      };

      let sceneSettings = [sceneSetting, ...state.sceneSettings];
      sortByName(sceneSettings);
      return {
        ...state,
        sceneSetting,
        sceneSettings,
        loadingSceneSetting: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_SCENESETTING_REJECTED": {
      return {
        ...state,
        sceneSetting: action.meta.sceneSetting,
        errors: action.payload.response.data.errors,
        loadingSceneSetting: false
      };
    }

    case "UPDATE_SINGLE_SCENESETTING_ASSIGN_LOCATION_FULFILLED": {
      return {
        ...state,
        redirect: { to: "list" }
      };
    }

    case "REDIRECT_SINGLE_SCENESETTING": {
      return {
        ...state,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_SCENESETTING_ASSIGN_LOCATION_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingSceneSetting: false
      };
    }

    case "DELETE_SINGLE_SCENESETTING_PENDING": {
      return {
        ...state,
        sceneSetting: action.payload,
        loadingSceneSetting: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_SCENESETTING_FULFILLED": {
      const sceneSetting = action.meta.sceneSetting;
      const sceneSettings: any = state.sceneSettings.filter(
        (c: any, index: number) => {
          return c.id !== sceneSetting.id;
        }
      );

      return {
        ...state,
        sceneSetting: null,
        sceneSettings,
        loadingSceneSetting: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_SCENESETTING_REJECTED": {
      return {
        ...state,
        sceneSetting: action.meta.sceneSetting,
        errors: action.payload.response.data.errors,
        loadingSceneSetting: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        sceneSetting: null,
        redirect: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingSceneSetting: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingSceneSetting: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingSceneSetting: false
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  rootState: reducer,
  overviewState: overviewReducer
});
