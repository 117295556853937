import mapPictures from "../../../../../../shared/image-mapper";
import { sortByName } from "./../../../../../../shared/utility";

const defaultState = {
  member: {},
  members: [],
  loading: true,
  readOnly: false,
  phoneNumberRequired: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_MEMBERS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        redirect: null
      };
    }

    case "CLEAR_CREW_MEMBER": {
      return {
        ...state,
        loading: false,
        member: null,
        errors: null,
        redirect: null
      };
    }

    case "FETCH_MEMBERS_FULFILLED": {
      return {
        ...state,
        members: action.payload.data.crewMembers,
        readOnly: action.payload.data.readOnly,
        phoneNumberRequired: action.payload.data.phoneNumberRequired,
        backstageSearchUrl: action.payload.data.backstageSearchUrl,
        sabNetworkSearchUrl: action.payload.data.sabNetworkSearchUrl,
        loading: false,
        errors: null,
        redirect: null
      };
    }

    case "FETCH_FILM_CREW_MEMBERS_FULFILLED": {
      return {
        ...state,
        allMembers: action.payload.data.crewMembers,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null,
        redirect: null
      };
    }

    case "FETCH_SINGLE_MEMBER_PENDING": {
      return {
        ...state,
        member: null,
        loadingMember: true,
        redirect: null
      };
    }

    case "FETCH_SINGLE_MEMBER_FULFILLED": {
      let member = action.payload.data;
      mapPictures(member);

      return {
        ...state,
        member,
        loadingMember: false,
        errors: action.payload.data.hasErrors ? action.payload.data.errors : null
      };
    }

    case "UPDATE_SINGLE_MEMBER_PENDING": {
      return {
        ...state,
        member: action.payload,
        loadingMember: true,
        errors: null,
        redirect: null
      };
    }

    case "UPDATE_SINGLE_MEMBER_FULFILLED": {
      const member = {
        ...action.meta.member,
        ...action.payload.data.crewMember
      };
      const members: any = state.members.map((c: any, index: number) => {
        if (c.id === member.id) {
          return { ...c, ...member };
        } else {
          return c;
        }
      });
      sortByName(members);
      return {
        ...state,
        member,
        members,
        loadingMember: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_MEMBER_REJECTED": {
      return {
        ...state,
        member: action.meta.member,
        errors: action.payload.response.data.errors,
        loadingMember: false,
        redirect: null
      };
    }

    case "ADD_SINGLE_MEMBER_PENDING": {
      return {
        ...state,
        member: action.meta.member,
        errors: null,
        loadingMember: true,
        redirect: null
      };
    }

    case "ADD_SINGLE_MEMBER_FULFILLED": {
      const member: any = {
        ...state.member,
        ...action.payload.data.crewMember
      };
      let members = [member, ...state.members];
      sortByName(members);
      return {
        ...state,
        member,
        members,
        loadingMember: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_MEMBER_REJECTED": {
      return {
        ...state,
        member: action.meta.member,
        errors: action.payload.response.data.errors,
        loadingMember: false,
        redirect: null
      };
    }

    case "ADD_SINGLE_MEMBER_TO_ROLE_FULFILLED": {
      let members = [action.payload.data.crewMember, ...state.members];
      sortByName(members);
      return {
        ...state,
        memberId: action.meta.memberId,
        members,
        errors: null,
        loadingMember: false,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_MEMBER_TO_ROLE_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingMember: false
      };
    }

    case "DELETE_SINGLE_MEMBER_PENDING": {
      return {
        ...state,
        member: action.payload,
        loadingMember: true,
        errors: null,
        redirect: null
      };
    }

    case "DELETE_SINGLE_MEMBER_FULFILLED": {
      const member = action.meta.member;
      const members: any = state.members.filter((c: any, index: number) => {
        return c.id !== member.id;
      });

      return {
        ...state,
        member: null,
        members,
        loadingMember: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_MEMBER_REJECTED": {
      return {
        ...state,
        member: action.meta.member,
        errors: action.payload.response.data.errors,
        loadingMember: false,
        redirect: null
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        member: null,
        redirect: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingMember: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingMember: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingMember: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
