import { createRef, useEffect, useState } from "react";
import WhatsNewItem from "./whats-new-item";

const WhatsNew = (props) => {  
    const {     
      changeLogs,
      moreToLoad,
      loadMore, 
      id, 
      loading
     } = props;

   const [ignoreScroll, setIgnoreScroll] = useState<Boolean>(false);

    const refs = changeLogs.reduce((acc, value) => {
        acc[value.id] = createRef();
        return acc;
      }, {});

    let whatsNewElement;

    useEffect(() => {      
      setIgnoreScroll(false);
    }, [id]);

    useEffect(() => {
        if (!ignoreScroll && id && changeLogs.length && whatsNewElement) {          
            scrollToSection(id);
        }
    }, [id, JSON.stringify(changeLogs), whatsNewElement, refs[0]?.current?.offsetTop, ignoreScroll]);
    
    const scrollToSection = (id) => {        
        if (refs[id]?.current) {          
          //scriptElement.scrollTo(0, refs[id].current.offsetTop - 491);
          refs[id].current.scrollIntoView({ behavior: 'smooth', block: 'start' });          
        }        
      };

    const onLoadMore = () => {
      setIgnoreScroll(true);
      loadMore();
    };

    return (
    
    <div ref={(elem) => (whatsNewElement = elem)} className="whats-new-container">
      {(changeLogs || []).map((changeLog: any, index: number) => <WhatsNewItem key={index} reference={refs[changeLog.id]} changeLog={changeLog} noNavbarPadding={index > 0}/>)}

      <div className="container">
        <div className="columns is-centered">
          <div className="column is-three-quarters-tablet is-6-desktop is-6-widescreen">
            {moreToLoad && <button className={`button is-center ${loading ? "is-loading" : ""}`} onClick={onLoadMore}>Load more</button> }
          </div>
        </div>
      </div>      
    </div>
    )
}

export default WhatsNew;