import React from "react";
import { Route } from "react-router-dom";
import Actors from "./actors/container";
import BackgroundActors from "./background-actors/container";
import AddShootingDay from "./add";
import Calendar from "./calendar/container";
import DayOutOfDays from "./day-out-of-days/container";
import Settings from "./settings/container";
import CallSheet from "./call-sheet/index";
import Catering from "./catering/container";
import Crew from "./crew/container";
import Equipment from "./equipment/container";
import EditShootingDay from "./edit";
import ListShootingDaysContainer from "./list-container";
import Locations from "./locations/container";
import ShootingDayMenu from "./menu";
import Schedule from "./schedule/container";
import ShootingOrder from "./shooting-order/container";
import ShotList from "./shot-list/container";
import Weather from "./weather/container";
import ShotListSchedule from "./shot-list-schedule/container";
import Scenes from "./scenes/container";
import RiskAssessments from "./risk-assessments/container";
import Messages from "./messages/container";
import { PrivateRoute } from "../../shared/private-route";

// import RoleRoutes from "./roles/routes";

const ShootingDayRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/shooting-days"
        component={ListShootingDaysContainer}
      />
      <PrivateRoute
        path="/films/:filmId/shooting-days/shooting-order"
        component={ShootingOrder}
      />
      <PrivateRoute
        path="/films/:filmId/shooting-days/calendar"
        component={Calendar}
      />

      <PrivateRoute
        path="/films/:filmId/shooting-days/messages"
        component={Messages}
      />

      <PrivateRoute
        path="/films/:filmId/shooting-days/day-out-of-days"
        component={DayOutOfDays}
      />

      <PrivateRoute
        path="/films/:filmId/shooting-days/settings"
        component={Settings}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/add"
        component={AddShootingDay}
      />
      <PrivateRoute
        path="/films/:filmId/shooting-days/:shootingdayId"
        component={ShootingDayMenu}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/edit"
        component={EditShootingDay}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/schedule"
        component={Schedule}
      />

      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/shot-list-schedule"
        component={ShotListSchedule}
      />

      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/scenes"
        component={Scenes}
      />

      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/actors"
        component={Actors}
      />

      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/background-actors"
        component={BackgroundActors}
      />

      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/crew"
        component={Crew}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/equipment"
        component={Equipment}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/locations"
        component={Locations}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/weather"
        component={Weather}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/shot-list"
        component={ShotList}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/catering"
        component={Catering}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingdayId/call-sheet"
        component={CallSheet}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/shooting-days/:shootingDayId/risk-assessments"
        component={RiskAssessments}
      />

    </>
  );
};

export default ShootingDayRoutes;
