import { ADD_SHOOTING_DAY_ACTORS } from "./actions";

const defaultState = {
  actors: [],
  allActors: [],
  loading: true,
  readOnly: false,
  adding: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTING_DAY_ACTORS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }

    case "FETCH_SHOOTING_DAY_ACTORS_FULFILLED": {
      return {
        ...state,
        actors: action.payload.data.actors,
        allActors: action.payload.data.allActors,
        callTime: action.payload.data.actorCall,
        onSetTime: action.payload.data.shootingCall,
        readOnly: action.payload.data.readOnly,
        canAccessActors: action.payload.data.canAccessActors,
        noScenes: action.payload.data.noScenes,
        loading: false,
        errors: null,
      };
    }

    case "UPDATE_SHOOTING_DAY_ACTORS_PENDING": {
      return {
        ...state,
        errors: null,
      };
    }

    // case "UPDATE_SHOOTING_DAY_ACTORS_FULFILLED": {
    //   const shootingday = { ...action.meta.shootingday, ...action.payload.data.shootingDay };
    //   const shootingdays: any = state.shootingdays.map((c: any, index: number) => {
    //     if (c.id === shootingday.id) {
    //       return { ...c, ...shootingday };
    //     } else {
    //       return c;
    //     }
    //   });

    //   return {
    //     ...state,
    //     shootingday,
    //     shootingdays,
    //     loadingShootingDay: false,
    //     errors: null,
    //   };
    // }

    case "UPDATE_SHOOTING_DAY_ACTORS_FULFILLED": {
      return {
        ...state,
        actors: action.meta.actors.actors,
        loading: false
      };
    }

    case "UPDATE_SHOOTING_DAY_ACTORS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
      };
    }

    case ADD_SHOOTING_DAY_ACTORS + "_PENDING": {
      return {
        ...state,
        adding: true,
        errors: null,
      };
    }

    case ADD_SHOOTING_DAY_ACTORS + "_FULFILLED": {
      const actors = action.payload.data.actor ? [...state.actors, action.payload.data.actor] : state.actors;
      return {
        ...state,
        actors: actors,
        adding: false
      };
    }

    case ADD_SHOOTING_DAY_ACTORS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        adding: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
