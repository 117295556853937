import { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import AnonymousPage from "../../../account/anonymous-pages/anonymous-page";
import BoxWraper from "../../../account/box-wrapper";
import Errors from "../../../shared/errors";
import Loader from "../../../shared/loader";
import { confirm } from "./ducks/actions";
import Confirm from "./index";

const ConfirmCallSheet = (props) => {    
    const languageCode = props.match.params.languageCode;
    const token = props.match.params.token;
    const {confirm, loading, user, errors} = props;

    useEffect(() => {
        confirm(token);
    },[token]);
    
    return(
        <AnonymousPage>
            <BoxWraper width={5}>
                <Suspense fallback={<Loader/>}>
                    <Errors errors={errors} />
                    {!errors && <Confirm user={user} loading={loading} languageCode={languageCode} />}
                </Suspense>
            </BoxWraper>
        </AnonymousPage>        
    )
};

function mapStateToProps(state) {
    const { confirmCallSheetState } = state;
    return {    
        loading: confirmCallSheetState.loading,
        user: confirmCallSheetState.user,
        errors: confirmCallSheetState.errors
    };
  }
  
  export default connect(mapStateToProps, {
    confirm
  })(ConfirmCallSheet);
  