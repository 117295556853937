import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import {
  fetchBudget,
  updateRoleDailyRate,
  updateCrewRequired,
  updateCrewDays,
  updateEquipmentRequired,
  updateEquipmentDays,
  updateEquipmentDailyRate,
  updateDays,
  updateBudget,
  updateCharacterDailyRate,
  updatePropBuget,
  updatePropDays,
  updateSetDressingBuget,
  updateSetDressingDays,
  updateCostumeBuget,
  updateCostumeDays,
  updateMakeupBuget,
  updateMakeupDays,
  updateBackgroundCharacterBuget,
  updateBackgroundCharacterDays,
  updateSceneSettingDailyRate,
  updateSceneSettingDays,
  onChangeCateringBuget,
  onChangeCateringDays,
  showHideCharacters,
  showHideSceneSettings,
  showHideCrew,
  showHideEquipment,
  showHideProps,
  showHideSetDressings,
  showHideCostumes,
  showHideMakeups,
  showHideCatering,
  showHideBackgroundCharacters,
  showHideVisualEffects,
  updateContingencyPercentage,
  downloadBudget,
  clearPdf,
  updateVisualEffectBuget
} from "./ducks/actions";
import Budget from "./budget";
import Errors from "../../shared/errors";
import Loader from "../../shared/loader";
import Onboarding from '../../shared/onboarding/onboarding';
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

const EditContainer = (props) => {
  const filmId = props.match.params.id;
  const {
    fetchBudget,
    updateRoleDailyRate,
    updateCrewRequired,
    updateCrewDays,
    updateEquipmentRequired,
    updateEquipmentDays,
    updateEquipmentDailyRate,
    updateDays,
    updateBudget,
    updateCharacterDailyRate,
    updatePropBuget,
    updatePropDays,
    updateSetDressingBuget,
    updateSetDressingDays,
    updateCostumeBuget,
    updateCostumeDays,
    updateBackgroundCharacterBuget,
    updateBackgroundCharacterDays,
    updateMakeupBuget,
    updateMakeupDays,
    updateSceneSettingDailyRate,
    updateSceneSettingDays,
    onChangeCateringBuget,
    onChangeCateringDays,
    budget,
    loading,
    errors,
    showHideCharacters,
    showHideSceneSettings,
    showHideCrew,
    showHideEquipment,
    showHideProps,
    showHideSetDressings,
    showHideCostumes,
    showHideMakeups,
    showHideBackgroundCharacters,
    showHideCatering,
    showHideVisualEffects,
    charactersVisible,
    sceneSettingsVisible,
    crewVisible,
    equipmentVisible,
    propsVisible,
    setDressingsVisible,
    costumesVisible,
    makeupsVisible,
    backgroundCharactersVisible,
    visualEffectsVisible,
    cateringVisible,
    updateContingencyPercentage,
    clearPdf,
    progress,
    download,
    downloadBudget,
    onboardingStepsWithShootingDays,
    onboardingStepsWithoutShootingDays,
    saved,
    saving,
    updateVisualEffectBuget
  } = props;

  useEffect(() => {
    fetchBudget(filmId);
  }, []);

  const onUpdateBudget = (budget) => {
    updateBudget(
      filmId,
      budget,
      charactersVisible,
      backgroundCharactersVisible,
      sceneSettingsVisible,
      crewVisible,
      propsVisible,
      setDressingsVisible,
      costumesVisible,
      makeupsVisible,
      cateringVisible,
      equipmentVisible,
      visualEffectsVisible
    );
  };

  function renderFilm(budget) {
    return (
      <ScrollIntoView
        className="blade-content wide"
        path="/films/:filmId/budget"
        loading={loading}>
        <h2 className="menu-label">Film Budget</h2>
        {errors && <Errors errors={errors} />}
        <Prompt when={!saved} message="" />
        {(saving) && <Loader />}
        <Budget
          filmId={filmId}
          budget={budget}
          updateRoleDailyRate={updateRoleDailyRate}
          updateCrewRequired={updateCrewRequired}
          updateDays={updateDays}
          updateBudget={onUpdateBudget}
          updateCrewDays={updateCrewDays}
          updateEquipmentRequired={updateEquipmentRequired}
          updateEquipmentDays={updateEquipmentDays}
          updateEquipmentDailyRate={updateEquipmentDailyRate}
          updateCharacterDailyRate={updateCharacterDailyRate}
          updatePropBuget={updatePropBuget}
          updatePropDays={updatePropDays}
          updateSetDressingBuget={updateSetDressingBuget}
          updateSetDressingDays={updateSetDressingDays}
          updateCostumeBuget={updateCostumeBuget}
          updateCostumeDays={updateCostumeDays}
          updateBackgroundCharacterBuget={updateBackgroundCharacterBuget}
          updateBackgroundCharacterDays={updateBackgroundCharacterDays}
          updateMakeupBuget={updateMakeupBuget}
          updateMakeupDays={updateMakeupDays}
          updateSceneSettingDailyRate={updateSceneSettingDailyRate}
          updateSceneSettingDays={updateSceneSettingDays}
          onChangeCateringBuget={onChangeCateringBuget}
          onChangeCateringDays={onChangeCateringDays}
          showHideCharacters={showHideCharacters}
          showHideBackgroundCharacters={showHideBackgroundCharacters}
          showHideSceneSettings={showHideSceneSettings}
          showHideCrew={showHideCrew}
          showHideEquipment={showHideEquipment}
          showHideProps={showHideProps}
          showHideSetDressings={showHideSetDressings}
          showHideCostumes={showHideCostumes}
          showHideMakeups={showHideMakeups}
          showHideCatering={showHideCatering}
          showHideVisualEffects={showHideVisualEffects}
          charactersVisible={charactersVisible}
          backgroundCharactersVisible={backgroundCharactersVisible}
          sceneSettingsVisible={sceneSettingsVisible}
          crewVisible={crewVisible}
          equipmentVisible={equipmentVisible}
          propsVisible={propsVisible}
          costumesVisible={costumesVisible}
          makeupsVisible={makeupsVisible}
          setDressingsVisible={setDressingsVisible}
          cateringVisible={cateringVisible}
          visualEffectsVisible={visualEffectsVisible}
          updateContingencyPercentage={updateContingencyPercentage}
          clearPdf={clearPdf}
          progress={progress}
          download={download}
          downloadBudget={downloadBudget}
          saved={saved}
          saving={saving}
          updateVisualEffectBuget={updateVisualEffectBuget}></Budget>
        <Onboarding onboardingSteps={budget.hasShootingDays ? onboardingStepsWithShootingDays : onboardingStepsWithoutShootingDays} section="Budget" />
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !budget) && <Loader />}
      {!loading && budget && renderFilm(budget)}
    </>
  );
};

function mapStateToProps(state) {
  const { budgetState } = state;
  return {
    errors: budgetState.errors,
    loading: budgetState.loading,
    budget: budgetState.budget,
    charactersVisible: budgetState.charactersVisible,
    sceneSettingsVisible: budgetState.sceneSettingsVisible,
    crewVisible: budgetState.crewVisible,
    equipmentVisible: budgetState.equipmentVisible,
    propsVisible: budgetState.propsVisible,
    costumesVisible: budgetState.costumesVisible,
    makeupsVisible: budgetState.makeupsVisible,
    setDressingsVisible: budgetState.setDressingsVisible,
    backgroundCharactersVisible: budgetState.backgroundCharactersVisible,
    cateringVisible: budgetState.cateringVisible,
    visualEffectsVisible: budgetState.visualEffectsVisible,
    download: budgetState.download,
    progress: budgetState.progress,
    onboardingStepsWithShootingDays: budgetState.onboardingStepsWithShootingDays,
    onboardingStepsWithoutShootingDays: budgetState.onboardingStepsWithoutShootingDays,
    saved: budgetState.saved,
    saving: budgetState.saving
  };
}

export default connect(mapStateToProps, {
  fetchBudget,
  updateRoleDailyRate,
  updateCrewRequired,
  updateCrewDays,
  updateEquipmentRequired,
  updateEquipmentDays,
  updateEquipmentDailyRate,
  updateDays,
  updateBudget,
  updateCharacterDailyRate,
  updatePropBuget,
  updatePropDays,
  updateSetDressingBuget,
  updateSetDressingDays,
  updateBackgroundCharacterBuget,
  updateBackgroundCharacterDays,
  updateCostumeBuget,
  updateCostumeDays,
  updateMakeupBuget,
  updateMakeupDays,
  updateSceneSettingDailyRate,
  updateSceneSettingDays,
  onChangeCateringBuget,
  onChangeCateringDays,
  showHideCharacters,
  showHideSceneSettings,
  showHideCrew,
  showHideEquipment,
  showHideProps,
  showHideCostumes,
  showHideMakeups,
  showHideBackgroundCharacters,
  showHideSetDressings,
  showHideCatering,
  showHideVisualEffects,
  updateContingencyPercentage,
  downloadBudget,
  clearPdf,
  updateVisualEffectBuget
})(EditContainer);
