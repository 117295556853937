const localStorageName = 'invitations';
const referralCodeStorageName = 'referralCode';

const addInvitation = (data) => {
  let invitations: any[] = getInvitations();

  if (invitations.indexOf(data) === -1) {
    invitations.push(data);
    localStorage.setItem(localStorageName, JSON.stringify((invitations || {})));
  }
};

const addReferralCode = (referralCode) => {
  localStorage.setItem(referralCodeStorageName, referralCode);
}

const getReferralCode = () => {
  return localStorage.getItem(referralCodeStorageName);
}

const getInvitations = () => {
  let invitations: any[] = [];
  let invitationsItem: any = localStorage.getItem(localStorageName);
  if (invitationsItem) {
    invitations = JSON.parse(invitationsItem);
  }

  return invitations;
};

const removeInvitations = () => {
  localStorage.removeItem(localStorageName);
}

const removeReferralCode = () => {
  localStorage.removeItem(referralCodeStorageName);
}

export default {
  addInvitation,
  getInvitations,
  removeInvitations,
  addReferralCode,
  getReferralCode,
  removeReferralCode
};
