export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="27.422" height="29.645" viewBox="0 0 22.208 22.732" {...props}>
    <g id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected" transform="translate(0.5 0.706)">
      <g id="Group_3843" data-name="Group 3843">
        <g id="Layer_1" data-name="Layer 1" transform="translate(12.361 0.332)">
          <line id="Line_707" data-name="Line 707" x2="5.511" transform="translate(2.867 3.834)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <line id="Line_708" data-name="Line 708" x2="5.511" transform="translate(2.867 6.02)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <line id="Line_709" data-name="Line 709" x2="5.511" transform="translate(2.867 8.291)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <line id="Line_710" data-name="Line 710" x2="5.511" transform="translate(2.867 10.563)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <line id="Line_711" data-name="Line 711" x2="5.511" transform="translate(2.867 12.834)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <line id="Line_712" data-name="Line 712" x2="5.511" transform="translate(2.867 15.106)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <line id="Line_713" data-name="Line 713" x2="5.511" transform="translate(2.867 17.378)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32852" data-name="Path 32852" d="M15.792,9.91h5.124a1,1,0,0,0,1.035-.965h0a1,1,0,0,0-1.035-.964H14.833a1.654,1.654,0,0,0-1.709,1.592l.02,18.01a1.654,1.654,0,0,0,1.709,1.592h6.083a1,1,0,0,0,1.035-.964h0a1,1,0,0,0-1.035-.964H15.812Z" transform="translate(-13.124 -7.981)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        </g>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Group_3842" data-name="Group 3842">
            <g id="Group_3841" data-name="Group 3841">
              <path id="Path_32853" data-name="Path 32853" d="M9.557,21.412v7.752H3.139V21.412" transform="translate(-2.763 -7.649)" fill="none" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1" />
              <path id="Path_32854" data-name="Path 32854" d="M9.97,18.93H2.763v2.376H9.97V18.93Z" transform="translate(-2.763 -7.649)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
              <path id="Path_32855" data-name="Path 32855" d="M9.057,18.806V14.851H3.681v3.955" transform="translate(-2.763 -7.649)" fill="none" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1" />
              <line id="Line_714" data-name="Line 714" y1="3.315" transform="translate(1.455 3.862)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
              <path id="Path_32856" data-name="Path 32856" d="M7.847,8.558c1.1,1.835.756,6.307.756,6.307" transform="translate(-2.763 -7.649)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
              <ellipse id="Ellipse_73" data-name="Ellipse 73" cx="2.464" cy="1.088" rx="2.464" ry="1.088" transform="matrix(0.672, -0.741, 0.741, 0.672, 0.838, 3.651)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
