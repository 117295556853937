export default (props) =>
  <svg id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 22 19"  {...props}>
    <g id="Group_3847" data-name="Group 3847">
      <g id="Rectangle_18" data-name="Rectangle 18" transform="translate(6)" fill="none" strokeWidth="1">
        <rect width="16" height="5" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="4" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_19" data-name="Rectangle 19" transform="translate(0 7)" fill="none" strokeWidth="1">
        <rect width="16" height="5" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="4" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_20" data-name="Rectangle 20" transform="translate(6 14)" fill="none" strokeWidth="1">
        <rect width="16" height="5" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="4" rx="0.5" fill="none" />
      </g>
    </g>
  </svg>
