import React from "react";
import AnonymousPage from "../../account/anonymous-pages/anonymous-page";
import BoxWraper from "../../account/box-wrapper";
import Container from "./container";

function Contact(props) {
  return (
    <AnonymousPage>
      <BoxWraper>
        <Container inApp={false} />
      </BoxWraper>
    </AnonymousPage>
  );
}

export default Contact;