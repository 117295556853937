import Moment from "react-moment";
import WhatsNewIcon from "../../../films/icons/whats-new";
import EnchancementIcon from "../../../films/icons/enchancement";
import NewFeatureIcon from "../../../films/icons/new-feature";

import './change-log-help.scss';
import CkEditorContent from "../../../shared/controls/ckeditor-content";
import { Link } from "react-router-dom";

function WhatsNewItem(props) {
  const {
    changeLog,
    noNavbarPadding,
    reference
  } = props;

  return (
    <section ref={reference} className="change-log-item">
      {(changeLog?.changes || []).map((change, index) => (
        <div key={index} className="change-log-change-help">
          <div className="change-log-change-help-item-header">
            {change.changeType === 1 && <div className="change-log-change-help-item-header-tag" style={{ backgroundColor: "#00ffce" }}><NewFeatureIcon className="change-log-icon-help" /><p>New Feature</p></div>}
            {change.changeType === 2 && <div className="change-log-change-help-item-header-tag" style={{ backgroundColor: "#F75A65" }}><EnchancementIcon className="change-log-icon-help" /><p>Bug</p></div>}
            {change.changeType === 3 && <div className="change-log-change-help-item-header-tag" style={{ backgroundColor: "#947eed" }}><EnchancementIcon className="change-log-icon-help" /><p>Enhancement</p></div>}
            {change.changeType === 4 && <div className="change-log-change-help-item-header-tag" style={{ backgroundColor: "#F9E26E" }}><WhatsNewIcon className="change-log-icon-help" /><p>Announcement</p></div>}
            <div className="change-log-date-container-help">
              <Moment className="change-log-date-help" fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{changeLog.releaseDate}</Moment>
            </div>
          </div>
          <div className="change-log-change-text-help">
            <div className="change-log-headline">{change.headline}</div>
            <CkEditorContent className="on-white" content={change.details} />
            {change.featureRequests?.length > 0 && <div className="change-log-feature-request-container">
              <div className="change-log-feature-request">Adds the functionality from {change.featureRequests?.length > 1 ? "these" : "this"} feature request{change.featureRequests?.length > 1 ? "s" : ""}:</div>
              {change.featureRequests.map(featureRequest => <div className="ckeditor-content"><Link className="" to={`/feature-request/${featureRequest.id}`}>{featureRequest.title}</Link></div>)}
            </div>}
          </div>
        </div>
      ))}

    </section>
  );
}

export default WhatsNewItem;
