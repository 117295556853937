import React from "react";
import Moment from "react-moment";
import { reduxForm } from "redux-form";
import { useTranslation } from 'react-i18next';
import Errors from "./../../../shared/errors";
import "./shooting-days-weather.scss";
import { formatTime } from "../../../shared/format-time";

function ShootingDayWeatherForm(props) {
  const { errors, weather } = props;

  const { t } = useTranslation(['confirm']);

  let weatherNotFoundReason: any = null;
  if (weather?.tooFarAhead) {
    weatherNotFoundReason = <div>{t('Too far ahead to get weather')}</div>;
  }

  if (weather?.inThePast) {
    weatherNotFoundReason = <div>{t('Date is in the past')}</div>;
  }

  if (weather?.serviceError) {
    weatherNotFoundReason = <div>{t('Weather service error')}</div>;
  }

  let weatherHeader: any = null;
  let dailyWeather: any = null;

  if (weather?.success && !weather?.tooFarAhead && !weather?.inThePast && !weather?.serviceError) {

    const observation = weather?.dayOverview;
    dailyWeather = observation ? (
      <div>
        <h2 className="is-size-6">{t('Overview')}</h2>
        <div className="observation">
          <div className="icon-cell">&nbsp;</div>
          <div className="header">{t('Sunrise')}</div>
          <div className="header">{t('Sunset')}</div>
          <div className="header">{t('Min')} {t('Temperature')}</div>
          <div className="header">{t('Max')} {t('Temperature')}</div>
          <div className="header">{t('Min')} {t('Humidity')}</div>
          <div className="header">{t('Max')} {t('Humidity')}</div>
          <div className="header">{t('Avg')} {t('Precipitation')}</div>
          <div className="header">{t('Avg')} {t('Cloud Cover')}</div>
          <div className="header">{t('Max')} {t('Wind Speed')}</div>
          <div className="header">{t('Max')} {t('Wind Gust')}</div>
          <div className="header">{t('Avg')} {t('Wind Direction')}</div>
        </div>
        <div className="observation">
          <div className="icon-cell">
            <img
              src={"/images/weather-icons/white/" + observation.icon}
              width="30"
              className="icon"
              alt={observation.description}
            />
          </div>
          <div>{formatTime(weather.sunriseSunset.sunrise)}</div>
          <div>{formatTime(weather.sunriseSunset.sunset)}</div>
          <div>
            {observation.minimumTemperature.value}&deg;{observation.minimumTemperature.unit}
          </div>
          <div>
            {observation.maximumTemperature.value}&deg;{observation.maximumTemperature.unit}
          </div>
          <div>
            {observation.minimumHumidity.value}
            {observation.minimumHumidity.unit}
          </div>
          <div>
            {observation.maximumHumidity.value}
            {observation.maximumHumidity.unit}
          </div>
          <div>
            {observation.averagePrecipitationProbability?.value}
            {observation.averagePrecipitationProbability?.unit}
          </div>
          <div>
            {observation.averageCloudCover?.value}
            {observation.averageCloudCover?.unit}
          </div>
          <div>
            {observation.maximumWindSpeed.value}&nbsp;{observation.maximumWindSpeed.unit}
          </div>
          <div>
            {observation.maximumWindGust?.value}&nbsp;{observation.maximumWindGust?.unit}
          </div>
          <div><span className="material-icons" style={{ transform: `rotate(${observation.averageWindDirection.value + 270}deg)` }}>keyboard_backspace</span>
            {/* {observation.averageWindDirection.value}&deg;  */}
          </div>
        </div>
      </div>
    ) : null;

    weatherHeader = (
      <>
        <div className="weather-header-container">
          <div className="observation weather-header">
            <div className="header">{t('Time')}</div>
            <div className="icon-cell">&nbsp;</div>
            <div className="header">{t('Temperature')} (&deg;{weather?.temperatureUnit})</div>
            <div className="header">{t('Humidity')}</div>
            <div className="header">{t('Precipitation')} </div>
            <div className="header">{t('Cloud Cover')}</div>
            <div className="header">{t('Wind Speed')} ({weather?.windSpeedUnit})</div>
            <div className="header">{t('Wind Gust')} ({weather?.windSpeedUnit})</div>
            <div className="header">{t('Wind Direction')}</div>
          </div>
        </div>
      </>
    );

  }

  let weatherForecasts: any[] = [];
  if (!weather?.tooFarAhead && !weather?.inThePast) {
    weatherForecasts = (weather?.weather || []).map(
      (observation, idx, records) => {
        return (
          <>
            <div key={idx} className="observation">
              <div className="header">
                <Moment format="HH:mm">{observation.observationTime}</Moment>
              </div>
              <div className="icon-cell">
                <img
                  src={"/images/weather-icons/white/" + observation.icon}
                  width="30"
                  className="icon"
                  alt={observation.description}
                />
              </div>
              <div>
                {observation.temperature.value}
              </div>
              <div>
                {observation.humidity.value}
                {observation.humidity.unit}
              </div>
              <div>
                {observation.precipitationProbability?.value}
                {observation.precipitationProbability?.unit}
              </div>
              <div>
                {observation.cloudCover?.value}
                {observation.cloudCover?.unit}
              </div>
              <div>
                {observation.windSpeed.value}
              </div>
              <div>
                {observation.windGust?.value}
              </div>
              <div><span className="material-icons" style={{ transform: `rotate(${observation.windDirection.value + 270}deg)` }}>keyboard_backspace</span>
                {/* {observation.windDirection.value}&deg;  */}
              </div>
            </div>
          </>
        );
      }
    );
  }

  return (
    <div className="shooting-days-weather">
      {errors && <Errors errors={errors} />}
      {!errors && <>
        {weatherNotFoundReason}
        {dailyWeather}
        <div className="clear"></div>
        {weatherForecasts.length > 0 &&
          <>
            <div>
              <h2 className="is-size-6">{t('Hourly')}</h2>
              <div className="hourly-container">
                {weatherHeader}
                {weatherForecasts}
              </div>
            </div>
          </>}
      </>}
    </div>
  );
}

export default reduxForm({
  form: "shootingDayWeatherForm",
  enableReinitialize: true
})(ShootingDayWeatherForm);
