import React, { useEffect, useRef } from "react";
import { CSVLink, CSVDownload } from 'react-csv';
import { Field, reduxForm, FieldArray } from "redux-form";
import { select, pie, arc, schemeDark2, interpolate } from 'd3';
import { scaleOrdinal } from 'd3-scale';
import FormErrors from "../../shared/form-errors";
import Errors from "../../shared/errors";
import { Link } from "react-router-dom";

function UsersForm(props) {
  const {
    handleSubmit,
    errors,
    submitFailed,
    invalid,
    countries,
    refresh,
    error
  } = props;

  const svgRef: any = useRef();

  const csvData = countries?.map((country: any) => {
    return [country.countryName, country.totalUsers, country.percentage, country.totalUsersWithFilms, country.totalUsersWithMoreThanOneLogin];
  });

  const entries = function (data: any) {
    var entries: any = [];
    for (var property in data) { entries.push({ key: property, value: data[property] }) };
    return entries;
  };

  const drawPie = (countries) => {
    // set the dimensions and margins of the graph
    var width = 600,
      height = 500,
      margin = 40;

    let sizes = {
      innerRadius: 50,
      outerRadius: 100
    };

    let durations = {
      entryAnimation: 2000
    };

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    var radius = Math.min(width, height) / 2 - margin;

    const svg: any = select(svgRef.current!);

    // append the svg object to the div called 'my_dataviz'
    var svg2: any = svg.select("#my_dataviz")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // Create dummy data
    var data = { US: 21.2, "United Kingdom": 19.4, "Australia": 14.3, "India": 7.2, "Germany": 5.1, "New Zealand": 3.5, g: 2.7, h: 2.1 };
    //var data = { US: 21.2, "United Kingdom": 19.4, "Australia": 14.3, "India": 7.2, "Germany": 5.1, "New Zealand": 3.5, g: 2.7, h: 2.1 }

    const countryNames = countries.map((country: any) => { return country.countryName });
    // set the color scale
    var color = scaleOrdinal()
      .domain(countryNames)
      //.domain(Object.entries(data).map(d => d[0]))
      .range(["#947eed", "#00ffce", "#5bc0de", "#ee5f5b", "#fef3b3", "#5cb778", "#fdabd3", "#ff9800", "#1db5ab"]);

    // Compute the position of each group on the pie:
    var pie2: any = pie()
      .sort(null) // Do not sort group by size
      .value(function (d: any) { return d.value; })

    let angleInterpolation = interpolate(pie2.startAngle()(), pie2.endAngle()());

    const countriesData = countries.map((country: any) => { return { key: country.countryName, value: country.percentage } });
    var data_ready = pie2(countriesData);
    //var data_ready = pie2(entries(data));

    //var data_ready = pie2(data)

    // The arc generator
    var arc2: any = arc()
      .innerRadius(radius * 0.5)         // This is the size of the donut hole
      .outerRadius(radius * 0.8)

    // Another arc that won't be drawn. Just for labels positioning
    var outerArc = arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9)

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    let arcs = svg
      .selectAll('allSlices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc2)
      .attr('fill', (d: any) => (color(d.data.key)))
      .attr("stroke", "#162135")
      .style("stroke-width", "0px")
      .style("opacity", 1)

    // Add the polylines between chart and labels:

    const polyline = svg
      .selectAll('allPolylines')
      .data(data_ready);

    var totalLength = 50;

    function calculateCallout(d: any): any {
      var posA: any = arc2.centroid(d) // line insertion in the slice
      var posB: any = outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
      var posC: any = outerArc.centroid(d); // Label position = almost the same as posB
      var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
      posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
      return [posA, posB, posC]
    }

    const distance = (firstObject, secondObject) => {
      const firstObjectX = firstObject[0];
      const firstObjectY = firstObject[1];
      const secondObjectX = secondObject[0];
      const secondObjectY = secondObject[1];
      return Math.sqrt((firstObjectX - secondObjectX) * (
        firstObjectX - secondObjectX) + (firstObjectY - secondObjectY)
        * (firstObjectY - secondObjectY));
    };

    const getTotalLength = (d) => {
      let path = calculateCallout(d);
      const firstDistance = distance(path[0], path[1]);
      const secondDistance = distance(path[1], path[2]);
      const totalLength = firstDistance + secondDistance;
      return totalLength;
    };

    const calloutLineDuration = 1000;
    const calloutTextDuration = 200;
    const minimumAngleToDisplayCallout = 0.08;

    polyline.enter()
      .append('polyline')
      .attr("stroke", "white")
      .style("fill", "none")
      .attr("stroke-width", 2)
      .attr('points', calculateCallout)
      .attr("opacity", function (d) {
        const angle = d.endAngle - d.startAngle;
        return angle < minimumAngleToDisplayCallout ? 0 : 1;
      })
      .attr("stroke-dasharray", function (d) {
        const totalLength = getTotalLength(d);
        return totalLength + " " + totalLength
      })
      .attr("stroke-dashoffset", function (d) { return getTotalLength(d); })
      .transition()
      .delay(function (d: any, i) {
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return (midangle / 8) * durations.entryAnimation;
      })
      .duration(calloutLineDuration)
      .attr("stroke-dashoffset", 0)

    // Add the polylines between chart and labels:
    svg
      .selectAll('allLabels')
      .data(data_ready)
      .enter()
      .append('text')
      .text(function (d: any) { return d.data.key + ": " + (Math.round(d.data.value * 10) / 10) + "%" })
      .attr('transform', function (d: any) {
        var pos = outerArc.centroid(d);
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
        pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        pos[1] += 5;
        return 'translate(' + pos + ')';
      })
      .style('text-anchor', function (d: any) {
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
        return (midangle < Math.PI ? 'start' : 'end')
      })
      .style("font-family", "Manrope")
      .attr("fill", "white")
      .style("opacity", 0)
      .transition()
      .delay(function (d, i) { return calloutLineDuration - calloutTextDuration + ((d.endAngle / 8) * durations.entryAnimation); })
      .duration(calloutTextDuration)
      .style("opacity", function (d) {
        const angle = d.endAngle - d.startAngle;
        return angle < minimumAngleToDisplayCallout ? 0 : 1;
      })

    arcs.transition()
      .duration(durations.entryAnimation)
      .attrTween("d", function (d: any) {
        let originalEnd = d.endAngle;
        return t => {
          let currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {
            return "";
          }

          d.endAngle = Math.min(currentAngle, originalEnd);

          return arc2(d);
        };
      });
  }

  useEffect(() => {
    if (countries.length) {
      drawPie(countries);
    }
  }, [countries?.length]);

  return (
    <>
      <h2 className="menu-label">User Countries</h2>
      <svg width={1400} height={700}>
        <g transform="translate(710 350) scale(1.75 1.75)">
          <g ref={svgRef}>
          </g>
        </g>
      </svg>

      {errors && <Errors errors={errors} />}
      <button className="button mb-1" onClick={refresh}>Refresh</button>
      <Link to="/admin/users/countries/dates">Dates</Link>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Country</th>
                <th>Total Users</th>
                <th>Percentage</th>
                <th>Total Users With Films</th>
                <th>Total Users With More than one login</th>
              </tr>
            </thead>
            <tbody>
              {(countries || []).map((country, idx, records) => {
                return (
                  <tr key={idx}>
                    <td>{country.countryName}</td>
                    <td>{country.totalUsers}</td>
                    <td>{country.percentage}</td>
                    <td>{country.totalUsersWithFilms}</td>
                    <td>{country.totalUsersWithMoreThanOneLogin}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {csvData && <CSVLink className="button mb-3 mt-1" data={csvData} filename={`User Countries ${convertDateToUTCISO(new Date())}`}>Export</CSVLink>}
          {error && <div className="error">{error}</div>}
        </div>
        <div>
          Total Countries: {countries.length}
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export const convertDateToUTCISO = (date: Date) => {
  var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(),
    date.getDate(), 0,
    0, 0));
  return utcDate.toISOString().substring(0, 10);
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true
})(UsersForm);
