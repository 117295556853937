import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "./../../shared/loader";
import confirm from "../../shared/modal/confirm";
import {
  clearRedirect,
  deleteShootingDay,
  fetchShootingDay,
  updateShootingDay
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function EditShootingDayContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchShootingDay,
    updateShootingDay,
    deleteShootingDay,
    loadingShootingDay,
    shootingday,
    errors
  } = props;

  useEffect(() => {
    if (shootingdayId !== "add" && shootingday?.id !== shootingdayId) {
      fetchShootingDay(filmId, shootingdayId);
    }
  }, [filmId, shootingdayId]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      const url = `/films/${filmId}/shooting-days`;
      props.clearRedirect();
      return <Redirect to={url} />;
    }
  }

  if (shootingdayId === "add") {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderShootingDay(shootingday) {
    // If the date has time component, the input type date is not shown
    shootingday.date = shootingday.date?.substring(0, 10);

    return (
      <ScrollIntoView
        className="blade-content wide"
        path="/films/:filmId/shooting-days/:shootingdayId/edit"
        loading={loadingShootingDay}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          shootingdayId={shootingday.id}
          initialValues={shootingday}
          loading={loadingShootingDay}
          onSubmit={onUpdateShootingDay}
          handleDelete={onDeleteShootingDay}
          showNumberField={shootingday.showNumberField}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateShootingDay(shootingday) {
    sanitiseDataTypes(shootingday);
    updateShootingDay(filmId, shootingday);
  }

  function onDeleteShootingDay(shootingday) {
    confirm(() => deleteShootingDay(filmId, shootingday), shootingday?.number);
  }

  function sanitiseDataTypes(shootingday) { }

  return !loadingShootingDay && shootingday?.id == shootingdayId
    ? renderShootingDay(shootingday)
    : renderLoading();
}

function mapStateToProps(state) {
  const s = state.shootingdaysState.rootState;
  return {
    shootingdays: s.shootingdays,
    shootingday: s.shootingday,
    redirect: s.redirect,
    loadingShootingDay: s.loadingShootingDay,
    errors: s.errors
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchShootingDay,
    updateShootingDay,
    deleteShootingDay,
    clearRedirect
  })(EditShootingDayContainer)
);
