import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../../shared/modal/confirm";
import Loader from "./../../../shared/loader";
import {
  clearDepartment,
  clearRedirect,
  deleteDepartment,
  fetchDepartment,
  updateDepartment
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function EditDepartmentContainer(props) {
  const { filmId, departmentId } = props.match.params;
  const {
    clearDepartment,
    updateDepartment,
    deleteDepartment,
    loadingDepartment,
    errors,
    readOnly
  } = props;

  useEffect(() => {
    if (departmentId !== "add") {
      clearDepartment();
      //fetchDepartment(filmId, departmentId);
    }
  }, [filmId, departmentId, clearDepartment]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/departments/`;
      return <Redirect to={url} />;
    }
  }

  function getDepartment() {
    if (!props.departments || !props.departments.length) {
      return null;
    }

    return props.departments.find((d) => d.id == departmentId);
  }

  if (departmentId == "add" || departmentId == "overview" || departmentId == "upload") {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderDepartment(department) {
    return (
      <ScrollIntoView
        className="blade-content"
        path="/films/:filmId/departments/:departmentId/edit"
        loading={loadingDepartment}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          departmentId={department.id}
          initialValues={department}
          loading={loadingDepartment}
          onSubmit={onUpdateDepartment}
          handleDelete={onDeleteDepartment}
          readOnly={readOnly}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateDepartment(department) {
    sanitiseDataTypes(department);
    updateDepartment(filmId, department);
  }

  function onDeleteDepartment(department) {
    confirm(() => deleteDepartment(filmId, department), department.name);
  }

  function sanitiseDataTypes(department) { }

  const thisDepartment = getDepartment();

  return thisDepartment ? renderDepartment(thisDepartment) : renderLoading();
}

function mapStateToProps(state) {
  return {
    departments: state.departmentsState.departments,
    readOnly: state.departmentsState.readOnly,
    redirect: state.departmentsState.redirect,
    errors: state.departmentsState.errors
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchDepartment,
    updateDepartment,
    deleteDepartment,
    clearRedirect,
    clearDepartment
  })(EditDepartmentContainer)
);
