import { clearNotifications, showError, showInfo } from "../../notifications/ducks/actions";
import getApiClient from "../../shared/api";

export function fetchUserSettings() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER_SETTINGS",
      payload: getApiClient().get(`/user/settings`)
    });
  };
}

export function updateUserSettings(userSettings) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_USER_SETTINGS",
      payload: getApiClient().put(
        `/user/settings`,
        userSettings
      ),
      meta: { userSettings }
    })
      .then((response) => {
        dispatch(showInfo(`Updated user settings`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating user settings`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
