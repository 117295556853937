import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";

import Form from "./form";
import { prices } from "./price-data"

function Register(props) {
  const {
    errors
  } = props;

  console.log("prices", prices);

  return (
    <div className="blade-content wideaf">
      <Form
        errors={errors}
        loading={false}
        prices={prices}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { adminState } = state;
  return {
    errors: adminState.adminUsersByDateState.errors,
    loading: adminState.adminUsersByDateState.loading
  };
}

export default connect(mapStateToProps, {
})(Register);
