import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Form from "./add-existing-form";
import {
  assignRoleToMember,
  clearRedirect,
  fetchFilmCrewMembers
} from "./ducks/actions";
import ScrollIntoView from "../../../../../shared/scroll-into-view/scroll-into-view";
import Loader from "../../../../../shared/loader";

function AddExistingMember(props) {
  const { filmId, departmentId, roleId } = props.match.params;
  const { fetchFilmCrewMembers, assignRoleToMember, errors, loading, loadingMember } = props;

  useEffect(() => {
    fetchFilmCrewMembers(filmId);
  }, []);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/departments/${departmentId}/roles/${roleId}/members`;
      return <Redirect to={url} />;
    }
  }

  function onAddMember(member) {
    assignRoleToMember(filmId, departmentId, roleId, member.memberId);
  }

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/departments/:departmentId/roles/:roleId/members/add-existing"
      loading={loading}>
      {loading || loadingMember && <Loader />}
      {!loading && !loadingMember && <Form
        mode="add"
        errors={errors}
        memberId={null}
        initialValues={null}
        allMembers={props.allMembers}
        loading={loading}
        onSubmit={onAddMember}
        {...props}
      />}
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    allMembers: state.crewMembersState.allMembers,
    redirect: state.crewMembersState.redirect,
    errors: state.crewMembersState.errors,
    loading: state.crewMembersState.loading,
    loadingMember: state.crewMembersState.loadingMember
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchFilmCrewMembers,
    assignRoleToMember,
    clearRedirect
  })(AddExistingMember)
);
