import { HashLink } from 'react-router-hash-link';
import CookieConsent from "react-cookie-consent";

const FilmanizeCookieConsent = () => {

    return ( <CookieConsent        
        buttonText="Accept"
        buttonClasses="button is-primary"
        buttonStyle={{
          background: "#040f21",
          border: "1px solid #495d7a",
          color: "white",
          borderRadius: "3px",
          display: "flex"
        }}
        style={{ background: "#040f21" }}>
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "0.9rem" }}>
          Click <HashLink to={"/cookies-policy#"} scrollOffset={-300}>here</HashLink> to view cookies
          policy.
        </span>{" "}
        <span style={{ fontSize: "0.9rem" }}>
          Click <HashLink to={"/privacy-policy#"} scrollOffset={-300}>here</HashLink> to view privacy
          policy.
        </span>
      </CookieConsent>);
}

export default FilmanizeCookieConsent;