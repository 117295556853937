import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "./../../../shared/loader";
import { fetchCharactersInSceneNotOffScreen } from "./../characters/ducks/actions";
import { fetchBackgroundCharactersInScene } from "./../background-characters/ducks/actions";
import {
  addShot,
  clearRedirect,
  fetchFilmFramesPerSecond,
  initialliseAddShotForm,
  uploadImagesAndSaveShot
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function AddShot(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    addShot,
    loadingShot,
    shot,
    errors,
    fetchCharactersInSceneNotOffScreen,
    fetchBackgroundCharactersInScene,
    fetchFilmFramesPerSecond,
    characters,
    backgroundCharacters,
    uploadImagesAndSaveShot
  } = props;

  useEffect(() => {
    props.initialliseAddShotForm();
    fetchFilmFramesPerSecond(filmId);
    fetchCharactersInSceneNotOffScreen(filmId, sceneId);
    fetchBackgroundCharactersInScene(filmId, sceneId);
  }, []);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/scenes/${sceneId}/shots`;
      return <Redirect to={url} />;
    }
  }

  if (props.loadingShot) {
    return <Loader />;
  }

  function renderShot(shot) {
    return (
      <ScrollIntoView
        className="blade-content"
        path="/films/:filmId/scenes/:sceneId/shots/add"
        loading={false}>
        <Form
          errors={errors}
          mode="add"
          filmId
          sceneId
          shotId
          initialValues={shot}
          characters={characters}
          backgroundCharacters={backgroundCharacters}
          loading={loadingShot}
          onSubmit={onAddShot}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onAddShot(shot) {
    sanitiseDataTypes(shot);
    shot.movements = shot.movements?.map((m) => m.value);

    const files = shot.pictures || [];

    if (files && files.length > 0) {
      uploadImagesAndSaveShot(shot, files, true, filmId, sceneId);
    } else {
      if (!shot.images) {
        shot.images = [];
      }
      addShot(filmId, sceneId, shot);
    }
  }

  function sanitiseDataTypes(shot) {
    shot.number = +shot.number;
    shot.shotSize = shot.shotSize ? +shot.shotSize : null;
    shot.lensFocalLength = shot.lensFocalLength ? +shot.lensFocalLength : null;
    shot.frameRate = shot.frameRate ? +shot.frameRate : null;
  }

  function renderLoading() {
    return <Loader />;
  }

  return loadingShot ? renderLoading() : shot ? renderShot(shot) : null;
}

function mapStateToProps(state) {
  const { shotsState, scenesState, commonState } = state;
  return {
    shots: shotsState.shots,
    shot: shotsState.shot,
    loadingShot: shotsState.loadingShot,
    shotSizeEnum: commonState.shotSizeEnum,
    cameraMovementEnum: commonState.cameraMovementEnum,
    focalLengths: commonState.focalLengths,
    frameRates: commonState.slowMotionFrameRates,
    characters: scenesState.characterState.characters,
    backgroundCharacters: scenesState.backgroundCharacterState.backgroundCharacters,
    errors: shotsState.errors,
    redirect: shotsState.redirect
  };
}

export default withRouter(
  connect(mapStateToProps, {
    addShot,
    uploadImagesAndSaveShot,
    initialliseAddShotForm,
    fetchCharactersInSceneNotOffScreen,
    fetchBackgroundCharactersInScene,
    fetchFilmFramesPerSecond,
    clearRedirect
  })(AddShot)
);
