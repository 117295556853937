import * as React from 'react'

export default (props) =>

<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
  <g id="Group_3990" data-name="Group 3990" transform="translate(-1024 -8090)">
    <path id="Path_32947" data-name="Path 32947" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" transform="translate(1026 8092)" fill="#222e44"/>
    <path id="noun-tick-3923816" d="M130.17,46.664a13.5,13.5,0,1,0,6.75,1.808,13.5,13.5,0,0,0-6.75-1.808Zm7.663,11.12-8.1,7.425a1.35,1.35,0,0,1-1.867-.04l-4.05-4.05a1.35,1.35,0,1,1,1.909-1.909l3.136,3.136,7.147-6.552a1.35,1.35,0,0,1,1.825,1.99Z" transform="translate(907.33 8043.336)" fill="#00ffce"/>
  </g>
</svg>
