import React from "react";
import { Link, Prompt } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import {
  renderCheckbox,
  renderTextAreaField,
  renderTimeField
} from "./../../../shared/form-helpers";
import "./shooting-days-crew.scss";

function ShootingDayCrewForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    saved,
    readOnly,
    canAccessActors,
    canAccessBackgroundActors,
    canAccessCrew,
    filmId
  } = props;
  const thisList = props.initialValues || {};
  const crew = { crew: thisList };

  if (saved) {
    reset();
  }

  return (
    <div className="shooting-days-crew">
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <FieldArray name="crewDepartments"
          component={renderDepartment} readOnly={readOnly}
          canAccessActors={canAccessActors}
          canAccessBackgroundActors={canAccessBackgroundActors}
          canAccessCrew={canAccessCrew}
          filmId={filmId} />
        <br />
        {!readOnly && <div className="buttons sticky-buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}
          >
            Save
          </button>
        </div>}
      </form>
    </div>
  );
}

function renderDepartment({ fields, meta: { error }, readOnly, filmId }) {
  return (
    <ul className="crew-departments">
      {(fields || []).map((dept, idx, records) => {
        return (
          <li key={idx}>
            <span className="dept-name">
              <Link to={`/films/${filmId}/departments/${records.get(idx).crewDepartmentId}/edit`}>{records.get(idx).crewDepartmentName}</Link>
            </span>
            {records.get(idx).fixedNotes && <div>{records.get(idx).fixedNotes}</div>}
            <Field
              name={`${dept}.notes`}
              label={"Notes"}
              component={renderTextAreaField}
              readOnly={readOnly}
            />
            <FieldArray
              name={`${dept}.crewRoles`}
              component={renderCrewRoles}
              readOnly={readOnly}
              filmId={filmId}
              departmentId={records.get(idx).crewDepartmentId}
            />
          </li>
        );
      })}
    </ul>
  );
}

function renderCrewRoles({ fields, meta: { error }, readOnly, filmId, departmentId }) {
  return (
    <div className="crew-roles">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Role Name</th>
            <th>Member</th>
            <th>Call time</th>
          </tr>
        </thead>
        <tbody>
          {(fields || []).map((role, idx, records) => {
            return (
              <FieldArray
                component={renderMembers}
                name={`${role}.crewMembers`}
                readOnly={readOnly}
                filmId={filmId}
                departmentId={departmentId}
                roleId={records.get(idx).crewRoleId}
                idx={idx}
                role={role}
                records={records}
              />
            );
          })}
        </tbody>
      </table>
    </div >
  );
}

function renderMembers({ fields, meta: { error }, readOnly, filmId, departmentId, roleId, idx, role, records }) {
  const outerIdx = idx;
  const outerRecords = records;
  return (
    <>


      {(fields || []).map((member, idx, records) => {
        return (
          <>
            {idx === 0 &&
              <tr key={outerIdx}>
                <td rowSpan={fields.length}>
                  <Field
                    name={`${role}.selected`}
                    component={renderCheckbox}
                    checked={`${role}.selected`}
                    readOnly={readOnly}
                    className="role-select"
                  />
                </td>
                <td rowSpan={fields.length}>
                  <Link to={`/films/${filmId}/departments/${departmentId}/roles/${outerRecords.get(outerIdx).crewRoleId}/edit`}>
                    {outerRecords.get(outerIdx).crewRoleName}
                  </Link>
                </td>
                <RenderMember
                  member={member}
                  idx={idx}
                  records={records}
                  readOnly={readOnly}
                  departmentId={departmentId}
                  roleId={roleId}
                  filmId={filmId} />
              </tr>
            }
            {idx > 0 && <tr>
              <RenderMember
                member={member}
                idx={idx}
                records={records}
                readOnly={readOnly}
                departmentId={departmentId}
                roleId={roleId}
                filmId={filmId} />
            </tr>}

          </>
        );
      })}
    </>




  );
}

const RenderMember = ({ member, idx, records, readOnly, departmentId, roleId, filmId }) => {
  return (
    <>
      <td>
        <label key={idx}>
          <Field
            component={renderCheckbox}
            name={`${member}.selected`}
            readOnly={readOnly}
            label={departmentId && roleId && <Link id={`${records.get(idx).id}_${roleId}`}
              to={`/films/${filmId}/departments/${departmentId}/roles/${roleId}/members/${records.get(idx).id}`}
            >
              {records.get(idx).fullName}
            </Link>}
          />
        </label>
      </td>
      <td>
        <Field
          name={`${member}.callTime`}
          type="time"
          component={renderTimeField}
          step="300"
          readOnly={readOnly}
        />
      </td>
    </>
  );
}

export default reduxForm({
  form: "shootingDayCrewForm",
  enableReinitialize: true
})(ShootingDayCrewForm);
