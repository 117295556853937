import React from "react";
import { useInView } from "react-intersection-observer";
import './bullet-point.scss';

const BulletPoint = ({ icon, description } : any) => {

    const options = { threshold: 0.7, triggerOnce: true, rootMargin: "0px 0px 0px 0px"  };
    const [ref, inView] = useInView(options);
   
    return (
        <div ref={ref} className={`marketing-bullet-point ${inView ? "marketing-bullet-point-in-view" : ""}`}>
            <div className="marketing-icon-container">
                {icon}                            
            </div>
            <div>
                {description}
            </div>
        </div>
    )
}

export default BulletPoint;