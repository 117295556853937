import React from "react";
import { NavLink } from "react-router-dom";

const EquipmentCategoriesList = ({ categories, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Category</p>
      <ul className="menu-list">
        {(categories || []).map((category, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/equipment-category/${category.id}/equipment`}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/equipment-category/${category.id}/equipment`
                  );
                }}>
                {category.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default EquipmentCategoriesList;
