import getApiClient from "./../../../../shared/api";

export function fetchSceneShootingDays(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENE_SHOOTING_DAYS",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/shootingDays`),
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
