import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "../../../shared/loader";
import {
  assignScenesToCharacter,
  fetchAllScenes,
  fetchAllSceneSettings,
  fetchScenesAssignedToCharacter,
  setSceneAssignedCharacter,
  findInScript,
  clearSceneSearchResults,
  fetchScriptDays
} from "./ducks/actions";

import { clearRedirect } from "../ducks/actions";

import { fetchCharacter } from "../ducks/actions";
import Form from "./scenes-form";
import {
  searchScenes,
  SEARCH_SCENES_FOR_CHARACTER
} from "../../scenes/search/ducks/actions";
import SearchScenesForm from "../../scenes/search/search-scenes-form";
import ScriptSearch from "../../script/search/script-search";

function CharacterScenes(props) {
  const characterId = props.characterId || props.match.params.characterId;
  const filmId = props.filmId || props.match.params.filmId;
  const {
    fetchCharacter,
    fetchAllScenes,
    fetchAllSceneSettings,
    fetchScenesAssignedToCharacter,
    setSceneAssignedCharacter,
    fetchScriptDays,
    character,
    assignScenesToCharacter,
    loadingCharacter,
    allScenes,
    searchScenes,
    clearSceneSearchResults,
    errors,
    sceneId,
    embedded,
    findingInScript,
    searchingScenes,
    redirect,
    clearRedirect
  } = props;

  const invalidIds = ["add", "scenes"];
  const readOnly = character?.readOnly;
  useEffect(() => {
    fetchCharacter(filmId, characterId);
    if (!sceneId) {
      fetchScenesAssignedToCharacter(filmId, characterId);
    }
  }, [
    filmId,
    characterId,
    sceneId,
    fetchCharacter,
    fetchScenesAssignedToCharacter
  ]);

  useEffect(() => {
    fetchAllScenes(filmId);
    fetchAllSceneSettings(filmId);
    fetchScriptDays(filmId);
  }, [filmId, fetchAllScenes, fetchAllSceneSettings, fetchScriptDays]);

  useEffect(() => {
    if (sceneId) {
      setSceneAssignedCharacter(sceneId);
    }
  }, [sceneId, setSceneAssignedCharacter]);

  if (!embedded && redirect) {
    if (redirect.to === "list") {
      clearRedirect();
      const url = `/films/${filmId}/characters/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(characterId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderForm() {
    return (
      <div className="blade-content">
        <h2 className="menu-label">Character Scenes</h2>

        {!readOnly && (
          <div className="box mt-1">
            <SearchScenesForm
              errors={errors}
              mode="edit"
              filmId={filmId}
              characterId={characterId}
              loading={loadingCharacter}
              onSubmit={onSearchScenes}
              allSceneSettings={props.allSceneSettings}
              scriptDays={props.scriptDays}
              characterName={character.name}
              searchingScenes={searchingScenes}
              scenes={allScenes}
              showScriptDays={true}
              showSceneSettings={true}
            />
          </div>
        )}
        {!readOnly && <div className={`box mt-1`}>
          <ScriptSearch filmId={filmId} text={character?.name} typeToDispatch="CHARACTER_SEARCH_SCRIPT" findingInScript={findingInScript} />
        </div>}
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          characterId={characterId}
          initialValues={{ selectedSceneIds: props.selectedSceneIds }}
          loading={loadingCharacter}
          onSubmit={onUpdateCharacterScenes}
          reduxFormValues={props.reduxFormValues}
          allScenes={props.allScenes}
          searchedSceneIds={props.searchedScenes?.map((s) => s.id)}
          selectSearchedScenes={props.selectSearchedScenes}
          clearSceneSearchResults={clearSceneSearchResults}
          readOnly={readOnly}
        />
      </div>
    );
  }

  function onSearchScenes(params) {
    searchScenes(
      SEARCH_SCENES_FOR_CHARACTER,
      filmId,
      params
    );
  }

  function onUpdateCharacterScenes(characterScenes) {
    assignScenesToCharacter(
      filmId,
      characterId,
      characterScenes.selectedSceneIds
    );
  }

  return allScenes?.length && character ? renderForm() : renderLoading();
}

function mapStateToCharacters(state) {
  const { charactersState, form } = state;
  const characterRootState = charactersState.rootState;
  const characterScenesState = charactersState.scenesState;
  return {
    character: characterRootState.character,
    allScenes: characterScenesState.scenes,
    allSceneSettings: characterScenesState.sceneSettings,
    selectedSceneIds: characterScenesState.selectedSceneIds,
    searchedScenes: characterScenesState.searchedScenes,
    selectSearchedScenes: characterScenesState.selectSearchedScenes,
    scriptDays: characterScenesState.scriptDays,
    findingInScript: characterScenesState.findingInScript,
    searchingScenes: characterScenesState.searchingScenes,
    errors: characterScenesState.errors,
    redirect: characterScenesState.redirect,
    loadingCharacter:
      characterScenesState.loading || characterRootState.loading,
    reduxFormValues: form?.characterScenesForm?.values
  };
}

export default connect(mapStateToCharacters, {
  fetchAllScenes,
  fetchAllSceneSettings,
  fetchScenesAssignedToCharacter,
  setSceneAssignedCharacter,
  fetchScriptDays,
  assignScenesToCharacter,
  searchScenes,
  findInScript,
  clearSceneSearchResults,
  fetchCharacter,
  clearRedirect
})(CharacterScenes);
