import {
  FETCH_POTENTIAL_DATES, POTENIAL_DATES_UNAVAILE_USER_EVENTS, POTENTIAL_DATES_ADD_USER_EVENTS, POTENTIAL_DATES_DELETE_DAYS, POTENTIAL_DATES_TOGGLE_AVAILABLE, UPDATE_AVAILABLE_DATES
} from "./actions";

interface SchedulingState {
  loading: boolean;
  saving: boolean;
  dates: any[];
  pristine: boolean;
  saved: boolean;
  onboardingSteps: any[]
}

export const OnboardingStep1ClassName = 'person-potential-dates-onboarding-step-1';
export const OnboardingStep2ClassName = 'person-potential-dates-onboarding-step-2';
export const OnboardingStep3ClassName = 'person-potential-dates-onboarding-step-3';
export const OnboardingStep4ClassName = 'person-potential-dates-onboarding-step-4';

const defaultState: SchedulingState = {
  dates: [],
  loading: true,
  saving: false,
  pristine: true,
  saved: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Calendar',
      disableBeacon: true,
      content: `        
      <p>Potential filming dates have been added to the calendar. Click on individual dates to show that 
      you are unavailable; clicking a second time will make them available again. You can toggle the 
      availability for a range of days by dragging over them.</p>      
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Import Google Calendar',
      disableBeacon: true,
      content: `        
        <p>Here you can import your personal Google calendar and see it superimposed on the grid. This data is not stored and is only visible to you.</p>
        <p>Once your personal calendar has been imported a button appears allowing you to set any dates on which you have events as unavailable.</p>
      `
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Save',
      disableBeacon: true,
      nextButtonText: 'Close',
      content: `        
        <p>One all dates are updated, remember to click Save.</p>        
      `
    }
  ]
};

export const availableColor = "#00ffce";
export const availableTextColor = "#040f21";
export const unavailableColor = "#ee5f5b";
export const unavailableTextColor = "#ffffff";
export const userEventColor = "#947eed";

const setDayColor = (day) => {

  // if (day.userEvent) {
  //   day.backgroundColor = userEventColor;
  //   day.borderColor = userEventColor;
  // }
  // else if (day.available) {
  //   day.textColor = availableTextColor;
  //   day.backgroundColor = availableColor;
  //   day.borderColor = availableColor;
  // } else {
  //   day.textColor = unavailableTextColor;
  //   day.backgroundColor = unavailableColor;
  //   day.borderColor = unavailableColor;
  // }
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_POTENTIAL_DATES + "_PENDING": {
      return {
        ...state,
        loading: true,
        dates: [],
        errors: null
      };
    }

    case FETCH_POTENTIAL_DATES + "_FULFILLED": {
      const dates = action.payload.data.dates;
      dates.forEach((day) => {
        day.start = new Date(day.start);
        day.title = "Filming";
        setDayColor(day);
      });

      return {
        ...state,
        dates: dates,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case FETCH_POTENTIAL_DATES + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case POTENTIAL_DATES_TOGGLE_AVAILABLE: {
      const dayId = action.meta.dayId;
      const dates = Array.from(state.dates);
      const day = dates.find((d) => d.id === dayId);
      if (day.userEvent) {
        return state;
      }

      // const existingDayIndex = dates.findIndex(d => d.id === action.meta.dayId);
      // if (existingDayIndex !== -1) {
      //   dates.splice(existingDayIndex, 1);
      // }

      day.available = !day.available;
      setDayColor(day);
      return {
        ...state,
        dates,
        pristine: false
      };
    }

    case POTENTIAL_DATES_DELETE_DAYS: {
      let dates = Array.from(state.dates);
      const startDate = action.meta.startDate;
      const endDate = action.meta.endDate;
      dates.filter(d => !d.userEvent && d.start >= startDate && d.start <= endDate).forEach((day) => {
        day.available = !day.available;
        setDayColor(day);
      });

      return {
        ...state,
        dates,
        pristine: false
      };
    }

    case POTENTIAL_DATES_ADD_USER_EVENTS: {
      let dates = Array.from(state.dates);
      const userEvents = action.meta.events;
      userEvents.forEach((event) => {
        if (event.status === "confirmed" && dates.findIndex(d => d.id === event.id) === -1) {
          const start = event.start.dateTime ? event.start.dateTime : event.start.date;
          const end = event.end.dateTime ? event.end.dateTime : event.end.date;
          const day = {
            id: event.id,
            start: new Date(start),
            end: new Date(end),
            title: event.summary,
            backgroundColor: userEventColor,
            borderColor: userEventColor,
            available: false,
            userEvent: true,
            allDay: !!event.start.date,
            description: event.description
          };

          setDayColor(day);
          dates.push(day)
        }
      });
      return {
        ...state,
        dates
      };
    }

    case POTENIAL_DATES_UNAVAILE_USER_EVENTS: {
      let dates = Array.from(state.dates);
      const userEvents = dates.filter((d) => d.userEvent);
      const allDayUserEvents = userEvents.filter(d => d.allDay);
      dates.forEach((event) => {
        if (!event.userEvent) {
          if (userEvents.findIndex((e) => e.start.toDateString() === event.start.toDateString()) !== -1) {
            event.available = false;
            setDayColor(event);
          }
          allDayUserEvents.forEach((userEvent) => {
            if (event.start >= userEvent.start && event.start < userEvent.end) {
              event.available = false;
              setDayColor(event);
            }
          });
        }
      });

      return {
        ...state,
        dates,
        pristine: false
      };
    }


    case UPDATE_AVAILABLE_DATES + "_PENDING": {
      return {
        ...state,
        saving: true
      }
    }

    case UPDATE_AVAILABLE_DATES + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }

    case UPDATE_AVAILABLE_DATES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        saving: false
      };
    }

    default:
      return state;
  }
};


export default reducer;
