import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderCheckboxGroup } from "../../../shared/form-helpers";
import Errors from "../../../shared/errors";

function CostumeScenesForm(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    allScenes,
    searchedSceneIds,
    reduxFormValues,
    clearSceneSearchResults,
    selectSearchedScenes,
    readOnly
  } = props;

  useEffect(() => {
    if (searchedSceneIds?.length && reduxFormValues) {
      const { selectedSceneIds } = reduxFormValues;

      if (selectSearchedScenes) {
        const union = new Set([...selectedSceneIds, ...searchedSceneIds]);
        const newSelectedSceneIds = Array.from(union);

        props.change("selectedSceneIds", newSelectedSceneIds);
        clearSceneSearchResults();
      } else {
        const newSelectedSceneIds = selectedSceneIds.filter(
          (el) => searchedSceneIds.indexOf(el) < 0
        );

        props.change("selectedSceneIds", newSelectedSceneIds);
        clearSceneSearchResults();
      }
    }
  }, [
    searchedSceneIds,
    props,
    reduxFormValues,
    clearSceneSearchResults,
    selectSearchedScenes
  ]);

  function onSelectAllClick() {
    props.change(
      "selectedSceneIds",
      allScenes.map((s) => s.id)
    );
  }

  function onUnselectAllClick() {
    props.change("selectedSceneIds", []);
  }

  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}

      {!readOnly && (
        <div className="buttons">
          <button
            className="button is-secondary is-small is-text"
            onClick={onSelectAllClick}>
            Select All
          </button>
          <button
            className="button is-secondary is-small is-text"
            onClick={onUnselectAllClick}>
            Unselect All
          </button>
        </div>
      )}

      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          label="Scenes"
          name="selectedSceneIds"
          component={renderCheckboxGroup}
          options={allScenes}
          textProperty="name"
          valueProperty="id"
          oneInEachRow={true}
          readOnly={readOnly}
        />

        {!readOnly && (
          <div className="buttons mt-2">
            <button
              type="submit"
              className="button is-primary"
              disabled={submitting}>
              Save
            </button>
          </div>
        )}
      </form>
    </>
  );
}

export default reduxForm({
  form: "characterScenesForm",
  enableReinitialize: true
})(CostumeScenesForm);
