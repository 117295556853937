export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="31.386" height="33.926" viewBox="0 0 24.962 26.975"  {...props}>
    <g id="Group_3830" data-name="Group 3830" transform="translate(0.5 0.5)">
      <g id="Group_3827" data-name="Group 3827" transform="translate(13.04)">
        <ellipse id="Ellipse_59" data-name="Ellipse 59" cx="1.873" cy="1.873" rx="1.873" ry="1.873" transform="translate(3.568 3.193)" fill="none" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_32834" data-name="Path 32834" d="M9.919,16.258a4.964,4.964,0,0,0-7.136-.12c-.053.053-.105.108-.156.164" transform="translate(-0.832 -7.283)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <ellipse id="Ellipse_60" data-name="Ellipse 60" cx="5.441" cy="5.441" rx="5.441" ry="5.441" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      </g>
      <g id="Group_3828" data-name="Group 3828" transform="translate(13.08 15.093)">
        <ellipse id="Ellipse_61" data-name="Ellipse 61" cx="1.873" cy="1.873" rx="1.873" ry="1.873" transform="translate(3.568 3.193)" fill="none" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_32835" data-name="Path 32835" d="M9.879,29.351a4.965,4.965,0,0,0-7.136-.119c-.053.053-.105.108-.156.163" transform="translate(-0.792 -20.377)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <ellipse id="Ellipse_62" data-name="Ellipse 62" cx="5.441" cy="5.441" rx="5.441" ry="5.441" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      </g>
      <g id="Group_3829" data-name="Group 3829" transform="translate(0 7.511)">
        <ellipse id="Ellipse_63" data-name="Ellipse 63" cx="1.873" cy="1.873" rx="1.873" ry="1.873" transform="translate(3.568 3.193)" fill="none" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_32836" data-name="Path 32836" d="M22.959,22.769a4.964,4.964,0,0,0-7.136-.12c-.053.054-.105.108-.156.164" transform="translate(-13.872 -13.794)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <ellipse id="Ellipse_64" data-name="Ellipse 64" cx="5.441" cy="5.441" rx="5.441" ry="5.441" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      </g>
      <line id="Line_703" data-name="Line 703" x1="2.999" y2="2.989" transform="translate(10.591 7.511)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_704" data-name="Line 704" x1="4" y1="3" transform="translate(9.591 15.5)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
    </g>
  </svg>

