import { DragDropContext } from 'react-beautiful-dnd';
import Errors from '../../shared/errors';
import ScrollIntoView from '../../shared/scroll-into-view/scroll-into-view';
import Column from "./column";
import './tasks.scss';

const Tasks = (props) => {
    const {         
        tasks,
        loading,
        columns,
        columnOrder,
        moveTask, 
        addTask,
        taskTextChanged,
        taskTextFinishedEditing,
        errors, 
        editTask, 
        deleteTask,
        showMenuOnTaskId,
        showMenuOnTask,
        collaborators,
        assignTask,
        readOnly
     } = props;
     
     const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
          return;
        }
    
        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }
        moveTask(destination, source, draggableId);        
      };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
          <ScrollIntoView
        className="blade-content"
        path="/films/:filmId/shooting-days/:shootingdayId/edit"
        loading={loading}>
            <Errors errors={errors} />
            <div className="tasks">
                {columnOrder.map((columnId, index) => {
                    const column = columns[columnId];
                    const columnTasks = column.taskIds.map(taskId => tasks[taskId]);

                    return <Column key={column.id} 
                                   index={index} 
                                   column={column} 
                                   tasks={columnTasks} 
                                   addTask={addTask} 
                                   taskTextChanged={taskTextChanged} 
                                   taskTextFinishedEditing={taskTextFinishedEditing}
                                   editTask={editTask} 
                                   deleteTask={deleteTask}
                                   showMenuOnTaskId={showMenuOnTaskId}
                                   showMenuOnTask={showMenuOnTask}
                                   collaborators={collaborators}
                                   assignTask={assignTask}
                                   readOnly={readOnly} />;
                })}

            </div>
          </ScrollIntoView>
        </DragDropContext>
      );

}
  
export default Tasks;
  