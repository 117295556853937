import React from "react";
import { Route } from "react-router-dom";
import ListSetDressingsContainer from "./list-container";
import AddSetDressing from "./add";
import EditSetDressing from "./edit";
import SetDressingScenes from "./scenes";
import SetDressingMenu from "./menu";
import Overview from "./overview/container";
import { PrivateRoute } from "../../../shared/private-route";

const SetDressingRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/set-dressings"
        component={ListSetDressingsContainer}
      />
      <PrivateRoute
        path="/films/:filmId/set-dressings/:setDressingId"
        component={SetDressingMenu}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/set-dressings/add"
        component={AddSetDressing}
      />
      <PrivateRoute exact path="/films/:filmId/set-dressings/overview" component={Overview} />
      <PrivateRoute
        exact
        path="/films/:filmId/set-dressings/:setDressingId"
        component={EditSetDressing}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/set-dressings/:setDressingId/edit"
        component={EditSetDressing}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/set-dressings/:setDressingId/scenes"
        component={SetDressingScenes}
      />
    </>
  );
};

export default SetDressingRoutes;
