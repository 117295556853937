import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import required from "../shared/validation";
import Errors from "./../shared/errors";
import {
  renderSingleReactSelect,
  renderTextField
} from "./../shared/form-helpers";
import Onboarding from '../shared/onboarding/onboarding';
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from './ducks/reducer';

function UserSettingsForm(props) {
  const { handleSubmit, pristine, submitting, errors, weightUnitEnum, heightUnitEnum, distanceUnitEnum, onboardingSteps, temperatureUnitEnum, windSpeedUnitEnum } =
    props;

  const weightUnitOptions = (weightUnitEnum || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  const heightUnitOptions = (heightUnitEnum || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  const distanceUnitOptions = (distanceUnitEnum || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  const temperatureUnitOptions = (temperatureUnitEnum || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  const windSpeedUnitOptions = (windSpeedUnitEnum || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  return (
    <>
      <Prompt when={!pristine} message="" />
      <h2 className="menu-label">User Settings</h2>
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="scriptPagesPerDay"
          label="Script Pages Per Day"
          className={OnboardingStep1ClassName}
          validate={[required]}
          required
          parse={(value) => Number(value)}
          component={renderTextField}
        />

        <Field
          name="weightUnit"
          label="Weight Unit"
          component={renderSingleReactSelect}
          options={weightUnitOptions}
          validate={[required]}
          required></Field>

        <Field
          name="heightUnit"
          label="Height Unit"
          component={renderSingleReactSelect}
          options={heightUnitOptions}
          validate={[required]}
          required></Field>

        <Field
          name="distanceUnit"
          label="Distance Unit"
          component={renderSingleReactSelect}
          options={distanceUnitOptions}
          validate={[required]}
          required></Field>

        <Field
          name="temperatureUnit"
          label="Temperature Unit"
          component={renderSingleReactSelect}
          options={temperatureUnitOptions}
          validate={[required]}
          required></Field>


        <Field
          name="windSpeedUnit"
          label="Wind Speed Unit"
          component={renderSingleReactSelect}
          options={windSpeedUnitOptions}
          validate={[required]}
          required />

        <div className="buttons mt-2">
          <button
            type="submit"
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={pristine || submitting}>
            Save
          </button>
        </div>
      </form>
      <Onboarding onboardingSteps={onboardingSteps} section="User Settings" />
    </>
  );
}

export default reduxForm({
  form: "userSettingsForm",
  enableReinitialize: true
})(UserSettingsForm);
