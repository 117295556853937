const FilmaizeText: React.FC<any> = (props) => {
  const { className, readOnly, label, required, infoText, value, onChange } = props;

  const handleOnChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className={`field ${className}`}>
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
        {infoText && (
          <span className="ml-1 material-icons" data-tool-tip={infoText}>
            info
          </span>
        )}
      </label>
      <div className="control">
        <input
          className="input"
          type="text"
          placeholder={label}
          value={value}
          onChange={handleOnChange}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

export default FilmaizeText;
