import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "../../../shared/errors";
import {
  renderCheckbox,
  renderTextField,
  renderTimeField
} from "../../../shared/form-helpers";
import required from "../../../shared/validation";

function ScheduleEventForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode
  } = props;
  const thisShootingDay = props.initialValues || {};
  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="time"
          label="Time"
          component={renderTimeField}
          canAddDay={false}
          validate={[required]}
          required
        />

        <Field
          name="description"
          label="Description"
          component={renderTextField}
          validate={[required]}
          required
        />

        <div className="field">
          <Field
            name="isMealEvent"
            label="Is meal event?"
            component={renderCheckbox}
            disabled={mode == "edit"}
          />
        </div>

        <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save Crew Event
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisShootingDay)}>
              Delete
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default reduxForm({
  form: "scheduleEventForm",
  enableReinitialize: true
})(ScheduleEventForm);
