import { FETCH_CHARACTER_MAKEUPS } from "./actions";

interface CharacterPropsState {
  makeups: any[];
  loading: boolean;
  readOnly: boolean;
}

const defaultState: CharacterPropsState = {
  makeups: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_CHARACTER_MAKEUPS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_CHARACTER_MAKEUPS + "_FULFILLED": {
      const makeups = action.payload.data.makeups;
      return {
        ...state,
        makeups,
        loading: false
      };
    }

    case FETCH_CHARACTER_MAKEUPS + "_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;