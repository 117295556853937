import getApiClient from "./../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

const url = "/film";

export function fetchSceneSettingOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENE_SETTING_OVERVIEW",
      payload: getApiClient().get(`${url}/${id}/SceneSetting/Overview`)
    });
  };
}

export function clearSceneSettingOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SCENE_SETTING_OVERVIEW"
    });
  };
}

export function assignLocation(filmId, location, sceneSetting) {
  let locationName = location.name;
  if (location.subLocation) {
    locationName += " - " + location.subLocation.name;
  }

  if (sceneSetting.locations) {
    const existingLocation = sceneSetting.locations.find(l => l.id === location.id && l.subLocation?.id === location.subLocation?.id);
    if (existingLocation) {
      return (dispatch) => { 
        dispatch(
          showError(
              `Scene Setting ${sceneSetting.name} already has location ${locationName} assigned`
            )
          );
      }
    }
  }

 
  return (dispatch) => {
    dispatch({
      type: "SAVE_ASSIGN_LOCATION_TO_SCENE_SETTING",
      payload: getApiClient().put(
        `${url}/${filmId}/SceneSetting/${sceneSetting.id}/AssignLocation`,
        {
          locationId: location.id,
          subLocationId: location.subLocation?.id
        }
      )
    })
      .then((response) => {
        dispatch({
          type: "ASSIGN_LOCATION_TO_SCENE_SETTING",
          meta: { location, sceneSetting }
        });
        dispatch(
          showInfo(
            `Assigned location ${locationName} to scene setting ${sceneSetting.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error assigning location ${locationName} to scene setting ${sceneSetting.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function replaceLocation(filmId, newLocation, curentLocation, sceneSetting) {
  let newLocationName = newLocation.name;
  if (newLocation.subLocation) {
    newLocationName += " - " + newLocation.subLocation.name;
  }

  let currentLocationName = curentLocation.name;
  if (curentLocation.subLocation) {
    currentLocationName += " - " + curentLocation.subLocation.name;
  }

  if (sceneSetting.locations) {
    const existingLocation = sceneSetting.locations.find(l => l.id === newLocation.id && l.subLocation?.id === newLocation.subLocation?.id);
    if (existingLocation) {
      return (dispatch) => { 
        dispatch(
          showError(
              `Scene Setting ${sceneSetting.name} already has location ${newLocationName} assigned`
            )
          );
      }
    }
  }

 
  return (dispatch) => {    
    dispatch({
      type: "SAVE_REPLACE_LOCATION_IN_SCENE_SETTING",
      payload: getApiClient().put(
        `${url}/${filmId}/SceneSetting/${sceneSetting.id}/ReplaceLocation`,
        {
          currentLocationId: curentLocation.id,
          currentSubLocationId: curentLocation.subLocation?.id,
          locationId: newLocation.id,
          subLocationId: newLocation.subLocation?.id
        }
      )
    })
      .then((response) => {
        dispatch({
          type: "REPLACE_LOCATION_ON_SCENE_SETTING",
          meta: { curentLocation, newLocation, sceneSetting }
        });
        dispatch(
          showInfo(
            `Replaced location ${currentLocationName} with ${newLocationName} on scene setting ${sceneSetting.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error replacing location ${currentLocationName} with ${newLocationName} on scene setting ${sceneSetting.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function unassignLocation(filmId, sceneSetting, location) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_UNASSIGN_LOCATION_TO_SCENE_SETTING",
      payload: getApiClient().put(
        `${url}/${filmId}/SceneSetting/${sceneSetting.id}/UnAssignLocation`,
        {
          locationId: location.id,
          subLocationId: location.subLocation?.id
        }
      )
    })
      .then((response) => {
        dispatch({
          type: "UNASSIGN_LOCATION_TO_SCENE_SETTING",
          meta: { sceneSettingId: sceneSetting.id, location }
        });
        dispatch(
          showInfo(
            `Unassigned location ${location.name} from scene setting ${sceneSetting.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error unassigning location ${location.name} from scene setting ${sceneSetting.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function aliasSceneSetting(filmId, aliasSceneSetting, mainSceneSetting) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_ALIAS_SCENE_SETTING",
      payload: getApiClient().put(
        `${url}/${filmId}/SceneSetting/${mainSceneSetting.id}/Alias`,
        { aliasId: aliasSceneSetting.id }
      )
    })
      .then((response) => {
        dispatch({
          type: "ALIAS_SCENE_SETTING",
          meta: { aliasSceneSetting, mainSceneSetting }
        });
        dispatch(
          showInfo(
            `Aliased ${aliasSceneSetting.name} with ${mainSceneSetting.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error aliasing ${aliasSceneSetting.name} with ${mainSceneSetting.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function unaliasSceneSetting(
  filmId,
  aliasSceneSetting,
  mainSceneSetting
) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_REMOVE_ALIAS_SCENE_SETTING",
      payload: getApiClient().put(
        `${url}/${filmId}/SceneSetting/${mainSceneSetting.id}/RemoveAlias`,
        { aliasId: aliasSceneSetting.id }
      )
    })
      .then((response) => {
        dispatch({
          type: "UNALIAS_SCENE_SETTING",
          meta: { aliasSceneSetting, mainSceneSetting }
        });
        dispatch(
          showInfo(
            `Removed alias ${aliasSceneSetting.name} from ${mainSceneSetting.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error removing alias ${aliasSceneSetting.name} from ${mainSceneSetting.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
