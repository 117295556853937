import OnboardingType from "../../../shared/onboarding/onboarding-type";
import { FETCH_USER_COMMUNICATION_PREFERENCES, UPDATE_USER_COMMUNICATION_PREFERENCES } from "./actions";

export const OnboardingStep1ClassName = 'user-settings-onboarding-step-1';
export const OnboardingStep2ClassName = 'user-settings-onboarding-step-2';

const defaultState = {
  onboardingSteps: [         
  ],
  userCommunicationPreferences: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_USER_COMMUNICATION_PREFERENCES + "_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case FETCH_USER_COMMUNICATION_PREFERENCES + "_FULFILLED": {
      return {
        ...state,
        userCommunicationPreferences: action.payload.data,
        loading: false
      };
    }

    case FETCH_USER_COMMUNICATION_PREFERENCES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case UPDATE_USER_COMMUNICATION_PREFERENCES + "_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case UPDATE_USER_COMMUNICATION_PREFERENCES + "_FULFILLED": {
      return {
        ...state,
        loading: false,        
        userCommunicationPreferences: action.meta.userCommunicationPreferences
      };
    }

    case UPDATE_USER_COMMUNICATION_PREFERENCES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
