import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "./../../../shared/loader";
import Onboarding from '../../../shared/onboarding/onboarding';
import {
  assignScenesToEquipment,
  fetchAllScenes,
  fetchAllSceneSettings,
  fetchScenesAssignedToEquipment,
  clearSceneSearchResults,
  setSceneAssignedToEquipment,
  clearRedirect,
  SEARCH_SCENES_FOR_EQUIPMENT
} from "./ducks/actions";
import { fetchEquipment } from "../ducks/actions";
import Form from "./scenes-form";
import {
  searchScenes,
  SEARCH_SCENES_FOR_SET_DRESSING
} from "../../scenes/search/ducks/actions";
import SearchScenesForm from "../../scenes/search/search-scenes-form";
import ScriptSearch from "../../script/search/script-search";
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from './ducks/reducer';

function SetDressingScenes(props) {
  const { filmId, equipmentId, categoryId } = props.match.params;

  const {
    fetchAllScenes,
    fetchAllSceneSettings,
    sceneSettings,
    equipment,
    selectedSceneIds,
    fetchScenesAssignedToEquipment,
    assignScenesToEquipment,
    setSceneAssignedToEquipment,
    loadingSetDressing,
    loadingScenes,
    allScenes,
    searchScenes,
    findInScript,
    clearSceneSearchResults,
    errors,
    readOnly,
    sceneId,
    embedded,
    onboardingSteps,
    loadingSceneSettings,
    findingInScript,
    searchingScenes,
    clearRedirect,
    loadingAssignedScenes
  } = props;


  const invalidIds = ["add", "scenes"];

  // const allSelectedScenes = (props.selectedSceneIds || []).concat(
  //   props.searchedScenes?.map((s) => s.id)
  // );

  useEffect(() => {
    //fetchEquipment(filmId, categoryId, equipmentId);
    if (!sceneId) {
      fetchScenesAssignedToEquipment(filmId, categoryId, equipmentId);
    }
  }, [filmId, equipmentId]);

  useEffect(() => {
    fetchAllScenes(filmId);
    fetchAllSceneSettings(filmId);
  }, [filmId, equipmentId]);

  useEffect(() => {
    if (sceneId) {
      setSceneAssignedToEquipment(sceneId);
    }
  }, [sceneId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      clearRedirect();
      const url = `/films/${filmId}/equipment-category/${categoryId}/equipment`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(equipmentId) > -1) {
    return null;
  }

  const onFindInScript = (e) => {
    e.preventDefault();
    findInScript(filmId, equipment.name);
  };

  function renderLoading() {
    return <Loader />;
  }

  function renderForm() {
    return (
      <div className="blade-content">
        <h2 className="menu-label">Equipment Scenes</h2>
        {!readOnly && <div className={`box mt-1 ${OnboardingStep1ClassName}`}>
          <SearchScenesForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            setDressingId={equipmentId}
            loading={loadingSetDressing}
            onSubmit={onSearchScenes}
            findInScript={onFindInScript}
            allCharacters={props.allCharacters}
            allSceneSettings={sceneSettings}
            searchingScenes={searchingScenes}
            scenes={allScenes}
            showCharacters={false}
            showScriptDays={false}
            showSceneSettings={true}
          />
        </div>}
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          setDressingId={equipmentId}
          initialValues={{ selectedSceneIds }}
          loading={loadingSceneSettings || loadingSetDressing || loadingScenes}
          onSubmit={onUpdateEquipmentScenes}
          reduxFormValues={props.reduxFormValues}
          allScenes={allScenes}
          searchedSceneIds={props.searchedScenes?.map((s) => s.id)}
          selectSearchedScenes={props.selectSearchedScenes}
          clearSceneSearchResults={clearSceneSearchResults}
          readOnly={readOnly}
          loadingAssignedScenes={loadingAssignedScenes}
        />
        <Onboarding onboardingSteps={onboardingSteps} section="Set Dressing Scenes" />
      </div>
    );
  }

  function onSearchScenes(params) {
    searchScenes(
      SEARCH_SCENES_FOR_EQUIPMENT,
      filmId,
      params
    );
  }

  function onUpdateEquipmentScenes(propScenes) {
    assignScenesToEquipment(filmId, categoryId, equipmentId, propScenes.selectedSceneIds);
  }

  return allScenes ? renderForm() : renderLoading();
}

function mapStateToSetDressings(state) {
  const { equipmentScenesState, equipmentState, form } = state;
  return {
    equipment: equipmentState.equipment,
    allScenes: equipmentScenesState.scenes,
    sceneSettings: equipmentScenesState.sceneSettings,
    searchedScenes: equipmentScenesState.searchedScenes,
    selectedSceneIds: equipmentScenesState.selectedSceneIds,
    selectSearchedScenes: equipmentScenesState.selectSearchedScenes,
    findingInScript: equipmentScenesState.findingInScript,
    searchingScenes: equipmentScenesState.searchingScenes,
    errors: equipmentScenesState.errors,
    redirect: equipmentScenesState.redirect,
    onboardingSteps: equipmentScenesState.onboardingSteps,
    reduxFormValues: form?.setDressingScenesForm?.values,
    loadingAssignedScenes: equipmentScenesState.loadingAssignedScenes,
    readOnly: equipmentScenesState.readOnly
  };
}

export default
  connect(mapStateToSetDressings, {
    fetchEquipment,
    fetchAllScenes,
    fetchAllSceneSettings,
    fetchScenesAssignedToEquipment,
    setSceneAssignedToEquipment,
    assignScenesToEquipment,
    searchScenes,
    clearSceneSearchResults,
    clearRedirect
  })(SetDressingScenes);
