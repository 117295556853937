import { Link } from "react-router-dom";

const UnauthenticatedMenu = (props) => {
  const { mobileMenu } = props;
  return (
    <div
      id="navbarBasicExample"
      className={"navbar-menu" + (mobileMenu ? " is-active" : "")}>
      <div className="navbar-end">
        <Link to="/contact" className="navbar-item">
          Contact
        </Link>
        <Link to="/register" className="navbar-item">
          Register
        </Link>
        <Link to="/login" className="navbar-item">
          Login
        </Link>
      </div>
    </div>
  );
}

export default UnauthenticatedMenu;