import { NavLink } from "react-router-dom";

const ChangeLogsList = ({ changeLogs }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Change Logs</p>
      <ul className="menu-list">
        {changeLogs?.map((changeLog, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/admin/change-logs/${changeLog.id}/edit`}
                title={changeLog.name}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/change-logs/${changeLog.id}`
                  );
                }}>
                {changeLog.versionName}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ChangeLogsList;
