import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressProvider from "./progress-provider";

const FilmalityCircularProgress = (props) => {
  const { title, percentage, icon, buttonText, buttonLink, history, className } = props;

  return (
    <div className={`box ${className}`}>
      <span style={{ position: "absolute", transform: "translate(-2.5rem,0)" }}>
        {icon}
      </span>

      <span className="is-size-7">{title}</span>
      <ProgressProvider valueStart={0} valueEnd={percentage}>
        {(value) => {
          const pathColor = value < 100 ? `#947eed` : `#00ffce`;
          return (
            <CircularProgressbar
              value={value}
              text={`${Math.round(value * 10) / 10}%`}
              strokeWidth={12}
              styles={buildStyles({
                pathTransitionDuration: 0.5,
                textSize: "16px",
                // Colors
                pathColor: pathColor,
                textColor: "#ffffff",
                trailColor: "#222e44",
                backgroundColor: "#222e44",
                strokeLinecap: "butt"
              })}
            />
          );
        }}
      </ProgressProvider>
      {buttonText && buttonLink && (
        <button
          className="button is-transparent"
          onClick={() => history.push(buttonLink)}
          disabled={percentage === 100}>
          {buttonText}
        </button>
      )}
    </div>
  );
};
export default FilmalityCircularProgress;
