export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="27.707" height="30.274" viewBox="0 0 22.216 24.347"  {...props}>
    <g id="Group_3845" data-name="Group 3845" transform="translate(0.699 0.5)">
      <path id="Path_32861" data-name="Path 32861" d="M-192.439,75.558v-2.8a7.526,7.526,0,0,1,7.32-7.143h0A7.526,7.526,0,0,1-177.8,72.76v2.8" transform="translate(195.665 -65.617)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <path id="Rectangle_4236" data-name="Rectangle 4236" d="M2.921,0h.032a0,0,0,0,1,0,0V5.811a.032.032,0,0,1-.032.032h0A2.921,2.921,0,0,1,0,2.921v0A2.921,2.921,0,0,1,2.921,0Z" transform="translate(0.272 7.63)" fill="none" strokeLinejoin="round" strokeWidth="1" />
      <path id="Rectangle_4237" data-name="Rectangle 4237" d="M2.921,0h.032a0,0,0,0,1,0,0V5.843a0,0,0,0,1,0,0H2.921A2.921,2.921,0,0,1,0,2.921v0A2.921,2.921,0,0,1,2.921,0Z" transform="translate(20.819 13.484) rotate(-180)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <ellipse id="Ellipse_74" data-name="Ellipse 74" cx="6.55" cy="6.743" rx="6.55" ry="6.743" transform="translate(3.953 3.651)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_718" data-name="Line 718" x1="5.522" transform="translate(12.343 13.484)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <rect id="Rectangle_4238" data-name="Rectangle 4238" width="3.403" height="1.798" rx="0.899" transform="translate(9.261 12.446)" strokeMiterlimit="10" strokeWidth="1" />
      <path id="Path_32862" data-name="Path 32862" d="M-195.665,88.607a12.978,12.978,0,0,1,10.352-5.684,12.841,12.841,0,0,1,10.02,5.251c.153.193.3.39.447.592" transform="translate(195.665 -65.617)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </g>
  </svg>
