import React from "react";
import ComingSoon from "./coming-soon";
import { PrivateRoute } from "../../shared/private-route";

const SceneSettingsRoutes = () => {
  return (
    <>
      <PrivateRoute path="/films/:filmId/availability" component={ComingSoon} />
    </>
  );
};

export default SceneSettingsRoutes;
