import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { NavLink } from "react-router-dom";
import { OnboardingStep1ClassName } from './ducks/reducer';
import CanAccessNavLink from "../../../shared/can-access-nav-link";

const Scene = ({
  filmId,
  index,
  scene,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  isSelected,
  isGhosting,
  selectionCount,
  readOnly,
  canAccessSceneSettings
}) => {
  const grid = 0;
  const getItemStyle = (isDragging, draggableStyle, isSelected) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging    
    background: isSelected ? "#222E44" : isDragging ? "#947eed" : "#040f21",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const primaryButton = 0;

  // Using onClick as it will be correctly
  // preventing if there was a drag
  const onClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    performAction(event);
  };

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (
    event: MouseEvent | KeyboardEvent
  ) => {
    const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) =>
    event.shiftKey;

  const performAction = (event: MouseEvent | KeyboardEvent) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(scene.sceneId);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(scene.sceneId);
      return;
    }

    toggleSelection(scene.sceneId);
  };

  return (
    <Draggable key={scene.id} draggableId={scene.id} index={index} isDragDisabled={readOnly}>
      {(provided, snapshot) => (
        <>
          <tr
            //onClick={onClick}
            className={`${index === 0 ? OnboardingStep1ClassName : ""}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              isSelected
            )}
          >
            <td>
              {!readOnly && <i className="material-icons">drag_indicator</i>}
              <NavLink to={`/films/${filmId}/scenes/${scene.id}/edit`}>
                {scene.number}
              </NavLink>
            </td>
            <td>
              <CanAccessNavLink
                to={`/films/${filmId}/scene-settings/${scene.settingId}/edit`}
                canAccess={canAccessSceneSettings}
              >
                {scene.settingName}
              </CanAccessNavLink>
            </td>
            <td>{scene.interiorExteriorDescription}</td>
            <td>{scene.dayNightDescription}</td>
          </tr>
        </>
      )}
    </Draggable>
  );
};

export default Scene;
