const defaultState = {
  actors: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTING_DAY_BACKGROUND_ACTORS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }

    case "FETCH_SHOOTING_DAY_BACKGROUND_ACTORS_FULFILLED": {
      return {
        ...state,
        backgroundActors: action.payload.data.backgroundActors,
        readOnly: action.payload.data.readOnly,
        canAccessBackgroundActors: action.payload.data.canAccessBackgroundActors,
        loading: false,
        errors: null,
      };
    }

    case "UPDATE_SHOOTING_DAY_BACKGROUND_ACTORS_PENDING": {
      return {
        ...state,
        errors: null,
      };
    }

    // case "UPDATE_SHOOTING_DAY_ACTORS_FULFILLED": {
    //   const shootingday = { ...action.meta.shootingday, ...action.payload.data.shootingDay };
    //   const shootingdays: any = state.shootingdays.map((c: any, index: number) => {
    //     if (c.id === shootingday.id) {
    //       return { ...c, ...shootingday };
    //     } else {
    //       return c;
    //     }
    //   });

    //   return {
    //     ...state,
    //     shootingday,
    //     shootingdays,
    //     loadingShootingDay: false,
    //     errors: null,
    //   };
    // }


    case "UPDATE_SHOOTING_DAY_BACKGROUND_ACTORS_FULFILLED": {
      return {
        ...state,
        backgroundActors: action.meta.actors.backgroundActors,
        loading: false
      };
    }

    case "UPDATE_SHOOTING_DAY_BACKGROUND_ACTORS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
