import React from "react";
import auth from "../shared/auth";
import Errors from "./errors";
import Progress from "./progress";

const Download = (props) => {
  const { clearPdf, download, progress, downloadFile, errors, className } = props;
  function showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: "application/pdf" });

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;

    link.download = download.fileName;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  if (download && !download.downloaded) {
    clearPdf();
    if (download.url) {
      fetch(download.url, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + auth.getToken()
        })
      })
        .then((r) => r.blob())
        .then(showFile);
    }
  }

  const url = download ? download.url + "?t=" + auth.getToken() : "";

  return (
    <>
      <Errors errors={errors} />
      {!progress && !download && !download?.downloaded && (
        <button type="button" className={`button ${className}`} onClick={() => downloadFile()}>
          Download
        </button>
      )}
      <Progress progress={progress}></Progress>
      {download && (
        <div>
          The file should automatically download, if not click the link below
          <br />
          <a href={url} target="_blank">
            Download
          </a>
        </div>
      )}
    </>
  );
};

export default Download;
