import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { clearDepartment, addDepartment, clearRedirect } from "./ducks/actions";
import Form from "./form";

function AddDepartment(props) {
  const { filmId, departmentId } = props.match.params;
  const { fetchDepartmentSettings, clearDepartment, addDepartment, errors } =
    props;

  useEffect(() => {
    clearDepartment();
  }, [clearDepartment]);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/departments/`;
      return <Redirect to={url} />;
    }
  }

  function onAddDepartment(department) {
    sanitiseDataTypes(department);
    addDepartment(filmId, department);
  }

  function sanitiseDataTypes(department) {}

  return (
    <div className="blade-content">
      <Form
        mode="add"
        errors={errors}
        departmentId={null}
        initialValues={null}
        loading={false}
        onSubmit={onAddDepartment}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    errors: state.departmentsState.errors,
    redirect: state.departmentsState.redirect
  };
}

export default connect(mapStateToProps, {
  addDepartment,
  clearRedirect,
  clearDepartment
})(AddDepartment);
