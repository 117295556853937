import React from "react";
import './prices-t-and-t.scss';

const PricesTermsAndConitions = () => {

    return (
        <div className="marketing-get-started">
            <div className="marketing-prices-t-and-c-box">
                <div className="marketing-prices-t-and-c-content">
                    <p>*Filmanize is free during beta phase, which we expect to end some time after Q2 2024. Anyone who signs up within the beta period and gives resonable feedback will get an additional 6 months free from the live launch date; to continue using Filmanize after this will require payment. </p>
                    <p>Your subscription can be paused at any time, for up to a year, which will stop the monthly payments, but during this time you will not be able to access Filmanize. After a year of non-payment, all your films and files will be deleted.</p>
                    <p>An active film is any current film that you are working on. Films may be archived once complete, and archived films do not count towards your film total. Once a film is archived it becomes read only and cannot be unarchived.</p>
                    <p>The storage is for all your films, active and archived. Storage is used up by any images you upload, for actors etc.</p>
                </div>
            </div>
        </div>
    )
}

export default PricesTermsAndConitions;