import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import { clearEquipment, initialiseNewEquipment, addEquipment, clearRedirect, fetchEquipmentAcquisitionMethods } from "./ducks/actions";
import Form from "./form";

function AddDepartment(props) {
  const { filmId, categoryId } = props.match.params;
  const { addEquipment, errors, clearEquipment, initialiseNewEquipment, equipment, fetchEquipmentAcquisitionMethods, acquisitionMethods } =
    props;

  useEffect(() => {
    fetchEquipmentAcquisitionMethods();
  }, []);

  useEffect(() => {
    initialiseNewEquipment();
  }, [initialiseNewEquipment]);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/equipment-category/${categoryId}/equipment`;
      return <Redirect to={url} />;
    }
  }

  function onAddEquipment(equipment) {
    sanitiseDataTypes(equipment);
    addEquipment(filmId, categoryId, equipment);
  }

  function sanitiseDataTypes(equipment) { }

  const emptyEquipment = {
    dailyRate: 30,
    deliveryAndCollectionCost: null,
    errors: {},
    hasWarnings: false,
    id: "d78f0878-e06c-4fe1-93ed-19a9fb4dfae1",
    link: null,
    name: "35mm",
    notes: null,
    readOnly: false,
    required: true,
    success: true,
    threeDayRate: null,
    twoWeeklyRate: null,
    warnings: {},
    weeklyRate: null
  }

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/equipment-category/:categoryId/equipment/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        initialValues={equipment}
        loading={false}
        onSubmit={onAddEquipment}
        acquisitionMethods={acquisitionMethods}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    errors: state.equipmentState.errors,
    redirect: state.equipmentState.redirect,
    equipment: state.equipmentState.equipment,
    acquisitionMethods: state.equipmentState.acquisitionMethods
  };
}

export default connect(mapStateToProps, {
  addEquipment,
  clearRedirect,
  clearEquipment,
  initialiseNewEquipment,
  fetchEquipmentAcquisitionMethods
})(AddDepartment);
