import { connect } from "react-redux";
import { useEffect } from "react";
import Loader from "../../../shared/loader";
import { fecthPeopleDetailsProgress, sendDietaryRequirements, sendDietaryRequirementsToPerson, updateExludeDrink } from "./ducks/actions";
import MessagesForm from "./messages";
import Onboarding from "../../../shared/onboarding/onboarding";
import confirm from "../../../shared/modal/confirm";
import EmailRecipients from "../call-sheet/email-recipients";
import { Actor, BackgroundActor, CrewMember } from "./ducks/reducer";

function ListShootingDayCrewContainer(props) {
  const { filmId } = props.match.params;
  const {
    fecthPeopleDetailsProgress,
    sendDietaryRequirements,
    sendDietaryRequirementsToPerson,
    loading,
    errors,
    readOnly,
    peopleDetailsProgress,
    onboardingSteps,
    progress,
    updateExludeDrink,
    savingExcludeDrinks
  } = props;

  useEffect(() => {
    fecthPeopleDetailsProgress(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  const onSendDietaryRequirements = () => {
    if (peopleDetailsProgress.hasPeopleWithoutEmailAddress) {
      confirm(() => sendDietaryRequirements(filmId), null, "Some people have no email addresses, see list below. Do you want to send to the ones that have?", "Missing Email Addresses");
    } else {
      confirm(() => sendDietaryRequirements(filmId), null, "Are you sure you want to send dietary requirements email to all actors and crew?", "Confirmation");
    }
  }

  const onSendPerson = (personId, personType, fullName, resend) => {
    confirm(() => {
      sendDietaryRequirementsToPerson(filmId, personId, personType);
    }, null, `Are you sure you want to ${resend ? "re" : ""}send dietary requirment email to ${fullName}?`, "Confirmation");
  };


  function renderShootingDays(filmId) {
    return (
      <div className="blade-content wider">
        <p className="menu-label">Message Actors and Crew</p>
        <MessagesForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          loading={loading}
          readOnly={readOnly}
          sendDietaryRequirements={onSendDietaryRequirements}
          progress={progress}
          initialExcludeDrinks={peopleDetailsProgress.excludeDrinks}
          shootingDaySettingsId={peopleDetailsProgress.shootingDaySettingsId}
          updateExludeDrink={updateExludeDrink}
          savingExcludeDrinks={savingExcludeDrinks}
        />
        <div className="mt-1">
          <EmailRecipients filmId={filmId}
            people={peopleDetailsProgress}
            onSendPerson={onSendPerson}
            includeClicked={true}
            canSend={true}
            actorType={Actor}
            backgroundActorType={BackgroundActor}
            crewMemberType={CrewMember}
            clickedName="Viewed"
            totalStatuses={7}
            includeSubmitted={true}
            canAccessActors={peopleDetailsProgress.canAccessActors}
            canAccessBackgroundActors={peopleDetailsProgress.canAccessBackgroundActors}
            canAccessCrew={peopleDetailsProgress.canAccessCrew}
            submittedName="Saved" />
        </div>
        <Onboarding onboardingSteps={onboardingSteps} section="Messages" />
      </div>
    );
  }

  return loading ? renderLoading() : renderShootingDays(filmId);
}

function mapStateToProps(state) {
  const { rootState, messagesState } = state.shootingdaysState;
  return {
    shootingDay: rootState.shootingday,
    readOnly: messagesState.readOnly,
    loading: messagesState.loading,
    saved: messagesState.saved,
    errors: messagesState.errors,
    peopleDetailsProgress: messagesState.peopleDetailsProgress,
    progress: messagesState.progress,
    onboardingSteps: messagesState.onboardingSteps,
    savingExcludeDrinks: messagesState.savingExcludeDrinks
  };
}

export default connect(mapStateToProps, {
  fecthPeopleDetailsProgress,
  sendDietaryRequirements,
  sendDietaryRequirementsToPerson,
  updateExludeDrink
})(ListShootingDayCrewContainer);
