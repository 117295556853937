import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import AnonymousPage from "../anonymous-pages/anonymous-page";
import BoxWraper from "../box-wrapper";
import queryString from "query-string";
import { setEmailAddress, forgotPassword } from "./ducks/actions";
import Form from "./form";

function ForgotPassword(props) {
  const { setEmailAddress, forgotPassword, loading, errors, user } = props;
  const queryParams = queryString.parse(window.location.search)

  useEffect(() => {
    setEmailAddress(props.location?.state?.emailAddress);
  }, [props.location?.state?.emailAddress]);

  useEffect(() => {
    if (queryParams?.email) {
      setEmailAddress(queryParams?.email);
    }
  }, [queryParams?.email]);

  function onForgotPassword(user) {
    forgotPassword(user);
  }

  return (
    <AnonymousPage>
      <BoxWraper>
      {loading && <Loader />}
      {!loading && <Form
          errors={errors}
          loading={false}
          onSubmit={onForgotPassword}
          initialValues={user}
          {...props}
        />}
      </BoxWraper>
    </AnonymousPage>
  );
}

function mapStateToProps(state) {
  const { accountState } = state;
  return {
    user: accountState.forgotPasswordState.user,
    errors: accountState.forgotPasswordState.errors,
    loading: accountState.forgotPasswordState.loading,
    submitted: accountState.forgotPasswordState.submitted
  };
}

export default connect(mapStateToProps, {
  setEmailAddress,
  forgotPassword
})(ForgotPassword);
