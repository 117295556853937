import { useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation, Trans } from 'react-i18next';
import "./risk-assessment.scss";
import DeleteIcon from "../../icons/delete";
import auth from "../../../shared/auth";
import confirm from "../../../shared/modal/confirm-promise";
import formatBytes from "../../../shared/formatBytes";

function RiskAssessments(props) {
  const { label, riskAssessments, onUploadRiskAssessment, setUploadError, deleteRiskAssessment, shootingDayId, onBoardingClass, readOnly } = props;

  const { t, i18n } = useTranslation(['confirm']);
  const acceptedFileTypes = [".pdf"];

  const [saving, setSaving] = useState(false);

  const uploadStatus = null;

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append(
      "model",
      JSON.stringify({ shootingDayId })
    );
    onUploadRiskAssessment(formData);
  }

  return (

    <div className={onBoardingClass}>
      <label className="label">{label}</label>
      {!readOnly && <Dropzone
        accept={acceptedFileTypes.join()}
        multiple={true}
        disabled={!!uploadStatus}
        onDrop={async (acceptedFiles, rejectedFiles) => {
          if (acceptedFiles && acceptedFiles.length > 0) {
            setSaving(true);
            for (const file of acceptedFiles) {
              if (riskAssessments.some(r => r.fileName.toLowerCase() === file.name.toLowerCase())) {
                const overwrite = await confirm(() => uploadFile(file), null, `Do you wish to overwrite ${file.name}`, "Overwrite");
                if (overwrite) {
                  uploadFile(file)
                }
              } else {
                uploadFile(file);
              }
            }
          }

          if (rejectedFiles && rejectedFiles.length > 0) {
            const fileName = rejectedFiles[0].file.name;
            const lastDot = fileName.lastIndexOf(".");
            const extention = fileName.substring(lastDot);
            setUploadError(
              "File type " + extention + " is not supported"
            );
          }
        }}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section
            className={`image-drop-area ${isDragActive ? "active" : ""}`}>
            <div
              {...getRootProps({ className: `image-drop-area-inner ${isDragActive ? "active" : ""}` })}>
              <input {...getInputProps()} />
              <button className="button" disabled={!!uploadStatus}>Upload file…</button>
            </div>
          </section>
        )}
      </Dropzone>}

      {(riskAssessments || []).map((riskAssessment, index) => (
        <div key={index}>
          <a href={riskAssessment.url + "?t=" + auth.getImageToken()}>{riskAssessment.fileName} ({formatBytes(riskAssessment.fileSize)})</a>{!readOnly && <a onClick={() => deleteRiskAssessment(riskAssessment.id, riskAssessment.fileName)}>
            <DeleteIcon className="white-icon delete-risk-assessment" /></a>}
        </div>
      ))}
    </div>
  );
}

export default RiskAssessments;
