import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.scss";
import store from "./store";
import Marketing from "./marketing/index";
import getConfig from "./app/config";
import './langauge/i18n';
import AnonymousPages from "./anonymous-pages";

const config = getConfig();
(window as any).apiUrl = config.apiUrl;
(window as any).googleApiKey = config.googleApiKey;

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Switch>
        <Route exact path="/" component={Marketing} />
        <AnonymousPages></AnonymousPages>
      </Switch>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);