import { combineReducers } from "redux";
import unapprovedUsersReducer from "./unapproved-users/ducks/reducer";
import adminUsersReducer from "./users/ducks/reducer";
import adminUserReducer from "./user/ducks/reducer";
import adminUserFilmsReducer from "./user-films/ducks/reducer";
import adminUsersByCountryReducer from "./user-countries/ducks/reducer";
import adminUsersByDateReducer from "./user-dates/ducks/reducer";
import adminUserCountriesByDateReducer from "./user-countries-registered-date/ducks/reducer";

export default combineReducers({
  unapprovedUsersState: unapprovedUsersReducer,
  adminUsersState: adminUsersReducer,
  adminUserState: adminUserReducer,
  adminUsersByCountryState: adminUsersByCountryReducer,
  adminUsersByDateState: adminUsersByDateReducer,
  adminUserCountriesByDateState: adminUserCountriesByDateReducer,
  adminUserFilmsState: adminUserFilmsReducer
});
