import getApiClient, { getAnnonymousApiClient } from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";
import invitations from "../../../shared/invitations";

export const REGISTER_GET_LANGUAGES = "REGISTER_GET_LANGUAGES";
export const REGISTER_GET_CURRENCIES = "REGISTER_GET_CURRENCIES";
export const CLEAR_REGISTERED = "CLEAR_REGISTERED";

export function getCountries() {
  return (dispatch) => {
    dispatch({
      type: "REGISTER_GET_COUNTRIES",
      payload: getApiClient().get(`/lookup/countries`),
    });
  };
}

export function getLanguages() {
  return (dispatch) => {
    dispatch({
      type: REGISTER_GET_LANGUAGES,
      payload: getApiClient().get(`/lookup/languages`),
    });
  };
}

export function getCurrencies() {
  return (dispatch) => {
    dispatch({
      type: REGISTER_GET_CURRENCIES,
      payload: getApiClient().get(`/lookup/Currencies`),
    });
  };
}

export function clearForm() {
  return (dispatch) => {
    dispatch({
      type: "REGISTER_CLEAR_FORM",
    });
  };
}

export function register(user) {
  return (dispatch) => {
    dispatch({
      type: "REGISTER",
      payload: getAnnonymousApiClient().post(`/account`, user),
      meta: { user }
    }).then((response) => {
      invitations.removeReferralCode();
      dispatch(showInfo(`Successfully registered`));
    })
      .catch((response) => {
        dispatch(showError(`Error registering`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REGISTER_REDIRECT",
    });
  };
}

export function clearRegistered() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REGISTERED,
    });
  };
}
