import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchCostumesInScene } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import { Link } from "react-router-dom";

function ListCostumesContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchCostumesInScene, loading, sceneCostumes, errors, readOnly } = props;

  useEffect(() => {
    fetchCostumesInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(sceneCostumes, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List sceneCostumes={sceneCostumes} filmId={filmId} sceneId={sceneId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <Link
          to={`/films/${filmId}/scenes/${sceneId}/costumes/add`}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Costume
        </Link>}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneCostumes, filmId, sceneId);
}

function mapStateToCostumes(state) {
  return {
    sceneCostumes: state.scenesState.costumeState.costumes,
    loading: state.scenesState.costumeState.loading,
    errors: state.scenesState.costumeState.errors,
    readOnly: state.scenesState.costumeState.readOnly
  };
}

export default connect(mapStateToCostumes, {
  fetchCostumesInScene
})(ListCostumesContainer);
