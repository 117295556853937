import Progress from "../../../shared/progress";

const SmartBreakdown = (props) => {
    const {
        progress,
        smartBreakdown } = props;

    return (
        <div>
            {!progress && <button className="button" onClick={smartBreakdown}>Smart Breakdown</button>}
            <Progress progress={progress} className="script-progress" />
        </div>
    )
}

export default SmartBreakdown;