import getApiClient from "../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export const FETCH_USERS_BY_COUNTRY = "FETCH_USERS_BY_COUNTRY";

export function fetchUsersByCountry() {
  return (dispatch) => {
    dispatch({
      type: FETCH_USERS_BY_COUNTRY,
      payload: getApiClient().get(`/admin/usersByCountry`)
    });
  };
}
