import { sortByName } from "./../../../../shared/utility";
import { FETCH_FILM_CREW_MEMBERS_OVERVIEW } from "./actions";

const defaultState = {
  crewMembers: [],
  loading: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_FILM_CREW_MEMBERS_OVERVIEW + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_FILM_CREW_MEMBERS_OVERVIEW + "_FULFILLED": {
      return {
        ...state,
        crewMembers: action.payload.data.crewMembers,
        loading: false,
        errors: null
      };
    }


    case FETCH_FILM_CREW_MEMBERS_OVERVIEW + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
