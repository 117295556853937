import { PrivateRoute } from "../../../shared/private-route";
import ListCostumesContainer from "./list-container";
import AddCostume from "./add";

const CostumeRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/costumes"
        component={ListCostumesContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/costumes/add"
        component={AddCostume}
      />
    </>
  );
};

export default CostumeRoutes;
