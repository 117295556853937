import React from "react";
import InputSlider from "../../shared/controls/input-slider";
import NumberFormat from "react-number-format";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function CateringBudget(props) {
  const {
    catering,
    onChangeCateringBuget,
    onChangeCateringDays,
    maxDays,
    hasShootingDays,
    readOnly,
    filmId,
    saving
  } = props;

  console.log("catering", catering);

  return (
    <tr>
      <td>Catering</td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={catering.budget}
          onChange={(val) => onChangeCateringBuget(catering.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-centered">{catering.mealDays}</td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={catering.total}
        />
      </td>
    </tr>
  );
}

export default CateringBudget;
