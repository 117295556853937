import { reorderScenes, sortScenesByNumber } from "../../../../shared/utility";
import { REORDER_SHOOTING_ORDER_DAY_SCENES } from "./actions";

const defaultState = {
  scenesWithoutDays: [],
  scenes: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTING_DAY_SCENES_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SHOOTING_DAY_SCENES_FULFILLED": {
      const scenesWithoutDays = action.payload.data.scenesWithoutDays;
      sortScenesByNumber(scenesWithoutDays);
      return {
        ...state,
        scenes: action.payload.data.scenes,
        scenesWithoutDays: scenesWithoutDays,
        readOnly: action.payload.data.readOnly,
        canAccessScenes: action.payload.data.canAccessScenes,
        canAccessLocations: action.payload.data.canAccessLocations,
        loading: false,
        errors: null
      };
    }

    case "UPDATE_SHOOTING_DAY_SCENES_PENDING": {
      return {
        ...state,
        errors: null
      };
    }

    case "UPDATE_SHOOTING_DAY_SCENES_FULFILLED": {
      return {
        ...state,
        errors: null
      };
    }

    // case "UPDATE_SHOOTING_DAY_ACTORS_FULFILLED": {
    //   const shootingday = { ...action.meta.shootingday, ...action.payload.data.shootingDay };
    //   const shootingdays: any = state.shootingdays.map((c: any, index: number) => {
    //     if (c.id === shootingday.id) {
    //       return { ...c, ...shootingday };
    //     } else {
    //       return c;
    //     }
    //   });

    //   return {
    //     ...state,
    //     shootingday,
    //     shootingdays,
    //     loadingShootingDay: false,
    //     errors: null,
    //   };
    // }

    case "UPDATE_SHOOTING_DAY_SCENES_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case REORDER_SHOOTING_ORDER_DAY_SCENES: {
      const startIndex = action.meta.startIndex;
      const endIndex = action.meta.endIndex;

      // from list to scenes without days
      if (action.meta.destinationDay === "newScene") {
        if (action.meta.sourceDay === "newScene") {
          return state;
        }

        const newScenesWithoutDays = Array.from(state.scenesWithoutDays);

        const sourceScenes = Array.from(state.scenes);
        const original = sourceScenes.splice(action.meta.startIndex, 1);

        newScenesWithoutDays.splice(endIndex, 0, original[0]);

        // reorder newScenesWithoutDays
        sortScenesByNumber(newScenesWithoutDays);

        return {
          ...state,
          scenes: sourceScenes,
          scenesWithoutDays: newScenesWithoutDays,
          pristine: false
        };
      }

      // from scenes without days to list
      if (action.meta.sourceDay === "newScene") {
        const newScenesWithoutDays = Array.from(state.scenesWithoutDays);
        const sourceScene = newScenesWithoutDays[startIndex];
        newScenesWithoutDays.splice(startIndex, 1);


        const destinationScenes = Array.from(state.scenes);
        destinationScenes.splice(action.meta.endIndex, 0, sourceScene);


        return {
          ...state,
          scenes: destinationScenes,
          scenesWithoutDays: newScenesWithoutDays,
          pristine: false
        };
      }

      // must be with scenes list
      const destinationScenes = Array.from(state.scenes);
      const scenes = reorderScenes(
        destinationScenes,
        action.meta.startIndex,
        action.meta.endIndex,
      );

      return {
        ...state,
        scenes: scenes,
        pristine: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
