import React from "react";
import UnapprovedUsersPage from "./unapproved-users/container";
import UsersPage from "./users/container";
import UserContainer from "./user/container";
import UserFilmsContainer from "./user-films/container";
import UserCountriesPage from "./user-countries/container";
import UserDates from "./user-dates/container";
import UserCountryDates from "./user-countries-registered-date/container";
import CompetitorPrices from "./competitor-prices/container";
import AddChangeLog from "./change-log/add";
import EditChangeLog from "./change-log/edit";
import ListChangeLogs from "./change-log/list-container";
import FaqRoutes from "./faq/routes";
import { AdminRoute } from "../shared/admin-route";
import { Route } from "react-router-dom";

const AdminRoutes = () => {
  return (
    <>
      <AdminRoute
        exact
        path="/admin/unapproved-users"
        component={UnapprovedUsersPage}
      />
      <AdminRoute
        exact
        path="/admin/users"
        component={UsersPage}
      />
      <AdminRoute
        exact
        path="/admin/users/:userId"
        component={UserContainer}
      />
      <AdminRoute
        exact
        path="/admin/users/:userId/films"
        component={UserFilmsContainer}
      />
      <AdminRoute
        exact
        path="/admin/users/countries"
        component={UserCountriesPage}
      />
      <AdminRoute
        exact
        path="/admin/users/dates"
        component={UserDates}
      />
      <AdminRoute
        exact
        path="/admin/users/countries/dates"
        component={UserCountryDates}
      />
      <AdminRoute
        exact
        path="/admin/change-logs"
        component={ListChangeLogs}
      />

      <AdminRoute
        exact
        path="/admin/change-logs/add"
        component={AddChangeLog}
      />

      <AdminRoute
        exact
        path="/admin/change-logs/:changeLogId/edit"
        component={EditChangeLog}
      />

      <AdminRoute
        exact
        path="/admin/competitor-prices"
        component={CompetitorPrices}
      />
      <Route exact path="/competitor-prices" component={CompetitorPrices} />
      <FaqRoutes />
    </>
  );
};

export default AdminRoutes;
