import React, { useEffect } from "react";
import { connect } from "react-redux";
import BoxWraper from "../account/box-wrapper";
import ScrollIntoView from "../shared/scroll-into-view/scroll-into-view";
import {  
  fetchChangeLog  
} from "./ducks/actions";
import View from './view';

function ViewChangeLogContainer(props) {  
  const { 
    errors,
    changeLog,
    fetchChangeLog,
    loading    
     } = props;

  const changeLogId = props.match.params.changeLogId;

  useEffect(() => {
    fetchChangeLog(changeLogId);    
  }, [changeLogId]);

  return (
    
        <View changeLog={changeLog} />
    
  );
}

function mapStateToChangeLogs(state) {
  const { changeLogState } = state;
  return {
    changeLog: changeLogState.changeLog,
    errors: changeLogState.errors,
    loading: changeLogState.loading    
  };
}

export default connect(mapStateToChangeLogs, {
  fetchChangeLog
})(ViewChangeLogContainer);
