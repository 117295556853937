import React from "react";
import { connect } from "react-redux";
import {
  setActiveOnboardingStep,
  updateOnboardingComplete,
  setActiveOnboarding
} from "../../app/ducks/actions";

const OnboardingTooltip = ({
  continuous,
  index,
  step,
  size,
  closeProps,
  tooltipProps,
  setActiveOnboardingStep,
  updateOnboardingComplete,
  setActiveOnboarding,
  activeOnboarding
}) => {
  const stepButtons: number[] = [];

  for (let i = 0; i < size; i++) stepButtons.push(i);

  const setStepIndex = (step) => {
    setActiveOnboardingStep(step);

    if (step === size) {
      updateOnboardingComplete(activeOnboarding);
      setActiveOnboarding(null);
    }
  };

  return (
    <div className="onboarding-tooltip" {...tooltipProps}>
      <div className="onboarding-tooltip__body">
        <button className="onboarding-tooltip__close" {...closeProps}>
          Close
        </button>
        <div className="onboarding-tooltip__content">
          {step.title && (
            <h2 className="onboarding-tooltip__header">{step.title}</h2>
          )}
          <div dangerouslySetInnerHTML={{ __html: step.content }}></div>
        </div>
      </div>
      <div className="onboarding-tooltip__actions">
        <div className="onboarding-tooltip__steps">
          {stepButtons.map((step) => (
            <button
              key={step}
              className={step === index ? "active" : ""}
              onClick={() => setStepIndex(step)}>
              Step {step + 1}
            </button>
          ))}
        </div>
        <div>
          <button
            className="onboarding-tooltip__back-btn"
            disabled={index <= 0}
            onClick={() => setStepIndex(index - 1)}>
            Back
          </button>
          <button
            className="onboarding-tooltip__next-btn"
            disabled={!continuous}
            onClick={() => setStepIndex(index + 1)}>
            {step?.nextButtonText ? step?.nextButtonText : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    activeOnboarding: state.commonState.activeOnboarding,
    activeOnboardingStep: state.commonState.activeOnboardingStep
  };
}

export default connect(mapStateToProps, {
  setActiveOnboardingStep,
  updateOnboardingComplete,
  setActiveOnboarding
})(OnboardingTooltip);
