import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../shared/loader";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  addFaq,
  clearFaq,
  clearRedirect,
  getLanguages,
  translateFaq,
  changeSection,
  addLanguage,
  removeLanguage,
  changeLanguage,
  changeLanguageQuestion,
  changeLanguageAnswer  
} from "./ducks/actions";
import Form from "./form";

function AddFaq(props) {  
  const { addFaq,    
    pristine,
    errors,
    faq,
    clearFaq,
    loading,
    redirect,
    clearRedirect,
    apiVersion,
    pageSections,
    getLanguages,
    languages,
    translateFaq,
    changeSection,
    addLanguage,
    removeLanguage,
    changeLanguage,
    changeLanguageQuestion,
    changeLanguageAnswer  
     } = props;
    
  useEffect(() => {
    clearFaq(apiVersion);    
    getLanguages();
  }, []);

  if (redirect) {
    if (redirect.to === "list") {
      clearRedirect();
      const url = `/admin/faqs`;
      return <Redirect to={url} />;
    } 
  }

  function onAddFaq(e) {
    e.preventDefault();    
    addFaq(faq);    
  }

  
  const onTranlate = (languageCode) => {
    const englishLanguage = faq.languages.find(language => language.languageCode === "en");
    translateFaq(englishLanguage, languageCode);
  };

  const onAddLanguage = () => { 
    addLanguage();
  };

  const onRemoveLanguage = (languageId) => { 
    removeLanguage(languageId);
  };

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/admin/faqs/add"
      loading={false}>
      {loading && <Loader/> }
      {!loading && <Form
        errors={errors}
        pristine={pristine}   
        pageSections={pageSections}        
        loading={loading}
        handleSubmit={onAddFaq}
        languages={languages}
        mode="add"        
        faq={faq}
        onTranlate={onTranlate} 
        addLanguage={onAddLanguage}
        removeLanguage={onRemoveLanguage}
        onChangeSection={changeSection}
        changeLanguage={changeLanguage}
        changeLanguageQuestion={changeLanguageQuestion}
        changeLanguageAnswer={changeLanguageAnswer}  
      />}
    </ScrollIntoView>
  );
}

function mapStateToFaqs(state) {
  const { adminFaqState } = state;
  return {
    faq: adminFaqState.faq,
    errors: adminFaqState.errors,
    redirect: adminFaqState.redirect,
    loading: adminFaqState.loading,
    languages: adminFaqState.languages,
    pristine: adminFaqState.pristine,
    pageSections: state.commonState.pageSections,
  };
}

export default connect(mapStateToFaqs, {
  addFaq,
  clearFaq,
  clearRedirect,
  getLanguages,
  translateFaq,
  changeSection,
  addLanguage,
  removeLanguage,
  changeLanguage,
  changeLanguageQuestion,
  changeLanguageAnswer  
})(AddFaq);
