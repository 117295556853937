import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { NavLink } from "react-router-dom";
import DeleteIcon from "../../icons/delete";
import { OnboardingStep6ClassName, OnboardingStep7ClassName } from "./ducks/reducer";
import CanAccessLink from "../../../shared/can-access-link";
import { formatTime, formatTimeFromSeconds } from "../../../shared/format-time";


const Shot = ({
  filmId,
  shootingdayId,
  index,
  shot,
  timeSlot,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  isSelected,
  isGhosting,
  selectionCount,
  deleteShot,
  readOnly,
  showOnboarding,
  canAccessScenes,
  canAccessShots
}) => {
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle, isSelected) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isSelected ? "#222E44" : isDragging ? "#947eed" : "#040f21",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const primaryButton = 0;

  // Using onClick as it will be correctly
  // preventing if there was a drag
  const onClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    performAction(event);
  };

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (
    event: MouseEvent | KeyboardEvent
  ) => {
    const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) =>
    event.shiftKey;

  const performAction = (event: MouseEvent | KeyboardEvent) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(shot.shotId);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(shot.shotId);
      return;
    }

    toggleSelection(shot.shotId);
  };

  console.log("shot.estimatedShootingDuration", shot.estimatedShootingDuration);

  return (
    <Draggable key={shot.shotId} draggableId={shot.shotId} index={index} isDragDisabled={readOnly}>
      {(provided, snapshot) => (
        <>
          <tr
            //onClick={onClick}
            className={showOnboarding ? OnboardingStep6ClassName : null}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              isSelected
            )}>
            <td>
              {!readOnly && <i className="material-icons">drag_indicator</i>}
              <CanAccessLink to={`/films/${filmId}/scenes/${shot.sceneId}/edit`} canAccess={canAccessScenes}>
                {shot.sceneNumber}
              </CanAccessLink>
            </td>
            <td>
              <CanAccessLink
                to={`/films/${filmId}/scenes/${shot.sceneId}/shots/${shot.shotId}/edit`} canAccess={canAccessShots}>
                {shot.shotNumber}
              </CanAccessLink>
            </td>
            <td>{shot.lensFocalLength}</td>
            <td>{shot.movements}</td>
            <td>{shot.actors?.map((actor, index) => (<><CanAccessLink to={`/films/${filmId}/shooting-days/${shootingdayId}/actors#${actor.id}`} key={index} canAccess={true}>{actor.name}</CanAccessLink>{index < shot.actors.length - 1 ? ", " : ""}</>))}</td>
            <td>{formatTime(shot.estimatedShootingDuration)}</td>
            <td>
              {!readOnly &&
                <a
                  onClick={() => deleteShot(timeSlot, shot)}>
                  <DeleteIcon className={`white-icon ${showOnboarding ? OnboardingStep7ClassName : ""}`} />
                </a>
              }
            </td>
          </tr>
        </>
      )}
    </Draggable>
  );
};

export default Shot;
