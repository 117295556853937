import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const CALENDAR_UPDATE_DATE = "CALENDAR_UPDATE_DATE";
export const UPDATE_SHOOTING_CALENDAR = "UPDATE_SHOOTING_CALENDAR";

export function updateEvent(plainEventObject, allowChangingDayOrder) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_EVENT',
      meta: { plainEventObject, allowChangingDayOrder }
    })
  }
};

export function updateDate(dayId, newDate, allowChangingDayOrder) {
  return (dispatch) => {
    dispatch({
      type: CALENDAR_UPDATE_DATE,
      meta: { dayId, newDate, allowChangingDayOrder }
    })
  }
};

export function changeStartDate(date) {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_START_DATE',
      meta: { date }
    })
  }
};

export function changeDayOfWeek(dayOfWeek, selected) {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_DAY_OF_WEEK',
      meta: { dayOfWeek, selected }
    })
  }
};

export function fetchShootingDays(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_CALEDAR",
      payload: getApiClient().get(`/film/${filmId}/shootingDay/calendar`),
    });
  };
}

export function smartShootingOrder(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_SMART_SHOOTING_ORDER",
      payload: getApiClient().get(`/film/${filmId}/scene/smartShootingOrder`),
    });
  };
}

export function fetchScenesShootingOrderByDay(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_SHOOTING_ORDER_BY_DAY",
      payload: getApiClient().get(`/film/${filmId}/scene/shootingOrderByDay`),
    });
  };
}

export function smartScenesShootingOrderByDay(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_SHOOTING_ORDER_BY_DAY",
      payload: getApiClient().get(`/film/${filmId}/scene/smartShootingOrderByDay`),
    });
  };
}

export function updateShootingCalendar(filmId, days, daysOfWeek, allowChangingDayOrder) {

  let daysOfWeekArray: any[] = [];
  let daysOfWeekKeys = Object.keys(daysOfWeek);
  daysOfWeekKeys.forEach((k) => {
    daysOfWeekArray.push({ ...daysOfWeek[k], dayOfWeek: +k });
  });
  const command = { days, daysOfWeek: daysOfWeekArray, allowChangingDayOrder };
  return (dispatch) => {
    dispatch({
      type: UPDATE_SHOOTING_CALENDAR,
      payload: getApiClient().put(`/film/${filmId}/shootingDay/calendar`, command),
      meta: { days },
    })
      .then((response) => {
        dispatch(showInfo(`Updated calendar`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating calendar`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addScenesShootingOrderDays(filmId, days) {
  const command = { days };
  return (dispatch) => {
    dispatch({
      type: "ADD_SCENES_SHOOTING_ORDER_DAYS",
      payload: getApiClient().post(`/film/${filmId}/scene/shootingOrderByDay`, command),
      meta: { days },
    })
      .then((response) => {
        dispatch(showInfo(`Updated scene orders`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating scene orders`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addShootingDay() {
  return (dispatch) => {
    dispatch({
      type: "ADD_SCENES_SHOOTING_ORDER_DAY"
    })
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
