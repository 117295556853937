import actionTypes from "./action-types";
const defaultState = null;

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case actionTypes.SHOW_INFO_NOTIFICATION:
    case actionTypes.SHOW_WARNING_NOTIFICATION:
    case actionTypes.SHOW_ERROR_NOTIFICATION: {
      return action.payload;
    }

    case actionTypes.CLEAR_NOTIFICATIONS: {
      return defaultState;
    }

    default:
      return state;
  }
};

export default reducer;
