import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "../../shared/errors";
import {
  renderMultiReactSelect,
  renderReactSelect,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField
} from "../../shared/form-helpers";
import required from "../../shared/validation";

function UserForm(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    handleDelete,
    //onResendInvite,
    mode,
    submitFailed,
    invalid,
    userAccessEnum,
    canEdit,
    roles
  } = props;

  const thisCollaborator = props.initialValues || {};
  const readOnly = thisCollaborator.readOnly;

  const roleOptions = (roles || []).map((item) => {
    return { value: item.id, label: item.name };
  });

  const onResendInvite = () => {
    props.change("resendInvite", true);
  };

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Collaborator</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Collaborator</h2>}
      {errors && <Errors errors={errors} />}

      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="field-body">
          <Field
            name="collaboratorFirstName"
            label="First Name"
            component={renderTextField}
            validate={[required]}
            required
            readOnly={readOnly || (mode === "edit" && !canEdit)}
          />
          <Field
            name="collaboratorLastName"
            label="Last Name"
            component={renderTextField}
            validate={[required]}
            required
            readOnly={readOnly || (mode === "edit" && !canEdit)}
          />
        </div>

        <div className="field-body">
          <Field
            name="collaboratorEmailAddress"
            label="Email Address"
            component={renderTextField}
            validate={[required]}
            readOnly={readOnly || (mode === "edit" && !canEdit)}
            required
          />
        </div>

        <div className="field-body mt-1">
          <Field
            name="roleIds"
            label="Roles"
            component={renderMultiReactSelect}
            options={roleOptions}
            validate={[required]}
            required
            readOnly={readOnly}></Field>
        </div>

        <div className="field-body mt-1">
          <Field
            name="message"
            label="Message"
            readOnly={readOnly || (mode === "edit" && !canEdit)}
            component={renderTextAreaField}
          />
        </div>

        {mode === "edit" && (
          <>
            <div className="mt-1 tag is-light">
              {thisCollaborator.statusDescription}
            </div>
            {(thisCollaborator.status !== 4) &&
              <div className={`mt-1 ml-1 tag ${thisCollaborator.emailingStatus === 5 ? "is-danger" : "is-purple"}`}>
                {thisCollaborator.emailingStatusDescription}
              </div>}
          </>
        )}

        {!readOnly && (
          <div className="buttons mt-1 sticky-buttons">
            <button
              type="submit"
              className={
                submitting
                  ? "button is-primary is-loading"
                  : "button is-primary"
              }
              disabled={pristine || submitting}>
              {mode === "add" ? "Invite" : "Save"}
            </button>
            <button
              type="button"
              className="button is-secondary"
              disabled={pristine || submitting}
              onClick={reset}>
              Reset Values
            </button>
            {mode === "edit" && (
              <>
                {(thisCollaborator.status !== 4) && (
                  <button
                    type="submit"
                    className="button"
                    disabled={submitting || !props.initialValues}
                    onClick={() => onResendInvite()}>
                    Resend Invite
                  </button>
                )}
                <button
                  type="button"
                  className="button is-text"
                  disabled={submitting || !props.initialValues}
                  onClick={() => handleDelete(thisCollaborator)}>
                  Delete
                </button>
              </>
            )}
          </div>
        )}
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export default reduxForm({
  form: "userForm",
  enableReinitialize: true
})(UserForm);
