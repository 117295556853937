import React from "react";
import { NavLink } from "react-router-dom";

const SetDressingsList = ({ filmId, sceneId, setDressings }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Set Dressings in scene</p>
      <ul className="menu-list">
        {(setDressings || []).map((setDressing, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/set-dressings/${setDressing.id}/edit`}
                activeClassName="is-active"
              >
                {setDressing.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default SetDressingsList;
