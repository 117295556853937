import React from "react";
import { NavLink } from "react-router-dom";

const SceneSettingsList = ({ sceneSettings, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Scene Settings</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/scene-settings/overview`}
            activeClassName="is-active"
          >
            Overview
          </NavLink>
        </li>
        <hr />
        {(sceneSettings || []).map((sceneSetting, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/scene-settings/${sceneSetting.id}/edit`}
                activeClassName="is-active"
                title={sceneSetting.name}
              >
                {sceneSetting.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default SceneSettingsList;
