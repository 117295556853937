import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../../shared/loader";
import { fetchEquipmentCategories } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list-categories";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListEquipmentCategoriesContainer(props) {
  const filmId = props.match.params.filmId;
  
  const { fetchEquipmentCategories, loading, equipmentCategories, errors, readOnly } = props;

  useEffect(() => {
    fetchEquipmentCategories(filmId);
  }, [filmId, fetchEquipmentCategories]);
  
  return (
    <ScrollIntoView
      className="blade-menu"
      path="/films/:filmId/equipment-category/add"
      loading={loading}>
        {loading && <Loader/>}
      <List categories={equipmentCategories} filmId={filmId} />
      {!readOnly && !window.location.pathname.endsWith("add") && (
        <NavLink
          to={`/films/${filmId}/equipment-category/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Category
        </NavLink>
      )}
    </ScrollIntoView>
  ); 
}

function mapStateToProps(state) {
  return {
    readOnly: state.equipmentCategoryState.readOnly,
    loading: state.equipmentCategoryState.loading,
    errors: state.equipmentCategoryState.errors,
    equipmentCategories: state.equipmentCategoryState.equipmentCategories    
  };
}

export default connect(mapStateToProps, {
  fetchEquipmentCategories
})(ListEquipmentCategoriesContainer);
