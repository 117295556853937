import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../shared/loader";
import { fetchVisualEffects } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListPropsContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchProps, loading, visualEffects, errors, readOnly } = props;

  useEffect(() => {
    fetchProps(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderVisualEffects(visualEffects, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/visual-effects"
        loading={loading}>
        <List visualEffects={visualEffects} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/visual-effects/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Visual Effect
        </NavLink>}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderVisualEffects(visualEffects, filmId);
}

function mapStateToProps(state) {
  const { visualEffects, loading, errors, readOnly } = state.visualEffectsState;
  return { visualEffects, loading, errors, readOnly };
}

export default connect(mapStateToProps, {
  fetchProps: fetchVisualEffects
})(ListPropsContainer);
