import { CKEditor } from 'ckeditor4-react';
import { useEffect, useState } from 'react';

const CkEditor = (props) => {
  const { value, onChange, onReady } = props;

    const onOnChange = (evt) => {
        if (evt.name === "change") {
            const value = evt.editor.getData();
            if (value) {
                onChange(value);
            } 
        }
    }


  return (
    <div>
        <CKEditor          
                // onNamespaceLoaded={ CKEDITOR => {
                //     // Handles `namespaceLoaded` event which is fired once the CKEDITOR namespace is loaded.
                //     // This event is emitted only once.
                //     CKEDITOR.plugins.add('menstions');
                // } }
                // config={{ mentions: { feed: ['Anna', 'Thomas', 'John'], minChars: 0 }, extraPlugins: 'mentions' }}
                initData={value}                
                onChange={onOnChange}                
                onInstanceReady={ () => {
                    if (onReady) {
                        //onReady();
                    }
                } }
            />            
    </div>
  )
}

export default CkEditor