import Notification from './notification';
import ExclamationIcon from '../films/icons/exclamation';
import BellIcon from '../films/icons/bell';
import IconWithBadge from '../shared/icon-with-badge';
import { useState } from 'react';

const UserMessages = (props: any) => {

const { onDismissAllMessages, allMessages, onDismissMessage, onLinkClick } = props;

  const [showUserMessages, setShowUserMessages] = useState<boolean>(true);

  const onMouseLeave = () => {
    setShowUserMessages(true);
  };

  const onOnLinkClick = (link: any) => {
    onLinkClick(link);
    setShowUserMessages(false);
  };

  const infoMessages = allMessages?.filter(
    (m) => m.severity == 1
  );
  const infoMessageCount = infoMessages.length;

  const warningMessages = allMessages?.filter(
    (m) => m.severity == 2
  )
    const warningMessageCount = warningMessages.length;

  const errorMessages = allMessages?.filter(
    (m) => m.severity == 3
  )
  const errorMessageCount = errorMessages.length;

  let messageCount =
    (infoMessageCount ?? 0) +
    (warningMessageCount ?? 0) +
    (errorMessageCount ?? 0);
  let messageClass;
  if (errorMessageCount > 0) {
    //messageCount = errorMessageCount;
    messageClass = "error";
  } else if (warningMessageCount > 0) {
    //messageCount = warningMessageCount;
    messageClass = "warning";
  } else if (infoMessageCount > 0) {
    //messageCount = infoMessageCount;
    messageClass = "info";
  }

  const noNotifications = messageCount === 0;

    return(
        <div className="navbar-item has-dropdown is-hoverable" onMouseLeave={onMouseLeave}>
          <a className="navbar-link is-arrowless name">
            <IconWithBadge count={messageCount} countClass={messageClass}>
              <BellIcon className="bell-icon" />
            </IconWithBadge>
          </a>
          <div className={`navbar-dropdown is-boxed ${!showUserMessages ? "is-hidden" : ""}`}>
                  <div className="navbar-item notification-details">
                    <div>{noNotifications ? "No " : ""}Notifications</div>
                    {errorMessageCount > 0 && (
                      <div className="user-notification-circle top error">
                        {errorMessageCount}
                      </div>
                    )}
                    {warningMessageCount > 0 && (
                      <div className="user-notification-circle top warning">
                        {warningMessageCount}
                      </div>
                    )}
                    {infoMessageCount > 0 && (
                      <div className="user-notification-circle top info">
                        {infoMessageCount}
                      </div>
                    )}
                    {infoMessageCount > 0 && (
                      <button
                        className="button is-text dismiss-all-button text-link-on-white"
                        onClick={onDismissAllMessages}>
                        Dismiss All
                      </button>
                    )}
                  </div>
                  <div className="notifications-list">
                    {errorMessages.map((message, index) => {
                        return (
                          <Notification
                            key={index}
                            message={message}
                            severity="error"
                            icon={
                              <ExclamationIcon className="navigation-icon" />
                            }
                          />
                        );
                      })}

                    {warningMessages.map((message, index) => {
                        return (
                          <Notification
                            key={index}
                            message={message}
                            severity="warning"
                            icon={
                              <ExclamationIcon className="navigation-icon" />
                            }
                          />
                        );
                      })}

                    {infoMessages.map((message, index) => {
                        return (
                          <Notification
                            key={index}
                            message={message}
                            severity="info"
                            dismissMessage={onDismissMessage}
                            onLinkClick={onOnLinkClick}
                            icon={
                              <ExclamationIcon className="navigation-icon info" />
                            }
                          />
                        );
                      })}
                  </div>
                </div>
                </div>
    )
}

export default UserMessages;