import { UPDATE_PERSON_MESSAGE_EMAIL_STATUS, UPDATE_PERSON_MESSAGE_SUBMITTED } from "../../../../middleware/messageSignalRMiddleware";
import {
  FETCH_AVAILABILITY
} from "./actions";

interface SchedulingState {
  loading: boolean;
  saving: boolean;
  readOnly: boolean;
  refetch: boolean;
  dates: any[];
}

const defaultState: SchedulingState = {
  loading: true,
  saving: false,
  readOnly: false,
  refetch: false,
  dates: []
};

const calculateTotals = (dates, actorsAvailability, backgroundActorsAvailability, crewMemberAvailability, locationsAvailability) => {
  let datesTotals: number[] = [];
  dates.forEach((date) => {
    let dateCount = personCount(actorsAvailability, date);
    dateCount += personCount(backgroundActorsAvailability, date);
    dateCount += personCount(crewMemberAvailability, date);
    dateCount += personCount(locationsAvailability, date);
    datesTotals.push(dateCount);
  });

  return datesTotals;
};

const personCount = (peeople: any[], date) => {
  let dateCount = 0;
  peeople.forEach((person) => {
    if (person.availableDates.includes(date)) {
      dateCount++;
    }
  });

  return dateCount;
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_AVAILABILITY + "_PENDING": {
      const refetch = action.meta.refetch;
      return {
        ...state,
        loading: !refetch,
        refetch: false,
        errors: null
      };
    }

    case FETCH_AVAILABILITY + "_FULFILLED": {
      const dates = action.payload.data.potentialDates;
      const actorsAvailability = action.payload.data.actorsAvailability;
      const backgroundActorsAvailability = action.payload.data.backgroundActorsAvailability;
      const crewMemberAvailability = action.payload.data.crewMemberAvailability;
      const locationsAvailability = action.payload.data.locationsAvailability;

      const datesTotals = calculateTotals(dates, actorsAvailability, backgroundActorsAvailability, crewMemberAvailability, locationsAvailability);

      return {
        ...state,
        loading: false,
        refetch: false,
        dates,
        datesTotals,
        actorsAvailability,
        backgroundActorsAvailability,
        crewMemberAvailability,
        locationsAvailability,
        canAccessActors: action.payload.data.canAccessActors,
        canAccessBackgroundActors: action.payload.data.canAccessBackgroundActors,
        canAccessCrew: action.payload.data.canAccessCrew
      };
    }

    case FETCH_AVAILABILITY + "_REJECTED": {
      return {
        ...state,
        loading: false,
        refetch: false,
        errors: action.payload.response.data.errors
      };
    }

    case UPDATE_PERSON_MESSAGE_SUBMITTED: {
      const { messageType, personId, personType } = action.meta;
      if (messageType !== "Availability") {
        return state;
      }

      return {
        ...state,
        refetch: true
      };
    }

    default:
      return state;
  }
};

export default reducer;