import React from "react";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";

const ShootingDaysList = ({ filmId, sceneId, shootingDays }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Shooting Days</p>
      <ul className="menu-list">
        {(shootingDays || []).map((day, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/shooting-days/${day.id}/scenes`}
                activeClassName="is-active"
              >
                {day.number}. {day.date && <Moment format="Do MMMM YYYY">
                                  {day.date}
                                </Moment>}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ShootingDaysList;
