import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import {
  addProp,
  clearProp,
  clearRedirect,
  uploadImagesAndSaveProp,
  setName,
  fetchAllCharacters,
  fetchAllBackgroundCharacters,
} from "./ducks/actions";
import Form from "./form";

function AddProp(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const { addProp,
    prop,
    errors,
    uploadImagesAndSaveProp,
    setName,
    name,
    embedded,
    clearProp,
    sceneId,
    fetchAllCharacters,
    fetchAllBackgroundCharacters,
    allCharacters,
    allBackgroundCharacters } = props;

  useEffect(() => {
    clearProp();
    fetchAllCharacters(filmId);
    fetchAllBackgroundCharacters(filmId);
  }, []);

  useEffect(() => {
    if (name) {
      setName(name);
    }
  }, [name]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/props/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "scenes") {
      props.clearRedirect();
      const url = `/films/${filmId}/props/${props.redirect.id}/scenes/`;
      return <Redirect to={url} />;
    }
  }

  function onAddProp(prop) {
    prop.sceneId = sceneId;
    prop.characterIds = prop.characters?.map((x) => x.value || x);
    prop.backgroundCharacterIds = prop.backgroundCharacters?.map((x) => x.value || x);
    const files = prop.pictures || [];
    if (files && files.length > 0) {
      uploadImagesAndSaveProp(prop, files, true, filmId);
    } else {
      if (!prop.imageUrls) {
        prop.imageUrls = [];
      }
      addProp(filmId, prop);
    }
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/props/add"
      loading={false}>
      <Form
        errors={errors}
        propId={null}
        initialValues={prop}
        loading={false}
        onSubmit={onAddProp}
        mode="add"
        acquisitionMethodEnum={props.acquisitionMethodEnum}
        reduxFormValues={props.reduxFormValues}
        allCharacters={allCharacters}
        allBackgroundCharacters={allBackgroundCharacters}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { propsState, commonState } = state;

  return {
    prop: propsState.prop,
    errors: propsState.errors,
    redirect: propsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod,
    allCharacters: propsState.characters,
    allBackgroundCharacters: propsState.backgroundCharacters
  };
}

export default connect(mapStateToProps, {
  addProp,
  clearProp,
  clearRedirect,
  uploadImagesAndSaveProp,
  setName,
  fetchAllCharacters,
  fetchAllBackgroundCharacters,
})(AddProp);
