import React from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import {
  renderPageNumberField,
  renderReactSelect,
  renderSelectField,
  renderSingleReactSelect,
  renderTextAreaField,
  renderTextField,
  renderTimeField
} from "./../../shared/form-helpers";
import required, { maxDecimalValue } from "./../../shared/validation";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName,
  OnboardingStep5ClassName
} from "./ducks/reducer";
import { formatTime } from "../../shared/format-time";

function SceneForm(props) {
  const {
    handleSubmit,
    handleDelete,
    handleSplit,
    pristine,
    reset,
    submitting,
    dayNightEnum,
    interiorExteriorEnum,
    sceneSettings,
    errors,
    mode,
    reduxFormValues,
    submitFailed,
    invalid,
    pageStartValue,
    pageEndValue,
    scriptPagesPerDay,
    hasCommercialScript
  } = props;

  const thisScene = props.initialValues || {};
  const readOnly = thisScene.readOnly;

  const dayNightOptions = (dayNightEnum || []).map((item) => { return { value: item.value, label: item.name } });
  const interiorExteriorOptions = (interiorExteriorEnum || []).map((item) => { return { value: item.value, label: item.name } });
  const sceneSettingOptions = (sceneSettings || []).map((item) => { return { value: item.id, label: item.name } });

  function onPageLengthChanged(pageLength) {
    calculateShootDays(pageLength);
  }

  function calculateShootDays(pageLength) {
    if (pageLength && scriptPagesPerDay) {
      let shootDays = pageLength / scriptPagesPerDay;
      shootDays = Math.round((shootDays + Number.EPSILON) * 100) / 100;
      if (shootDays < 0) {
        shootDays = 0;
      }

      props.change("shootDays", shootDays);
    }
  }

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Scene</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Scene</h2>}
      <br />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="columns">
          <div className={`column ${OnboardingStep1ClassName}`}>
            <Field
              name="number"
              type="test"
              label="Number"
              component={renderTextField}
              validate={[required, maxDecimalValue]}
              required
              readOnly={readOnly}
            />
          </div>
          <div className={`column ${OnboardingStep2ClassName}`}>
            <Field
              name="sceneSettingId"
              label="Setting"
              component={renderSingleReactSelect}
              isMulti={false}
              options={sceneSettingOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Field
              name="dayNight"
              label="Day/Night"
              component={renderSingleReactSelect}
              isMulti={false}
              options={dayNightOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>
          </div>
          <div className="column">
            <Field
              name="interiorExterior"
              label="Interior/Exterior"
              component={renderSingleReactSelect}
              isMulti={false}
              options={interiorExteriorOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>
          </div>
        </div>

        <Field
          name="summary"
          label="Summary"
          component={renderTextAreaField}
          readOnly={readOnly}></Field>

        <div className="columns">
          {!hasCommercialScript &&
            <div className="column">
              <Field
                name="page"
                label="Start Page"
                component={renderTextField}
                readOnly={readOnly}
              />
            </div>}
          <div className={`column ${OnboardingStep5ClassName}`}>
            <Field
              name="scriptDay"
              type="number"
              label="Script Day"
              component={renderTextField}
              validate={[maxDecimalValue]}
              readOnly={readOnly}
            />
          </div>
        </div>
        <div className="columns">
          {!hasCommercialScript && <div className={`column ${OnboardingStep3ClassName}`}>
            <Field
              name="pageLength"
              type="number"
              label="Page Length"
              onChange={onPageLengthChanged}
              component={renderPageNumberField}
              validate={[required, maxDecimalValue]}
              required
              readOnly={readOnly}
            />
          </div>}
          <div className={`column ${OnboardingStep4ClassName}`}>
            <Field
              name="shootDays"
              type="number"
              label="Shoot Days"
              component={renderTextField}
              validate={[required, maxDecimalValue]}
              required
              step="any"
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Field
              name="estimatedShootingDuration"
              label="Shooting Duration"
              component={renderTimeField}
              canAddDay={false}
              readOnly={readOnly}
            />
          </div>
          <div className={`column`}>
            <label className="label">Duration from Shots</label>
            {formatTime(thisScene.totalShootingDurationFromShots)}
          </div>
        </div>


        {!readOnly && <div className="buttons mt-2 sticky-buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>
          {mode === "edit" && (
            <>
              <button
                type="button"
                className="button is-text"
                disabled={submitting || !props.initialValues}
                onClick={() => handleSplit(thisScene)}>
                Split Scene
              </button>
              <button
                type="button"
                className="button is-text"
                disabled={submitting || !props.initialValues}
                onClick={() => handleDelete(thisScene)}>
                Delete Scene
              </button>
            </>
          )}
        </div>}
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}
const form = "editSceneForm";

const EditSceneForm = reduxForm({
  form,
  enableReinitialize: true
})(SceneForm);

const selector = formValueSelector(form);

export default connect(state => {
  const pageStartValue = selector(state, 'pageStart');
  const pageEndValue = selector(state, 'pageEnd');

  return {
    pageStartValue,
    pageEndValue
  }
})(EditSceneForm);

