import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const ADD_SET_DRESSING_TO_SCENE = "ADD_SET_DRESSING_TO_SCENE";

export function fetchSetDressingsInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SET_DRESSINGS_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/setDressings`),
    });
  };
}

export function addSetDressingToScene(filmId, sceneId, setDressing, fromBreakdown) {
  return (dispatch) => {
    dispatch({
      type: ADD_SET_DRESSING_TO_SCENE,
      payload: getApiClient().post(`/film/${filmId}/scene/${sceneId}/setDressing/${setDressing.setDressingId}`),
      meta: { fromBreakdown }
    })
      .then((response) => {
        dispatch(showInfo(`Added set dressing to scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding set dressing to scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
