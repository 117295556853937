import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../../../shared/private-route";
import ListTakes from "./list-container";
import ViewTake from "./view";

const ShotRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/shots/:shotId/takes"
        component={ListTakes}
      />
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/shots/:shotId/takes/:takeId/view"
        component={ViewTake}
      />
    </>
  );
};

export default ShotRoutes;
