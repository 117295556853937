import { useEffect } from "react";
import { connect } from "react-redux";
import { uploadCrew, clearErrors } from "./ducks/actions";
import UploadCrew from "./upload";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Errors from "../../../shared/errors";
import { OnboardingStep2ClassName } from "./ducks/reducer";
import Onboarding from "../../../shared/onboarding/onboarding";

function UploadCrewContainer(props) {
  const filmId = props.match.params.filmId;
  const { uploadCrew, loading, errors, warnings, readOnly, isUploading, clearErrors, onboardingSteps } = props;

  useEffect(() => {
    clearErrors();
  }, []);

  const onImportCrew = (formData) => {
    uploadCrew(filmId, formData)
  }

  function renderCrew(filmId) {
    return (
      <ScrollIntoView
        className="blade-content wide"
        path="/films/:filmId/actors"
        loading={loading}>
        <h2 className="menu-label">Upload</h2>
        {errors && <Errors errors={errors} />}
        {warnings && <Errors errors={warnings} isWarning={true} />}
        <UploadCrew filmId={filmId}
          readOnly={readOnly}
          isUploading={isUploading}
          importActors={onImportCrew} />
        <a href="/downloads/Crew.csv" className={`${OnboardingStep2ClassName}`}>Example File</a>
        <Onboarding onboardingSteps={onboardingSteps} section="Crew Upload" />
      </ScrollIntoView>
    );
  }

  return renderCrew(filmId);
}

function mapStateToProps(state) {
  const uploadState = state.crewMembersUploadState;
  return {
    readOnly: uploadState.readOnly,
    loading: uploadState.loading,
    errors: uploadState.errors,
    warnings: uploadState.warnings,
    isUploading: uploadState.isUploading,
    onboardingSteps: uploadState.onboardingSteps
  };
}

export default connect(mapStateToProps, {
  uploadCrew,
  clearErrors
})(UploadCrewContainer);
