import getApiClient from "./../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "./../../../../notifications/ducks/actions";

export const ADD_MAKEUP_TO_SCENE = "ADD_MAKEUP_TO_SCENE";

export function fetchMakeupsInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_MAKEUPS_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/makeups`),
    });
  };
}

export function addMakeupToScene(filmId, sceneId, makeup, fromBreakdown) {
  return (dispatch) => {
    dispatch({
      type: ADD_MAKEUP_TO_SCENE,
      payload: getApiClient().post(`/film/${filmId}/scene/${sceneId}/makeup/${makeup.makeupId}`),
      meta: { fromBreakdown }
    })
      .then((response) => {
        dispatch(showInfo(`Added makeup to scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding makeup to scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
