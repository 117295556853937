import { useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

import DaysOfWeek from "../shooting-days/calendar/days-of-week";
import Radio from "../../shared/filmanize-radio";
import confirm from "../../shared/modal/confirm";
import confirmPromise from "../../shared/modal/confirm-promise";
import { Prompt } from "react-router-dom";
import { OnboardingStep1ClassName, OnboardingStep2ClassName, OnboardingStep3ClassName, OnboardingStep4ClassName } from "./ducks/reducer";
import './potential-dates.scss';
import Onboarding from "../../shared/onboarding/onboarding";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

const SelectionCalendar = ({ daysOfWeek,
  days,
  readOnly,
  updateEvent,
  addDays,
  deleteDay,
  deleteDays,
  changeDayOfWeek
}) => {

  const cal = useRef<any>();
  const [mode, setMode] = useState("add");
  const addMode = "add";
  const deleteMode = "delete";
  const forceUpdate = useForceUpdate();

  const workingDays = daysOfWeek.filter(d => d.selected).map(d => +d.dayOfWeek);

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const dayRender = ({ date, el }) => {
    if (date < today) { //|| !daysOfWeek[date.getDay()].selected
      el.style.backgroundColor = '#040f21'
    }
  };

  const handleEventChange = (changeInfo) => {
    updateEvent(changeInfo.event.toPlainObject());
  };

  const dateSelect = (dateInfo) => {
    // if all day event, then end date is +1
    const endDate = dateInfo.end.setDate(dateInfo.end.getDate() - 1)
    if (mode === addMode) {
      addDays(dateInfo.start, endDate);
      cal.current.getApi().unselect();
    } else if (mode === deleteMode) {
      const momentStartDay = moment(dateInfo.start);
      const momentEndtDay = moment(endDate);
      // check to see if there are any dates in the range

      confirmPromise(() => deleteDays(dateInfo.start, endDate), null, `Are you sure you want to delete days between ${momentStartDay.format("Do MMMM YYYY")} and ${momentEndtDay.format("Do MMMM YYYY")}?`)
        .then((value) => {
          if (value) {
            deleteDays(dateInfo.start, endDate)
          }

          cal.current.getApi().unselect();
        });
    }
  };

  const selectAllow = (selectInfo) => {
    if (readOnly) {
      return false;
    }

    if (mode === addMode && selectInfo.start < today) {
      return false;
    } else if (selectInfo.start < today) {
      return days.find((d) => d.start.toISOString() === selectInfo.start.toISOString());
    } else {
      return workingDays.includes(selectInfo.start.getDay());
    }
  };

  const eventAllow = (dropInfo, draggedEvent) => {

    if (readOnly) {
      return false;
    }

    if (dropInfo.start < today) {
      return false;
    }

    // if dropInfo.start is the same as a day that is not editable, return false
    // and not the day we are dragging
    //dropInfo.start.toISOString() == days[1].start.toISOString() 
    var dayOnThisDay = days.find((d) => d.start.toISOString() === dropInfo.start.toISOString() && d.id != draggedEvent.id);

    if (dayOnThisDay) {
      if (!dayOnThisDay.editable) {
        return false;
      }
    }

    const dayIndex = days.findIndex((d) => d.id === draggedEvent.id);
    if (dayIndex === 0) {
      return true;
    }

    const previosuDay = days[dayIndex - 1];
    return dropInfo.start >= previosuDay.start;
  };

  const eventMouseClick = (info) => {

    const { event, jsEvent } = info;
    const day = days.find((d) => d.id === event.id);
    if (day.isPotentialDate) {
      return;
    }
    //const { clientX, clientY } = jsEvent;
    const momentDay = moment(day.start);
    confirm(() => deleteDay(event.id), null, `Are you sure you want to delete day ${momentDay.format("Do MMMM YYYY")}?`)

    //deleteDay(event.id);

  };

  const onSetMode = (mode) => {
    setMode(mode);
  }

  const onChangeDayOfWeek = (dayOfWeek, checked) => {
    changeDayOfWeek(dayOfWeek, checked);
    forceUpdate();
  };

  return (
    <>
      <div className={`calendar-working-days ${OnboardingStep1ClassName}`}>
        <p className="mr-1">Available Days:</p>
        <DaysOfWeek daysOfWeek={daysOfWeek} readOnly={readOnly} onChange={onChangeDayOfWeek} />
      </div>
      <div className={`mode-selection-container ${OnboardingStep2ClassName}`}>
        <p className="mr-1 mode-selection">Selection:</p>
        <div style={{ verticalAlign: "center" }}>
          <Radio
            value={addMode}
            checked={mode === addMode}
            text={"Add"}
            onChange={onSetMode}
          />
        </div>
        <Radio
          value={deleteMode}
          checked={mode === deleteMode}
          text={"Delete"}
          onChange={onSetMode}
        />
      </div>
      <div className={OnboardingStep3ClassName}>
        <FullCalendar
          ref={cal}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          eventColor="#947eed"
          events={days}
          editable={true}
          selectable={true}
          eventOverlap={true}
          firstDay={1}
          selectMirror={true}
          eventConstraint={{
            daysOfWeek: daysOfWeek
              .filter((d) => d.selected)
              .map((d) => d.dayOfWeek)
          }}
          contentHeight={"auto"}
          eventChange={handleEventChange}
          select={dateSelect}
          eventAllow={eventAllow}
          selectAllow={selectAllow}
          eventClick={eventMouseClick}
          eventStartEditable={!readOnly}
          showNonCurrentDates={true}
          dayCellDidMount={dayRender}
          businessHours={{
            daysOfWeek: workingDays,
            startTime: '08:00',
            endTime: '18:00'
          }}
          dayHeaderClassNames="calendar-header"
          timeZone="UTC"
        />
      </div>
    </>
  );
};


export default SelectionCalendar;
