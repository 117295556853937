import { Link } from "react-router-dom";
import './day-out-of-days.scss';
import CanAccessLink from "../../../shared/can-access-link";

const DayOutOfDaysCharactersTable = (props) => {
  const {
    characters,
    days,
    hasDates,
    filmId,
    showNonShootingDays,
    canAccessCharacters,
    scrollWithOffset,
    sectionLink
  } = props;

  const filteredDays = showNonShootingDays ? days : days.filter((d: any) => !d.isOffDay);

  return (
    <div className="day-out-days-table-container">
      <table className="table day-out-days-table">
        <thead className="day-out-days-table-header-row">
          <tr >
            <th className="day-out-days-table-actor-column-header">Name</th>
            {(filteredDays || []).map((day: any, index: number) => (<th className="has-text-centered" key={index} >Day {day.number}{!day.number && <span>Off Day</span>}</th>))}
            {hasDates && <th className="has-text-centered" colSpan={2}>Total</th>}
            {!hasDates && <>
              <th className="has-text-centered">Total Work</th>
              <th className="has-text-centered">Total Hold</th>
            </>}
          </tr>
          {hasDates &&
            <tr>
              <th className="day-out-days-table-actor-column-header"></th>
              {(filteredDays || []).map((day: any, index: number) => (<th className="has-text-centered" key={index}>{new Date(day.date).toLocaleDateString()}</th>))}
              <th className="has-text-centered">Work</th>
              <th className="has-text-centered">Hold</th>
            </tr>
          }
        </thead>
        <tbody>
          {(characters || []).map((character, index) => {
            return (
              <tr key={index}>
                <td className="day-out-days-table-actor-column">
                  <CanAccessLink to={`/films/${filmId}/${sectionLink}/${character.characterId}/edit`} canAccess={canAccessCharacters}>{character.characterName}</CanAccessLink>
                </td>
                {(character.days.filter((d: any) => showNonShootingDays || d.number) || []).map((day: any, index: number) => (<td className="has-text-centered" key={index}>
                  {day.start ? "S" : ""}{day.work ? "W" : ""}{day.hold ? "H" : ""}{day.finish ? "F" : ""}
                </td>))}
                <td className="has-text-centered">{character.totalWorkDays}</td>
                <td className="has-text-centered">{character.totalHoldDays}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}


export default DayOutOfDaysCharactersTable;
