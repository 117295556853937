import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

export function fetchShootingDayEquipment(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_EQUIPMENT",
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Equipment`
      )
    });
  };
}

export function updateShootingDayEquipment(filmId, shootingDayId, equipmentCategories) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SHOOTING_DAY_EQUIPMENT",
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Equipment`,
        equipmentCategories
      ),
      meta: { equipmentCategories }
    })
      .then((response) => {
        dispatch(showInfo(`Updated Shooting Equipment`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day Equipment`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      })
      .then(() => {
        dispatch({
          type: "RESET_SHOOTING_DAY_EQUIPMENT"
        });
      });
  };
}
