import './faq-help.scss';
import CkEditorContent from "../../../shared/controls/ckeditor-content";
import QuestionMarkIcon from "../../../films/icons/question-mark";

function Question(props) {  
  const { 
    faq,    
    reference
     } = props;

  return (
    <section ref={reference} className="faq-item">      
        <div className='faq-icon-container'>
          <div className="faq-icon"><QuestionMarkIcon/></div>
        </div>        
        <div className='faq-question-container'>
          <div className="change-log-headline">
              {faq.question}
            </div>
            <div className="faq-answer">
              <CkEditorContent className="on-white" content={faq.answer} />
            </div>                
          </div>      
            
    </section>
  );
}

export default Question;
