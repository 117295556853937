import getApiClient from "./../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications,
  showWarning
} from "../../../../notifications/ducks/actions";

const url = "/film";

export const FETCH_PROP_OVERVIEW = "FETCH_PROP_OVERVIEW";
export const DOWNLOAD_PROPS_OVERVIEW = "DOWNLOAD_PROPS_OVERVIEW";
export const CLEAR_DOWNLOAD_PROPS_OVERVIEW = "CLEAR_DOWNLOAD_PROPS_OVERVIEW";
export const SAVE_ALIAS_PROP = "SAVE_ALIAS_PROP";
export const ALIAS_PROP = "ALIAS_PROP";
export const SAVE_REMOVE_ALIAS_PROP = "SAVE_REMOVE_ALIAS_PROP";
export const UNALIAS_PROP = "UNALIAS_PROP";

export function fetchPropOverview(id) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PROP_OVERVIEW,
      payload: getApiClient().get(`${url}/${id}/Prop/Overview`)
    });
  };
}


export function downloadPropsOverview(id) {
  return (dispatch) => {
    dispatch({
      type: DOWNLOAD_PROPS_OVERVIEW,
      payload: getApiClient().post(`${url}/${id}/prop/download`),
    });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DOWNLOAD_PROPS_OVERVIEW,
      meta: {},
    });
  };
}


export function aliasProp(filmId, aliasProp, mainProp) {
  return (dispatch) => {
    dispatch({
      type: SAVE_REMOVE_ALIAS_PROP,
      payload: getApiClient().put(
        `${url}/${filmId}/Prop/${mainProp.id}/Alias`,
        { aliasId: aliasProp.id }
      )
    })
      .then((response) => {
        dispatch({
          type: ALIAS_PROP,
          meta: { aliasProp, mainProp }
        });
        dispatch(
          showInfo(`Aliased ${aliasProp.name} with ${mainProp.name}`)
        );
      })
      .catch((response) => {
        debugger;
        dispatch(
          showError(
            `Error aliasing ${aliasProp.name} with ${mainProp.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function unaliasProp(filmId, aliasProp, mainProp) {
  return (dispatch) => {
    dispatch({
      type: SAVE_REMOVE_ALIAS_PROP,
      payload: getApiClient().put(
        `${url}/${filmId}/prop/${mainProp.id}/RemoveAlias`,
        { aliasId: aliasProp.id }
      )
    })
      .then((response) => {
        dispatch({
          type: UNALIAS_PROP,
          meta: { aliasProp, mainProp }
        });
        dispatch(
          showInfo(
            `Removed alias ${aliasProp.name} from ${mainProp.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error removing alias ${aliasProp.name} from ${mainProp.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}