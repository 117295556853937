import "./errors.scss";

const Errors = (props) => {  
  return (
    <>
      {props.errors && Object.keys(props.errors).length > 0 && (
        <div className="content">
          <ul className={`notification ${props.isWarning ? "is-warning" : "is-danger"}`}>
            {Object.keys(props.errors).map((field, i) => {
              return props.errors[field].map((error, j) => {
                if (!props.messageToIgnore || error != props.messageToIgnore) {
                  return <li key={i + "" + j}>{error}</li>;
                }
              });
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default Errors;
