import React, { useState } from "react";
import Tick from './tick';

const Price = ({included} : any) => {    
    return (
        <div className="price-included">
          <Tick/> <span className="price-included-text">{included}</span>
        </div>
    )
}

export default Price;