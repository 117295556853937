import mapPictures from "../../../../shared/image-mapper";

const defaultState = {
  shots: [],
  shot: {},
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOTS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_SHOTS_FULFILLED": {
      return {
        ...state,
        shots: action.payload.data.shots,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_SHOT_PENDING": {
      return {
        ...state,
        shot: null,
        loadingShot: true
      };
    }

    case "FETCH_SINGLE_SHOT_FULFILLED": {
      let shot = action.payload.data;

      mapPictures(shot);

      return {
        ...state,
        shot: action.payload.data,
        loadingShot: false,
        errors: null
      };
    }

    case "UPDATE_SINGLE_SHOT_PENDING": {
      return {
        ...state,
        shot: action.payload,
        loadingShot: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_SHOT_FULFILLED": {
      const shot = { ...action.meta.shot, ...action.payload.data.shot };
      const shots: any = state.shots.map((c: any, index: number) => {
        if (c.id === shot.id) {
          return { ...c, ...shot };
        } else {
          return c;
        }
      });

      sortShots(shots);

      return {
        ...state,
        shot,
        shots,
        loadingShot: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_SHOT_REJECTED": {
      return {
        ...state,
        shot: action.meta.shot,
        errors: action.payload.response.data.errors,
        loadingShot: false
      };
    }

    case "ADD_SINGLE_SHOT_PENDING": {
      return {
        ...state,
        shot: action.payload,
        errors: null,
        loadingShot: true
      };
    }

    case "INITIALIZE_ADD_SHOT_FORM": {
      let nextNumber = 1;
      if (state.shots && state.shots.length > 0) {
        const maxNumber = Math.max.apply(
          null,
          state.shots.map((shot: any) => {
            return shot.number;
          })
        );
        nextNumber = maxNumber + 1;
      }

      return {
        ...state,
        shot: { number: nextNumber },
        errors: null,
        loadingShot: false
      };
    }

    case "ADD_SINGLE_SHOT_FULFILLED": {
      const shot: any = action.payload.data.shot;

      let shots = [shot, ...state.shots];

      sortShots(shots);

      return {
        ...state,
        shot,
        shots,
        loadingShot: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_SHOT_REJECTED": {
      return {
        ...state,
        shot: action.meta.shot,
        errors: action.payload.response.data.errors,
        loadingShot: false
      };
    }

    case "DELETE_SINGLE_SHOT_PENDING": {
      return {
        ...state,
        shot: action.payload,
        loadingShot: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_SHOT_FULFILLED": {
      const shot = action.meta.shot;
      const updatedShots = action.payload.data.shots;
      const newShots = Array.from(state.shots);
      const shots: any = newShots.filter((c: any, index: number) => {
        return c.id !== shot.id;
      });

      shots.forEach(shot => {
        const updatedShot = updatedShots.find(s => s.id === shot.id);
        if (updatedShot) {
          shot.number = updatedShot.number;
        }
      });



      return {
        ...state,
        shot: null,
        shots,
        loadingShot: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_SHOT_REJECTED": {
      return {
        ...state,
        shot: action.meta.shot,
        errors: action.payload.response.data.errors,
        loadingShot: false
      };
    }

    case "FETCH_FRAMES_PER_SECOND_FULFILLED": {
      const shot = { ...state.shot, frameRate: action.payload.data.frameRate };
      return {
        ...state,
        shot: shot
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        shot: null,
        redirect: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingShot: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingShot: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingShot: false
      };
    }

    default:
      return state;
  }
};

function sortShots(shots) {
  shots.sort((a, b) => {
    return a.number - b.number;
  });
}

export default reducer;
