import {
  clearNotifications,
  showError,
  showInfo
} from "./../../../notifications/ducks/actions";
import getApiClient from "./../../../shared/api";
import { connection } from "../../../middleware/filmTaskSignalRMiddleware";
import { HubConnectionState } from "@microsoft/signalr";

const google: any = null;

export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASK_BOARD = "FETCH_TASK_BOARD";
export const MOVE_TASK = "MOVE_TASK";
export const ADD_TASK = "ADD_TASK";
export const EDIT_TASK = "EDIT_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const TASK_TEXT_CHANGED = "TASK_TEXT_CHANGED";
export const TASK_TEXT_FINISHED_EDITING = "TASK_TEXT_FINISHED_EDITING";
export const TASK_SHOW_MENU = "TASK_SHOW_MENU";
export const ASSIGN_TASK = "ASSIGN_TASK";
export const FETCH_ASSIGNED_COLLABORATORS = "FETCH_ASSIGNED_COLLABORATORS";

export function fetchTasks(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_TASKS,
      payload: getApiClient().get(`/film/${filmId}/task`)
    });
  };
}

export function fetchAssignedCollaborators(filmId) {  
  return (dispatch) => {
    dispatch({
      type: FETCH_ASSIGNED_COLLABORATORS,
      payload: getApiClient().get(`/film/${filmId}/task/collaborators`),
    });
  };
}

export function fetchTaskBoard(filmId) {
  if (connection.state !== HubConnectionState.Connected) {
    connection.start().then(() => connection.invoke('SubscribeToFilm', filmId));
  }

  return (dispatch) => {
    dispatch({
      type: FETCH_TASK_BOARD,
      payload: getApiClient().get(`/film/${filmId}/task/board`)
    });
  };
}

export function moveTask(filmId, destination, source, draggableId) {
  return (dispatch) => {
    dispatch({
      type: MOVE_TASK,
      payload: getApiClient().put(`/film/${filmId}/task/${draggableId}/status`, { taskStatusId: destination.droppableId, order: destination.index + 1 }),
      meta: { destination, source, draggableId }
    });
  };
}

export function addTask(columnId) {
  return (dispatch) => {
    dispatch({
      type: ADD_TASK,
      meta: { columnId }
    });
  };
}

export function editTask(task) {
  return (dispatch) => {
    dispatch({
      type: EDIT_TASK,
      meta: { task }
    });
  };
}

export function showMenuOnTask(taskId, show) {
  return (dispatch) => {
    dispatch({
      type: TASK_SHOW_MENU,
      meta: { taskId, show }
    });
  };
}

export function deleteTask(filmId, columnId, task, originalIndex) {
  return (dispatch) => {
    dispatch({
      type: DELETE_TASK,
      payload: getApiClient().delete(`/film/${filmId}/task/${task.id}`),
      meta: { columnId, task, originalIndex }
    });
  };
}

export function taskTextChanged(taskId, title) {
  return (dispatch) => {
    dispatch({
      type: TASK_TEXT_CHANGED,
      meta: { taskId, title }
    });
  };
}

export function taskTextFinishedEditing(filmId, columnId, task) {  
  return (dispatch) => {
    dispatch({
      type: TASK_TEXT_FINISHED_EDITING,
      payload: task.new ? getApiClient().post(`/film/${filmId}/task`, {...task, taskStatusId: columnId }) : getApiClient().put(`/film/${filmId}/task/${task.id}`, task),
      meta: { taskId: task.id }
    });
  };
}

export function assignTask(filmId, taskId, collaboratorId, isOwner) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_TASK,
      payload: getApiClient().put(`/film/${filmId}/task/${taskId}/assign`, { collaboratorId, isOwner }),
      meta: { collaboratorId, taskId }
    });
  };
}