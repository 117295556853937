import {
  clearNotifications,
  showError,
  showInfo
} from "../../../../notifications/ducks/actions";
import getApiClient from "../../../../shared/api";

export function fetchAllScenes(filmId) {
  let filters: string[] = [];
  
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENES_FOR_CHARACTER",
      payload: getApiClient().get(`/film/${filmId}/scene`)
    });
  };
}

export function fetchScenesAssignedToCharacter(filmId, characterId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_ASSIGNED_TO_CHARACTER",
      payload: getApiClient().get(
        `/film/${filmId}/Character/${characterId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedCharacter(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SET_SCENE_ASSIGNED_TO_CHARACTER",
      meta: { sceneId }      
    });
  };
}

export function assignScenesToCharacter(filmId, characterId, sceneIds) {
  return (dispatch) => {
    dispatch({
      type: "ASSIGN_SCENES_TO_CHARACTER",
      payload: getApiClient().put(
        `/film/${filmId}/character/${characterId}/AssignToScenes`,
        { sceneIds }
      ),
      meta: { characterId }
    })
      .then((response) => {
        dispatch(showInfo(`Assigned Character to Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error assigning Character to Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchAllCharacters(filmId, sceneId) {
  return (dispatch) => {
    if (sceneId) {
      dispatch({
        type: "FETCH_ALL_CHARACTERS_FOR_CHARACTER",        
        payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/characters`)
      });
    } else {
      dispatch({
        type: "FETCH_ALL_CHARACTERS_FOR_CHARACTER",
        payload: getApiClient().get(`/film/${filmId}/character`)
      });
    }
  };
}

export function fetchAllSceneSettings(filmId) {
  let filters: string[] = [];

  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENE_SETTINGS_FOR_CHARACTER",
      payload: getApiClient().get(
        `/film/${filmId}/scenesetting/withcharacters?${filters.join("&")}`
      )
    });
  };
}

export function fetchScriptDays(filmId) {
  let filters: string[] = [];
  
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCRIPT_DAYS_FOR_CHARACTER",
      payload: getApiClient().get(
        `/film/${filmId}/scene/scriptDays/search?${filters.join("&")}`
      )
    });
  };
}

export function searchScenes(
  filmId,
  characterIds,
  sceneSettingIds,
  sceneNumberFrom,
  sceneNumberTo,
  selectSearchedScenes,
  scriptDays
) {
  return (dispatch) => {
    let filters: string[] = [];

    if (characterIds?.length) {
      filters.push(`characterIds=${characterIds.join(",")}`);      
    }

    if (sceneSettingIds?.length) {
      filters.push(`sceneSettingIds=${sceneSettingIds.join(",")}`);
    }

    if (sceneNumberFrom) {
      filters.push(`sceneFrom=${sceneNumberFrom}`);
    }

    if (sceneNumberTo) {
      filters.push(`sceneTo=${sceneNumberTo}`);
    }

    if (scriptDays?.length) {
      filters.push(`scriptDays=${scriptDays.join(",")}`);      
    }

    dispatch({
      type: "SEARCH_SCENES_FOR_CHARACTER",
      payload: getApiClient().get(
        `/film/${filmId}/scene/search?${filters.join("&")}`
      ),
      meta: { selectSearchedScenes }
    });
  };
}

export function findInScript(
  filmId,
  characterName
) {
  return (dispatch) => {
    dispatch({
      type: "SEARCH_SCRIPT_FOR_CHARACTER",
      payload: getApiClient().get(
        `/film/${filmId}/script/search/${characterName}`
      ),
      meta: { selectSearchedScenes: true }
    });
  };
}

export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_SCENES_FOR_CHARACTER"
    });
  };
}
