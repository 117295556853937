import { FETCH_FAQS } from './actions';

interface ActionState {
  faqs: any[];
  loading: boolean;
  continuationToken: string | null; 
}

const defaultState: ActionState = {
  faqs: [],
  loading: true,  
  continuationToken: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_FAQS + "_PENDING": {
      return {
        ...state,
        faqs: [],
        loading: true,
        errors: null
      };
    }
    
    case FETCH_FAQS + "_FULFILLED": {
      let newFaqs: any[] = [];
      if (action.meta.append) {
        newFaqs = [...state.faqs, ...action.payload.data.faqs];
      } else {
        newFaqs = action.payload.data.faqs;
      }
      return {
        ...state,
        faqs: newFaqs,
        continuationToken: action.payload.data.continuationToken,        
        loading: false,
        errors: null
      };
    }
    
    default:
      return state;
  }
};

export default reducer;
