import { combineReducers } from "redux";
import confirmEmailReducer from "./confirm-email/ducks/reducer";
import forgotPasswordReducer from "./forgot-password/ducks/reducer";
import changePasswordReducer from "./change-password/ducks/reducer";
import resetPasswordReducer from "./reset-password/ducks/reducer";
import loginReducer from "./login/ducks/reducer";
import registerReducer from "./register/ducks/reducer";
import updateUserReducer from "./update-user/ducks/reducer";
import accountReducer from "./account/ducks/reducer";
import paymentReducer from "./payment/ducks/reducer";

export default combineReducers({
  confirmEmailState: confirmEmailReducer,
  forgotPasswordState: forgotPasswordReducer,
  changePasswordState: changePasswordReducer,
  resetPasswordState: resetPasswordReducer,
  loginState: loginReducer,
  registerState: registerReducer,
  updateUserState: updateUserReducer,
  accountState: accountReducer,
  paymentState: paymentReducer
});
