import React from "react";
import { connect } from "react-redux";
import Form from "./crew-event-form";
import { updateShootingDayScheduleEvent } from "./ducks/actions";

function EditCrewEvent(props) {
  const { filmId, shootingdayId } = props;
  const {
    fetchScheduleEventSettings,
    updateShootingDayScheduleEvent,
    errors,
  } = props;

  function onUpdateScheduleEvent(scheduleEvent) {
    scheduleEvent.type = scheduleEvent.isMealEvent ? 2 : 3;
    sanitiseDataTypes(scheduleEvent);
    updateShootingDayScheduleEvent(filmId, shootingdayId, scheduleEvent);
  }
  if (props.currentEvent.type == 2) {
    props.currentEvent.isMealEvent = true;
  }
  function sanitiseDataTypes(ScheduleEvent) {}

  return (
    <div className="blade-content">
      <Form
        mode="edit"
        errors={errors}
        ScheduleEventId={null}
        initialValues={props.currentEvent}
        loading={false}
        onSubmit={onUpdateScheduleEvent}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    errors: state.shootingdaysState.scheduleState.errors,
  };
}

export default connect(mapStateToProps, {
  updateShootingDayScheduleEvent,
})(EditCrewEvent);
