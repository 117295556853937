import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../shared/private-route";
import AddLocation from "./add";
import EditLocation from "./edit";
import ListContainer from "./list-container";
import Overview from "./overview/container";

const LocationRoutes = () => {
  return (
    <>
      <PrivateRoute path="/films/:filmId/locations" component={ListContainer} />
      <PrivateRoute path="/films/:filmId/locations/overview" exact component={Overview} />
      <PrivateRoute
        exact
        path="/films/:filmId/locations/add"
        component={AddLocation}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/locations/:locationId"
        component={EditLocation}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/locations/:locationId/edit"
        component={EditLocation}
      />
    </>
  );
};

export default LocationRoutes;
