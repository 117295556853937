import getApiClient, { getAnnonymousApiClient } from "./../../shared/api";

export const FETCH_UNIONS = "FETCH_UNIONS";
export const LOG_ERROR = "LOG_ERROR";

export function fetchApiVersion() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_API_VERSION",
      payload: getAnnonymousApiClient().get("/Version")
    });
  };
}

export function fetchEnums() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_ENUMS",
      payload: getApiClient().get("/Enums/All")
    });
  };
}

export function fetchLookups() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_LOOKUPS",
      payload: getApiClient().get("/Lookup")
    });
  };
}

export function getUnions() {
  return (dispatch) => {
    dispatch({
      type: FETCH_UNIONS,
      payload: getApiClient().get("lookup/unions")
    });
  };
}

export function logout(history) {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT"
    });
    history.push('/login');
  };
}

export function dismissMessage(messageId) {
  return (dispatch) => {
    dispatch({
      type: "DISMISS_USER_MESSAGE",
      payload: getApiClient().delete(`/message/dismiss/${messageId}`),
      meta: { userMessageId: messageId }
    });
  };
}

export function dismissAllMessages(filmId) {
  return (dispatch) => {
    dispatch({
      type: "DISMISS_ALL_USER_MESSAGES",
      payload: getApiClient().delete(`/message/dismissAll/${filmId ? filmId : ""}`)
    });
  };
}

export function setActiveOnboarding(onboarding) {
  return (dispatch) => {
    dispatch({
      type: "SET_ACTIVE_ONBOARDING",
      payload: onboarding
    });
  };
}

export function setActiveOnboardingStep(step) {
  return (dispatch) => {
    dispatch({
      type: "SET_ACTIVE_ONBOARDING_STEP",
      payload: step
    });
  };
}

export function updateOnboardingComplete(onboarding, updateApi = true) {
  if (updateApi) {
    getApiClient().post("/user/adduseronboarding", {
      "UserOnboarding": onboarding
    });
  }

  return (dispatch) => {
    dispatch({
      type: "UPDATE_ONBOARDING_COMPLETE",
      payload: onboarding
    });
  };
}

export function setOnboardingComplete(onboarding) {
  return (dispatch) => {
    dispatch({
      type: "SET_ONBOARDING_COMPLETE",
      payload: onboarding
    });
  };
}

export function fetchUserOnboarding() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER_ONBOARDING",
      payload: getApiClient().get(`/user/onboarding`)
    });
  };
}

export function fetchUser() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER_ONBOARDING",
      payload: getApiClient().get(`/user/onboarding`)
    });
  };
}


export function setActiveSection(activeSection) {
  return (dispatch) => {
    dispatch({
      type: "SET_ACTIVE_SECTION",
      payload: activeSection
    });
  };
}

export function logError(location, errorMessage, stack, info) {
  return (dispatch) => {
    dispatch({
      type: LOG_ERROR,
      payload: getApiClient().post("error/log", {
        location,
        errorMessage,
        stack,
        info
      })
    });
  };
}
