import OnboardingType from "../../../../shared/onboarding/onboarding-type";

interface CharacterOverviewState {
  overview: any;
  loading: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'locations-overview-onboarding-step-1';
export const OnboardingStep2ClassName = 'locations-overview-onboarding-step-2';
export const OnboardingStep3ClassName = 'locations-overview-onboarding-step-3';

const defaultState: CharacterOverviewState = {
  overview: {},
  loading: true,
  readOnly: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Distance or Duration',
      content: `Choose whether to show distance or durtation in the matrix below.`
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'From and To',
      content: `Choose the From and To to highlight the selection in the matrix.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Directions',
      content: `Click the cell to open directions (in another tab) from the left location to the top location.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_LOCATION_OVERVIEW_PENDING": {
      return {
        ...state,
        loading: true,
        overview: null,
        errors: null
      };
    }

    case "FETCH_LOCATION_OVERVIEW_FULFILLED": {
      return {
        ...state,
        loading: false,
        overview: action.payload.data,
        errors: null
      };
    }

    case "CLEAR_LOCATION_OVERVIEW": {
      return {
        ...state,
        loading: false,
        overview: null,
        errors: null
      };
    }

    // case "FETCH_LOCATION_OVERVIEW_DISTANCES_FULFILLED" : {
    //   debugger;
    //   return {
    //     ...state,
    //     loading: false,
    //     distances: action.payload.data,
    //     errors: null
    //   };
    // }

    // case "FETCH_LOCATION_OVERVIEW_DISTANCES_REJECTED" : {
    //   return {
    //     ...state
    //   };
    // }

    default:
      return state;
  }
};

export default reducer;
