import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function VisualEffectMenu(props) {
  const { filmId, visualEffectId } = props.match.params;
  const { visualEffect } = props;
  const invalidIds = ["add", "overview"];

  if (invalidIds.indexOf(visualEffectId) > -1) {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Visual Effect</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/visual-effects/${visualEffectId}/edit`}
            activeClassName="is-active">
            Edit Visual Effect
          </NavLink>
        </li>
        {visualEffect?.canAccessScenes &&
          <li>
            <NavLink
              to={`/films/${filmId}/visual-effects/${visualEffectId}/scenes/`}
              activeClassName="is-active">
              Scenes
            </NavLink>
          </li>
        }
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const { visualEffectsState } = state;
  return {
    visualEffect: visualEffectsState.visualEffect
  };
}

export default connect(mapStateToProps, {
})(VisualEffectMenu);

