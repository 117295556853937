import {
  clearNotifications,
  showError,
  showInfo
} from "./../../../../notifications/ducks/actions";
import getApiClient from "./../../../../shared/api";

export const FETCH_SCENES_ASSIGNED_TO_EQUIPMENT = "FETCH_SCENES_ASSIGNED_TO_EQUIPMENT";
export const FETCH_ALL_SCENES_FOR_EQUIPMENT = "FETCH_ALL_SCENES_FOR_EQUIPMENT";
export const FETCH_ALL_SCENE_SETTINGS_FOR_EQUIPMENT = "FETCH_ALL_SCENE_SETTINGS_FOR_EQUIPMENT";
export const ASSIGN_SCENES_TO_EQUIPMENT = "ASSIGN_SCENES_TO_EQUIPMENT";
export const SET_SCENE_ASSIGNED_TO_EQUIPMENT = "SET_SCENE_ASSIGNED_TO_EQUIPMENT";
export const CLEAR_EQUIPMENT_SCENES_REDIRECT = "CLEAR_EQUIPMENT_SCENES_REDIRECT";
export const SEARCH_SCENES_FOR_EQUIPMENT = "SEARCH_SCENES_FOR_EQUIPMENT";

export function fetchScenesAssignedToEquipment(filmId, categoryId, equipmentId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SCENES_ASSIGNED_TO_EQUIPMENT,
      payload: getApiClient().get(
        `/film/${filmId}/EquipmentCategory/${categoryId}/Equipment/${equipmentId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedToEquipment(sceneId) {
  return (dispatch) => {
    dispatch({
      type: SET_SCENE_ASSIGNED_TO_EQUIPMENT,
      meta: { sceneId }
    });
  };
}

export function assignScenesToEquipment(filmId, categoryId, equipmentId, sceneIds) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_SCENES_TO_EQUIPMENT,
      payload: getApiClient().put(
        `/film/${filmId}/EquipmentCategory/${categoryId}/equipment/${equipmentId}/AssignToScenes`,
        { sceneIds }
      ),
      meta: { equipmentId }
    })
      .then((response) => {
        dispatch(showInfo(`Assigned Equipment to Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error assigning Equipment to Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchAllSceneSettings(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_SCENE_SETTINGS_FOR_EQUIPMENT,
      payload: getApiClient().get(`/film/${filmId}/scenesetting`)
    });
  };
}

export function fetchAllScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_SCENES_FOR_EQUIPMENT,
      payload: getApiClient().get(`/film/${filmId}/scene`)
    });
  };
}

export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_SCENES_FOR_SET_DRESSING"
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EQUIPMENT_SCENES_REDIRECT
    });
  };
}
