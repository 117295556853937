import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "../../../shared/errors";
import {
  renderCheckboxGroup,
  renderPageNumberField,
  renderSelectField,
  renderTimeField
} from "../../../shared/form-helpers";
import required, { maxDecimalValue } from "../../../shared/validation";

function SceneEventForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    scenes,
    onSceneSelected
  } = props;
  const thisSceneEvent = props.initialValues || {};
  const [selectedSceneId, setSelectedSceneId] = useState(
    thisSceneEvent.sceneId
  );

  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="time"
          label="Time"
          component={renderTimeField}
          canAddDay={false}
          type="time"
          step="300"
          validate={[required]}
          required
        />

        <Field
          name="sceneId"
          label="Scene"
          component={renderSelectField}
          value={thisSceneEvent.sceneId}
          onChange={(e) => {
            setSelectedSceneId(e.target.value);
            onSceneSelected(e.target.value);
          }}
          validate={[required]}
          required>
          <option value="" />
          {(scenes || []).map((item, i) => {
            return (
              <option value={item.id} key={i}>
                {item.number}-{item.settingName}
              </option>
            );
          })}
        </Field>

        {selectedSceneId && props.selectedSceneDetails && (
          <>
            <Field
              label="Shots"
              name="shotIds"
              component={renderCheckboxGroup}
              options={props.selectedSceneDetails.shots}
              value={thisSceneEvent.shotIds}
              valueProperty="shotId"
              validate={[required]}
              required
            />

            <div className="field-body">
              <Field
                name="pageStart"
                label="Page Start"
                component={renderPageNumberField}
                type="number"
                validate={[required, maxDecimalValue]}
                required
              />
              <Field
                name="pageEnd"
                label="Page End"
                component={renderPageNumberField}
                type="number"
                validate={[required, maxDecimalValue]}
                required
              />
            </div>
          </>
        )}

        <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save Scene Event
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisSceneEvent)}>
              Delete
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default reduxForm({
  form: "editSceneEventForm",
  enableReinitialize: true
})(SceneEventForm);
