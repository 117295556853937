import mapPictures from "../../../../shared/image-mapper";
import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { sortByName } from "./../../../../shared/utility";
import { ASSIGN_SCENES_TO_EQUIPMENT, CLEAR_EQUIPMENT_SCENES_REDIRECT, FETCH_ALL_SCENES_FOR_EQUIPMENT, FETCH_ALL_SCENE_SETTINGS_FOR_EQUIPMENT, FETCH_SCENES_ASSIGNED_TO_EQUIPMENT, SEARCH_SCENES_FOR_EQUIPMENT, SET_SCENE_ASSIGNED_TO_EQUIPMENT } from "./actions";

interface ActionState {
  setDressings: any[];
  loading: boolean;
  loadingSceneSettings: boolean;
  loadingScenes: boolean;
  loadingAssignedScenes: boolean;
  setDressing: any;
  imageFiles: any[];
  scenes: any[];
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  findingInScript: boolean;
  searchingScenes: boolean;
  enableSave: boolean;
}

export const OnboardingStep1ClassName = 'set-dressing-scenes-onboarding-step-1';
export const OnboardingStep2ClassName = 'set-dressing-scenes-onboarding-step-2';
export const OnboardingStep3ClassName = 'set-dressing-scenes-onboarding-step-3';
export const OnboardingStep4ClassName = 'set-dressing-scenes-onboarding-step-4';
export const OnboardingStep5ClassName = 'set-dressing-scenes-onboarding-step-5';

const defaultState: ActionState = {
  setDressings: [],
  loading: true,
  loadingSceneSettings: false,
  loadingAssignedScenes: true,
  loadingScenes: true,
  setDressing: {},
  imageFiles: [],
  scenes: [],
  readOnly: false,
  findingInScript: false,
  searchingScenes: false,
  enableSave: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scene Search',
      content: `        
        Use this to quickly select the scenes that match the selected criteria.
      `
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Select',
      content: `Or just select or unselect all scenes.`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Manual',
      content: `<p>If any scenes have not been selected using any of the above methods, then you can manually select or unselect a scene.</p>
                <p>This list only includes scenes which are set at the selected scene setting.</p>`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Save',
      content: `Once you have the desired scenes selected, don't forget to click Save.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        setDressing: null,
        redirect: null
      };
    }

    case FETCH_ALL_SCENES_FOR_EQUIPMENT + "_PENDING": {
      return {
        ...state,
        loadingScenes: true
      };
    }

    case FETCH_ALL_SCENES_FOR_EQUIPMENT + "_FULFILLED": {
      const scenes = action.payload.data.scenes.map((scene) => {
        return { ...scene, name: `${scene.number}. ${scene.settingName}` };
      });
      return {
        ...state,
        scenes,
        loadingScenes: false,
        errors: null
      };
    }

    case FETCH_ALL_SCENE_SETTINGS_FOR_EQUIPMENT + "_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case FETCH_SCENES_ASSIGNED_TO_EQUIPMENT + "_PENDING": {
      return {
        ...state,
        loadingAssignedScenes: true
      };
    }

    case FETCH_SCENES_ASSIGNED_TO_EQUIPMENT + "_FULFILLED": {
      return {
        ...state,
        selectedSceneIds: action.payload.data.sceneIds,
        readOnly: action.payload.data.readOnly,
        loadingAssignedScenes: false,
        errors: null
      };
    }

    case SET_SCENE_ASSIGNED_TO_EQUIPMENT: {
      return {
        ...state,
        selectedSceneIds: [action.meta.sceneId]
      };
    }

    case ASSIGN_SCENES_TO_EQUIPMENT + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "FETCH_ALL_SCENE_SETTINGS_FOR_SET_DRESSING_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case SEARCH_SCENES_FOR_EQUIPMENT + "_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case SEARCH_SCENES_FOR_EQUIPMENT + "_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        searchingScenes: false,
      };
    }

    case "SEARCH_SCENES_FOR_SET_DRESSING_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "SET_DRESSING_SEARCH_SCRIPT_PENDING": {
      return {
        ...state,
        findingInScript: true
      }
    }

    case "SET_DRESSING_SEARCH_SCRIPT_FULFILLED": {
      let searchedScenes = action.payload.data.scenes;
      const sceneIds = state.scenes.map(s => s.id);
      const filteredScenes = searchedScenes.filter(s => sceneIds.indexOf(s) > -1);
      return {
        ...state,
        searchedScenes: filteredScenes.map((id) => { return { id } }),
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        findingInScript: false
      };
    }

    case "SET_DRESSING_SEARCH_SCRIPT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
        findingInScript: false
      };
    }

    case "CLEAR_SEARCH_SCENES_FOR_SET_DRESSING": {
      return {
        ...state,
        searchedScenes: [],
        loading: false,
        errors: null
      };
    }

    case CLEAR_EQUIPMENT_SCENES_REDIRECT: {
      return {
        ...state,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
