import getApiClient from "../../../../shared/api";
import { showInfo, showError, clearNotifications } from "../../../../notifications/ducks/actions";

export const FETCH_DAY_OUT_OF_DAYS = "FETCH_DAY_OUT_OF_DAYS";
export const UPDATE_SHOOTING_DAY_SHOW_OFF_DAYS = "UPDATE_SHOOTING_DAY_SHOW_OFF_DAYS";
export const UPDATE_SHOOTING_DAY_SHOW_CHARACTER = "UPDATE_SHOOTING_DAY_SHOW_CHARACTER";
export const UPDATE_SHOOTING_DAY_ACTOR_ORDER = "UPDATE_SHOOTING_DAY_ACTOR_ORDER";
export const UPDATE_SHOOTING_DAY_DOOD_CREW_ORDER = "UPDATE_SHOOTING_DAY_DOOD_CREW_ORDER";
export const UPDATE_SHOOTING_DAY_DOOD_LOCATIONS_ORDER = "UPDATE_SHOOTING_DAY_DOOD_LOCATIONS_ORDER";
export const DOWNLOAD_DAY_OUT_OF_DAYS = "DOWNLOAD_DAY_OUT_OF_DAYS";
export const DOWNLOADED_DAY_OUT_OF_DAYS = "DOWNLOADED_DAY_OUT_OF_DAYS";
export const CLEAR_DOWNLOAD_DAY_OUT_OF_DAYS = "CLEAR_DOWNLOAD_DAY_OUT_OF_DAYS";
export const CHECK_SHOOTING_DAYS = "CHECK_SHOOTING_DAYS";
export const DOWNLOAD_DAY_OUT_OF_DAYS_ERROR = "DOWNLOAD_DAY_OUT_OF_DAYS_ERROR";

export const DOOD_ACTOR_ORDER_NAME = "name";
export const DOOD_ACTOR_ORDER_NUMBER = "number";

export const DOOD_CREW_ORDER_NAME = "name";
export const DOOD_CREW_ORDER_ROLE = "role";
export const DOOD_CREW_ORDER_DEPARTMENT = "department";

export const DOOD_LOCATIONS_ORDER_NAME = "name";
export const DOOD_LOCATIONS_ORDER_SHOOTING_DAY = "day";

export function fetchDayOutOfDays(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_DAY_OUT_OF_DAYS,
      payload: getApiClient().get(`/film/${filmId}/ShootingDay/DayOutOfDays`),
    });
  };
}

export function updateShowOffDays(filmId, shootingDaySettingsId, show) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SHOOTING_DAY_SHOW_OFF_DAYS,
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDaySettingsId}/ShowOffDays`,
        { show }
      ),
      meta: { show }
    })
      .then((response) => {
        //dispatch(showInfo(`Updated Shooting Day show off days`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day show off days`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateActorOrder(filmId, shootingDaySettingsId, order) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SHOOTING_DAY_ACTOR_ORDER,
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDaySettingsId}/DayDayOutOfDaysActorOrder`,
        { order }
      ),
      meta: { order }
    })
      .then((response) => {
        //dispatch(showInfo(`Updated Shooting Day actor order`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day actor order`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateShowCharacters(filmId, shootingDaySettingsId, show) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SHOOTING_DAY_SHOW_CHARACTER,
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDaySettingsId}/DayDayOutOfDaysShowCharacters`,
        { show }
      ),
      meta: { show }
    })
      .then((response) => {
        //dispatch(showInfo(`Updated Shooting Day actor order`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day show characters`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateCrewOrder(filmId, shootingDaySettingsId, order) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SHOOTING_DAY_DOOD_CREW_ORDER,
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDaySettingsId}/DayDayOutOfDaysCrewOrder`,
        { order }
      ),
      meta: { order }
    })
      .then((response) => {
        //dispatch(showInfo(`Updated Shooting Day actor order`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day crew order`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateLocationsOrder(filmId, shootingDaySettingsId, order) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SHOOTING_DAY_DOOD_LOCATIONS_ORDER,
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDaySettingsId}/DayDayOutOfDaysLocationsOrder`,
        { order }
      ),
      meta: { order }
    })
      .then((response) => {
        //dispatch(showInfo(`Updated Shooting Day actor order`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day locations order`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function checkShootingDays(filmId, fixIssues) {
  return (dispatch) => {
    dispatch({
      type: CHECK_SHOOTING_DAYS,
      payload: getApiClient().post(
        `/film/${filmId}/ShootingDay/check`,
        { fixIssues }
      ),
      meta: { fixIssues }
    })
      .then((response) => {
        //dispatch(showInfo(`Updated Shooting Day show off days`));
      })
      .catch((response) => {
        dispatch(showError(`Error checking Shooting Days`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function downloadDayOutOfDays(filmId, includeCharacters, includeBackgroundCharacters, includeActors, includeBackgroundActors, includeCrew, includePhoneNumbers, includeLocations) {
  return (dispatch) => {
    dispatch({
      type: DOWNLOAD_DAY_OUT_OF_DAYS,
      payload: getApiClient().post(`/film/${filmId}/ShootingDay/DayOutOfDays/download`, { includeCharacters, includeBackgroundCharacters, includeActors, includeBackgroundActors, includeCrew, includePhoneNumbers, includeLocations }),
    });
  };
}

export function pdfDownloaded() {
  return (dispatch) => {
    dispatch({
      type: DOWNLOADED_DAY_OUT_OF_DAYS
    });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DOWNLOAD_DAY_OUT_OF_DAYS
    });
  };
}

export function setError(error) {
  return (dispatch) => {
    dispatch({
      type: DOWNLOAD_DAY_OUT_OF_DAYS_ERROR,
      meta: { error }
    });
  };
}