import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CreatePaymentMethodData } from "@stripe/stripe-js";

import Loader from "../../shared/loader";
import AnonymousPage from "../anonymous-pages/anonymous-page";
import BoxWraper from "../box-wrapper";
import {
  payment,
  getCurrencies,
  clearRedirect,
  clearForm,
  clearRegistered,
  getPaymentIntent,
  setError,
  setPaying,
  fetchPricingPlans,
  checkDiscountCode
} from "./ducks/actions";
import Form from "./form";

function Payment(props) {
  const {
    paying,
    getPaymentIntent,
    loadingPricingPlans,
    countries,
    defaultCountryId,
    languages,
    defaultLanguageId,
    getCurrencies,
    currencies,
    loadingCurrencies,
    errors,
    user,
    clearForm,
    registered,
    referralCode,
    clearRegistered,
    clientSecret,
    setError,
    setPaying,
    fetchPricingPlans,
    selectedCurrencyId,
    pricingPlans,
    selectedPricingPlan: defaultPricingPlan,
    userName,
    userEmailAddress,
    userCountryCode,
    noPaymentNeeded,
    pendingSetupIntent,
    checkDiscountCode,
    discountCodeValid,
    discountCodeDescription,
    checkingCode,
    firstMonthPrice
  } = props;

  const pricingPlan = props.match.params.pricingPlan ? +props.match.params.pricingPlan : 0;

  const history = useHistory();
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [selectedPricingPlan, setSelectedPricingPlan] = useState<any>(defaultPricingPlan);

  useEffect(() => {
    clearForm();
    fetchPricingPlans();
    //getCurrencies();
  }, []);

  useEffect(() => {
    if (defaultPricingPlan) {
      setSelectedPricingPlan(defaultPricingPlan);
    }
  }, [defaultPricingPlan]);


  // useEffect(() => {
  //   if (clientSecret) {
  //     const cardElement = elements.getElement(CardElement);
  //     const data: CreatePaymentMethodData = {
  //       type: "card", card: cardElement, billing_details: {
  //         name: userName,
  //         email: userEmailAddress,
  //         address: {
  //           country: userCountryCode
  //         }
  //       }
  //     };

  //     const getPaymentMethodRequest = async (data) => {
  //       const paymentMethodRequest: any = await stripe?.createPaymentMethod(data);
  //       if (paymentMethodRequest?.error) {
  //         throw paymentMethodRequest?.error.message;
  //       }
  //       const confirmedCardPayment = await stripe?.confirmCardPayment(clientSecret, { payment_method: paymentMethodRequest.paymentMethod.id })
  //       if (confirmedCardPayment?.error) {
  //         throw confirmedCardPayment?.error.message;
  //       }

  //       return confirmedCardPayment;
  //     }

  //     getPaymentMethodRequest(data).then(() => {
  //       setPaying(false);
  //       history.push("/payment/complete/" + pricingPlan);
  //     }).catch((error) => {
  //       setPaying(false);
  //       setError(error);
  //     });
  //   }
  // }, [clientSecret]);

  useEffect(() => {
    if (clientSecret) {
      //const cardElement = elements.getElement(CardElement);

      const data: any = {
        payment_method: paymentMethodId
      };

      const confirmCardSetup = async (data) => {
        try {
          const confirmedCardSetup = await stripe?.confirmCardSetup(clientSecret, data)
          if (confirmedCardSetup?.error) {
            throw confirmedCardSetup?.error.message;
          }

          return confirmedCardSetup;
        } catch (error) {
          debugger;
        }
      }

      const confirmCardPayment = async (data) => {
        try {
          const confirmedCardPayment = await stripe?.confirmCardPayment(clientSecret, data)
          if (confirmedCardPayment?.error) {
            throw confirmedCardPayment?.error.message;
          }

          return confirmedCardPayment;
        } catch (error) {
          debugger;
        }
      }

      if (pendingSetupIntent) {
        confirmCardSetup(data).then(() => {
          setPaying(false);
          history.push("/payment/complete/" + selectedPricingPlan);
        }).catch((error) => {
          setPaying(false);
          setError(error);
        });
      }
      else {
        confirmCardPayment(data).then(() => {
          setPaying(false);
          history.push("/payment/complete/" + selectedPricingPlan);
        }).catch((error) => {
          setPaying(false);
          setError(error);
        });
      }
    }
  }, [clientSecret]);

  useEffect(() => {
    if (noPaymentNeeded) {
      setPaying(false);
      history.push("/payment/complete/" + selectedPricingPlan);
    }
  }, [noPaymentNeeded]);

  const stripe = useStripe();
  const elements: any = useElements();


  if (registered) {
    clearRegistered();
    return <Redirect to={`/confirmation/${selectedPricingPlan}`} />;
  }

  const onPayment = async (pricingPlan, currencyId, discountCode) => {
    const cardElement = elements.getElement(CardElement);
    const data: CreatePaymentMethodData = {
      type: "card", card: cardElement, billing_details: {
        name: userName,
        email: userEmailAddress,
        address: {
          country: userCountryCode
        }
      }
    };

    const getPaymentMethodRequest = async (data) => {
      const paymentMethodRequest: any = await stripe?.createPaymentMethod(data);
      if (paymentMethodRequest?.error) {
        throw paymentMethodRequest?.error.message;
      }

      return paymentMethodRequest;
      // const confirmedCardPayment = await stripe?.confirmCardPayment(clientSecret, { payment_method: paymentMethodRequest.paymentMethod.id })
      // if (confirmedCardPayment?.error) {
      //   throw confirmedCardPayment?.error.message;
      // }

      // return confirmedCardPayment;
    }

    getPaymentMethodRequest(data).then((response) => {

      const paymentMethodId = response?.paymentMethod?.id;
      setPaymentMethodId(paymentMethodId);
      getPaymentIntent(pricingPlan, currencyId, paymentMethodId, discountCode);
      //setPaying(false);
      //history.push("/payment/complete/" + pricingPlan);
    }).catch((error) => {
      setPaying(false);
      setError(error);
    });
  }

  const onCheckDiscountCode = (code, pricingPlan, currencyId) => {
    checkDiscountCode(code, pricingPlan, currencyId);
  }

  return (
    <AnonymousPage>
      <BoxWraper width={5}>

        {(loadingPricingPlans || loadingCurrencies || paying) && <Loader />}
        {!(loadingPricingPlans || loadingCurrencies) && <>
          {!registered && <Form
            errors={errors}
            loading={false}
            initialValues={user}
            handleSubmit={onPayment}
            currencies={currencies}
            showReferrer={referralCode == null}
            submitting={paying}
            defaultCurrencyId={selectedCurrencyId}
            pricingPlans={pricingPlans}
            selectedPricingPlan={selectedPricingPlan}
            setSelectedPricingPlan={setSelectedPricingPlan}
            noPaymentNeeded={noPaymentNeeded}
            checkDiscountCode={onCheckDiscountCode}
            discountCodeValid={discountCodeValid}
            discountCodeDescription={discountCodeDescription}
            checkingCode={checkingCode}
            firstMonthPrice={firstMonthPrice}
            {...props}
          />}
        </>}
      </BoxWraper>
    </AnonymousPage>
  );
}

function mapStateToProps(state) {
  const { accountState, commonState } = state;
  const { paymentState } = accountState;
  return {
    defaultCountryId: paymentState.defaultCountryId,
    currencies: paymentState.currencies,
    selectedCurrencyId: paymentState.selectedCurrencyId,
    selectedPricingPlan: paymentState.selectedPricingPlan,
    errors: paymentState.errors,
    loadingRegister: paymentState.loadingRegister,
    redirect: paymentState.redirect,
    clientSecret: paymentState.clientSecret,
    paying: paymentState.paying,
    pricingPlans: paymentState.pricingPlans,
    loadingPricingPlans: paymentState.loadingPricingPlans,
    userCountryCode: paymentState.userCountryCode,
    noPaymentNeeded: paymentState.noPaymentNeeded,
    pendingSetupIntent: paymentState.pendingSetupIntent,
    discountCodeValid: paymentState.discountCodeValid,
    discountCodeDescription: paymentState.discountCodeDescription,
    firstMonthPrice: paymentState.firstMonthPrice,
    checkingCode: paymentState.checkingCode,
    userName: commonState.user?.name,
    // userFirstName: commonState.user?.firstName,
    // userLastName: commonState.user?.lastName,
    userEmailAddress: commonState.user?.emailAddress,
  };
}

export default connect(mapStateToProps, {
  clearForm,
  payment,
  getCurrencies,
  clearRedirect,
  clearRegistered,
  getPaymentIntent,
  setError,
  setPaying,
  fetchPricingPlans,
  checkDiscountCode
})(Payment);
