import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import {
  aliasCharacter,
  assignActor,
  fetchCharacterOverview,
  clearCharacterOverview,
  unaliasCharacter,
  unassignActor
} from "./ducks/actions";
import Overview from "./overview";
import Onboarding from '../../../shared/onboarding/onboarding';
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

const OverviewContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchCharacterOverview,
    assignActor,
    unassignActor,
    aliasCharacter,
    unaliasCharacter,
    overview,
    loading,
    assigning,
    errors,
    warnings,
    onboardingSteps
  } = props;

  useEffect(() => {
    fetchCharacterOverview(filmId);

    return function cleanUp() {
      props.clearCharacterOverview();
    };
  }, [filmId]);

  function renderOverview(overview) {
    const charcactersWithActors = overview.backgroundCharacters?.reduce((a, b) => a + b.backgroundActors?.length || 0, 0);
    //.filter((c) => c.backgroundActors?.length === c.quantity)      .length;
    const allCharcacters = overview.backgroundCharacters.reduce((a, b) => a + b.quantity, 0);
    const charactersAssignedPercentage =
      !allCharcacters || allCharcacters === 0
        ? 0
        : (charcactersWithActors / allCharcacters) * 100;

    return (
      <ScrollIntoView
        className="blade-content charcterOverview"
        path="/films/:filmId/background-characters/overview"
        loading={loading}>
        <h2 className="menu-label">Overview</h2>
        {assigning && <Loader />}
        <Overview
          overview={overview}
          filmId={filmId}
          charactersAssignedPercentage={charactersAssignedPercentage}
          assignActor={assignActor}
          unassignActor={unassignActor}
          aliasCharacter={aliasCharacter}
          unaliasCharacter={unaliasCharacter}
          errors={errors}
          warnings={warnings}
        ></Overview>
        <Onboarding onboardingSteps={onboardingSteps} section="Background Characters" />
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !overview) && <Loader />}
      {!loading && overview && renderOverview(overview)}
    </>
  );
};

function mapStateToProps(state) {
  const { errors, warnings, overview, loading, assigning, onboardingSteps } = state.backgroundCharactersState.overviewState;
  return {
    errors,
    warnings,
    overview,
    loading,
    onboardingSteps,
    assigning
  };
}

export default connect(mapStateToProps, {
  fetchCharacterOverview,
  clearCharacterOverview,
  assignActor,
  unassignActor,
  aliasCharacter,
  unaliasCharacter
})(OverviewContainer);
