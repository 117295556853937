import { useEffect, useState } from "react";
import Errors from "../../../shared/errors";
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";
import Progress from "../../../shared/progress";
import { OnboardingStep1ClassName } from "./ducks/reducer";

const MessagesForm = (props) => {
  const {
    handleSubmit,
    reset,
    submitting,
    errors,
    saved,
    readOnly,
    sendDietaryRequirements,
    progress,
    updateExludeDrink,
    initialExcludeDrinks,
    filmId,
    shootingDaySettingsId,
    savingExcludeDrinks
  } = props;

  if (saved) {
    reset();
  }

  const [excludeDrinks, setExcludeDrinks] = useState(false);

  useEffect(() => {
    if (initialExcludeDrinks !== null) {
      setExcludeDrinks(initialExcludeDrinks);
    }
  }, [initialExcludeDrinks]);

  const onSetExcludeDrinks = (exlcude) => {
    updateExludeDrink(filmId, shootingDaySettingsId, exlcude);
    setExcludeDrinks(exlcude);
  };

  return (
    <div>
      {errors && <Errors errors={errors} />}
      <FilmanizeCheckbox label="Exclude drink options" checked={excludeDrinks} onChange={onSetExcludeDrinks} disabled={savingExcludeDrinks} />
      {!readOnly && <div className="buttons mt-1">
        <button
          className={`button is-primary ${OnboardingStep1ClassName}`}
          disabled={submitting}
          onClick={sendDietaryRequirements}>
          Send Dietary Requirements
        </button>
      </div>}
      {progress && progress.progress < 100 && <Progress progress={progress} />}

    </div>
  );
}

export default MessagesForm;