  
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useInView } from 'react-intersection-observer';
import {  
  fetchFaqs
} from "./ducks/actions";
import Loader from "../../../shared/loader";
import Faqs from "./faqs"
import { matchPath, useLocation } from "react-router-dom";


const FaqContainer = (props) => {  
    const { 
      errors,
      faqs,
      fetchFaqs,
      continuationToken,
      id,
      loading,
      onAskAQuestion,
      pageSections,
      selectedPageSection,
      setSelectedPageSection
       } = props;

    const { ref, inView } = useInView({ triggerOnce: true });
    
    const location = useLocation();
    useEffect(() => {    
      if (!selectedPageSection) {
        pageSections.forEach((pageSection) => {
          
          if (matchPath(location.pathname, { path: pageSection.path, exact: true })) {       
            setSelectedPageSection(pageSection.name);          
          }
        });
      }
    }, [location, pageSections]);

    useEffect(() => {        
      if (selectedPageSection) {
        fetchFaqs(null, selectedPageSection);      
      }
    }, [selectedPageSection]);

    const onLoadMore = () => {
      fetchFaqs(continuationToken, selectedPageSection);
    };

    const onPageSectionChange = (pageSection) => {      
      setSelectedPageSection(pageSection.value);
    };

    const onOnAskAQuestion = () => {
      onAskAQuestion(selectedPageSection);
    };

    return (
      <>        
        <Faqs ref={ref} 
             faqs={faqs} 
             pageSections={pageSections}
             selectedPageSection={selectedPageSection}
             moreToLoad={continuationToken != null} 
             loadMore={onLoadMore} 
             onPageSectionChange={onPageSectionChange}
             id={id} 
             loading={loading} 
             onAskAQuestion={onOnAskAQuestion}/>
        </>
    )
}


  function mapStateToChangeLogs(state) {
    const { faqState, commonState } = state;
    return {
      faqs: faqState.faqs,
      continuationToken: faqState.continuationToken,
      errors: faqState.errors,    
      loading: faqState.loading,
      pageSections: commonState.pageSections
    };
  }
  
  export default connect(mapStateToChangeLogs, {
    fetchFaqs
  })(FaqContainer);
  