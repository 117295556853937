import { sortByName } from "../../../../shared/utility";
import { ADD_SET_DRESSING_TO_SCENE } from "./actions";

const defaultState = {
  setDressing: {},
  setDressings: [],
  loading: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SET_DRESSINGS_IN_SCENE_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SET_DRESSINGS_IN_SCENE_FULFILLED": {
      return {
        ...state,
        setDressings: action.payload.data.setDressings,
        loading: false,
        errors: null
      };
    }

    case ADD_SET_DRESSING_TO_SCENE + "_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case ADD_SET_DRESSING_TO_SCENE + "_FULFILLED": {
      const setDressing = action.payload.data.setDressing;
      const setDressings = [...state.setDressings, setDressing];
      sortByName(setDressings);
      const { fromBreakdown } = action.meta;
      return {
        ...state,
        setDressings,
        saving: false,
        errors: null,
        redirect: { to: fromBreakdown ? "breakdown" : "list" }
      };
    }

    case ADD_SET_DRESSING_TO_SCENE + "_REJECTED": {
      return {
        ...state,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        character: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
