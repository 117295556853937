import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../shared/loader";
import confirm from "../../shared/modal/confirm";
import {
  addBackgroundCharacter,
  deleteBackgroundCharacter,
  fetchBackgroundCharacter,
  updateBackgroundCharacter,
  uploadImagesAndSaveBackgroundCharacter,
  clearRedirect,
  fetchBackgroundActorsWithEmailAddresses,
  changeBackgroundCharacterToCharacter
} from "./ducks/actions";
import Form from "./form";
import { Redirect, withRouter } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function EditBackgroundCharacter(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const backgroundCharacterId = props.backgroundCharacterId || props.match.params.backgroundCharacterId;

  const {
    fetchBackgroundCharacter,
    updateBackgroundCharacter,
    uploadImagesAndSaveBackgroundCharacter,
    deleteBackgroundCharacter,
    fetchBackgroundActorsWithEmailAddresses,
    loadingBackgroundCharacter,
    backgroundCharacter,
    backgroundActors,
    errors,
    warnings,
    embedded,
    genderEnum,
    clearRedirect,
    changeBackgroundCharacterToCharacter
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {
    if (invalidIds.indexOf(backgroundCharacterId) === -1) {
      fetchBackgroundCharacter(filmId, backgroundCharacterId);
      fetchBackgroundActorsWithEmailAddresses(filmId);
    }
  }, [filmId, backgroundCharacterId]);

  useEffect(() => {
    if (backgroundCharacter && backgroundCharacter.backgroundActorIds && backgroundActors) {
      let characterBackgroundActors: any[] = [];
      backgroundCharacter.backgroundActorIds.forEach(actorId => {
        const actor = backgroundActors.find((c) => c.id === actorId);
        if (actor) {
          characterBackgroundActors.push({ value: actorId, label: actor.name })
        }
      });
      backgroundCharacter.backgroundActors = characterBackgroundActors;
    }
  }, [backgroundCharacter, backgroundActors]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/background-characters/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "characters") {
      props.clearRedirect();
      const url = `/films/${filmId}/characters/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(backgroundCharacterId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function onChangeBackgroundCharacterToCharacter() {
    let message = `Are you sure you want to change ${backgroundCharacter.name} into a Character?`;
    if (backgroundCharacter.backgroundActorIds?.length > 0) {
      message += ` The assigned Background Actor${backgroundCharacter.backgroundActorIds?.length > 1 ? "s" : ""} will be removed.`
    }
    confirm(() => changeBackgroundCharacterToCharacter(filmId, backgroundCharacter), null, message, "Change Background Character");
  }

  function renderBackgroundCharacter(backgroundCharacter) {
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/background-characters/:backgroundCharacterId/edit"
        loading={loadingBackgroundCharacter}>
        <Form
          errors={errors}
          warnings={warnings}
          mode="edit"
          initialValues={backgroundCharacter}
          loading={loadingBackgroundCharacter}
          onSubmit={onUpdateBackgroundCharacter}
          handleDelete={onDeleteBackgroundCharacter}
          handleChangeBackgroundCharacterToCharacter={onChangeBackgroundCharacterToCharacter}
          backgroundActors={backgroundActors}
          ethnicityTypes={props.ethnicityTypes}
          skinColors={props.skinColors}
          filmId={filmId}
          loadingRate={false}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateBackgroundCharacter(backgroundCharacter) {
    backgroundCharacter.minimumAge = backgroundCharacter.minimumAge ? +backgroundCharacter.minimumAge : null;
    backgroundCharacter.maximumAge = backgroundCharacter.maximumAge ? +backgroundCharacter.maximumAge : null;
    backgroundCharacter.imageUrls = (backgroundCharacter.imageUrls || []).filter((url) => url);

    if (backgroundCharacter.ethnicities?.length) {
      backgroundCharacter.ethnicityIds = backgroundCharacter.ethnicities.map((m) => m.value || m);
    } else {
      backgroundCharacter.ethnicityIds = [];
    }

    if (backgroundCharacter.skinColors?.length) {
      backgroundCharacter.skinColorIds = backgroundCharacter.skinColors.map((m) => m.value || m);
    } else {
      backgroundCharacter.skinColorIds = [];
    }

    backgroundCharacter.backgroundActorIds = backgroundCharacter.backgroundActors?.map((m) => m.value || m);

    const addMode = !backgroundCharacter.id;

    const files = backgroundCharacter.pictures || [];

    if ((files && files.length) > 0 || (backgroundCharacter.images && backgroundCharacter.images.length > 0)) {
      uploadImagesAndSaveBackgroundCharacter(backgroundCharacter, files, addMode, filmId);
    } else {
      if (!backgroundCharacter.imageUrls) {
        backgroundCharacter.imageUrls = [];
      }
      updateBackgroundCharacter(filmId, backgroundCharacter);
    }
  }

  function onDeleteBackgroundCharacter(backgroundCharacter) {
    confirm(() => deleteBackgroundCharacter(filmId, backgroundCharacter), backgroundCharacter?.name);
  }

  return !loadingBackgroundCharacter
    ? renderBackgroundCharacter(backgroundCharacter)
    : renderLoading();
}

function mapStateToProps(state) {
  const { backgroundCharactersState, backgroundActorsState, commonState } = state;
  const backgroundCharacterRootState = backgroundCharactersState.rootState;
  return {
    backgroundCharacter: backgroundCharacterRootState.backgroundCharacter,
    loading: backgroundCharacterRootState.loading,
    loadingBackgroundCharacter: backgroundCharacterRootState.loadingBackgroundCharacter,
    redirect: backgroundCharacterRootState.redirect,
    genderEnum: commonState.genderEnum,
    ethnicityTypes: commonState.ethnicityTypes,
    skinColors: commonState.skinColors,
    backgroundActors: backgroundCharacterRootState.backgroundActors,
    errors: backgroundCharacterRootState.errors,
    warnings: backgroundCharacterRootState.warnings
  };
}

export default connect(mapStateToProps, {
  fetchBackgroundCharacter,
  addBackgroundCharacter,
  updateBackgroundCharacter,
  uploadImagesAndSaveBackgroundCharacter,
  deleteBackgroundCharacter,
  fetchBackgroundActorsWithEmailAddresses,
  clearRedirect,
  changeBackgroundCharacterToCharacter
})(EditBackgroundCharacter);
