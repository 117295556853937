import React from "react";
import { Route } from "react-router-dom";
import ShotContainer from "./list-container";
import EditShot from "./edit";
import AddShot from "./add";
import ShotMenu from "./menu";

import TakeRoutes from "./takes/routes";
import { PrivateRoute } from "../../../shared/private-route";

const ShotRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/shots"
        component={ShotContainer}
      />
      {/* <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/shots/:shotId"
        component={ShotMenu}
      /> */}
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/shots/:shotId/edit"
        component={EditShot}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/shots/add"
        component={AddShot}
      />
      <TakeRoutes />
    </>
  );
};

export default ShotRoutes;
