import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export function fetchShootingDaySettings(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_SETTINGS",
      payload: getApiClient().get(`/film/${filmId}/shootingDay/settings`),
    });
  };
}

export function updateShootingDaySettings(filmId, shootingDaySettings) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SHOOTING_DAY_SETTINGS",
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/settings/${shootingDaySettings.id}`,
        shootingDaySettings
      ),
      meta: { shootingDaySettings: shootingDaySettings }
    })
      .then((response) => {
        dispatch(showInfo(`Updated Shooting Day settings`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day Settings`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
