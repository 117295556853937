const defaultState = {
  loading: true,
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTING_DAY_WEATHER_PENDING": {
      return {
        ...state,
        weather: null,
        loading: true,
        errors: null,
      };
    }

    case "FETCH_SHOOTING_DAY_WEATHER_FULFILLED": {      
      return {
        ...state,
        weather: action.payload.data,
        loading: false,
        errors: null,
      };
    }

    case "FETCH_SHOOTING_DAY_WEATHER_REJECTED": {
      return {
        ...state,    
        weather: null,
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    default:
      return state;
  }
};

export default reducer;
