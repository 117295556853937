import React from "react";
import Script from "./index";
import { PrivateRoute } from "../../shared/private-route";
import AddProp from "../accessories/props/add";

const ScriptRoutes = () => {
  return (
    <>
      <PrivateRoute path="/films/:filmId/script" component={Script} />
      <PrivateRoute path="/films/:filmId/script-dev" component={Script} />
      <PrivateRoute path="/films/:filmId/script/prop" component={AddProp} />
    </>
  );
};

export default ScriptRoutes;
