import React from "react";
import InputSlider from "../../shared/controls/input-slider";
import NumberFormat from "react-number-format";
import CurrencyInputContainer from "../../shared/currency-input-container";
import Checkbox from "../../shared/filmanize-checkbox";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function Equipment(props) {
  const {
    equipment,
    equipmentCategoryId,
    onChangeRequired,
    onChangeDailyRate,
    onChangeDays,
    days,
    hasShootingDays,
    readOnly,
    filmId,
    saving
  } = props;

  const label = <Link to={`/films/${filmId}/equipment-category/${equipmentCategoryId}/equipment/edit/${equipment.id}`}>{equipment.name}</Link>;

  return (
    <tr>
      <td>
        {!readOnly && (
          <Checkbox
            checked={equipment.required}
            onChange={(value) => onChangeRequired(equipmentCategoryId, equipment.id, value)}
            label={label}
            disabled={saving}
          />
        )}
      </td>
      <td className="budget-days-column">
        {equipment.acquisitionMethod == 3 ? (hasShootingDays ? (
          equipment.days
        ) : (
          <InputSlider
            step={1}
            formatLabel={(value) => `${value}`}
            draggableTrack={false}
            allowSameValues={false}
            maxValue={days}
            minValue={1}
            value={equipment.days}
            onChange={(val) => onChangeDays(equipmentCategoryId, equipment.id, val)}
            disabled={readOnly || saving}
          />
        )
        ) : (
          "-"
        )}
      </td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={equipment.acquisitionMethod == 3 ? equipment.dailyRate : equipment.buyBudget}
          onChange={(val) => onChangeDailyRate(equipmentCategoryId, equipment.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={equipment.total}
        />
      </td>
    </tr>
  );
}

export default Equipment;
