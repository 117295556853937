import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../../shared/modal/confirm";
import Loader from "../../../shared/loader";
import {
  clearRedirect,
  deleteMakeup,
  fetchMakeup,
  updateMakeup,
  uploadImagesAndSaveMakeup,
  fetchAllCharacters,
  fetchAllBackgroundCharacters
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function EditMakeup(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const makeupId = props.makeupId || props.match.params.makeupId;

  const {
    fetchMakeup,
    updateMakeup,
    uploadImagesAndSaveMakeup,
    deleteMakeup,
    loadingMakeup,
    makeup,
    errors,
    embedded,
    fetchAllCharacters,
    fetchAllBackgroundCharacters,
    allCharacters,
    allBackgroundCharacters
  } = props;

  const invalidIds = ["add"];

  useEffect(() => {
    if (invalidIds.indexOf(makeupId) === -1) {
      fetchMakeup(filmId, makeupId);
      fetchAllCharacters(filmId);
      fetchAllBackgroundCharacters(filmId);
    }
  }, [filmId, makeupId]);

  useEffect(() => {
    if (makeup && allCharacters) {
      makeup.characters = [];
      makeup.characterIds?.forEach(characterId => {
        const character = allCharacters.find((c) => c.id === characterId);
        if (character) {
          makeup.characters.push({ value: characterId, label: character.name })
        }
      });
    }
  }, [makeup, allCharacters]);

  useEffect(() => {
    if (makeup && allBackgroundCharacters) {
      makeup.backgroundCharacters = [];
      makeup.backgroundCharacterIds?.forEach(backgroundCharacterId => {
        const backgroundCharacter = allBackgroundCharacters.find((c) => c.id === backgroundCharacterId);
        if (backgroundCharacter) {
          makeup.backgroundCharacters.push({ value: backgroundCharacterId, label: backgroundCharacter.name })
        }
      });
    }
  }, [makeup, allBackgroundCharacters]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/makeup/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(makeupId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderMakeup(makeup) {
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/makeup/:makeupId/edit"
        loading={loadingMakeup}>
        {makeup && <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          makeupId={makeup.id}
          initialValues={makeup}
          loading={loadingMakeup}
          onSubmit={onUpdateMakeup}
          handleDelete={onDeleteMakeup}
          reduxFormValues={props.reduxFormValues}
          acquisitionMethodEnum={props.acquisitionMethodEnum}
          allCharacters={allCharacters}
          allBackgroundCharacters={allBackgroundCharacters}
        />}
      </ScrollIntoView>
    );
  }

  function onUpdateMakeup(makeup) {
    makeup.characterIds = makeup.characters?.map((x) => x.value || x);
    makeup.backgroundCharacterIds = makeup.backgroundCharacters?.map((x) => x.value || x);
    const files = makeup.pictures || [];

    if ((files && files.length) > 0 || (makeup.images && makeup.images.length > 0)) {
      uploadImagesAndSaveMakeup(makeup, files, false, filmId);
    } else {
      if (!makeup.images) {
        makeup.images = [];
      }

      updateMakeup(filmId, makeup);
    }
  }

  function onDeleteMakeup(makeup) {
    confirm(() => deleteMakeup(filmId, makeup), makeup?.name);
  }

  return makeup && !loadingMakeup ? renderMakeup(makeup) : renderLoading();
}

function mapStateToMakeups(state) {
  const { makeupState, commonState } = state;
  return {
    makeup: makeupState.makeup,
    errors: makeupState.errors,
    redirect: makeupState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod,
    allCharacters: makeupState.characters,
    allBackgroundCharacters: makeupState.backgroundCharacters
  };
}

export default
  connect(mapStateToMakeups, {
    fetchMakeup,
    updateMakeup,
    deleteMakeup,
    clearRedirect,
    uploadImagesAndSaveMakeup,
    fetchAllCharacters,
    fetchAllBackgroundCharacters
  })(EditMakeup);
