const ReCaptureInfo = () => {

    return (
        <div className="recaptcha-info">
            This site is protected by reCAPTCHA and the Google&nbsp;<a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and&nbsp;
            <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
        </div>
    );
}

export default ReCaptureInfo;
