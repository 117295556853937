import CanAccessLink from "../../../shared/can-access-link";

const AvailabilityActorsTable = (props) => {
  const {
    filmId,
    sectionLink,
    canAccess,
    people,
    dates,
    linkGenerator
  } = props;

  const filteredDates = dates;

  return (
    <>
      {(people || []).map((actor, index) => {

        const link = linkGenerator ? linkGenerator(actor) : `/films/${filmId}/${sectionLink}/${actor.id}/edit`;

        return (
          <tr key={index}>
            <td className="day-out-days-table-actor-column">
              <CanAccessLink to={link} canAccess={canAccess}>
                {actor.name}</CanAccessLink>
            </td>
            <td>{actor.hasSubmitted && <i className="material-icons">check</i>}</td>
            {(filteredDates || []).map((day: any, index: number) => (<td className="has-text-centered" key={index}>{(actor.availableDates || []).some((availableDate) => availableDate == day) && <span className="tag available-tag">Available</span>}</td>))}
          </tr>
        );
      })}
    </>
  );
}


export default AvailabilityActorsTable;
