import { Link } from "react-router-dom";
import AnonymousPage from "../anonymous-pages/anonymous-page";
import BoxWraper from "../box-wrapper";

function Confirmation(props) {

  const pricingPlan = +props.match.params.pricingPlan;

  return (
    <AnonymousPage>
      <BoxWraper width={5}>

        <p className="mb-1">Thank you for registering with Filmanize. You will shortly receive an email in which you will need to click to confirm your email address.</p>
        <p className="mb-1">In the meantime you can check out our tutorials <a href="https://www.youtube.com/watch?v=eOror_QjPZM&list=PLEGzR_hAtu4Jxatzc52ewuIuzgUHZIAKU&index=1" target="_blank">here</a>.</p>
        <p><Link to={'/login'}>Login</Link></p>

      </BoxWraper>
    </AnonymousPage>
  );
}

export default Confirmation;
