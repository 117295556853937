import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { sortByName } from "../../../../shared/utility";
import {
  FETCH_COLLABORTAROR_ROLES,
  FETCH_COLLABORTAROR_ROLE,
  UPDATE_COLLABORTAROR_ROLE,
  ADD_COLLABORTAROR_ROLE,
  DELETE_COLLABORTAROR_ROLE,
  CLEAR_COLLABORTAROR_ROLE_REDIRECT,
  CLEAR_COLLABORTAROR_ROLE,
  FETCH_COLLABORTAROR_ROLE_SECTIONS
} from "./actions";

interface ActionState {
  canAdd: boolean;
  roles: any[];
  sections: any[];
  loading: boolean;
  role: any;
  imageFiles: any[];
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'collaborator-roles-onboarding-step-1';

const defaultState: ActionState = {
  roles: [],
  sections: [],
  canAdd: false,
  loading: true,
  role: { sectionPermissions: {} },
  imageFiles: [],
  readOnly: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Section Permissions',
      content: `<p>Define which sections this role has access to.</p> 
                <p>Deny means the user with this role will not even see the section in the left hand menu.</p>
                <p>Read Only means the user with this role will only be able to view data, but not update it.</p>
                <p>Edit means the user with this role will be able to view and update data.</p>
                <p>Clicking on any of these headings will set all sections to that permission.</p>`,
      disableBeacon: true,
      nextButtonText: 'Get Started'
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_COLLABORTAROR_ROLES + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_COLLABORTAROR_ROLES + "_FULFILLED": {
      return {
        ...state,
        roles: action.payload.data.roles,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_COLLABORTAROR_ROLE_SECTIONS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_COLLABORTAROR_ROLE_SECTIONS + "_FULFILLED": {
      return {
        ...state,
        sections: action.payload.data,
        loading: false,
        errors: null
      };
    }

    case FETCH_COLLABORTAROR_ROLE + "_PENDING": {
      return {
        ...state,
        user: null,
        loadingUser: true
      };
    }

    case FETCH_COLLABORTAROR_ROLE + "_REJECTED": {
      return {
        ...state,
        loadingUser: false,
        errors: action.payload.data
      };
    }

    case FETCH_COLLABORTAROR_ROLE + "_FULFILLED": {
      let role = action.payload.data;

      return {
        ...state,
        role,
        errors: action.payload.data.errors,
        loadingUser: false
      };
    }

    case UPDATE_COLLABORTAROR_ROLE + "_PENDING": {
      return {
        ...state,
        user: action.payload,
        loadingUser: true,
        errors: null
      };
    }

    case UPDATE_COLLABORTAROR_ROLE + "_FULFILLED": {
      const role = { ...action.meta.role, ...action.payload.data.role };
      const roles: any = state.roles.map((c: any, index: number) => {
        if (c.id === role.id) {
          return { ...c, ...role };
        } else {
          return c;
        }
      });
      sortByName(roles);
      return {
        ...state,
        role,
        roles,
        loadingUser: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case UPDATE_COLLABORTAROR_ROLE + "_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loadingUser: false
      };
    }

    case ADD_COLLABORTAROR_ROLE + "_PENDING": {
      return {
        ...state,
        errors: null,
        loadingUser: true
      };
    }

    case ADD_COLLABORTAROR_ROLE + "_FULFILLED": {
      const role: any = action.payload.data.role;

      let roles = [role, ...state.roles];
      sortByName(roles);
      return {
        ...state,
        role,
        roles,
        loadingUser: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case ADD_COLLABORTAROR_ROLE + "_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loadingUser: false
      };
    }

    case DELETE_COLLABORTAROR_ROLE + "_PENDING": {
      return {
        ...state,
        user: action.payload,
        loadingUser: true,
        errors: null
      };
    }

    case DELETE_COLLABORTAROR_ROLE + "_FULFILLED": {
      const role = action.meta.role;
      const roles: any = [...state.roles].filter((c: any, index: number) => {
        return c.id !== role.id;
      });

      return {
        ...state,
        role: null,
        roles,
        loadingUser: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case DELETE_COLLABORTAROR_ROLE + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingUser: false
      };
    }

    case CLEAR_COLLABORTAROR_ROLE: {
      return {
        ...state,
        loading: false,
        role: { sectionPermissions: {} },
        errors: null
      };
    }

    case CLEAR_COLLABORTAROR_ROLE_REDIRECT: {
      return {
        ...state,
        role: { sectionPermissions: {} },
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
