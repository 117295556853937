import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../shared/private-route";
import UploadFilmScriptPage from "./index";

const ScriptRoutes = () => {
  return (
    <PrivateRoute
      exact
      path="/script/upload"
      component={UploadFilmScriptPage}
    />
  );
};

export default ScriptRoutes;
