import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import { CkEditorField } from "../../shared/controls/ckeditor-field";
import FilmanizeSelectField from "../../shared/filmanize-select-field";

import {
  renderTextField,
  renderSingleReactSelect,
  renderTextAreaField,
  renderCkEditorField,
} from "../../shared/form-helpers";
import TextInput from "../../shared/text-input";

const FaqLangauge = (props) => {
  const { languageOptions,
    readOnly,
    language,
    canChangeLanguage,
    getTranslation,
    removeLanguage,
    changeLanguage,
    changeLanguageQuestion,
    changeLanguageAnswer } = props;

  const onGetTranslation = (e) => {
    e.preventDefault();
    getTranslation(language.languageCode);
  };

  const onRemoveLanguage = () => {
    removeLanguage(language.id);
  };

  const onChangeLanguage = (value) => {
    changeLanguage(language.id, value);
  };

  const onChangeLanguageQuestion = (text) => {
    changeLanguageQuestion(language.id, text);
  };

  const onChangeLanguageAnswer = (value) => {
    changeLanguageAnswer(language.id, value);
  };

  return (
    <div>

      <div className="mt-1">

        <div>
          {!readOnly && (
            <a
              onClick={onRemoveLanguage}
              style={{ cursor: "pointer" }}>
              <i className="material-icons">delete</i>
            </a>
          )}
        </div>
        <div className="columns">
          <div className="column">

            <FilmanizeSelectField label="Language" value={language?.languageCode} onChange={onChangeLanguage} options={languageOptions} required />

            {language?.languageCode !== "en" && <button className="button mb-1" onClick={(e) => onGetTranslation(e)}>Translate</button>}

            <TextInput label="Question" value={language?.question} onChange={onChangeLanguageQuestion} />

            <CkEditorField label="Answer" value={language?.answer} onChange={onChangeLanguageAnswer} />


          </div>
        </div>
      </div>
      {/* {error && <li className="error">{error}</li>} */}
    </div>)
};

export default FaqLangauge;