import { getAnnonymousApiClient } from "./../../../../shared/api";

export const FETCH_CONFIRM_CALL_SHEET = "FETCH_CONFIRM_CALL_SHEET";

export function confirm(token) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CONFIRM_CALL_SHEET,
      payload: getAnnonymousApiClient().post(`/callsheet/confirm`, { token }),
    });
  };
}
