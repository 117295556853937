import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "./../../../notifications/ducks/actions";
import { getActionToUploadImagesAndSaveEntity } from "./../../../shared/image-action-creator";

export const FETCH_CHARACTER_RATE = "FETCH_CHARACTER_RATE";
export const CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER = "CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER";

export function fetchCharacters(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHARACTERS",
      payload: getApiClient().get(`/film/${filmId}/character`)
    });
  };
}

export function fetchCharacterRate(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CHARACTER_RATE,
      payload: getApiClient().get(`/film/${filmId}/backgroundCharacter/rate?isBackground=false`)
    });
  };
}

export function initialiseCharacter() {
  return (dispatch) => {
    dispatch({
      type: "INITIALISE_CHARACTER"
    });
  };
}

export function setName(name) {
  return (dispatch) => {
    dispatch({
      type: "CHARACTER_SET_NAME",
      meta: { name }
    });
  };
}

export function getGender(name) {
  return (dispatch) => {
    dispatch({
      type: "CHARACTER_GET_GENDER",
      meta: { name },
      payload: getApiClient().get(`/lookup/CharacterNameGender/${name}`)
    });
  };
}

export function clearCharacter() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_CHARACTER"
    });
  };
}

export function fetchCharacter(filmId, characterId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_CHARACTER",
      payload: getApiClient().get(`/film/${filmId}/character/${characterId}`)
    });
  };
}

export function updateCharacter(filmId, character) {
  return (dispatch) => {
    updateCharacterWithImageUrls(dispatch, character, filmId);
  };
}

function updateCharacterWithImageUrls(dispatch, character, filmId) {
  const redirect = character.actorId == character.originalActorId;
  dispatch({
    type: "UPDATE_SINGLE_CHARACTER",
    payload: getApiClient().put(
      `/film/${filmId}/character/${character.id}`,
      character
    ),
    meta: { character, redirect }
  })
    .then((response) => {
      if (character.actorId != character.originalActorId) {
        if (!character.actorId) {
          dispatch({
            type: "UPDATE_SINGLE_CHARACTER_UNASSIGN_ACTOR",
            payload: getApiClient().put(
              `/film/${filmId}/character/${character.id}/UnAssignActor`
            ),
            meta: { character }
          })
            .then((response) => {
              dispatch({ type: "SINGLE_CHARACTER_REDIRECT_TO_LIST" });
              dispatch(showInfo(`Updated character ${character.name}`));
            })
            .catch((response) => {
              dispatch(showError(`Error updating character ${character.name}`));
            })
            .then((response) => {
              dispatch(clearNotifications());
            });
        } else {
          dispatch({
            type: "UPDATE_SINGLE_CHARACTER_ASSIGN_ACTOR",
            payload: getApiClient().put(
              `/film/${filmId}/character/${character.id}/AssignActor`,
              {
                filmId,
                characterId: character.id,
                actorId: character.actorId
              }
            ),
            meta: { character }
          })
            .then((response) => {
              dispatch({ type: "SINGLE_CHARACTER_REDIRECT_TO_LIST" });
              dispatch(showInfo(`Updated character ${character.name}`));
            })
            .catch((response) => {
              dispatch(showError(`Error updating character ${character.name}`));
            })
            .then((response) => {
              dispatch(clearNotifications());
            });
        }
      } else {
        dispatch({ type: "SINGLE_CHARACTER_REDIRECT_TO_LIST" });
        dispatch(showInfo(`Updated character ${character.name}`));
      }
    })
    .catch((response) => {
      dispatch(showError(`Error updating character ${character.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function addCharacter(filmId, character) {
  return (dispatch) => {
    addCharacterWithImageUrls(dispatch, character, filmId);
  };
}

function addCharacterWithImageUrls(dispatch, character, filmId) {
  dispatch({
    type: "ADD_SINGLE_CHARACTER",
    payload: getApiClient().post(`/film/${filmId}/character/`, character),
    meta: { character }
  })
    .then((response) => {
      const characterId = response.value.data.character.id;
      if (character.actorId) {
        dispatch({
          type: "UPDATE_SINGLE_CHARACTER_ASSIGN_ACTOR",
          payload: getApiClient().put(
            `/film/${filmId}/character/${characterId}/AssignActor`,
            {
              filmId,
              characterId,
              actorId: character.actorId
            }
          ),
          meta: { character }
        });
      }

      dispatch(showInfo(`Added character ${character.name}`));
    })
    .catch((response) => {
      dispatch(showError(`Error adding character ${character.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function deleteCharacter(filmId, character) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_CHARACTER",
      payload: getApiClient().delete(
        `/film/${filmId}/character/${character.id}`,
        { data: { eTag: character.eTag } }
      ),
      meta: { character }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted character ${character.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting character ${character.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changeCharacterToBackgroundCharacter(filmId, character) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER,
      payload: getApiClient().put(`/film/${filmId}/character/${character.id}/ChangeToBackgroundCharacter`, character),
      meta: { character }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Character ${character.name} to Background Character`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Character ${character.name} to Background Character`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function initAddLocationForm() {
  return (dispatch) => {
    dispatchClearRedirect(dispatch);
  };
}

export function initEditLocationForm() {
  return (dispatch) => {
    dispatchClearRedirect(dispatch);
  };
}

function dispatchClearRedirect(dispatch) {
  dispatch({
    type: "CLEAR_REDIRECT"
  });
}

export const uploadImagesAndSaveCharacter = getActionToUploadImagesAndSaveEntity(
  addCharacterWithImageUrls,
  updateCharacterWithImageUrls
);

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}
