import React from "react";
import { Route } from "react-router-dom";
import ListPropsContainer from "./list-container";
import AddProp from "./add";
import EditProp from "./edit";
import PropScenes from "./scenes";
import PropMenu from "./menu";
import Overview from "./overview/container";
import { PrivateRoute } from "../../../shared/private-route";

const PropRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/props"
        component={ListPropsContainer}
      />
      <PrivateRoute path="/films/:filmId/props/:propId" component={PropMenu} />
      <PrivateRoute exact path="/films/:filmId/props/add" component={AddProp} />
      <PrivateRoute exact path="/films/:filmId/props/overview" component={Overview} />
      <PrivateRoute
        exact
        path="/films/:filmId/props/:propId"
        component={EditProp}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/props/:propId/edit"
        component={EditProp}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/props/:propId/scenes"
        component={PropScenes}
      />
    </>
  );
};

export default PropRoutes;
