import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "./../../../shared/loader";
import confirm from "../../../shared/modal/confirm";
import { fetchCharacters } from "./../../characters/ducks/actions";
import {
  clearRedirect,
  deleteCharacter,
  fetchCharacter,
  updateCharacter,
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function CharactersContainer(props) {
  const { filmId, sceneId, characterId } = props.match.params;
  const {
    fetchCharacter,
    fetchCharacters,
    updateCharacter,
    deleteCharacter,
    loadingCharacter,
    character,
    errors,
  } = props;

  useEffect(() => {
    if (characterId !== "add") {
      fetchCharacter(filmId, sceneId, characterId);
      fetchCharacters(filmId);
    }
  }, [filmId, characterId]);

  if (props.redirect) {
    props.clearRedirect();
    if (props.location?.state?.fromBreakdown) {
      const url = `/films/${filmId}/breakdown/scenes/${sceneId}`;
      return <Redirect to={url} />;
    } else if (props.location?.state?.fromBreakdownOverview) {
      const url = `/films/${filmId}/breakdown/overview`;
      return <Redirect to={url} />;
    } else if (props.redirect.to == "list") {
      const url = `/films/${filmId}/scenes/${sceneId}/characters`;
      return <Redirect to={url} />;
    }
  }

  if (characterId == "add") {
    return null;
  }

  if (props.redirect) {
    const url = `/films/${filmId}/scenes/${sceneId}/characters`;
    return <Redirect to={url} />;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacter(character) {
    return (
      <ScrollIntoView
        className="blade-content"
        path="/films/:filmId/scenes/:sceneId/characters/:characterId/edit"
        loading={loadingCharacter}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          characterId={character.id}
          initialValues={character}
          characters={props.characters}
          loading={loadingCharacter}
          onSubmit={onUpdateCharacter}
          handleDelete={onDeleteCharacter}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateCharacter(character) {
    sanitiseDataTypes(character);
    updateCharacter(filmId, sceneId, character);
  }

  function onDeleteCharacter(character) {
    confirm(() => deleteCharacter(filmId, sceneId, character), character?.characterName, `Are you sure you want to remove ${character?.characterName} from the scene?`, "Remove");
  }

  function sanitiseDataTypes(character) { }

  return props.character ? renderCharacter(props.character) : renderLoading();
}

function mapStateToProps(state) {
  return {
    characters: state.charactersState.rootState.characters,
    character: state.scenesState.characterState.character,
    errors: state.scenesState.characterState.errors,
    redirect: state.scenesState.characterState.redirect,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchCharacter,
    updateCharacter,
    deleteCharacter,
    fetchCharacters,
    clearRedirect,
  })(CharactersContainer),
);
