import { UPDATE_PERSON_MESSAGE_EMAIL_STATUS, UPDATE_PERSON_MESSAGE_PROGRESS } from "../../../../middleware/messageSignalRMiddleware";
import { Actor, BackgroundActor, CrewMember } from "../../../shooting-days/messages/ducks/reducer";
import {
  FETCH_PERSON_POTENTIAL_DATES,
  SEND_POTENTIAL_DATES,
  SEND_POTENTIAL_DATES_TO_PERSON,
  SEND_POTENTIAL_DATES_TO_SELF
} from "./actions";

interface SchedulingState {
  peopleDetailsProgress: any,
  loading: boolean;
  saving: boolean;
  readOnly: boolean;
  progress: any;
  pristine: boolean;
}

const defaultState: SchedulingState = {
  peopleDetailsProgress: {},
  loading: true,
  saving: false,
  readOnly: false,
  progress: null,
  pristine: true
};

const updatePersonStatus = (person, personId, status, statusDescription, statusChangedDate) => {
  if (person.id === personId) {
    if (status > person.status || status == 1) {
      person.status = status;
      person.statusDescription = statusDescription;
    }
    person.lastStatusChanged = statusChangedDate;
    person.sending = false;
  } else {
    person.disabled = false;
  }
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_PERSON_POTENTIAL_DATES + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_PERSON_POTENTIAL_DATES + "_FULFILLED": {
      return {
        ...state,
        peopleDetailsProgress: action.payload.data,
        loading: false
      };
    }

    case FETCH_PERSON_POTENTIAL_DATES + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case SEND_POTENTIAL_DATES + "_PENDING": {
      const peopleDetailsProgress = { ...state.peopleDetailsProgress };

      peopleDetailsProgress.actors.forEach(actor => {
        actor.sending = true;
      });
      peopleDetailsProgress.backgroundActors.forEach(backgroundActor => {
        backgroundActor.sending = true;
      });

      peopleDetailsProgress.crewMembers.forEach(crewMember => {
        crewMember.sending = true;
      });

      return {
        ...state,
        errors: null,
        peopleDetailsProgress: peopleDetailsProgress,
        progress: { progress: null, message: "Queued for sending" }
      };
    }


    case SEND_POTENTIAL_DATES + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        pristine: true
      };
    }

    case SEND_POTENTIAL_DATES + "_REJECTED": {
      return {
        ...state,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case SEND_POTENTIAL_DATES_TO_PERSON + "_PENDING": {
      const { personId, personType } = action.meta;
      const peopleDetailsProgress = { ...state.peopleDetailsProgress };
      if (personType === Actor) {
        peopleDetailsProgress.actors.forEach((actor: any) => {
          if (actor.id === personId) {
            actor.sending = true;
          } else {
            actor.disabled = true;
          }
        });
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = true;
        });
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = true;
        });
      } else if (personType === BackgroundActor) {
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          if (backgroundActor.id === personId) {
            backgroundActor.sending = true;
          } else {
            backgroundActor.disabled = true;
          }
        });

        peopleDetailsProgress.actors.forEach((actor: any) => {
          actor.disabled = true;
        });
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = true;
        });
      } else if (personType === CrewMember) {
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          if (crewMember.id === personId) {
            crewMember.sending = true;
          } else {
            crewMember.disabled = true;
          }
        });

        peopleDetailsProgress.actors.forEach((actor: any) => {
          actor.disabled = true;
        });
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = true;
        });
      }


      return {
        ...state,
        errors: null,
        peopleDetailsProgress: peopleDetailsProgress,
        progress: { progress: null, message: "Queued for sending" }
      };
    }

    case SEND_POTENTIAL_DATES_TO_PERSON + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }


    case SEND_POTENTIAL_DATES_TO_SELF + "_PENDING": {
      return {
        ...state,
        errors: null,
        progress: { progress: null, message: "Queued for sending" }
      };
    }

    case SEND_POTENTIAL_DATES_TO_SELF + "_FULFILLED": {
      return {
        ...state,
        errors: null,
        saved: true
      };
    }

    case SEND_POTENTIAL_DATES_TO_SELF + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }

    case SEND_POTENTIAL_DATES_TO_PERSON + "_FULFILLED": {
      return {
        ...state,
        errors: null,
        peopleDetailsProgress: { ...state.peopleDetailsProgress, sendToSelf: false, sendToMembers: false, sendToPerson: null },
        saved: true
      };
    }

    case UPDATE_PERSON_MESSAGE_EMAIL_STATUS: {
      const { messageType, personId, personType, status, statusDescription, statusChangedDate } = action.meta;
      if (messageType !== "Availability") {
        return state;
      }

      const peopleDetailsProgress = { ...state.peopleDetailsProgress };
      if (personType === Actor) {
        peopleDetailsProgress.actors.forEach((actor: any) => {
          updatePersonStatus(actor, personId, status, statusDescription, statusChangedDate);
        });

        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = false;
        });
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = false;
        });
      } else if (personType === BackgroundActor) {
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          updatePersonStatus(backgroundActor, personId, status, statusDescription, statusChangedDate);
        });

        peopleDetailsProgress.actors.forEach((actor: any) => {
          actor.disabled = false;
        });
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = false;
        });
      } else if (personType === CrewMember) {
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          updatePersonStatus(crewMember, personId, status, statusDescription, statusChangedDate);
        });

        peopleDetailsProgress.actors.forEach((actor: any) => {
          actor.disabled = false;
        });
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = false;
        });
      }

      return {
        ...state,
        peopleDetailsProgress
      };
    }

    case UPDATE_PERSON_MESSAGE_PROGRESS: {
      if (action.meta.messageType !== "Availability") {
        return state;
      }

      return {
        ...state,
        progress: action.meta.progress
      };
    }

    default:
      return state;
  }
};

export default reducer;