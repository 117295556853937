import { useTranslation } from 'react-i18next';
import SmallLoader from "../../../shared/small-loader/small-loader";
import { useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderTextAreaField,
    renderCheckboxGroup,
    renderDietaryRequirements,
    renderMilkTypesCheckboxGroup
} from "../../../shared/form-helpers";
import FilmanizeCheckbox from '../../../shared/filmanize-checkbox';
import { connect } from 'react-redux';

let DietaryRequirementsForm = (props) => {
    const { dietaryRequirements,
        loading,
        loadingEnums,
        saving,
        saved,
        languageCode,
        handleSubmit,
        dietaryRequirementEnum,
        pristine,
        dietaryRequirementValue,
        dietaryRequirementDetailsValue,
        drinks,
        milkTypes,
        selectedDrinks,
        showDrinks } = props;
    const { t, i18n } = useTranslation(['dietary-requirements']);

    useEffect(() => {
        if (languageCode) {
            i18n.changeLanguage(languageCode);
        }
    }, [languageCode]);

    useEffect(() => {
        if (!languageCode && !loading && dietaryRequirements) {
            i18n.changeLanguage(dietaryRequirements.filmLanguageCode);
        }
    }, [loading, dietaryRequirements]);

    let thisActor = props.initialValues || {};


    const noDietaryRequirements = dietaryRequirementValue?.length == 0;

    const drinkOptions = (drinks || []).map((item) => { return { value: item.id, name: item.name } });
    const milkTypeOptions = (milkTypes || []).map((item) => { return { value: item.id, name: item.name, vegan: item.vegan } });

    let showMilk = false;
    if (selectedDrinks) {
        selectedDrinks.forEach(drinkId => {
            const drink = drinks.find(d => d.id === drinkId);
            if (drink) {
                if (drink.canHaveMilk) {
                    showMilk = true;
                }
            }
        });
    }

    const showDairyMilk = !dietaryRequirementValue?.some(r => r === 2 || r === 8);


    return (
        <div>
            <h2 className="menu-label">{t("Dietary Requirements")}</h2>
            {(loading || loadingEnums) && <span>{t("Loading")}... <SmallLoader /></span>}
            {saved && <div>{t("Thank you for submitting your dietary requirements.")}</div>}
            {!loading && !saved &&
                <>
                    <label className="label">{dietaryRequirements.personName}</label>
                    <label className="label">{dietaryRequirements.filmName}</label>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <Field
                            label={t("Dietary requirements")}
                            name="dietaryRequirementsArray"
                            component={renderDietaryRequirements}
                            options={dietaryRequirementEnum}
                            value={thisActor.dietaryRequirements}
                            dietaryRequirementDetailsValue={dietaryRequirementDetailsValue}
                            disabled={saving}
                        />

                        <Field
                            label={t("Dietary requirements Details")}
                            name="dietaryRequirementDetails"
                            component={renderTextAreaField}
                            readOnly={saving}
                        />

                        {showDrinks && <>
                            <Field
                                label="Drinks"
                                name="drinkIds"
                                component={renderCheckboxGroup}
                                options={drinkOptions}
                            />
                            {showMilk &&
                                <Field
                                    label="Milk Types"
                                    name="milkTypeIds"
                                    component={renderMilkTypesCheckboxGroup}
                                    showDairy={showDairyMilk}
                                    options={milkTypeOptions}
                                />
                            }
                        </>}
                        <button
                            type="submit"
                            className={
                                saving ? "button is-primary is-loading" : "button is-primary"
                            }
                            disabled={saving}>
                            {t("Save")}
                        </button>
                    </form>
                </>}
        </div>
    )
};




DietaryRequirementsForm = reduxForm({
    form: "dietaryRequirementsForm",
    enableReinitialize: true
})(DietaryRequirementsForm);

// Decorate with connect to read form values
const selector = formValueSelector('dietaryRequirementsForm'); // <-- same as form name
const DietaryRequirementsFormConnect = connect(
    state => {
        // can select values individually
        const selectedDrinks = selector(state, 'drinkIds')
        const dietaryRequirementValue = selector(state, 'dietaryRequirementsArray');
        const dietaryRequirementDetailsValue = selector(state, 'dietaryRequirementDetails');
        return {
            dietaryRequirementValue,
            dietaryRequirementDetailsValue,
            selectedDrinks
        }
    }
)(DietaryRequirementsForm)

export default DietaryRequirementsFormConnect
