import React from "react";
import { CSVLink, CSVDownload } from 'react-csv';
import { Field, reduxForm, FieldArray } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "../../shared/errors";
import { Map1 } from "./map";

function UsersForm(props) {
  const {
    handleSubmit,
    errors,
    submitFailed,
    invalid,
    countries,
    refresh,
    error
  } = props;

  const csvData = countries?.map((country: any) => {
    return [country.countryCode, country.firstDate, country.lastDate, country.userCount, country.userPercentage, country.dateStartPercentage, country.dateEndPercentage];
  });

  return (
    <>
      <h2 className="menu-label">User Countries</h2>
      {errors && <Errors errors={errors} />}
      <button className="button mb-1" onClick={refresh}>Refresh</button>
      <br />
      <Map1 countries={countries} />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Country Code</th>
                <th>firstDate</th>
                <th>lastDate</th>
                <th>UserCount</th>
                <th>UserPercentage</th>
                <th>DateStartPercentage</th>
                <th>DateEndPercentage</th>
              </tr>
            </thead>
            <tbody>
              {(countries || []).map((country, idx, records) => {
                return (
                  <tr key={idx}>
                    <td>{country.countryCode}</td>
                    <td>{country.firstDate}</td>
                    <td>{country.lastDate}</td>
                    <td>{country.userCount}</td>
                    <td>{country.userPercentage}</td>
                    <td>{country.dateStartPercentage}</td>
                    <td>{country.dateEndPercentage}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {csvData && <CSVLink className="button mb-3 mt-1" data={csvData} filename={`User Countries ${convertDateToUTCISO(new Date())}`}>Export</CSVLink>}
          {error && <div className="error">{error}</div>}
        </div>
        <div>
          Total Countries: {countries?.length}
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export const convertDateToUTCISO = (date: Date) => {
  var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(),
    date.getDate(), 0,
    0, 0));
  return utcDate.toISOString().substring(0, 10);
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true
})(UsersForm);
