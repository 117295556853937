import actionTypes from "./action-types";

export function showInfo(message) {
  return {
    type: actionTypes.SHOW_INFO_NOTIFICATION,
    payload: { type: "info", message },
  };
}

export function showError(message) {
  return {
    type: actionTypes.SHOW_ERROR_NOTIFICATION,
    payload: { type: "error", message },
  };
}

export function showWarning(message) {
  return {
    type: actionTypes.SHOW_WARNING_NOTIFICATION,
    payload: { type: "warning", message },
  };
}

export function clearNotifications() {
  return {
    type: actionTypes.CLEAR_NOTIFICATIONS,
    payload: null,
  };
}
