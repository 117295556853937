import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import { fetchTasks,
        fetchTaskBoard,
         moveTask, 
         addTask, 
         editTask,
         deleteTask,
         taskTextChanged,
         taskTextFinishedEditing,
         showMenuOnTask,
         assignTask, 
         fetchAssignedCollaborators } from "./ducks/actions";
import Tasks from "./tasks";

const TasksContainer = (props) => {
    const {         
        fetchAssignedCollaborators,
        fetchTaskBoard,
        moveTask,
        addTask,
        editTask,
        deleteTask,
        taskTextChanged,
        taskTextFinishedEditing,
        tasks,
        columns,
        columnOrder,
        errors,
        loading,
        showMenuOnTaskId,
        showMenuOnTask,
        collaborators,
        assignTask,
        readOnly
     } = props;
    const filmId = props.match.params.filmId;

    useEffect(() => {
      fetchTaskBoard(filmId);
      fetchAssignedCollaborators(filmId);
    }, [filmId]);

  const onTaskTextFinishedEditing = (columnId, task) => {
    taskTextFinishedEditing(filmId, columnId, task);
  };

  const onMoveTask = (destination, source, draggableId) => {
    moveTask(filmId, destination, source, draggableId);
  };

  const onDeleteTask = (columnId, task, originalIndex) => {
    deleteTask(filmId, columnId, task, originalIndex);
  };

  const onAssignTask = (taskId, collaboratorId, isOwner) => {
    assignTask(filmId, taskId, collaboratorId, isOwner);
  }

    return (
        <>
          {loading && <Loader />}
          {!loading && <Tasks
              loading={loading}
              tasks={tasks}
              columns={columns}
              columnOrder={columnOrder}
              moveTask={onMoveTask}
              addTask={addTask}
              taskTextChanged={taskTextChanged}
              errors={errors}
              taskTextFinishedEditing={onTaskTextFinishedEditing}
              editTask={editTask} 
              deleteTask={onDeleteTask}
              showMenuOnTaskId={showMenuOnTaskId}
              showMenuOnTask={showMenuOnTask}
              collaborators={collaborators}
              assignTask={onAssignTask}
              readOnly={readOnly}
            />}
        </>
      );
}

function mapStateToProps(state) {
    const { tasksState } = state;
    return {
      errors: tasksState.errors,
      loading: tasksState.loading,
      tasks: tasksState.tasks,
      columns: tasksState.columns,
      columnOrder: tasksState.columnOrder,
      showMenuOnTaskId: tasksState.showMenuOnTaskId,
      collaborators: tasksState.collaborators,
      readOnly: tasksState.readOnly
    };
  }
  
  export default connect(mapStateToProps, { 
    fetchTasks,
    fetchTaskBoard,
    fetchAssignedCollaborators,
    moveTask,
    addTask,
    editTask,
    deleteTask,
    taskTextChanged,
    taskTextFinishedEditing,
    showMenuOnTask,
    assignTask
 })(TasksContainer);
  