import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "../../../shared/loader";
import {
  assignScenesToBackgroundCharacter,
  fetchAllScenes,
  fetchAllSceneSettings,
  fetchScenesAssignedToBackgroundCharacter,
  setSceneAssignedBackgroundCharacter,
  findInScript,
  clearSceneSearchResults,
  fetchScriptDays
} from "./ducks/actions";

import { clearRedirect } from "../ducks/actions";

import {
  fetchBackgroundCharacter
} from "../ducks/actions";
import Form from "./scenes-form";
import {
  searchScenes,
  SEARCH_SCENES_FOR_BACKGROUND_CHARACTER
} from "../../scenes/search/ducks/actions";
import SearchScenesForm from "../../scenes/search/search-scenes-form";
import ScriptSearch from "../../script/search/script-search";

function CharacterScenes(props) {
  const backgroundCharacterId = props.backgroundCharacterId || props.match.params.backgroundCharacterId;
  const filmId = props.filmId || props.match.params.filmId;
  const {
    fetchBackgroundCharacter,
    fetchAllScenes,
    fetchAllSceneSettings,
    fetchScenesAssignedToBackgroundCharacter,
    setSceneAssignedBackgroundCharacter,
    fetchScriptDays,
    findInScript,
    backgroundCharacter,
    assignScenesToBackgroundCharacter,
    loadingCharacter,
    allScenes,
    searchScenes,
    clearSceneSearchResults,
    errors,
    sceneId,
    embedded,
    findingInScript,
    searchingScenes,
    redirect,
    clearRedirect
  } = props;

  const invalidIds = ["add", "scenes"];
  const readOnly = backgroundCharacter?.readOnly;
  useEffect(() => {
    fetchBackgroundCharacter(filmId, backgroundCharacterId);
    if (!sceneId) {
      fetchScenesAssignedToBackgroundCharacter(filmId, backgroundCharacterId);
    }
  }, [filmId, backgroundCharacterId]);

  useEffect(() => {
    fetchAllScenes(filmId);
    fetchAllSceneSettings(filmId);
    fetchScriptDays(filmId);
  }, [filmId]);

  useEffect(() => {
    if (sceneId) {
      setSceneAssignedBackgroundCharacter(sceneId);
    }
  }, [sceneId]);

  if (!embedded && redirect) {
    if (redirect.to === "list") {
      clearRedirect();
      const url = `/films/${filmId}/background-characters/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(backgroundCharacterId) > -1) {
    return null;
  }

  const onFindInScript = (e) => {
    e.preventDefault();
    findInScript(filmId, backgroundCharacter.name);
  };

  function renderLoading() {
    return <Loader />;
  }

  console.log("allScenes", allScenes);

  function renderForm() {
    return (
      <div className="blade-content">
        <h2 className="menu-label">Background Character Scenes</h2>

        {!readOnly && <div className="box mt-1">
          <SearchScenesForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            loading={loadingCharacter}
            onSubmit={onSearchScenes}
            findInScript={onFindInScript}
            allSceneSettings={props.allSceneSettings}
            scriptDays={props.scriptDays}
            backgroundCharacterName={backgroundCharacter.name}
            searchingScenes={searchingScenes}
            scenes={allScenes}
            showScriptDays={true}
            showSceneSettings={true}
          />
        </div>}
        {!readOnly && <div className={`box mt-1`}>
          <ScriptSearch filmId={filmId} text={backgroundCharacter?.name} typeToDispatch="BACKGROUND_CHARACTER_SEARCH_SCRIPT" findingInScript={findingInScript} />
        </div>}
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ selectedSceneIds: props.selectedSceneIds }}
          loading={loadingCharacter}
          onSubmit={onUpdateBackgroundCharacterScenes}
          reduxFormValues={props.reduxFormValues}
          allScenes={props.allScenes}
          searchedSceneIds={props.searchedScenes?.map((s) => s.id)}
          selectSearchedScenes={props.selectSearchedScenes}
          clearSceneSearchResults={clearSceneSearchResults}
          readOnly={readOnly}
        />
      </div>
    );
  }

  function onSearchScenes(params) {
    searchScenes(
      SEARCH_SCENES_FOR_BACKGROUND_CHARACTER,
      filmId,
      params);
  }

  function onUpdateBackgroundCharacterScenes(backgroundCharacterScenes) {
    assignScenesToBackgroundCharacter(filmId, backgroundCharacterId, backgroundCharacterScenes.selectedSceneIds);
  }

  return allScenes?.length && backgroundCharacter ? renderForm() : renderLoading();
}

function mapStateToCharacters(state) {
  const { backgroundCharactersState, form } = state;
  const backgroundCharacterRootState = backgroundCharactersState.rootState;
  const backgroundCharacterScenesState = backgroundCharactersState.scenesState;
  return {
    backgroundCharacter: backgroundCharacterRootState.backgroundCharacter,
    allScenes: backgroundCharacterScenesState.scenes,
    allSceneSettings: backgroundCharacterScenesState.sceneSettings,
    selectedSceneIds: backgroundCharacterScenesState.selectedSceneIds,
    searchedScenes: backgroundCharacterScenesState.searchedScenes,
    selectSearchedScenes: backgroundCharacterScenesState.selectSearchedScenes,
    scriptDays: backgroundCharacterScenesState.scriptDays,
    errors: backgroundCharacterScenesState.errors,
    redirect: backgroundCharacterScenesState.redirect,
    findingInScript: backgroundCharacterScenesState.findingInScript,
    searchingScenes: backgroundCharacterScenesState.searchingScenes,
    loadingCharacter: backgroundCharacterScenesState.loading || backgroundCharacterRootState.loading,
    reduxFormValues: form?.backgroundCharacterScenesForm?.values,
  };
}

export default
  connect(mapStateToCharacters, {
    fetchAllScenes,
    fetchAllSceneSettings,
    fetchScenesAssignedToBackgroundCharacter,
    setSceneAssignedBackgroundCharacter,
    fetchScriptDays,
    assignScenesToBackgroundCharacter,
    searchScenes,
    findInScript,
    clearSceneSearchResults,
    fetchBackgroundCharacter,
    clearRedirect
  })(CharacterScenes);
