import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../../shared/loader";
import {
  fetchCostumeOverview
} from "./ducks/actions";
import Overview from "./overview";

const OverviewContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchPropOverview,
    overview,
    loading,
    errors,
    warnings

  } = props;

  useEffect(() => {
    fetchPropOverview(filmId);
  }, [filmId, fetchPropOverview]);

  function renderOverview(overview) {

    return (
      <ScrollIntoView
        className="blade-content wider"
        path="/films/:filmId/costumes/overview"
        loading={loading}>
        <h2 className="menu-label">Overview</h2>
        <Overview
          overview={overview}
          filmId={filmId}
          errors={errors}
          warnings={warnings}
        ></Overview>
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !overview) && <Loader />}
      {!loading && overview && renderOverview(overview)}
    </>
  );
};

function mapStateToProps(state) {
  const { errors, warnings, overview, loading } =
    state.costumesOverviewState;
  return {
    errors,
    warnings,
    overview,
    loading
  };
}

export default connect(mapStateToProps, {
  fetchPropOverview: fetchCostumeOverview
})(OverviewContainer);
