import { useEffect } from "react";
import { connect } from "react-redux";
import BoxWraper from "../../../account/box-wrapper";
import Loader from "../../../shared/loader";
import {  
  fetchFeatureRequestView,
  voteForFeatureRequest, 
  updateFeatureRequestComment,
  setEditComment,
  deleteFeatureRequestComment
  } from "../ducks/actions";
import View from "./view";

const FeatureRequestContainer = (props) => {
 const { 
        fetchFeatureRequestView, 
        featureRequest, 
        voteForFeatureRequest,
        errors,
        loading,
        voteForLoading,
        updateFeatureRequestComment,
        setEditComment,
        deleteFeatureRequestComment } = props;

const featureRequestId = props.match.params.featureRequestId;

 useEffect(() => {
  if (featureRequestId !== "add") {
    fetchFeatureRequestView(featureRequestId);
  }
 }, [featureRequestId]);

 if (featureRequestId === "add") {
  return null;
 }

 const upVote = () => {  
  voteForFeatureRequest(featureRequestId, true);
 };

 const downVote = () => {  
  voteForFeatureRequest(featureRequestId, false);
 };

 return (    
    <BoxWraper width={5}>
        {loading && <Loader/>}        
        <View 
          featureRequest={featureRequest} 
          upVote={upVote} 
          downVote={downVote} 
          voteForLoading={voteForLoading} 
          errors={errors}
          updateFeatureRequestComment={updateFeatureRequestComment}
          deleteFeatureRequestComment={deleteFeatureRequestComment}
          setCommentEditMode={setEditComment} />        
    </BoxWraper>
  );
}

function mapStateToChangeLogs(state) {
    const { featureRequestState } = state;
    return {
      loading: featureRequestState.loading,
      voteForLoading: featureRequestState.voteForLoading,
      errors: featureRequestState.errors,
      featureRequest : featureRequestState.featureRequest
    };
  }
  
  export default connect(mapStateToChangeLogs, {
    fetchFeatureRequestView,
    voteForFeatureRequest,
    updateFeatureRequestComment,
    setEditComment,
    deleteFeatureRequestComment
  })(FeatureRequestContainer);
  