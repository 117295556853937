import React from "react";
import { Switch, Route } from "react-router-dom";
import MemberMenu from "./menu";
import ListMembersContainer from "./list-container";
import AddMember from "./add";
import EditMember from "./edit";
import AddExistingMember from "./add-existing";
import { PrivateRoute } from "../../../../../shared/private-route";

const MemberRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/departments/:departmentId/roles/:roleId/members"
        component={ListMembersContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/departments/:departmentId/roles/:roleId/members/add"
        component={AddMember}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/departments/:departmentId/roles/:roleId/members/add-existing"
        component={AddExistingMember}
      />
      {/* <Route path="/films/:filmId/departments/:departmentId/roles/:roleId/members/:memberId" component={MemberMenu} /> */}
      <PrivateRoute
        exact
        path="/films/:filmId/departments/:departmentId/roles/:roleId/members/:memberId"
        component={EditMember}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/departments/:departmentId/roles/:roleId/members/:memberId/edit"
        component={EditMember}
      />
    </>
  );
};

export default MemberRoutes;
