import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import {
  fetchUserCountriesByRegistrationDate
} from "./ducks/actions";
import Form from "./form";
//import { countries } from "./country-data";

function Register(props) {
  const {
    fetchUserCountriesByRegistrationDate,
    loading,
    errors,
    countries
  } = props;

  useEffect(() => {
    fetchUserCountriesByRegistrationDate();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const refresh = () => {
    fetchUserCountriesByRegistrationDate();
  };

  return (
    <div className="blade-content wideaf">
      <Form
        errors={errors}
        loading={false}
        initialValues={{ countries }}
        countries={countries}
        refresh={refresh}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { adminState } = state;
  return {
    countries: adminState.adminUserCountriesByDateState.countries,
    errors: adminState.adminUserCountriesByDateState.errors,
    loading: adminState.adminUserCountriesByDateState.loading
  };
}

export default connect(mapStateToProps, {
  fetchUserCountriesByRegistrationDate
})(Register);
