import React, { CSSProperties } from "react";
import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SceneSetting from "./scene-setting";
import Location from "./location";
import Errors from "../../../shared/errors";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName
} from "./ducks/reducer";
import HelpText from "../../../shared/help-text";

function Overview(props) {
  const {
    overview,
    locationsWithSubLocations,
    filmId,
    sceneSettingsAssignedPercentage,
    assignLocation,
    unassignLocation,
    replaceLocation,
    aliasSceneSetting,
    unaliasSceneSetting,
    errors
  } = props;

  const readOnly = overview.readOnly;

  function Charts() {
    return (
      <div className="columns">
        <div className="circular-progress column is-one-quarter">
          <FilmalityCircularProgress
            percentage={sceneSettingsAssignedPercentage}
            title="Scene Settings Assigned"
          />
        </div>
      </div>
    );
  }

  function RenderHelpText() {
    return (
      <HelpText>
        Here you can manage your Scene Settings:
        <ul>
          <li>
            Assign Locations to Scene Settings by dragging the location's name
            from the list on the right into the 'Location's Name' column for the
            Scene Setting.
          </li>
          <li>
            You can also replace a Location on a Scene Settings by dragging the
            new Location's name from the list on the right onto the existing
            Location name.
          </li>
          <li>
            Link duplicate Scene Settings (e.g. 'Bank', 'The Bank') by dragging
            the duplicate Scene Setting's name onto the preferred Scene
            Setting's name to create a link.
          </li>
        </ul>
        <p>
          Made a mistake with either of these? Click{" "}
          <i className="material-icons">cancel</i> to unassign.
        </p>
      </HelpText>
    );
  }

  function SceneSettingsList() {
    return (
      <table className={`${OnboardingStep1ClassName} table`}>
        <thead>
          <tr>
            <th>Scene Setting Name</th>
            <th>Location Name</th>
          </tr>
        </thead>
        <tbody>
          {(overview.sceneSettings || []).map((sceneSetting, index) => {
            return (
              <SceneSetting
                key={index}
                index={index}
                sceneSetting={sceneSetting}
                filmId={filmId}
                assignLocation={assignLocation}
                unassignLocation={unassignLocation}
                replaceLocation={replaceLocation}
                aliasSceneSetting={aliasSceneSetting}
                unaliasSceneSetting={unaliasSceneSetting}
                readOnly={readOnly}
                canAccessShootingLocations={overview?.canAccessShootingLocations}></SceneSetting>
            );
          })}
        </tbody>
      </table>
    );
  }

  function LocationsList() {
    return (
      <table className={`${OnboardingStep2ClassName} table sticky`}>
        <thead>
          <tr>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {(locationsWithSubLocations || []).map((location, index) => {
            return (
              <Location
                key={index}
                location={location}
                index={index}
                filmId={filmId}
                readOnly={readOnly}
                canAccessShootingLocations={overview?.canAccessShootingLocations}></Location>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div>
      <Charts />
      {errors && <Errors errors={errors} />}
      <DndProvider backend={HTML5Backend}>
        <div className="columns">
          <div className="column">
            <SceneSettingsList />
          </div>
          {!readOnly && (
            <div className="column is-narrow">
              <LocationsList />
            </div>
          )}
        </div>
      </DndProvider>
    </div>
  );
}

export default Overview;
