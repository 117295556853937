import React from "react";
import { Route } from "react-router-dom";
import ListCostumesContainer from "./list-container";
import AddCostume from "./add";
import EditCostume from "./edit";
import CostumeScenes from "./scenes";
import CostumeMenu from "./menu";
import Overview from "./overview/container";
import { PrivateRoute } from "../../../shared/private-route";

const CostumeRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/costumes"
        component={ListCostumesContainer}
      />
      <PrivateRoute
        path="/films/:filmId/costumes/:costumeId"
        component={CostumeMenu}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/costumes/add"
        component={AddCostume}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/costumes/overview"
        component={Overview}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/costumes/:costumeId"
        component={EditCostume}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/costumes/:costumeId/edit"
        component={EditCostume}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/costumes/:costumeId/scenes"
        component={CostumeScenes}
      />
    </>
  );
};

export default CostumeRoutes;
