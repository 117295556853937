export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="26.543" height="22.5" viewBox="0 0 22 18.72" {...props}>
    <g id="Group_3836" data-name="Group 3836" transform="translate(0.5 0.5)">
      <g id="Group_3834" data-name="Group 3834">
        <g id="Group_3833" data-name="Group 3833">
          <rect id="Rectangle_4231" data-name="Rectangle 4231" width="21" height="17.72" fill="none" strokeLinejoin="round" strokeWidth="1" />
        </g>
      </g>
      <g id="Group_3835" data-name="Group 3835" transform="translate(0.197 7.545)">
        <path id="Path_32839" data-name="Path 32839" d="M5.7,21.97l4.9-7.286,6.65,9.9" transform="translate(-5.697 -14.685)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_32840" data-name="Path 32840" d="M13.122,18.161l2.086-3.1,4.182,6.223" transform="translate(-5.697 -14.685)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
      <circle id="Ellipse_68" data-name="Ellipse 68" cx="2.814" cy="2.814" r="2.814" transform="translate(13.201 2.208)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      <line id="Line_705" data-name="Line 705" x2="10.883" transform="translate(9.837 14.388)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </g>
  </svg>
