import { v4 as uuidv4 } from 'uuid';

import { ADD_FAQ, CLEAR_FAQ, 
         CLEAR_REDIRECT_FAQ, 
         DELETE_FAQ, 
         FAQ_ADD_LANGUAGE, 
         FAQ_CHANGE_SECTION, 
         FAQ_GET_LANGUAGES, 
         FAQ_LANGUAGE_CHANGE_ANSWER, 
         FAQ_LANGUAGE_CHANGE_LANGUAGE, 
         FAQ_LANGUAGE_CHANGE_QUESTION, 
         FAQ_REMOVE_LANGUAGE, 
         FETCH_ADMIN_FAQ,
         FETCH_ADMIN_FAQS, 
         TRANSLATE_FAQ, 
         UPDATE_FAQ } from './actions';

interface ActionState {
  pristine: boolean,
  faqSections: any[];
  languages: any[];
  loading: boolean;
  faq: any;
  readOnly: boolean;
  redirect: any
}

const defaultState: ActionState = {
  pristine: true,
  faqSections: [],
  languages: [],
  loading: true,  
  faq: {},
  readOnly: false,
  redirect: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_ADMIN_FAQS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }
    
    case FETCH_ADMIN_FAQS + "_FULFILLED": {      
      return {
        ...state,
        faqSections: action.payload.data.faqSections,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FAQ_GET_LANGUAGES + "_FULFILLED" : {           
      return {
        ...state,
        languages: action.payload.data.languages
      };
    }

    case FAQ_GET_LANGUAGES + "_REJECTED" : {
      return {
        ...state,
        errors: action.payload.response.data.errors,
      };
    }

    case TRANSLATE_FAQ + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }
    
    case TRANSLATE_FAQ + "_FULFILLED" : {         
      const languageCode = action.meta.languageCode;
      const newFaq = {...state.faq};
      const translatedLanguage = newFaq.languages.find(l => l.languageCode === languageCode);
      if (translatedLanguage) {
          translatedLanguage.question = action.payload.data.translatedQuestion;
          translatedLanguage.answer = action.payload.data.translatedAnswer;
      }

      return {
        ...state,
       faq: newFaq,
       pristine: false,
       loading: false,
      };
    }

    case TRANSLATE_FAQ + "_REJECTED" : {
      return {
        ...state,
        errors: action.payload.response.data.errors,
      };
    }


    case FETCH_ADMIN_FAQ + "_PENDING" : {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_ADMIN_FAQ + "_FULFILLED": {            

      const faq = action.payload.data;
      faq.languages.forEach(language => language.id = uuidv4());

      return {
        ...state,
        faq: faq,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null,
        pristine: true
      };
    }
    
    case ADD_FAQ + "_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case ADD_FAQ + "_FULFILLED": {
      let faq: any = action.payload.data.faq;
      //let faqs = [faq, ...state.faqs];
      //sortByQuestion(faqs);

      return {
        ...state,
        //faq,
        //faqs,
        loading: false,
        errors: null,
        redirect: { to: "list" },
        pristine: true
      };
    }

    case ADD_FAQ + "_REJECTED": {
      return {
        ...state,
        faq: action.meta.faq,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case UPDATE_FAQ + "_PENDING" : {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case UPDATE_FAQ + "_FULFILLED": {

      //sortByReleaseDate(faqs);
      return {
        ...state,      
        loading: false,
        errors: null,
        redirect: { to: "list" },
        pristine: true
      };
    }

    case UPDATE_FAQ + "_REJECTED": {
      return {
        ...state,
        faq: action.meta.faq,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case DELETE_FAQ + "_PENDING" : {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case DELETE_FAQ + "_FULFILLED": {
      return {
        ...state,      
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case DELETE_FAQ + "_REJECTED": {
      return {
        ...state,
        faq: action.meta.faq,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }
 
    case CLEAR_FAQ :{            
      return {
        ...state,
        loading: false,
        faq: { section: null, languages:[] }, //{id:uuidv4(), languageCode: "en", question: "", answer: "" }
        errors: null,
        pristine: true
      };
    }

    case CLEAR_REDIRECT_FAQ: {
      return {
        ...state,
        redirect: null
      };
    }

    case FAQ_CHANGE_SECTION: {
      const newFaq = {...state.faq};      
      newFaq.section = action.meta.section;
      return {
        ...state,
        faq: newFaq,
        pristine: false
      };
    }    

    case FAQ_ADD_LANGUAGE: {
      const newFaq = {...state.faq};      
      if (newFaq.languages) {
        newFaq.languages.push({ id: uuidv4() });
      } else {
        newFaq.languages = [{ id: uuidv4() }];
      }
      return {
        ...state,
        faq: newFaq,
        pristine: false
      };
    }

    case FAQ_REMOVE_LANGUAGE: {      
      const { languageId } = action.meta;
      const newFaq = {...state.faq};      
      const languageIndex = newFaq.languages.findIndex(language => language.id === languageId);
      newFaq.languages.splice(languageIndex, 1);
      return {
        ...state,
        faq: newFaq,
        pristine: false
      };
    }

    case FAQ_LANGUAGE_CHANGE_LANGUAGE: {
      const { id, languageCode } = action.meta;
      const newFaq = {...state.faq};      
      const language = newFaq.languages.find(language => language.id === id);
      language.languageCode = languageCode
      return {
        ...state,
        faq: newFaq,
        pristine: false
      };
    }

    case FAQ_LANGUAGE_CHANGE_QUESTION: {
      const { id, question } = action.meta;
      const newFaq = {...state.faq};      
      const language = newFaq.languages.find(language => language.id === id);
      language.question = question
      return {
        ...state,
        faq: newFaq,
        pristine: false
      };
    }

    case FAQ_LANGUAGE_CHANGE_ANSWER: {
      const { id, answer } = action.meta;
      const newFaq = {...state.faq};      
      const language = newFaq.languages.find(language => language.id === id);
      language.answer = answer
      return {
        ...state,
        faq: newFaq,
        pristine: false
      };
    }

    default:
      return state;
  }
};

export function sortByQuestion(list) {
  list.sort((a, b) => {
    return a.question - b.question;
  });
}


export default reducer;
