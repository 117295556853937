import getApiClient, { getAnnonymousApiClient } from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export const FETCH_FEATURE_REQUESTS = "FETCH_FEATURE_REQUESTS";
export const SEARCH_FEATURE_REQUESTS = "SEARCH_FEATURE_REQUESTS";
export const VOTE_FOR_FEATURE_REQUEST = "VOTE_FOR_FEATURE_REQUEST";
export const UPDATE_FEATURE_REQUEST = "UPDATE_FEATURE_REQUEST";
export const CLEAR_EDIT_FEATURE_REQUEST_REDIRECT = "CLEAR_EDIT_FEATURE_REQUEST_REDIRECT";
export const CLEAR_EDIT_FEATURE_REQUEST_SENT = "CLEAR_EDIT_FEATURE_REQUEST_SENT";
export const ADD_FEATURE_REQUEST = "ADD_FEATURE_REQUEST";
export const ADD_FEATURE_REQUEST_COMMENT = "ADD_FEATURE_REQUEST_COMMENT";
export const SHOW_ADD_FEATURE_REQUEST_COMMENT = "SHOW_ADD_FEATURE_REQUEST_COMMENT";
export const UPDATE_FEATURE_REQUEST_COMMENT = "UPDATE_FEATURE_REQUEST_COMMENT";
export const EDIT_FEATURE_REQUEST_COMMENT = "EDIT_FEATURE_REQUEST_COMMENT";
export const DELETE_FEATURE_REQUEST_COMMENT = "DELETE_FEATURE_REQUEST_COMMENT";
export const FETCH_FEATURE_REQUEST_STATUSES = "FETCH_FEATURE_REQUEST_STATUSES";
export const FETCH_FEATURE_REQUEST_PRITOITIES = "FETCH_FEATURE_REQUEST_PRITOITIES";

export function fetchFeatureRequests(section) {
  return (dispatch) => {
    dispatch({
      type: FETCH_FEATURE_REQUESTS,
      payload: getApiClient().get(`/featurerequest`, {
        params: {
          section
        }
      })
    });
  };
}

export function searchFeatureRequests(section, searchString, statuses, orderBy, orderByDirection, startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_FEATURE_REQUESTS,
      payload: getApiClient().get(`/featurerequest/search`, {
        params: {
          section,
          searchString,
          statuses,
          orderBy,
          orderByDirection,
          startDate,
          endDate
        }
      })
    });
  };
}

export function addFeatureRequest(featureRequest, standAlone = false) {
  return (dispatch) => {
    dispatch({
      type: ADD_FEATURE_REQUEST,
      payload: getApiClient().post(`/featurerequest`, { ...featureRequest, 'url': window.location.href }),
      meta: { featureRequest, standAlone }
    }).then((response) => {
      dispatch(showInfo(`Successfully sent feature request`));
    })
      .catch((response) => {
        dispatch(showError(`Error sending feature request`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateFeatureRequest(featureRequest) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_REQUEST,
      payload: getApiClient().put(`/featurerequest/${featureRequest.id}`, featureRequest),
      meta: { featureRequest }
    }).then((response) => {
      dispatch(showInfo(`Successfully updated feature request`));
    })
      .catch((response) => {
        dispatch(showError(`Error updating feature request`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchFeatureRequestStatuses() {
  return (dispatch) => {
    dispatch({
      type: FETCH_FEATURE_REQUEST_STATUSES,
      payload: getApiClient().get(`/enums/FeatureRequestStatus`)
    });
  };
}

export function fetchFeatureRequestPriorities() {
  return (dispatch) => {
    dispatch({
      type: FETCH_FEATURE_REQUEST_PRITOITIES,
      payload: getApiClient().get(`/enums/FeatureRequestPriority`)
    });
  };
}

export function fetchFeatureRequestView(id: any) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_FEATURE_REQUEST_VIEW",
      payload: getApiClient().get(`/featureRequest/${id}/view`, {
        params: {
          id
        }
      })
    });
  };
}

export function fetchFeatureRequest(id: any) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_FEATURE_REQUEST",
      payload: getApiClient().get(`/featureRequest/${id}`, {
        params: {
          id
        }
      })
    });
  };
}

export function voteForFeatureRequest(featureRequestId, vote) {
  return (dispatch) => {
    const text = vote ? "" : "un";
    dispatch({
      type: VOTE_FOR_FEATURE_REQUEST,
      payload: getApiClient().put(`/featurerequest/${featureRequestId}/vote`, { vote }),
      meta: { vote }
    }).then((response) => {
      dispatch(showInfo(`Successfully ${text}voted for feature request`));
    })
      .catch((response) => {
        dispatch(showError(`Error ${text}voting for feature request`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addFeatureRequestComment(featureRequestId, featureRequestComment) {
  return (dispatch) => {
    dispatch({
      type: ADD_FEATURE_REQUEST_COMMENT,
      payload: getApiClient().post(`/featurerequest/${featureRequestId}/comment`, featureRequestComment),
      meta: { featureRequestComment }
    }).then((response) => {
      dispatch(showInfo(`Successfully added feature request comment`));
    })
      .catch((response) => {
        dispatch(showError(`Error adding feature request comment`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateFeatureRequestComment(featureRequestId, featureRequestComment) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_REQUEST_COMMENT,
      payload: getApiClient().put(`/featurerequest/${featureRequestId}/comment/${featureRequestComment.id}`, featureRequestComment),
      meta: { featureRequestComment }
    }).then((response) => {
      dispatch(showInfo(`Successfully updated feature request comment`));
    })
      .catch((response) => {
        dispatch(showError(`Error updating feature request comment`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteFeatureRequestComment(featureRequestId, featureRequestCommentId) {
  return (dispatch) => {
    dispatch({
      type: DELETE_FEATURE_REQUEST_COMMENT,
      payload: getApiClient().delete(`/featurerequest/${featureRequestId}/comment/${featureRequestCommentId}`),
      meta: { featureRequestCommentId }
    }).then((response) => {
      dispatch(showInfo(`Successfully deleted feature request comment`));
    })
      .catch((response) => {
        dispatch(showError(`Error deleting feature request comment`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EDIT_FEATURE_REQUEST_REDIRECT
    });
  };
}

export function clearSent() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EDIT_FEATURE_REQUEST_SENT
    });
  };
}

export function setShowAddComment(show) {
  return (dispatch) => {
    dispatch({
      type: SHOW_ADD_FEATURE_REQUEST_COMMENT,
      meta: { show }
    });
  };
}

export function setEditComment(commentId, editMode) {
  return (dispatch) => {
    dispatch({
      type: EDIT_FEATURE_REQUEST_COMMENT,
      meta: { commentId, editMode }
    });
  };
}
