import React, { useState } from "react";
import FilmaizeSelect from "../../../shared/filmanize-select";
import {
    OnboardingStep3ClassName
} from "./ducks/reducer";

const ShotQuickSelection = (props) => {
    const {
        readOnly,
        sceneSettings,
        scenes,
        selectShots,
        selectAllShots,
        selectNoShots
    } = props;

    const sceneSettingOptions = (sceneSettings || []).map((option) => {
        return {
            value: option.id,
            label: option.name + (option.location ? " - " + option.location?.name + (option.subLocation ? " (" + option.subLocation.name + ")" : "") : "")
        }
    });

    const [quickSelectSceneSetting, quickSelectSceneSettingChanged] = useState(
        (sceneSettingOptions && sceneSettingOptions.length > 0) ? sceneSettingOptions[0] : null);


    const sceneOptions = scenes?.filter((f) => f.shots.length > 0 && f.sceneSetting.id == quickSelectSceneSetting.value)
        .map((scene) => {

            let location = "";
            if (scene.sceneSetting?.location) {
                location = " - " + scene.sceneSetting.location?.name + " " + (scene.sceneSetting.subLocation ? "(" + scene.sceneSetting.subLocation?.name + ")" : "")
            }
            return {
                value: scene.id,
                label: scene.number + " - " + scene.interiorExterior + " " +
                    scene.dayNight + " " + scene.sceneSetting?.name + location
            }
        });

    if (sceneOptions && sceneOptions.length > 0) {
        sceneOptions.unshift({ label: "" });
    }

    const lensOptions = scenes?.filter((f) => f.shots.length > 0)
        .map(function (p) {
            return p.shots.map(function (p) {
                return p.lensFocalLength;
            });
        })
        .reduce(function (a, b) {
            return a.concat(b);
        }, [])
        .filter(onlyUnique)
        .sort((a, b) => a - b)
        .map((focalLength) => { return { value: focalLength, label: focalLength } });

    if (lensOptions && lensOptions.length > 0) {
        lensOptions.unshift({ label: "" });
    }

    const movementOptions = scenes?.filter((f) => f.shots.length > 0)
        .map(function (p) {
            return p.shots
                .filter((s) => s.movements != "")
                .map(function (p) {
                    return p.movements.split(", ");
                });
        })
        .reduce(function (a, b) {
            return a.concat(b);
        }, [])
        .reduce(function (a, b) {
            return a.concat(b);
        }, [])
        .filter(onlyUnique)
        .sort()
        .map((movement) => { return { value: movement, label: movement } });

    if (movementOptions && movementOptions.length > 0) {
        movementOptions.unshift({ label: "" });
    }

    const actorOptions = scenes?.filter((f) => f.shots.length > 0)
        .map(function (p) {
            return p.shots
                .map(function (p) {
                    return p.actors.map((actor) => actor.name);
                });
        })
        .reduce(function (a, b) {
            return a.concat(b);
        }, [])
        .reduce(function (a, b) {
            return a.concat(b);
        }, [])
        .filter(onlyUnique)
        .sort()
        .map((actor) => { return { value: actor, label: actor } });

    if (actorOptions && actorOptions.length > 0) {
        actorOptions.unshift({ label: "" });
    }

    const [quickSelectScene, quickSelectSceneChanged] = useState<any>();
    const [quickSelectLens, quickSelectLensChanged] = useState<any>();
    const [quickSelectMovements, quickSelectMovementsChanged] = useState<any>();
    const [quickSelectActors, quickSelectActorsChanged] = useState<any>();


    const onQuickSelectSceneSettingChanged = (event) => {
        quickSelectSceneSettingChanged(event);
        quickSelectSceneChanged(null);
    };

    const onQuickSelectSceneChanged = (event) => {
        quickSelectSceneChanged(event);
    };

    const onQuickSelectLensChanged = (event) => {
        quickSelectLensChanged(event);
    };

    const onQuickSelectMovementsChanged = (event) => {
        quickSelectMovementsChanged(event);
    };

    const onQuickSelectActorsChanged = (event) => {
        quickSelectActorsChanged(event);
    };

    function onlyUnique(value, index, self) {
        if (!value) {
            return false;
        }

        return self.indexOf(value) === index;
    }

    const onSelectShots = () => {
        selectShots(
            quickSelectSceneSetting?.value,
            quickSelectScene?.value,
            quickSelectLens?.value,
            quickSelectMovements?.value,
            quickSelectActors?.value
        );
    };

    const onSelectAllShots = () => {
        selectAllShots(quickSelectSceneSetting);
    }

    const onSelectNoShots = () => {
        selectNoShots();
    }


    return (
        <>
            {!readOnly && <div className={`notification content ${OnboardingStep3ClassName}`}>
                Quick slection
                <table className="quick-select-table">
                    <tr>
                        <td>
                            Scene Setting
                        </td>
                        <td>
                            <FilmaizeSelect
                                value={quickSelectSceneSetting}
                                isMulti={false}
                                options={sceneSettingOptions}
                                onChange={onQuickSelectSceneSettingChanged}
                                placeholder=""
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Scene
                        </td>
                        <td>
                            <FilmaizeSelect
                                value={quickSelectScene}
                                isMulti={false}
                                options={sceneOptions}
                                onChange={onQuickSelectSceneChanged}
                                placeholder=""
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Lens</td>
                        <td>
                            <FilmaizeSelect
                                value={quickSelectLens}
                                isMulti={false}
                                options={lensOptions}
                                onChange={onQuickSelectLensChanged}
                                placeholder=""
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Movements</td>
                        <td>
                            <FilmaizeSelect
                                value={quickSelectMovements}
                                isMulti={false}
                                options={movementOptions}
                                onChange={onQuickSelectMovementsChanged}
                                placeholder=""
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Actors</td>
                        <td>
                            <FilmaizeSelect
                                value={quickSelectActors}
                                isMulti={false}
                                options={actorOptions}
                                onChange={onQuickSelectActorsChanged}
                                placeholder=""
                            />
                        </td>
                    </tr>
                </table>
                <div className="buttons mt-2">
                    <button className="button" onClick={() => onSelectShots()}>
                        Select Shots
                    </button>
                    <button className="button" onClick={() => onSelectAllShots()}>
                        Select All
                    </button>
                    <button className="button" onClick={() => onSelectNoShots()}>
                        Select None
                    </button>
                </div>
            </div>}
        </>
    )

}

export default ShotQuickSelection;