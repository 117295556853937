import React from "react";
import { connect } from "react-redux";

const Tooltip = ({
  continuous,
  index,
  step,
  size,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps
}) => {
  const stepButtons: number[] = [];

  for (let i = 0; i < size; i++) stepButtons.push(i);


  return (
    <div className="onboarding-tooltip" {...tooltipProps}>
      <div className="onboarding-tooltip__body">
        <button className="onboarding-tooltip__close" {...closeProps}>
          Close
        </button>
        <div className="onboarding-tooltip__content">
          {step.title && (
            <h2 className="onboarding-tooltip__header">{step.title}</h2>
          )}
          <div dangerouslySetInnerHTML={{ __html: step.content }}></div>
        </div>
      </div>
      <div className="onboarding-tooltip__actions">
        <div className="onboarding-tooltip__steps">
          {stepButtons.map((step) => (
            <button
              key={step}
              className={step === index ? "active" : ""}>
              Step {step + 1}
            </button>
          ))}
        </div>
        <div>
          {index > 0 && (<button {...backProps}
            className="onboarding-tooltip__back-btn"
            disabled={index <= 0}
          >
            Back
          </button>)}
          {continuous && <button
            className="onboarding-tooltip__next-btn"
            {...primaryProps}
          >
            Next
          </button>}
        </div>
      </div>
    </div>
  );
};


export default Tooltip;
