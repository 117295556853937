import getApiClient from "../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export function fetchUsers() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USERS",
      payload: getApiClient().get(`/admin/users`)
    });
  };
}
