import { sortByName } from "../../../../shared/utility";
import { ADD_PROP_TO_SCENE, CLEAR_SCENE_PROPS_REDIRECT } from "./actions";

const defaultState = {
  prop: {},
  props: [],
  loading: true,
  saving: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_PROPS_IN_SCENE_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_PROPS_IN_SCENE_FULFILLED": {
      return {
        ...state,
        props: action.payload.data.props,
        loading: false,
        errors: null
      };
    }

    case ADD_PROP_TO_SCENE + "_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case ADD_PROP_TO_SCENE + "_FULFILLED": {
      const prop = action.payload.data.prop;
      const props = [...state.props, prop];
      sortByName(props);
      const { fromBreakdown } = action.meta;
      return {
        ...state,
        props: props,
        saving: false,
        errors: null,
        redirect: { to: fromBreakdown ? "breakdown" : "list" }
      };
    }

    case ADD_PROP_TO_SCENE + "_REJECTED": {
      return {
        ...state,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case CLEAR_SCENE_PROPS_REDIRECT: {
      return {
        ...state,
        prop: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
