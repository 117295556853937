import UpdateUserPage from "./update-user/container";
import ChangePasswordPage from "./change-password/container";
import Account from "./account/container";
import { PrivateRoute } from "../shared/private-route";
import { Suspense } from "react";
import Loader from "../shared/loader";

const AccountRoutes = () => {
  return (
    <>
    <Suspense fallback={<Loader/>}>
          <PrivateRoute exact path="/change-password" component={ChangePasswordPage} />
          <PrivateRoute exact path="/user" component={UpdateUserPage} />
          <PrivateRoute exact path="/account" component={Account} />
      </Suspense>
    </>
  );
};

export default AccountRoutes;
