import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications,
  showWarning
} from "./../../../notifications/ducks/actions";
import { getActionToUploadImagesAndSaveEntity } from "./../../../shared/image-action-creator";

export const CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER = "CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER";

export function fetchBackgroundCharacters(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BACKGROUND_CHARACTERS",
      payload: getApiClient().get(`/film/${filmId}/backgroundCharacter`)
    });
  };
}

export function fetchBackgroundActorsWithEmailAddresses(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BACKGROUND_ACTORS_WITH_EMAIL_ADDRESSES",
      payload: getApiClient().get(`/film/${filmId}/backgroundActor`)
    });
  };
}

export function fetchBackgroundCharacterRate(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BACKGROUND_CHARACTER_RATE",
      payload: getApiClient().get(`/film/${filmId}/backgroundCharacter/rate?isBackground=true`)
    });
  };
}

export function initialiseBackgroundCharacter() {
  return (dispatch) => {
    dispatch({
      type: "INITIALISE_BACKGROUND_CHARACTER"
    });
  };
}

export function clearBackgroundCharacter() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_BACKGROUND_CHARACTER"
    });
  };
}

export function setName(name) {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_CHARACTER_SET_NAME",
      meta: { name }
    });
  };
}

export function getGender(name) {
  return (dispatch) => {
    dispatch({
      type: "BACKGROUND_CHARACTER_GET_GENDER",
      meta: { name },
      payload: getApiClient().get(`/lookup/CharacterNameGender/${name}`)
    });
  };
}

export function fetchBackgroundCharacter(filmId, backgroundCharacterId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_BACKGROUND_CHARACTER",
      payload: getApiClient().get(`/film/${filmId}/backgroundCharacter/${backgroundCharacterId}`)
    });
  };
}

export function updateBackgroundCharacter(filmId, backgroundCharacter) {
  return (dispatch) => {
    updateBackgroundCharacterWithImageUrls(dispatch, backgroundCharacter, filmId);
  };
}

function updateBackgroundCharacterWithImageUrls(dispatch, backgroundCharacter, filmId) {
  const redirect = backgroundCharacter.actorId == backgroundCharacter.originalActorId;
  dispatch({
    type: "UPDATE_SINGLE_BACKGROUND_CHARACTER",
    payload: getApiClient().put(
      `/film/${filmId}/backgroundCharacter/${backgroundCharacter.id}`,
      backgroundCharacter
    ),
    meta: { backgroundCharacter, redirect }
  })
    .then((response) => {
      if (backgroundCharacter.actorId != backgroundCharacter.originalActorId) {
        if (!backgroundCharacter.actorId) {
          dispatch({
            type: "UPDATE_SINGLE_BACKGROUND_CHARACTER_UNASSIGN_ACTOR",
            payload: getApiClient().put(
              `/film/${filmId}/backgroundCharacter/${backgroundCharacter.id}/UnAssignActor`
            ),
            meta: { backgroundCharacter }
          })
            .then((response) => {
              dispatch(showInfo(`Updated Background Character ${backgroundCharacter.name}`));

              if (response.value.data.hasWarnings) {
                dispatch(showWarning(`Warning updating Background Character ${backgroundCharacter.name}`));
              } else {
                dispatch({ type: "SINGLE_BACKGROUND_CHARACTER_REDIRECT_TO_LIST" });
              }
            })
            .catch((response) => {
              dispatch(showError(`Error updating Background Character ${backgroundCharacter.name}`));
            })
            .then((response) => {
              dispatch(clearNotifications());
            });
        } else {
          dispatch({
            type: "UPDATE_SINGLE_BACKGROUND_CHARACTER_ASSIGN_ACTOR",
            payload: getApiClient().put(
              `/film/${filmId}/backgroundCharacter/${backgroundCharacter.id}/AssignActor`,
              {
                filmId,
                backgroundCharacterId: backgroundCharacter.id,
                actorId: backgroundCharacter.actorId
              }
            ),
            meta: { backgroundCharacter }
          })
            .then((response) => {

              dispatch(showInfo(`Updated Background Character ${backgroundCharacter.name}`));
              if (response.value.data.hasWarnings) {
                dispatch(showWarning(`Warning updating Background Character ${backgroundCharacter.name}`));
              } else {
                dispatch({ type: "SINGLE_BACKGROUND_CHARACTER_REDIRECT_TO_LIST" });
              }

            })
            .catch((response) => {
              dispatch(showError(`Error updating Background Character ${backgroundCharacter.name}`));
            })
            .then((response) => {
              dispatch(clearNotifications());
            });
        }
      } else {
        dispatch(showInfo(`Updated Background Character ${backgroundCharacter.name}`));
        if (response.value.data.hasWarnings) {
          dispatch(showWarning(`Warning updating Background Character ${backgroundCharacter.name}`));
        } else {
          dispatch({ type: "SINGLE_BACKGROUND_CHARACTER_REDIRECT_TO_LIST" });
        }

      }
    })
    .catch((response) => {
      dispatch(showError(`Error updating Background Character ${backgroundCharacter.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function addBackgroundCharacter(filmId, backgroundCharacter) {
  return (dispatch) => {
    addBackgroundCharacterWithImageUrls(dispatch, backgroundCharacter, filmId);
  };
}

function addBackgroundCharacterWithImageUrls(dispatch, backgroundCharacter, filmId) {
  dispatch({
    type: "ADD_SINGLE_BACKGROUND_CHARACTER",
    payload: getApiClient().post(`/film/${filmId}/backgroundCharacter/`, backgroundCharacter),
    meta: { backgroundCharacter }
  })
    .then((response) => {
      const backgroundCharacterId = response.value.data.backgroundCharacter.id;
      if (backgroundCharacter.actorId) {
        dispatch({
          type: "UPDATE_SINGLE_BACKGROUND_CHARACTER_ASSIGN_ACTOR",
          payload: getApiClient().put(
            `/film/${filmId}/backgroundCharacter/${backgroundCharacterId}/AssignActor`,
            {
              filmId,
              backgroundCharacterId,
              actorId: backgroundCharacter.actorId
            }
          ),
          meta: { backgroundCharacter }
        });
      }

      if (response.value.data.hasWarnings) {
        dispatch(showWarning(`Warning updating Background Charcter ${backgroundCharacter.name}`));
      }

      dispatch(showInfo(`Added Background Character ${backgroundCharacter.name}`));
    })
    .catch((response) => {
      dispatch(showError(`Error adding background character ${backgroundCharacter.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function deleteBackgroundCharacter(filmId, backgroundCharacter) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_BACKGROUND_CHARACTER",
      payload: getApiClient().delete(
        `/film/${filmId}/backgroundCharacter/${backgroundCharacter.id}`
      ),
      meta: { backgroundCharacter }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted background character ${backgroundCharacter.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting background character ${backgroundCharacter.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changeBackgroundCharacterToCharacter(filmId, backgroundCharacter) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER,
      payload: getApiClient().put(`/film/${filmId}/backgroundCharacter/${backgroundCharacter.id}/ChangeToCharacter`, backgroundCharacter),
      meta: { backgroundCharacter }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Background Character ${backgroundCharacter.name} to Character`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Background Character ${backgroundCharacter.name} to Character`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function initAddLocationForm() {
  return (dispatch) => {
    dispatchClearRedirect(dispatch);
  };
}

export function initEditLocationForm() {
  return (dispatch) => {
    dispatchClearRedirect(dispatch);
  };
}

function dispatchClearRedirect(dispatch) {
  dispatch({
    type: "CLEAR_REDIRECT"
  });
}

export const uploadImagesAndSaveBackgroundCharacter = getActionToUploadImagesAndSaveEntity(
  addBackgroundCharacterWithImageUrls,
  updateBackgroundCharacterWithImageUrls
);

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}
