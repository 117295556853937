import { useEffect } from "react";
import { fetchCharacterProps } from "./ducks/actions";
import { connect } from "react-redux";
import CharacterProps from "./character-props";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "../../../shared/loader";

const CharacterPropsContainer = (props) => {
    const { loading, characterProps, fetchCharacterProps } = props;
    const backgroundCharacterId = props.characterId || props.match.params.backgroundCharacterId;
    const filmId = props.filmId || props.match.params.filmId;
    useEffect(() => {
        fetchCharacterProps(filmId, backgroundCharacterId);
    }, [backgroundCharacterId]);

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/background-characters/:backgroundCharacterId/props"
            loading={loading}>
            <CharacterProps characterProps={characterProps} filmId={filmId} loading={loading} />
        </ScrollIntoView>
    )
}

function mapStateToProps(state) {
    const { backgroundCharactersState } = state;
    const characterPropsState = backgroundCharactersState.propsState;
    return {
        errors: characterPropsState.errors,
        characterProps: characterPropsState.characterProps,
        loading: characterPropsState.loading

    };
}

export default connect(mapStateToProps, {
    fetchCharacterProps
})(CharacterPropsContainer);