import { FETCH_CONFIRM_CALL_SHEET } from "./actions";

interface ConfirmCallSheetState {
  loading: boolean;
  user: any;  
}

const defaultState: ConfirmCallSheetState = {
  loading: false,
  user: {}  
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_CONFIRM_CALL_SHEET + "_PENDING" : {
      return {
        ...state,
        loading: true
      }
    }

    case FETCH_CONFIRM_CALL_SHEET + "_FULFILLED" : {
      return {
        ...state,
        loading: false,
        user: action.payload.data
      };
    }

    case FETCH_CONFIRM_CALL_SHEET + "_REJECTED" : {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }
    
    default:
      return state;
  }
};

export default reducer;
