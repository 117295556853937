import getApiClient from "./../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications,
  showWarning
} from "../../../../notifications/ducks/actions";

const url = "/film";

export function fetchCharacterOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BACKGROUND_CHARCTER_OVERVIEW",
      payload: getApiClient().get(`${url}/${id}/BackgroundCharacter/Overview`)
    });
  };
} 

export function clearCharacterOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_BACKGROUND_CHARACTER_OVERVIEW"
    });
  };
}

export function assignActor(filmId, actor, character) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_ASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER",
      payload: getApiClient().put(
        `${url}/${filmId}/BackgroundCharacter/${character.id}/AssignBackgroundActor`,
        { backgroundActorId: actor.id }
      )
    })
      .then((response) => {
        dispatch({
          type: "ASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER",
          meta: { actor, character }
        });
        dispatch(
          showInfo(
            `Assigned Background Actor ${actor.name} to Background Character ${character.name}`
          )
        );

        if (response.value.data.hasWarnings) {
          dispatch(
            showWarning(
              `Warning assigning Background Actor ${actor.name} to Background Character ${character.name}`
            )
          );
        }          
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error assigning actor ${actor.name} to character ${character.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function unassignActor(filmId, character, backgroundActorId, actorName) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_UNASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER",
      payload: getApiClient().put(
        `${url}/${filmId}/BackgroundCharacter/${character.id}/UnAssignBackgroundActor/${backgroundActorId}`
      )
    })
      .then((response) => {
        dispatch({
          type: "UNASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER",
          meta: { characterId: character.id, backgroundActorId: backgroundActorId }
        });
        dispatch(
          showInfo(
            `Unassigned Background Actor ${actorName} from Background Character ${character.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error unassigning Background Actor ${actorName} from Background Character ${character.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function aliasCharacter(filmId, aliasCharacter, mainCharacter) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_ALIAS_BACKGROUND_CHARACTER",
      payload: getApiClient().put(
        `${url}/${filmId}/BackgroundCharacter/${mainCharacter.id}/Alias`,
        { aliasId: aliasCharacter.id }
      )
    })
      .then((response) => {
        dispatch({
          type: "ALIAS_BACKGROUND_CHARCTER",
          meta: { aliasCharacter, mainCharacter }
        });
        dispatch(
          showInfo(`Aliased ${aliasCharacter.name} with ${mainCharacter.name}`)
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error aliasing ${aliasCharacter.name} with ${mainCharacter.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function unaliasCharacter(filmId, aliasCharacter, mainCharacter) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_REMOVE_ALIAS_BACKGROUND_CHARACTER",
      payload: getApiClient().put(
        `${url}/${filmId}/BackgroundCharacter/${mainCharacter.id}/RemoveAlias`,
        { aliasId: aliasCharacter.id }
      )
    })
      .then((response) => {
        dispatch({
          type: "ALIAS_BACKGROUND_CHARCTER",
          meta: { aliasCharacter, mainCharacter }
        });
        dispatch(
          showInfo(
            `Removed alias ${aliasCharacter.name} from ${mainCharacter.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error removing alias ${aliasCharacter.name} from ${mainCharacter.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
