import { FETCH_USERS_BY_DATE } from "./actions";

interface UsersState {
  loading: boolean;
  dates: any[];
}

const defaultState: UsersState = {
  loading: false,
  dates: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_USERS_BY_DATE + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_USERS_BY_DATE + "_FULFILLED": {
      let runningTotal = 0;
      let dates = action.payload.data.dates;

      return {
        ...state,
        dates,
        loading: false
      };
    }

    case FETCH_USERS_BY_DATE + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
