import React from "react";

const Eighth = ({ page }) => {
  const whole = Math.trunc(page);
  const eighth = (page - Math.trunc(page)) * 8;
  return (
    <>
      {whole > 0 && <span>{whole} </span>}
      {eighth > 0 && <span className="eighth">{eighth}/8</span>}
    </>
  );
};

export default Eighth;
