import { Link } from "react-router-dom";
import { Draggable } from 'react-beautiful-dnd';
import './actor-order.scss'


function OrderItem(props) {
    const {
        actor,
        index,
        readOnly,
        filmId
    } = props;

    return (
        <Draggable draggableId={actor.id} index={index} isDragDisabled={readOnly}>
            {(provided: any) => (
                <tr className="track-order"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <td>
                        <div className="actor-order-row">
                            {!readOnly && <i className="material-icons dark drag-icon">drag_indicator</i>}
                            <Link className="actor-order-row" to={`${actor.id}/edit`}><span>{actor.number}.&nbsp;</span><span>{actor.name}</span></Link>
                        </div>
                    </td>
                    <td>
                        {(actor.characters || []).map((character, index) => <><Link to={`/films/${filmId}/characters/${character.id}/edit`}>{character.name}</Link>{index < actor.characters.length - 1 ? ", " : ""}</>)}
                    </td>
                </tr>
            )}
        </Draggable>
    );
}

export default OrderItem;
