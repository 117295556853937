import { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import confirm from "../../shared/modal/confirm";
import {
  fetchUser,
  disableUser,
  updateUser,
  fetchPricingPlans
} from "./ducks/actions";
import Form from "./form";

function Register(props) {
  const {
    fetchUser,
    loading,
    errors,
    user,
    disableUser,
    updateUser,
    fetchPricingPlans,
    pricingPlans
  } = props;

  const userId = props.match.params.userId;

  useEffect(() => {
    fetchPricingPlans();
  }, []);

  useEffect(() => {
    fetchUser(userId);
  }, [userId]);

  if (loading) {
    return <Loader />;
  }

  const onDisableUser = (e) => {
    e.preventDefault();
    confirm(() => disableUser(userId), null, "Are you sure you want to disable user?", "Disable User");
  };

  function onUpdateDetails(user) {
    if (!user.maximumFilms) {
      user.maximumFilms = null;
    }

    updateUser(user);
  }

  return (
    <div className="blade-content wideaf">
      <Form
        onSubmit={onUpdateDetails}
        errors={errors}
        loading={false}
        user={user}
        initialValues={user}
        disableUser={onDisableUser}
        pricingPlans={pricingPlans}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { adminState } = state;
  return {
    user: adminState.adminUserState.user,
    pricingPlans: adminState.adminUserState.pricingPlans,
    errors: adminState.adminUserState.errors,
    loading: adminState.adminUserState.loading
  };
}

export default connect(mapStateToProps, {
  fetchUser,
  disableUser,
  updateUser,
  fetchPricingPlans
})(Register);
