import React, { useEffect, useState } from "react";
import "./loader.scss";

const Loader = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`loader-wrapper ${!isLoading ? "" : "is-active"}`}>
      <div className="loader is-loading">
        <div className="inner-loader"></div>
      </div>
    </div>
  );
};

export default Loader;
