import { withCallbacks, signalMiddleware } from "redux-signalr";
import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel
} from "@microsoft/signalr"; // version 1.0.4
import getConfig from "../app/config";
import auth from "../shared/auth";

      let config = getConfig();
      const urlRoot = config.apiUrl;
      let url = urlRoot.substring(0, urlRoot.length - 1);
      var to = url.lastIndexOf("/");
      to = to == -1 ? url.length : to + 1;
      url = url.substring(0, to);
      const connectionHub = url + `filmTaskhub`;

      const protocol = new JsonHubProtocol();

      // let transport to fall back to to LongPolling if it needs to
      const transport =
        HttpTransportType.WebSockets | HttpTransportType.LongPolling;

      const options = {
        transport,
        logMessageContent: true,
        logger: LogLevel.Error,
        accessTokenFactory: () => auth.getSignalRToken()
      };

      // create the connection instance
      export const connection = new HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withHubProtocol(protocol)
        .withAutomaticReconnect()
        .build();
        
  export const FILM_TASK_ADDED = "FILM_TASK_ADDED";
  export const FILM_TASK_MOVED = "FILM_TASK_MOVED";
  export const FILM_TASK_UPDATED = "FILM_TASK_UPDATED";
  export const FILM_TASK_DELETED = "FILM_TASK_DELETED";
  export const FILM_TASK_ASSIGNED = "FILM_TASK_ASSIGNED";

  const callbacks = withCallbacks()  
  .add('TaskAdded', (taskId, taskStatusId, order, title) => (dispatch) => {
    dispatch({
      type: FILM_TASK_ADDED,
      meta: { taskId, taskStatusId, order, title }
    });
  })
  .add('TaskMoved', (taskId, oldTaskStatusId, newTaskStatusId, oldOrder, newOrder) => (dispatch) => {
    dispatch({
      type: FILM_TASK_MOVED,
      meta: { taskId, oldTaskStatusId, newTaskStatusId, oldOrder, newOrder }
    });
  })
  .add('TaskUpdated', (taskId, title) => (dispatch) => {
    dispatch({
      type: FILM_TASK_UPDATED,
      meta: { taskId, title }
    });
  })
  .add('TaskDeleted', (taskId, taskStatusId) => (dispatch) => {
    dispatch({
      type: FILM_TASK_DELETED,
      meta: { taskId, taskStatusId }
    });
  })
  .add('TaskAssigned', (taskId, 
    collaboratorId,
    collaboratorFirstName,
    collaboratorLastName,
    backgroundColor,
    profileImageUrl) => (dispatch) => {
    dispatch({
      type: FILM_TASK_ASSIGNED,
      meta: { taskId, 
              collaboratorId,
              collaboratorFirstName,
              collaboratorLastName,
              backgroundColor,
              profileImageUrl }
    });
  })
  
  export const signal = signalMiddleware({
    callbacks,
    connection,
    shouldConnectionStartImmediately: false
  });

  