import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchOverview } from "./ducks/actions";
import Overview from "./overview";
import Errors from "./../../shared/errors";
import Loader from "./../../shared/loader";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

const EditContainer = (props) => {
  const filmId = props.match.params.id;
  const { fetchOverview, overview, loading, errors } = props;

  useEffect(() => {
    fetchOverview(filmId);
  }, []);

  function renderFilm(overview) {
    return (
      <ScrollIntoView
        className="blade-content wider film-overview"
        path="/films/:filmId/overview"
        loading={loading}>
        <Overview overview={overview}></Overview>
      </ScrollIntoView>
    );

  }

  return (
    <>
      {(loading || !overview) && <Loader />}
      {!loading && overview && renderFilm(overview)}
    </>
  );
};

function mapStateToProps(state) {
  return {
    errors: state.filmOverviewState.errors,
    loading: state.filmOverviewState.loading,
    overview: state.filmOverviewState.overview,
  };
}

export default connect(mapStateToProps, { fetchOverview })(EditContainer);
