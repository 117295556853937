import React from "react";
import NumberFormat from "react-number-format";
import CurrencyDisplay from "../../shared/currency-display";
import CateringBudget from "./catering-budget";

function Catering(props) {
  const {
    budget,
    onChangeCateringBuget,
    onChangeCateringDays,
    showHide,
    visible,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <div>
      <div className="columns subtitle is-4 mt-1">
        <div className="column">
          <h4
            className="subtitle is-4 pointer"
            onClick={() => showHide(!visible)}>
            <button className="button is-text is-small">
              {!visible && <i className="material-icons">add</i>}
              {visible && <i className="material-icons">remove</i>}
            </button>
            Catering
          </h4>
        </div>
        <div className="column budget-header-number">
          {!visible && (
            <CurrencyDisplay
              value={budget.cateringTotal}
            />
          )}
        </div>
      </div>
      {visible && (
        <>
          <table className="table budget-table">
            <thead>
              <tr>
                <th>Name</th>
                <th className="budget-daily-rate-column">Budget</th>
                <th className="has-text-centered">Total Meals</th>
                <th className="has-text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {budget.catering &&
                <CateringBudget
                  catering={budget.catering}
                  onChangeCateringBuget={onChangeCateringBuget}
                  onChangeCateringDays={onChangeCateringDays}
                  maxDays={budget.days}
                  totalScenes={budget.totalScenes}
                  hasShootingDays={budget.hasShootingDays}
                  readOnly={readOnly}
                  filmId={filmId}
                  saving={saving}
                />
              }
              <tr>
                <td colSpan={3}></td>
                <td className="has-text-right">
                  <CurrencyDisplay
                    value={budget.cateringTotal}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default Catering;
