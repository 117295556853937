import getApiClient from "../../../../shared/api";
import { showInfo, showError, clearNotifications } from "../../../../notifications/ducks/actions";

export function fetchShootingDayShotList(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_SHOT_LIST",
      payload: getApiClient().get(`/film/${filmId}/ShootingDay/${shootingDayId}/ShotList`),
    });
  };
}

export function sendShotListToCrew(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "SEND_SHOOTING_DAY_EMAILS",
      payload: getApiClient().post(`/film/${filmId}/ShotList/${shootingDayId}`),
      meta: {},
    })
      .then((response) => {
        dispatch(showInfo(`Shot list emails sent`));
      })
      .catch((response) => {
        dispatch(showError(`Error sending shot list emails`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function sendShotListToSelf(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "SEND_SHOOTING_DAY_EMAILS",
      payload: getApiClient().post(`/film/${filmId}/ShotList/${shootingDayId}/ToSelf`),
      meta: {},
    })
      .then((response) => {
        dispatch(showInfo(`Shot list email sent to you`));
      })
      .catch((response) => {
        dispatch(showError(`Error sending shot lost email`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}