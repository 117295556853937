import OnboardingType from "../../../../shared/onboarding/onboarding-type";

interface ShootingDayOrderState {
  shootingDaySettings: any;
  loading: boolean;  
  errors: any;
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'shooting-day-settings-onboarding-step-1';
export const OnboardingStep2ClassName = 'shooting-day-settings-onboarding-step-2';
export const OnboardingStep3ClassName = 'shooting-day-settings-onboarding-step-3';
export const OnboardingStep4ClassName = 'shooting-day-settings-onboarding-step-4';

const defaultState: ShootingDayOrderState = {
  shootingDaySettings: null,
  loading: true,
  errors: null,
  onboardingSteps: [      
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Crew Call',
      content: `        
      <p>The time values on this page are the defaults applied when creating a new Shooting Day, for example when selecting Smart Days on the Shooting Order page.</p>
      <p>Once the days are created, then individual days can be adjusted to the specific time.</p>
      <p>Changing this values will not affect any existing days.</p>      
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Evening Meal',
      content: `        
        <p>This will only be included in the day if Estimated Wrap is greater than this.</p>
      `
    },
    // {
    //   target: '.' + OnboardingStep3ClassName,
    //   title: 'Call Sheet Sections',
    //   content: `        
    //     <p>This allows you choose which sections you would like to include in the generated Call Sheet PDF.</p>
    //   `
    // },
    // {
    //   target: '.' + OnboardingStep4ClassName,
    //   title: 'Weather Sections',
    //   content: `        
    //     <p>This allows you to select as much or little details about the weather that is included on the Call Sheet.</p>
    //   `
    // }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
  
    case "FETCH_SHOOTING_DAY_SETTINGS_PENDING": {
      return {
        ...state,        
        loading: true, 
        errors: null
      };
    }

    case "FETCH_SHOOTING_DAY_SETTINGS_FULFILLED": {
      return {
        ...state,
        shootingDaySettings: action.payload.data,
        loading: false
      };
    }

    case "FETCH_SHOOTING_DAY_SETTINGS_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "UPDATE_SHOOTING_DAY_SETTINGS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "UPDATE_SHOOTING_DAY_SETTINGS_FULFILLED": {
      return {
        ...state,
        shootingDaySettings: action.meta.shootingDaySettings,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case "UPDATE_SHOOTING_DAY_SETTINGS_REJECTED": {
      return {
        ...state,
        shootingDaySettings: action.meta.shootingDaySettings,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
