import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../shared/loader";
import confirm from "../../shared/modal/confirm";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import { fetchEquipment, updateEquipment, clearRedirect, deleteEquipment, fetchEquipmentAcquisitionMethods } from "./ducks/actions";
import Form from "./form";

function AddDepartment(props) {
  const { filmId, categoryId, equipmentId } = props.match.params;
  const { fetchEquipment, equipment, clearDepartment, updateEquipment, errors, loadingEquipment, deleteEquipment, fetchEquipmentAcquisitionMethods, acquisitionMethods } =
    props;

  useEffect(() => {
    fetchEquipmentAcquisitionMethods();
  }, []);

  useEffect(() => {
    fetchEquipment(filmId, categoryId, equipmentId);
  }, [equipmentId]);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/equipment-category/${categoryId}/equipment`;
      return <Redirect to={url} />;
    }
  }

  function onUpdateEquipment(equipment) {
    sanitiseDataTypes(equipment);
    updateEquipment(filmId, categoryId, equipment);
  }

  function onDeleteEquipment(equipment) {
    confirm(() => deleteEquipment(filmId, categoryId, equipment), equipment.name);
  };

  function sanitiseDataTypes(equipment) { }

  function renderLoading() {
    return <Loader />;
  }

  if (loadingEquipment) {
    return renderLoading();
  }

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/equipment-category/:categoryId/equipment/:equipmentId/edit"
      loading={loadingEquipment}>
      <Form
        mode="edit"
        errors={errors}
        initialValues={equipment}
        loading={loadingEquipment}
        onSubmit={onUpdateEquipment}
        handleDelete={onDeleteEquipment}
        acquisitionMethods={acquisitionMethods}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    errors: state.equipmentState.errors,
    redirect: state.equipmentState.redirect,
    equipment: state.equipmentState.equipment,
    loadingEquipment: state.equipmentState.loadingEquipment,
    acquisitionMethods: state.equipmentState.acquisitionMethods
  };
}

export default connect(mapStateToProps, {
  fetchEquipment,
  updateEquipment,
  clearRedirect,
  deleteEquipment,
  fetchEquipmentAcquisitionMethods
})(AddDepartment);
