import React from "react";
import InputSlider from "../../shared/controls/input-slider";
import NumberFormat from "react-number-format";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { OnboardingStep6ClassName } from './ducks/reducer';
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function SceneSetting(props) {
  const {
    sceneSetting,
    onChangeSceneSettingDailyRate,
    onChangeSceneSettingDays,
    maxDays,
    hasShootingDays,
    readOnly,
    index,
    filmId,
    saving
  } = props;

  return (
    <tr>
      <td><Link to={`/films/${filmId}/scene-settings/${sceneSetting.id}/edit`}>{sceneSetting.name}</Link></td>
      <td className={`budget-days-column ${index === 0 ? OnboardingStep6ClassName : ''}`}>
        {hasShootingDays ? (
          sceneSetting.days
        ) : (
          <InputSlider
            step={1}
            formatLabel={(value) => `${value}`}
            draggableTrack={false}
            allowSameValues={false}
            maxValue={maxDays}
            minValue={1}
            value={sceneSetting.days}
            onChange={(val) => onChangeSceneSettingDays(sceneSetting.id, val)}
            disabled={readOnly || saving}
          />
        )}
      </td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={sceneSetting.dailyBudget}
          onChange={(val) =>
            onChangeSceneSettingDailyRate(sceneSetting.id, val)
          }
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={sceneSetting.total}
        />
      </td>
    </tr>
  );
}

export default SceneSetting;
