import React, { useState, useEffect } from "react";
import FilmaizeSelect from "../../../shared/filmanize-select";
import TextInput from "../../../shared/text-input";
import Time from "../../../shared/time";
import Checkbox from "../../../shared/filmanize-checkbox";
import { OnboardingStep1ClassName, OnboardingStep8ClassName, OnboardingStep9ClassName } from "./ducks/reducer";

const AddTimeSlot = (props) => {
  const {
    readOnly,
    crewCallHour,
    estimatedWrapHour,
    sceneSettings,
    scenes,
    addTimeSlot,
    addCrewTimeSlot,
    inputTabIndex,
    crewEventDesciption,
    mealTimeSlot,
    descriptionChanged,
    mealTimeSlotChanged
  } = props;

  const tabs = ["Scene Event", "Crew Event"];
  const [tabIndex, setTabIndex] = React.useState(0);
  const [startTime, startTimeChanged] = useState(
    { hours: crewCallHour, minutes: 0, days: 0 }
  );
  const [endTime, endTimeChanged] = useState(
    { hours: crewCallHour, minutes: 0, days: 0 }
  );
  const [sceneSetting, sceneSettingChanged] = useState<any>();
  const [scene, sceneChanged] = useState();

  useEffect(() => {
    if (inputTabIndex != null) {
      setTabIndex(inputTabIndex);
    }
  }, [inputTabIndex])

  function onChangeTab(tabIdx: number) {
    if (tabIdx === 2) return;
    setTabIndex(tabIdx);
  }

  const sceneSettingOptions = (sceneSettings || []).map((option) => {
    return {
      value: option.id,
      label:
        option.name +
        (option.location
          ? " - " +
          option.location?.name +
          (option.subLocation ? " (" + option.subLocation.name + ")" : "")
          : "")
    };
  });

  const scenesOptions = (scenes || []).filter((scene) => scene.sceneSetting.id == sceneSetting?.value).map((option: any) => { return { label: option.number, value: option.id } });
  scenesOptions.unshift({ label: "", id: null });

  useEffect(() => {
    if (sceneSettingOptions && sceneSettingOptions.length > 0) {
      sceneSettingChanged(sceneSettingOptions[0]);
    }
  }, [sceneSettings]);

  const onSceneSettingChanged = (event) => {
    sceneSettingChanged(event);
  };

  const onSceneChanged = (event) => {
    sceneChanged(event);
  };

  const onDescriptionChanged = (text) => {
    descriptionChanged(text);
  };

  const onMealTimeSlotChanged = (value) => {
    mealTimeSlotChanged(value);
  };

  const onAddShotTimeSlot = (startTime, endTime, sceneSettingOption, sceneOption) => {
    const sceneSetting = sceneSettings.find(
      (l) => l.id == sceneSettingOption.value
    );

    const scene = scenes.find(
      (l) => l.id == sceneOption?.value
    );

    addTimeSlot(startTime, endTime, sceneSetting, scene);
  };

  const onAddCrewTimeSlot = (startTime, endTime, description, mealTimeSlot) => {
    addCrewTimeSlot(startTime, endTime, description, mealTimeSlot);
  };

  return (
    <>
      {!readOnly && (
        <>
          <div className="tabs">
            <ul>
              {tabs.map((tab, idx) => {
                const className = (tabIndex === idx ? "is-active " : " ") + (idx === 1 ? OnboardingStep8ClassName : "");
                return (
                  <li className={className} key={idx}>
                    <a onClick={() => onChangeTab(idx)}>{tab}</a>
                  </li>
                );
              })}
            </ul>
          </div>
          {tabIndex === 0 && (
            <div className={OnboardingStep1ClassName}>
              <div className="columns">
                <div className="column">
                  <Time
                    label="Start Time"
                    onChange={startTimeChanged}
                    value={startTime}
                    startTime={crewCallHour}
                    endTime={estimatedWrapHour}
                    canAddDay={estimatedWrapHour > 23}></Time>
                  {/*
            <Time
              label="End Time"
              onChange={endTimeChanged}
              value={endTime}
              startTime={crewCallHour}
              endTime={estimatedWrapHour}></Time>
            */}
                </div>
                <div className="column">
                  <label className={"label " + (readOnly ? "is-disabled" : "")}>
                    Scene Setting
                  </label>
                  <FilmaizeSelect
                    value={sceneSetting}
                    isMulti={false}
                    options={sceneSettingOptions}
                    onChange={onSceneSettingChanged}
                    placeholder=""
                    isDisabled={readOnly}
                  />
                </div>
                <div className="column">
                  <label className={"label " + (readOnly ? "is-disabled" : "")}>
                    Scene
                  </label>
                  <FilmaizeSelect
                    value={scene}
                    isMulti={false}
                    options={scenesOptions}
                    onChange={onSceneChanged}
                    placeholder=""
                    isDisabled={readOnly}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="button"
                onClick={() => {
                  onAddShotTimeSlot(startTime, endTime, sceneSetting, scene);
                }}>
                Add Time Slot
              </button>
            </div>
          )}
          {tabIndex === 1 && (
            <div>
              <div className="columns">
                <div className="column">
                  <Time
                    label="Start Time"
                    onChange={startTimeChanged}
                    value={startTime}
                    startTime={crewCallHour}
                    endTime={estimatedWrapHour}
                    canAddDay={estimatedWrapHour > 23}></Time>
                  {/*
          <Time
            label="End Time"
            onChange={endTimeChanged}
            value={endTime}
            startTime={crewCallHour}
            endTime={estimatedWrapHour}></Time>
        */}
                </div>
                <div className="column">
                  <TextInput
                    label="Description"
                    value={crewEventDesciption}
                    onChange={onDescriptionChanged}
                  />
                </div>
                <div className="column">
                  <Checkbox
                    className={OnboardingStep9ClassName}
                    checked={mealTimeSlot}
                    onChange={onMealTimeSlotChanged}
                    label="Meal"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="button"
                onClick={() =>
                  onAddCrewTimeSlot(
                    startTime,
                    endTime,
                    crewEventDesciption,
                    mealTimeSlot
                  )
                }>
                Add Time Slot
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AddTimeSlot;
