import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../../shared/private-route";
import ListContainer from "./list-container";

const ShootingDaysRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/shooting-days"
        component={ListContainer}
      />
    </>
  );
};

export default ShootingDaysRoutes;
