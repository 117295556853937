export const HeightUnitName = (heightUnit) => {
    switch (heightUnit) {
        case 1:
            return "cm";
        case 2:
            return "ft";
        case 3:
            return "in";
        default:
            return "cm";
    }
}

export const WeightUnitName = (weightUnit) => {
    switch (weightUnit) {
        case 1:
            return "kg";
        case 2:
            return "lb";
        case 3:
            return "st";
        default:
            return "kg";
    }
}

export const DistanceUnitName = (distanceUnit) => {
    switch (distanceUnit) {
        case 1:
            return "km";
        case 2:
            return "m";
        default:
            return "km"
    }
} 