import React, { useEffect } from "react";
import { connect } from "react-redux";
import Errors from "../shared/errors";
import {
  importFilm,
  clearRedirect
} from "./ducks/actions";

import { useState } from "react";
import { Redirect } from "react-router-dom";
import Progress from "../shared/progress";
import Onboarding from '../shared/onboarding/onboarding';
import { OnboardingStep1ClassName, OnboardingStep2ClassName, OnboardingStep3ClassName } from './ducks/reducer';
import BoxWraper from "../account/box-wrapper";
import Loader from "../shared/loader";
import ImportFilm from "./import-film";

interface IProps {
  uploadStatus: string;
}

const ImportFilmContainer = (props: any) => {
  const {
    loading,
    uploadStatus,
    setUploadError,
    initScriptUpload,
    errors,
    progress,
    clearRedirect,
    onboardingSteps,
    loadingOnBoarding,
    importFilm,
    uploading,
    redirect
  } = props;

  useEffect(() => {

  }, []);

  if (redirect) {
    if (redirect.to) {
      clearRedirect();
      const url = redirect.to;
      return <Redirect to={url} />;
    }
  }

  const UploadForm = () => {
    const [saving, setSaving] = useState(false);

    const onUpload = (formData) => {
      importFilm(formData);
    };

    return (
      <BoxWraper>
        {loadingOnBoarding && <Loader />}
        {errors && <Errors errors={errors} />}
        <>
          <h2 className="menu-label">Import Film</h2>
          {uploading && <Loader />}
          {!saving && <div>

            <ImportFilm
              isUploading={!!uploadStatus}
              setUploadError={setUploadError}
              progress={progress}
              clearRedirect={clearRedirect}
              saving={saving}
              setSaving={setSaving}
              onUpload={onUpload}
              disabled={loading}
            />

          </div>}
        </>

      </BoxWraper>
    );
  };

  return (
    <>
      {loading ? <Loader /> : <UploadForm {...props} />}
      <Onboarding onboardingSteps={onboardingSteps} section="Import Film" />
    </>
  );
};

const mapStateToProps = function (state) {
  const { importFilmState, commonState } = state;
  return {
    loading: importFilmState.loading,
    uploadStatus: importFilmState.uploadingStatus,
    redirect: importFilmState.redirect,
    script: importFilmState.script,
    errors: importFilmState.errors,
    progress: importFilmState.progress,
    onboardingSteps: importFilmState.onboardingSteps,
    uploading: importFilmState.uploading,
    loadingOnBoarding: commonState.loadingOnBoarding
  };
};

export default connect(mapStateToProps, {
  importFilm,
  clearRedirect
})(ImportFilmContainer);
