import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import confirm from "../../../shared/modal/confirm";
import Loader from "../../../shared/loader";
import {
  clearRedirect,
  deleteCollaboratorRole,
  fetchCollaboratorRole,
  updateCollaboratorRole,
  fetchSections
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Onboarding from "../../../shared/onboarding/onboarding";

function UsersContainer(props) {
  const { filmId, collaboratorRoleId } = props.match.params;
  const {
    fetchCollaboratorRole,
    updateCollaboratorRole,
    deleteCollaboratorRole,
    fetchSections,
    loadingUser,
    role,
    errors,
    userAccessEnum,
    sections,
    onboardingSteps
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {
    fetchSections(filmId);
    if (invalidIds.indexOf(collaboratorRoleId) === -1) {
      fetchCollaboratorRole(filmId, collaboratorRoleId);
    }
  }, [filmId, collaboratorRoleId, fetchCollaboratorRole]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/collaborators/collaborator-roles/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(collaboratorRoleId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderRole(role) {
    return (
      <ScrollIntoView
        className="blade-content medium-wide"
        path="/films/:filmId/collaborators/collaborator-roles/:collaboratorId/edit"
        loading={loadingUser}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          userId={role.id}
          initialValues={role}
          loading={loadingUser}
          onSubmit={onUpdateRole}
          handleDelete={onDeleteRole}
          userAccessEnum={userAccessEnum}
          canEdit={role.canEdit}
          sections={sections}
          onboardingSteps={onboardingSteps}
          anyCollaboratorsInRole={role.anyCollaboratorsInRole}
          {...props}
        />
        {/* <Onboarding onboardingSteps={onboardingSteps} section="Edit Collaborator Role" /> */}

      </ScrollIntoView>
    );
  }

  function onUpdateRole(collaboratorRole) {
    sanitiseDataTypes(collaboratorRole);
    return updateCollaboratorRole(filmId, collaboratorRole);
  }

  function onDeleteRole() {
    confirm(
      () => deleteCollaboratorRole(filmId, role),
      role?.name
    );
  }

  function sanitiseDataTypes(user) { }

  return role && !loadingUser ? renderRole(role) : renderLoading();
}

function mapStateToProps(state) {
  const { collaboratorRolesState, commonState } = state;
  return {
    role: collaboratorRolesState.role,
    sections: collaboratorRolesState.sections,
    loadingUser: collaboratorRolesState.loadingUser,
    errors: collaboratorRolesState.errors,
    redirect: collaboratorRolesState.redirect,
    onboardingSteps: collaboratorRolesState.onboardingSteps,
    userAccessEnum: commonState.userAccessEnum
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchCollaboratorRole,
    updateCollaboratorRole,
    deleteCollaboratorRole,
    fetchSections,
    clearRedirect
  })(UsersContainer)
);
