import React from "react";
import { Link, NavLink } from "react-router-dom";

const DepartmentsList = ({ crewMembers, filmId }) => {


  const outputRole = (role: any) => {
    return (
      <>
        <td>
          <Link
            to={`/films/${filmId}/departments/${role.departmentId}`}>
            {role.departmentName}
          </Link>
        </td>
        <td>
          <Link
            to={`/films/${filmId}/departments/${role.departmentId}/roles/${role.roleId}`}>
            {role.roleName}
          </Link>
        </td>
      </>
    )
  };

  return (
    <div className="blade-content wide">
      <p className="menu-label">Overview</p>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Department</th>
            <th>Role</th>
          </tr>
        </thead>
        {(crewMembers || []).map((crewMember, idx) => {
          const departmentId = crewMember.roles?.length ? crewMember.roles[0].departmentId : null;
          const roleId = crewMember.roles?.length ? crewMember.roles[0].roleId : null;
          return (
            <>
              <tr key={idx}>
                <td>
                  {departmentId && roleId && <Link
                    to={`/films/${filmId}/departments/${departmentId}/roles/${roleId}/members/${crewMember.id}`}
                  >
                    {crewMember.name}
                  </Link>}
                  {(!departmentId || !roleId) && <span>{crewMember.name}</span>}
                </td>
                {roleId && outputRole(crewMember.roles[0])}
                {!roleId && <><td></td><td></td></>}
              </tr>
              <>
                {crewMember.roles.slice(1).map((role, index) => (<tr>
                  <td></td>
                  {outputRole(role)}
                </tr>))}
              </>
            </>
          );
        })}
      </table>
    </div>
  );
};

export default DepartmentsList;
