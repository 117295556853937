import { useEffect } from "react";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { connect } from "react-redux";
import Upload from "./upload";
import {
    uploadScript,
    setUploadError,
    clearScriptUpload,
    fetchVersions
} from "./ducks/actions";
import { fetchScript } from '../ducks/actions';

const UploadScript = (props: any) => {
    const {
        filmId,
        uploadScript,
        setUploadError,
        progress,
        completed,
        fetchScript,
        clearScriptUpload,
        fetchVersions,
        versions
    } = props;

    useEffect(() => {
        clearScriptUpload();
        fetchVersions(filmId);
    }, []);

    useEffect(() => {
        if (completed) {
            fetchScript(filmId);
        }
    }, [completed]);

    const onUploadScript = (formData) => {
        uploadScript(filmId, formData);
    };

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/script/upload"
            loading={false}>
            <div>
                <h2 className="menu-label">Upload</h2>
                <Upload
                    versions={versions}
                    uploadScript={onUploadScript}
                    progress={progress}
                    setUploadError={setUploadError}
                />
            </div>
        </ScrollIntoView>
    )
};

function mapStateToProps(state) {
    const uploadScriptState = state.scriptState.uploadScriptState;
    return {
        loading: uploadScriptState.loading,
        readOnly: uploadScriptState.readOnly,
        errors: uploadScriptState.errors,
        progress: uploadScriptState.progress,
        completed: uploadScriptState.completed,
        versions: uploadScriptState.versions
    };
}

export default connect(mapStateToProps, {
    fetchVersions,
    uploadScript,
    setUploadError,
    fetchScript,
    clearScriptUpload
})(UploadScript);