import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../shared/private-route";
import ImportFilm from "./container";

const ImportRoutes = () => {
  return (
    <PrivateRoute
      exact
      path="/import"
      component={ImportFilm}
    />
  );
};

export default ImportRoutes;
