import React, { useEffect } from "react";
import { connect } from "react-redux";
import Errors from "./../../../shared/errors";
import Loader from "./../../../shared/loader";
import Calendar from "./calendar";
import {
  changeDayOfWeek,
  changeStartDate,
  fetchShootingDays,
  updateEvent,
  updateDate,
  updateShootingCalendar
} from "./ducks/actions";
import { Prompt } from "react-router-dom";
import Onboarding from "../../../shared/onboarding/onboarding";

function ShootingCalendarContainer(props) {
  const filmId = props.match.params.filmId;
  const {
    fetchShootingDays,
    days,
    potentialDates,
    errors,
    loading,
    saving,
    updateShootingCalendar,
    updateEvent,
    updateDate,
    changeStartDate,
    daysOfWeek,
    changeDayOfWeek,
    pristine,
    readOnly,
    onboardingSteps,
    allowChangingDayOrder
  } = props;

  useEffect(() => {
    fetchShootingDays(filmId);
  }, []);

  const onUpdateShootingCalendar = (allowChangingDayOrder) => {
    updateShootingCalendar(filmId, days, daysOfWeek, allowChangingDayOrder);
  };

  function renderLoading() {
    return <Loader />;
  }

  function renderScenes(days) {
    return (
      <>
        <Prompt when={!pristine} message="" />
        {saving && <Loader />}

        <div className="blade-content wide">
          {errors && <Errors errors={errors} />}
          <Calendar
            days={days}
            potentialDates={potentialDates}
            updateEvent={updateEvent}
            updateDate={updateDate}
            changeStartDate={changeStartDate}
            onUpdateShootingCalendar={onUpdateShootingCalendar}
            daysOfWeek={daysOfWeek}
            changeDayOfWeek={changeDayOfWeek}
            readOnly={readOnly}
            defaultAllowChangingDayOrder={allowChangingDayOrder}
          />
        </div>
        <Onboarding onboardingSteps={onboardingSteps} section="Shooting Day Calendar" />
      </>
    );
  }

  return loading ? renderLoading() : renderScenes(days);
}

function mapStateToProps(state) {
  const calendar = state.shootingCalendarState;
  return {
    scenes: calendar.scenes,
    readOnly: calendar.readOnly,
    days: calendar.days,
    potentialDates: calendar.potentialDates,
    hasDayOrder: calendar.hasDayOrder,
    loading: calendar.loading,
    saving: calendar.saving,
    errors: calendar.errors,
    daysOfWeek: calendar.daysOfWeek,
    onboardingSteps: calendar.onboardingSteps,
    pristine: calendar.pristine,
    allowChangingDayOrder: calendar.allowChangingDayOrder
  };
}

export default connect(mapStateToProps, {
  fetchShootingDays,
  updateShootingCalendar,
  updateEvent,
  updateDate,
  changeStartDate,
  changeDayOfWeek
})(ShootingCalendarContainer);
