import React from "react";
import { Prompt, NavLink, Link } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Errors from "./../../../shared/errors";
import { renderPageNumberField } from "./../../../shared/form-helpers";
import CanAccessLink from "../../../shared/can-access-link";
import { formatTime } from "../../../shared/format-time";
import { act } from "react-test-renderer";
import ScenesWithoutDays from "../shooting-order/scenes-without-days";

function ShootingDayScenesForm(props) {
  const {
    filmId,
    shootingdayId,
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    readOnly,
    canAccessScenes,
    canAccessLocations,
    scenesWithoutDays,
    dragEnd
  } = props;
  const thisList = props.initialValues || {};
  const scenes = { actors: thisList };

  const onDragEnd = (result) => {

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    dragEnd(result);
    // if (result.type == "scene") {
    //   updateSceneOrder(
    //     result.source.index,
    //     result.destination.index,
    //     result.source.droppableId,
    //     result.destination.droppableId
    //   );
    // }
  };

  return (
    <>
      {/* <Prompt when={!pristine} message="" /> */}
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="scene">
            {(provided, snapshot) => (
              <div className="columns">
                <div className="column">
                  <FieldArray name="scenes" filmId={filmId} droppableProvided={provided} component={scenesListForm} readOnly={readOnly} canAccessScenes={canAccessScenes} canAccessLocations={canAccessLocations} shootingdayId={shootingdayId} />
                </div>
                <div className="column is-narrow">{!readOnly && <ScenesWithoutDays scenes={scenesWithoutDays} />}</div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!readOnly && <div className="buttons mt-1 sticky-buttons">
          <button
            type="submit"
            className="button"
            disabled={submitting}>
            Save
          </button>
        </div>}
      </form>
    </>
  );
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  //padding: grid * 2,
  borderRadius: "5px",
  //margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? "#222E44" : "#040F21",
  color: isDragging ? "white" : "white",
  // styles we need to apply on draggables
  ...draggableStyle
});

function scenesListForm({ filmId, fields, meta: { error }, readOnly, canAccessScenes, canAccessLocations, shootingdayId, droppableProvided }) {
  return (
    <div {...droppableProvided.droppableProps}
      ref={droppableProvided.innerRef}>
      <table className="table">
        <thead>
          <tr>
            <th>Number</th>
            <th>Location</th>
            <th>Int/Ext</th>
            <th>Day/Night</th>
            <th>Actors</th>
            <th>Pages</th>
            <th>Estimated Shooting Duration</th>
          </tr>
        </thead>
        <tbody>
          {(fields || []).map((scene, idx, records) => {
            return (
              <Draggable
                key={records.get(idx)?.sceneId}
                draggableId={records.get(idx)?.sceneId}
                index={idx}
                isDragDisabled={readOnly}
              >
                {(provided, snapshot) => (
                  <tr key={idx} ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}>
                    <td>
                      {!readOnly && <i className="material-icons">
                        drag_indicator
                      </i>}
                      <CanAccessLink
                        to={`/films/${filmId}/scenes/${records.get(idx)?.sceneId
                          }/edit`}
                        canAccess={canAccessScenes}>
                        {records.get(idx)?.number}
                      </CanAccessLink>
                    </td>
                    <td>
                      <CanAccessLink
                        to={`/films/${filmId}/locations/${records.get(idx)?.locationId
                          }/edit`}
                        canAccess={canAccessLocations}>
                        {records.get(idx).locationName && (records.get(idx).locationName + (records.get(idx).subLocationName ? " - " + records.get(idx).subLocationName : ""))}
                      </CanAccessLink>
                    </td>
                    <td>{records.get(idx).interiorExterior}</td>
                    <td>{records.get(idx).dayNight}</td>
                    <td>{records.get(idx).actors?.map((actor, index) => (<><CanAccessLink to={`/films/${filmId}/shooting-days/${shootingdayId}/actors#${actor.id}`} key={index} canAccess={true}>{actor.name}</CanAccessLink>{index < records.get(idx).actors.length - 1 ? ", " : ""}</>))}</td>
                    <td>
                      <Field
                        name={`${scene}.pageLength`}
                        type="time"
                        component={renderPageNumberField}
                        step="300"
                        readOnly={readOnly}
                      />
                    </td>
                    <td>{formatTime(records.get(idx).estimatedShootingDuration)}</td>
                  </tr>
                )}
              </Draggable>
            );
          })}
          {droppableProvided.placeholder}
          <tr style={{ backgroundColor: "#162135" }}>
            <td style={{ borderBottomWidth: 0 }} colSpan={11}></td>
          </tr>
        </tbody>
      </table>

      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default reduxForm({
  form: "shootingDayScenesForm",
  enableReinitialize: true
})(ShootingDayScenesForm);
