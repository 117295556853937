import getApiClient from "../../../../shared/api";

export const FETCH_FAQS = "FETCH_FAQS";

export function fetchFaqs(continuationToken, section) {
  return (dispatch) => {

    let continuationTokenQuery = "";
    if (continuationToken != null) {
      continuationTokenQuery = `continuationToken=${continuationToken}`;
    }

    let sectionQuery = "";
    if (section != null) {      
      sectionQuery = `${continuationTokenQuery ? "&" : ""}section=${section}`;
    }

    dispatch({
      type: FETCH_FAQS,
      payload: getApiClient().get(`/faq?${continuationTokenQuery}${sectionQuery}`),
      meta: { append: continuationToken != null}
    });
  };
}