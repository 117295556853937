import mapPictures from "../../../../shared/image-mapper";
import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { SEARCH_SCENES_FOR_COMMERCIAL_SCRIPT } from "../../../scenes/search/ducks/actions";
import { sortByName } from "./../../../../shared/utility";

interface ActionState {
  loading: boolean;
  loadingSceneSettings: boolean;
  readOnly: boolean;
  scenes: any[];
  searchingScenes: boolean;
  enableSave: boolean;
  selectedSceneIds: any[];
}

export const OnboardingStep1ClassName = 'props-scenes-onboarding-step-1';
export const OnboardingStep2ClassName = 'props-scenes-onboarding-step-2';
export const OnboardingStep3ClassName = 'props-scenes-onboarding-step-3';
export const OnboardingStep4ClassName = 'props-scenes-onboarding-step-4';
export const OnboardingStep5ClassName = 'props-scenes-onboarding-step-5';

const defaultState: ActionState = {
  loading: true,
  loadingSceneSettings: false,
  readOnly: false,
  scenes: [],
  searchingScenes: false,
  enableSave: false,
  selectedSceneIds: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_ALL_SCENES_FOR_SCRIPT_FULFILLED": {
      const scenes = action.payload.data.scenes.map((scene) => {
        return { ...scene, name: `${scene.number}. ${scene.settingName}` };
      });
      return {
        ...state,
        scenes,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_SCRIPT_PENDING": {
      return {
        ...state,
        loadingSceneSettings: true
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_SCRIPT_FULFILLED": {
      return {
        ...state,
        selectedSceneIds: action.payload.data.sceneIds,
        readOnly: action.payload.data.readOnly,
        loadingSceneSettings: false,
        loading: false,
        errors: null
      };
    }

    case "SET_SCENE_ASSIGNED_TO_SCRIPT": {
      return {
        ...state,
        selectedSceneIds: [action.meta.sceneId]
      };
    }

    case "FETCH_ALL_CHARACTERS_FOR_SCRIPT_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        loading: false,
        errors: null
      };
    }

    case "FETCH_ALL_SCENE_SETTINGS_FOR_SCRIPT_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case SEARCH_SCENES_FOR_COMMERCIAL_SCRIPT + "_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case SEARCH_SCENES_FOR_COMMERCIAL_SCRIPT + "_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        searchingScenes: false,
      };
    }

    case SEARCH_SCENES_FOR_COMMERCIAL_SCRIPT + "_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "CLEAR_SEARCH_SCENES_FOR_SCRIPT": {
      return {
        ...state,
        searchedScenes: [],
        loading: false,
        errors: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
