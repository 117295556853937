import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export const NOT_APPROVE_USERS = "NOT_APPROVE_USERS";
export const IGNORE_USERS = "IGNORE_USERS";

export function fetchUnapprovedUsers(showNotApproved) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_UNAPPROVED_USERS",
      payload: getApiClient().get(`/admin/users/Unapproved?ShowNotApproved=${showNotApproved ? "true" : "false"}`)
    });
  };
}

export function approveUsers(users) {
  const userIds = users.filter(user => user.selected).map((user) => user.id);

  return (dispatch) => {
    dispatch({
      type: "APPROVE_USERS",
      payload: getApiClient().put(`/admin/users/approve`, { userIds }),
      meta: { userIds }
    }).then((response) => {
      dispatch(showInfo(`Successfully approved users`));
    })
      .catch((response) => {
        dispatch(showError(`Error approving users`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function notApproveUsers(users) {
  const userIds = users.filter(user => user.selected).map((user) => user.id);

  return (dispatch) => {
    dispatch({
      type: NOT_APPROVE_USERS,
      payload: getApiClient().put(`/admin/users/notapprove`, { userIds }),
      meta: { userIds }
    }).then((response) => {
      dispatch(showInfo(`Successfully not approved users`));
    })
      .catch((response) => {
        dispatch(showError(`Error not approving users`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function ignoreUsers(users) {
  const userIds = users.filter(user => user.selected).map((user) => user.id);

  return (dispatch) => {
    dispatch({
      type: IGNORE_USERS,
      payload: getApiClient().put(`/admin/users/ignore`, { userIds }),
      meta: { userIds }
    }).then((response) => {
      dispatch(showInfo(`Successfully ignored users`));
    })
      .catch((response) => {
        dispatch(showError(`Error ignoring users`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
