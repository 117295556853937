import React from "react";

const PageToEighth = (props) => {
  const page = props.page;
  if (!page) {
    return null;
  }
  const wholePage = Math.floor(Math.abs(page));
  const fraction = page - wholePage;
  let eighth: any = null;
  if (fraction > 0) {
    eighth = Math.ceil(fraction / 0.125);
    eighth = eighth + "/8";
  }

  return (
    <>
      {wholePage > 0 && <span>{wholePage}</span>}
      {eighth ? <span className="eighth">&nbsp;{eighth}</span> : null}
    </>
  );
};

export default PageToEighth;
