
export const createMandyUrl = (ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, countryMandyId): string => {
  return createBackStageServiceUrl("www.mandy.com", ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, countryMandyId);
}

export const createStarNowUrl = (ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, countryMandyId): string => {
  return createBackStageServiceUrl("www.starnow.com", ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, countryMandyId);
}

export const createBackStageUrl = (ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, countryMandyId): string => {
  return createBackStageServiceUrl("www.backstage.com", ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, countryMandyId);
}

export const createBackStageServiceUrl = (url, ethnicitiesValue, ethnicityTypes, ageRangeValue, genderValue, countryMandyId): string => {
  let mandyEthnicityQuery = "";

  if (ethnicitiesValue) {
    const selectedEthnicityIds = ethnicitiesValue.map((e: any) => e.value);
    const selectedEthnicities = ethnicityTypes?.filter((e: any) => selectedEthnicityIds.includes(e.id));
    const nestedEthnicityStarNowIds = selectedEthnicities?.map((e: any) => e.starNowIds);
    const ethnicitiyStarNowIds = [].concat.apply([], nestedEthnicityStarNowIds);
    mandyEthnicityQuery = ethnicitiyStarNowIds.map((id) => `&ethnicities=${id}`).join("");
  }

  let ageQuery = "";
  const ageRange = ageRangeValue;
  if (ageRange != null) {
    var min = !ageRange.min || ageRange.min < 18 ? 18 : ageRange.min;
    var max = !ageRange.max || ageRange.max > 99 ? 99 : ageRange.max;
    ageQuery = `&max_age__gte=${min}&min_age__lte=${max}`;
  }

  let sexQuery = "";

  if (genderValue != null) {
    let sex: string | null = null;
    switch (genderValue) {
      case 2:
        sex = "1";
        break;
      case 1:
        sex = "2";
        break;
    }
    if (sex != null) {
      sexQuery = `&gender_selections=${sex}`;
    }
  }

  let countryQuery = "";
  if (countryMandyId) {
    countryQuery = `&country_id=${countryMandyId}`;
  }

  const finalQuery = `${ageQuery}${mandyEthnicityQuery}${sexQuery}${countryQuery}`;

  return `https://${url}/talent/grid?${finalQuery.substring(1)}`;
}

