import React, { CSSProperties } from "react";
import FilmaizeSelect from "./filmanize-select";

const timeSeparatorStyle: CSSProperties = {
  marginTop: "0.4rem"
};

const Time = (props) => {
  const {
    label,
    onChange,
    value,
    required = null,
    startTime = 0,
    endTime = 23,
    readOnly = false,
    reduxHelper = null,
    minuteGap = 5,
    canAddDay = true
  } = props;

  const { hours, minutes, days } = value;
  const getHours = () => {
    const items: any[] = [];
    let localEndTime = endTime;
    let localStartTime = startTime;
    if (endTime > 23) {
      if (days === 0) {
        localEndTime = 23;
      } else {
        localStartTime = 0;
        localEndTime = endTime - (days * 24);
      }
    }
    for (let hour = localStartTime; hour <= localEndTime; hour++) {
      items.push({ value: hour, label: (hour + '').padStart(2, '0') });
    }

    return items;
  };

  let selectHour = {};
  const selectedOption: any = getHours().find((x) => x.value === hours % 24);

  if (selectedOption) {
    selectHour = selectedOption;
  } else {
    selectHour = getHours()[0];
  }

  const onChangeHour = (event) => {
    let hour = event.value;
    return onChange({ hours: hour, minutes, days });
  };

  const onDayClick = (event) => {
    event.preventDefault();
    return onChange({ hours, minutes, days: days == 1 ? 0 : 1 });
  };

  const getMinutes = () => {
    const items: any[] = [];
    for (let minute = 0; minute < 60; minute += minuteGap) {
      items.push({ value: minute, label: (minute + '').padStart(2, '0') });
    }

    return items;
  };

  const onChangeMinute = (event) => {
    let minute = event.value;
    return onChange({ hours, minutes: minute, days });
  }

  let selectMinute = {};
  const selectedMinuteOption: any = getMinutes().find((x) => x.value === minutes);
  if (selectedMinuteOption) {
    selectMinute = selectedMinuteOption;
  }

  return (
    <div className="field">
      {label && (
        <label className={"label " + (readOnly ? "is-disabled" : "")}>
          {label}
          {required ? <span className="required">&nbsp;*</span> : null}
        </label>
      )}

      <div className="field-body time-container">
        <div className="time-width">
          <FilmaizeSelect
            value={selectHour}
            isMulti={false}
            options={getHours()}
            onChange={onChangeHour}
            placeholder=""
            isDisabled={readOnly}
          />
          <div className="time-seperator" style={timeSeparatorStyle}>
            :
          </div>
          <FilmaizeSelect
            value={selectMinute}
            isMulti={false}
            options={getMinutes()}
            onChange={onChangeMinute}
            placeholder=""
            isDisabled={readOnly}
          />
        </div>
        {canAddDay &&
          <button className="button plus-day-button" onClick={onDayClick}>{days == 0 ? "+" : "-"} Day</button>
        }
      </div>
      {reduxHelper}
    </div>
  );
};

export default Time;
