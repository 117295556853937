import './faq-help.scss';
import CkEditorContent from "../../../shared/controls/ckeditor-content";
import QuestionMarkIcon from "../../../films/icons/question-mark";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function QuestionSkeleton(props) {  
  const { 
    faq,    
    reference
     } = props;

  return (
    <SkeletonTheme baseColor="#d5e0f4" highlightColor="#91a5c9">
      <section ref={reference} className="faq-item">      
          <div className='faq-icon-container'>
            <Skeleton circle={true} width={38} height={38} />
          </div>          
          <div className='faq-question-container'>
            <div className="change-log-headline">
                <Skeleton width={`95%`}/>
              </div>
              <div className="faq-answer">
                 <Skeleton width={`95%`} count={3}/>
              </div>                
            </div>      
              
      </section>
    </SkeletonTheme>
  );
}

export default QuestionSkeleton;
