import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../shared/loader";
import BoxWraper from "../box-wrapper";
import {
  updateUser,
  fetchUser,
  getCountries,
  getLanguages
} from "./ducks/actions";
import Form from "./form";

function Register(props) {
  const {
    getCountries,
    getLanguages,
    fetchUser,
    updateUser,
    countries,
    languages,
    currencies,
    loading,
    errors,
    user
  } = props;

  useEffect(() => {
    getCountries();
    getLanguages();
    fetchUser();
  }, []);

  if (props.redirect) {
    if (props.redirect.to === "login") {
      props.clearRedirect();
      const url = `/login`;
      return <Redirect to={url} />;
    }
  }

  function onUpdateDetails(user) {
    updateUser(user);
  }

  return (
    <BoxWraper width={5}>
      {loading && <Loader />}
      {!loading && <Form
        errors={errors}
        loading={false}
        initialValues={user}
        onSubmit={onUpdateDetails}
        countries={countries}
        currencies={currencies}
        languages={languages}
        {...props}
      />}
    </BoxWraper>
  );
}

function mapStateToProps(state) {
  const { accountState, commonState } = state;
  return {
    user: accountState.updateUserState.user,
    countries: accountState.updateUserState.countries,
    languages: accountState.updateUserState.languages,
    currencies: commonState.currencies,
    errors: accountState.updateUserState.errors,
    loading: accountState.updateUserState.loading
  };
}

export default connect(mapStateToProps, {
  updateUser,
  getCountries,
  getLanguages,
  fetchUser
})(Register);
