import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import { fetchSceneSettings } from "./../scene-settings/ducks/actions";
import {
  addScene,
  clearRedirect,
  clearScene
} from "./ducks/actions";
import Onboarding from '../../shared/onboarding/onboarding';
import Form from "./form";

function AddScene(props) {
  const filmId = props.match.params.filmId;
  const {
    fetchSceneSettings,
    clearScene,
    addScene,
    scene,
    errors,
    scriptPagesPerDay,
    onboardingSteps,
    hasCommercialScript
  } = props;

  useEffect(() => {
    clearScene();
    fetchSceneSettings(filmId);
  }, []);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/scenes/`;
      return <Redirect to={url} />;
    }
  }

  function onAddScene(scene) {
    sanitiseDataTypes(scene);
    addScene(filmId, scene);
  }

  function sanitiseDataTypes(scene) {
    scene.dayNight = +scene.dayNight;
    scene.interiorExterior = +scene.interiorExterior;
    scene.scriptDay = scene.scriptDay ? +scene.scriptDay : null;
    scene.shootDays = scene.shootDays ? +scene.shootDays : null;
  }

  console.log("scene", scene);

  return (
    <ScrollIntoView
      className="blade-content medium-wide"
      path="/films/:filmId/scenes/add"
      loading={false}>
      <Form
        errors={errors}
        sceneId={null}
        initialValues={scene}
        loading={false}
        onSubmit={onAddScene}
        {...props}
        mode="add"
        reduxFormValues={props.reduxFormValues}
        scriptPagesPerDay={scriptPagesPerDay}
        hasCommercialScript={hasCommercialScript}
      />
      <Onboarding onboardingSteps={onboardingSteps} section="Scene" />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { scenesState, sceneSettingsState, commonState, form, filmsState } = state;
  const { rootState } = scenesState;

  return {
    scene: { ...rootState.scene, scriptPagesPerDay: commonState.user.scriptPagesPerDay },
    sceneSettings: sceneSettingsState.rootState.sceneSettings,
    dayNightEnum: commonState.dayNightEnum,
    interiorExteriorEnum: commonState.interiorExteriorEnum,
    errors: rootState.errors,
    redirect: rootState.redirect,
    onboardingSteps: rootState.onboardingSteps,
    reduxFormValues: form?.editSceneForm?.values,
    scriptPagesPerDay: commonState.user.scriptPagesPerDay,
    hasCommercialScript: filmsState.userFilm?.hasCommercialScript
  };
}

export default connect(mapStateToProps, {
  addScene,
  fetchSceneSettings,
  clearScene,
  clearRedirect
})(AddScene);
