import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../shared/loader";
import {
  aliasCharacter,
  assignActor,
  fetchCharacterOverview,
  clearCharacterOverview,
  unaliasCharacter,
  unassignActor,
  orderCharactersByScenes,
  orderCharactersByName
} from "./ducks/actions";
import Overview from "./overview";

const OverviewContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchCharacterOverview,
    assignActor,
    unassignActor,
    aliasCharacter,
    unaliasCharacter,
    overview,
    loading,
    errors,
    warnings,
    orderCharactersByScenes,
    orderCharactersByName,
    characterOrderBy,
    clearCharacterOverview
  } = props;

  useEffect(() => {
    fetchCharacterOverview(filmId);

    return function cleanUp() {
      clearCharacterOverview();
    };
  }, [filmId, fetchCharacterOverview, clearCharacterOverview]);

  function renderOverview(overview) {
    const charcactersWithActors = overview.characters?.filter(
      (c) => c.actorId
    ).length;
    const allCharcacters = overview.characters?.length;
    const charactersAssignedPercentage =
      !allCharcacters || allCharcacters === 0
        ? 0
        : (charcactersWithActors / allCharcacters) * 100;

    return (
      <ScrollIntoView
        className="blade-content charcterOverview"
        path="/films/:filmId/characters/overview"
        loading={loading}>
        <h2 className="menu-label">Overview</h2>
        <Overview
          overview={overview}
          filmId={filmId}
          charactersAssignedPercentage={charactersAssignedPercentage}
          assignActor={assignActor}
          unassignActor={unassignActor}
          aliasCharacter={aliasCharacter}
          unaliasCharacter={unaliasCharacter}
          errors={errors}
          warnings={warnings}
          characterOrderBy={characterOrderBy}
          orderCharactersByScenes={orderCharactersByScenes}
          orderCharactersByName={orderCharactersByName}></Overview>
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !overview) && <Loader />}
      {!loading && overview && renderOverview(overview)}
    </>
  );
};

function mapStateToProps(state) {
  const { errors, warnings, overview, loading, characterOrderBy } =
    state.charactersState.overviewState;
  return {
    errors,
    warnings,
    overview,
    loading,
    characterOrderBy
  };
}

export default connect(mapStateToProps, {
  fetchCharacterOverview,
  clearCharacterOverview,
  assignActor,
  unassignActor,
  aliasCharacter,
  unaliasCharacter,
  orderCharactersByScenes,
  orderCharactersByName
})(OverviewContainer);
