import { useEffect } from "react";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { connect } from "react-redux";
import SmartBreakdown from "./smart-breakdown";
import {
    smartBreakdown,
    setUploadError,
    clearScriptUpload
} from "./ducks/actions";
import { fetchScript } from '../ducks/actions';
import Errors from "../../../shared/errors";

const SmartBreakdownContainer = (props: any) => {
    const {
        filmId,
        smartBreakdown,
        progress,
        completed,
        fetchScript,
        clearScriptUpload,
        errors
    } = props;

    useEffect(() => {
        clearScriptUpload();
    }, []);

    useEffect(() => {
        if (completed) {
            fetchScript(filmId);
        }
    }, [completed]);

    const onSmartBreakdown = () => {
        smartBreakdown(filmId);
    };

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/script/upload"
            loading={false}>
            <div>
                <h2 className="menu-label">Smart Breakdown</h2>
                <Errors errors={errors} />
                <SmartBreakdown
                    smartBreakdown={onSmartBreakdown}
                    progress={progress}
                />
            </div>
        </ScrollIntoView>
    )
};

function mapStateToProps(state) {
    const smartBreakdownState = state.scriptState.smartBreakdownState;
    return {
        loading: smartBreakdownState.loading,
        readOnly: smartBreakdownState.readOnly,
        errors: smartBreakdownState.errors,
        progress: smartBreakdownState.progress,
        completed: smartBreakdownState.completed
    };
}

export default connect(mapStateToProps, {
    smartBreakdown,
    setUploadError,
    fetchScript,
    clearScriptUpload
})(SmartBreakdownContainer);