import React from "react";
import InputSlider from "../../shared/controls/input-slider";
import NumberFormat from "react-number-format";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function SetDressing(setDressings) {
  const {
    setDressing,
    onChangeSetDressingBuget,
    onChangeSetDressingDays,
    maxDays,
    hasShootingDays,
    readOnly,
    filmId,
    saving
  } = setDressings;

  return (
    <tr>
      <td><Link to={`/films/${filmId}/set-dressings/${setDressing.id}/edit`}>{setDressing.name}</Link></td>
      <td className="budget-days-column">
        {setDressing.hire ? (
          hasShootingDays ? (
            setDressing.days
          ) : (
            <InputSlider
              step={1}
              formatLabel={(value) => `${value}`}
              draggableTrack={false}
              allowSameValues={false}
              maxValue={maxDays}
              minValue={1}
              value={setDressing.days}
              onChange={(val) => onChangeSetDressingDays(setDressing.id, val)}
              disabled={readOnly || saving}
            />
          )
        ) : (
          "-"
        )}
      </td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={
            setDressing.hire ? setDressing.dailyBudget : setDressing.budget
          }
          onChange={(val) => onChangeSetDressingBuget(setDressing.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td>{setDressing.quantity}</td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={setDressing.total}
        />
      </td>
    </tr>
  );
}

export default SetDressing;
