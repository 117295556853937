import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

export interface IPosition {
    x: number;
    y: number;
  }
  
export interface IContextMenuItem {
    name: string;
    icon: string;
  }
  
  interface ContextMenuItemProps {
    index: number;
    name: string;
    icon: string;
    active: boolean;
    position: IPosition;
    offset: IPosition;
    itemClick: any;
  }
  
  const ContextMenuItem: React.FC<ContextMenuItemProps> = (props: ContextMenuItemProps) => {
    const 
          [active, setActive] = React.useState<boolean>(false),
          [visible, setVisible] = useState<boolean>(false),
          [position, setPosition] = useState({ x: 0, y: 0 });
    
    useEffect(() => {      
      if(props.active){
        console.log("active updated to true");        
        setActive(props.active);
        setPosition({ x: props.position.x, y: props.position.y });
        setTimeout(() => setVisible(true), 50);
      }
      else{
        console.log("active updated to false");
        setVisible(false);
        setTimeout(() => setActive(false), 350);
      }
    }, [props.active]);
    
    useEffect(() => {
      console.log("position updated");
      setPosition({ x: props.position.x, y: props.position.y });
    }, [props.position]);
    
    const selectItem = (item) => {
        const text = (window as any).getSelection().toString().trim();        
        props.itemClick({ item, text });
    };
    
    if(active){
      const getStyles = (): React.CSSProperties => {
        const left: number = visible ? position.x + props.offset.x : position.x,
              opacity: number = visible ? 1 : 0,
              top: number = visible ? position.y + props.offset.y : position.y;
        
        return {
          left: `${left}px`,
          opacity,
          top: `${top}px`,
          transitionDelay: `${props.index * 50}ms`
        }
      }
      
      return ReactDOM.createPortal((
        <button type="button" className="context-menu-item" style={getStyles()} onClick={() => selectItem(props.name)}>
          {props.icon}
          <h1>{props.name}</h1>
        </button>
      ), document.getElementById("app")!);
    }
  
    return null;
  }  
  
  export default ContextMenuItem;