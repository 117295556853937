import React from "react";
import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Character from "./character";
import Actor from "./actor";
import Errors from "../../../shared/errors";
import HelpText from "../../../shared/help-text";
import { OnboardingStep1ClassName } from './ducks/reducer';

function Overview(props) {
  const {
    overview,
    filmId,
    charactersAssignedPercentage,
    assignActor,
    unassignActor,
    aliasCharacter,
    unaliasCharacter,
    errors,
    warnings
  } = props;

  const readOnly = overview.readOnly;

  function Charts() {
    return (
      <div className="columns">
        <div className="circular-progress column is-one-quarter">
          <FilmalityCircularProgress
            percentage={charactersAssignedPercentage}
            title="Background Characters Assigned"
          />
        </div>
      </div>
    );
  }

  function RenderHelpText() {
    return (
      <HelpText>
        Here you can manage your Background Characters:
        <ul>
          <li>
            Assign Background Actors to Background Characters by dragging the
            Background Actor's name from the list on the right into the
            'Background Actor's Name' column for the Background Character.
          </li>
        </ul>
        <p>
          Made a mistake? Click <i className="material-icons">cancel</i> to
          unassign.
        </p>
      </HelpText>
    );
  }

  function CharactersList() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Background Character</th>
            <th>No.</th>
            <th>Background Actor</th>
            <th>Mandy Url</th>
            <th>Starnow Url</th>
          </tr>
        </thead>
        <tbody>
          {(overview.backgroundCharacters || []).map((character, index) => {
            return (
              <Character
                key={index}
                index={index}
                character={character}
                filmId={filmId}
                assignActor={assignActor}
                unassignActor={unassignActor}
                aliasCharacter={aliasCharacter}
                unaliasCharacter={unaliasCharacter}
                readOnly={readOnly}
                canAccessBackgroundActors={overview.canAccessBackgroundActors}></Character>
            );
          })}
        </tbody>
      </table>
    );
  }

  function ActorsList() {
    return (
      <table className={`${OnboardingStep1ClassName} table sticky`}>
        <thead>
          <tr>
            <th>Background Actor</th>
          </tr>
        </thead>
        <tbody>
          {(overview.backgroundActors || []).map((actor, index) => {
            return (
              <Actor
                key={index}
                actor={actor}
                index={index}
                filmId={filmId}
                readOnly={readOnly}></Actor>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div>
      <Charts />
      {errors && <Errors errors={errors} />}
      {warnings && <Errors errors={warnings} isWarning={true} />}
      <DndProvider backend={HTML5Backend}>
        <div className="columns">
          <div className="column">
            <CharactersList />
          </div>
          <div className="column is-narrow">
            {!readOnly && <ActorsList />}
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

export default Overview;
