import React, { useState } from "react";
import { Link } from "react-router-dom";

import './nav.scss';

const Nav = ({ tranparent, appUrl, registerUrl }: any) => {

    const [phoneMenuVisible, setPhoneMenuVisible] = useState(false);

    const burgerClick = () => {
        setPhoneMenuVisible(!phoneMenuVisible);
    };

    const hideMenu = () => {
        setPhoneMenuVisible(false);
    };



    return (
        <nav className={`navbar marketing-navbar ${(tranparent && !phoneMenuVisible) ? "is-transparent" : "white"} is-fixed-top`}>
            <div className="marketing-navbar-brand navbar-brand">
                <a className="navbar-item" href="#home">
                    <div className={`nav-logo ${(tranparent && !phoneMenuVisible) ? "invisible" : ""}`}>
                        <img src="/images/logo-black.svg" alt="Filmanize: film production management" />
                        <span className="tag is-purple is-beta">Beta</span>
                    </div>
                </a>

                <div className={`navbar-burger ${phoneMenuVisible ? "open" : ""}`} data-target="navbarFilmanize" onClick={burgerClick}>
                    <span className="bar1"></span>
                    <span className="bar2"></span>
                    <span className="bar3"></span>
                </div>
            </div>

            <div id="navbarFilmanize" className={`navbar-menu ${phoneMenuVisible ? "is-active" : ""}`}>
                <div className="navbar-start">

                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <a href="#features" className={`${(tranparent) ? "green-hover-link" : "purple-hover-link"}`} onClick={hideMenu}>Features</a>
                    </div>
                    <div className="navbar-item">
                        <a href="#pricing" className={`${(tranparent) ? "green-hover-link" : "purple-hover-link"}`} onClick={hideMenu}>Pricing</a>
                    </div>
                    <div className="navbar-item">
                        <a href={registerUrl} className={`button is-light ${(tranparent) ? "" : "green-border"}`}>Try the Beta</a>
                    </div>
                    <div className="navbar-item">
                        <a href={`${appUrl}/login`} className="button hollow-button">Login</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Nav;