import { withCallbacks, signalMiddleware } from "redux-signalr";
import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel
} from "@microsoft/signalr"; // version 1.0.4
import getConfig from "../app/config";
import auth from "../shared/auth";

let config = getConfig();
const urlRoot = config.apiUrl;
let url = urlRoot.substring(0, urlRoot.length - 1);
var to = url.lastIndexOf("/");
to = to == -1 ? url.length : to + 1;
url = url.substring(0, to);
const connectionHub = url + `messageHub`;

const protocol = new JsonHubProtocol();

// let transport to fall back to to LongPolling if it needs to
const transport =
  HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
  logMessageContent: true,
  logger: LogLevel.Error,
  accessTokenFactory: () => auth.getSignalRToken()
};

// create the connection instance
export const connection = new HubConnectionBuilder()
  .withUrl(connectionHub, options)
  .withHubProtocol(protocol)
  .withAutomaticReconnect()
  .build();

export const UPDATE_PERSON_MESSAGE_EMAIL_STATUS = "UPDATE_PERSON_MESSAGE_EMAIL_STATUS";
export const UPDATE_PERSON_MESSAGE_SUBMITTED = "UPDATE_PERSON_MESSAGE_SUBMITTED";
export const UPDATE_PERSON_MESSAGE_PROGRESS = "UPDATE_PERSON_MESSAGE_PROGRESS";

const callbacks = withCallbacks()
  .add('PersonMessageEmailStatus', (messageType,
    personId,
    personType,
    status,
    statusDescription,
    statusChangedDate) => (dispatch) => {
      dispatch({
        type: UPDATE_PERSON_MESSAGE_EMAIL_STATUS,
        meta: {
          messageType,
          personId,
          personType,
          status,
          statusDescription,
          statusChangedDate
        }
      });
    })
  .add('SendPersonMessageProgress', (messageType,
    progress,
    message) => (dispatch) => {
      dispatch({
        type: UPDATE_PERSON_MESSAGE_PROGRESS,
        meta: { messageType, progress: { progress: progress, message: message } }
      });
    })
  .add('PersonMessageSubmitted', (messageType,
    personId,
    personType) => (dispatch) => {
      dispatch({
        type: UPDATE_PERSON_MESSAGE_SUBMITTED,
        meta: {
          messageType,
          personId,
          personType
        }
      });
    });

export const signal = signalMiddleware({
  callbacks,
  connection,
  shouldConnectionStartImmediately: false
});

