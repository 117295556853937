import { getAnnonymousApiClient } from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export function contact(user, inApp, reCaptchaToken) {
  const data = { ...user, inApp, reCaptchaToken }
  return (dispatch) => {
    dispatch({
      type: "CONTACT",
      payload: getAnnonymousApiClient().post(`/contact`, data),
      meta: { user }
    }).then((response) => {
      dispatch(showInfo(`Successfully sent message`));
    })
      .catch((response) => {
        dispatch(showError(`Error sending message`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearContact() {
  return (dispatch) => {
    dispatch({
      type: "CONTACT_CLEAR",
    });
  };
}