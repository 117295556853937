import OverviewContainer from "./list-container";
import { PrivateRoute } from "../../../shared/private-route";

const DepartmentRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/departments/overview"
        component={OverviewContainer}
      />
    </>
  );
};

export default DepartmentRoutes;
