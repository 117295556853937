import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchSetDressingsInScene } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import { Link } from "react-router-dom";

function ListSetDressingsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchSetDressingsInScene, loading, sceneSetDressings, errors, readOnly } = props;

  useEffect(() => {
    fetchSetDressingsInScene(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(sceneSetDressings, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List setDressings={sceneSetDressings} filmId={filmId} sceneId={sceneId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <Link
          to={`/films/${filmId}/scenes/${sceneId}/set-dressings/add`}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Set Dressing
        </Link>}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(sceneSetDressings, filmId, sceneId);
}

function mapStateToSetDressings(state) {
  return {
    sceneSetDressings: state.scenesState.setDressingState.setDressings,
    loading: state.scenesState.setDressingState.loading,
    errors: state.scenesState.setDressingState.errors,
    readOnly: state.scenesState.setDressingState.readOnly
  };
}

export default connect(mapStateToSetDressings, {
  fetchSetDressingsInScene
})(ListSetDressingsContainer);
