import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import auth from "./auth";
import UploadIcon from "../films/icons/upload-large";
import DeleteIcon from "../films/icons/delete";
import confirm from "./modal/confirm";
import "./images.scss";
import SmallLoader from "./small-loader/small-loader";

function SingleImageUpload(props) {
  const { setImage, imageFileName, label, readOnly, maximumImageHeight } = props;

    const [previewImage, setPreviewImage] = useState<any>();  
    const [imageLoading, setImageLoading] = useState<any>();  

    useEffect(() => {
      if (imageFileName) {     
        setImageLoading(true);
        setPreviewImage(imageFileName + `?t=${auth.getSignalRToken()}`);
      } else {
        setPreviewImage(null);
      }
    },[imageFileName]);

    const onDropFile = (file: any) => {
      setImage(file);
      const uploadedFile = URL.createObjectURL(file);
      setImageLoading(true);
      setPreviewImage(uploadedFile);
    };

    const onRemove = () => {      
      confirm(() => {
        setImage(null);      
        setPreviewImage(null);
      }, "Production Company Logo");      
    };

    const acceptedFileTypes =
    "image/x-png, image/png, image/jpg, image/jpeg, image/gif";

    return (
    <>
    <div >
        <label className={"label " + (readOnly ? "is-disabled" : "")}>
          {label ?? "Images"}
        </label>       
    </div>
    <div className="mt-1">
      {imageLoading && previewImage && <SmallLoader/>}
      {previewImage && 
        <div className="main-image-container">
              <img
                className="single-image"
                src={previewImage}
                alt={label}                
                onLoad={() => setImageLoading(false)}
              />
              {!readOnly && !imageLoading && (
                <div className="single-image-menu-icon">
                  <DeleteIcon
                    className="image-edit-icon"
                    onClick={onRemove}
                  />
                </div>
              )}
            </div>
        }
      
         {!previewImage &&  <Dropzone
            accept={acceptedFileTypes}
            onDrop={(acceptedFiles) => {
              let allFiles = acceptedFiles;
              
              onDropFile(allFiles[0]);
            }}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} className={`image-drop-area ${isDragActive ? "active" : ""}`} >
                <input {...getInputProps()} />
                <div className={`image-upload-area ${isDragActive ? "active" : ""}`}>
                  <UploadIcon className={`image-upload-icon ${isDragActive ? "active" : ""}` } />
                  <p>Drag and drop image files here, or click to add.</p>
                </div>
              </div>
            )}
          </Dropzone>
          }
    </div>
    </>
    );
}

export default SingleImageUpload;
