import getApiClient from "../../../../shared/api";

export const FETCH_COLLABORTARORS_IN_ROLES = "FETCH_COLLABORTARORS_IN_ROLES";

export function fetchCollaboratorsInRoles(filmId, roleId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COLLABORTARORS_IN_ROLES,
      payload: getApiClient().get(`/film/${filmId}/collaborator/InRole/${roleId}`),
    });
  };
}
