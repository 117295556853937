import React, { useRef, useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";

const ScrollIntoView = (props) => {
  const { loading = false, path, children } = props;

  const location = useLocation();

  const scrollIntoViewEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      scrollIntoViewEl?.current &&
      !loading &&
      matchPath(location.pathname, { path, exact: true })
    ) {
      window.scrollTo({
        top:
          window.scrollY +
          scrollIntoViewEl.current.getBoundingClientRect().top -
          100,
        left:
          window.scrollX +
          scrollIntoViewEl.current.getBoundingClientRect().left -
          scrollIntoViewEl.current.clientWidth,
        behavior: "smooth"
      });
    }
  }, [location, scrollIntoViewEl, loading, path]);

  return (
    <div ref={scrollIntoViewEl} {...props}>
      {children}
    </div>
  );
};

export default ScrollIntoView;
