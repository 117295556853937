import { HashLink } from 'react-router-hash-link';

interface CanAccessLinkProps {
    to: string;
    children: any;
    canAccess: boolean;
    id?: string;
    scrollWithOffset?: any;
}

const CanAccessLink = ({ to, children, canAccess, id, scrollWithOffset }: CanAccessLinkProps) => {

    return (
        <>
            {canAccess && <HashLink to={to} id={id} scroll={scrollWithOffset}>
                {children}
            </HashLink>}
            {!canAccess && <>{children}</>}
        </>
    );
};

export default CanAccessLink;