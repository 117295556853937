import React from "react";
import ListCollaboratorRolesContainer from "./list-container";
import AddCollaboratorRoles from "./add";
import EditCollaboratorRoles from "./edit";
import { PrivateRoute } from "../../../shared/private-route";

const UserRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/collaborators/collaborator-roles"
        component={ListCollaboratorRolesContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/collaborators/collaborator-roles/add"
        component={AddCollaboratorRoles}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/collaborators/collaborator-roles/:collaboratorRoleId"
        component={EditCollaboratorRoles}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/collaborators/collaborator-roles/:collaboratorRoleId/edit"
        component={EditCollaboratorRoles}
      />
    </>
  );
};

export default UserRoutes;
