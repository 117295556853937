import SelectionCalendar from "../scheduling/selection-calendar";

const LocationAvailability = (props) => {
    const { daysOfWeek, availableDates, updateEvent, addDays, deleteDay, deleteDays, changeDayOfWeek } = props;
    const hasPotentialDates = availableDates.findIndex(d => d.isPotentialDate) > -1;
    return (
        <div>
            <SelectionCalendar
                daysOfWeek={daysOfWeek}
                days={availableDates}
                readOnly={undefined}
                updateEvent={updateEvent}
                addDays={addDays}
                deleteDay={deleteDay}
                deleteDays={deleteDays}
                changeDayOfWeek={changeDayOfWeek}
            />
            <div className="shooting-days-potential-day-container">
                <div className="shooting-days-potential-day-background"></div>
                Potential Date
            </div>
            {/* <div className='mt-1'>
                Key:
                <span className="tag tag-key tag-location">Available</span>
                {hasPotentialDates && <span className="tag tag-key tag-available">Potential Date</span>}
            </div> */}
        </div>
    )
}

export default LocationAvailability;