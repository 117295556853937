import React from "react";
import ScriptRoutes from "./../film-scripts/routes";
import FilmsRoutes from "./../films/routes";
import UserRoutes from "./../user/routes";
import AccountRoutes from "../account/routes";
import StaticRoutes from "./../static/routes";
import AdminRoutes from "./../admin/routes";
import ChangeLogRoutes from "./../change-log/routes";
import FilmsAddContainer from "./../films/film/add-container";
import FeatureRequetstRoutes from "./feature-request/routes";
import NotFound from "./not-found";
import { Route, Switch } from "react-router-dom";
import CustomSwitch from "./custom-switch";
import { PrivateRoute } from "../shared/private-route";
import ImportRoutes from "../import-film/routes";

function AppRoutes(props) {
  return (
    <>
      <AccountRoutes />

      <ScriptRoutes />
      <ImportRoutes />
      <UserRoutes />
      <ChangeLogRoutes />
      <PrivateRoute exact path="/films/add" component={FilmsAddContainer} />
      <FeatureRequetstRoutes />
      <div className="blade-container scroll">
        <FilmsRoutes />

        <StaticRoutes />
        <AdminRoutes />

        <div className="blade-content minimal">
          <span id="rhsOfBlades">&nbsp;</span>
        </div>
      </div>
    </>
  );
}

export default AppRoutes;
