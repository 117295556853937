import React from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Errors from "../../../shared/errors";
import {
  renderCheckbox,
  renderCheckboxGroup,
  renderDateField,
  renderTextAreaField,
  renderTextField,
  renderTimeField
} from "../../../shared/form-helpers";
import required, { maxDecimalValue } from "../../../shared/validation";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName
} from './ducks/reducer';
import { timeDiffMinutes, formatTimeFromMinutes } from "../../../shared/format-time";

let ShootingDaySettingsForm = (props: any) => {
  const { handleSubmit,
    pristine,
    submitting,
    errors,
    showHoursBeforeToSendCallSheet,
    crewCallValue,
    estimatedWrapValue } = props;

  let thisSettings = props.initialValues || {};
  const readOnly = thisSettings.readOnly;

  const dayLengthMinutes = timeDiffMinutes(crewCallValue, estimatedWrapValue);

  return (
    <>
      <Prompt when={!pristine} message="" />
      <p className="menu-label">Shooting Days Settings</p>
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="columns">
          <div className="column">
            <div className={OnboardingStep1ClassName}>
              <Field
                name="crewCall"
                label="Crew Call"
                component={renderTimeField}
                type="time"
                step="300"
                canAddDay={false}
                validate={[required]}
                required
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="column">
            <Field
              name="actorCall"
              label="Actor Call"
              component={renderTimeField}
              type="time"
              step="300"
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Field
              name="shootingCall"
              label="Shooting Call"
              component={renderTimeField}
              type="time"
              step="300"
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
          <div className="column">
            <Field
              name="estimatedWrap"
              label="Estimated Wrap"
              component={renderTimeField}
              type="time"
              step="300"
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Field
              name="lunch"
              label="Lunch"
              component={renderTimeField}
              type="time"
              step="300"
              validate={[required]}
              required
              readOnly={readOnly}
            />
          </div>
          <div className="column">
            <div className={OnboardingStep2ClassName}>
              <Field
                name="eveningMeal"
                label="Evening Meal"
                component={renderTimeField}
                type="time"
                step="300"
                readOnly={readOnly}
              />
            </div>
          </div>
        </div>

        {/* <div className="columns">
          <div className={`column ${OnboardingStep2ClassName}`}>
          <Field
            className={`mr-1`}
            name="automaticallySendCallSheets"
            label="Automatically Send Call Sheets"            
            component={renderCheckbox}
            readOnly={readOnly} />                      
          </div>
          <div className="column">
          {showHoursBeforeToSendCallSheet && <Field                        
            name="hoursBeforeToSendCallSheet"
            label="Hours before call time"
            type="number"
            component={renderTextField}
            validate={[maxDecimalValue]}
            readOnly={readOnly}/>}
          </div>
        </div> */}

        {/* <div className="columns">
          <div className="column">
            <div className={OnboardingStep3ClassName}>
              <Field
                name="callSheetSectionsList"
                label="Call Sheet Sections"
                component={renderCheckboxGroup}
                value={thisSettings.callSheetSections}
                options={callSheetSectionEnum}
                textProperty="name"
                valueProperty="value"
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="column">
            <div className={OnboardingStep4ClassName}>
              <Field
                  name="weatherSectionsList"
                  label="Weather Sections"
                  component={renderCheckboxGroup}
                  options={weatherSectionEnum}
                  value={thisSettings.weatherSections}
                  textProperty="name"
                  valueProperty="value"
                  readOnly={readOnly}
                />
            </div>
          </div>
        </div> */}

        <div className="mb-1">
          Day Length: {formatTimeFromMinutes(dayLengthMinutes)}
        </div>

        {!readOnly && <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>
        </div>}
      </form>
    </>
  )
};

// export default reduxForm({
//     form: "shootingDaySettings",
//     enableReinitialize: true
//   })(ShootingDaySettingsForm);


const form = "shootingDaySettings";

ShootingDaySettingsForm = reduxForm({
  form: form,
  enableReinitialize: true
})(ShootingDaySettingsForm);

const selector = formValueSelector(form);

const ShootingDaySettingsFormConnect = connect(state => {
  const showHoursBeforeToSendCallSheet = selector(state, 'automaticallySendCallSheets')
  const crewCallValue = selector(state, 'crewCall');
  const estimatedWrapValue = selector(state, 'estimatedWrap');

  return {
    showHoursBeforeToSendCallSheet,
    crewCallValue,
    estimatedWrapValue
  }
})(ShootingDaySettingsForm);


export default ShootingDaySettingsFormConnect;