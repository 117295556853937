import * as React from 'react'

export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.021" height="17" viewBox="0 0 15.021 17" {...props}>
    <g id="Icon_Delete" data-name="Icon / Delete" >
      <path id="Path_32779" data-name="Path 32779" d="M-85.469,3.6V16.76A1.24,1.24,0,0,1-86.709,18H-95.39a1.24,1.24,0,0,1-1.24-1.24V3.6" transform="translate(98.06 -2)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_540" data-name="Line 540" x2="14.021" transform="translate(0 1.595)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      <path id="Path_32780" data-name="Path 32780" d="M-94.623,3.54V2.248A.248.248,0,0,1-94.375,2h6.652a.248.248,0,0,1,.248.248V3.54" transform="translate(98.06 -2)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      <g id="Group_3695" data-name="Group 3695" transform="translate(98.06 -2)">
        <line id="Line_541" data-name="Line 541" y2="8.082" transform="translate(-91.049 6.655)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_542" data-name="Line 542" y2="5.443" transform="translate(-88.542 7.975)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <line id="Line_543" data-name="Line 543" y2="5.443" transform="translate(-93.557 7.975)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      </g>
    </g>
  </svg>
