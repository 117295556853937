import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Field } from "redux-form";
import auth from "./auth";
import UploadIcon from "../films/icons/upload-large";
import AddIcon from "../films/icons/add";
import EditIcon from "../films/icons/edit";
import DeleteIcon from "../films/icons/delete";
import confirm from "../shared/modal/confirm";
import "./images.scss";

function Images(props) {
const { label, singleImage } = props;


  const [url, setUrl] = useState("");
  const [mainImageIndex, setMainImageIndex] = useState(
    props.initialValues?.mainImageIndex
  );
  const [mainImageLoading, setMainImageLoading] = useState(false);

  useEffect(() => {
    setMainImageLoading(true);
  },[]);

  const readOnly = props.readOnly;

  const acceptedFileTypes =
    "image/x-png, image/png, image/jpg, image/jpeg, image/gif";

  const renderDropzoneInput = (field) => {
    const files = field.input.value;

    function onSetUrl(e) {
      setUrl(e.target.value);
    }

    const isImage = (url, callback, errorcallback) => {
      var img = new Image();
      if (typeof errorcallback === "function") {
        img.onerror = function () {
          errorcallback();
        };
      } else {
        img.onerror = function () {
          return false;
        };
      }
      if (typeof callback === "function") {
        img.onload = function () {
          callback();
        };
      } else {
        img.onload = function () {
          return true;
        };
      }

      img.src = url;
    };

    const onAddUrl = (event) => {
      event.preventDefault();
      // check url is actually an image
      //var myRegex = /(https?:\/\/.*\.(?:png|jpg|gif))/i;
      if (!url) {
        return;
      }

      isImage(
        url,
        () => {
          let found =
            files &&
            files.find((f) => {
              return f.url === url;
            });
          if (!found) {
            let urlFile = { url: url, isUploaded: true };
            var allFiles = [urlFile];
            if (files) {
              allFiles = [...files, urlFile];
            }
            field.input.onChange(allFiles);

            if (files.length === 0) {
              props.change(`mainImageIndex`, "0");
              setMainImageIndex(0);
            }
          }
          setUrl("");
        },
        () => {
          confirm(null, null, "Not an image url", "Error");
        }
      );
    };

    const changeMainImageUrl = (index) => {
      props.change(`mainImageIndex`, index);
      setMainImageIndex(index);
    };

    const onDeleteImage = (imageIndex) => {
      const deleteImage = (imageIndex) => {
        if (mainImageIndex === imageIndex) {
          props.change(`mainImageIndex`, "0");
          setMainImageIndex("0");
        }
        const remainingFiles = files.filter((file, idx) => {
          return idx !== imageIndex;
        });
        field.input.onChange(remainingFiles);
      };
      confirm(() => deleteImage(imageIndex), `Image ${imageIndex + 1}`);
    };

    const mainImage = mainImageIndex >= 0 ? files[mainImageIndex] : null;

    let rootUrl = window.location.host;
    const urlColonIndex = rootUrl.indexOf(":");
    if (urlColonIndex !== -1) {
      rootUrl = rootUrl.substr(0, urlColonIndex);
    }

    const urlDotIndex = rootUrl.indexOf(".");
    if (urlDotIndex !== -1) {
      const splitUrl = rootUrl.split(".");
      rootUrl =
        splitUrl[splitUrl.length - 2] + "." + splitUrl[splitUrl.length - 1];
    }

    return (
      <div>
        <label className={"label " + (readOnly ? "is-disabled" : "")}>
          {label ?? "Images"}
        </label>
        {mainImage && (
          <div className="main-image-container">
            <img
              className="main-picture"
              src={
                mainImage.preview
                  ? mainImage.preview
                  : mainImage.url +
                  (mainImage.url.indexOf(rootUrl) === -1
                    ? ""
                    : "?t=" + auth.getImageToken())
              }
              onLoad={() => setMainImageLoading(false)}
              alt=""
            />
            {!readOnly && !mainImageLoading && (
              <div className="main-image-menu-icon">
                <DeleteIcon
                  className="image-edit-icon"
                  onClick={() => onDeleteImage(mainImageIndex)}
                />
              </div>
            )}
          </div>
        )}
        {!singleImage && files && Array.isArray(files) && files.length > 0 && (
          <ul className="picture-list is-clearfix">
            {files.map((file, i) => (
              <>
                {i !== mainImageIndex && (
                  <li className="picture-list-item" key={i}>
                    <div className="picture-container-label">
                      <div className="picture-container">
                        <img
                          className="picture"
                          src={
                            file.preview
                              ? file.preview
                              : file.url +
                              (file.url.indexOf(rootUrl) === -1
                                ? ""
                                : "?t=" + auth.getImageToken())
                          }
                          alt=""
                        />
                        {!readOnly && (
                          <>
                            <div className="image-menu-icon">
                              <EditIcon className="image-edit-icon" />
                              <div className="image-dropdown-content">
                                <button className="button" onClick={() => onDeleteImage(i)}>
                                  Delete image
                                </button>
                                {i !== mainImageIndex && (
                                  <button className="button" onClick={() => changeMainImageUrl(i)}>
                                    Make main image
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </li>
                )}
              </>
            ))}
          </ul>
        )}

        {(!readOnly && !(files?.length > 0 && singleImage)) && (
          <Dropzone
            accept={acceptedFileTypes}
            onDrop={(acceptedFiles) => {
              acceptedFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file)
                })
              );

              let allFiles = acceptedFiles;
              if (files) {
                allFiles = [...files, ...allFiles];
              }

              field.input.onChange(allFiles);

              if (files.length === 0) {
                props.change(`mainImageIndex`, "0");
                setMainImageIndex(0);
              }
            }}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} className={`image-drop-area ${isDragActive ? "active" : ""}`} >
                <input {...getInputProps()} />
                <div className={`image-upload-area ${isDragActive ? "active" : ""}`}>
                  <UploadIcon className={`image-upload-icon ${isDragActive ? "active" : ""}` } />
                  <p>Drag and drop image files here, or click to add.</p>
                </div>
              </div>
            )}
          </Dropzone>
        )}
        {field.meta.touched && field.meta.error && (
          <span className="error">{field.meta.error}</span>
        )}
        {!readOnly && !singleImage && (
          <>
            <div className="field">
              <label className="label">Image Url</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  key="urlEditor"
                  value={url}
                  placeholder="http://"
                  onChange={onSetUrl}
                />
              </div>
            </div>
            <div className="field">
              <button className="button is-small" onClick={onAddUrl}>
                <AddIcon />
                Add an image by Url
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <Field name="pictures" label="Images" component={renderDropzoneInput} />
  );
}

export default Images;
