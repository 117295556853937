import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import auth from "../../shared/auth";
import Loader from "../../shared/loader";
import BoxWraper from "../box-wrapper";
import {
  login,
  clearRedirect,
  resendEmailConfirmation,
  clearErrors
} from "./ducks/actions";
import Form from "./form";
import AnonymousPage from '../anonymous-pages/anonymous-page';

function Login(props) {
  const {
    login,
    resendEmailConfirmation,
    clearErrors,
    user,
    loading,
    errors,
    invitationErrors
  } = props;

  useEffect(() => {
    clearErrors();
  }, []);

  const originalLocation = props.location.state?.from;
  
  function onLogin(user) {
    login(user, props.history, props.location.state?.from)
  }

  function onResendEmailConfirmation(emailAddressValue) {
    resendEmailConfirmation(emailAddressValue)
  }

  return (
    <AnonymousPage>
      <BoxWraper>
        {loading && <Loader />}
        {!loading &&
          <Form
            errors={errors}
            invitationErrors={invitationErrors}
            loading={false}
            initialValues={user}
            onSubmit={onLogin}
            onResendEmailConfirmation={onResendEmailConfirmation}
            isLoggedIn={auth.isAuthenticated()}
            {...props}
          />}
      </BoxWraper>
    </AnonymousPage>
  );
}

function mapStateToProps(state) {
  const { accountState } = state;
  return {
    user: accountState.loginState.user,
    errors: accountState.loginState.errors,
    invitationErrors: accountState.loginState.invitationErrors,
    loading: accountState.loginState.loading,
    redirect: accountState.loginState.redirect
  };
}

export default connect(mapStateToProps, {
  login,
  resendEmailConfirmation,
  clearRedirect,
  clearErrors
})(Login);
