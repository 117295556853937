import React, { Suspense } from "react";
import { useInView } from "react-intersection-observer";
import Nav from './nav';
import './anonymous-page.scss';
import useWindowSize from "../../shared/window-size";
import Loader from "../../shared/loader";

const AnonymousPage = (props: any) => {

  const windowSize: any = useWindowSize();
  const windowWidth = windowSize.width;
  const options = { threshold: 1, rootMargin: `-30px 0px 400px 0px`, initialInView: true };
  const [titleRef, titleInView] = useInView(options);

  return (
    <div className="anonymous-background">
      <div className="navbar-padding">
        <Nav tranparent={titleInView} />
        <div className="container" ref={titleRef}>
          <Suspense fallback={<Loader />}>
            {props.children}
          </Suspense>
        </div>
      </div>
    </div>
  )
};

export default AnonymousPage;