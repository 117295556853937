import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SmallLoader from "../../shared/small-loader/small-loader";
import {
  fetchFeatureRequests,
  addFeatureRequest,
  clearSent,
  fetchFeatureRequestPriorities
} from "../feature-request/ducks/actions";
import Form from "./form";
import './add-feature-request.scss';
import { useEffect } from "react";
import Loader from "../../shared/loader";

const FeedbackContainer = (props) => {
  const {
    fetchFeatureRequests,
    emptyFeatureRequest,
    featureRequests,
    featureRequestsLoading,
    featureRequestSaving,
    featureRequestSent,
    featureRequestErrors,
    pageSections,
    activeSection,
    addFeatureRequest,
    clearSent,
    fetchFeatureRequestPriorities,
    priorities,
    prioritiesLoading } = props;

  const handleFeedbackFormSubmit = (featureRequest) => {
    addFeatureRequest(featureRequest);
  };

  useEffect(() => {
    fetchFeatureRequestPriorities();
    clearSent();
  },
    []);

  return (
    <div>
      {prioritiesLoading && <Loader />}
      <Form errors={featureRequestErrors}
        featureRequestSaving={featureRequestSaving}
        pageSections={pageSections}
        fetchFeatureRequests={fetchFeatureRequests}
        featureRequestSent={featureRequestSent}
        activeSection={activeSection}
        initialValues={emptyFeatureRequest}
        onSubmit={handleFeedbackFormSubmit}
        priorities={priorities}
        prioritiesLoading={prioritiesLoading} />

      <div className="leave-feedback-requests">
        {featureRequestsLoading && <SmallLoader />}
        {!featureRequestsLoading && <>

          <h3>Current Feature Requests for Section</h3>
          {featureRequests?.length ? (
            <div className="mb-1">
              <table className="feature-request-table">
                {featureRequests.map((request) => (
                  <tr key={request.id}>
                    <td className="feature-request-table-title"><Link className="link-on-white" to={`/feature-request/${request.id}`}>{request.title}</Link></td>
                    <td className="feature-request-line-votes"><i className="material-icons" title="Votes">thumb_up</i>&nbsp;{request.totalVotes}&nbsp;&nbsp;&nbsp;</td>
                    <td className="feature-request-line-votes"><i className="material-icons feature-request-comment-icon" title="Comments">comment</i>&nbsp;{request.totalComments}</td>
                  </tr>
                ))}
              </table>
            </div>) : <div className="mb-1">No existing feature requests for selected section</div>}
        </>}

        <Link to={"/feature-request"} className="link-on-white">Search All</Link>
      </div>
    </div>
  );

}


function mapStateToChangeLogs(state) {
  const { featureRequestState } = state;
  return {
    featureRequests: featureRequestState.summmaryFeatureRequests,
    emptyFeatureRequest: featureRequestState.emptyFeatureRequest,
    featureRequestsLoading: featureRequestState.featureRequestsLoading,
    featureRequestSaving: featureRequestState.featureRequestSaving,
    featureRequestSent: featureRequestState.featureRequestSent,
    featureRequestErrors: featureRequestState.featureRequestErrors,
    priorities: featureRequestState.priorities,
    prioritiesLoading: featureRequestState.prioritiesLoading,
    pageSections: state.commonState.pageSections,
    activeSection: state.commonState.activeSection
  };
}

export default connect(mapStateToChangeLogs, {
  fetchFeatureRequests,
  addFeatureRequest,
  clearSent,
  fetchFeatureRequestPriorities
})(FeedbackContainer);
