import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../shared/loader";
import { fetchScenes } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListScenesContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchScenes, loading, scenes, errors, readOnly } = props;

  useEffect(() => {
    fetchScenes(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderScenes(scenes, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/scenes"
        loading={loading}>
        <List scenes={scenes} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/scenes/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Scene
        </NavLink>}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderScenes(scenes, filmId);
}

function mapStateToProps(state) {
  const rootState = state.scenesState.rootState;
  return {
    scenes: rootState.scenes,
    readOnly: rootState.readOnly,
    loading: rootState.loading,
    errors: rootState.errors
  };
}

export default connect(mapStateToProps, {
  fetchScenes
})(ListScenesContainer);
