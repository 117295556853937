import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function MakeupMenu(props) {
  const { filmId, makeupId } = props.match.params;
  const { makeup } = props;
  if (makeupId === "add") {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Makeup</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/makeup/${makeupId}/edit`}
            activeClassName="is-active">
            Edit Makeup
          </NavLink>
        </li>
        {makeup?.canAccessScenes &&
          <li>
            <NavLink
              to={`/films/${filmId}/makeup/${makeupId}/scenes/`}
              activeClassName="is-active">
              Scenes
            </NavLink>
          </li>
        }
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const { makeupState } = state;
  return {
    makeup: makeupState.makeup
  };
}

export default connect(mapStateToProps, {
})(MakeupMenu);
