import ListCollaboratorsContainer from "./list-container";
import CollaboratorsOverviewContainer from "./overview/container";
import AddUser from "./add";
import EditUser from "./edit";
import RolesRoutes from './roles/routes';
import InRolesRoutes from './in-role/routes';
import { PrivateRoute } from "../../shared/private-route";

const CollaboratorRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/collaborators"
        component={ListCollaboratorsContainer}
      />
      <PrivateRoute
        path="/films/:filmId/collaborators/overview"
        component={CollaboratorsOverviewContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/collaborators/add"
        component={AddUser}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/collaborators/:collaboratorId"
        component={EditUser}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/collaborators/:collaboratorId/edit"
        component={EditUser}
      />
      <RolesRoutes />
      <InRolesRoutes />
    </>
  );
};

export default CollaboratorRoutes;
