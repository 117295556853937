import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../../../shared/loader";
import { fetchRoles } from "./ducks/actions";
import AddIcon from "../../../icons/add";
import List from "./list";
import ScrollIntoView from "../../../../shared/scroll-into-view/scroll-into-view";

function ListRolesContainer(props) {
  const { filmId, departmentId, roleId } = props.match.params;
  const { fetchRoles, loading, roles, errors, readOnly } = props;

  useEffect(() => {
    fetchRoles(filmId, departmentId);
  }, [filmId, departmentId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderRoles(roles, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/departments/:departmentId/roles">
        <List
          roles={roles}
          filmId={filmId}
          departmentId={departmentId}
          readOnly={readOnly}
        />
        {!readOnly && !window.location.pathname.endsWith("add") && (
          <NavLink
            to={`/films/${filmId}/departments/${departmentId}/roles/add`}
            activeClassName={
              "is-active " + window.location.pathname.endsWith("add")
                ? "disabled"
                : ""
            }
            className="button is-transparent is-add">
            <AddIcon className="white-icon" />
            Add Role
          </NavLink>
        )}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderRoles(roles, filmId);
}

function mapStateToProps(state) {
  return {
    roles: state.crewRolesState.roles,
    readOnly: state.crewRolesState.readOnly,
    loading: state.departmentsState.loading,
    errors: state.departmentsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchRoles
})(ListRolesContainer);
