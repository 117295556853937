export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="23.391" height="23.391" viewBox="0 0 19.891 19.891"  {...props}>
    <g id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected" transform="translate(0.5 0.5)">
      <g id="Icon_Navigation_Edit_Film" data-name="Icon / Navigation / Edit Film">
        <path id="Path_52" data-name="Path 52" d="M9.488,26.131" transform="translate(-5.23 -10.566)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <g id="view">
          <path id="Path_32816" data-name="Path 32816" d="M17.832,1H3.059A2.059,2.059,0,0,0,1,3.059V17.832a2.059,2.059,0,0,0,2.059,2.059H17.832a2.059,2.059,0,0,0,2.059-2.059V3.059A2.059,2.059,0,0,0,17.832,1Z" transform="translate(-1 -1)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32817" data-name="Path 32817" d="M4.112,1V19.891" transform="translate(-0.125 -1)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32818" data-name="Path 32818" d="M13.889,1V19.891" transform="translate(1.016 -1)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32819" data-name="Path 32819" d="M1,9H4.986" transform="translate(-1 0.445)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32820" data-name="Path 32820" d="M1,5H4.986" transform="translate(-1 -0.277)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32821" data-name="Path 32821" d="M1,13H4.986" transform="translate(-1 1.168)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32822" data-name="Path 32822" d="M13.889,13h3.986" transform="translate(1.016 1.168)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32823" data-name="Path 32823" d="M13.889,5h3.986" transform="translate(1.016 -0.277)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32824" data-name="Path 32824" d="M13.889,9h3.986" transform="translate(1.016 0.445)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        </g>
      </g>
    </g>
  </svg>
