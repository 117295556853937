import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";
import { submit } from 'redux-form';

export const CANCEL_SHOOTING_DAY = "CANCEL_SHOOTING_DAY";

// obsoloete
export function getCallSheetDates(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_CALL_SHEET_DATES",
      payload: getApiClient().get(`/film/${filmId}/ShootingDay/${shootingDayId}/CallSheet`),
      meta: {}
    });
  };
}

export function generatePdf(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_PDF",
      payload: getApiClient().get(
        `./../film/${filmId}/CallSheet/pdf/${shootingDayId}`,
        {
          responseType: "blob",
          timeout: 30000
        }
      ),
      meta: {}
    });
  };
}

export function clearDownload() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_CALL_SHEET_DOWNLOAD"
    });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_CALL_SHEET_PDF",
      meta: {}
    });
  };
}

export function downloadCallSheet(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "DOWNLOAD_CALL_SHEET",
      payload: getApiClient().post(
        `/film/${filmId}/CallSheet/${shootingDayId}/Download`
      ),
      meta: {}
    })
      /*
        .then((response) => {
          dispatch(showInfo(`Call sheet email sent to you`));
        })
        */
      .catch((response) => {
        dispatch(showError(`Error sending call sheet emails`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function sendCallSheetToSelf(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "SEND_SHOOTING_DAY_EMAILS_TO_SELF",
      payload: getApiClient().post(
        `/film/${filmId}/CallSheet/${shootingDayId}/ToSelf`
      ),
      meta: {}
    })
      .catch((response) => {
        dispatch(showError(`Error sending call sheet email to self`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function sendCallSheetToPerson(filmId, shootingDayId, personId, personType) {
  return (dispatch) => {
    dispatch({
      type: "SEND_SHOOTING_DAY_EMAILS_TO_PERSON",
      payload: getApiClient().post(
        `/film/${filmId}/CallSheet/${shootingDayId}/ToPerson`,
        { personId, personType }
      ),
      meta: { personId, personType }
    })
      .catch((response) => {
        dispatch(showError(`Error sending call sheet emails to person`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function sendCallSheetToMembers(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "SEND_SHOOTING_DAY_EMAILS",
      payload: getApiClient().post(
        `/film/${filmId}/CallSheet/${shootingDayId}`
      ),
      meta: {}
    })
      .catch((response) => {
        dispatch(showError(`Error sending call sheet emails`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function cancelShootingDay(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_SHOOTING_DAY,
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Cancel`,{}
      ),
      meta: {}
    })
      .then((response) => {
        dispatch(showInfo(`Shooting Day Cancelled`));
      })
      .catch((response) => {
        dispatch(showError(`Error cancelling shooting day`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function submitForm() {
  return (dispatch) => {
    dispatch(submit('remoteSubmit'));
  };
}

export function fetchShootingDayCallSheetSettings(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_CALL_SHEET_SETTINGS",
      payload: getApiClient().get(`/film/${filmId}/CallSheet/settings/${shootingDayId}`),
    });
  };
}

export function updateShootingDayCallSheetSettings(filmId, shootingDayId, shootingDaySettings) {
  return (dispatch) => {
    return dispatch({
      type: "UPDATE_SHOOTING_DAY_CALL_SHEET_SETTINGS",
      payload: getApiClient().put(
        `/film/${filmId}/CallSheet/settings/${shootingDayId}/${shootingDaySettings.id}`,
        shootingDaySettings
      ),
      meta: { shootingDaySettings: shootingDaySettings }
    })
      .then((response) => {
        dispatch(showInfo(`Updated Shooting Day Call Sheet settings`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day Call Sheet Settings`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
