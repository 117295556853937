import Moment from "react-moment";
import CkEditorContent from "../../../shared/controls/ckeditor-content";
import confirm from "../../../shared/modal/confirm";
import Form from "../comment/add/form";

const Comment = (props) => {
    const { comment, featureRequestId, updateFeatureRequestComment, deleteFeatureRequestComment, setEditMode, hideEdit } = props;
    

    const onEdit = () => {
        setEditMode(comment.id, true);
    };

    const onCancelEdit = () => {
        setEditMode(comment.id, false);
    };

    const handleFeedbackFormSubmit = (comment) => {    
        updateFeatureRequestComment(featureRequestId, comment);
     };

     const onDelete = () => {  
        let truncatedComment = comment.comment;
        const maxLength = 40;
        if (truncatedComment.length > maxLength) {
            truncatedComment = truncatedComment.substring(0, maxLength) + "...";
        }

        confirm(() => deleteFeatureRequestComment(featureRequestId, comment.id), '"' + truncatedComment + '"');
     };  
     
     const createMarkup = (content) => {
        return { __html: content };
      }

    return (
        <div>
            <hr className="feature-request-comment-hr" />
            <div className="feature-request-comment">            
                {!comment.editMode && <>
                    <CkEditorContent content={comment.comment} />
                     - <span className="feature-request-comment-username">{comment.userName}</span>&nbsp;&nbsp;
                    <span className="feature-request-comment-date"><Moment fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{comment.submitted}</Moment> {comment.edited && <>&nbsp;&nbsp; Edited: <Moment fromNow withTitle={true} titleFormat="Do MMMM YYYY [at] HH:mm">{comment.edited}</Moment></>}</span>
                    {comment.canEdit && <div className="mt-05">
                        {!hideEdit && <a className="mr-1" onClick={onEdit}>Edit</a>}
                        <a onClick={onDelete}>Delete</a>
                    </div>}
                </>}
                {comment.editMode && <Form initialValues={comment} 
                                    onSubmit={handleFeedbackFormSubmit}
                                    onCancel={onCancelEdit} />}
            </div>
            
        </div>
    )
}

export default Comment;