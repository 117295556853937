import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { fetchBackgroundCharacters } from "./../../background-characters/ducks/actions";
import {
  addBackgroundCharacter,
  clearBackgroundCharacterInScene,
  clearRedirect,
} from "./ducks/actions";
import Form from "./form";

function AddCharacter(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    fetchBackgroundCharacters,
    addBackgroundCharacter,
    clearBackgroundCharacterInScene,
    sceneBackgroundCharacters,
    backgroundCharacter,
    backgroundCharacters,
    errors,
  } = props;

  const [fromBreakdown, setFromBreakdown] = useState<boolean>(false);
  useEffect(() => {
    setFromBreakdown(props.location?.state?.fromBreakdown);
  }, [props.location?.state?.fromBreakdown]);

  useEffect(() => {
    fetchBackgroundCharacters(filmId);
    clearBackgroundCharacterInScene();
  }, []);

  if (props.redirect) {
    props.clearRedirect();
    if (props.redirect.to == "list") {
      const url = `/films/${filmId}/scenes/${sceneId}/Background-characters`;
      return <Redirect to={url} />;
    } else if (props.redirect.to == "breakdown") {
      const url = `/films/${filmId}/breakdown/scenes/${sceneId}`;
      return <Redirect to={url} />;
    }
  }

  function onAddBackgroundCharacter(character) {
    sanitiseDataTypes(character);
    addBackgroundCharacter(filmId, sceneId, character, fromBreakdown);
  }

  function sanitiseDataTypes(character) {
  }

  const sceneBackgroundCharacterIds = sceneBackgroundCharacters.map(p => p.id);
  const nonSceneBackgroundCharacters = backgroundCharacters.filter((prop: any) => !sceneBackgroundCharacterIds.includes(prop.id));

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/scenes/:sceneId/background-characters/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        characterId={null}
        initialValues={backgroundCharacter}
        backgroundCharacters={nonSceneBackgroundCharacters}
        loading={false}
        onSubmit={onAddBackgroundCharacter}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    sceneBackgroundCharacters: state.scenesState.backgroundCharacterState.backgroundCharacters,
    backgroundCharacters: state.backgroundCharactersState.rootState.backgroundCharacters,
    backgroundCharacter: state.scenesState.backgroundCharacterState.backgroundCharacter,
    errors: state.scenesState.backgroundCharacterState.errors,
    redirect: state.scenesState.backgroundCharacterState.redirect,
  };
}

export default connect(mapStateToProps, {
  addBackgroundCharacter,
  fetchBackgroundCharacters,
  clearBackgroundCharacterInScene,
  clearRedirect,
})(AddCharacter);
