import React from "react";
import { connect } from "react-redux";
import { NavLink, Route, Link, withRouter } from "react-router-dom";

function BackgroundCharacterMenu(props) {
  const { filmId, backgroundCharacterId } = props.match.params;
  const { backgroundCharacter } = props;
  const invalidIds = ["add", "overview"];

  if (invalidIds.indexOf(backgroundCharacterId) > -1) {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Background Character</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/background-characters/${backgroundCharacterId}/edit`}
            activeClassName="is-active"
          >
            Edit Background Character
          </NavLink>
        </li>
        {backgroundCharacter?.canAccessScenes &&
          <li>
            <NavLink
              to={`/films/${filmId}/background-characters/${backgroundCharacterId}/scenes`}
              activeClassName="is-active">
              Scenes
            </NavLink>
          </li>}
        {backgroundCharacter?.canAccessProps && backgroundCharacter?.hasProps &&
          <li>
            <NavLink
              to={`/films/${filmId}/background-characters/${backgroundCharacterId}/props`}
              activeClassName="is-active">
              Props
            </NavLink>
          </li>}
        {backgroundCharacter?.canAccessCostumes && backgroundCharacter?.hasCostumes &&
          <li>
            <NavLink
              to={`/films/${filmId}/background-characters/${backgroundCharacterId}/costumes`}
              activeClassName="is-active">
              Costumes
            </NavLink>
          </li>}
        {backgroundCharacter?.canAccessMakeups && backgroundCharacter?.hasMakeups &&
          <li>
            <NavLink
              to={`/films/${filmId}/background-characters/${backgroundCharacterId}/makeup`}
              activeClassName="is-active">
              Makeup
            </NavLink>
          </li>}
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const rootState = state.backgroundCharactersState.rootState;
  return {
    backgroundCharacter: rootState.backgroundCharacter
  };
}

export default connect(mapStateToProps, {
})(BackgroundCharacterMenu);

