import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "./../../shared/loader";
import confirm from "../../shared/modal/confirm";
import { fetchSceneSettings } from "./../scene-settings/ducks/actions";
import {
  clearRedirect,
  deleteScene,
  fetchScene,
  updateScene,
  splitScene
} from "./ducks/actions";
import Onboarding from '../../shared/onboarding/onboarding';
import Form from "./form";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ScenesContainer(props: any) {
  const filmId = props.filmId || props.match.params.filmId;
  const sceneId = props.sceneId || props.match.params.sceneId;
  const {
    fetchScene,
    fetchSceneSettings,
    updateScene,
    deleteScene,
    splitScene,
    loadingScene,
    scene,
    errors,
    embedded,
    dayNightEnum,
    interiorExteriorEnum,
    sceneSettings,
    scriptPagesPerDay,
    onboardingSteps,
    hasCommercialScript
  } = props;

  const invalidIds = ["add", "script-days", "order"];

  useEffect(() => {
    if (invalidIds.indexOf(sceneId) === -1) {
      fetchScene(filmId, sceneId);
      fetchSceneSettings(filmId);
    }
  }, [filmId, sceneId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/scenes/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(sceneId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderScene(scene) {
    return (
      <ScrollIntoView
        className="blade-content medium-wide"
        path="/films/:filmId/scenes/:sceneId/edit"
        loading={loadingScene}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          sceneId={scene.id}
          initialValues={scene}
          loading={loadingScene}
          onSubmit={onUpdateScene}
          handleDelete={onDeleteScene}
          handleSplit={onHandleSplit}
          reduxFormValues={props.reduxFormValues}
          scriptPagesPerDay={scriptPagesPerDay}
          hasCommercialScript={hasCommercialScript}
          {...props}
        />
        <Onboarding onboardingSteps={onboardingSteps} section="Scene" />
      </ScrollIntoView>
    );
  }

  function onUpdateScene(scene) {
    sanitiseDataTypes(scene);
    updateScene(filmId, scene);
  }

  function onDeleteScene(scene) {
    confirm(() => deleteScene(filmId, scene), "Scene " + scene?.number + " " + scene.settingName);
  }

  function onHandleSplit(scene) {
    confirm(() => splitScene(filmId, scene), "Scene " + scene?.number + " " + scene.settingName, "Are you sure you want to split this scene? It will just half the page length and shoot days. It would be better to do it in the Script section.", "Split Scene");
  }

  function sanitiseDataTypes(scene) {
    scene.scriptDay = scene.scriptDay ? +scene.scriptDay : null;
    scene.shootDays = scene.shootDays ? +scene.shootDays : null;
  }

  return scene && !loadingScene ? renderScene(scene) : renderLoading();
}

function mapStateToProps(state) {
  const { scenesState, sceneSettingsState, commonState, form, filmsState } = state;
  const { rootState } = scenesState;
  return {
    scene: {
      ...rootState.scene,
      scriptPagesPerDay: commonState.user.scriptPagesPerDay
    },
    loadingScene: rootState.loadingScene,
    sceneSettings: sceneSettingsState.rootState.sceneSettings,
    errors: rootState.errors,
    dayNightEnum: commonState.dayNightEnum,
    interiorExteriorEnum: commonState.interiorExteriorEnum,
    redirect: rootState.redirect,
    onboardingSteps: rootState.onboardingSteps,
    reduxFormValues: form?.editSceneForm?.values,
    scriptPagesPerDay: commonState.user.scriptPagesPerDay,
    hasCommercialScript: filmsState.userFilm?.hasCommercialScript
  };
}

export default
  connect(mapStateToProps, {
    fetchScene,
    updateScene,
    deleteScene,
    fetchSceneSettings,
    clearRedirect,
    splitScene
  })(ScenesContainer);