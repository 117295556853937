import { clearNotifications, showError, showInfo } from "../../../notifications/ducks/actions";
import getApiClient from "../../../shared/api";

export const FETCH_USER_COMMUNICATION_PREFERENCES = "FETCH_USER_COMMUNICATION_PREFERENCES";
export const UPDATE_USER_COMMUNICATION_PREFERENCES = "UPDATE_USER_COMMUNICATION_PREFERENCES";

export function fetchUserCommunicationPreferences() {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_COMMUNICATION_PREFERENCES,
      payload: getApiClient().get(`/user/CommunicationPreferences`)
    });
  };
}

export function updateUserCommunicationPreferences(userCommunicationPreferences) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_COMMUNICATION_PREFERENCES,
      payload: getApiClient().put(
        `/user/CommunicationPreferences`,
        userCommunicationPreferences
      ),
      meta: { userCommunicationPreferences }
    })
      .then((response) => {
        dispatch(showInfo(`Updated user communication preferences`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating user communication preferences`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
