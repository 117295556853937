import React from "react";
import memoizeOne from "memoize-one";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Scene from "./scene";
import {
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName
} from './ducks/reducer';

const ScenesList = ({
  days,
  filmId,
  updateSceneOrder,
  addScriptDay,
  deleteScriptDay,
  updateDayOrder,
  onUpdateScenesScriptDays,
  selectedSceneIds,
  draggingSceneId,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  unselectAll,
  readOnly,
  canAccessSceneSettings
}) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type == "scene") {
      updateSceneOrder(
        result.source.index,
        result.destination.index,
        result.source.droppableId,
        result.destination.droppableId
      );
    } else if (result.type == "day") {
      updateDayOrder(result.source.index, result.destination.index);
    }
  };

  const onDragStart = (start) => {
    const id = start.draggableId;
    const selected = selectedSceneIds.find((sceneId) => sceneId === id);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }

    // setDraggingSceneId(start.draggableId);
  };

  const grid = 0;

  console.log("selectedSceneIds", selectedSceneIds);

  const getDayStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? "#947eed" : "#947EED",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  type SceneIdMap = {
    [sceneId: string]: true;
  };

  const getSelectedMap = memoizeOne((selectedSceneIds: string[]) =>
    selectedSceneIds.reduce(
      (previous: SceneIdMap, current: string): SceneIdMap => {
        previous[current] = true;
        return previous;
      },
      {}
    )
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="day">
          {(provided, snapshot) => (
            <table
              className="table"
              {...provided.droppableProps}
              ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>Scene</th>
                  <th>Scene Setting</th>
                  <th>Int/Ext</th>
                  <th>Day/Night</th>
                </tr>
              </thead>

              {(days || []).map((day, index) => (
                <Draggable
                  key={day.number}
                  draggableId={day.number + ""}
                  index={index}
                  isDragDisabled={readOnly}>
                  {(draggableProvided, draggableSapshot) => (
                    <Droppable
                      droppableId={day.number + ""}
                      type="scene"
                      isDropDisabled={!day.number}>
                      {(provided, snapshot) => (
                        <tbody
                          key={index}
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          <tr
                            className={`${index === 0 ? OnboardingStep3ClassName : ""}`}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={getDayStyle(
                              draggableSapshot.isDragging,
                              draggableProvided.draggableProps.style
                            )}>
                            <td colSpan={3}>
                              {day.number && (
                                <p>
                                  {!readOnly && (
                                    <i className="material-icons">
                                      drag_indicator
                                    </i>
                                  )}
                                  Script Day {day.number}
                                </p>
                              )}
                              {!day.number && <p>No Script Day</p>}
                            </td>
                            <td>
                              {!readOnly && day.number && day.number > 1 && (
                                <a onClick={() => deleteScriptDay(day.number)} className={`${day.number === 2 ? OnboardingStep4ClassName : ""}`}>
                                  <i className="material-icons">delete</i>
                                </a>
                              )}
                            </td>
                          </tr>
                          {(day.scenes || []).map((scene, index) => {
                            const selectionMap = getSelectedMap(
                              selectedSceneIds || []
                            );
                            const isSelected = selectionMap
                              ? selectionMap[scene.sceneId]
                              : false;
                            console.log(scene.sceneId);
                            console.log(isSelected);
                            const isGhosting: boolean =
                              isSelected &&
                              Boolean(draggingSceneId) &&
                              draggingSceneId !== scene.id;
                            return (
                              <Scene
                                filmId={filmId}
                                scene={scene}
                                index={index}
                                key={scene.sceneId}
                                isSelected={isSelected}
                                isGhosting={isGhosting}
                                selectionCount={(selectedSceneIds || []).length}
                                toggleSelection={toggleSelection}
                                toggleSelectionInGroup={toggleSelectionInGroup}
                                multiSelectTo={multiSelectTo}
                                readOnly={readOnly}
                                canAccessSceneSettings={canAccessSceneSettings}></Scene>
                            );
                          })}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  )}
                </Draggable>
              ))}
            </table>
          )}
        </Droppable>
      </DragDropContext>
      {!readOnly && <div className="buttons mt-2">
        <button type="submit" className={`button ${OnboardingStep2ClassName}`} onClick={() => addScriptDay()}>
          Add Day
        </button>
        <button
          type="submit"
          className="button"
          onClick={() => onUpdateScenesScriptDays(days)}>
          Save
        </button>
      </div>}
    </>
  );
};

export default ScenesList;
