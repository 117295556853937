import React from "react";

const HelpText = (props) => {
  const { children, className } = props;
  return (
    <div className={`container ${className}`}>
      <div className="notification content">
        <span className="material-icons info">info</span>
        {children}
      </div>
    </div>
  );
};

export default HelpText;