import { acceptedFileTypes } from '../../../film-scripts/index';
import { showNotification } from '../../../shared/notification/browser-notification';
import { ADD_FILM_GET_COUNTRIES, COPY_FILM, FETCH_FILM_DEFAULTS, FILM_GET_COUNTRIES } from './actions';
import { connection } from "../../../middleware/collaboratorSignalRMiddleware";
import { connection as filmTaskConnection } from "../../../middleware/filmTaskSignalRMiddleware";
import { HubConnectionState } from "@microsoft/signalr";

interface FilmState {
  films: any[];
  loading: boolean;
  film: any;
  defaults: any;
  canAddFilm: boolean;
  userFilm: any;
  filmOnboardingSteps: any[];
  editFilmOnboardingSteps: any[];
  countries: any[]
}

export const OnboardingStep1ClassName = 'edit-film-onboarding-step-1';
export const OnboardingStep2ClassName = 'edit-film-onboarding-step-2';
export const OnboardingStep3ClassName = 'edit-film-onboarding-step-3';

const defaultState: FilmState = {
  films: [],
  film: {},
  defaults: {},
  canAddFilm: false,
  userFilm: null,
  loading: false,
  countries: [],
  filmOnboardingSteps: [
    {
      target: '.onboarding-step-1',
      title: 'Creating a film from your script',
      content: `If you’ve already got a script for your film then you can upload it here. Supported formats include ${acceptedFileTypes.join(", ")}`
    },
    {
      target: '.onboarding-step-2',
      title: 'Creating a film without a script',
      content: 'If you haven’t got a script, you can add your film manually here.'
    },
    {
      target: '.onboarding-step-3',
      title: 'Help Guides',
      content: 'Want to view these help guides again? Just click this icon.'
    },
    {
      target: '.onboarding-step-4',
      title: 'Help and Feedback',
      content: 'Need extra help with Filmanize? Visit the Help section here to read the FAQs, ask questions and leave feedback.'
    },
    {
      target: ".onboarding-step-5",
      title: "Example film",
      content:
        `An example film has already been added so you can see what a completed film looks like. 
        Click "Manage Film" and you’ll be shown more help guides using this film as an example – this will give you an understanding of how Filmanize works and get you up and running quickly.`
    }
  ],
  editFilmOnboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Camera Settings',
      content: `Aspect Ratio, Resolution and Frame Rate will appear on the Call Sheet under the camera department, so they know what to set the camera setting to.`
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Language',
      content: `This sets the language for any PDFs and emails sent for this film. It is independent of your own language setting, so they can be different.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Send Call Sheets',
      content: `This will automatically send call sheets for each day to the cast and crew the specified number of hours before the call time for that day.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {

  if (action.type.endsWith("_FULFILLED")) {
    /*
    let userMessages = action.payload.data.userMessages;
    if (userMessages && userMessages.length > 0) {
      let userFilm = state.userFilm;
      if (userFilm) {
        userMessages.forEach(userMessage => 
          {
            let existingMessage = userFilm.messages?.find(m => m.message === userMessage.message && m.entityId === userMessage.entityId && m.entityType == userMessage.entityType);
            if (!existingMessage) {
              userFilm.messages?.push(userMessage);
            } else {
              existingMessage.entityName = userMessage.entityName;
            }
          })        
      }
      return {
        ...state,        
        userFilm
      };
    }
    
    let clearUserMessages = action.payload.data.clearUserMessages;
    if (clearUserMessages && clearUserMessages.length > 0) {
      let userFilm = state.userFilm;
      if (userFilm) {
        clearUserMessages.forEach(userMessage => 
          {
            
            let existingMessageIndex = userFilm.messages?.findIndex(m => m.message === userMessage.message && m.entityId === userMessage.entityId && m.entityType == userMessage.entityType); 
            if (existingMessageIndex > -1) {
              userFilm.messages?.splice(existingMessageIndex, 1);
            }
          })        
      }
      return {
        ...state,        
        userFilm
      };
    }
    */
  }

  switch (action.type) {
    case "ADD_USER_MESSAGE": {
      let userMessage = action.meta.userMessage;
      if (userMessage) {
        let userFilm = { ...state.userFilm };
        if (userFilm) {
          if (userFilm.id !== userMessage.filmId) {
            return state;
          }
          let existingMessage = userFilm.messages?.find(m => m.filmId === userMessage.filmId && m.message === userMessage.message && m.entityId === userMessage.entityId && m.entityType == userMessage.entityType);
          if (!existingMessage) {
            userFilm.messages?.push(userMessage);
            showNotification(userMessage.entityName, userMessage.message, userMessage.link);
            userFilm.messages?.sort((a, b) => {
              if (a.added > b.added) return -1;
              else if (a.added < b.added) return 1;
              return 0;
            });

          } else {
            existingMessage.entityName = userMessage.entityName;
            existingMessage.severity = userMessage.severity;
            existingMessage.added = userMessage.added;
          }

          return {
            ...state,
            userFilm
          };
        }
      }

      return state;
    }

    case "REMOVE_USER_MESSAGE": {
      let removeUserMessage = action.meta.userMessage;
      if (removeUserMessage) {
        let userFilm = { ...state.userFilm };
        if (userFilm) {
          if (userFilm.id !== removeUserMessage.filmId) {
            return state;
          }
          let existingMessageIndex = userFilm.messages?.findIndex(m => m.filmId === removeUserMessage.filmId && m.message === removeUserMessage.message && m.entityId === removeUserMessage.entityId && m.entityType == removeUserMessage.entityType);
          if (existingMessageIndex > -1) {
            userFilm.messages?.splice(existingMessageIndex, 1);
          }
        }

        return {
          ...state,
          userFilm
        };
      }

      return state;
    }

    case "DISMISS_USER_MESSAGE": {
      let removeUserMessageId = action.meta.userMessageId;
      if (removeUserMessageId) {
        let userFilm = { ...state.userFilm };
        if (userFilm) {
          let existingMessageIndex = userFilm.messages?.findIndex(m => m.id === removeUserMessageId);
          if (existingMessageIndex > -1) {
            userFilm.messages?.splice(existingMessageIndex, 1);
          }
        }

        return {
          ...state,
          userFilm
        };
      }

      return state;
    }

    case "FETCH_FILMS_PENDING": {

      if (connection.state === HubConnectionState.Connected) {
        connection.stop();
      }

      if (filmTaskConnection.state === HubConnectionState.Connected) {
        filmTaskConnection.stop();
      }

      return {
        ...state,
        films: action.payload,
        userFilm: null,
        loading: true,
        redirect: false,
        errors: null
      };
    }

    case "FETCH_FILMS_FULFILLED": {
      return {
        ...state,
        films: action.payload.data.films,
        canAddFilm: action.payload.data.canAddFilm,
        loading: false
      };
    }

    case "FETCH_SINGLE_FILM_PENDING": {
      return {
        ...state,
        loading: true,
        film: action.payload
      };
    }

    case "FETCH_SINGLE_FILM_FULFILLED": {
      return {
        ...state,
        loading: false,
        film: action.payload.data,
        redirect: false
      };
    }

    case FETCH_FILM_DEFAULTS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_FILM_DEFAULTS + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        defaults: action.payload.data,
        redirect: false
      };
    }

    case FETCH_FILM_DEFAULTS + "_REJECTED": {
      return {
        ...state,
        loading: false
      };
    }

    case "FETCH_SINGLE_FILM_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "FETCH_USER_FILM_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_USER_FILM_FULFILLED": {
      return {
        ...state,
        loading: false,
        userFilm: action.payload.data,
        redirect: false
      };
    }

    case "FETCH_USER_FILM_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "UPDATE_SINGLE_FILM_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case "UPDATE_SINGLE_FILM_FULFILLED": {
      const userFilm = { ...state.userFilm, name: action.meta.film.name };
      const currencySymbol = action.payload.data?.film?.currencySymbol;
      if (currencySymbol) {
        userFilm.currencySymbol = currencySymbol;
      }

      const languageHasGender = action.payload.data?.languageHasGender;
      if (languageHasGender !== null) {
        userFilm.languageHasGender = languageHasGender;
      }

      const film = { ...action.meta.film, productionCompanyLogoFileName: action.payload.data?.productionCompanyLogoFileName }

      return {
        ...state,
        loading: false,
        film: film,
        userFilm
      };
    }

    case "UPDATE_SINGLE_FILM_REJECTED": {
      return {
        ...state,
        film: action.meta.film,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "ADD_SINGLE_FILM_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        film: action.payload
      };
    }

    case "ADD_SINGLE_FILM_FULFILLED": {
      const film = { ...action.meta.film, ...action.payload.data.film }
      return {
        ...state,
        loading: false,
        film: film,
        redirect: true
      };
    }

    case "ADD_SINGLE_FILM_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors,
        film: action.meta.film
      };
    }

    case "ADD_SINGLE_EXAMPLE_FILM_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        film: action.payload
      };
    }

    case "ADD_SINGLE_EXAMPLE_FILM_FULFILLED": {
      let films = [action.payload.data.film, ...state.films];
      return {
        ...state,
        loading: false,
        films: films,
        redirect: true
      };
    }

    case "ADD_SINGLE_EXAMPLE_FILM_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors,
        film: action.payload.data
      };
    }

    case COPY_FILM + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case COPY_FILM + "_FULFILLED": {
      let films = [action.payload.data.film, ...state.films];
      return {
        ...state,
        loading: false,
        films,
        redirect: true
      };
    }

    case COPY_FILM + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case FILM_GET_COUNTRIES + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        countries: action.payload.data.countries,
        defaultCountryId: action.payload.data.defaultId
      };
    }

    case FILM_GET_COUNTRIES + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "DELETE_SINGLE_FILM_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        film: action.payload
      };
    }

    case "DELETE_SINGLE_FILM_FULFILLED": {
      const film = action.meta.film;
      const filmIndexToRemove = state.films.findIndex(f => f.id == film.id);
      const films = Array.from(state.films);
      films.splice(filmIndexToRemove, 1);
      return {
        ...state,
        films,
        loading: false,
        redirect: true
      };
    }

    case "DELETE_SINGLE_FILM_REJECTED": {
      return {
        ...state,
        loading: false,
        film: action.meta
      };
    }

    case "ARCHIVE_SINGLE_FILM_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "ARCHIVE_SINGLE_FILM_FULFILLED": {
      return {
        ...state,
        loading: false,
        redirect: true
      };
    }

    case "ARCHIVE_SINGLE_FILM_REJECTED": {
      return {
        ...state,
        loading: false,
        film: action.meta.film,
        errors: action.payload.response.data.errors
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        film: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
