import React from "react";
import InputSlider from "../../shared/controls/input-slider";
import NumberFormat from "react-number-format";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function Costume(props) {
  const {
    costume,
    onChangeCostumeBuget,
    onChangeCostumeDays,
    maxDays,
    hasShootingDays,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <tr>
      <td><Link to={`/films/${filmId}/costumes/${costume.id}/edit`}>{costume.name}</Link></td>
      <td className="budget-days-column">
        {costume.hire ? (
          hasShootingDays ? (
            costume.days
          ) : (
            <InputSlider
              step={1}
              formatLabel={(value) => `${value}`}
              draggableTrack={false}
              allowSameValues={false}
              maxValue={maxDays}
              minValue={1}
              value={costume.days}
              onChange={(val) => onChangeCostumeDays(costume.id, val)}
              onChangeComplete={(args) => console.log(args)}
              disabled={readOnly || saving}
            />
          )
        ) : (
          "-"
        )}
      </td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={costume.hire ? costume.dailyBudget : costume.budget}
          onChange={(val) => onChangeCostumeBuget(costume.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-centered">{costume.quantity}</td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={costume.total}
        />
      </td>
    </tr>
  );
}

export default Costume;
