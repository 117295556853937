export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.985" height="27.134" viewBox="0 0 21.096 22.938" {...props} >
    <rect id="Rectangle_4242" data-name="Rectangle 4242" width="19.282" height="14.089" transform="translate(1.314 8.349)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
    <g id="Group_3855" data-name="Group 3855" transform="translate(1.314 8.349)">
      <rect id="Rectangle_4243" data-name="Rectangle 4243" width="19.282" height="3.15" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_730" data-name="Line 730" y1="3.24" x2="2.308" transform="translate(1.811 0.081)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_731" data-name="Line 731" y1="3.24" x2="2.308" transform="translate(6.195 0.081)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_732" data-name="Line 732" y1="3.24" x2="2.308" transform="translate(10.578 0.081)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_733" data-name="Line 733" y1="3.24" x2="2.308" transform="translate(14.962 0.081)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
    </g>
    <g id="Group_3856" data-name="Group 3856" transform="translate(0.581 0.581)">
      <rect id="Rectangle_4244" data-name="Rectangle 4244" width="19.282" height="3.15" transform="matrix(0.984, -0.179, 0.179, 0.984, 0, 3.447)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_734" data-name="Line 734" y1="3.6" x2="1.692" transform="translate(2.375 2.79)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_735" data-name="Line 735" y1="3.6" x2="1.692" transform="translate(6.688 2.007)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_736" data-name="Line 736" y1="3.6" x2="1.692" transform="translate(11.002 1.223)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_737" data-name="Line 737" y1="3.6" x2="1.692" transform="translate(15.315 0.439)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
    </g>
  </svg>
