import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


const ScenesWithoutDays = (props) => {
    const { scenes } = props;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        //padding: grid * 2,
        //borderRadius: "5px",
        //margin: `0 0 ${grid}px 0`,
        // change background colour if dragging
        background: isDragging ? "#222E44" : "#040F21",
        //color: isDragging ? "white" : "white",
        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <div className="sticky">
            <table className="table">
                <thead>
                    <tr>
                        <th>Scenes without days</th>
                    </tr>
                </thead>


                <Droppable droppableId={"newScene"} type="scene">
                    {(provided, snapshot) => (
                        <tbody  {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {(scenes || []).map((scene, index) => (
                                <Draggable
                                    key={scene.sceneId}
                                    draggableId={scene.sceneId + index + scene.locationId + scene.subLocationId}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <tr >
                                            <td
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >{scene.number} - {scene.settingName}</td>
                                        </tr>
                                    )}
                                </Draggable>
                            )
                            )}
                            {provided.placeholder}
                        </tbody>)}
                </Droppable>
            </table>
        </div>
    )
}

export default ScenesWithoutDays;