import React from "react";
import { NavLink } from "react-router-dom";
import { OnboardingStep2ClassName } from "./ducks/reducer";

const CollaboratorsList = ({ users, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Collaborators</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/collaborators/overview`}
            activeClassName="is-active">
            Overview
          </NavLink>
        </li>
        <li className={OnboardingStep2ClassName}>
          <NavLink
            to={`/films/${filmId}/collaborators/collaborator-roles`}
            activeClassName="is-active">
            Roles
          </NavLink>
        </li>
        <hr />

        {(users || []).map((user, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/collaborators/${user.id}/edit`}
                title={user.name}
                activeClassName="is-active">
                {user.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default CollaboratorsList;
