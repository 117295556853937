interface ForgotPasswordState {
  loading: boolean;
  user: any;
  submitted: boolean;
  errors: any;
}

const defaultState: ForgotPasswordState = {
  loading: false,
  submitted: false,
  user: {},
  errors: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "SET_FORGOT_PASSWORD_EMAIL_ADDDRESS": {
      const user = { ...state.user };
      user.emailAddress = action.meta.emailAddress;
      return {
        ...state,        
        user
      };
    }

    case "FORGOT_PASSWORD_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        submitted: false
      };
    }
    
    case "FORGOT_PASSWORD_FULFILLED": {        
      return {
        ...state,      
        loading: false,
        submitted: true
      };
    }

    case "FORGOT_PASSWORD_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loading: false,
        submitted: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
