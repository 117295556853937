import { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import Errors from "./../../shared/errors";

import FilmanizeCheckbox from "../../shared/filmanize-checkbox";
import "./communication-preferences.scss";
import CommunicationPreference from "./preference";

function UserCommunicationPreferencesForm(props) {
  const { userCommunicationPreferences, handleSubmit, submitting, errors, onSubmit, notificationsGranted, onEnableNotifications, notificationsDefault } =
    props;

  const [pristine, setPristine] = useState<boolean>(true);
  const [whatsNew, setWhatsNew] = useState<number[]>([]);
  const [featureRequestComment, setFeatureRequestComment] = useState<number[]>([]);
  const [featureRequestStatus, setFeatureRequestStatus] = useState<number[]>([]);
  const [featureRequestVote, setFeatureRequestVote] = useState<number[]>([]);
  const [collaboratorEmailDelivered, setCollaboratorEmailDelivered] = useState<number[]>([]);
  const [collaboratorEmailBounce, setCollaboratorEmailBounce] = useState<number[]>([]);
  const [collaboratorEmailExpired, setCollaboratorEmailExpired] = useState<number[]>([]);
  const [collaboratorAccepts, setCollaboratorAccepts] = useState<number[]>([]);
  const [callSheetEmailBounce, setCallSheetEmailBounce] = useState<number[]>([]);
  const [callSheetDelivered, setCallSheetDelivered] = useState<number[]>([]);
  const [callSheetConfirmed, setCallSheetConfirmed] = useState<number[]>([]);
  const [taskAssigned, setTaskAssigned] = useState<number[]>([]);
  const [emailNewsletter, setEmailNewsletter] = useState<boolean>(false);
  const [personDetailsEmailBounce, setPersonDetailsEmailBounce] = useState<number[]>([]);
  const [personDetailsDelivered, setPersonDetailsDelivered] = useState<number[]>([]);
  const [personDetailsViewed, setPersonDetailsViewed] = useState<number[]>([]);
  const [personDetailsSaved, setPersonDetailsSaved] = useState<number[]>([]);
  const [personMessageEmailBounce, setPersonMessageEmailBounce] = useState<number[]>([]);
  const [personMessageDelivered, setPersonMessageDelivered] = useState<number[]>([]);
  const [personMessageViewed, setPersonMessageViewed] = useState<number[]>([]);
  const [personMessageSaved, setPersonMessageSaved] = useState<number[]>([]);

  useEffect(() => {
    if (userCommunicationPreferences) {
      setWhatsNew(userCommunicationPreferences.whatsNew);
      setFeatureRequestComment(userCommunicationPreferences.featureRequestComment);
      setFeatureRequestStatus(userCommunicationPreferences.featureRequestStatus);
      setFeatureRequestVote(userCommunicationPreferences.featureRequestVote);
      setCollaboratorEmailBounce(userCommunicationPreferences.collaboratorEmailBounce);
      setCollaboratorEmailExpired(userCommunicationPreferences.collaboratorEmailExpired);
      setCollaboratorEmailDelivered(userCommunicationPreferences.collaboratorEmailDelivered);
      setCollaboratorAccepts(userCommunicationPreferences.collaboratorAccepts);
      setCallSheetEmailBounce(userCommunicationPreferences.callSheetEmailBounce);
      setCallSheetDelivered(userCommunicationPreferences.callSheetDelivered);
      setCallSheetConfirmed(userCommunicationPreferences.callSheetConfirmed);
      setTaskAssigned(userCommunicationPreferences.taskAssigned);
      setEmailNewsletter(userCommunicationPreferences.emailNewsletter);
      setPersonDetailsEmailBounce(userCommunicationPreferences.personDetailsEmailBounce);
      setPersonDetailsDelivered(userCommunicationPreferences.personDetailsDelivered);
      setPersonDetailsViewed(userCommunicationPreferences.personDetailsViewed);
      setPersonDetailsSaved(userCommunicationPreferences.personDetailsSaved);
      setPersonMessageEmailBounce(userCommunicationPreferences.personMessageEmailBounce);
      setPersonMessageDelivered(userCommunicationPreferences.personMessageDelivered);
      setPersonMessageViewed(userCommunicationPreferences.personMessageViewed);
      setPersonMessageSaved(userCommunicationPreferences.personMessageSaved);
    }
  }, [userCommunicationPreferences]);


  const onSetWhatsNew = (value) => {
    onSetPreference(value, whatsNew, setWhatsNew);
  };

  const onSetFeatureRequestComment = (value) => {
    onSetPreference(value, featureRequestComment, setFeatureRequestComment);
  };

  const onSetFeatureRequestStatus = (value) => {
    onSetPreference(value, featureRequestStatus, setFeatureRequestStatus);
  };

  const onSetFeatureRequestVote = (value) => {
    onSetPreference(value, featureRequestVote, setFeatureRequestVote);
  };

  const onSetCollaboratorEmailBounce = (value) => {
    onSetPreference(value, collaboratorEmailBounce, setCollaboratorEmailBounce);
  };

  const onSetCollaboratorEmailExpired = (value) => {
    onSetPreference(value, collaboratorEmailExpired, setCollaboratorEmailExpired);
  };

  const onSetCollaboratorEmailDelivered = (value) => {
    onSetPreference(value, collaboratorEmailDelivered, setCollaboratorEmailDelivered);
  };

  const onSetCollaboratorAccepts = (value) => {
    onSetPreference(value, collaboratorAccepts, setCollaboratorAccepts);
  };

  const onSetCallSheetEmailBounce = (value) => {
    onSetPreference(value, callSheetEmailBounce, setCallSheetEmailBounce);
  };

  const onSetCallSheetDelivered = (value) => {
    onSetPreference(value, callSheetDelivered, setCallSheetDelivered);
  };

  const onSetCallSheetConfirmed = (value) => {
    onSetPreference(value, callSheetConfirmed, setCallSheetConfirmed);
  };

  const onSetTaskAssigned = (value) => {
    onSetPreference(value, taskAssigned, setTaskAssigned);
  };

  const onSetNewsletter = () => {
    setEmailNewsletter(!emailNewsletter)
    setPristine(false);
  };

  const onSetPersonDetailsEmailBounce = (value) => {
    onSetPreference(value, personDetailsEmailBounce, setPersonDetailsEmailBounce);
  };

  const onSetPersonDetailsDelivered = (value) => {
    onSetPreference(value, personDetailsDelivered, setPersonDetailsDelivered);
  };

  const onSetPersonDetailsViewed = (value) => {
    onSetPreference(value, personDetailsViewed, setPersonDetailsViewed);
  };

  const onSetPersonDetailsSaved = (value) => {
    onSetPreference(value, personDetailsSaved, setPersonDetailsSaved);
  };

  const onSetPersonMessageEmailBounce = (value) => {
    onSetPreference(value, personMessageEmailBounce, setPersonMessageEmailBounce);
  };

  const onSetPersonMessageDelivered = (value) => {
    onSetPreference(value, personMessageDelivered, setPersonMessageDelivered);
  };

  const onSetPersonMessageViewed = (value) => {
    onSetPreference(value, personMessageViewed, setPersonMessageViewed);
  };

  const onSetPersonMessageSaved = (value) => {
    onSetPreference(value, personMessageSaved, setPersonMessageSaved);
  };

  const onSetPreference = (value, preference, setPreference) => {
    const newPreference = Array.from(preference);
    const index = newPreference.findIndex(w => w === value);
    if (index > -1) {
      newPreference.splice(index, 1)
    } else {
      newPreference.push(value);
    }

    setPreference(newPreference);

    setPristine(false);
  };

  const submit = () => {
    onSubmit({
      whatsNew,
      featureRequestComment,
      featureRequestStatus,
      featureRequestVote,
      collaboratorEmailBounce,
      collaboratorEmailExpired,
      collaboratorEmailDelivered,
      collaboratorAccepts,
      callSheetEmailBounce,
      callSheetDelivered,
      callSheetConfirmed,
      emailNewsletter,
      taskAssigned,
      personDetailsEmailBounce,
      personDetailsDelivered,
      personDetailsViewed,
      personDetailsSaved,
      personMessageEmailBounce,
      personMessageDelivered,
      personMessageViewed,
      personMessageSaved
    });
  };

  return (
    <>
      <Prompt when={!pristine} message="" />
      <h2 className="menu-label">Communication Preferences</h2>
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={submit}>

        <table className="table communication-preferences">
          <thead>
            <tr>
              <th></th>
              <th className="has-text-centered">In App</th>
              <th className="has-text-centered">Email</th>
            </tr>
          </thead>
          <tbody>
            <CommunicationPreference label="What's New" preference={whatsNew} onChange={onSetWhatsNew} />
            <tr>
              <td>Newsletter</td>
              <td></td>
              <td><div className="communication-preferences-checkbox"><FilmanizeCheckbox checked={emailNewsletter} onChange={onSetNewsletter} /></div></td>
            </tr>
            <td colSpan={3}><div className="has-text-weight-bold">Call Sheet</div></td>
            <CommunicationPreference label="Email Bounce" preference={callSheetEmailBounce} onChange={onSetCallSheetEmailBounce} />
            <CommunicationPreference label="Email Delivered" preference={callSheetDelivered} onChange={onSetCallSheetDelivered} />
            <CommunicationPreference label="Confirmed" preference={callSheetConfirmed} onChange={onSetCallSheetConfirmed} />
            <td colSpan={3}><div className="has-text-weight-bold">Dietary Requirments</div></td>
            <CommunicationPreference label="Email Bounce" preference={personDetailsEmailBounce} onChange={onSetPersonDetailsEmailBounce} />
            <CommunicationPreference label="Email Delivered" preference={personDetailsDelivered} onChange={onSetPersonDetailsDelivered} />
            <CommunicationPreference label="Viewed" preference={personDetailsViewed} onChange={onSetPersonDetailsViewed} />
            <CommunicationPreference label="Saved" preference={personDetailsSaved} onChange={onSetPersonDetailsSaved} />
            <td colSpan={3}><div className="has-text-weight-bold">Messages</div></td>
            <CommunicationPreference label="Email Bounce" preference={personMessageEmailBounce} onChange={onSetPersonMessageEmailBounce} />
            <CommunicationPreference label="Email Delivered" preference={personMessageDelivered} onChange={onSetPersonMessageDelivered} />
            <CommunicationPreference label="Viewed" preference={personMessageViewed} onChange={onSetPersonMessageViewed} />
            <CommunicationPreference label="Saved" preference={personMessageSaved} onChange={onSetPersonMessageSaved} />
            <td colSpan={3}><div className="has-text-weight-bold">Collaborators</div></td>
            <CommunicationPreference label="Email Bounce" preference={collaboratorEmailBounce} onChange={onSetCollaboratorEmailBounce} />
            <CommunicationPreference label="Email Expired" preference={collaboratorEmailExpired} onChange={onSetCollaboratorEmailExpired} />
            <CommunicationPreference label="Email Delivered" preference={collaboratorEmailDelivered} onChange={onSetCollaboratorEmailDelivered} />
            <CommunicationPreference label="Accepted" preference={collaboratorAccepts} onChange={onSetCollaboratorAccepts} />
            <td colSpan={3}><div className="has-text-weight-bold">Tasks</div></td>
            <CommunicationPreference label="Task Assigned" preference={taskAssigned} onChange={onSetTaskAssigned} />
            <td colSpan={3}><div className="has-text-weight-bold">Feature Requests</div></td>
            <CommunicationPreference label="Comment" preference={featureRequestComment} onChange={onSetFeatureRequestComment} />
            <CommunicationPreference label="Vote" preference={featureRequestStatus} onChange={onSetFeatureRequestStatus} />
            <CommunicationPreference label="Status Change" preference={featureRequestVote} onChange={onSetFeatureRequestVote} />
          </tbody>
        </table>
        {(!notificationsGranted || notificationsDefault) && <div>
          <div>Browser notifications are currently denied. </div>
          {!notificationsDefault && <div>Please enable them in your browser.</div>}
          {notificationsDefault && <button className="button mt-1" onClick={onEnableNotifications}>Enable</button>}
        </div>}
        <div className="buttons mt-2">
          <button
            type="submit"
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={pristine || submitting}>
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export default UserCommunicationPreferencesForm;
