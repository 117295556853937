import mapPictures from "../../../shared/image-mapper";
import OnboardingType from "../../../shared/onboarding/onboarding-type";
import { SEARCH_SCENES_FOR_VISUAL_EFFECTS } from "../../scenes/search/ducks/actions";
import { sortByName } from "./../../../shared/utility";
import { ADD_SINGLE_VISUAL_EFFECT, ASSIGN_SCENES_TO_VISUAL_EFFECT, DELETE_SINGLE_VISUAL_EFFECT, UPDATE_SINGLE_VISUAL_EFFECT } from "./actions";

interface ActionState {
  visualEffects: any[];
  loading: boolean;
  loadingSceneSettings: boolean;
  visualEffect: any;
  imageFiles: any[];
  readOnly: boolean;
  scenes: any[];
  onboardingSteps: OnboardingType[];
  findingInScript: boolean;
  searchingScenes: boolean;
  enableSave: boolean;
}

export const OnboardingStep1ClassName = 'visual-effects-scenes-onboarding-step-1';
export const OnboardingStep2ClassName = 'visual-effects-scenes-onboarding-step-2';
export const OnboardingStep3ClassName = 'visual-effects-scenes-onboarding-step-3';
export const OnboardingStep4ClassName = 'visual-effects-scenes-onboarding-step-4';
export const OnboardingStep5ClassName = 'visual-effects-scenes-onboarding-step-5';

const defaultState: ActionState = {
  visualEffects: [],
  loading: true,
  loadingSceneSettings: false,
  visualEffect: {},
  imageFiles: [],
  readOnly: false,
  scenes: [],
  findingInScript: false,
  searchingScenes: false,
  enableSave: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scene Search',
      content: `        
        Use this to quickly select the scenes that match the selected criteria.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Script Search',
      content: `Or use this to search the script for the name of the visual effect, and select any scenes that match. You can chose which parts of the script to select.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Select',
      content: `Or just select or unselect all scenes.`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Manual',
      content: `If any scenes have not been selected using any of the above methods, then you can manually select or unselect a scene.`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Save',
      content: `Once you have the desired scenes selected, don't forget to click Save.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "CLEAR_VISUAL_EFFECT": {
      return {
        ...state,
        loading: false,
        visualEffect: null,
        errors: null
      };
    }

    case "VISUAL_EFFECT_SET_NAME": {
      const visualEffect = { name: action.meta.name, nameInScript: action.meta.name, enableSave: true }
      return {
        ...state,
        visualEffect: visualEffect
      };
    }

    case "FETCH_NEXT_VISUAL_EFFECT_NUMBER": {
      let nextNumber = 1;
      if (state.visualEffects && state.visualEffects.length > 0) {
        const maxNumber = Math.max.apply(
          null,
          state.visualEffects.map((visualEffect) => {
            return visualEffect.number;
          })
        );
        nextNumber = maxNumber + 1;
      }
      return {
        ...state,
        visualEffect: { number: nextNumber }
      };
    }

    case "FETCH_VISUAL_EFFECTS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_VISUAL_EFFECTS_FULFILLED": {
      return {
        ...state,
        visualEffects: action.payload.data.visualEffects,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_VISUAL_EFFECT_PENDING": {
      return {
        ...state,
        visualEffect: null,
        loadingvisualEffect: true
      };
    }

    case "FETCH_SINGLE_VISUAL_EFFECT_FULFILLED": {
      let visualEffect = action.payload.data;

      mapPictures(visualEffect);

      return {
        ...state,
        visualEffect,
        loadingvisualEffect: false,
        errors: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingvisualEffect: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingvisualEffect: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingvisualEffect: false
      };
    }

    case UPDATE_SINGLE_VISUAL_EFFECT + "_PENDING": {
      return {
        ...state,
        visualEffect: action.payload,
        loadingvisualEffect: true,
        errors: null
      };
    }

    case UPDATE_SINGLE_VISUAL_EFFECT + "_FULFILLED": {
      const visualEffect = { ...action.meta.visualEffect, ...action.payload.data.visualEffect };
      const visualEffects: any = state.visualEffects.map((c: any, index: number) => {
        if (c.id === visualEffect.id) {
          return { ...c, ...visualEffect };
        } else {
          return c;
        }
      });
      sortByName(visualEffects);
      return {
        ...state,
        visualEffect,
        visualEffects,
        loadingvisualEffect: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case UPDATE_SINGLE_VISUAL_EFFECT + "_REJECTED": {
      return {
        ...state,
        visualEffect: action.meta.visualEffect,
        errors: action.payload.response.data.errors,
        loadingvisualEffect: false
      };
    }

    case ADD_SINGLE_VISUAL_EFFECT + "_PENDING": {
      return {
        ...state,
        errors: null,
        loadingvisualEffect: true
      };
    }

    case ADD_SINGLE_VISUAL_EFFECT + "_FULFILLED": {
      const visualEffect: any = action.payload.data.visualEffect;

      let visualEffects = [visualEffect, ...state.visualEffects];
      sortByName(visualEffects);
      return {
        ...state,
        visualEffect,
        visualEffects,
        loadingvisualEffect: false,
        errors: null,
        redirect: { to: "scenes", id: visualEffect.id }
      };
    }

    case ADD_SINGLE_VISUAL_EFFECT + "_REJECTED": {
      return {
        ...state,
        visualEffect: action.meta.visualEffect,
        errors: action.payload.response.data.errors,
        loadingvisualEffect: false
      };
    }

    case DELETE_SINGLE_VISUAL_EFFECT + "_PENDING": {
      return {
        ...state,
        visualEffect: action.payload,
        loadingvisualEffect: true,
        errors: null
      };
    }

    case DELETE_SINGLE_VISUAL_EFFECT + "_FULFILLED": {
      const visualEffect = action.meta.visualEffect;
      const visualEffects: any = state.visualEffects.filter((c: any, index: number) => {
        return c.id !== visualEffect.id;
      });

      return {
        ...state,
        visualEffect: null,
        visualEffects,
        loadingvisualEffect: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case DELETE_SINGLE_VISUAL_EFFECT + "_REJECTED": {
      return {
        ...state,
        visualEffect: action.meta.visualEffect,
        errors: action.payload.response.data.errors,
        loadingvisualEffect: false
      };
    }

    case "ADD_VISUAL_EFFECT_IMAGE_FILES": {
      let mainImageUrl = state.visualEffect.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.imageFiles[0].name;
      }

      return {
        ...state,
        imageFiles: [...state.imageFiles, ...action.meta.imageFiles],
        visualEffect: { ...state.visualEffect, mainImageUrl: mainImageUrl }
      };
    }

    case "ADD_VISUAL_EFFECT_IMAGE_URL": {
      var visualEffect: any = { ...state.visualEffect };
      let mainImageUrl = state.visualEffect.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.url;
      }

      if (visualEffect?.imageUrls) {
        visualEffect = {
          ...visualEffect,
          imageUrls: [...visualEffect?.imageUrls, action.meta.url],
          mainImageUrl: mainImageUrl
        };
      } else {
        visualEffect = {
          ...visualEffect,
          imageUrls: [action.meta.url],
          mainImageUrl: mainImageUrl
        };
      }
      return {
        ...state,
        visualEffect: visualEffect
      };
    }

    case "DELETE_VISUAL_EFFECT_IMAGE_URL": {
      let imageUrls: any = state.visualEffect.imageUrls;
      imageUrls = imageUrls.filter((item) => {
        return item !== action.meta.url;
      });
      return {
        ...state,
        visualEffect: { ...state.visualEffect, imageUrls: imageUrls }
      };
    }

    case "DELETE_VISUAL_EFFECT_IMAGE_FILE": {
      let imageFiles = state.imageFiles.filter((file) => {
        return file.name !== action.meta.fileName;
      });
      return {
        ...state,
        imageFiles: imageFiles
      };
    }

    case "VISUAL_EFFECT_MAIN_IMAGE_CHANGED": {
      return {
        ...state,
        visualEffect: { ...state.visualEffect, mainImageUrl: action.meta.url }
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        visualEffect: null,
        redirect: null
      };
    }

    case "FETCH_ALL_SCENES_FOR_VISUAL_EFFECTS_FULFILLED": {
      const scenes = action.payload.data.scenes.map((scene) => {
        return { ...scene, name: `${scene.number}. ${scene.settingName}` };
      });
      return {
        ...state,
        scenes,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_VISUAL_EFFECTS_PENDING": {
      return {
        ...state,
        loadingSceneSettings: true
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_VISUAL_EFFECTS_FULFILLED": {
      return {
        ...state,
        selectedSceneIds: action.payload.data.sceneIds,
        readOnly: action.payload.data.readOnly,
        loadingSceneSettings: false,
        loading: false,
        errors: null
      };
    }

    case "SET_SCENE_ASSIGNED_TO_VISUAL_EFFECTS": {
      return {
        ...state,
        selectedSceneIds: [action.meta.sceneId]
      };
    }

    case ASSIGN_SCENES_TO_VISUAL_EFFECT + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "FETCH_ALL_CHARACTERS_FOR_VISUAL_EFFECTS_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        loading: false,
        errors: null
      };
    }

    case "FETCH_ALL_SCENE_SETTINGS_FOR_VISUAL_EFFECTS_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case SEARCH_SCENES_FOR_VISUAL_EFFECTS + "_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case SEARCH_SCENES_FOR_VISUAL_EFFECTS + "_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        searchingScenes: false,
      };
    }

    case SEARCH_SCENES_FOR_VISUAL_EFFECTS + "_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "VISUAL_EFFECTS_SEARCH_SCRIPT_PENDING": {
      return {
        ...state,
        findingInScript: true
      }
    }

    case "VISUAL_EFFECTS_SEARCH_SCRIPT_FULFILLED": {
      let searchedScenes = action.payload.data.scenes;
      const sceneIds = state.scenes.map(s => s.id);
      const filteredScenes = searchedScenes.filter(s => sceneIds.indexOf(s) > -1);
      return {
        ...state,
        searchedScenes: filteredScenes.map((id) => { return { id } }),
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        findingInScript: false
      };
    }

    case "VISUAL_EFFECTS_SEARCH_SCRIPT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
        findingInScript: false
      };
    }

    case "CLEAR_SEARCH_SCENES_FOR_VISUAL_EFFECTS": {
      return {
        ...state,
        searchedScenes: [],
        loading: false,
        errors: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
