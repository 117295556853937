import { v4 as uuidv4 } from 'uuid';
import { FILM_TASK_ADDED, FILM_TASK_MOVED, FILM_TASK_UPDATED, FILM_TASK_DELETED, FILM_TASK_ASSIGNED } from '../../../middleware/filmTaskSignalRMiddleware';
import OnboardingType from "../../../shared/onboarding/onboarding-type";
import { ADD_TASK, ASSIGN_TASK, DELETE_TASK, EDIT_TASK, FETCH_ASSIGNED_COLLABORATORS, FETCH_TASKS, FETCH_TASK_BOARD, MOVE_TASK, TASK_SHOW_MENU, TASK_TEXT_CHANGED, TASK_TEXT_FINISHED_EDITING } from "./actions";

interface TasksState {
  tasks: any;  
  columns: any;
  columnOrder: any[];
  showMenuOnTaskId: any;
  loading: boolean;
  saving: boolean;
  task: any;  
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'location-onboarding-step-1';
export const OnboardingStep2ClassName = 'location-onboarding-step-2';
export const OnboardingStep3ClassName = 'location-onboarding-step-3';
export const OnboardingStep4ClassName = 'location-onboarding-step-4';
export const OnboardingStep5ClassName = 'location-onboarding-step-5';

const defaultState: TasksState = {
  tasks: {    
  },    
  columns: {    
  },  
  columnOrder: [],
  loading: true,
  saving: false,
  task: null,  
  showMenuOnTaskId: null,
  readOnly: false,  
  onboardingSteps: [      
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Task',
      content: `Add task.`    
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_TASKS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_TASKS + "_FULFILLED": {
      return {
        ...state,
        tasks: action.payload.data.tasks,
        readOnly: action.payload.data.readOnly,
        loading: false
      };
    }

    case FETCH_TASKS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FETCH_ASSIGNED_COLLABORATORS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_ASSIGNED_COLLABORATORS + "_FULFILLED": {
      return {
        ...state,
        collaborators: action.payload.data.collaborators,        
        loading: false
      };
    }

    case FETCH_ASSIGNED_COLLABORATORS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FETCH_TASK_BOARD + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_TASK_BOARD + "_FULFILLED": {
      const columns = action.payload.data.statuses;
      const columnOrder = Object.keys(columns);
      const tasks = action.payload.data.tasks;

      return {
        ...state,
        columns,
        columnOrder,
        tasks,
        readOnly: action.payload.data.readOnly,
        loading: false
      };
    }

    case FETCH_TASK_BOARD + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FILM_TASK_MOVED : {    

      const { taskId, oldTaskStatusId, newTaskStatusId, oldOrder, newOrder } = action.meta;      
      if (state.tasks[taskId].saving) {        
        return { ...state };
      }

      const start = state.columns[oldTaskStatusId];
      const finish = state.columns[newTaskStatusId];
  
      const newTasks = {...state.tasks};
      
      if (start === finish) {
        const newTaskIds = Array.from(start.taskIds);
        newTaskIds.splice(oldOrder - 1, 1);
        newTaskIds.splice(newOrder - 1, 0, taskId);
  
        const newColumn = {
          ...start,
          taskIds: newTaskIds,
        };
  
        const newState = {
          ...state,
          columns: {
            ...state.columns,
            [newColumn.id]: newColumn,
          },
          tasks: newTasks
        };
          
        return newState;
      }
  
      // Moving from one list to another
      const startTaskIds = Array.from(start.taskIds);
      startTaskIds.splice(oldOrder - 1, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };
  
      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(newOrder - 1, 0, taskId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };

      return {
        ...state,
        columns: {
          ...state.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
        tasks: newTasks
      };
    }

    case MOVE_TASK + "_PENDING": {
      const { destination, source, draggableId } = action.meta;
  
      const start = state.columns[source.droppableId];
      const finish = state.columns[destination.droppableId];
  
      const newTasks = {...state.tasks};
      newTasks[draggableId].saving = true;

      if (start === finish) {
        const newTaskIds = Array.from(start.taskIds);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);
  
        const newColumn = {
          ...start,
          taskIds: newTaskIds,
        };
  
        const newState = {
          ...state,
          columns: {
            ...state.columns,
            [newColumn.id]: newColumn,
          },
          tasks: newTasks,
          showMenuOnTaskId: null
        };
          
        return newState;
      }
  
      // Moving from one list to another
      const startTaskIds = Array.from(start.taskIds);
      startTaskIds.splice(source.index, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };
  
      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };

      return {
        ...state,
        columns: {
          ...state.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
        tasks: newTasks,
        showMenuOnTaskId: null
      };
    }

    case MOVE_TASK + "_FULFILLED": {
      // allow moving again
      const { draggableId } = action.meta;
      const newTasks = {...state.tasks};
      newTasks[draggableId].saving = false;

      return {
        ...state,
        tasks: newTasks
      }
    }

    case MOVE_TASK + "_REJECTED": {
      // TODO: move it back

      const { destination, source, draggableId } = action.meta;
      const newTasks = {...state.tasks};
      newTasks[draggableId].saving = false;

      return {
        ...state,
        tasks: newTasks
      }    
    }

    case DELETE_TASK + "_PENDING": {
      const { columnId, task } = action.meta;  
      const column = state.columns[columnId];
        
      const newTasks = {...state.tasks};
      delete newTasks[task.id];
          
        const newTaskIds = Array.from(column.taskIds);
        const columTaskIndex = column.taskIds.findIndex(t => t === task.id)
        newTaskIds.splice(columTaskIndex, 1);        
  
        const newColumn = {
          ...column,
          taskIds: newTaskIds,
        };
  
        const newState = {
          ...state,
          columns: {
            ...state.columns,
            [newColumn.id]: newColumn,
          },
          tasks: newTasks
        };
          
        return newState;    
    }

    case DELETE_TASK + "_REJECTED": {
      // put back      
      const { columnId, task, originalIndex } = action.meta;  
      const newTasks = {...state.tasks};
      newTasks[task.id] = task;

      const column = state.columns[columnId];
      const newTaskIds = Array.from(column.taskIds);
      
      newTaskIds.splice(originalIndex, 0, task.id);

      const newColumn = {
        ...column,
        taskIds: newTaskIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
        tasks: newTasks
      };
        
      return newState;    
    }

    case FILM_TASK_DELETED : {      
      const { taskId, taskStatusId } = action.meta;  
      
      if (!state.tasks[taskId]) {
        // this happens when the client that is doing the delete recieves this signalR message
        return {
          ...state
        }
      }

      const column = state.columns[taskStatusId];
      
      const newTasks = {...state.tasks};
      delete newTasks[taskId];
          
        const newTaskIds = Array.from(column.taskIds);
        const columTaskIndex = column.taskIds.findIndex(t => t === taskId)
        newTaskIds.splice(columTaskIndex, 1);        
  
        const newColumn = {
          ...column,
          taskIds: newTaskIds,
        };
  
        const newState = {
          ...state,
          columns: {
            ...state.columns,
            [newColumn.id]: newColumn,
          },
          tasks: newTasks,
          showMenuOnTaskId: null
        };
          
        return newState;    
    }

    case ASSIGN_TASK + "_PENDING": {
      const { taskId, collaboratorId } = action.meta;
      const task = state.tasks[taskId];
      const tasks = {
        ...state.tasks,
        [taskId]: { ...task, saving: true },
      };
      
      return {
        ...state,
        tasks,
        showMenuOnTaskId: null,
        saving: true,
        errors: null
      };
    }

    case ASSIGN_TASK + "_FULFILLED": {
      const { taskId, collaboratorId } = action.meta;      

      const task = state.tasks[taskId];
      const collaborator = action.payload.data;
      
      const tasks = {
        ...state.tasks,
        [taskId]: { ...task, saving: false, assignee: collaboratorId ? { id: collaboratorId, firstName: collaborator.firstName, lastName: collaborator.lastName, backgroundColor: collaborator.backgroundColor, profileImageUrl: collaborator.profileImageUrl } : null }
      };

      return {
        ...state,
        tasks,
        showMenuOnTaskId: null,
        saving: true,
        errors: null
      };
    }

    case ASSIGN_TASK + "_REJECTED": {
      const { taskId } = action.meta;
      debugger;

      const task = state.tasks[taskId];
      const tasks = {
        ...state.tasks,
        [taskId]: { ...task, saving: false },
      };

      return {
        ...state,
        tasks,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case FILM_TASK_ASSIGNED : {
      const { taskId, 
              collaboratorId,
              collaboratorFirstName,
              collaboratorLastName,
              backgroundColor,
              profileImageUrl } = action.meta;  
      
      if (state.tasks[taskId].saving) {
        // this happens when the client that is doing the delete recieves this signalR message
        return {
          ...state
        }
      }

      const task = state.tasks[taskId];
      const tasks = {
        ...state.tasks,
        [taskId]: { ...task, assignee: collaboratorId ? { id: collaboratorId, firstName: collaboratorFirstName, lastName: collaboratorLastName, backgroundColor, profileImageUrl } : null }, 
      };

      return {
        ...state,
        tasks        
      };
    }

    case ADD_TASK: {
      var taskId = uuidv4();

      const tasks = {
        ...state.tasks,
        [taskId]: { id: taskId, title: "", edit: true, new: true },
      };

      const columnId = action.meta.columnId;
      const column = state.columns[columnId];
      const newTaskIds = Array.from(column.taskIds);
      newTaskIds.unshift(taskId);
      const newColumn = {
        ...column,
        taskIds: newTaskIds,
      };

      return {
        ...state,
        tasks,
        columns: {
          ...state.columns,
          [columnId]: newColumn
        }
      };
    }

    case TASK_SHOW_MENU: {      
      return {
        ...state,
        showMenuOnTaskId: action.meta.show ? action.meta.taskId : null
      };
    }

    case FILM_TASK_ADDED: {      
      const { taskId, taskStatusId, order, title } = action.meta;

      if (state.tasks[taskId]) {
        return { ...state };
      }

      const tasks = {
        ...state.tasks,
        [taskId]: { id: taskId, title },
      };

      const columnId = taskStatusId;
      const column = state.columns[columnId];
      const newTaskIds = Array.from(column.taskIds);
      newTaskIds.unshift(taskId);
      const newColumn = {
        ...column,
        taskIds: newTaskIds,
      };

      return {
        ...state,
        tasks,
        columns: {
          ...state.columns,
          [columnId]: newColumn
        }
      }
    }
    
    case EDIT_TASK: {
      const { task } = action.meta;

      const tasks = {
        ...state.tasks,
        [task.id]: { ...task, edit: true },
      };

      return {
        ...state,
        tasks
      };
    }

    case FILM_TASK_UPDATED:
    case TASK_TEXT_CHANGED: {
      const { taskId, title } = action.meta;

      const task = state.tasks[taskId];
      const tasks = {
        ...state.tasks,
        [taskId]: { ...task, title },
      };
      
      return {
        ...state,
        tasks
      };
    }

    case TASK_TEXT_FINISHED_EDITING + "_PENDING": {
      const { taskId } = action.meta;

      const task = state.tasks[taskId];
      const tasks = {
        ...state.tasks,
        [taskId]: { ...task, saving: true },
      };
      
      return {
        ...state,
        tasks,
        showMenuOnTaskId: null,
        saving: true,
        errors: null
      };
    }

    case TASK_TEXT_FINISHED_EDITING + "_FULFILLED": {
      const { taskId } = action.meta;

      const task = state.tasks[taskId];
      const tasks = {
        ...state.tasks,
        [taskId]: { ...task, edit: false, saving: false, new: false },
      };
      
      return {
        ...state,
        tasks,
        saving: false,
        errors: null
      };
    }

    case TASK_TEXT_FINISHED_EDITING + "_REJECTED": {

      const { taskId } = action.meta;

      const task = state.tasks[taskId];
      const tasks = {
        ...state.tasks,
        [taskId]: { ...task, saving: false },
      };

      return {
        ...state,
        errors: action.payload.response.data.errors,
        tasks,
        saving: false
      };
    }

    default:
      return state;
  }
};

export default reducer;

