import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const ADD_VISUAL_EFFECT_TO_SCENE = "ADD_VISUAL_EFFECT_TO_SCENE";
export const CLEAR_SCENE_VISUAL_EFFECTS_REDIRECT = "CLEAR_SCENE_VISUAL_EFFECTS_REDIRECT";
export const FETCH_VISUAL_EFFECTS_IN_SCENE = "FETCH_VISUAL_EFFECTS_IN_SCENE";

export function fetchVisualEffectsInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_VISUAL_EFFECTS_IN_SCENE,
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/visualEffects`),
    });
  };
}

export function addVisualEffectToScene(filmId, sceneId, visualEffect, fromBreakdown) {
  return (dispatch) => {
    dispatch({
      type: ADD_VISUAL_EFFECT_TO_SCENE,
      payload: getApiClient().post(`/film/${filmId}/scene/${sceneId}/visualEffect/${visualEffect.visualEffectId}`),
      meta: { fromBreakdown }
    })
      .then((response) => {
        dispatch(showInfo(`Added visual effect to scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding visual effect to scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SCENE_VISUAL_EFFECTS_REDIRECT
    });
  };
}
