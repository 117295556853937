import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

export const REORDER_SHOOTING_ORDER_DAY_SCENES = "REORDER_SHOOTING_ORDER_DAY_SCENES";

export function fetchShootingDayScenes(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_SCENES",
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Scenes`
      )
    });
  };
}

export function updateShootingDayScenes(filmId, shootingDayId, scenes) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SHOOTING_DAY_SCENES",
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Scenes`,
        scenes
      ),
      meta: { scenes }
    })
      .then((response) => {
        dispatch(showInfo(`Updated Shooting Day Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Shooting Day Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateSceneOrder(
  startIndex,
  endIndex,
  sourceDay,
  destinationDay
) {
  return (dispatch) => {
    dispatch({
      type: REORDER_SHOOTING_ORDER_DAY_SCENES,
      meta: { startIndex, endIndex, sourceDay, destinationDay }
    });
  };
}
