import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "./../../../notifications/ducks/actions";
import { getActionToUploadImagesAndSaveEntity } from "./../../../shared/image-action-creator";

export function fetchSceneSettings(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENESETTINGS",
      payload: getApiClient().get(`/film/${filmId}/scenesetting`)
    });
  };
}

export function clearSceneSetting() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SCENESETTING"
    });
  };
}

export function fetchSceneSetting(filmId, scenesettingId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_SCENESETTING",
      payload: getApiClient().get(
        `/film/${filmId}/scenesetting/${scenesettingId}`
      )
    });
  };
}

export function updateSceneSetting(filmId, sceneSetting) {
  sceneSetting.mainImageUrl = null;
  sceneSetting.imageUrls = [];

  return (dispatch) => {
    updateSceneSettingWithImageUrls(dispatch, sceneSetting, filmId);
  };
}

function updateSceneSettingWithImageUrls(dispatch, scenesetting, filmId) {
  dispatch({
    type: "UPDATE_SINGLE_SCENESETTING",
    payload: getApiClient().put(
      `/film/${filmId}/scenesetting/${scenesetting.id}`,
      scenesetting
    ),
    meta: { sceneSetting: scenesetting }
  })
    .then((response) => {
      const selectedLocation : any = scenesetting.location;
      if (selectedLocation && 
        (selectedLocation.locationId != scenesetting.originalLocation?.id ||
        selectedLocation.subLocationId != scenesetting.originalLocation?.subLocation?.id)
      ) {        
        if (!selectedLocation.locationId) {
          dispatch({
            type: "UPDATE_SINGLE_SCENESETTING_UNASSIGN_LOCATION",
            payload: getApiClient().put(
              `/film/${filmId}/scenesetting/${scenesetting.id}/UnAssignLocation`
            ),
            meta: { scenesetting }
          });
          dispatch({ type: "REDIRECT_SINGLE_SCENESETTING" });
        } else {
          let locations : any[] = [];
          locations.push(selectedLocation);
          dispatch({
            type: "UPDATE_SINGLE_SCENESETTING_ASSIGN_LOCATION",
            payload: getApiClient().put(
              `/film/${filmId}/scenesetting/${scenesetting.id}/AssignLocation`,
              {
                filmId,
                scenesettingId: scenesetting.id,
                ... selectedLocation
              }
            ),
            meta: { scenesetting }
          })
            .catch((response) => {
              dispatch(
                showError(
                  `Error assigning location to scene setting ${scenesetting.name}`
                )
              );
            })
            .then((response) => {
              dispatch(clearNotifications());
            });
        }
      } else {
        dispatch({ type: "REDIRECT_SINGLE_SCENESETTING" });
      }

      dispatch(showInfo(`Updated scene setting ${scenesetting.name}`));
    })
    .catch((response) => {
      dispatch(showError(`Error updating scene setting ${scenesetting.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function addSceneSetting(filmId, sceneSetting) {
  sceneSetting.mainImageUrl = null;
  sceneSetting.imageUrls = [];

  return (dispatch) => {
    addSceneSettingWithImageUrls(dispatch, sceneSetting, filmId);
  };
}

function addSceneSettingWithImageUrls(dispatch, sceneSetting, filmId) {
  dispatch({
    type: "ADD_SINGLE_SCENESETTING",
    payload: getApiClient().post(`/film/${filmId}/scenesetting/`, sceneSetting),
    meta: { sceneSetting }
  })
    .then((response) => {
      const sceneSettingId = response.value.data.sceneSetting.id;
      if (sceneSetting.locationId) {
        dispatch({
          type: "UPDATE_SINGLE_SCENESETTING_ASSIGN_LOCATION",
          payload: getApiClient().put(
            `/film/${filmId}/scenesetting/${sceneSettingId}/AssignLocation`,
            {
              filmId,
              sceneSettingId,
              locationId: sceneSetting.locationId,
              subLocationId: sceneSetting.subLocationId
            }
          ),
          meta: { sceneSetting }
        });
      } else {
        //dispatch({ type: "REDIRECT_SINGLE_SCENESETTING" });
      }

      dispatch(showInfo(`Added scene setting ${sceneSetting.name}`));
    })
    .catch((response) => {
      dispatch(showError(`Error adding scene setting ${sceneSetting.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function deleteSceneSetting(filmId, sceneSetting) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_SCENESETTING",
      payload: getApiClient().delete(
        `/film/${filmId}/scenesetting/${sceneSetting.id}`
      ),
      meta: { sceneSetting }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted scene setting ${sceneSetting.name}`));
      })
      .catch((response) => {
        dispatch(
          showError(`Error deleting scene setting ${sceneSetting.name}`)
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function initAddLocationForm() {
  return (dispatch) => {
    dispatchClearRedirect(dispatch);
  };
}

export function initEditLocationForm() {
  return (dispatch) => {
    dispatchClearRedirect(dispatch);
  };
}

function dispatchClearRedirect(dispatch) {
  dispatch({
    type: "CLEAR_REDIRECT"
  });
}

export const uploadImagesAndSaveSceneSetting = getActionToUploadImagesAndSaveEntity(
  addSceneSettingWithImageUrls,
  updateSceneSettingWithImageUrls
);

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}
