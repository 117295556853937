import React from 'react';
import Marker from './marker';

const LocationPin = (props: any) => {
    const { text, image, onClick, clickable } = props;

    return (
        <div style={{
            color: 'white', 
            //background: 'grey',
            background: 'transparent',
            padding: '15px 10px',
            display: 'inline-flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',            
            transform: 'translate(-50%, -50%)',
            cursor: clickable ? "pointer" : "arrow"
          }}
          
          onClick={onClick}
          >
              {/* <Marker number={1}/> */}
               <img className="map-marker" src={`/images/${image}`}/> 

          </div>
        // 
      
  )}

  export default LocationPin;