import getAnnonymousApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const FETCH_POTENTIAL_DATES = "FETCH_POTENTIAL_DATES";
export const POTENTIAL_DATES_TOGGLE_AVAILABLE = "POTENTIAL_DATES_TOGGLE_AVAILABLE";
export const POTENTIAL_DATES_DELETE_DAYS = "POTENTIAL_DATES_DELETE_DAYS";
export const UPDATE_AVAILABLE_DATES = "UPDATE_AVAILABLE_DATES";
export const POTENTIAL_DATES_ADD_USER_EVENTS = "POTENTIAL_DATES_ADD_USER_EVENTS";
export const POTENIAL_DATES_UNAVAILE_USER_EVENTS = "POTENIAL_DATES_UNAVAILE_USER_EVENTS";

export function fetchPotentialDates(token) {
  return (dispatch) => {
    dispatch({
      type: FETCH_POTENTIAL_DATES,
      payload: getAnnonymousApiClient().get(`/PotentialDates?token=${token}`),
    });
  };
}

export function toggleAvailability(dayId) {
  return (dispatch) => {
    dispatch({
      type: POTENTIAL_DATES_TOGGLE_AVAILABLE,
      meta: { dayId },
    });
  };
}

export function deleteDays(startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: POTENTIAL_DATES_DELETE_DAYS,
      meta: { startDate, endDate }
    })
  }
};

export function addUserEvents(events) {
  return (dispatch) => {
    dispatch({
      type: POTENTIAL_DATES_ADD_USER_EVENTS,
      meta: { events }
    })
  }
};

export function setPotentialDaysToUnavailableIfUserEvent() {
  return (dispatch) => {
    dispatch({
      type: POTENIAL_DATES_UNAVAILE_USER_EVENTS
    })
  }
};

export function updateAvailableDates(token, availableDates) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AVAILABLE_DATES,
      payload: getAnnonymousApiClient().put(`/PotentialDates`, { availableDates, token }),
    }).then((response) => {
      dispatch(showInfo(`Updated available dates`));
    })
      .catch((response) => {
        dispatch(
          showError(`Error updating available dates`)
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}