import {
  clearNotifications,
  showError,
  showInfo
} from "./../../../../notifications/ducks/actions";
import getApiClient from "./../../../../shared/api";
import { getActionToUploadImagesAndSaveEntity } from "./../../../../shared/image-action-creator";

export const CHANGE_SET_DRESSING_TO_PROP = "CHANGE_SET_DRESSING_TO_PROP";

export function fetchSetDressings(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SET_DRESSINGS",
      payload: getApiClient().get(`/film/${filmId}/setDressing`)
    });
  };
}

export function clearSetDressing() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SET_DRESSING"
    });
  };
}

export function setNameAndSceneSetting(name, sceneSettingId) {
  return (dispatch) => {
    dispatch({
      type: "SET_DRESSING_SET_NAME_AND_SCENE_SETTING",
      meta: { name, sceneSettingId }
    });
  };
}


export function fetchNextSetDressingNumber(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NEXT_SET_DRESSING_NUMBER"
    });
  };
}

export function fetchSetDressing(filmId, setDressingId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_SET_DRESSING",
      payload: getApiClient().get(`/film/${filmId}/setDressing/${setDressingId}`)
    });
  };
}

export function updateSetDressing(filmId, setDressing) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_SET_DRESSING",
      payload: getApiClient().put(`/film/${filmId}/setDressing/${setDressing.id}`, setDressing),
      meta: { setDressing }
    })
      .then((response) => {
        dispatch(showInfo(`Updated Set Dressing ${setDressing.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating Set Dressing ${setDressing.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changeSetDressingToProp(filmId, setDressing) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SET_DRESSING_TO_PROP,
      payload: getApiClient().put(`/film/${filmId}/setDressing/${setDressing.id}/ChangeToProp`, setDressing),
      meta: { setDressing }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Set Dressing ${setDressing.name} to Prop`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Set Dressing ${setDressing.name} to Prop`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

function addSetDressingWithImageUrls(dispatch, setDressing, filmId) {
  dispatch({
    type: "ADD_SINGLE_SET_DRESSING",
    payload: getApiClient().post(`/film/${filmId}/setDressing/`, setDressing),
    meta: { setDressing }
  })
    .then((response) => {
      dispatch(showInfo(`Added Set Dressing ${setDressing.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function updateSetDressingWithImageUrls(dispatch, setDressing, filmId) {
  dispatch({
    type: "UPDATE_SINGLE_SET_DRESSING",
    payload: getApiClient().put(`/film/${filmId}/setDressing/${setDressing.id}`, setDressing),
    meta: { setDressing }
  })
    .then((response) => {
      dispatch(showInfo(`Updated setDressing ${setDressing.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export const uploadImagesAndSaveSetDressing = getActionToUploadImagesAndSaveEntity(
  addSetDressingWithImageUrls,
  updateSetDressingWithImageUrls
);

export function addSetDressing(filmId, setDressing) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_SET_DRESSING",
      payload: getApiClient().post(`/film/${filmId}/setDressing/`, setDressing),
      meta: { setDressing }
    })
      .then((response) => {
        dispatch(showInfo(`Added Set Dressing ${setDressing.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding Set Dressing`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteSetDressing(filmId, setDressing) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_SET_DRESSING",
      payload: getApiClient().delete(`/film/${filmId}/setDressing/${setDressing.id}`),
      meta: { setDressing }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted Set Dressing ${setDressing.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting Set Dressing ${setDressing.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addImageFiles(imageFiles) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SET_DRESSING_IMAGE_FILES",
      meta: { imageFiles }
    });
  };
}

export function addImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SET_DRESSING_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SET_DRESSING_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageFile(fileName) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SET_DRESSING_IMAGE_FILE",
      meta: { fileName }
    });
  };
}

export function mainImageUrlChanged(url) {
  return (dispatch) => {
    dispatch({
      type: "SET_DRESSING_MAIN_IMAGE_CHANGED",
      meta: { url }
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function fetchScenesAssignedToSetDressing(filmId, setDressingId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_ASSIGNED_TO_SET_DRESSING",
      payload: getApiClient().get(
        `/film/${filmId}/SetDressing/${setDressingId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedToSetDressing(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SET_SCENE_ASSIGNED_TO_SET_DRESSING",
      meta: { sceneId }
    });
  };
}

export function assignScenesToSetDressing(filmId, setDressingId, sceneIds) {
  return (dispatch) => {
    dispatch({
      type: "ASSIGN_SCENES_TO_SET_DRESSING",
      payload: getApiClient().put(
        `/film/${filmId}/setDressing/${setDressingId}/AssignToScenes`,
        { sceneIds }
      ),
      meta: { setDressingId }
    })
      .then((response) => {
        dispatch(showInfo(`Assigned Set Dressing to Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error assigning Set Dressing to Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchAllSceneSettings(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENE_SETTINGS_FOR_SET_DRESSING",
      payload: getApiClient().get(`/film/${filmId}/scenesetting`)
    });
  };
}

export function fetchAllScenes(filmId, sceneSettingId) {
  let filters: string[] = [];

  filters.push(`sceneSettingIds=${sceneSettingId}`);

  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENES_FOR_SET_DRESSING",
      payload: getApiClient().get(`/film/${filmId}/scene/search?${filters.join("&")}`)
    });
  };
}

export function findInScript(
  filmId,
  propName
) {
  return (dispatch) => {
    dispatch({
      type: "SEARCH_SCRIPT_FOR_SET_DRESSING",
      payload: getApiClient().get(
        `/film/${filmId}/script/search/${propName}`
      ),
      meta: { selectSearchedScenes: true }
    });
  };
}


export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_SCENES_FOR_SET_DRESSING"
    });
  };
}
