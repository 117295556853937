import React, { useEffect } from "react";

import Joyride from "react-joyride";
import { connect } from "react-redux";
import OnboardingTooltip from "./onboarding-tooltip";

import {
  setActiveOnboarding,
  setActiveOnboardingStep,
  updateOnboardingComplete
} from "../../app/ducks/actions";

const Onboarding = (props) => {

  const { onboardingSteps, section, setActiveOnboarding, activeOnboarding, updateOnboardingComplete, setActiveOnboardingStep, activeOnboardingStep, onboardingComplete, callbackFunctions } = props;

  useEffect(() => {
    if (onboardingComplete && !onboardingComplete?.includes(section)) {
      setActiveOnboardingStep(0);
      setActiveOnboarding(section);
    }
  }, [JSON.stringify(onboardingComplete), section]);

  if (!onboardingSteps) {
    return null;
  }

  onboardingSteps.forEach(element => {
    element.disableBeacon = true;
  });

  onboardingSteps[onboardingSteps.length - 1].nextButtonText = 'Get Started';


  const handleJoyrideCallback = (data) => {
    const { action, lifecycle, index } = data;

    if (action === "start") {
      setActiveOnboardingStep(0);
    }

    if (action === "close") {
      updateOnboardingComplete(activeOnboarding);
      setActiveOnboarding(null);
    }

    if (action === "next" && lifecycle === "ready") {
      if (callbackFunctions && callbackFunctions[index]) {
        callbackFunctions[index]();
      }
    }
  };

  return (
    <>
      {activeOnboarding === section ? (
        <Joyride
          steps={onboardingSteps}
          stepIndex={activeOnboardingStep}
          continuous={true}
          scrollOffset={150}
          scrollToFirstStep={true}
          tooltipComponent={OnboardingTooltip}
          callback={handleJoyrideCallback}
        />) : null}
    </>
  )
}

function mapStateToProps(state) {
  return {
    activeOnboarding: state.commonState.activeOnboarding,
    activeOnboardingStep: state.commonState.activeOnboardingStep,
    onboardingComplete: state.commonState.onboardingComplete
  };
}

export default connect(mapStateToProps, {
  setActiveOnboarding,
  setActiveOnboardingStep,
  updateOnboardingComplete
})(Onboarding);