import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../shared/loader";
import { fetchActors } from "./../actors/ducks/actions";
import confirm from "../../shared/modal/confirm";
import {
  addCharacter,
  deleteCharacter,
  fetchCharacter,
  updateCharacter,
  uploadImagesAndSaveCharacter,
  clearRedirect,
  changeCharacterToBackgroundCharacter
} from "./ducks/actions";
import Form from "./form";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function EditCharacter(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const characterId = props.characterId || props.match.params.characterId;

  const {
    fetchCharacter,
    updateCharacter,
    uploadImagesAndSaveCharacter,
    deleteCharacter,
    fetchActors,
    loadingCharacter,
    character,
    errors,
    embedded,
    genderEnum,
    actors,
    readOnly,
    changeCharacterToBackgroundCharacter
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {
    if (invalidIds.indexOf(characterId) === -1) {
      fetchCharacter(filmId, characterId);
      fetchActors(filmId, fetchCharacter);
    }
  }, [filmId, characterId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/characters/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "background-characters") {
      props.clearRedirect();
      const url = `/films/${filmId}/background-characters/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(characterId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function onDeleteCharacter(character) {
    confirm(() => deleteCharacter(filmId, character), character.name);
  }

  function onChangeCharacterToBackgroundCharacter() {
    let message = `Are you sure you want to change ${character.name} into a Background Character?`;
    if (character.actorId) {
      message += " The assigned Actor will be removed."
    }
    confirm(() => changeCharacterToBackgroundCharacter(filmId, character), null, message, "Change Character");
  }

  function renderCharacter(character) {
    return (
      <ScrollIntoView
        className="blade-content widest"
        path="/films/:filmId/characters/:characterId/edit"
        loading={loadingCharacter}>
        <Form
          errors={errors}
          mode="edit"
          characterId={character.id}
          initialValues={character}
          loading={loadingCharacter}
          onSubmit={onUpdateCharacter}
          handleDelete={onDeleteCharacter}
          handleChangeCharacterToBackgroundCharacter={onChangeCharacterToBackgroundCharacter}
          actors={props.actors}
          ethnicityTypes={props.ethnicityTypes}
          skinColors={props.skinColors}
          filmId={filmId}
          readOnly={readOnly}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateCharacter(character) {
    character.minimumAge = character.minimumAge ? +character.minimumAge : null;
    character.maximumAge = character.maximumAge ? +character.maximumAge : null;
    character.imageUrls = (character.imageUrls || []).filter((url) => url);

    if (character.ethnicities?.length) {
      character.ethnicityIds = character.ethnicities.map((m) => m.value || m);
    } else {
      character.ethnicityIds = [];
    }

    if (character.skinColors?.length) {
      character.skinColorIds = character.skinColors.map((m) => m.value || m);
    } else {
      character.skinColorIds = [];
    }

    const addMode = !character.id;

    const files = character.pictures || [];
    if ((files && files.length) > 0 || (character.images && character.images.length > 0)) {
      uploadImagesAndSaveCharacter(character, files, addMode, filmId);
    } else {
      if (!character.imageUrls) {
        character.imageUrls = [];
      }
      updateCharacter(filmId, character);
    }
  }

  return character && !loadingCharacter
    ? renderCharacter(character)
    : renderLoading();
}

function mapStateToProps(state) {
  const { charactersState, actorsState, commonState } = state;
  const characterRootState = charactersState.rootState;
  return {
    character: characterRootState.character,
    loading: characterRootState.loading,
    loadingCharacter: characterRootState.loadingCharacter,
    redirect: characterRootState.redirect,
    genderEnum: commonState.genderEnum,
    ethnicityTypes: commonState.ethnicityTypes,
    skinColors: commonState.skinColors,
    actors: actorsState.rootState.actors,
    errors: characterRootState.errors,
    readOnly: characterRootState.readOnly
  };
}

export default connect(mapStateToProps, {
  fetchCharacter,
  addCharacter,
  updateCharacter,
  uploadImagesAndSaveCharacter,
  deleteCharacter,
  fetchActors,
  clearRedirect,
  changeCharacterToBackgroundCharacter
})(EditCharacter);
