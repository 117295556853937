import React from "react";
import { connect } from "react-redux";
import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Character from "./character";
import Actor from "./actor";
import Errors from "../../../shared/errors";
import HelpText from "../../../shared/help-text";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName
} from "./ducks/reducer";
import Onboarding from '../../../shared/onboarding/onboarding';
import './character-overview.scss';

function Overview(props) {
  const {
    overview,
    filmId,
    charactersAssignedPercentage,
    assignActor,
    unassignActor,
    aliasCharacter,
    unaliasCharacter,
    errors,
    warnings,
    characterOrderBy,
    charactersOnboardingSteps
  } = props;

  const readOnly = overview.readOnly;

  function Charts() {
    return (
      <div className="columns">
        <div
          className={`${OnboardingStep1ClassName} circular-progress column is-one-quarter`}>
          <FilmalityCircularProgress
            percentage={overview.completedCharactersPercentage}
            title="Completed Characters"
          />
        </div>
        <div className="circular-progress column is-one-quarter">
          <FilmalityCircularProgress
            percentage={charactersAssignedPercentage}
            title="Characters Assigned"
          />
        </div>
      </div>
    );
  }

  function CharactersList() {
    return (
      <table className={`${OnboardingStep3ClassName} table`}>
        <thead>
          <tr>
            <th>Character Name</th>
            <th>Scenes</th>
            <th>Actor Name</th>
            <th>Mandy Url</th>
            <th>Starnow Url</th>
          </tr>
        </thead>
        <tbody>
          {(overview.characters || []).map((character, index) => {
            return (
              <Character
                key={index}
                index={index}
                character={character}
                filmId={filmId}
                assignActor={assignActor}
                unassignActor={unassignActor}
                aliasCharacter={aliasCharacter}
                unaliasCharacter={unaliasCharacter}
                readOnly={readOnly}
                totalScenes={overview.totalScenes}
                canAccessActors={overview.canAccessActors}></Character>
            );
          })}
        </tbody>
      </table>
    );
  }

  function ActorsList() {
    return (
      <div className="sticky">
        <table className={`${OnboardingStep4ClassName} table character-overview-actors-table`}>
          <thead>
            <tr>
              <th>Actor</th>
            </tr>
          </thead>
          <tbody>
            {(overview.actors || []).map((actor, index) => {
              return (
                <Actor
                  key={index}
                  actor={actor}
                  index={index}
                  filmId={filmId}
                  readOnly={readOnly}></Actor>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <Charts />
      {/* <RenderHelpText /> */}
      {errors && <Errors errors={errors} />}
      {warnings && <Errors errors={warnings} isWarning={true} />}
      <div className="buttons">
        {characterOrderBy == "Name" && (
          <button
            className={`${OnboardingStep2ClassName} button`}
            onClick={props.orderCharactersByScenes}>
            Order By Number Of Scenes
          </button>
        )}

        {characterOrderBy == "Scenes" && (
          <button
            className={`${OnboardingStep2ClassName} button`}
            onClick={props.orderCharactersByName}>
            Order By Name
          </button>
        )}
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="columns">
          <div className="column">
            <CharactersList />
          </div>
          <div className="column is-narrow">{!readOnly && <ActorsList />}</div>
        </div>
      </DndProvider>

      <Onboarding onboardingSteps={charactersOnboardingSteps} section="Characters" />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    charactersOnboardingSteps:
      state.charactersState.overviewState.charactersOnboardingSteps
  };
}

export default connect(mapStateToProps, {
})(Overview);
