export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.88" height="29.115" viewBox="0 0 18.748 25.853" {...props}>
    <g id="Group_3848" data-name="Group 3848" transform="translate(0.507 0.5)">
      <path id="Path_32867" data-name="Path 32867" d="M22.011,19.566H5.547a.635.635,0,0,1-.595-.857l3.9-10.48a2.412,2.412,0,0,1,2.26-1.571h5.334a2.411,2.411,0,0,1,2.26,1.571l3.9,10.48A.635.635,0,0,1,22.011,19.566Z" transform="translate(-4.912 -6.658)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <path id="Path_32868" data-name="Path 32868" d="M17,31.511H10.667L9.553,25.373a2.276,2.276,0,0,1,2.24-2.683h4.086a2.276,2.276,0,0,1,2.239,2.683Z" transform="translate(-4.912 -6.658)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <circle id="Ellipse_75" data-name="Ellipse 75" cx="1.154" cy="1.154" r="1.154" transform="translate(14.492 16.151)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_719" data-name="Line 719" y2="3.15" transform="translate(15.646 13)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_720" data-name="Line 720" y2="3.061" transform="translate(8.059 13.133)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
      <line id="Line_721" data-name="Line 721" y2="3.061" transform="translate(9.66 13.133)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
    </g>
  </svg>
