import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchSceneDetails,
  fetchScenes,
  updateShootingDayScheduleEvent,
} from "./ducks/actions";
import Form from "./scene-event-form-edit-mode";

function EditSceneEvent(props) {
  const {
    filmId,
    shootingdayId,
    fetchScheduleEventSettings,
    fetchScenes,
    fetchSceneDetails,
    updateShootingDayScheduleEvent,
    currentEvent,
    errors,
  } = props;

  console.log("In edit scene event: " + new Date().valueOf());

  currentEvent.pageStart = currentEvent.pages.start;
  currentEvent.pageEnd = currentEvent.pages.end;
  currentEvent.shotIds = currentEvent.shots.map((s) => s.id);

  useEffect(() => {
    fetchScenes(filmId);
  }, [filmId]);

  useEffect(() => {
    onSceneSelected(currentEvent.sceneId);
  }, [currentEvent.sceneId]);

  function onSceneSelected(sceneId) {
    fetchSceneDetails(filmId, sceneId);
  }

  function onEditScheduleEvent(scheduleEvent) {
    scheduleEvent.type = 1;
    sanitiseDataTypes(scheduleEvent);
    updateShootingDayScheduleEvent(filmId, shootingdayId, scheduleEvent);
  }

  function sanitiseDataTypes(scheduleEvent) {
    scheduleEvent.pageStart = +scheduleEvent.pageStart;
    scheduleEvent.pageEnd = +scheduleEvent.pageEnd;
    //scheduleEvent.shotIds = scheduleEvent.shots.map((s) => s.id);
  }

  if (!currentEvent.id) {
    return null;
  }

  if (!(props.scenes && props.scenes.length && props.selectedSceneDetails)) {
    //debugger;
    return null;
  }

  return (
    <div className="blade-content">
      <Form
        mode="edit"
        errors={errors}
        ScheduleEventId={null}
        initialValues={currentEvent}
        scenes={props.scenes}
        onSceneSelected={onSceneSelected}
        selectedSceneDetails={props.selectedSceneDetails}
        loading={false}
        onSubmit={onEditScheduleEvent}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    scenes: state.shootingdaysState.scheduleState.scenes,
    selectedSceneDetails:
      state.shootingdaysState.scheduleState.selectedSceneDetails,
    errors: state.shootingdaysState.scheduleState.errors,
  };
}

export default connect(mapStateToProps, {
  updateShootingDayScheduleEvent,
  fetchScenes,
  fetchSceneDetails,
})(EditSceneEvent);
