import decodeJWT from "jwt-decode";

const isLoggedIn = () => {
  if (!localStorage.user) {
    return false;
  }

  return !!localStorage.user;
};

const isAuthenticated = () => {
  const user = getUser();
  return isUserAuthenticationValid(user);
};

const isAdmin = () => {
  const user = getUser();
  return isUserAuthenticationValid(user) && user?.isAdmin;
};

const isUserAuthenticationValid = (user) => {
  let authenticationValid = false;
  if (user) {
    const expires = new Date(user.expires);
    const expiresTommorrow = new Date(expires.getTime() + 1000 * 60 * 60 * 24);
    let dateTime: Date = new Date();
    authenticationValid = expiresTommorrow > dateTime;
  }

  return authenticationValid;
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("signalRToken");
  localStorage.removeItem("imageToken");
  localStorage.removeItem("user");
};

const getToken = () => {
  return localStorage.token;
};

const getSignalRToken = () => {
  return localStorage.signalRToken;
};

const getImageToken = () => {
  return localStorage.imageToken;
};

const setToken = (data) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("signalRToken", data.signalRToken);
  localStorage.setItem("imageToken", data.imageToken);
  localStorage.setItem("user", JSON.stringify(data || {}));
};

const updateToken = (token, expires) => {
  localStorage.setItem("token", token);
  let currentUser = getUser();
  let newUser = { ...currentUser, token, expires };
  localStorage.setItem("user", JSON.stringify(newUser || {}));
};

const getUser = () => {
  return (
    isLoggedIn() && (JSON.parse(localStorage.getItem("user") || "") || undefined)
  );
};

const getCurrentUserInfo = () => {
  const decoded = decodeJWT(localStorage.token);
  return {
    name: decoded.name,
    firstName: decoded.given_name,
    lastName: decoded.family_name,
    emails: decoded.emails,
    city: decoded.city,
    country: decoded.country
  };
};

export default {
  isLoggedIn,
  isAuthenticated,
  logout,
  setToken,
  updateToken,
  getToken,
  getSignalRToken,
  getImageToken,
  getUser,
  getCurrentUserInfo,
  isAdmin
};
