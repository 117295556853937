import getApiClient from "./../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../notifications/ducks/actions";

export const CLEAR_EQUIPMENT = "CLEAR_EQUIPMENT";
export const DELETE_SINGLE_EQUIPMENT = "DELETE_SINGLE_EQUIPMENT";
export const FETCH_EQUIPMENT_ACQUISITION_METHODS = "FETCH_EQUIPMENT_ACQUISITION_METHODS";
export const NEW_EQUIPMENT = "NEW_EQUIPMENT";

export function fetchEquipments(filmId, categoryId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_EQUIPMENTS",
      payload: getApiClient().get(`/film/${filmId}/EquipmentCategory/${categoryId}/Equipment`),
    });
  };
}

export function fetchEquipment(filmId, categoryId, equipmentId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_EQUIPMENT",
      payload: getApiClient().get(`/film/${filmId}/EquipmentCategory/${categoryId}/Equipment/${equipmentId}`),
    });
  };
}

export function fetchEquipmentAcquisitionMethods() {
  return (dispatch) => {
    dispatch({
      type: FETCH_EQUIPMENT_ACQUISITION_METHODS,
      payload: getApiClient().get(`/enums/EquipmentAcquisitionMethod`),
    });
  };
}

export function clearEquipment() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EQUIPMENT
    });
  };
}

export function initialiseNewEquipment() {
  return (dispatch) => {
    dispatch({
      type: NEW_EQUIPMENT
    });
  };
}

export function updateEquipment(filmId, categoryId, equipment) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_EQUIPMENT",
      payload: getApiClient().put(`/film/${filmId}/EquipmentCategory/${categoryId}/Equipment/${equipment.id}`, equipment),
      meta: { equipment },
    })
      .then((response) => {
        dispatch(showInfo(`Updated equipment ${equipment.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating equipment ${equipment.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addEquipment(filmId, categoryId, equipment) {

  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_EQUIPMENT",
      payload: getApiClient().post(`/film/${filmId}/EquipmentCategory/${categoryId}/Equipment`, equipment),
      meta: { equipment },
    })
      .then((response) => {
        dispatch(showInfo(`Added equipment ${equipment.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding equipment`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteEquipment(filmId, categoryId, equipment) {
  return (dispatch) => {
    dispatch({
      type: DELETE_SINGLE_EQUIPMENT,
      payload: getApiClient().delete(`/film/${filmId}/EquipmentCategory/${categoryId}/Equipment/${equipment.id}`),
      meta: { equipment },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted equipment ${equipment.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting equipment ${equipment.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteDepartment(filmId, department) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_DEPARTMENT",
      payload: getApiClient().delete(`/film/${filmId}/CrewDepartment/${department.id}`),
      meta: { department },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted department ${department.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting department ${department.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
