import OffScreen from "../icons/off-screen";
import NoDialog from "../icons/no-dialog";
import CanAccessNavLink from "../../shared/can-access-nav-link";

const SceneCharacter = (props) => {
  const { id, filmId, sceneId, name, onlyOffScene, hasDialog, canAccessCharacters } = props;

  return (
    <span className="tag is-dark">

      <CanAccessNavLink to={{ pathname: `/films/${filmId}/scenes/${sceneId}/characters/${id}/edit`, state: { fromBreakdownOverview: true } }} canAccess={canAccessCharacters}>
        {name}
      </CanAccessNavLink>

      {(onlyOffScene || !hasDialog) && <p>&nbsp; &nbsp;</p>}
      {onlyOffScene && <OffScreen className="small-icon" />}
      {!hasDialog && <NoDialog className="small-icon" />}
    </span>
  );
};

export default SceneCharacter;
