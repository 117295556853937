export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.33" height="32.271" viewBox="0 0 11.986 23.793"  {...props}>
    <g id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected" transform="translate(0.504 0.5)">
      <g id="Group_3846" data-name="Group 3846">
        <path id="Path_32863" data-name="Path 32863" d="M-213.922,49.373h0a5.489,5.489,0,0,1-5.463-6.028l.841-7.306a.452.452,0,0,1,.448-.4h8.347a.451.451,0,0,1,.448.4l.841,7.306A5.489,5.489,0,0,1-213.922,49.373Z" transform="translate(219.412 -35.64)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32864" data-name="Path 32864" d="M-217.817,58.433h7.79a.165.165,0,0,0,.128-.268s-3.385-2.409-3.385-3.348V49.373l-1.464-.029v5.43c0,.859-3.2,3.4-3.2,3.4A.165.165,0,0,0-217.817,58.433Z" transform="translate(219.412 -35.64)" fill="none" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32865" data-name="Path 32865" d="M-214.748,47.329" transform="translate(219.412 -35.64)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32866" data-name="Path 32866" d="M-210.512,38.56l.419,3.633a4.677,4.677,0,0,1-2.1,4.38" transform="translate(219.412 -35.64)" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      </g>
    </g>
  </svg>
