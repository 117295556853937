import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import {
  fetchShootingDayScenes,
  updateShootingDayScenes,
  updateSceneOrder
} from "./ducks/actions";
import EditScenesListForm from "./edit-form";

function ListShootingDayScenesContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchShootingDayScenes,
    updateShootingDayScenes,
    loading,
    scenes,
    scenesWithoutDays,
    errors,
    readOnly,
    canAccessScenes,
    canAccessLocations,
    updateSceneOrder
  } = props;

  useEffect(() => {
    fetchShootingDayScenes(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function renderLoading() {
    return <Loader />;
  }

  function onUpdateShootingDayActors(scenes) {
    console.log(scenes);
    updateShootingDayScenes(filmId, shootingdayId, scenes);
  }


  const onDragEnd = (result) => {

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    updateSceneOrder(
      result.source.index,
      result.destination.index,
      result.source.droppableId,
      result.destination.droppableId
    );
  };

  function renderShootingDays(scenes, filmId) {
    return (
      <div className="blade-content wider">
        <p className="menu-label">Scenes</p>
        {!scenes?.length && (
          <div className="notification">No scenes assigned yet</div>
        )}
        {!!scenes?.length && (
          <EditScenesListForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            initialValues={{ scenes: scenes }}
            loading={loading}
            onSubmit={onUpdateShootingDayActors}
            readOnly={readOnly}
            canAccessScenes={canAccessScenes}
            canAccessLocations={canAccessLocations}
            shootingdayId={shootingdayId}
            scenesWithoutDays={scenesWithoutDays}
            dragEnd={onDragEnd}
            {...props}
          />
        )}
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(scenes, filmId);
}

function mapStateToProps(state) {
  return {
    shootingDay: state.shootingdaysState.rootState.shootingday,
    readOnly: state.shootingdaysState.scenesState.readOnly,
    canAccessScenes: state.shootingdaysState.scenesState.canAccessScenes,
    canAccessLocations: state.shootingdaysState.scenesState.canAccessLocations,
    scenes: state.shootingdaysState.scenesState.scenes,
    scenesWithoutDays: state.shootingdaysState.scenesState.scenesWithoutDays,
    loading: state.shootingdaysState.scenesState.loading,
    errors: state.shootingdaysState.scenesState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayScenes,
  updateShootingDayScenes,
  updateSceneOrder
})(ListShootingDayScenesContainer);
