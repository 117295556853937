export function sortByName(list) {
  list.sort((a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });
}

export function sortScenesByNumber(scenes) {
  if (!scenes) {
    return;
  }
  scenes.sort((a, b) => {
    const regex = /(\d+)/;
    const numberA = a.number;
    const numberB = b.number;
    const matchA = numberA.match(regex);
    if (!matchA) {
      return numberA.toLowerCase().localeCompare(numberB.toLowerCase());
    }
    const aNumeric = +matchA[0];
    const matchB = numberB.match(regex);
    if (!matchB) {
      return numberA.toLowerCase().localeCompare(numberB.toLowerCase());
    }
    const bNumeric = +matchB[0];
    if (aNumeric === bNumeric) {
      // perform string comparison
      return numberA.toLowerCase().localeCompare(numberB.toLowerCase());
    }
    if (aNumeric < bNumeric) return -1;
    if (aNumeric > bNumeric) return 1;
    return 0;
  });
}

export const reorderScenes = (list, sourceIndex, destinationIndex) => {
  const result: any[] = Array.from(list);
  result[sourceIndex].shootingOrder = destinationIndex + 1;

  if (sourceIndex > destinationIndex) {
    for (let i = destinationIndex; i < sourceIndex; i++) {
      result[i].shootingOrder++;
    }
  }

  if (sourceIndex < destinationIndex) {
    for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
      result[i].shootingOrder--;
    }
  }

  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};
