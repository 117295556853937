import React from "react";
import { Route } from "react-router-dom";
import AddScene from "./add";
import SceneCharacterRoutes from "./characters/routes";
import SceneBackgroundCharacterRoutes from "./background-characters/routes";
import ScenePropRoutes from "./props/routes";
import SceneSetDressingRoutes from "./set-dressings/routes";
import SceneCostumeRoutes from "./costumes/routes";
import SceneMakeupRoutes from "./makeups/routes";
import SceneVisualEffectRoutes from "./visual-effects/routes";
import SceneEquipmentRoutes from "./equipment/routes";
import SceneShootingDaysRoutes from "./shooting-days/routes";
import EditScene from "./edit";
import ListScenesContainer from "./list-container";
import SceneMenu from "./menu";
import SceneScriptDays from "./script-days/container";
import SceneOrder from "./order/container";
import ShotsRoutes from "./shots/routes";
import { PrivateRoute } from "../../shared/private-route";

const SceneRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes"
        component={ListScenesContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/add"
        component={AddScene}
      />
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId"
        component={SceneMenu}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId"
        component={EditScene}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/edit"
        component={EditScene}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/script-days"
        component={SceneScriptDays}
      />

      <PrivateRoute
        exact
        path="/films/:filmId/scenes/order"
        component={SceneOrder}
      />

      <ShotsRoutes />
      <SceneCharacterRoutes />
      <SceneBackgroundCharacterRoutes />
      <ScenePropRoutes />
      <SceneSetDressingRoutes />
      <SceneCostumeRoutes />
      <SceneMakeupRoutes />
      <SceneVisualEffectRoutes />
      <SceneShootingDaysRoutes />
      <SceneEquipmentRoutes />
    </>
  );
};

export default SceneRoutes;
