import React from "react";
import "./icon-with-badge.scss";

const IconWithBadge = (props) => {
  const { count, countClass, children } = props;
  return (
    <div className="icon-with-badge">
      {children}
      <div
        className={`badge ${countClass} ${
          count === null || count === 0 ? "no-notificatons" : ""
        }`}>
        {count ?? ""}
      </div>
    </div>
  );
};

export default IconWithBadge;
