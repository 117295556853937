import { Address } from "../../../shared/Map/google-address-parser";
import {
  clearNotifications,
  showError,
  showInfo
} from "./../../../notifications/ducks/actions";
import getApiClient from "./../../../shared/api";
import { getActionToUploadImagesAndSaveEntity } from "./../../../shared/image-action-creator";

const google: any = null;

export const FETCH_LOCATION_DEFAULTS = "FETCH_LOCATION_DEFAULTS";
export const LOCATION_GET_COUNTRIES = "LOCATION_GET_COUNTRIES";
export const LOCATION_CHANGE_DAY_OF_WEEK = "LOCATION_CHANGE_DAY_OF_WEEK";
export const LOCATION_UPDATE_EVENT = "LOCATION_UPDATE_EVENT";
export const LOCATION_ADD_EVENTS = "LOCATION_ADD_EVENTS";
export const LOCATION_DELETE_EVENT = "LOCATION_DELETE_EVENT";
export const LOCATION_DELETE_EVENTS = "LOCATION_DELETE_EVENTS";
export const WHAT_THREE_WORDS_LOOKUP = "WHAT_THREE_WORDS_LOOKUP";

export function initializeAddEditForm() {
  return (dispatch) => {
    dispatch({
      type: "INITIALIZE_BEFORE_FORM_LOAD"
    });
  };
}

export function fetchDefaults(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_LOCATION_DEFAULTS,
      payload: getApiClient().get(`/film/${filmId}/location/defaults`)
    });
  };
}

export function whatThreeWordsLookup(words) {
  return (dispatch) => {
    dispatch({
      type: WHAT_THREE_WORDS_LOOKUP,
      payload: getApiClient().get(`/WhatThreeWords/${words}`)
    });
  };
}

export function fetchLocations(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_LOCATIONS",
      payload: getApiClient().get(`/film/${filmId}/location`)
    });
  };
}

export function fetchLocationsWithSubLocations(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_LOCATIONS_WITH_SUB_LOCATIONS",
      payload: getApiClient().get(`/film/${filmId}/location/WithSubLocations`)
    });
  };
}

export function fetchLocation(filmId, locationId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_LOCATION",
      payload: getApiClient().get(`/film/${filmId}/location/${locationId}`),
      meta: { locationId }
    });
  };
}

export function getCountries(filmId) {
  const filmIdQuery = filmId ? `?filmId=${filmId}` : null;
  return (dispatch) => {
    dispatch({
      type: LOCATION_GET_COUNTRIES,
      payload: getApiClient().get(`/lookup/countries${filmIdQuery}`),
    });
  };
}

export function clearLocation() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_LOCATION"
    });
  };
}

export function updateLocation(filmId, location, availableDates) {
  location.availableDates = availableDates;
  return (dispatch) => {
    updateLocationWithImageUrls(dispatch, location, filmId, availableDates);
  };
}

export function updateLocationWithImageUrls(dispatch, location, filmId, availableDates) {
  if (!Array.isArray(location.address.addressLines)) {
    location.address.addressLines = location.address.addressLines.split("\n");
  }
  dispatch({
    type: "UPDATE_SINGLE_LOCATION",
    payload: getApiClient().put(
      `/film/${filmId}/location/${location.id}`,
      JSON.stringify(location)
    ),
    meta: { location }
  })
    .then((response) => {
      dispatch(showInfo(`Updated location ${location.name}`));
    })
    .catch((response) => {
      dispatch(showError(`Error updating location ${location.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function addLocationWithImageUrls(dispatch, location, filmId) {
  const json = JSON.stringify(location);
  dispatch({
    type: "ADD_SINGLE_LOCATION",
    payload: getApiClient().post(`/film/${filmId}/location/`, json),
    meta: { location }
  })
    .then((response) => {
      dispatch(showInfo(`Added location ${location.name}`));
    })
    .catch((response) => {
      dispatch(showError(`Error adding location ${location.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function addLocation(filmId, location, availableDates) {
  location.mainImageUrl = null;
  location.imageUrls = [];
  location.availableDates = availableDates;
  if (!Array.isArray(location.address.addressLines)) {
    location.address.addressLines = location.address.addressLines.split("\n");
  }

  return (dispatch) => {
    addLocationWithImageUrls(dispatch, location, filmId);
  };
}

export function deleteLocation(filmId, location) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_LOCATION",
      payload: getApiClient().delete(`/film/${filmId}/location/${location.id}`),
      meta: { location }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted location ${location.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting location ${location.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function setLatLng(lat: number, lng: number) {
  console.log("lat: " + lat);
  console.log("lng: " + lng);
  return (dispatch) => {
    dispatch({
      type: "SET_LOCATION_LAT_LNG",
      meta: { lat, lng }
    });
  };
}

export function fetchHospitals(latitude: number, longitude: number, countryCode: string) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_HOSPITALS",
      payload: getApiClient().get(
        `/hospital?Latitude=${latitude}&Longitude=${longitude}&countryCode=${countryCode}`
      )
    });
  };
}

export function setSelectedHospital(hospital) {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_HOSPITAL",
      meta: { hospital }
    });
  };
}

export function fetchCarParks(latitude: number, longitude: number) {
  var request = {
    location: { lat: latitude, lng: longitude },
    rankby: "distance",
    type: ["parking"]
  };
  let service = new google.maps.places.PlacesService(
    document.createElement("div")
  );
  service.nearbySearch(request, fetchCarParksCallback);

  function fetchCarParksCallback(results, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      const carParks = results.map((carPark) => {
        return {
          place_id: carPark.place_id,
          name: carPark.name,
          position: {
            latitude: carPark.geometry.location.lat(),
            longitude: carPark.geometry.location.lng()
          }
        };
      });

      return (dispatch) => {
        dispatch({
          type: "FETCH_CAR_PARKS",
          payload: carParks
        });
      };
    }
  }
}

export function setCarParks(carParks) {
  return (dispatch) => {
    dispatch({
      type: "SET_CAR_PARKS",
      meta: { carParks: carParks }
    });
  };
}

export function setNoCarParksFound() {
  return (dispatch) => {
    dispatch({
      type: "SET_CAR_PARKS_NONE_FOUND"
    });
  };
}

export function setSelectedCarPark(carPark) {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_CAR_PARK",
      meta: { carPark: carPark }
    });
  };
}

export function setCarParkAddress(address: Address) {
  return (dispatch) => {
    dispatch({
      type: "SET_CAR_PARK_ADDRESS",
      meta: { address: address }
    });
  };
}

export function resetZoomMap() {
  return (dispatch) => {
    dispatch({
      type: "RESET_ZOOM_MAP"
    });
  };
}

export function initAddLocationForm() {
  return (dispatch) => {
    dispatchClearRedirect(dispatch);
  };
}

export function initEditLocationForm() {
  return (dispatch) => {
    dispatchClearRedirect(dispatch);
  };
}

function dispatchClearRedirect(dispatch) {
  dispatch({
    type: "CLEAR_REDIRECT"
  });
}

export const uploadImagesAndSaveLocation = getActionToUploadImagesAndSaveEntity(
  addLocationWithImageUrls,
  updateLocationWithImageUrls
);

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function changeDayOfWeek(dayOfWeek, selected) {
  return (dispatch) => {
    dispatch({
      type: LOCATION_CHANGE_DAY_OF_WEEK,
      meta: { dayOfWeek, selected }
    })
  }
};

export function updateEvent(plainEventObject) {
  return (dispatch) => {
    dispatch({
      type: LOCATION_UPDATE_EVENT,
      plainEventObject
    })
  }
};

export function addDays(startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: LOCATION_ADD_EVENTS,
      meta: { startDate, endDate }
    })
  }
};

export function deleteDay(dayId) {
  return (dispatch) => {
    dispatch({
      type: LOCATION_DELETE_EVENT,
      meta: { dayId }
    })
  }
};

export function deleteDays(startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: LOCATION_DELETE_EVENTS,
      meta: { startDate, endDate }
    })
  }
};

