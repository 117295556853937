import getApiClient from "../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../notifications/ducks/actions";

export const UPLOAD_SCRIPT_UNIONS = "UPLOAD_SCRIPT_UNIONS";
export const UPLOAD_SCRIPT_SET_ACTOR_UNION = "UPLOAD_SCRIPT_SET_ACTOR_UNION";
export const UPLOAD_SCRIPT_SET_CREW_UNION = "UPLOAD_SCRIPT_SET_CREW_UNION";
export const UPLOAD_SCRIPT_SET_CURRENCY_ID = "UPLOAD_SCRIPT_SET_CURRENCY_ID";
export const UPLOAD_SCRIPT_SET_LANGUAGE_ID = "UPLOAD_SCRIPT_SET_LANGUAGE_ID";
export const UPLOAD_SCRIPT_SET_BUDGET = "UPLOAD_SCRIPT_SET_BUDGET";
export const UPLOAD_SCRIPT_SET_USE_AUTO_BREAKDOWN = "UPLOAD_SCRIPT_SET_USE_AUTO_BREAKDOWN";
export const UPLOAD_SCRIPT_ERROR = "UPLOAD_SCRIPT_ERROR";
export const UPLOAD_SCRIPT_PROGRESS = "UPLOAD_SCRIPT_PROGRESS";

export function initScriptUpload() {
  return (dispatch) => {
    dispatch({
      type: "INITIALISE_SCRIPT_UPLOAD",
      payload: null
    });
  };
}

export function getProgress() {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_PROGRESS,
      payload: getApiClient().get("script/progress")
    })
  };
}

export function uploadScript(formData) {
  return (dispatch) => {
    dispatch({
      type: "UPLOAD_SCRIPT",
      payload: getApiClient().post("script", formData, {
        headers: {
          "content-type": `multipart/form-data`
        }
      })
    }).then((response) => {
      //dispatch(showInfo(`Successfully uploaded script`));
    })
      .catch((response) => {
        dispatch(showError(`Error uploading script`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "SCRIPT_CLEAR_REDIRECT"
    });
  };
}

export function setUploadError(error) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_ERROR,
      meta: { error }
    });
  };
}

export function orderCharactersByScenes() {
  return (dispatch) => {
    dispatch({
      type: "UPLOAD_SCRIPT_CHARACTERS_ORDERBY_SCENES"
    });
  };
}


export function orderCharactersByName() {
  return (dispatch) => {
    dispatch({
      type: "UPLOAD_SCRIPT_CHARACTERS_ORDERBY_NAME"
    });
  };
}

export function setActorUnion(actorUnion) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_SET_ACTOR_UNION,
      meta: { actorUnion }
    });
  };
}

export function setCrewUnion(crewUnion) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_SET_CREW_UNION,
      meta: { crewUnion }
    });
  };
}

export function setCurrencyId(currencyId) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_SET_CURRENCY_ID,
      meta: { currencyId }
    });
  };
}

export function setLanguageId(languageId) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_SET_LANGUAGE_ID,
      meta: { languageId }
    });
  };
}

export function setBudget(budget) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_SET_BUDGET,
      meta: { budget }
    });
  };
}

export function setUseAutopBreakdown(useAutoBreakdown) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_SCRIPT_SET_USE_AUTO_BREAKDOWN,
      meta: { useAutoBreakdown }
    });
  };
}