const defaultState = {
  scheduleEventList: [],
  totalPages: 0,
  loading: true,
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTINGDAY_SCHEDULE_EVENT_LIST_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }

    case "FETCH_SHOOTINGDAY_SCHEDULE_EVENT_LIST_FULFILLED": {
      return {
        ...state,
        scheduleEventList: action.payload.data.schedule,
        totalPages: action.payload.data.totalPages,
        loading: false,
        errors: null,
      };
    }

    case "FETCH_SHOOTINGDAY_SCENES_FULFILLED": {
      return {
        ...state,
        scenes: action.payload.data.scenes,
      };
    }

    case "FETCH_SHOOTINGDAY_SCENE_DETAILS_FULFILLED": {
      let shots = action.payload.data.shots.map((s) => {
        s.name = `${s.shotNumber}-${s.shotDescription}`;
        return s;
      });

      return {
        ...state,
        selectedSceneDetails: { ...action.payload.data, shots: shots },
        errors: null
      };
    }

    case "FETCH_SHOOTINGDAY_SCENE_DETAILS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        selectedSceneDetails: null,
        loadingScheduleEvent: false,
      };
    }

    // case "FETCH_SINGLE_SHOOTINGDAY_PENDING": {
    //   return {
    //     ...state,
    //     shootingday: null,
    //     loadingShootingDay: true,
    //   };
    // }

    // case "FETCH_SINGLE_SHOOTINGDAY_FULFILLED": {
    //   return {
    //     ...state,
    //     shootingday: action.payload.data,
    //     loadingShootingDay: false,
    //     errors: null,
    //   };
    // }

    // case "UPDATE_SINGLE_SHOOTINGDAY_PENDING": {
    //   return {
    //     ...state,
    //     shootingday: action.payload,
    //     loadingShootingDay: true,
    //     errors: null,
    //   };
    // }

    // case "UPDATE_SINGLE_SHOOTINGDAY_FULFILLED": {
    //   const shootingday = { ...action.meta.shootingday, ...action.payload.data.shootingDay };
    //   const shootingdays: any = state.shootingdays.map((c: any, index: number) => {
    //     if (c.id === shootingday.id) {
    //       return { ...c, ...shootingday };
    //     } else {
    //       return c;
    //     }
    //   });

    //   return {
    //     ...state,
    //     shootingday,
    //     shootingdays,
    //     loadingShootingDay: false,
    //     errors: null,
    //   };
    // }

    // case "UPDATE_SINGLE_SHOOTINGDAY_REJECTED": {
    //   return {
    //     ...state,
    //     shootingday: action.meta.shootingDay,
    //     errors: action.payload.response.data.errors,
    //     loadingShootingDay: false,
    //   };
    // }

    case "ADD_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_PENDING": {
      return {
        ...state,
        event: action.payload,
        errors: null,
        loadingScheduleEvent: true,
      };
    }

    case "ADD_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_FULFILLED": {
      const scheduleEvent: any = { ...action.meta.scheduleEvent, ...action.payload.data.event };
      
      let scheduleEventList = [scheduleEvent, ...state.scheduleEventList];
      sortEvents(scheduleEventList);
      return {
        ...state,
        scheduleEvent,
        scheduleEventList,
        loadingScheduleEvent: false,
        errors: null,
      };
    }

    case "ADD_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_REJECTED": {
      return {
        ...state,
        scheduleEvent: action.meta.scheduleEvent,
        errors: action.payload.response.data.errors,
        loadingScheduleEvent: false,
      };
    }

    case "UPDATE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_PENDING": {
      return {
        ...state,
        loadingScheduleEvent: true
      };
    }

    case "UPDATE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_FULFILLED": {
      const scheduleEvent = { ...action.meta.scheduleEvent, ...action.payload.data.event };
      const scheduleEventList: any = state.scheduleEventList.map((c: any, index: number) => {
        if (c.id === scheduleEvent.id) {
          return { ...c, ...scheduleEvent };
        } else {
          return c;
        }
      });
      sortEvents(scheduleEventList);
      return {
        ...state,        
        scheduleEventList,
        loadingScheduleEvent: false,
        errors: null
      };
    }

    case "DELETE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_PENDING": {
      return {
        ...state,
        scheduleEvent: action.meta,
        loadingScheduleEvent: true,
        errors: null,
      };
    }

    case "DELETE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_FULFILLED": {
      const scheduleEvent = action.meta.scheduleEvent;
      const scheduleEventList: any = state.scheduleEventList.filter((c: any, index: number) => {
        return c.id !== scheduleEvent.id;
      });

      return {
        ...state,
        scheduleEvent: null,
        scheduleEventList,
        loadingScheduleEvent: false,
        errors: null,
      };
    }

    case "DELETE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_REJECTED": {
      return {
        ...state,
        scheduleEvent: action.meta.scheduleEvent,
        errors: action.payload.response.data.errors,
        loadingScheduleEvent: false,
      };
    }

    default:
      return state;
  }
};

function sortEvents(events) {
  events.sort((a, b) => {
    if (a.time < b.time)
      return -1;
    if (a.time > b.time)
      return 1;
    return 0;
  });
}

export default reducer;
