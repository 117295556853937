import CkEditor from "./ckeditor";

export const CkEditorField = (props) => {     
    const {
        label,
        value,   
        onChange, 
        required,
        readOnly,
        className    
      } = props;
    return (
    <div className={`field ${className}`}>
      <label className={"label " + (readOnly ? "is-disabled" : "")}>
        {label}
        {required ? <span className="required">&nbsp;*</span> : null}
      </label>
      <div className="control">
        <CkEditor value={value} onChange={onChange} />      
      </div>
    </div>
  )};