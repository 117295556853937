import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import {
  fetchAvailability
} from "./ducks/actions";
import Availability from "./availability";
import Loader from "../../../shared/loader";

const SchedulingContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchAvailability,
    loading,
    saving,
    dates,
    actorsAvailability,
    backgroundActorsAvailability,
    crewMemberAvailability,
    locationsAvailability,
    readOnly,
    canAccessActors,
    canAccessBackgroundActors,
    canAccessCrew,
    datesTotals,
    refetch } = props;

  useEffect(() => {
    fetchAvailability(filmId, false);
  }, []);

  useEffect(() => {
    if (refetch) {
      fetchAvailability(filmId, refetch);
    }
  }, [refetch]);

  return (
    <ScrollIntoView
      className="blade-content wider"
      path="/films/:filmId/scheduling"
      loading={loading}>
      <h2 className="menu-label">Availability</h2>
      {(loading || saving) && <Loader />}
      {!loading && <Availability
        dates={dates}
        datesTotals={datesTotals}
        actorsAvailability={actorsAvailability}
        backgroundActorsAvailability={backgroundActorsAvailability}
        crewMemberAvailability={crewMemberAvailability}
        locationsAvailability={locationsAvailability}
        readOnly={readOnly}
        filmId={filmId}
        canAccessActors={canAccessActors}
        canAccessBackgroundActors={canAccessBackgroundActors}
        canAccessCrew={canAccessCrew}
      />}
    </ScrollIntoView>
  );
};

function mapStateToProps(state) {
  const { schedulingState } = state;
  const availabilityState = schedulingState.availabilityState;
  return {
    errors: availabilityState.errors,
    loading: availabilityState.loading,
    readOnly: availabilityState.readOnly,
    dates: availabilityState.dates,
    datesTotals: availabilityState.datesTotals,
    actorsAvailability: availabilityState.actorsAvailability,
    backgroundActorsAvailability: availabilityState.backgroundActorsAvailability,
    crewMemberAvailability: availabilityState.crewMemberAvailability,
    locationsAvailability: availabilityState.locationsAvailability,
    canAccessActors: availabilityState.canAccessActors,
    canAccessBackgroundActors: availabilityState.canAccessBackgroundActors,
    canAccessCrew: availabilityState.canAccessCrew,
    refetch: availabilityState.refetch
  };
}

export default connect(mapStateToProps,
  {
    fetchAvailability
  })(SchedulingContainer);
