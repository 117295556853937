import React from "react";
import { Route } from "react-router-dom";
import auth from "./auth";

export const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      let authenticated = auth.isAdmin();
      return authenticated ? (
        <Component {...props} />
      ) : (
        <div className="notification is-danger">Not authorized</div>
      );
    }}
  />
);
