import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { addRole, clearRedirect, clearRole } from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../../../shared/scroll-into-view/scroll-into-view";

function AddRole(props) {
  const { filmId, departmentId, roleId } = props.match.params;
  const { addRole, errors, clearRole } = props;

  useEffect(() => {
    clearRole();
  }, []);

  if (props.redirect) {
    if (props.redirect.to == "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/departments/${departmentId}/roles`;
      return <Redirect to={url} />;
    }
  }

  function onAddRole(role) {
    sanitiseDataTypes(role);
    addRole(filmId, departmentId, role);
  }

  function sanitiseDataTypes(role) {}

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/departments/:departmentId/roles/add">
      <Form
        mode="add"
        errors={errors}
        roleId={null}
        initialValues={{ required: true }}
        loading={false}
        onSubmit={onAddRole}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    dietaryRequirementEnum: state.commonState.dietaryRequirementEnum,
    errors: state.crewRolesState.errors,
    redirect: state.crewRolesState.redirect
  };
}

export default connect(mapStateToProps, {
  addRole,
  clearRedirect,
  clearRole
})(AddRole);
