import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../shared/loader";
import { fetchFaqs } from "./ducks/actions";
import AddIcon from "../../films/icons/add";
import List from "./list";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListCostumesContainer(props) {  
  const { fetchFaqs, loading, faqSections, errors, readOnly } = props;

  useEffect(() => {
    fetchFaqs();
  }, [fetchFaqs]);

  function renderLoading() {
    return <Loader />;
  }

  function renderFaqs(faqs) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/admin/faqs">
        <List faqSections={faqSections} />
        {!readOnly && !window.location.pathname.endsWith("add") && (
          <NavLink
            to={`/admin/faqs/add`}
            activeClassName={
              "is-active " + window.location.pathname.endsWith("add")
                ? "disabled"
                : ""
            }
            className="button is-transparent is-add">
            <AddIcon className="white-icon" />
            Add FAQ
          </NavLink>
        )}
      </ScrollIntoView>
    ); 
  }

  return loading ? renderLoading() : renderFaqs(faqSections);
}

function mapStateToProps(state) {
  const { faqSections, loading, errors, readOnly } = state.adminFaqState;
  return { faqSections, loading, errors, readOnly };
}

export default connect(mapStateToProps, {
  fetchFaqs
})(ListCostumesContainer);
