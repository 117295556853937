import { NavLink } from "react-router-dom";

const ChangeLogsList = ({ faqSections }) => {
  return (
    <aside className="menu">
      <p className="menu-label">FAQs</p>
      
        {faqSections?.map((faqSection: any, idx) => (  
          <>
            <div className="heading">
              {faqSection.section}
            </div>  
            <ul className="menu-list">
              {faqSection?.questions?.map((faq, questionIdx) => (
                 <li key={questionIdx}>
                 <NavLink
                   to={`/admin/faqs/${faq.section}/${faq.id}/edit`}
                   title={faq.name}
                   activeClassName="is-active"
                   isActive={(match, location) => {
                     return location.pathname.startsWith(
                       `/admin/faqs/${faq.section}/${faq.id}`
                     );
                   }}>
                   {faq.question}
                 </NavLink>
               </li>
              ))}
            </ul> 
          </>
        ))}
      
    </aside>
  );
};

export default ChangeLogsList;
