import * as React from 'react'

export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="30.691" height="26.775" viewBox="0 0 30.691 26.775" {...props}>
    <g id="Navigation_Icon_Not_Selected" data-name="Navigation / Icon / Not Selected">
      <g id="Icon_Navigation_Extras" data-name="Icon / Navigation / Extras">
        <path id="Path_18" data-name="Path 18" d="M19.521,14.654a6.855,6.855,0,0,0-8.48.416" transform="translate(-1.177 -3.96)" fill="none" strokeLinejoin="round" strokeWidth="1.5" />
        <circle id="Ellipse_4" data-name="Ellipse 4" cx="4.226" cy="4.226" r="4.226" transform="translate(10.076)" fill="none" strokeLinejoin="round" strokeWidth="1.5" />
        <circle id="Ellipse_5" data-name="Ellipse 5" cx="4.226" cy="4.226" r="4.226" transform="translate(18.59 8.361)" fill="none" strokeLinejoin="round" strokeWidth="1.5" />
        <circle id="Ellipse_6" data-name="Ellipse 6" cx="4.226" cy="4.226" r="4.226" transform="translate(1.552 8.361)" fill="none" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Path_19" data-name="Path 19" d="M29.747,23.242a8.4,8.4,0,0,0-14.284,5.993h0A8.4,8.4,0,0,0,1.177,23.242" transform="translate(-1.177 -3.96)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </g>
  </svg>
