import React from "react";
import { NavLink } from "react-router-dom";

const PropsList = ({ filmId, sceneId, props }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Props in scene</p>
      <ul className="menu-list">
        {(props || []).map((prop, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/props/${prop.id}/edit`}
                activeClassName="is-active"
              >
                {prop.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default PropsList;
