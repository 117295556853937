import getApiClient from "./api";
import { clearNotifications, showError } from "./../notifications/ducks/actions";

export function getActionToUploadImagesAndSaveEntity(addEntityFn, updateEntityFn) {
  return function (entity, imageFiles, add, ...args) {
    const mainImageIndex = +entity.mainImageIndex || 0;

    const filmId = args[0]
    var formData = new FormData();
    formData.append("filmId", filmId);
    imageFiles
      .filter((i) => !i.isUploaded)
      .forEach((file) => {
        formData.append("image", file);
      });

    entity.images = imageFiles.filter((i) => i.isUploaded).map((i, index) => { return { url: i.url, isMain: mainImageIndex === index } });

    const originalMainFileName = imageFiles[mainImageIndex]?.name;

    return (dispatch) => {
      dispatch({
        type: "UPLOAD_IMAGES",
        payload: getApiClient().post(`/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
        meta: entity
      })
        .then((response) => {
          let images: any[];
          if (entity?.images && entity.images.length) {
            images = [...entity.images, ...response.value.data.images.map((image, index) => {
              return { url: image.url, isMain: originalMainFileName === image.originalFileName }
            })];
          } else {
            images = response.value.data.images.map((image, index) => { return { url: image.url, isMain: originalMainFileName === image.originalFileName } });
          }

          entity = { ...entity, images: images };

          /*
          let uploadedFile = response.value.data.urls.find((url) => url.originalFileName == entity.mainImageUrl);
          if (uploadedFile) {
            entity.mainImageUrl = uploadedFile.url;
          }
          */

          if (add) {
            addEntityFn(dispatch, entity, ...args);
          } else {
            updateEntityFn(dispatch, entity, ...args);
          }
        })
        .catch((response) => {
          debugger;
          dispatch(showError(`Error uploading images`));
        })
        .then((response) => {
          dispatch(clearNotifications());
        });
    };
  };
}
