import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../shared/loader";
import {
  fetchLocationOverview,
  clearLocationOverview,
  fetchDistances
} from "./ducks/actions";
import Overview from "./overview";
import Onboarding from '../../../shared/onboarding/onboarding';

const OverviewContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchLocationOverview,
    clearLocationOverview,  
    overview,
    loading,
    errors,
    warnings,
    fetchDistances,
    onboardingSteps
  } = props;

  useEffect(() => {
    fetchLocationOverview(filmId);
    //fetchDistances(1, 2);

    return function cleanUp() {
      clearLocationOverview();
    };
  }, [filmId, fetchLocationOverview, clearLocationOverview]);

  function renderOverview(overview) {
    return (
      <ScrollIntoView
        className="blade-content charcterOverview"
        path="/films/:filmId/locations/overview"
        loading={loading}>
        <h2 className="menu-label">Overview</h2>
        <Overview
          overview={overview}
          filmId={filmId}          
          errors={errors}
          warnings={warnings}
          />
        <Onboarding onboardingSteps={onboardingSteps} section="Locations Overview"/>
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !overview) && <Loader />}
      {!loading && overview && renderOverview(overview)}
    </>
  );
};

function mapStateToProps(state) {
  const { errors, warnings, overview, loading, onboardingSteps } =
    state.locationsState.overviewState;
  return {
    errors,
    warnings,
    overview,
    loading,
    onboardingSteps
  };
}

export default connect(mapStateToProps, {
  fetchLocationOverview,
  clearLocationOverview,
  fetchDistances
})(OverviewContainer);
