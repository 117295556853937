import { FETCH_EQUIPMENT_IN_SCENE } from "./actions";

const defaultState = {
  equipmentCategories: [],
  loading: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_EQUIPMENT_IN_SCENE + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_EQUIPMENT_IN_SCENE + "_FULFILLED": {
      return {
        ...state,
        equipmentCategories: action.payload.data.equipmentCategories,
        loading: false,
        errors: null
      };
    }

    case FETCH_EQUIPMENT_IN_SCENE + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        character: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
