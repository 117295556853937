import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function DepartmentsMenu(props) {
  const { filmId, departmentId } = props.match.params;
  const { readOnly } = props;

  if (departmentId === "add" || departmentId === "overview" || departmentId === "upload") {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Department</p>
      <ul className="menu-list">
        {!readOnly && <li>
          <NavLink
            to={`/films/${filmId}/departments/${departmentId}/edit`}
            activeClassName="is-active">
            Edit Department
          </NavLink>
        </li>}
        <li>
          <NavLink
            to={`/films/${filmId}/departments/${departmentId}/roles`}
            activeClassName="is-active">
            Roles
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const { departmentsState } = state;
  return {
    readOnly: departmentsState.readOnly,
  };
}

export default connect(mapStateToProps, {
})(DepartmentsMenu);
