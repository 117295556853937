import { combineReducers } from "redux";
import { getDate } from "date-fns";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import confirm from "../../../shared/modal/confirm";
import dietaryRequirementsReducer from '../dietary-requirements/ducks/reducer';
import potentialDatesReducer from '../potential-dates/ducks/reducer';
import sendPotentialDatesReducer from '../send-potential-dates/ducks/reducer';
import availabilityReducer from '../availability/ducks/reducer';
import {
  FETCH_SCHEDULE,
  UPDATE_SCHEDULE,
  SCHEDULING_ADD_EVENTS,
  SCHEDULING_CHANGE_DAY_OF_WEEK,
  SCHEDULING_DELETE_EVENT,
  SCHEDULING_DELETE_EVENTS,
  SCHEDULING_UPDATE_EVENT,
  FETCH_SCHEDULE_PROGRESS,
  SET_POTENTIAL_DAYS_WHERE_LOCATIONS_AVAILABLE
} from "./actions";
import { SEND_POTENTIAL_DATES, SEND_POTENTIAL_DATES_TO_PERSON } from "../send-potential-dates/ducks/actions";

interface SchedulingState {
  scenes: any[];
  loading: boolean;
  saving: boolean;
  daysOfWeek: any[];
  days: any[];
  ignoreStartDateChange: boolean;
  pristine: boolean;
  readOnly: boolean;
  onboardingSteps: any[];
  hasPotentialDays: boolean;
  hasSentPotentialDays: boolean;
  loadingProgress: boolean;
}

export const OnboardingStep1ClassName = 'scheduling-onboarding-step-1';
export const OnboardingStep2ClassName = 'scheduling-onboarding-step-2';
export const OnboardingStep3ClassName = 'scheduling-onboarding-step-3';
export const OnboardingStep4ClassName = 'scheduling-onboarding-step-4';

const defaultState: SchedulingState = {
  scenes: [],
  daysOfWeek: [],
  days: [],
  loading: true,
  saving: false,
  ignoreStartDateChange: false,
  //daysOfWeek: {},
  pristine: true,
  readOnly: false,
  hasPotentialDays: false,
  hasSentPotentialDays: false,
  loadingProgress: true,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Working Days',
      content: `        
      <p>Select which days of the week you might be filming on.</p>      
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Selection',
      content: `        
        <p>This chooses what selecting the calendar does. In add mode it will add potential dates to the calendar. In delete mode any potential dates in the selected range will be deleted.</p>
      `
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Calendar',
      content: `        
        <p>Select the days to add or delete depending on the selection mode. You can click and drag across multiple days. Any days of the week not in the working days will be ignored. To quickly delete a single day when in add mode, just click the event.</p>        
      `
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Save',
      content: `        
        <p>Once you have saved the potential dates you will then be able to send these dates to the actors and crew for them to fill in which days they are available.</p>        
      `
    }
  ]
};

export const updateDay = (state, dayId, date) => {
  const days = Array.from(state.days);
  var dayIndex = days.findIndex(
    (day: any) => day.id == dayId
  );
  var day: any = days[dayIndex];
  const newDate = new Date(date);
  var a = moment(day.start);
  var b = moment(newDate);
  const dayDifference = b.diff(a, "days");
  day.id = uuidv4();
  day.start = newDate;
  day.manual = true;

  // // if last day
  // if (dayIndex == days.length - 1) {
  //   return days;
  // }

  // const nextDay: any = days[dayIndex + 1];
  // if there is a day on this new day then shift them all forwards
  // if the next day's date is now less than this date, then move
  // if (nextDay.start <= day.start) {
  //   let currentDay = new Date(day.start);
  //   currentDay.setDate(currentDay.getDate() + 1);
  //   for (let i = dayIndex + 1; i < days.length; i++) {
  //     let nextDay: any = days[i];
  //     while (!state.daysOfWeek[currentDay.getDay()].selected) {
  //       currentDay.setDate(currentDay.getDate() + 1);
  //     }


  //     // if (!nextDay.manual) {
  //     //   nextDay.start = new Date(currentDay);
  //     // } else if (dayDifference > 0) {              
  //     //     const nextDayDate = new Date(nextDay.start);
  //     //     nextDayDate.setDate(nextDayDate.getDate() + dayDifference);
  //     //     nextDay.start = nextDayDate;              
  //     // }


  //     nextDay.start = new Date(currentDay);

  //     currentDay.setDate(currentDay.getDate() + 1);
  //   }
  // }

  return days;
}

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_SCHEDULE_PROGRESS + "_PENDING": {
      return {
        ...state,
        loadingProgress: true
      };
    }

    case FETCH_SCHEDULE_PROGRESS + "_FULFILLED": {

      return {
        ...state,
        hasPotentialDays: action.payload.data.hasPotentialDays,
        hasSentPotentialDays: action.payload.data.hasSentPotentialDays,
        loadingProgress: false,
        errors: null
      };
    }

    case FETCH_SCHEDULE_PROGRESS + "_REJECTED": {
      return {
        ...state,
        loading: false,
        loadingProgress: false,
        errors: action.payload.response.data.errors
      };
    }

    case FETCH_SCHEDULE + "_PENDING": {
      return {
        ...state,
        loading: true,
        days: [],
        errors: null
      };
    }

    case FETCH_SCHEDULE + "_FULFILLED": {
      let daysOfWeek = action.payload.data.daysOfWeek;
      daysOfWeek.forEach((dayOfWeek) => {
        dayOfWeek.allDay = true;
        dayOfWeek.start = new Date(dayOfWeek.start);
        dayOfWeek.manual = false;
      });

      const days = action.payload.data.days;
      days.forEach((day) => {
        day.start = new Date(day.start);
        day.title = "All day";
      });

      action.payload.data.locationsAvailability.forEach((location) => {
        location.availability.forEach((day) => {
          days.push({ id: location.id, start: new Date(day.start), allDay: day.allDay, title: location.name, backgroundColor: "#947eed", borderColor: "#947eed", textColor: "#ffffff", editable: false, isLocation: true })
        });
      });

      return {
        ...state,
        daysOfWeek: daysOfWeek,
        readOnly: action.payload.data.readOnly,
        days: days,
        loading: false,
        errors: null,
        pristine: true
      };
    }

    case FETCH_SCHEDULE + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case UPDATE_SCHEDULE + "_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case UPDATE_SCHEDULE + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        hasPotentialDays: true,
        pristine: true
      };
    }

    case UPDATE_SCHEDULE + "_REJECTED": {
      return {
        ...state,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case SCHEDULING_CHANGE_DAY_OF_WEEK: {
      var daysOfWeek = Array.from(state.daysOfWeek);
      var dayOfWeek: any = daysOfWeek.find(d => d.dayOfWeek === +action.meta.dayOfWeek);
      if (dayOfWeek) {
        dayOfWeek.selected = action.meta.selected;
      }

      let days = Array.from(state.days);
      if (!action.meta.selected) {
        days = days.filter(d => d.isLocation || d.start.getDay() !== dayOfWeek.dayOfWeek);
      }

      return {
        ...state,
        daysOfWeek: daysOfWeek,
        days: days,
        pristine: false
      };
    }

    case SET_POTENTIAL_DAYS_WHERE_LOCATIONS_AVAILABLE: {
      let days = Array.from(state.days);
      const locationEvents = days.filter(d => d.isLocation);

      locationEvents.forEach((location) => {
        const existingDayIndex = days.findIndex(d => !d.isLocation && d.start.getTime() == location.start.getTime());
        if (existingDayIndex == -1) {

          const dayOfWeek = state.daysOfWeek.find(d => d.dayOfWeek == location.start.getDay());
          if (dayOfWeek && dayOfWeek.selected) {
            days.push({ id: uuidv4(), start: new Date(location.start), title: "All day", allDay: true, editable: true });
          }
        }
      });

      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case SCHEDULING_UPDATE_EVENT: {
      const days = updateDay(state, action.plainEventObject.id, action.plainEventObject.start);

      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case SCHEDULING_ADD_EVENTS: {
      const days = Array.from(state.days);
      let startDate = action.meta.startDate;
      const endDate = action.meta.endDate;
      for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
        const existingDayIndex = days.findIndex(d => !d.isLocation && d.start.getTime() == day.getTime());

        if (existingDayIndex == -1) {

          const dayOfWeek = state.daysOfWeek.find(d => d.dayOfWeek == day.getDay());
          if (dayOfWeek && dayOfWeek.selected) {
            days.push({ id: uuidv4(), start: new Date(day), title: "All day", allDay: true, editable: true });
          }
        }
      }
      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case SCHEDULING_DELETE_EVENT: {
      const days = Array.from(state.days);
      const existingDayIndex = days.findIndex(d => d.id === action.meta.dayId);
      if (existingDayIndex !== -1) {
        days.splice(existingDayIndex, 1);
      }

      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case SCHEDULING_DELETE_EVENTS: {
      let days = Array.from(state.days);
      const startDate = action.meta.startDate;
      const endDate = action.meta.endDate;

      days = days.filter(d => d.isLocation || !d.editable || (d.start < startDate || d.start > endDate));

      return {
        ...state,
        days: days,
        pristine: false
      };
    }

    case SEND_POTENTIAL_DATES + "_FULFILLED": {
      return {
        ...state,
        hasSentPotentialDays: true
      };
    }

    case SEND_POTENTIAL_DATES_TO_PERSON + "_PENDING": {
      return {
        ...state,
        hasSentPotentialDays: true
      };
    }

    default:
      return state;
  }
};


export default combineReducers({
  rootState: reducer,
  dietaryRequirementsState: dietaryRequirementsReducer,
  potentialDatesState: potentialDatesReducer,
  sendPotentialDatesState: sendPotentialDatesReducer,
  availabilityState: availabilityReducer
});
