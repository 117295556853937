import View from "./view";

function ListChangeLogs(props) {  
  const {     
    changeLogs,
    moreToLoad,
    loadMore
     } = props;

  return (
    <div>
      {(changeLogs || []).map((changeLog: any, index: number) => <View key={index} changeLog={changeLog} noNavbarPadding={index > 0}/>)}

      <div className="container">
        <div className="columns is-centered">
          <div className="column is-three-quarters-tablet is-6-desktop is-6-widescreen">
            {moreToLoad && <button className="button is-center" onClick={loadMore}>Load more</button> }
          </div>
        </div>
      </div>      
    </div>
  );
}

export default ListChangeLogs;
