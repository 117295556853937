import React from "react";
import memoizeOne from "memoize-one";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import SceneShot from "./sceneShot";
import TimeSlot from "./time-slot";
import HelpText from "../../../shared/help-text";
import Errors from "../../../shared/errors";
import Download from "../../../shared/download";
import "./shot-list-schedule.scss";
import AddTimeSlot from "./add-time-slot";
import ShotQuickSelection from "./shot-quick-selection";
import {
  OnboardingStep2ClassName,
  OnboardingStep10ClassName,
  OnboardingStep11ClassName,
  OnboardingStep12ClassName
} from "./ducks/reducer";
import { useState } from "react";
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";
import confirm from "../../../shared/modal/confirm";
import Scenes from "./scenes";
import { formatTime } from "../../../shared/format-time";

const ScenesList = ({
  shotListSchedule,
  scenes,
  filmId,
  shootingdayId,
  updateShotOrder,
  addTimeSlot,
  addCrewTimeSlot,
  deleteTimeSlot,
  updateTimeSlotOrder,
  onUpdateShotListSchedule,
  selectedShotIds,
  draggingShotId,
  toggleSelection,
  toggleSelectionInGroup,
  setDraggingShotId,
  selectShots,
  selectAllShots,
  selectNoShots,
  multiSelectTo,
  unselectAll,
  deleteShot,
  sceneSettings,
  crewCallHour,
  estimatedWrapHour,
  estimatedWrapTime,
  errors,
  progress,
  download,
  clearPdf,
  onDownloadFile,
  readOnly,
  timeSlotStartTimeChanged,
  onEmailShotList,
  addTimeSlotTabIndex,
  crewEventDesciption,
  mealTimeSlot,
  crewEventDescriptionChanged,
  mealTimeSlotChanged,
  includeImages,
  includeImagesChanged,
  includeEquipment,
  includeEquipmentChanged,
  pristine,
  canAccessScenes,
  canAccessShots
}) => {

  const onDragEnd = (result) => {
    setDraggingShotId(null);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type.startsWith("shot")) {
      updateShotOrder(
        result.source.index,
        result.destination.index,
        result.source.droppableId,
        result.destination.droppableId
      );
    } else if (result.type == "time") {
      updateTimeSlotOrder(result.source.index, result.destination.index);
    }

    //quickSelectSceneChanged("");
  };

  const onDragStart = (start) => {
    const id = start.draggableId;
    const selected = selectedShotIds.find((sceneId) => sceneId === id);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }

    setDraggingShotId(start.draggableId);
  };

  const onDeleteShot = (timeSlot, shot) => {
    confirm(() => deleteShot(timeSlot, shot.sceneId, shot.shotId), shot.shotNumber + " from time slot " + formatTime(timeSlot));
  };


  return (
    <div>
      {(!sceneSettings || sceneSettings.length === 0) && (
        <div className="notification">No Scene Settings</div>
      )}
      {sceneSettings && sceneSettings.length > 0 && (
        <>
          {/* <HelpText>
            Constuct the schedule by creating time slots and then dragging shots
            from the right into the time slot. You can use the quick shot
            selection to select similar shots, and then drag them all into a
            time slot. You can also multi select by holding down ctrl key.
            <br />
            You can add crew events, like setup, as well as meals. Meals will be
            picked up as key events and added to the top of the call sheet.
          </HelpText> */}
          <Errors errors={errors} />
          <div className="columns">
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <div className="column">
                <ShotQuickSelection
                  readOnly={readOnly}
                  sceneSettings={sceneSettings}
                  scenes={scenes}
                  selectShots={selectShots}
                  selectAllShots={selectAllShots}
                  selectNoShots={selectNoShots}
                />
                <Scenes
                  scenes={scenes}
                  filmId={filmId}
                  shootingdayId={shootingdayId}
                  selectedShotIds={selectedShotIds}
                  draggingShotId={draggingShotId}
                  toggleSelection={toggleSelection}
                  toggleSelectionInGroup={toggleSelectionInGroup}
                  multiSelectTo={multiSelectTo}
                  readOnly={readOnly}
                  canAccessShots={canAccessShots} />
              </div>
              <div className="column">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Scene</th>
                      <th>Shot</th>
                      <th>Lens</th>
                      <th>Movements</th>
                      <th>Actors</th>
                      <th>Duration</th>
                      <th></th>
                    </tr>
                  </thead>

                  {(shotListSchedule || []).map((timeSlot, index) => (
                    <TimeSlot
                      key={index}
                      filmId={filmId}
                      shootingdayId={shootingdayId}
                      index={index}
                      toggleSelection={toggleSelection}
                      toggleSelectionInGroup={toggleSelectionInGroup}
                      multiSelectTo={multiSelectTo}
                      readOnly={readOnly}
                      timeSlot={timeSlot}
                      deleteTimeSlot={deleteTimeSlot}
                      draggingShotId={draggingShotId}
                      selectedShotIds={selectedShotIds}
                      deleteShot={(timeSlot, shot) => onDeleteShot(timeSlot, shot)}
                      crewCallHour={crewCallHour}
                      estimatedWrapHour={estimatedWrapHour}
                      estimatedWrapTime={estimatedWrapTime}
                      timeSlotStartTimeChanged={timeSlotStartTimeChanged}
                      nextTimeSlot={shotListSchedule[index + 1]}
                      canAccessScenes={canAccessScenes}
                      canAccessShots={canAccessShots}
                    />
                  ))}
                </table>

                <AddTimeSlot
                  readOnly={readOnly}
                  crewCallHour={crewCallHour}
                  estimatedWrapHour={estimatedWrapHour}
                  sceneSettings={sceneSettings}
                  scenes={scenes}
                  addTimeSlot={addTimeSlot}
                  addCrewTimeSlot={addCrewTimeSlot}
                  inputTabIndex={addTimeSlotTabIndex}
                  crewEventDesciption={crewEventDesciption}
                  mealTimeSlot={mealTimeSlot}
                  descriptionChanged={crewEventDescriptionChanged}
                  mealTimeSlotChanged={mealTimeSlotChanged}
                />

                <div className="mt-1">
                  <FilmanizeCheckbox className={`${OnboardingStep11ClassName}`} label="Include shot images" checked={includeImages} onChange={includeImagesChanged} readOnly={readOnly} />
                </div>
                <div className="mt-1">
                  <FilmanizeCheckbox label="Include equipment" checked={includeEquipment} onChange={includeEquipmentChanged} readOnly={readOnly} />
                </div>
                <div className="buttons mt-1 sticky-buttons">
                  {!readOnly && (
                    <button
                      type="submit"
                      className="button"
                      disabled={pristine}
                      onClick={() =>
                        onUpdateShotListSchedule(shotListSchedule)
                      }>
                      Save
                    </button>
                  )}
                  <button
                    type="submit"
                    className={`button ${OnboardingStep12ClassName}`}
                    onClick={onEmailShotList}>
                    Email to self
                  </button>
                  <Download
                    progress={progress}
                    clearPdf={clearPdf}
                    download={download}
                    className={`mb-1 ${OnboardingStep10ClassName}`}
                    downloadFile={onDownloadFile}></Download>

                </div>

              </div>
            </DragDropContext>

          </div>


        </>
      )}


    </div>
  );
};

export default ScenesList;
