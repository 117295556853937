import getApiClient from "./../../../shared/api";
import { showInfo, showError, showWarning, clearNotifications } from "./../../../notifications/ducks/actions";
import { getActionToUploadImagesAndSaveEntity } from "./../../../shared/image-action-creator";

export function fetchActors(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ACTORS",
      payload: getApiClient().get(`/film/${filmId}/actor`),
    });
  };
}

export function clearActor() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_ACTOR",
    });
  };
}

export function fetchNextActorNumber(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NEXT_ACTOR_NUMBER",
    });
  };
}

export function fetchActor(filmId, actorId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_ACTOR",
      payload: getApiClient().get(`/film/${filmId}/actor/${actorId}`),
    });
  };
}

export function updateActor(filmId, actor) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_ACTOR",
      payload: getApiClient().put(`/film/${filmId}/actor/${actor.id}`, actor),
      meta: { actor },
    })
      .then((response) => {
        dispatch(showInfo(`Updated actor ${actor.firstName} ${actor.lastName}`));
        
        if (response.value.data.hasWarnings) {
          dispatch(showWarning(`Warning updating actor ${actor.firstName} ${actor.lastName}`));
        }
      })
      .catch((response) => {        
        dispatch(showError(`Error updating actor ${actor.firstName} ${actor.lastName}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

function addActorWithImageUrls(dispatch, actor, filmId) {
  dispatch({
    type: "ADD_SINGLE_ACTOR",
    payload: getApiClient().post(`/film/${filmId}/actor/`, actor),
    meta: { actor },
  })
    .then((response) => {
      dispatch(showInfo(`Added actor ${actor.firstName} ${actor.lastName}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function updateActorWithImageUrls(dispatch, actor, filmId) {
  dispatch({
    type: "UPDATE_SINGLE_ACTOR",
    payload: getApiClient().put(`/film/${filmId}/actor/${actor.id}`, actor),
    meta: { actor },
  })
    .then((response) => {
      dispatch(showInfo(`Updated actor ${actor.firstName} ${actor.lastName}`));
    })
    .catch((response) => {        
      dispatch(showError(`Error updating actor ${actor.firstName} ${actor.lastName}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export const uploadImagesAndSaveActor = getActionToUploadImagesAndSaveEntity(
  addActorWithImageUrls,
  updateActorWithImageUrls
);

export function addActor(filmId, actor) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_ACTOR",
      payload: getApiClient().post(`/film/${filmId}/actor/`, actor),
      meta: { actor },
    })
      .then((response) => {
        dispatch(showInfo(`Added actor ${actor.firstName} ${actor.lastName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding actor`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteActor(filmId, actor) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_ACTOR",
      payload: getApiClient().delete(`/film/${filmId}/actor/${actor.id}`),
      meta: { actor },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted actor ${actor.firstName} ${actor.lastName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting actor ${actor.firstName} ${actor.lastName}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addImageFiles(imageFiles) {
  return (dispatch) => {
    dispatch({
      type: "ADD_ACTOR_IMAGE_FILES",
      meta: { imageFiles },
    });
  };
}

export function addImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "ADD_ACTOR_IMAGE_URL",
      meta: { url },
    });
  };
}

export function deleteImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_ACTOR_IMAGE_URL",
      meta: { url },
    });
  };
}

export function deleteImageFile(fileName) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_ACTOR_IMAGE_FILE",
      meta: { fileName },
    });
  };
}

export function mainImageUrlChanged(url) {
  return (dispatch) => {
    dispatch({
      type: "ACTOR_MAIN_IMAGE_CHANGED",
      meta: { url },
    });
  };
}

export function parseMandyUrl(url) {
  url = encodeURI(url);
  const parsingApiUrl = `/WebPageParser/Mandy?Url=${url}`;
  return parseUrlFromExternalSource("ACTOR_PARSE_MANDY_URL", url, parsingApiUrl);
}

export function parseStarNowUrl(url) {
  url = encodeURI(url);
  const parsingApiUrl = `/WebPageParser/StarNow?Url=${url}`;
  return parseUrlFromExternalSource("ACTOR_PARSE_STARNOW_URL", url, parsingApiUrl);
}

function parseUrlFromExternalSource(actionType, url, parsingApiUrl) {
  //url = encodeURI(url);
  return (dispatch) => {
    dispatch({
      type: actionType,
      payload: getApiClient().get(parsingApiUrl),
      meta: { url },
    });
    // .then((response) => {
    //   if (response.value.data.success) {
    //     dispatch(showInfo(`Parsed actor ${response.value.data.firstName} ${response.value.data.lastName}`));
    //   } else {
    //     dispatch(showError(`Error parsing actor: ${response.value.data.errors[""][0]}`));
    //   }
    // })
    // .catch((response) => {
    //   dispatch(showError(`Error parsing actor`));
    // })
    // .then((response) => {
    //   dispatch(clearNotifications());
    // });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}

function showWanring(arg0: string): any {
  throw new Error("Function not implemented.");
}

