import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  addShootingDayScheduleEvent,
  fetchSceneDetails,
  fetchScenes,
} from "./ducks/actions";
import Form from "./scene-event-form";

function AddSceneEvent(props) {
  const { filmId, shootingdayId } = props;
  const {
    fetchScenes,
    fetchSceneDetails,
    addShootingDayScheduleEvent,
    errors,
  } = props;

  useEffect(() => {
    fetchScenes(filmId);
  }, []);

  function onSceneSelected(sceneId) {
    fetchSceneDetails(filmId, sceneId);
  }

  function onAddScheduleEvent(scheduleEvent) {
    scheduleEvent.type = 1;
    sanitiseDataTypes(scheduleEvent);
    addShootingDayScheduleEvent(filmId, shootingdayId, scheduleEvent);
  }

  function sanitiseDataTypes(scheduleEvent) {
    scheduleEvent.pageStart = +scheduleEvent.pageStart;
    scheduleEvent.pageEnd = +scheduleEvent.pageEnd;
  }

  return (
    <div className="blade-content">
      <Form
        mode="add"
        errors={errors}
        ScheduleEventId={null}
        initialValues={null}
        scenes={props.scenes}
        onSceneSelected={onSceneSelected}
        selectedSceneDetails={props.selectedSceneDetails}
        loading={false}
        onSubmit={onAddScheduleEvent}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    scenes: state.shootingdaysState.scheduleState.scenes,
    selectedSceneDetails:
      state.shootingdaysState.scheduleState.selectedSceneDetails,
    errors: state.shootingdaysState.scheduleState.errors,
  };
}

export default connect(mapStateToProps, {
  addShootingDayScheduleEvent,
  fetchScenes,
  fetchSceneDetails,
})(AddSceneEvent);
