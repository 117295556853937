export const showNotification = (title: string, body: string, url: string) => {
    if (Notification?.permission !== "granted") {
        return;
    }

    const origin = window.location.origin;    

    var options: NotificationOptions = {
      body: body,
      icon: "https://www.filmanize.com/images/big-f.svg",
      dir: "ltr",
      data: origin + url
    };
    var notification = new Notification(title, options);
    notification.onclick = (event: any) => {
        event.preventDefault(); // prevent the browser from focusing the Notification's tab                
        const url = event.currentTarget.data
        window.open(url, '_blank');
      }
  }