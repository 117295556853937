import { Link, Prompt } from "react-router-dom";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import Errors from "../../shared/errors";
import {
  renderSingleReactSelect,
} from "../../shared/form-helpers";
import required from "../../shared/validation";
import FormErrors from "../../shared/form-errors";
import BoxWraper from "../../account/box-wrapper";
import FaqLangauge from "./language";
import FilmanizeSelectField from "../../shared/filmanize-select-field";

const FAQForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    submitFailed,
    invalid,
    faqId,
    pageSections,
    languages,
    onTranlate,
    faq,
    onChangeSection,
    addLanguage,
    removeLanguage,
    changeLanguage,
    changeLanguageQuestion,
    changeLanguageAnswer
  } = props;
  const thisFaq = props.initialValues || {};
  const readOnly = thisFaq.readOnly;

  const getTranslation = (languageCode) => {
    onTranlate(languageCode)
  };

  const languageOptions = (languages || []).map((language) => {
    return { value: language.code, label: language.name };
  });

  return (
    <BoxWraper width={7}>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add FAQ</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit FAQ</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>

            <FilmanizeSelectField label="Section" value={faq?.section} onChange={onChangeSection} options={pageSections.map((pageSection) => {
              return { value: pageSection.name, label: pageSection.name };
            })} required readOnly={readOnly || mode === "edit"} />

            {/* <Field
              name="section"
              label="Section"
              component={renderSingleReactSelect}
              options={pageSections.map((pageSection) => {
                return { value: pageSection.name, label: pageSection.name };
              })}
              validate={[required]}
              required
              readOnly={readOnly || mode === "edit"}
            /> */}

            {(faq?.languages || []).map((language, index) => (
              <FaqLangauge key={index}
                languageOptions={languageOptions}
                changeLanguage={changeLanguage}
                readOnly={readOnly}
                language={language}
                getTranslation={getTranslation}
                removeLanguage={removeLanguage}
                changeLanguageQuestion={changeLanguageQuestion}
                changeLanguageAnswer={changeLanguageAnswer}
                canChangeSection={mode === "add"} />
            ))}

            <button
              type="button"
              className="button is-small mt-1"
              onClick={addLanguage}>
              Add Language
            </button>

            {/* <FieldArray
                  name={`languages`}
                  languageOptions={languageOptions}
                  component={FaqLangauge}
                  canChangeLanguage={mode === "add"}
                  readOnly={readOnly}
                  getTranslation={getTranslation}
                /> */}

            {!readOnly &&
              <>
                <div className="buttons mt-2">
                  <Link to={`/admin/faqs`} className="button">Cancel</Link>
                  <button
                    onClick={handleSubmit}
                    className="button is-primary"
                    disabled={pristine || submitting}>
                    Save
                  </button>
                  {mode === "edit" && (
                    <>
                      <button
                        type="button"
                        className="button is-text"
                        disabled={submitting || !faq}
                        onClick={() => handleDelete(thisFaq)}>
                        Delete FAQ
                      </button>
                    </>
                  )}
                </div>
              </>}

            <FormErrors submitFailed={submitFailed} invalid={invalid} />

          </form>
        </div>
      </div>
    </BoxWraper>
  );
}

export default FAQForm;
