import * as React from "react";
import Checkbox from "react-custom-checkbox";

export default (props) => {
  const {
    checked,
    onChange,
    disabled,
    readOnly,
    label,
    name,
    value,
    required,
    className
  } = props;

  let labelWithRequired: any[] = [];
  labelWithRequired.push(label);

  if (required) {
    labelWithRequired.push(<span className="required">&nbsp;*</span>);
  }

  const onOnChange = (value) => {
    onChange(value);
  };

  return (
    <Checkbox
      checked={checked ? true : false}
      icon={
        <div
          style={{
            left: "-1px",
            display: "flex",
            flex: 1,
            backgroundColor: "#00FFCE",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
            opacity: readOnly ? "50%" : "100%"
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.623"
            height="7.377"
            viewBox="0 0 8.623 7.377">
            <path
              id="Path_32574"
              data-name="Path 32574"
              d="M-3487.965-12569.415l2.338,2.314,4.175-5.577"
              transform="translate(3489.025 12573.728)"
              fill="none"
              stroke="#182231"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </svg>
        </div>
      }
      onChange={onOnChange}
      disabled={disabled || readOnly}
      label={labelWithRequired}
      borderColor="#495D7A"
      borderWidth={1}
      borderRadius={3}
      labelStyle={{ marginLeft: 10, marginBottom: 0 }}
      name={name}
      value={value}
      labelClassName={disabled || readOnly ? "is-disabled" : ""}
      className={className}
    />
  );
};
