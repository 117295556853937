const defaultState = {
  takes: [],
  loading: true,
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_TAKES_PENDING": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_TAKES_FULFILLED": {
      return {
        ...state,
        takes: action.payload.data.takes,
        loading: false,
      };
    }

    case "FETCH_SINGLE_TAKE_PENDING": {
      return {
        ...state,
        take: null,
        loadingTake: true,
      };
    }

    case "FETCH_SINGLE_TAKE_FULFILLED": {
      return {
        ...state,
        take: action.payload.data,
        loadingTake: false,
        errors: null,
      };
    }

    default:
      return state;
  }
};

export default reducer;
