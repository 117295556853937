import * as React from 'react'

export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.315" height="15.738" viewBox="0 0 19.315 15.738" {...props}>
    <g id="Icon_Non-speaking_part" data-name="Icon / Non-speaking part" transform="translate(0.657 0.707)">
      <g id="Group_3679" data-name="Group 3679" transform="translate(23.873 -2.838)">
        <path id="Path_32769" data-name="Path 32769" d="M-23.873,10.1l.227-.267,3.581-4.21a2.661,2.661,0,0,1,3.237-.646l1.515.773a1.036,1.036,0,0,0,.955-.008l1.366-.727a2.661,2.661,0,0,1,3.258.6l3.861,4.442L-8.21,12.837a6.955,6.955,0,0,1-5.32,2.475h-2.681a6.952,6.952,0,0,1-5.283-2.432Z" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
        <path id="Path_32770" data-name="Path 32770" d="M-23.873,10.1l3.06-1.087a13.031,13.031,0,0,1,4.351-.749h3.176a13.023,13.023,0,0,1,4.21.7l3.2,1.1L-9.044,11.17a13.335,13.335,0,0,1-4.4.747h-2.921a13.346,13.346,0,0,1-4.348-.728Z" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="1" />
      </g>
      <g id="Group_3680" data-name="Group 3680" transform="translate(23.873 -2.838)">
        <line id="Line_538" data-name="Line 538" y1="14.324" x2="14.324" transform="translate(-22.035 2.838)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
      </g>
    </g>
  </svg>
