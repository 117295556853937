import React from "react";
import './about.scss';

const About = () => {
   
    return (
        <div className="marketing-about">
            <div className="marketing-about-box">
                <div className="marketing-about-content">
                    <span>As the site is currently in beta, there may be a few bugs. If you find any, please report them to us and we'll get them fixed for you ASAP. The current features are just the beginning of Filmanize, and we want to build it into the most useful film production software product available. We welcome your input and ideas, and as the timeline of planned features is not set in stone we're open to adding new features or reordering the timeline to meet our users' demands. We want to make this a tool for first ADs, built by first ADs, so please come and join in with the discussion on our </span><a href="https://discord.gg/4G4E87Y5E4" target="_blank">Discord&nbsp;server.</a>
                </div>
            </div>
        </div>
    )
}

export default About;