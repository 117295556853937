import getApiClient from "./../../../../../shared/api";

export function fetchTakes(filmId, sceneId, shotId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_TAKES",
      payload: getApiClient().get(`/film/${filmId}/scene/${sceneId}/shot/${shotId}/Take`),
    });
  };
}

export function fetchTake(filmId, sceneId, shotId, takeId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_TAKE",
      payload: getApiClient().get(`/film/${filmId}/scene/${sceneId}/shot/${shotId}/Take/${takeId}`),
    });
  };
}
