import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchLocationsWithSubLocations } from "./../../locations/ducks/actions";
import {
  aliasSceneSetting,
  assignLocation,
  fetchSceneSettingOverview,
  clearSceneSettingOverview,
  unaliasSceneSetting,
  unassignLocation,
  replaceLocation
} from "./ducks/actions";
import Overview from "./overview";
import Onboarding from '../../../shared/onboarding/onboarding';
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

const OverviewContainer = (props) => {
  const filmId = props.match.params.filmId;
  const {
    fetchSceneSettingOverview,
    fetchLocationsWithSubLocations,
    locationsWithSubLocations,
    assignLocation,
    unassignLocation,
    replaceLocation,
    aliasSceneSetting,
    unaliasSceneSetting,
    overview,
    loading,
    errors,
    onboardingSteps
  } = props;

  useEffect(() => {
    fetchSceneSettingOverview(filmId);
    fetchLocationsWithSubLocations(filmId);
    return function cleanUp() {
      props.clearSceneSettingOverview();
    };
  }, [filmId]);

  function renderOverview(overview, locationsWithSubLocations) {
    console.log("rendering char overview", locationsWithSubLocations);
    const charcactersWithLocations = overview.sceneSettings?.filter(
      (c) => c.locations?.length > 0
    ).length;
    const allSceneSettings = overview.sceneSettings?.length;
    const sceneSettingsAssignedPercentage =
      !allSceneSettings || allSceneSettings === 0
        ? 0
        : (charcactersWithLocations / allSceneSettings) * 100;

    return (
      <ScrollIntoView
        className="blade-content charcterOverview"
        path="/films/:filmId/scene-settings/overview"
        loading={loading}>
        <h2 className="menu-label">Overview</h2>
        <Overview
          overview={overview}
          locationsWithSubLocations={locationsWithSubLocations}
          filmId={filmId}
          sceneSettingsAssignedPercentage={sceneSettingsAssignedPercentage}
          assignLocation={assignLocation}
          unassignLocation={unassignLocation}
          replaceLocation={replaceLocation}
          aliasSceneSetting={aliasSceneSetting}
          unaliasSceneSetting={unaliasSceneSetting}
          errors={errors}
        ></Overview>
        <Onboarding onboardingSteps={onboardingSteps} section="Scene Settings" />
      </ScrollIntoView>
    );
  }

  return (
    <>
      {(loading || !overview) && <Loader />}
      {!loading &&
        overview &&
        renderOverview(overview, locationsWithSubLocations)}
    </>
  );
};

function mapStateToProps(state) {
  const { sceneSettingsState, locationsState } = state;
  const { errors, overview, loading, onboardingSteps } = sceneSettingsState.overviewState;
  return {
    errors,
    overview,
    loading,
    onboardingSteps,
    locationsWithSubLocations: locationsState?.rootState?.locationsWithSubLocations
  };
}

export default connect(mapStateToProps, {
  fetchSceneSettingOverview,
  fetchLocationsWithSubLocations,
  clearSceneSettingOverview,
  assignLocation,
  unassignLocation,
  aliasSceneSetting,
  unaliasSceneSetting,
  replaceLocation
})(OverviewContainer);
