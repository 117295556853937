import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import { fetchShootingDayCrew, updateShootingDayCrew } from "./ducks/actions";
import EditCrewForm from "./edit-form";

function ListShootingDayCrewContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchShootingDayCrew,
    updateShootingDayCrew,
    loading,
    crew,
    errors,
    readOnly
  } = props;

  useEffect(() => {
    fetchShootingDayCrew(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function renderLoading() {
    return <Loader />;
  }

  function onUpdateShootingDayActors(crew) {
    updateShootingDayCrew(filmId, shootingdayId, crew);
  }

  function renderShootingDays(crew, filmId) {
    return (
      <div className="blade-content wide">
        <p className="menu-label">Crew</p>
        <EditCrewForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ crewDepartments: crew }}
          loading={loading}
          onSubmit={onUpdateShootingDayActors}
          readOnly={readOnly}
          {...props}
        />
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(crew, filmId);
}

function mapStateToProps(state) {
  const { rootState, crewState } = state.shootingdaysState;
  return {
    shootingDay: rootState.shootingday,
    readOnly: crewState.readOnly,
    crew: crewState.crew,
    loading: crewState.loading,
    saved: crewState.saved,
    errors: crewState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayCrew,
  updateShootingDayCrew
})(ListShootingDayCrewContainer);
