import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../../shared/loader";
import { fetchShots } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";

function ListShotsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchShots, loading, shots, errors, readOnly } = props;

  useEffect(() => {
    fetchShots(filmId, sceneId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderShots(shots, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List shots={shots} filmId={filmId} sceneId={sceneId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/scenes/${sceneId}/shots/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add New
        </NavLink>}
      </div>
    );
  }

  return loading ? renderLoading() : renderShots(shots, filmId, sceneId);
}

function mapStateToProps(state) {
  return {
    shots: state.shotsState.shots,
    readOnly: state.shotsState.readOnly,
    loading: state.shotsState.loading,
    errors: state.shotsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShots
})(ListShotsContainer);
