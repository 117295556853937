import React from "react";
import Radio from "../../shared/filmanize-radio";

const carPark = (props) => {
  const { carPark, selectedCarPark, onSelectedCarParkChanged } = props;

  return (
    <Radio
      value={carPark.place_id}
      checked={selectedCarPark?.place_id == carPark.place_id}
      text={carPark.name}
      onChange={onSelectedCarParkChanged}
    />
  );
};

export default carPark;
