import {
  clearNotifications,
  showError,
  showInfo
} from "../../../notifications/ducks/actions";
import getApiClient from "./../../../shared/api";

const url = "/film";

export const BUGET_SHOW_HIDE_VISUAL_EFFECTS = "BUGET_SHOW_HIDE_VISUAL_EFFECTS";
export const UPDATE_BUGET_VISUAL_EFFECT_BUDGET = "UPDATE_BUGET_VISUAL_EFFECT_BUDGET";

export function fetchBudget(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_FILM_BUDGET",
      payload: getApiClient().get(`${url}/${id}/Budget`)
    });
  };
}

export function updateDays(days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_DAYS",
      meta: { days }
    });
  };
}

export function updateContingencyPercentage(contingencyPercentage) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_CONTINGENCY_PERCENTAGE",
      meta: { contingencyPercentage }
    });
  };
}

export function updateRoleDailyRate(departmentId, roleId, dailyRate) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_CREW_DAILY_RATE",
      meta: { departmentId, roleId, dailyRate }
    });
  };
}

export function updateCharacterDailyRate(characterId, dailyBudget) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_CHARACTER_DAILY_RATE",
      meta: { characterId, dailyBudget }
    });
  };
}

export function updateSceneSettingDailyRate(sceneSettingId, dailyBudget) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_SCENE_SETTING_DAILY_RATE",
      meta: { sceneSettingId, dailyBudget }
    });
  };
}

export function updateSceneSettingDays(sceneSettingId, days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_SCENE_SETTING_DAYS",
      meta: { sceneSettingId, days }
    });
  };
}

export function updatePropBuget(propId, budget) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_PROP_BUDGET",
      meta: { propId, budget }
    });
  };
}

export function updatePropDays(propId, days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_PROP_DAYS",
      meta: { propId, days }
    });
  };
}

export function updateSetDressingBuget(setDressingId, budget) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_SET_DRESSING_BUDGET",
      meta: { setDressingId, budget }
    });
  };
}

export function updateVisualEffectBuget(visualEffectId, budget) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BUGET_VISUAL_EFFECT_BUDGET,
      meta: { visualEffectId, budget }
    });
  };
}

export function updateSetDressingDays(setDressingId, days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_SET_DRESSING_DAYS",
      meta: { setDressingId, days }
    });
  };
}

export function updateCostumeBuget(costumeId, budget) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_COSTUME_BUDGET",
      meta: { costumeId, budget }
    });
  };
}

export function updateCostumeDays(costumeId, days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_COSTUME_DAYS",
      meta: { costumeId, days }
    });
  };
}

export function updateMakeupBuget(makeupId, budget) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_MAKEUP_BUDGET",
      meta: { makeupId, budget }
    });
  };
}

export function updateMakeupDays(makeupId, days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_MAKEUP_DAYS",
      meta: { makeupId, days }
    });
  };
}

export function onChangeCateringBuget(makeupId, budget) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_CATERING_BUDGET",
      meta: { makeupId, budget }
    });
  };
}

export function onChangeCateringDays(makeupId, days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_CATERING_DAYS",
      meta: { makeupId, days }
    });
  };
}

export function updateBackgroundCharacterBuget(backgroundCharacterId, budget) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_BACKGROUND_CHARACTER_BUDGET",
      meta: { backgroundCharacterId, budget }
    });
  };
}

export function updateBackgroundCharacterDays(backgroundCharacterId, days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_BACKGROUND_CHARACTER_DAYS",
      meta: { backgroundCharacterId, days }
    });
  };
}

export function updateCrewRequired(departmentId, roleId, required) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_CREW_REQUIRED",
      meta: { departmentId, roleId, required }
    });
  };
}

export function updateCrewDays(departmentId, roleId, days, prepDays) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_CREW_DAYS",
      meta: { departmentId, roleId, days, prepDays }
    });
  };
}

export function updateEquipmentRequired(categoryId, equipmentId, required) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_EQUIPMENT_REQUIRED",
      meta: { categoryId, equipmentId, required }
    });
  };
}

export function updateEquipmentDays(categoryId, equipmentId, days) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_EQUIPMENT_DAYS",
      meta: { categoryId, equipmentId, days }
    });
  };
}

export function updateEquipmentDailyRate(categoryId, equipmentId, dailyRate) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_BUGET_EQUIPMENT_DAILY_RATE",
      meta: { categoryId, equipmentId, dailyRate }
    });
  };
}

export function updateBudget(filmId, budget, charactersVisible, backgroundCharactersVisible, sceneSettingsVisible, crewVisible, propsVisible, setDressingsVisible, costumesVisible, makeupsVisible, cateringVisible, equipmentVisible, visualEffectsVisible) {
  var crewMemebers = budget.departments.reduce((accumulator, d) => {
    accumulator = [...accumulator, ...d.crew];
    return accumulator;
  }, []);

  var equipments = budget.equipments.reduce((accumulator, d) => {
    accumulator = [...accumulator, ...d.equipment];
    return accumulator;
  }, []);

  var saveObject = {
    crewDailyRates: crewMemebers,
    equipmentRates: equipments,
    characterDailyBudget: budget.characters,
    backgroundCharacterBudgets: budget.backgroundCharacters,
    days: budget.days,
    propBudgets: budget.props,
    setDressingBudgets: budget.setDressings,
    costumeBudgets: budget.costumes,
    makeupBudgets: budget.makeups,
    sceneSettingBudgets: budget.sceneSettings,
    cateringBudget: budget.catering,
    visualEffectBudgets: budget.visualEffects,
    contingencyPercentage: budget.contingencyPercentage,
    charactersVisible,
    backgroundCharactersVisible,
    sceneSettingsVisible,
    cateringVisible,
    crewVisible,
    equipmentVisible,
    propsVisible,
    setDressingsVisible,
    costumesVisible,
    makeupsVisible,
    totalBudget: budget.totalBudget,
    visualEffectsVisible
  };

  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_FILM_BUDGET",
      payload: getApiClient().put(`${url}/${filmId}/Budget`, saveObject),
      meta: { budget }
    })
      .then((response) => {
        dispatch(showInfo(`Updated budget`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating budget`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function showHideCharacters(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_CHARACTERS",
      meta: { show }
    });
  };
}

export function showHideSceneSettings(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_SCENE_SETTINGS",
      meta: { show }
    });
  };
}

export function showHideCrew(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_CREW",
      meta: { show }
    });
  };
}

export function showHideEquipment(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_EQUIPMENT",
      meta: { show }
    });
  };
}

export function showHideProps(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_PROPS",
      meta: { show }
    });
  };
}

export function showHideSetDressings(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_SET_DRESSINGS",
      meta: { show }
    });
  };
}

export function showHideCostumes(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_COSTUMES",
      meta: { show }
    });
  };
}

export function showHideMakeups(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_MAKEUPS",
      meta: { show }
    });
  };
}

export function showHideBackgroundCharacters(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_BACKGROUND_CHARACTERS",
      meta: { show }
    });
  };
}

export function showHideCatering(show) {
  return (dispatch) => {
    dispatch({
      type: "BUGET_SHOW_HIDE_CATERING",
      meta: { show }
    });
  };
}

export function showHideVisualEffects(show) {
  return (dispatch) => {
    dispatch({
      type: BUGET_SHOW_HIDE_VISUAL_EFFECTS,
      meta: { show }
    });
  };
}

export function downloadBudget(id) {
  return (dispatch) => {
    dispatch({
      type: "DOWNLOAD_BUDGET",
      payload: getApiClient().post(`${url}/${id}/budget/download`),
    });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_DOWNLOAD_BUDGET",
      meta: {},
    });
  };
}
