import { withCallbacks, signalMiddleware } from "redux-signalr";
import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState
} from "@microsoft/signalr"; // version 1.0.4
import getConfig from "../app/config";
import auth from "../shared/auth";

// const startSignalRConnection = (connection) =>
//   connection
//     .start()
//     .then(() => console.info("SignalR Connected"))
//     .catch((err) => console.error("SignalR Connection Error: ", err));

      let config = getConfig();
      const urlRoot = config.apiUrl;
      let url = urlRoot.substring(0, urlRoot.length - 1);
      var to = url.lastIndexOf("/");
      to = to == -1 ? url.length : to + 1;
      url = url.substring(0, to);
      const connectionHub = url + `collaboratorhub`;

      const protocol = new JsonHubProtocol();

      // let transport to fall back to to LongPolling if it needs to
      const transport =
        HttpTransportType.WebSockets | HttpTransportType.LongPolling;

      const options = {
        transport,
        logMessageContent: true,
        logger: LogLevel.Error,
        accessTokenFactory: () => auth.getSignalRToken()
      };

      // create the connection instance
      export const connection = new HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withHubProtocol(protocol)
        .withAutomaticReconnect()
        .build();
        
        //connection.start().then(() => connection.invoke('SubscribeToFilm', "9f7e57c2-ed5e-4308-85e7-ce32f380396d"));
  export const UPDATE_COLLABORATOR_EMAILING_STATUS = "UPDATE_COLLABORATOR_EMAILING_STATUS";

  const callbacks = withCallbacks()  
  .add('CollaboratorEmailStatus', (collaboratorId, status, statusDescription, statusChangedDate) => (dispatch) => {
    dispatch({
      type: UPDATE_COLLABORATOR_EMAILING_STATUS,
      meta: { collaboratorId, status, statusDescription, statusChangedDate }
    });
  })
  
  export const signal = signalMiddleware({
    callbacks,
    connection,
    shouldConnectionStartImmediately: false
  });

  