import React from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import * as QueryString from "query-string"
import {
  resetPassword
} from "./ducks/actions";
import Form from "./form";
import BoxWraper from "../box-wrapper";
import AnonymousPage from "../anonymous-pages/anonymous-page";

function Login(props) {
  const {    
    resetPassword,
    loading,
    errors,
    user
  } = props;

  const params = QueryString.parse(props.location.search);      
  const emailAddress = params.email;
  const token = params.token;

  function onResetPassword(user) {    
    resetPassword(user, emailAddress, token)
  }

  return (
    <AnonymousPage>
      <BoxWraper>
      {loading && <Loader />}
      {!loading && <Form
          errors={errors}        
          loading={false}
          onSubmit={onResetPassword}     
          initialValues={user}   
          {...props}
        />}
      </BoxWraper>
    </AnonymousPage>
  );
}

function mapStateToProps(state) {
  const { accountState } = state;
  return {    
    user: accountState.resetPasswordState.user,
    errors: accountState.resetPasswordState.errors,
    loading: accountState.resetPasswordState.loading,
    submitted: accountState.resetPasswordState.submitted
  };
}

export default connect(mapStateToProps, {
  resetPassword
})(Login);
