import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Loader from "./../../../../../shared/loader";
import { fetchMembers } from "./ducks/actions";
import AddIcon from "../../../../icons/add";
import List from "./list";
import ScrollIntoView from "../../../../../shared/scroll-into-view/scroll-into-view";

function ListMembersContainer(props) {
  const { filmId, departmentId, roleId } = props.match.params;
  const {
    fetchMembers,
    loading,
    members,
    departments,
    errors,
    readOnly,
    phoneNumberRequired,
    backstageSearchUrl,
    sabNetworkSearchUrl
  } = props;

  useEffect(() => {
    fetchMembers(filmId, departmentId, roleId);
  }, [filmId, departmentId, roleId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderMembers(members, filmId, phoneNumberRequired) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/departments/:departmentId/roles/:roleId/members">
        <List
          members={members}
          filmId={filmId}
          departmentId={departmentId}
          roleId={roleId}
        />
        {!readOnly && <>
          {!window.location.pathname.endsWith("add") && (
            <NavLink
              to={{
                pathname: `/films/${filmId}/departments/${departmentId}/roles/${roleId}/members/add`,
                state: { phoneNumberRequired }
              }}
              activeClassName={
                "is-active " + window.location.pathname.endsWith("add")
                  ? "disabled"
                  : ""
              }
              className="button is-transparent is-add">
              <AddIcon className="white-icon" />
              Add New Member
            </NavLink>
          )}
          {!window.location.pathname.endsWith("add-existing") && (
            <NavLink
              to={`/films/${filmId}/departments/${departmentId}/roles/${roleId}/members/add-existing`}
              activeClassName={
                "is-active " + window.location.pathname.endsWith("add-existing")
                  ? "disabled"
                  : ""
              }
              className="button is-transparent is-add">
              <AddIcon className="white-icon" />
              Add Existing Member
            </NavLink>
          )}

          {backstageSearchUrl && <div>
            <a className="button is-transparent is-add" href={backstageSearchUrl} target="_blank">Search Backstage.com</a>
          </div>}

          {sabNetworkSearchUrl && <div>
            <a className="button is-transparent is-add" href={sabNetworkSearchUrl} target="_blank">Search The Sab Network</a>
          </div>}

        </>}
      </ScrollIntoView>
    );
  }

  return loading
    ? renderLoading()
    : renderMembers(members, filmId, phoneNumberRequired);
}

function mapStateToProps(state) {
  return {
    departments: state.departmentsState.departments,
    members: state.crewMembersState.members,
    phoneNumberRequired: state.crewMembersState.phoneNumberRequired,
    backstageSearchUrl: state.crewMembersState.backstageSearchUrl,
    sabNetworkSearchUrl: state.crewMembersState.sabNetworkSearchUrl,
    readOnly: state.crewMembersState.readOnly,
    loading: state.crewMembersState.loading,
    errors: state.crewMembersState.errors
  };
}

export default connect(mapStateToProps, {
  fetchMembers
})(ListMembersContainer);
