import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderCheckboxGroup } from "../../../shared/form-helpers";
import Loader from "../../../shared/loader";
import Errors from "./../../../shared/errors";
import { OnboardingStep3ClassName,
  OnboardingStep4ClassName, 
  OnboardingStep5ClassName } from './ducks/reducer';

function ScriptDownloadScenesForm(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    allScenes,
    searchedSceneIds,
    clearSceneSearchResults,
    selectSearchedScenes,
    readOnly,
    loading,
    reduxFormValues
  } = props;

  useEffect(() => {

    if (searchedSceneIds?.length) {

      const { selectedSceneIds } = reduxFormValues;

      if (selectSearchedScenes) {
        const union = selectedSceneIds ? new Set([...selectedSceneIds, ...searchedSceneIds]) : searchedSceneIds;
        const newSelectedSceneIds = Array.from(union);

        props.change("selectedSceneIds", newSelectedSceneIds);
        clearSceneSearchResults();
      } else {
        const newSelectedSceneIds = (selectedSceneIds || []).filter(
          (el) => searchedSceneIds.indexOf(el) < 0
        );

        props.change("selectedSceneIds", newSelectedSceneIds);
        clearSceneSearchResults();
      }
    }
  }, [searchedSceneIds]);

  function onSelectAllClick() {
    props.change(
      "selectedSceneIds",
      allScenes.map((s) => s.id)
    );
  }

  function onUnselectAllClick() {
    props.change("selectedSceneIds", []);
  }

  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      {!readOnly && <div className={`buttons`}>
        <button
          className="button is-secondary is-small"
          onClick={onSelectAllClick}>
          Select All
        </button>
        <button
          className="button is-secondary is-small"
          onClick={onUnselectAllClick}>
          Unselect All
        </button>
      </div>}
      {loading && <Loader/>}
      {!loading &&
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Field
            label="Scenes"
            name="selectedSceneIds"
            className={OnboardingStep4ClassName}
            component={renderCheckboxGroup}
            options={allScenes}
            textProperty="name"
            valueProperty="id"
            oneInEachRow={true}
            readOnly={readOnly}
          />
        </form>
      }
    </>
  );
}

export default reduxForm({
  form: "scriptDownloadScenesForm",
  enableReinitialize: true  
})(ScriptDownloadScenesForm);
