import React, { useState } from "react";
import {
  findInScript
} from "./ducks/actions";
import { connect } from "react-redux";
import Checkbox from "../../../shared/filmanize-checkbox";

const ScriptSearch = (props: any) => {
  const {
    findInScript,
    text,
    filmId,
    typeToDispatch,
    findingInScript
  } = props;

  const [searchDialog, setSearchDialog] = useState(false);
  const [searchAction, setSearchAction] = useState(true);
  const [searchCharacter, setSearchCharacter] = useState(false);
  const [searchParenthetical, setSearchParenthetical] = useState(true);
  const [searchGeneral, setSearchGeneral] = useState(true);

  const handleSearchDialogClick = () => setSearchDialog(!searchDialog)
  const handleSearchActionClick = () => setSearchAction(!searchAction)
  const handleSearchCharacterClick = () => setSearchCharacter(!searchCharacter)
  const handleSearchParentheticalClick = () => setSearchParenthetical(!searchParenthetical)
  const handleSearchGeneralClick = () => setSearchGeneral(!searchGeneral);

  const onFindInScript = (e) => {
    e.preventDefault();
    findInScript(filmId, text, searchDialog, searchAction, searchCharacter, searchParenthetical, typeToDispatch);
  };

  return (
    <>

      <div className="field">
        <Checkbox checked={searchDialog}
          onChange={handleSearchDialogClick}
          disabled={false}
          readOnly={false}
          label="Search Dialog" />
        <Checkbox checked={searchAction}
          onChange={handleSearchActionClick}
          disabled={false}
          readOnly={false}
          label="Search Action" />
        <Checkbox checked={searchCharacter}
          onChange={handleSearchCharacterClick}
          disabled={false}
          readOnly={false}
          label="Search Character" />
        <Checkbox checked={searchParenthetical}
          onChange={handleSearchParentheticalClick}
          disabled={false}
          readOnly={false}
          label="Search Parenthetical" />
        <Checkbox checked={searchGeneral}
          onChange={handleSearchGeneralClick}
          disabled={false}
          readOnly={false}
          label="Search General" />

        <button
          type="submit"
          className={`button is-secondary is-small mt-1 ${findingInScript ? "is-loading" : ""}`}
          onClick={onFindInScript}>
          Find in script
        </button>
      </div>

    </>
  )
}


function mapStateToProps(state) {
  const { scriptState } = state;
  return {

  };
}

export default connect(mapStateToProps, {
  findInScript
})(ScriptSearch);