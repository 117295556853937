import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../shared/modal/confirm";
import Loader from "../../shared/loader";
import {
  clearRedirect,
  deleteCollaborator,
  fetchCollaborator,
  updateCollaborator,
  resendInvite,
  fetchCollaboratorsRoles
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function UsersContainer(props) {
  const { filmId, collaboratorId } = props.match.params;
  const {
    fetchUser,
    updateUser,
    deleteUser,
    resendInvite,
    loadingUser,
    user,
    errors,
    userAccessEnum,
    fetchCollaboratorsRoles,
    roles
  } = props;

  const invalidIds = ["add", "overview", "collaborator-roles"];

  useEffect(() => {
    fetchCollaboratorsRoles(filmId);
  }, [filmId]);

  useEffect(() => {
    if (invalidIds.indexOf(collaboratorId) === -1) {
      fetchUser(filmId, collaboratorId);
    }
  }, [filmId, collaboratorId, fetchUser]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/collaborators/`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(collaboratorId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderUser(user) {
    return (
      <ScrollIntoView
        className="blade-content"
        path="/films/:filmId/collaborators/:collaboratorId/edit"
        loading={loadingUser}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          userId={user.id}
          initialValues={user}
          loading={loadingUser}
          onSubmit={onUpdateUser}
          handleDelete={onDeleteUser}
          onResendInvite={onResendInvite}
          userAccessEnum={userAccessEnum}
          canEdit={user.canEdit}
          roles={roles}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateUser(user) {
    sanitiseDataTypes(user);
    return updateUser(filmId, user);
  }

  function onResendInvite(user) {
    if (user.canEdit) {
      // need to set flag to call resend after somewhere in form probably like on email recipients page in shooting days
      onUpdateUser(user).then(() => resendInvite(filmId, user))
    } else {
      resendInvite(filmId, user);
    }
  }

  function onDeleteUser(user) {
    confirm(
      () => deleteUser(filmId, user),
      user?.collaboratorFirstName + " " + user?.collaboratorLastName
    );
  }

  function sanitiseDataTypes(collaborator) {
  }

  return user && !loadingUser ? renderUser(user) : renderLoading();
}

function mapStateToProps(state) {
  const { usersState, commonState } = state;
  return {
    user: usersState.user,
    roles: usersState.roles,
    loadingUser: usersState.loadingUser,
    errors: usersState.errors,
    redirect: usersState.redirect,
    userAccessEnum: commonState.userAccessEnum
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchUser: fetchCollaborator,
    updateUser: updateCollaborator,
    deleteUser: deleteCollaborator,
    clearRedirect,
    resendInvite,
    fetchCollaboratorsRoles
  })(UsersContainer)
);
