import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "./../../shared/loader";
import { GoogleAddressParser } from "../../shared/Map/google-address-parser";
import {
  addLocation,
  clearLocation,
  clearRedirect,
  deleteLocation,
  fetchCarParks,
  fetchHospitals,
  fetchLocation,
  fetchLocations,
  initAddLocationForm,
  initEditLocationForm,
  initializeAddEditForm,
  fetchDefaults,
  resetZoomMap,
  setCarParkAddress,
  setCarParks,
  setLatLng,
  setNoCarParksFound,
  setSelectedCarPark,
  setSelectedHospital,
  updateLocation,
  uploadImagesAndSaveLocation,
  getCountries,
  updateEvent,
  addDays,
  deleteDay,
  deleteDays,
  changeDayOfWeek,
  whatThreeWordsLookup
} from "./ducks/actions";
import Form from "./form";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

declare const google: any;

function AddLocationContainer(props) {
  const filmId = props.match.params.filmId;
  const {
    fetchLocations,
    initializeAddEditForm,
    fetchDefaults,
    uploadImagesAndSaveLocation,
    addLocation,
    location = {},
    errors,
    fetchHospitals,
    hospitals,
    selectedHospital,
    setSelectedHospital,
    carParks,
    setCarParks,
    noCarParks,
    setNoCarParksFound,
    setSelectedCarPark,
    selectedCarPark,
    resetZoomMap,
    zoomMap,
    setCarParkAddress,
    showCarParkList,
    showHospitalList,
    clearRedirect,
    onboardingSteps,
    getCountries,
    countries,
    defaultCountryId,
    availableDates,
    daysOfWeek,
    addDays,
    deleteDay,
    deleteDays,
    changeDayOfWeek,
    showAvailability,
    whatThreeWordsLookup
  } = props;

  const [searched, setSearched] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initializeAddEditForm();
    fetchDefaults(filmId);
  }, [filmId]);

  useEffect(() => {
    if (filmId) {
      getCountries(filmId);
    }
  }, [filmId]);

  if (props.redirect) {
    if (props.redirect.to === "list") {
      clearRedirect();
      return <Redirect to={`/films/${filmId}/locations/`} />;
    }
  }

  function onFetchHospitals({ address: { latitude, longitude, countryCode } }) {
    if (!props.fetchingHospitalList) {
      fetchHospitals(latitude, longitude, countryCode);
    }
  }

  function onSetSelectedCarPark(carParkId) {
    const carPark = carParks.find((c) => c.place_id === carParkId);
    onPlaceDetails(carParkId);
    setSelectedCarPark(carPark);
  }

  const onFindParking = ({ address: { latitude, longitude } }) => {
    var request = {
      location: { lat: latitude, lng: longitude },
      radius: "1600",
      rankby: "distance",
      type: ["parking"]
    };
    let service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.nearbySearch(request, nearbySearchCallback);
  };

  const nearbySearchCallback = (results, status) => {
    if (status === "ZERO_RESULTS") {
      setNoCarParksFound();
    } else if (status === google.maps.places.PlacesServiceStatus.OK) {
      const carParks = results.map((carPark) => {
        return {
          place_id: carPark.place_id,
          name: carPark.name,
          position: {
            latitude: carPark.geometry.location.lat(),
            longitude: carPark.geometry.location.lng()
          }
        };
      });
      setCarParks(carParks);
    }
  };

  const onPlaceDetails = (place_id: string) => {
    var request = {
      placeId: place_id,
      fields: ["name", "address_component", "geometry"]
    };
    let service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails(request, placeDetailsCallback);

    function placeDetailsCallback(results, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const address = new GoogleAddressParser(
          results.name,
          results.address_components,
          results.geometry.location.lat(),
          results.geometry.location.lng(),
          place_id
        ).result();
        setCarParkAddress(address);
        console.log("details results", address);
      }
    }
  };

  function onAddLocation(location) {

    sanitiseDataTypes(location);

    const addMode = !location.id;

    const files = location.pictures || [];

    const justAvailableDates = availableDates.filter(d => !d.isPotentialDate);

    if (files && files.length > 0) {
      uploadImagesAndSaveLocation(location, files, addMode, filmId, justAvailableDates);
    } else {
      if (!location.imageUrls) {
        location.imageUrls = [];
      }

      addLocation(filmId, location, justAvailableDates);
    }
  }

  function onUpdateLatLng(lat, lng) {
    props.setLatLng(lat, lng);
  }

  function sanitiseDataTypes(location) {
    location.interiorExterior = +location.interiorExterior;
  }

  if (props.loadingLocation) {
    return <Loader />;
  }

  if (initialized) {
    setInitialized(true);
    return null;
  }

  return (
    <ScrollIntoView
      className="blade-content wider"
      path="/films/:filmId/locations/add"
      loading={false}>
      <Form
        errors={errors}
        mode="add"
        locationId={null}
        initialValues={{ ...location }}
        loading={false}
        onSubmit={onAddLocation}
        onUpdateLatLng={onUpdateLatLng}
        onFetchHospitals={onFetchHospitals}
        hospitals={hospitals}
        onSetSelectedHospital={setSelectedHospital}
        selectedHospital={selectedHospital}
        showCarParkList={showCarParkList}
        showHospitalList={showHospitalList}
        onFindParking={onFindParking}
        carParks={carParks}
        noCarParks={noCarParks}
        onSetSelectedCarPark={onSetSelectedCarPark}
        selectedCarPark={selectedCarPark}
        zoomMap={zoomMap}
        resetZoom={resetZoomMap}
        searched={searched}
        setSearched={setSearched}
        countries={countries}
        defaultCountryId={defaultCountryId}
        availableDates={availableDates}
        daysOfWeek={daysOfWeek}
        addDays={addDays}
        deleteDay={deleteDay}
        deleteDays={deleteDays}
        changeDayOfWeek={changeDayOfWeek}
        showAvailability={showAvailability}
        whatThreeWordsLookup={whatThreeWordsLookup}
        {...props}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const locationsState = state.locationsState.rootState;

  return {
    interiorExteriorEnum: state.commonState.interiorExteriorEnum,
    location: locationsState.location,
    errors: locationsState.errors,
    hospitals: locationsState.hospitals,
    selectedHospital: locationsState.selectedHospital,
    carParks: locationsState.carParks,
    noCarParks: locationsState.noCarParks,
    selectedCarPark: locationsState.selectedCarPark,
    zoomMap: locationsState.zoomMap,
    showHospitalList: locationsState.showHospitalList,
    showCarParkList: locationsState.showCarParkList,
    fetchingHospitalList: locationsState.fetchingHospitalList,
    fetchingCarParkList: locationsState.fetchingCarParkList,
    redirect: locationsState.redirect,
    loadingLocation: locationsState.loadingLocation,
    onboardingSteps: locationsState.onboardingSteps,
    countries: locationsState.countries,
    defaultCountryId: locationsState.defaultCountryId,
    availableDates: locationsState.availableDates,
    daysOfWeek: locationsState.daysOfWeek,
    showAvailability: locationsState.showAvailability
  };
}

export default connect(mapStateToProps, {
  fetchLocation,
  uploadImagesAndSaveLocation,
  addLocation,
  updateLocation,
  fetchLocations,
  deleteLocation,
  fetchHospitals,
  setSelectedHospital,
  fetchCarParks,
  setCarParks,
  setNoCarParksFound,
  setSelectedCarPark,
  resetZoomMap,
  setCarParkAddress,
  clearLocation,
  setLatLng,
  initAddLocationForm,
  initEditLocationForm,
  clearRedirect,
  initializeAddEditForm,
  fetchDefaults,
  getCountries,
  updateEvent,
  addDays,
  deleteDay,
  deleteDays,
  changeDayOfWeek,
  whatThreeWordsLookup
})(AddLocationContainer);
