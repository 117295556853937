const defaultState = {
  actors: [],
  loading: true,
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTING_DAY_SHOT_LIST_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }

    case "FETCH_SHOOTING_DAY_SHOT_LIST_FULFILLED": {      
      return {
        ...state,
        shotList: action.payload.data,
        loading: false,
        errors: null,
      };
    }

    case "FETCH_SHOOTING_DAY_SHOT_LIST_REJECTED": {
      return {
        ...state,    
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    default:
      return state;
  }
};

export default reducer;
