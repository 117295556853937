import { useTranslation } from 'react-i18next';
import SmallLoader from "../../../shared/small-loader/small-loader";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useRef, useState } from 'react';
import moment from "moment";
import './potential-dates.scss';
import { OnboardingStep1ClassName, OnboardingStep2ClassName, OnboardingStep3ClassName, OnboardingStep4ClassName, availableColor, unavailableColor, userEventColor } from './ducks/reducer';

import Joyride from "react-joyride";
import OnboardingTooltip from "../../../shared/onboarding/tooltip";
import { Link } from 'react-router-dom';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here 
    // is better than directly setting `setValue(value + 1)`
}

const PotentialDates = (props) => {
    const { user,
        loading,
        languageCode,
        dates,
        toggleAvailability,
        deleteDays,
        updateAvailableDates,
        handleGetGoogleCalendar,
        saving,
        setPotentialDaysToUnavailableIfUserEvent,
        importedUserEvents,
        saved,
        onboardingSteps } = props;
    const { t, i18n } = useTranslation(['potential-dates']);

    useEffect(() => {
        if (languageCode) {
            i18n.changeLanguage(languageCode);
        }
    }, [languageCode]);

    useEffect(() => {
        if (!languageCode && !loading && user) {
            i18n.changeLanguage(user.filmLanguageCode);
        }
    }, [loading, user]);

    const forceUpdate = useForceUpdate();

    const [showOnboarding, setShowOnboarding] = useState(() => {
        const saved = localStorage.getItem("potential-dates-onbarding");
        const alreadyViewed = saved === "true";
        return !alreadyViewed;
    });
    const cal = useRef<any>();
    const [mode, setMode] = useState("delete");
    const addMode = "add";
    const deleteMode = "delete";

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    const eventMouseClick = (info) => {
        const { event, jsEvent } = info;
        toggleAvailability(event.id);
        forceUpdate();
    };

    const showHelp = () => {
        setShowOnboarding(true);
    }

    const dayRender = ({ date, el }) => {
        if (date > today) { //|| !daysOfWeek[date.getDay()].selected
            el.style.backgroundColor = '#162135'
        }
    };

    const dateSelect = (dateInfo) => {
        // if all day event, then end date is +1
        const endDate = dateInfo.end.setDate(dateInfo.end.getDate() - 1)
        if (mode === addMode) {
            // addDays(dateInfo.start, endDate);

        } else if (mode === deleteMode) {
            const momentStartDay = moment(dateInfo.start);
            const momentEndtDay = moment(endDate);
            deleteDays(dateInfo.start, endDate);
        }

        cal.current.getApi().unselect();
        forceUpdate();
    };

    const onGetGoogleCalendar = () => {
        handleGetGoogleCalendar();
    };



    function renderEventContent(eventInfo) {
        const allDay = eventInfo.event.allDay;

        const startDate = moment(eventInfo.event.start);
        const endDate = moment(eventInfo.event.end);
        const formattedStartDate = startDate.format('HH:mm');
        const formattedEndDate = endDate.format('HH:mm');

        const title = eventInfo.event.title;
        let timeAndTitle = formattedStartDate + " - " + formattedEndDate + " " + title;

        const day = dates.find(d => d.id == eventInfo.event.id);
        let userEvent = true;
        let available = false;
        if (day) {
            userEvent = day.userEvent;
            available = day.available;
        }

        const title1 = userEvent ? title : available ? "Available" : "Unavailable";

        return (
            <>
                {allDay && <div className={`fc-event-title fc-sticky`} title={title1}>{title1}</div>}
                {!allDay && <>
                    <div className="fc-daygrid-event-dot" title={timeAndTitle} ></div>
                    <div className="fc-event-time" title={timeAndTitle}>{formattedStartDate}</div>
                    <div className="fc-event-title" title={timeAndTitle}>{title}</div>
                </>}
            </>
        )
    }

    function getClassNames(eventInfo) {
        const day = dates.find(d => d.id == eventInfo.event.id);
        let userEvent = true;
        let available = false;
        if (day) {
            userEvent = day.userEvent;
            available = day.available;
        }

        return userEvent ? "event-user" : available ? "event-available" : "event-unavailable";
    }

    const handleJoyrideCallback = (data) => {
        const { action, lifecycle, index } = data;

        if (lifecycle === "complete" && index === onboardingSteps.length - 1) {
            setShowOnboarding(false);
            localStorage.setItem("potential-dates-onbarding", "true");
        }

        if (action === "close") {
            setShowOnboarding(false);
            localStorage.setItem("potential-dates-onbarding", "true");
        }
    };

    const selectAllow = (selectInfo) => {

        return dates.find((d) => d.start.toISOString() === selectInfo.start.toISOString());

    };


    return (
        <div className='user-potential-dates'>
            <h2 className="menu-label">{t("Potential Dates")}</h2>

            {saved && <div>
                {t("Thank you for submitting your availability.")}
            </div>}
            {!saved && <>
                <div className="mb-1">
                    {t("The calendar shows potential filming dates. Please update these with any that you're unavailable for, then click Save.")}
                </div>
                {loading && <SmallLoader />}

                {!loading && //!user.cancelled &&
                    <div className="">
                        <div className='buttons'>
                            <button className={`button ${OnboardingStep2ClassName}`} onClick={onGetGoogleCalendar} title="View your events directly on this calendar.">Import Google Calendar</button>
                            {importedUserEvents && <button className='button' onClick={setPotentialDaysToUnavailableIfUserEvent} title="Set any days with existing events as unavailable">Set unavailable days</button>}
                        </div>
                        <div className={OnboardingStep1ClassName}>
                            <FullCalendar
                                ref={cal}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                events={dates}
                                eventTimeFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    meridiem: false,
                                    hour12: false
                                }}
                                initialDate={dates[0].start}
                                editable={false}
                                selectable={true}
                                eventOverlap={true}
                                firstDay={1}
                                selectMirror={true}
                                select={dateSelect}
                                selectAllow={selectAllow}
                                contentHeight={"auto"}
                                eventClassNames={getClassNames}
                                eventContent={renderEventContent}
                                eventClick={eventMouseClick}
                                showNonCurrentDates={true}
                                dayCellDidMount={dayRender}
                                dayHeaderClassNames="calendar-header"
                                timeZone="UTC"
                            />
                        </div>

                        <div className='mt-1'>
                            Key:
                            <span className="tag tag-key tag-available">Available</span>
                            <span className="tag tag-key tag-unavailable">Unavailable</span>
                            {importedUserEvents && <span className="tag tag-key tag-event">Your event</span>}
                        </div>
                        <div className='buttons mt-2'>
                            <button className={`button ${saving ? 'is-loading' : ''} ${OnboardingStep3ClassName}`} onClick={updateAvailableDates}>Save</button>
                            <button className={`button ${saving ? 'is-loading' : ''} ${OnboardingStep4ClassName}`} onClick={showHelp}>Help</button>
                        </div>

                        <Joyride
                            steps={onboardingSteps}
                            continuous={true}
                            run={showOnboarding}
                            scrollOffset={150}
                            scrollToFirstStep={true}
                            tooltipComponent={OnboardingTooltip}
                            callback={handleJoyrideCallback}
                        />
                        {/* <Onboarding onboardingSteps={onboardingSteps} activeOnboarding="Potential Dates" section="Potential Dates" /> */}

                    </div>}
            </>}
            <Link to={"/privacy-policy"} target="_blank">
                Privacy Policy
            </Link>
        </div>

    )
};

export default PotentialDates;