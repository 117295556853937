import React from "react";
import PotentialDates from "./container";
import SendPotentialDates from "./send-potential-dates/container";
import Availability from "./availability/container";
import Menu from './menu';
import { PrivateRoute } from "../../shared/private-route";

const SceneSettingsRoutes = () => {
  return (
    <>
      <PrivateRoute path="/films/:filmId/scheduling" component={Menu} />
      <PrivateRoute path="/films/:filmId/scheduling/potential-dates" component={PotentialDates} />
      <PrivateRoute path="/films/:filmId/scheduling/send-potential-dates" component={SendPotentialDates} />
      <PrivateRoute path="/films/:filmId/scheduling/availability" component={Availability} />
    </>
  );
};

export default SceneSettingsRoutes;
