import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  fetchScheduleProgress
} from "./ducks/actions";
import { useEffect } from "react";
import SmallLoader from "../../shared/small-loader/small-loader";

function PropMenu(props) {
  const { filmId } = props.match.params;
  const { hasPotentialDays, hasSentPotentialDays, fetchScheduleProgress, loadingProgress } = props;

  useEffect(() => {
    fetchScheduleProgress(filmId);
  }, []);

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Scheduling</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/scheduling/potential-dates`}
            activeClassName="is-active">
            Potential Dates
          </NavLink>
        </li>
        {loadingProgress && !hasPotentialDays && <li>
          <div className="mt-1">
            <SmallLoader />
          </div>
        </li>}
        {hasPotentialDays && <li>
          <NavLink
            to={`/films/${filmId}/scheduling/send-potential-dates`}
            activeClassName="is-active">
            Send Potential Dates
          </NavLink>
        </li>}
        {hasPotentialDays && hasSentPotentialDays && <li>
          <NavLink
            to={`/films/${filmId}/scheduling/availability`}
            activeClassName="is-active">
            Availability
          </NavLink>
        </li>}
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const { schedulingState } = state;
  const rootState = schedulingState.rootState;
  return {
    loadingProgress: rootState.loadingProgress,
    hasPotentialDays: rootState.hasPotentialDays,
    hasSentPotentialDays: rootState.hasSentPotentialDays
  };
}

export default connect(mapStateToProps, {
  fetchScheduleProgress
})(PropMenu);
