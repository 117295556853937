
import { Link } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import CommercialScriptSceneShot from "./shot";
import FilmanizeSelect from "../../shared/filmanize-select";

const CommercialScriptScene = ({scene, 
                                characters,
                                provided, 
                                draggableProvided, 
                                getDayStyle, 
                                draggableSapshot,
                                readOnly,
                                getItemStyle,
                                filmId,
                                shotSizeEnum,
                                cameraMovementEnum,
                                addScriptField,
                                removeScriptField,
                                addShot,
                                removeShot,
                                sceneSettings,
                                interiorExteriorEnum,
                                dayNightEnum,
                                sceneSettingChange,
                                interiorExteriorChange,
                                dayNightChange,
                                setShotSize, 
                                setCameraMovements,
                                setDuration,
                                setDescription,
                                setFieldText,
                                setFieldCharacter }) => {

    const onRemoveShot = (shotId) => {
      removeShot(scene.id, shotId);
    }

    const onAddScriptField = (fieldType, shotId) => {
        addScriptField(fieldType, scene.id, shotId);
    };

    const onRemoveScriptField = (fieldId, shotId) => {
      removeScriptField(fieldId, scene.id, shotId);
    };

    const onSetShotSize = (shotId, shotSize) => {
        setShotSize(scene.id, shotId, shotSize);
    };

    const onSetCameraMovements = (shotId, cameraMovements) => {
        setCameraMovements(scene.id, shotId, cameraMovements);
    };

    const onSetDuration = (shotId, duration) => {
        setDuration(scene.id, shotId, duration);
    };

    const onSetDescription = (shotId, description) => {
        setDescription(scene.id, shotId, description);
    };

    const onSetFieldText = (shotId, fieldId, text) => {
        setFieldText(scene.id, shotId, fieldId, text);
    };

    const onSetFieldCharacter = (shotId, fieldId, characterId) => {
      setFieldCharacter(scene.id, shotId, fieldId, characterId);
    };
    

    const dayNightOptions = (dayNightEnum || []).map((item) => { return { value: item.value, label: item.name } });
    const interiorExteriorOptions = (interiorExteriorEnum || []).map((item) => { return { value: item.value, label: item.name } });
    const sceneSettingOptions = (sceneSettings || []).map((item) => { return { value: item.id, label: item.name } });

    const selectedSceneSettingOption = sceneSettingOptions.find(s => s.value === scene.sceneSettingId)    
    const selectedInteriorExteriorOption = interiorExteriorOptions.find(s => s.value === scene.interiorExterior)    
    const selectedDayNightOption = dayNightOptions.find(s => s.value === scene.dayNight)    

    const onSceneSettingChange = (sceneSettingId) => {
        sceneSettingChange(scene.id, sceneSettingId);
    }

    const onInteriorExteriorChange = (interiorExterior) => {
        interiorExteriorChange(scene.id, interiorExterior);
    }

    const onDayNightChange = (dayNight) => {
        dayNightChange(scene.id, dayNight);
    }

    return (
        <tbody        
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        <tr
          ref={draggableProvided.innerRef}
          
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          style={getDayStyle(
            draggableSapshot.isDragging,
            draggableProvided.draggableProps.style
          )}
        >
          <td colSpan={3} >
            <div className="commercial-script-scene-row">
                {!readOnly && <i className="material-icons">drag_indicator</i>}
                <Link to={`/films/${filmId}/scenes/${scene.id}/edit`}>{scene.number} &nbsp; &nbsp;</Link>                              
                Scene Setting:
                <div className="scene-setting-select">
                    <FilmanizeSelect options={sceneSettingOptions} value={selectedSceneSettingOption} onChange={onSceneSettingChange} />
                </div>        
                Int/Ext:
                <div className="scene-setting-select">
                    <FilmanizeSelect options={interiorExteriorOptions} value={selectedInteriorExteriorOption} onChange={onInteriorExteriorChange} />
                </div>                
                Day/Night:
                <div className="scene-setting-select">
                    <FilmanizeSelect options={dayNightOptions} value={selectedDayNightOption} onChange={onDayNightChange} />
                </div>                  
            </div>
          </td>
          <td className="commercial-script-scene-duration-cell">
            <div className="commercial-script-scene-duration">
              {scene.totalDuration}
            </div>
          </td>
        </tr>
        {(scene.shots || []).map((shot, index) => (
          <Draggable
            key={shot.id}
            draggableId={shot.id + shot.number + index + shot.locationId + shot.subLocationId}
            index={index}
            isDragDisabled={readOnly}
          >
            {(provided, snapshot) => (
              <CommercialScriptSceneShot 
                shot={shot} 
                provided={provided} 
                getItemStyle={getItemStyle}
                snapshot={snapshot}
                readOnly={readOnly} 
                filmId={filmId}
                characters={characters}
                shotSizeEnum={shotSizeEnum}
                cameraMovementEnum={cameraMovementEnum}
                addScriptField={onAddScriptField}
                removeScriptField={onRemoveScriptField}
                setShotSize={onSetShotSize}
                setCameraMovements={onSetCameraMovements}
                setDuration={onSetDuration}
                setDescription={onSetDescription}
                setFieldText={onSetFieldText}
                setFieldCharacter={onSetFieldCharacter}
                removeShot={onRemoveShot}
              />
            )}
          </Draggable>
        ))}
        {provided.placeholder}
        <tr style={{ backgroundColor: "#162135" }}>
          <td style={{borderBottomWidth: 0}} colSpan={11}>
            <button className="button" onClick={() => addShot(scene.id)}>Add Shot</button>
          </td>
        </tr>
      </tbody>
    );
}

export default CommercialScriptScene;
