import React from "react";
import NumberFormat from "react-number-format";
import CurrencyDisplay from "../../shared/currency-display";
import Equipment from "./equipment";

function EquipmentCategories(props) {
  const {
    budget,
    updateEquipmentRequired,
    updateEquipmentDailyRate,
    updateEquipmentDays,
    calcDays,
    sumDay,
    sumEquipmentCategories,
    showHideEquipment,
    equipmentVisible,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <div>
      <div className="columns subtitle is-4 mt-1">
        <div className="column">
          <h4
            className="subtitle is-4 pointer"
            onClick={() => showHideEquipment(!equipmentVisible)}>
            <button className="button is-text is-small">
              {!equipmentVisible && <i className="material-icons">add</i>}
              {equipmentVisible && <i className="material-icons">remove</i>}
            </button>
            Equipment
          </h4>
        </div>
        <div className="column budget-header-number">
          {!equipmentVisible && (
            <CurrencyDisplay
              value={sumEquipmentCategories(budget?.equipments)}
            />
          )}
        </div>
      </div>
      {equipmentVisible && (
        <table className="table budget-table">
          <thead>
            <tr>
              <th>Name</th>
              <th className="budget-days-column">Days</th>
              <th className="budget-daily-rate-column">Daily Rate</th>
              <th className="has-text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {(budget?.equipments || []).map((equipmentCategory) => {
              return (
                <>
                  <tr>
                    <th colSpan={3}>{equipmentCategory.name}</th>
                    <th className="has-text-right">
                      <CurrencyDisplay
                        value={equipmentCategory.total}
                      />
                    </th>
                  </tr>

                  {(equipmentCategory.equipment || []).map((equipment, i) => {
                    return (
                      <Equipment
                        key={i}
                        equipment={equipment}
                        equipmentCategoryId={equipmentCategory.id}
                        onChangeRequired={updateEquipmentRequired}
                        onChangeDailyRate={updateEquipmentDailyRate}
                        onChangeDays={updateEquipmentDays}
                        days={budget.days}
                        calcDays={calcDays}
                        sumDay={sumDay}
                        hasShootingDays={budget.hasShootingDays}
                        readOnly={readOnly}
                        filmId={filmId}
                        saving={saving} />
                    );
                  })}
                </>
              );
            })}
            <tr>
              <td colSpan={3}></td>
              <td className="has-text-right">
                <CurrencyDisplay
                  value={budget?.equipmentTotal}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default EquipmentCategories;
