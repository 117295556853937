import List from "./list/container";
import View from "./view/container";
import Edit from "./edit/container";
import Add from "./add/container";

import { PrivateRoute } from "../../shared/private-route";

function FeatureRequestRoutes(props) {
  return (
    <>
      <PrivateRoute exact path="/feature-request" component={List} />
      <PrivateRoute exact path="/feature-request/add" component={Add} />
      <PrivateRoute exact path="/feature-request/:featureRequestId" component={View} />
      <PrivateRoute exact path="/feature-request/:featureRequestId/edit" component={Edit} />      
    </>
  );
}

export default FeatureRequestRoutes;
