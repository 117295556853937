import React, { Suspense } from "react";
import { Route, useHistory, withRouter } from "react-router-dom";
import FilmsList from "./../films/index";
import ActorRoutes from "./actors/routes";
import BackgroundActorRoutes from "./background-actors/routes";
import FilmBreakdownContainer from "./breakdown/overview-container";
import CharactersRoutes from "./characters/routes";
import BackgrounCharacterRoutes from "./background-characters/routes";
import DepartmentRoutes from "./crew/departments/routes";
import FilmsEditContainer from "./film/edit-container";
import LocationRoutes from "./locations/routes";
import FilmMenu from "./menu";
import FilmOverviewContainer from "./overview/container";
import SceneSettingsRoutes from "./scene-settings/routes";
import SceneRoutes from "./scenes/routes";
import ShootingDaysRoutes from "./shooting-days/routes";
import PropRoutes from "./accessories/props/routes";
import CostumeRoutes from "./accessories/costumes/routes";
import MakeupRoutes from "./accessories/makeup/routes";
import BudgetContainer from "./budget/container";
import SetDressingRoutes from "./accessories/setDressings/routes";
import CollaboratorRoutes from "./collaborators/routes";
import InitationRoutes from "./invitation/routes";
import ScriptRoutes from "./script/routes";
import CommercialScriptRoutes from "./commercial-script/routes";
import AvailabilityRoutes from "./availability/routes";
import EquipmentRoutes from "./equipment/routes";
import { PrivateRoute } from "../shared/private-route";
import TestComponent from "./test";
import BreakdownRoutes from "./breakdown/routes";
import Loader from "../shared/loader";
import TaskRoutes from "./tasks/routes";
import SchedulingRoutes from "./scheduling/routes";
import VisualEffectsRoutes from "./visual-effects/routes";

const FilmRoutes = (props) => {
  let history = useHistory();

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Route exact path="/test" component={TestComponent} />

        <PrivateRoute path="/films/:filmId" component={FilmMenu} />
        <PrivateRoute
          exact
          path="/films/:id/edit"
          component={FilmsEditContainer}
        />
        <BreakdownRoutes />

        <PrivateRoute
          exact
          path="/films/:id/overview"
          component={FilmOverviewContainer}
        />

        <PrivateRoute
          exact
          path="/films/:id/budget"
          component={BudgetContainer}
        />

        <TaskRoutes />
        <ActorRoutes />
        <BackgroundActorRoutes />
        <CharactersRoutes />
        <BackgrounCharacterRoutes />
        <SceneRoutes />
        <SceneSettingsRoutes />
        <PropRoutes />
        <CostumeRoutes />
        <MakeupRoutes />
        <SetDressingRoutes />
        <LocationRoutes />
        <DepartmentRoutes />
        <ShootingDaysRoutes />
        <CollaboratorRoutes />
        <InitationRoutes />
        <ScriptRoutes />
        <CommercialScriptRoutes />
        <AvailabilityRoutes />
        <EquipmentRoutes />
        <SchedulingRoutes />
        <VisualEffectsRoutes />
      </Suspense>
    </>
  );
};

export default withRouter(FilmRoutes);
