import getApiClient from "./../../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications,
  showWarning
} from "../../../../../notifications/ducks/actions";

const url = "/film";

export const FETCH_COSTUME_OVERVIEW = "FETCH_COSTUME_OVERVIEW";

export function fetchCostumeOverview(id) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COSTUME_OVERVIEW,
      payload: getApiClient().get(`${url}/${id}/Costume/Overview`)
    });
  };
}