import React from "react";
import { connect } from "react-redux";
import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";
import Errors from "../../../shared/errors";
import {
  OnboardingStep1ClassName
} from "../ducks/reducer";
import Onboarding from '../../../shared/onboarding/onboarding';
import Collaborator from "./collaborator";

function Overview(props) {
  const {
    overview,
    filmId,
    collaboratorsAssignedPercentage,
    maximumCollaboratorsPercentage,
    errors,
    warnings
  } = props;

  const readOnly = overview.readOnly;

  function Charts() {
    return (
      <div className="columns">
        {maximumCollaboratorsPercentage &&
          <div
            className={`circular-progress column is-one-quarter`}>
            <FilmalityCircularProgress
              percentage={maximumCollaboratorsPercentage}
              title="Used"
            />
          </div>}
        <div className="circular-progress column is-one-quarter">
          <FilmalityCircularProgress
            percentage={collaboratorsAssignedPercentage}
            title="Assigned"
          />
        </div>
      </div>
    );
  }


  function CollaboratorsList() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Emailing Status</th>
            <th>Roles</th>
          </tr>
        </thead>
        <tbody>
          {(overview.collaborators || []).map((collaborator, index) => {
            return (
              <Collaborator
                key={index}
                collaborator={collaborator}
                index={index}
                filmId={filmId}
                readOnly={readOnly}></Collaborator>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div>
      {overview?.collaborators?.length > 0 && <Charts />}
      {errors && <Errors errors={errors} />}
      {warnings && <Errors errors={warnings} isWarning={true} />}
      {!overview?.collaborators?.length && <div>No collaborators yet</div>}
      {overview?.collaborators?.length > 0 && <CollaboratorsList />}
    </div>
  );
}

export default Overview;
