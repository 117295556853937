import { useState } from 'react';
import Comment from './comment';
import AddComment from "../comment/add/container";

const Comments = (props) => {
    const { comments, featureRequestId, updateFeatureRequestComment, deleteFeatureRequestComment, setEditMode } = props;
    const anyEditMode = comments?.some((comment: any) => comment.editMode);
    return (
        <div>
            {(comments || []).map((comment: any) => (
                <Comment key={comment.id} 
                         comment={comment} 
                         featureRequestId={featureRequestId} 
                         updateFeatureRequestComment={updateFeatureRequestComment} 
                         deleteFeatureRequestComment={deleteFeatureRequestComment}
                         setEditMode={setEditMode} 
                         hideEdit={anyEditMode} />
            ))}
            <hr className="feature-request-comment-hr" />
           
            <AddComment featureRequestId={featureRequestId} />
        </div>
    )
}

export default Comments;