import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../../../../shared/modal/confirm";
import ScrollIntoView from "../../../../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../../../../shared/loader";
import {
  clearRedirect,
  deleteMember,
  fetchMember,
  updateMember,
  uploadImagesAndSaveCrewMember
} from "./ducks/actions";
import Form from "./form";

function MembersEditContainer(props) {
  const { filmId, departmentId, roleId, memberId } = props.match.params;
  const {
    fetchMember,
    fetchMemberSettings,
    updateMember,
    uploadImagesAndSaveCrewMember,
    deleteMember,
    loadingMember,
    member,
    errors,
    genderEnum,
    languageHasGender,
    drinks,
    milkTypes
  } = props;

  useEffect(() => {
    if (memberId !== "add" && memberId !== "add-existing") {
      fetchMember(filmId, departmentId, roleId, memberId);
    }
  }, [filmId, memberId]);

  if (memberId === "add" || memberId === "add-existing") {
    return null;
  }

  if (props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/departments/${departmentId}/roles/${roleId}/members`;
      return <Redirect to={url} />;
    }
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderMember(member) {
    let dietaryRequirements: any[] = [];
    (props.dietaryRequirementEnum || []).forEach((item) => {
      if ((item.value & member.dietaryRequirements) === item.value) {
        dietaryRequirements.push(item.value);
      }
    });

    member.dietaryRequirementsArray = dietaryRequirements;

    member.roleIds = (member.roles || []).map((r) => r.id);

    return (
      <ScrollIntoView
        className="blade-content"
        path="/films/:filmId/departments/:departmentId/roles/:roleId/members/:memeberId/edit"
        loading={loadingMember}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          memberId={member.id}
          initialValues={member}
          loading={loadingMember}
          onSubmit={onUpdateMember}
          handleDelete={onDeleteMember}
          genderEnum={genderEnum}
          languageHasGender={languageHasGender}
          drinks={drinks}
          milkTypes={milkTypes}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateMember(member) {
    sanitiseDataTypes(member);

    const files = member.pictures || [];

    if ((files && files.length) > 0 || (member.images && member.images.length > 0)) {
      uploadImagesAndSaveCrewMember(
        member,
        files,
        false,
        filmId,
        departmentId,
        roleId
      );
    } else {
      updateMember(filmId, departmentId, roleId, member);
    }
  }

  function onDeleteMember(member) {
    confirm(() => deleteMember(filmId, departmentId, roleId, member), member.firstName + " " + member.lastName);
  }

  function sanitiseDataTypes(member) {
    member.gender = +member.gender;
    member.heightCentimeters = +member.heightCentimeters;
    member.weightKilograms = +member.weightKilograms;

    let dietaryRequirement = 0;
    member.dietaryRequirementsArray.forEach((item) => {
      dietaryRequirement = dietaryRequirement | item;
    });

    member.dietaryRequirements = dietaryRequirement;
  }

  return member && !loadingMember ? renderMember(member) : renderLoading();
}

function mapStateToProps(state) {
  const { crewMembersState, commonState, filmsState } = state;
  return {
    member: crewMembersState.member,
    loadingMember: crewMembersState.loadingMember,
    errors: crewMembersState.errors,
    redirect: crewMembersState.redirect,
    genderEnum: commonState.genderEnum,
    ethnicityEnum: commonState.ethnicityEnum,
    dietaryRequirementEnum: commonState.dietaryRequirementEnum,
    skinColors: commonState.skinColors,
    ethnicityTypes: commonState.ethnicityTypes,
    languageHasGender: filmsState.userFilm?.languageHasGender,
    drinks: state.commonState.drinks,
    milkTypes: state.commonState.milkTypes
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchMember,
    updateMember,
    deleteMember,
    clearRedirect,
    uploadImagesAndSaveCrewMember
  })(MembersEditContainer)
);
