import React from "react";
import NumberFormat from "react-number-format";
import InputSlider from "../../shared/controls/input-slider";
import Characters from "./characters";
import SceneSettings from "./scene-settings";
import Crew from "./crew";
import EquipmentCategories from "./equipmentCategories";
import "./budget.scss";
import Props from "./props";
import HelpText from "../../shared/help-text";
import Costumes from "./costumes";
import Download from "../../shared/download";
import Makeups from "./makeups";
import SetDressings from "./set-dressings";
import VisualEffects from "./visual-effects";
import BackgroundCharacters from "./background-characters";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName
} from './ducks/reducer';
import Catering from "./catering";
import CurrencyDisplay from "../../shared/currency-display";

function Budget(props) {
  const {
    filmId,
    budget,
    updateRoleDailyRate,
    updateCrewRequired,
    updateCrewDays,
    updateEquipmentDailyRate,
    updateEquipmentRequired,
    updateEquipmentDays,
    updateCharacterDailyRate,
    updatePropBuget,
    updatePropDays,
    updateSetDressingBuget,
    updateSetDressingDays,
    updateCostumeBuget,
    updateCostumeDays,
    updateBackgroundCharacterBuget,
    updateBackgroundCharacterDays,
    updateMakeupBuget,
    updateMakeupDays,
    onChangeCateringBuget,
    onChangeCateringDays,
    updateDays,
    updateContingencyPercentage,
    updateBudget,
    updateSceneSettingDailyRate,
    updateSceneSettingDays,
    showHideCharacters,
    showHideSceneSettings,
    showHideCrew,
    showHideEquipment,
    showHideProps,
    showHideSetDressings,
    showHideCostumes,
    showHideMakeups,
    showHideBackgroundCharacters,
    showHideCatering,
    charactersVisible,
    sceneSettingsVisible,
    crewVisible,
    equipmentVisible,
    propsVisible,
    setDressingsVisible,
    costumesVisible,
    makeupsVisible,
    backgroundCharactersVisible,
    cateringVisible,
    visualEffectsVisible,
    clearPdf,
    progress,
    download,
    downloadBudget,
    saved,
    saving,
    showHideVisualEffects,
    updateVisualEffectBuget
  } = props;

  const readOnly = budget?.readOnly;

  const onChangeDays = (val) => {
    updateDays(val);
  };

  const onChangeContingencyPercentage = (val) => {
    updateContingencyPercentage(val);
  };

  const sumCrew = function (items) {
    return items.reduce(function (a, b) {
      return a + sumDay(b);
    }, 0);
  };

  const sumDay = function (crew) {
    if (!crew.required) {
      return 0;
    }

    return crew.dailyRate * calcDays(crew);
  };

  const calcDays = function (crew) {
    return !crew.days || crew.days > budget.days ? budget.days : crew.days;
  };

  const sumDepartments = function (items) {
    if (!items) {
      return 0;
    }
    return items.reduce(function (a, b) {
      return a + sumCrew(b.crew);
    }, 0);
  };

  const sumEquipmentDay = function (crew) {
    if (!crew.required) {
      return 0;
    }

    return crew.dailyRate * calcDays(crew);
  };

  const sumEquipment = function (items) {
    return items.reduce(function (a, b) {
      return a + sumEquipmentDay(b);
    }, 0);
  };

  const sumEquipmentCategories = function (items) {
    if (!items) {
      return 0;
    }
    return items.reduce(function (a, b) {
      return a + sumEquipment(b.equipment);
    }, 0);
  };

  const onChangeDailyRate = (departmentId, id, rate) => {
    updateRoleDailyRate(departmentId, id, rate);
  };

  const onChangeCrewDays = (departmentId, roleId, days, prepDays) => {
    updateCrewDays(departmentId, roleId, days, prepDays);
  };

  const onChangeEquipmentDays = (categoryId, equipmentId, days) => {
    updateCrewDays(categoryId, equipmentId, days);
  };

  const onChangeRequired = (departmentId, id, required) => {
    updateCrewRequired(departmentId, id, required);
  };

  const onDownloadFile = () => {
    downloadBudget(filmId);
  };

  return (
    <div>
      <Characters
        budget={budget}
        onChangeCharacterDailyRate={updateCharacterDailyRate}
        showHideCharacters={showHideCharacters}
        charactersVisible={charactersVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}></Characters>

      <BackgroundCharacters
        budget={budget}
        items={budget.backgroundCharacters}
        onChangeBuget={updateBackgroundCharacterBuget}
        onChangeDays={updateBackgroundCharacterDays}
        showHide={showHideBackgroundCharacters}
        visible={backgroundCharactersVisible}
        total={budget.backgroundCharactersTotal}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}
      />

      <SceneSettings
        budget={budget}
        onChangeSceneSettingDailyRate={updateSceneSettingDailyRate}
        showHideSceneSettings={showHideSceneSettings}
        sceneSettingsVisible={sceneSettingsVisible}
        onChangeSceneSettingDays={updateSceneSettingDays}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving} />

      <Crew
        budget={budget}
        sumCrew={sumCrew}
        onChangeRequired={onChangeRequired}
        onChangeDailyRate={onChangeDailyRate}
        onChangeCrewDays={onChangeCrewDays}
        calcDays={calcDays}
        sumDay={sumDay}
        sumDepartments={sumDepartments}
        showHideCrew={showHideCrew}
        crewVisible={crewVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}
        crewHavePrepDays={budget.crewHavePrepDays} />

      <Props
        budget={budget}
        onChangePropBuget={updatePropBuget}
        onChangePropDays={updatePropDays}
        showHideProps={showHideProps}
        propsVisible={propsVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}
      />

      <SetDressings
        budget={budget}
        onChangeSetDressingBuget={updateSetDressingBuget}
        onChangeSetDressingDays={updateSetDressingDays}
        showHideSetDressings={showHideSetDressings}
        setDressingsVisible={setDressingsVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}
      />

      <Costumes
        budget={budget}
        onChangeCostumeBuget={updateCostumeBuget}
        onChangeCostumeDays={updateCostumeDays}
        showHideCostumes={showHideCostumes}
        costumesVisible={costumesVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}
      />

      <Makeups
        budget={budget}
        onChangeMakeupBuget={updateMakeupBuget}
        onChangeMakeupDays={updateMakeupDays}
        showHideMakeups={showHideMakeups}
        makeupsVisible={makeupsVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}
      />

      <EquipmentCategories
        budget={budget}
        sumCrew={sumCrew}
        updateEquipmentDailyRate={updateEquipmentDailyRate}
        updateEquipmentRequired={updateEquipmentRequired}
        updateEquipmentDays={updateEquipmentDays}
        calcDays={calcDays}
        sumDay={sumEquipmentDay}
        sumEquipmentCategories={sumEquipmentCategories}
        showHideEquipment={showHideEquipment}
        equipmentVisible={equipmentVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving} />

      <VisualEffects
        budget={budget}
        onChangeVisualEffectBuget={updateVisualEffectBuget}
        showHideVisualEffects={showHideVisualEffects}
        visualEffectsVisible={visualEffectsVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}
      />

      <Catering
        budget={budget}
        onChangeCateringBuget={onChangeCateringBuget}
        onChangeCateringDays={onChangeCateringDays}
        showHide={showHideCatering}
        visible={cateringVisible}
        readOnly={readOnly}
        filmId={filmId}
        saving={saving}
      />

      <div className="budget-footer mt-2">
        <div className="ml-1 mr-1">
          <div className={`budget-total`}>
            Total:{" "}
            <CurrencyDisplay
              className={`${OnboardingStep1ClassName}`}
              value={budget?.totalBudget}
            />
          </div>
          <div className="columns is-variable is-6">
            <div className={`column ${OnboardingStep2ClassName}`}>
              <div className="budget-slider-label">Days</div>
              <InputSlider
                step={1}
                formatLabel={(value) => `${value} day${value > 1 ? "s" : ""}`}
                draggableTrack={false}
                allowSameValues={false}
                maxValue={budget.maxDays}
                minValue={1}
                value={budget.days}
                onChange={onChangeDays}
                disabled={readOnly || saving}
              />
            </div>
            <div className={`column ${OnboardingStep3ClassName}`}>
              <div className="budget-slider-label">Contingency</div>
              <InputSlider
                step={1}
                unit="%"
                draggableTrack={false}
                allowSameValues={false}
                maxValue={100}
                minValue={0}
                value={budget.contingencyPercentage}
                onChange={onChangeContingencyPercentage}
                disabled={readOnly || saving}
              />
            </div>
          </div>
          <div className="buttons mt-1">
            {!readOnly && (
              <button
                type="button"
                className={`button ${saving ? "is-loading" : ""
                  }`}
                onClick={() => updateBudget(budget)}
                disabled={saved}>
                Save
              </button>
            )}
            <Download
              progress={progress}
              clearPdf={clearPdf}
              download={download}
              downloadFile={onDownloadFile}></Download>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Budget;
