import React, { useRef, useState } from "react";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import ViewFeatures from "./view-features";

const CallToAction = ({ titleRef, windowWidth }: any) => {

  const [videoPlaying, setVideoPlaying] = useState(false);

  const videoRef = useRef<any>(null);

  const handlePlayVideo = () => {
    setVideoPlaying(true);
    videoRef.current.play();
  }

  const windowSplit = 936;

  return (

    <section id="home">
      <div ref={titleRef} className="call-to-action">
        <div className="call-to-action-columns">
          <div className="marketing-title-column">
            <div className="marketing-title-container">
              <h1 className="">
                From <mark className="script-mark">script</mark> to <br />
                <mark className="call-sheet-mark">call sheet</mark> easily<br />
                and efficiently
              </h1>
              {windowWidth < windowSplit && <ViewFeatures />}
            </div>
          </div>
          <div className="marketing-image-column">
            {/* <iframe  style={{marginBottom: "2rem"}} width="490" height="276" src="https://www.youtube.com/embed/XroTI6A-QvU" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope;" allowFullScreen></iframe>                   */}
            <div className="video-container">
              <div className="video">
                <Video
                  controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                  poster="/images/Vid.webp"
                >
                  <source src="https://www.filmanize.com/images/marketing/Filmanize%20Overview.mp4" type="video/mp4" />
                </Video>
                {/* <img className="video-poster" src="/images/vid.png" onClick={handlePlayVideo} style={{display: videoPlaying ? "none" : "block"}}/>
                  
                    <video ref={videoRef} style={{display: !videoPlaying ? "none" : "block"}} controls poster="https://www.filmanize.com/images/marketing/Filmanize%20Thumbnail.png" controlsList="nodownload" preload="true">
                      <source src="https://www.filmanize.com/images/marketing/Filmanize%20Overview.mp4" type="video/mp4" />
                    </video>  */}

              </div>
            </div>
          </div>
        </div>
        {windowWidth >= windowSplit && <ViewFeatures />}
      </div>

    </section>

  );
};

export default CallToAction;
