import React from "react";
import { NavLink, Route, Link, withRouter } from "react-router-dom";

function RoleMenu(props) {
  const { filmId, departmentId, roleId } = props.match.params;

  if (roleId == "add") {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Role</p>
      <ul className="menu-list">
        <li>
          <NavLink to={`/films/${filmId}/departments/${departmentId}/roles/${roleId}/edit`} activeClassName="is-active">
            Edit Role
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/films/${filmId}/departments/${departmentId}/roles/${roleId}/members`}
            activeClassName="is-active">
            Members
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default RoleMenu;
