import Dropzone from "react-dropzone";
import { Redirect } from "react-router-dom";
import Progress from "../shared/progress";
import { OnboardingStep3ClassName } from "./ducks/reducer";
import WarningText from "../shared/warning-text";
import Onboarding from "../shared/onboarding/onboarding";

export const acceptedFileTypes = [".fdx", ".cxscript", ".celtx", ".fountain", ".highland"];

const UploadScript = (props: any) => {
  const {
    isUploading,
    setUploadError,
    progress,
    saving,
    setSaving,
    onUpload,
    disabled,
    onboardingSteps
  } = props;



  return (
    <>
      <div className="subtitle supported-formats mt-1">
        The following file formats are supported:
        <div className="tags script-formats">
          {acceptedFileTypes.map((fileType: any, index: number) => <span key={index} className="tag">{fileType}</span>)}
        </div>
      </div>

      <Dropzone
        accept={acceptedFileTypes.join()}
        multiple={false}
        disabled={isUploading || disabled}
        onDrop={(acceptedFiles, rejectedFiles) => {
          if (acceptedFiles && acceptedFiles.length > 0) {
            setSaving(true);
            const file = acceptedFiles[0];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("filename", file.name);
            onUpload(formData);
          }

          if (rejectedFiles && rejectedFiles.length > 0) {
            const fileName = rejectedFiles[0].file.name;
            const lastDot = fileName.lastIndexOf(".");
            const extention = fileName.substring(lastDot);
            setUploadError(
              "File type " + extention + " is not supported"
            );
          }
        }}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section
            className={`image-drop-area ${OnboardingStep3ClassName} ${isDragActive ? "active" : ""}`}>
            <div
              {...getRootProps({ className: `image-drop-area-inner ${isDragActive ? "active" : ""}` })}>
              <input {...getInputProps()} />
              <button className="button" disabled={isUploading || disabled}>Choose a script…</button>
            </div>
          </section>
        )}
      </Dropzone>

      {/* {uploadStatus === "uploading" && <Progress progress={progress} />} */}
      <Progress progress={progress} className="script-progress" />
    </>
  );
};

export default UploadScript;