import React from "react";
import { NavLink } from "react-router-dom";

const PropsList = ({ visualEffects, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Visual Effects</p>
      <ul className="menu-list">
        {/* <li>
          <NavLink
            to={`/films/${filmId}/visual-effects/overview`}
            activeClassName="is-active">
            Overview
          </NavLink>
        </li>
        <hr /> */}
        {visualEffects?.map((visualEffect, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/visual-effects/${visualEffect.id}/edit`}
                title={visualEffect.name}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/visual-effects/${visualEffect.id}`
                  );
                }}>
                {visualEffect.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default PropsList;
