import Errors from "../../../shared/errors";
import {
  renderTextField,
  renderTextAreaField,
  renderCheckbox,
  renderSingleReactSelect,
  renderCkEditorField,
  renderSelectField
} from "../../../shared/form-helpers";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FormErrors from "../../../shared/form-errors";
import required from "../../../shared/validation";
import { Link } from "react-router-dom";

let EditFeatureRequest = (props) => {
  const {
    featureRequestId,
    handleSubmit,
    pristine,
    submitFailed,
    invalid,
    activeSection,
    change,
    pageSections,
    featureRequestSaving,
    allowShowValue,
    errors,
    isAdmin,
    statuses,
    priorities,
    readOnly
  } = props;

  const statusOptions = (statuses || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  const pritoryOptions = (priorities || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  return (
    <div>
      <h2 className="menu-label">Feature Request</h2>
      <Errors errors={errors} />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="section"
          component={renderSingleReactSelect}
          options={pageSections.map((pageSection) => {
            return { value: pageSection.name, label: pageSection.name };
          })}
          label="Section"
          validate={[required]}
          readOnly={readOnly}
          required></Field>

        <Field
          name="title"
          component={renderTextField}
          label="Title"
          validate={[required]}
          readOnly={readOnly}
          required></Field>

        <Field
          name="description"
          component={renderCkEditorField}
          label="Description"
          validate={[required]}
          readOnly={readOnly}
          required></Field>

        <Field
          name="priority"
          component={renderSingleReactSelect}
          options={pritoryOptions}
          label="Priority"
          readOnly={readOnly}
          required />

        {isAdmin &&
          <Field
            name="status"
            component={renderSingleReactSelect}
            options={statusOptions}
            label="Status"
            required />
        }

        <Field
          name="allowShow"
          component={renderCheckbox}
          readOnly={readOnly}
          label="Are you happy for this to be voted on?" />

        {allowShowValue && <div className="mt-1">
          <Field
            name="anonymous"
            component={renderCheckbox}
            readOnly={readOnly}
            label="Post anonymously?" />
        </div>}

        <div className="buttons mt-2">
          {featureRequestId && <Link className="button" to={`/feature-request/${featureRequestId}`}>Cancel</Link>}
          {!featureRequestId && <Link className="button" to={`/feature-request`}>Cancel</Link>}
          <button
            type="submit"
            className={`button is-primary ${featureRequestSaving ? "is-loading" : ""}`}
            disabled={pristine || featureRequestSaving}>
            Submit
          </button>
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </div>
  );
}

EditFeatureRequest = reduxForm({
  form: "editFeatureRequest",
  enableReinitialize: true
})(EditFeatureRequest);

const selector = formValueSelector('editFeatureRequest'); // <-- same as form name
const LeaveFeedbackFormConnect = connect(
  state => {
    // can select values individually
    const allowShowValue = selector(state, 'allowShow');
    return {
      allowShowValue
    }
  }
)(EditFeatureRequest);

export default LeaveFeedbackFormConnect