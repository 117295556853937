import { NavLink } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./constants";
function VisualEffect(props) {
  const {
    visualEffect,
    filmId,
    aliasProp,
    unaliasProp,
    readOnly,
  } = props;

  const hasAliases = (visualEffect.aliases || []).length > 0;

  const propDropped = (alias) => {
    aliasProp(filmId, alias, visualEffect);
  };

  const canDropProp = (item, monitor) => {
    return !readOnly && item.prop.id != visualEffect.id;
  };

  const canDragProp = (monitor) => {
    return !readOnly && !hasAliases;
  };

  const [{ isOverProp }, dropProp] = useDrop({
    accept: ItemTypes.PROP,
    drop: (item: any, monitor) => propDropped(item.prop),
    canDrop: (item, monitor) => canDropProp(item, monitor),
    collect: (monitor) => ({
      isOverProp: !!monitor.isOver()
    })
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.PROP, visualEffect },
    canDrag: (monitor) => canDragProp(monitor),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const getItemStyle = (isDragging) => ({
    background: isDragging ? "#947eed" : "#040f21"
  });

  return (
    <>
      <tr ref={drag}
        style={{
          cursor: !hasAliases && !readOnly ? "grab" : ""
        }}>
        <td ref={dropProp} style={getItemStyle(isOverProp)}>
          {!readOnly && !hasAliases && (
            <i className="material-icons dark">drag_indicator</i>
          )}
          <NavLink to={`/films/${filmId}/visual-effects/${visualEffect.id}/edit`}>
            {visualEffect.name}
          </NavLink>
        </td>
        <td>
          {visualEffect.quantity}
        </td>
        <td>
          {visualEffect.quantityAcquired}
        </td>
        <td>
          {visualEffect.acquisitionMethodDescription}
        </td>
      </tr>
      {(visualEffect.aliases || []).map((alias) => (
        <tr key={alias.id}>
          <td colSpan={4}>
            <div style={{ marginLeft: 30 }}>
              {alias.name}
              &nbsp;
              {!readOnly && (
                <a onClick={() => unaliasProp(filmId, alias, visualEffect)}>
                  <i className="material-icons">cancel</i>
                </a>
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default VisualEffect;
