import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import { fetchShootingDayWeather } from "./ducks/actions";
import WeatherForm from "./edit-form";

function ListShootingDayWeatherContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const { fetchShootingDayWeather, loading, weather, errors } = props;

  useEffect(() => {
    fetchShootingDayWeather(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderShootingDays(weather, filmId) {
    return (
      <div className="blade-content wider">
        <p className="menu-label">Weather</p>
        <WeatherForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          weather={ weather }
          weatherColumns={5}
          loading={loading}
          {...props}
        />
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(weather, filmId);
}

function mapStateToProps(state) {
  return {
    shootingDay: state.shootingdaysState.rootState.shootingday,
    weather: state.shootingdaysState.weatherState.weather,
    loading: state.shootingdaysState.weatherState.loading,
    errors: state.shootingdaysState.weatherState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayWeather
})(ListShootingDayWeatherContainer);
