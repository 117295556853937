import React from "react";
import InputSlider from "../../shared/controls/input-slider";
import NumberFormat from "react-number-format";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function Prop(props) {
  const {
    prop,
    onChangePropBuget,
    onChangePropDays,
    maxDays,
    hasShootingDays,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <tr>
      <td><Link to={`/films/${filmId}/props/${prop.id}/edit`}>{prop.name}</Link></td>
      <td className="budget-days-column">
        {prop.hire ? (
          hasShootingDays ? (
            prop.days
          ) : (
            <InputSlider
              step={1}
              formatLabel={(value) => `${value}`}
              draggableTrack={false}
              allowSameValues={false}
              maxValue={maxDays}
              minValue={1}
              value={prop.days}
              onChange={(val) => onChangePropDays(prop.id, val)}
              disabled={readOnly || saving}
            />
          )
        ) : (
          "-"
        )}
      </td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={prop.hire ? prop.dailyBudget : prop.budget}
          onChange={(val) => onChangePropBuget(prop.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-centered">{prop.quantity}</td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={prop.total}
        />
      </td>
    </tr>
  );
}

export default Prop;
