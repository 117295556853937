import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import './lozenger.scss';

const Lozenger = ({ imageUrl, icon, title, description, backgroundColor, alignRight, windowWidth }: any) => {

    const options = { threshold: 0.1, triggerOnce: true };
    const [ref, inView] = useInView(options);

    let backgroundColorHex = "#0ff1ce"
    switch (backgroundColor) {
        case "green":
            backgroundColorHex = "#00ffce";
            break;
        case "grey":
            backgroundColorHex = "#f4f4f4";
            break;
        case "purple":
            backgroundColorHex = "#947eed";
            break;
    }

    const textFirst = alignRight || windowWidth < 768;

    const image = <div className="column lozenger-image-column">
        <img className="lozenger-image" alt="Filmanize on laptop" src={imageUrl} loading="lazy" />
    </div>

    const text = <div className="column lozenger-text">
        <div className="lozenger-icon-container" style={{ backgroundColor: backgroundColor === "grey" ? "#00ffce" : "white" }}>
            {icon}
        </div>
        <div className="lozenger-title">
            {title}
        </div>
        <div>
            {description}
        </div>
    </div>

    return (
        <div ref={ref} className={`lozenger ${(inView) ? "inview" : ""} ${alignRight ? "lozender-right clearfix" : "clearfix"}`} style={{ backgroundColor: backgroundColorHex }}>
            <div className={`lozenger-content ${alignRight ? "lozenger-content-right" : ""}`}>
                <div className="columns">
                    {!textFirst && image}
                    {text}
                    {textFirst && image}
                </div>
            </div>
        </div>
    )
}

export default Lozenger;