import React from "react";
import { connect } from "react-redux";
import FilmalityCircularProgress from "../../../shared/filmality-circular-progress";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Location from "./location";
import Errors from "../../../shared/errors";
import HelpText from "../../../shared/help-text";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,  
  OnboardingStep3ClassName
} from "./ducks/reducer";
import Map from "../../../shared/Map/Map";
import Onboarding from '../../../shared/onboarding/onboarding';
import Radio from "../../../shared/filmanize-radio";

import { getDistances } from '../../../shared/Map/google-api';
import { useState } from "react";
import FilmaizeSelect from "../../../shared/filmanize-select";
import { useEffect } from "react";

function Overview(props) {
  const {
    overview,
    filmId,
    errors,
    warnings,
    locationsOverviewOnboardingSteps    
  } = props;

  const readOnly = overview.readOnly;

  const [showDistance, setShowDistance] = useState<boolean>(true);
  const [fromLocation, setFromLocation] = useState<any>(null);
  const [toLocation, setToLocation] = useState<any>(null);
  const [fromLocationNumber, setFromLocationNumber] = useState<any>(null);
  const [toLocationNumber, setToLocationNumber] = useState<any>(null);

  const findLocationNumberFromId = (locationId: any) => {
    return overview.locations.findIndex((l: any) => l.id === locationId) + 1;
  };

  const locationOptions = (overview.locations || []).map((option, index) => {
    return {
        value: option.id,
        label: (index + 1) + " " + option.name
    }
  });

  useEffect(() => {    
    if (overview?.locations?.length) {
      setFromLocation(locationOptions[0]);
      setFromLocationNumber(findLocationNumberFromId(locationOptions[0].value));
      if (locationOptions.length > 1) {
        setToLocation(locationOptions[1]);
        setToLocationNumber(findLocationNumberFromId(locationOptions[1].value));
      }
    }
  },[overview?.locations?.length]);

  const locationMarkers: any[] = overview.locations.map((location, index) => {
    return {
      number: location.number,
      position: {
        lat: location.address.latitude,
        lng: location.address.longitude
      }
    };
  });

  const hospitalMarkers: any[] = overview.hospitals?.map((hospital, index) => {
    return {      
      position: {
        lat: hospital.latitude,
        lng: hospital.longitude
      }
    };
  });

  const parkingMarkers: any[] = overview.parking?.map((hospital, index) => {
    return {      
      position: {
        lat: hospital.latitude,
        lng: hospital.longitude
      }
    };
  });

  function LocationsList() {
    return (
      <div>
          <Map
            locationMarkers={locationMarkers}
             hospitalMarkers={hospitalMarkers}
             parkingMarkers={parkingMarkers}
            // readOnly={readOnly}
            ></Map>
      
        <table className="table mt-2">
          <thead>
            <tr>
              <th></th>
              <th>Location Name</th>
              <th>Address</th>
              <th>Parking</th>
            </tr>
          </thead>
          <tbody>
            {(overview.locations || []).map((location, index) => {
              return (
                <Location
                  key={index}
                  index={index}
                  location={location}
                  filmId={filmId}
                  readOnly={readOnly}
                  totalScenes={overview.totalScenes}></Location>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

const onSetShowDistance = (e: any) => {
  setShowDistance(!showDistance);
};

const onSetFromLocation = (e: any) => {
  setFromLocation(e);
  setFromLocationNumber(findLocationNumberFromId(e.value));
      
};

const onSetToLocation = (e: any) => {
  setToLocation(e);
  setToLocationNumber(findLocationNumberFromId(e.value));
};

  return (
    <div>
      {/* <RenderHelpText /> */}
      {errors && <Errors errors={errors} />}
      {warnings && <Errors errors={warnings} isWarning={true} />}

      {overview?.locations?.length === 0 && <div>
          <p>This will appear once Shooting Locations have been added.</p>
          <p>Click "Add Location" to begin.</p>
        </div>}
      {overview?.locations?.length > 0 &&
      <>
      <LocationsList />

      {overview?.locations?.length > 1 &&
      <div>
        <h3 className="title is-5 mt-1">Distances</h3>
        <div className={`${OnboardingStep1ClassName}`}>
          <Radio
                checked={showDistance}
                value={true}
                onChange={onSetShowDistance}
                text="Distance"
                />

          <Radio
                checked={!showDistance}
                value={false}
                onChange={onSetShowDistance}
                text="Duration"
                />
        </div>
        
        <div className={`${OnboardingStep2ClassName} columns`}>
            <div className="column">
              <div className="field">
                <label className="label">From</label>
                <FilmaizeSelect 
                    value={fromLocation}
                    isMulti={false}
                    options={locationOptions}
                    onChange={onSetFromLocation}
                    placeholder="From Location"
                  />
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">To</label>
                <FilmaizeSelect 
                    value={toLocation}
                    isMulti={false}
                    options={locationOptions}
                    onChange={onSetToLocation}
                    placeholder="To Location"
                  />
              </div>
            </div>
          </div>
        <table className={`table is-hoverable is-striped table is-fullwidth ${OnboardingStep3ClassName}`}>
          <thead>          
          <tr>
            <th></th>
            {(overview?.distanceMatrix?.origins || []).map((origin: any, index: number) => (
            <th key={index} className="has-text-centered">
                {index + 1}
            </th>
          ))}
          </tr>
          </thead>

          {(overview?.distanceMatrix?.origins || []).map((origin: any, index: number) => (
            <tr key={index}>
              <th>{index+1}</th>
              {origin.map((destination: any, innerIndex: number) => (
                  <td className="has-text-centered" style={(fromLocationNumber == index + 1 && toLocationNumber == innerIndex + 1) ? {backgroundColor: "#00ffce", color: "#040f21"} : {}}>                    
                    {innerIndex !== index && <div><a target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=${overview?.locations[index].address.latitude},${overview?.locations[index].address.longitude}&destination=${overview?.locations[innerIndex].address.latitude},${overview?.locations[innerIndex].address.longitude}`} style={(fromLocationNumber == index + 1 && toLocationNumber == innerIndex + 1) ? { color: "#040f21"} : {}}>{showDistance && destination.distance} {!showDistance && destination.duration}</a></div>}                  
                  </td>
              ))}
            </tr>
          ))}
        </table>
        </div>}        
        <Onboarding onboardingSteps={locationsOverviewOnboardingSteps} section="Shooting Locations Overview"/>
      </>
      }  
    </div>
    
  );
}

function mapStateToProps(state) {  
  return {
    locationsOverviewOnboardingSteps:
      state.locationsState.overviewState.onboardingSteps    
  };
}

export default connect(mapStateToProps, {  
})(Overview);
