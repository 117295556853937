import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../shared/loader";
import { fetchBackgroundCharacters } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list";

function ListBackgroundCharactersContainer(props) {
  const { filmId } = props.match.params;
  const { fetchBackgroundCharacters, loading, backgroundCharacters, errors, readOnly } = props;

  useEffect(() => {
    fetchBackgroundCharacters(filmId);
  }, []);

  function renderLoading() {
    return <Loader />;
  }

  function renderBackgroundCharacters(backgroundCharacters, filmId) {
    return (
      <div className="blade-menu">
        <List backgroundCharacters={backgroundCharacters} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/background-characters/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add Background Character
        </NavLink>}
      </div>
    );
  }

  return loading ? renderLoading() : renderBackgroundCharacters(backgroundCharacters, filmId);
}

function mapStateToProps(state) {
  const rootState = state.backgroundCharactersState.rootState;
  return {
    backgroundCharacters: rootState.backgroundCharacters,
    readOnly: rootState.readOnly,
    loading: rootState.loading,
    errors: rootState.errors
  };
}

export default connect(mapStateToProps, {
  fetchBackgroundCharacters
})(ListBackgroundCharactersContainer);
