import mapPictures from "../../../../shared/image-mapper";
import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { SEARCH_SCENES_FOR_PROPS } from "../../../scenes/search/ducks/actions";
import { sortByName } from "./../../../../shared/utility";
import { CHANGE_PROP_TO_COSTUME, CHANGE_PROP_TO_SET_DRESSING, FETCH_ALL_BACKGROUND_CHARACTERS_FOR_PROPS, FETCH_ALL_CHARACTERS_FOR_PROPS } from "./actions";

interface ActionState {
  props: any[];
  loading: boolean;
  loadingSceneSettings: boolean;
  prop: any;
  imageFiles: any[];
  readOnly: boolean;
  scenes: any[];
  onboardingSteps: OnboardingType[];
  findingInScript: boolean;
  searchingScenes: boolean;
  enableSave: boolean;
}

export const OnboardingStep1ClassName = 'props-scenes-onboarding-step-1';
export const OnboardingStep2ClassName = 'props-scenes-onboarding-step-2';
export const OnboardingStep3ClassName = 'props-scenes-onboarding-step-3';
export const OnboardingStep4ClassName = 'props-scenes-onboarding-step-4';
export const OnboardingStep5ClassName = 'props-scenes-onboarding-step-5';

const defaultState: ActionState = {
  props: [],
  loading: true,
  loadingSceneSettings: false,
  prop: {},
  imageFiles: [],
  readOnly: false,
  scenes: [],
  findingInScript: false,
  searchingScenes: false,
  enableSave: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scene Search',
      content: `        
        Use this to quickly select the scenes that match the selected criteria.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Script Search',
      content: `Or use this to search the script for the name of the prop, and select any scenes that match. You can chose which parts of the script to select.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Select',
      content: `Or just select or unselect all scenes.`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Manual',
      content: `If any scenes have not been selected using any of the above methods, then you can manually select or unselect a scene.`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Save',
      content: `Once you have the desired scenes selected, don't forget to click Save.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_PROPS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "CLEAR_PROP": {
      return {
        ...state,
        loading: false,
        prop: null,
        errors: null
      };
    }

    case "PROP_SET_NAME": {
      const prop = { name: action.meta.name, quantity: 1, nameInScript: action.meta.name, acquisitionMethod: 1, enableSave: true }
      return {
        ...state,
        prop: prop
      };
    }

    case "FETCH_NEXT_PROP_NUMBER": {
      let nextNumber = 1;
      if (state.props && state.props.length > 0) {
        const maxNumber = Math.max.apply(
          null,
          state.props.map((prop) => {
            return prop.number;
          })
        );
        nextNumber = maxNumber + 1;
      }
      return {
        ...state,
        prop: { number: nextNumber }
      };
    }

    case "FETCH_PROPS_FULFILLED": {
      return {
        ...state,
        props: action.payload.data.props,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_PROP_PENDING": {
      return {
        ...state,
        prop: null,
        loadingProp: true
      };
    }

    case "FETCH_SINGLE_PROP_FULFILLED": {
      let prop = action.payload.data;

      mapPictures(prop);

      return {
        ...state,
        prop,
        loadingProp: false,
        errors: null
      };
    }

    case FETCH_ALL_CHARACTERS_FOR_PROPS + "_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        loading: false,
        errors: null
      };
    }

    case FETCH_ALL_CHARACTERS_FOR_PROPS + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case FETCH_ALL_BACKGROUND_CHARACTERS_FOR_PROPS + "_FULFILLED": {
      return {
        ...state,
        backgroundCharacters: action.payload.data.backgroundCharacters,
        loading: false,
        errors: null
      };
    }

    case FETCH_ALL_BACKGROUND_CHARACTERS_FOR_PROPS + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "UPDATE_SINGLE_PROP_PENDING": {
      return {
        ...state,
        prop: action.payload,
        loadingProp: true,
        errors: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingProp: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingProp: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingProp: false
      };
    }

    case "UPDATE_SINGLE_PROP_FULFILLED": {
      const prop = { ...action.meta.prop, ...action.payload.data.prop };
      const props: any = state.props.map((c: any, index: number) => {
        if (c.id === prop.id) {
          return { ...c, ...prop };
        } else {
          return c;
        }
      });
      sortByName(props);
      return {
        ...state,
        prop,
        props,
        loadingProp: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_PROP_REJECTED": {
      return {
        ...state,
        prop: action.meta.prop,
        errors: action.payload.response.data.errors,
        loadingProp: false
      };
    }

    case CHANGE_PROP_TO_SET_DRESSING + "_PENDING": {
      return {
        ...state,
        loadingProp: true,
        errors: null
      };
    }

    case CHANGE_PROP_TO_SET_DRESSING + "_FULFILLED": {
      const props = Array.from(state.props);
      var index = props.findIndex(s => s.id === action.meta.prop.id);
      if (index >= 0) {
        props.splice(index);
      }

      return {
        ...state,
        props,
        loadingProp: false,
        errors: null,
        redirect: { to: "set-dressing", id: action.payload.data.setDressingId }
      };
    }

    case CHANGE_PROP_TO_SET_DRESSING + "_REJECTED": {
      return {
        ...state,
        prop: action.meta.prop,
        errors: action.payload.response.data.errors,
        loadingProp: false
      };
    }

    case CHANGE_PROP_TO_COSTUME + "_PENDING": {
      return {
        ...state,
        loadingProp: true,
        errors: null
      };
    }

    case CHANGE_PROP_TO_COSTUME + "_FULFILLED": {
      const props = Array.from(state.props);
      var index = props.findIndex(s => s.id === action.meta.prop.id);
      if (index >= 0) {
        props.splice(index);
      }

      return {
        ...state,
        props,
        loadingProp: false,
        errors: null,
        redirect: { to: "costume", id: action.payload.data.costumeId }
      };
    }

    case CHANGE_PROP_TO_COSTUME + "_REJECTED": {
      return {
        ...state,
        prop: action.meta.prop,
        errors: action.payload.response.data.errors,
        loadingProp: false
      };
    }

    case "ADD_SINGLE_PROP_PENDING": {
      return {
        ...state,
        errors: null,
        loadingProp: true
      };
    }

    case "ADD_SINGLE_PROP_FULFILLED": {
      const prop: any = action.payload.data.prop;

      let props = [prop, ...state.props];
      sortByName(props);
      return {
        ...state,
        prop,
        props,
        loadingProp: false,
        errors: null,
        redirect: { to: "scenes", id: prop.id }
      };
    }

    case "ADD_SINGLE_PROP_REJECTED": {
      return {
        ...state,
        prop: action.meta.prop,
        errors: action.payload.response.data.errors,
        loadingProp: false
      };
    }

    case "DELETE_SINGLE_PROP_PENDING": {
      return {
        ...state,
        prop: action.payload,
        loadingProp: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_PROP_FULFILLED": {
      const prop = action.meta.prop;
      const props: any = state.props.filter((c: any, index: number) => {
        return c.id !== prop.id;
      });

      return {
        ...state,
        prop: null,
        props,
        loadingProp: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_PROP_REJECTED": {
      return {
        ...state,
        prop: action.meta.prop,
        errors: action.payload.response.data.errors,
        loadingProp: false
      };
    }

    case "ADD_PROP_IMAGE_FILES": {
      let mainImageUrl = state.prop.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.imageFiles[0].name;
      }

      return {
        ...state,
        imageFiles: [...state.imageFiles, ...action.meta.imageFiles],
        prop: { ...state.prop, mainImageUrl: mainImageUrl }
      };
    }

    case "ADD_PROP_IMAGE_URL": {
      var prop: any = { ...state.prop };
      let mainImageUrl = state.prop.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.url;
      }

      if (prop?.imageUrls) {
        prop = {
          ...prop,
          imageUrls: [...prop?.imageUrls, action.meta.url],
          mainImageUrl: mainImageUrl
        };
      } else {
        prop = {
          ...prop,
          imageUrls: [action.meta.url],
          mainImageUrl: mainImageUrl
        };
      }
      return {
        ...state,
        prop: prop
      };
    }

    case "DELETE_PROP_IMAGE_URL": {
      let imageUrls: any = state.prop.imageUrls;
      imageUrls = imageUrls.filter((item) => {
        return item !== action.meta.url;
      });
      return {
        ...state,
        prop: { ...state.prop, imageUrls: imageUrls }
      };
    }

    case "DELETE_PROP_IMAGE_FILE": {
      let imageFiles = state.imageFiles.filter((file) => {
        return file.name !== action.meta.fileName;
      });
      return {
        ...state,
        imageFiles: imageFiles
      };
    }

    case "PROP_MAIN_IMAGE_CHANGED": {
      return {
        ...state,
        prop: { ...state.prop, mainImageUrl: action.meta.url }
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        prop: null,
        redirect: null
      };
    }

    case "FETCH_ALL_SCENES_FOR_PROPS_FULFILLED": {
      const scenes = action.payload.data.scenes.map((scene) => {
        return { ...scene, name: `${scene.number}. ${scene.settingName}` };
      });
      return {
        ...state,
        scenes,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_PROPS_PENDING": {
      return {
        ...state,
        loadingSceneSettings: true
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_PROPS_FULFILLED": {
      return {
        ...state,
        selectedSceneIds: action.payload.data.sceneIds,
        readOnly: action.payload.data.readOnly,
        loadingSceneSettings: false,
        loading: false,
        errors: null
      };
    }

    case "SET_SCENE_ASSIGNED_TO_PROPS": {
      return {
        ...state,
        selectedSceneIds: [action.meta.sceneId]
      };
    }

    case "ASSIGN_SCENES_TO_PROP_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "FETCH_ALL_CHARACTERS_FOR_PROPS_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        loading: false,
        errors: null
      };
    }

    case "FETCH_ALL_SCENE_SETTINGS_FOR_PROPS_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case SEARCH_SCENES_FOR_PROPS + "_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case SEARCH_SCENES_FOR_PROPS + "_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        searchingScenes: false,
      };
    }

    case SEARCH_SCENES_FOR_PROPS + "_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "PROPS_SEARCH_SCRIPT_PENDING": {
      return {
        ...state,
        findingInScript: true
      }
    }

    case "PROPS_SEARCH_SCRIPT_FULFILLED": {
      let searchedScenes = action.payload.data.scenes;
      const sceneIds = state.scenes.map(s => s.id);
      const filteredScenes = searchedScenes.filter(s => sceneIds.indexOf(s) > -1);
      return {
        ...state,
        searchedScenes: filteredScenes.map((id) => { return { id } }),
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        findingInScript: false
      };
    }

    case "PROPS_SEARCH_SCRIPT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
        findingInScript: false
      };
    }

    case "CLEAR_SEARCH_SCENES_FOR_PROPS": {
      return {
        ...state,
        searchedScenes: [],
        loading: false,
        errors: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
