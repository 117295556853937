import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { sortByName } from "../../../../shared/utility";

export const OnboardingStep1ClassName = 'background-characters-overview-onboarding-step-1';

interface CharacterOverviewState {
  overview: any;
  loading: boolean;
  assigning: boolean;
  onboardingSteps: OnboardingType[];
}

const defaultState: CharacterOverviewState = {
  overview: {},
  loading: true,
  assigning: false,
  onboardingSteps: [            
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Assign Background Actors to Background Characters',
      content: `<p>Assign Background Actors to Background Characters by dragging the Background Actor's name from the list on the right into the 'Background Actor's Name' column for the Background Character.</p> 
              <p>Made a mistake? Click <i class="material-icons">cancel</i> to remove the Background Actor from the Background Character.</p>`,
      disableBeacon: true,
      nextButtonText: 'Get Started'
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_BACKGROUND_CHARCTER_OVERVIEW_PENDING": {
      return {
        ...state,
        loading: true,
        overview: null,
        errors: null
      };
    }

    case "FETCH_BACKGROUND_CHARCTER_OVERVIEW_FULFILLED": {
      return {
        ...state,
        loading: false,
        overview: action.payload.data,
        errors: null
      };
    }

    case "CLEAR_BACKGROUND_CHARACTER_OVERVIEW": {
      return {
        ...state,
        loading: false,
        overview: null,
        errors: null,
        warnings: null
      };
    }

    case "ASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER": {      
      const actor = action.meta.actor;
      let backgroundCharacters = [...state.overview.backgroundCharacters];
      const character = backgroundCharacters.find(
        (c) => c.id === action.meta.character.id
      );

      character.backgroundActors.push({
        id: actor.id,
        name: actor.name,
        mandyUrl: actor.mandyUrl,
        starNowUrl: actor.starNowUrl
      })      

      let overview = { ...state.overview, backgroundCharacters };
      return {
        ...state,
        overview: overview
      };
    }

    case "UNASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER": {
      const backgroundActorId = action.meta.backgroundActorId;
      let backgroundCharacters = [...state.overview.backgroundCharacters];
      const character = backgroundCharacters.find(
        (c) => c.id === action.meta.characterId
      );

      let backgroundActorIndex = character.backgroundActors.findIndex(a => a.id === backgroundActorId)
      if (backgroundActorIndex > -1) {
        character.backgroundActors.splice(backgroundActorIndex, 1);
      }

      let overview = { ...state.overview, backgroundCharacters };
      return {
        ...state,
        overview: overview
      };
    }

    case "ALIAS_BACKGROUND_CHARCTER": {
      const aliasCharacter = action.meta.aliasCharacter;
      const mainCharacter = action.meta.mainCharacter;
      let characters = [...state.overview.characters];
      const character = characters.find((c) => c.id === mainCharacter.id);
      const aliasCharacterIndex = characters.findIndex(
        (c) => c.id === aliasCharacter.id
      );

      character.aliases = [
        ...character.aliases,
        aliasCharacter,
        ...aliasCharacter.aliases
      ];
      characters[aliasCharacterIndex].aliases = [];

      characters.splice(aliasCharacterIndex, 1);

      let overview = { ...state.overview, characters };
      return {
        ...state,
        overview: overview
      };
    }

    case "UNALIAS_BACKGROUND_CHARCTER": {
      const aliasCharacter = action.meta.aliasCharacter;
      const mainCharacter = action.meta.mainCharacter;
      let characters = [...state.overview.characters];
      const character = characters.find((c) => c.id === mainCharacter.id);

      const aliasCharacterIndex = character.aliases.findIndex(
        (a) => a.id === aliasCharacter.id
      );
      character.aliases.splice(aliasCharacterIndex, 1);

      characters.push(aliasCharacter);

      sortByName(characters);

      let overview = { ...state.overview, characters };
      return {
        ...state,
        overview: overview
      };
    }

    case "SAVE_UNASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER_PENDING": {
      return {
        ...state,
        errors: null,
        warnings: null,
        assigning: true 
      };
    }

    case "SAVE_UNASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER_FULFILLED": {
      return {
        ...state,
        errors: null,
        warnings: null,
        assigning: false
      };
    }

    case "SAVE_UNASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        assigning: false 
      };
    }

    case "SAVE_ASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER_PENDING": {
      return { 
        ...state,
        errors: null,
        warnings: null,
        assigning: true 
      };
    }

    case "SAVE_ASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER_FULFILLED": {
      return {
        ...state,
        warnings: action.payload.data.hasWarnings ? action.payload.data.warnings : null,
        errors: null,
        assigning: false
      };
    }

    case "SAVE_ASSIGN_BACKGROUND_ACTOR_TO_BACKGROUND_CHARCTER_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        assigning: false
      };
    }

    case "SAVE_ALIAS_BACKGROUND_CHARACTER": {
      return state;
    }

    case "SAVE_REMOVE_ALIAS_BACKGROUND_CHARACTER": {
      return state;
    }

    default:
      return state;
  }
};

export default reducer;
