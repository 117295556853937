import getApiClient from "../../../../shared/api";

export function fetchShootingDayWeather(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTING_DAY_WEATHER",
      payload: getApiClient().get(`/film/${filmId}/ShootingDay/${shootingDayId}/Weather`),
    });
  };
}
