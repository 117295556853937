import { FETCH_PEOPLE_DETAILS_PROGRESS, SEND_DIETARY_REQUIREMENTS, SEND_DIETARY_REQUIREMENTS_TO_PERSON, UPDATE_EXCLUDE_DRINK, UPDATE_PERSON_DETAILS_EMAIL_STATUS, UPDATE_SEND_PERSON_DETAILS_PROGRESS } from "./actions";
import OnboardingType from "../../../../shared/onboarding/onboarding-type";
export const OnboardingStep1ClassName = 'messages-onboarding-step-1';

interface MessagesState {
  loading: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  peopleDetailsProgress: any;
  progress: any;
  savingExcludeDrinks: boolean;
}

const defaultState: MessagesState = {
  loading: false,
  readOnly: false,
  peopleDetailsProgress: {},
  progress: null,
  savingExcludeDrinks: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Dietary Requirements',
      content: `        
        Clicking this will send an email to all actors, background actors and crew asking them to fill in their dietary requirements.
      `
    }
  ]
};

export const Actor = "Actor";
export const BackgroundActor = "BackgroundActor";
export const CrewMember = "CrewMember";

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case SEND_DIETARY_REQUIREMENTS + "_PENDING": {

      const peopleDetailsProgress = { ...state.peopleDetailsProgress };

      peopleDetailsProgress.actors.forEach(actor => {
        actor.sending = true;
      });
      peopleDetailsProgress.backgroundActors.forEach(backgroundActor => {
        backgroundActor.sending = true;
      });

      peopleDetailsProgress.crewMembers.forEach(crewMember => {
        crewMember.sending = true;
      });

      return {
        ...state,
        errors: null,
        peopleDetailsProgress: peopleDetailsProgress,
        progress: { progress: null, message: "Queued for sending" }
      };
    }

    case SEND_DIETARY_REQUIREMENTS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }

    case SEND_DIETARY_REQUIREMENTS + "_FULFILLED": {
      return {
        ...state,
        errors: null,
        saved: true
      };
    }

    case SEND_DIETARY_REQUIREMENTS_TO_PERSON + "_PENDING": {
      const { personId, personType } = action.meta;
      const peopleDetailsProgress = { ...state.peopleDetailsProgress };
      if (personType === Actor) {
        peopleDetailsProgress.actors.forEach((actor: any) => {
          if (actor.id === personId) {
            actor.sending = true;
          } else {
            actor.disabled = true;
          }
        });
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = true;
        });
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = true;
        });
      } else if (personType === BackgroundActor) {
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          if (backgroundActor.id === personId) {
            backgroundActor.sending = true;
          } else {
            backgroundActor.disabled = true;
          }
        });

        peopleDetailsProgress.actors.forEach((actor: any) => {
          actor.disabled = true;
        });
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = true;
        });
      } else if (personType === CrewMember) {
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          if (crewMember.id === personId) {
            crewMember.sending = true;
          } else {
            crewMember.disabled = true;
          }
        });

        peopleDetailsProgress.actors.forEach((actor: any) => {
          actor.disabled = true;
        });
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = true;
        });
      }


      return {
        ...state,
        errors: null,
        peopleDetailsProgress: peopleDetailsProgress,
        progress: { progress: null, message: "Queued for sending" }
      };
    }

    case SEND_DIETARY_REQUIREMENTS_TO_PERSON + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }

    case SEND_DIETARY_REQUIREMENTS_TO_PERSON + "_FULFILLED": {
      return {
        ...state,
        errors: null,
        peopleDetailsProgress: { ...state.peopleDetailsProgress, sendToSelf: false, sendToMembers: false, sendToPerson: null },
        saved: true
      };
    }

    case FETCH_PEOPLE_DETAILS_PROGRESS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_PEOPLE_DETAILS_PROGRESS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FETCH_PEOPLE_DETAILS_PROGRESS + "_FULFILLED": {
      return {
        ...state,
        peopleDetailsProgress: action.payload.data,
        errors: null,
        loading: false,
        saved: true
      };
    }

    case UPDATE_SEND_PERSON_DETAILS_PROGRESS: {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case UPDATE_PERSON_DETAILS_EMAIL_STATUS: {
      const { personId, personType, status, statusDescription, statusChangedDate } = action.meta;
      const peopleDetailsProgress = { ...state.peopleDetailsProgress };
      if (personType === Actor) {
        peopleDetailsProgress.actors.forEach((actor: any) => {
          updatePersonStatus(actor, personId, status, statusDescription, statusChangedDate);
        });

        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = false;
        });
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = false;
        });
      } else if (personType === BackgroundActor) {
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          updatePersonStatus(backgroundActor, personId, status, statusDescription, statusChangedDate);
        });

        peopleDetailsProgress.actors.forEach((actor: any) => {
          actor.disabled = false;
        });
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = false;
        });
      } else if (personType === CrewMember) {
        peopleDetailsProgress.crewMembers.forEach((crewMember: any) => {
          updatePersonStatus(crewMember, personId, status, statusDescription, statusChangedDate);
        });

        peopleDetailsProgress.actors.forEach((actor: any) => {
          actor.disabled = false;
        });
        peopleDetailsProgress.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = false;
        });
      }

      return {
        ...state,
        peopleDetailsProgress
      };
    }

    case UPDATE_EXCLUDE_DRINK + "_PENDING": {
      return {
        ...state,
        savingExcludeDrinks: true
      };
    }

    case UPDATE_EXCLUDE_DRINK + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        savingExcludeDrinks: false
      };
    }

    case UPDATE_EXCLUDE_DRINK + "_FULFILLED": {
      return {
        ...state,
        errors: null,
        savingExcludeDrinks: false
      };
    }

    default:
      return state;
  }
};

const updatePersonStatus = (person, personId, status, statusDescription, statusChangedDate) => {
  if (person.id === personId) {
    if (status > person.status || status == 1) {
      person.status = status;
      person.statusDescription = statusDescription;
    }
    person.lastStatusChanged = statusChangedDate;
    person.sending = false;
  } else {
    person.disabled = false;
  }
};



export default reducer;
