import getApiClient from "../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../notifications/ducks/actions";

export const IMPORT_FILM = "IMPORT_FILM";
export const IMPORT_FILM_ERROR = "IMPORT_FILM_ERROR";
export const IMPORT_FILM_CLEAR_REDIRECT = "IMPORT_FILM_CLEAR_REDIRECT";

export function importFilm(formData) {
  return (dispatch) => {
    dispatch({
      type: IMPORT_FILM,
      payload: getApiClient().post("film/import", formData, {
        headers: {
          "content-type": `multipart/form-data`
        }
      })
    }).then((response) => {
      dispatch(showInfo(`Successfully imported film`));
    })
      .catch((response) => {
        dispatch(showError(`Error importing film`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: IMPORT_FILM_CLEAR_REDIRECT
    });
  };
}

export function setImportError(error) {
  return (dispatch) => {
    dispatch({
      type: IMPORT_FILM_ERROR,
      meta: { error }
    });
  };
}