import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import {
  fetchUnapprovedUsers,
  approveUsers,
  notApproveUsers,
  ignoreUsers
} from "./ducks/actions";
import Form from "./form";

function UnapprovedUsersContainer(props) {
  const {
    fetchUnapprovedUsers,
    approveUsers,
    notApproveUsers,
    ignoreUsers,
    loading,
    errors,
    users
  } = props;

  const [showNotApproved, setShowNotApproved] = useState(false);

  useEffect(() => {
    fetchUnapprovedUsers(showNotApproved);
  }, [showNotApproved]);

  function onApproveUsers(users) {
    approveUsers(users)
  }

  function onNotApproveUsers(users) {
    notApproveUsers(users)
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="blade-content wideaf">
      <Form
        errors={errors}
        loading={false}
        initialValues={{ users: users }}
        approveUsers={onApproveUsers}
        notApproveUsers={onNotApproveUsers}
        ignoreUsers={ignoreUsers}
        showNotApproved={showNotApproved}
        setShowNotApproved={setShowNotApproved}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { adminState } = state;
  return {
    users: adminState.unapprovedUsersState.users,
    errors: adminState.unapprovedUsersState.errors,
    loading: adminState.unapprovedUsersState.loading
  };
}

export default connect(mapStateToProps, {
  fetchUnapprovedUsers,
  approveUsers,
  notApproveUsers,
  ignoreUsers
})(UnapprovedUsersContainer);

