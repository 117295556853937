import getApiClient from "./../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications,
  showWarning
} from "../../../../notifications/ducks/actions";

const url = "/film";

export function fetchCharacterOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHARCTER_OVERVIEW",
      payload: getApiClient().get(`${url}/${id}/Character/Overview`)
    });
  };
}

export function clearCharacterOverview(id) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_CHARACTER_OVERVIEW"
    });
  };
}

export function assignActor(filmId, actor, character) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_ASSIGN_ACTOR_TO_CHARCTER",
      payload: getApiClient().put(
        `${url}/${filmId}/Character/${character.id}/AssignActor`,
        { actorId: actor.id }
      )
    })
      .then((response) => {
        dispatch({
          type: "ASSIGN_ACTOR_TO_CHARCTER",
          meta: { actor, character }
        });
        
        dispatch(
          showInfo(
            `Assigned actor ${actor.name} to character ${character.name}`
          )
        );

        if (response.value.data.hasWarnings) {
          dispatch(
            showWarning(
              `Warning assigning actor ${actor.name} to character ${character.name}`
            )
          );
        }                   
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error assigning actor ${actor.name} to character ${character.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function unassignActor(filmId, character, actorName) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_UNASSIGN_ACTOR_TO_CHARCTER",
      payload: getApiClient().put(
        `${url}/${filmId}/Character/${character.id}/UnAssignActor`
      )
    })
      .then((response) => {
        dispatch({
          type: "UNASSIGN_ACTOR_TO_CHARCTER",
          meta: { characterId: character.id }
        });
        dispatch(
          showInfo(
            `Unassigned actor ${actorName} from character ${character.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error unassigning actor ${actorName} from character ${character.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function aliasCharacter(filmId, aliasCharacter, mainCharacter) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_ALIAS_CHARACTER",
      payload: getApiClient().put(
        `${url}/${filmId}/Character/${mainCharacter.id}/Alias`,
        { aliasId: aliasCharacter.id }
      )
    })
      .then((response) => {
        dispatch({
          type: "ALIAS_CHARCTER",
          meta: { aliasCharacter, mainCharacter }
        });
        dispatch(
          showInfo(`Aliased ${aliasCharacter.name} with ${mainCharacter.name}`)
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error aliasing ${aliasCharacter.name} with ${mainCharacter.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function unaliasCharacter(filmId, aliasCharacter, mainCharacter) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_REMOVE_ALIAS_CHARACTER",
      payload: getApiClient().put(
        `${url}/${filmId}/Character/${mainCharacter.id}/RemoveAlias`,
        { aliasId: aliasCharacter.id }
      )
    })
      .then((response) => {
        dispatch({
          type: "UNALIAS_CHARCTER",
          meta: { aliasCharacter, mainCharacter }
        });
        dispatch(
          showInfo(
            `Removed alias ${aliasCharacter.name} from ${mainCharacter.name}`
          )
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error removing alias ${aliasCharacter.name} from ${mainCharacter.name}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function orderCharactersByScenes() { 
  return (dispatch) => {
    dispatch({
      type: "CHARACTERS_OVERVIEW_ORDERBY_SCENES"
    });
  };
}

export function orderCharactersByName() { 
  return (dispatch) => {
    dispatch({
      type: "CHARACTERS_OVERVIEW_ORDERBY_NAME"
    });
  };
}
