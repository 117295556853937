import { UPDATE_SCRIPT_UPLOAD_ERROR } from "../../middleware/signalRMiddleware";
import { UPLOAD_SCRIPT_ERROR, UPLOAD_SCRIPT_PROGRESS, UPLOAD_SCRIPT_SET_ACTOR_UNION, UPLOAD_SCRIPT_SET_BUDGET, UPLOAD_SCRIPT_SET_CREW_UNION, UPLOAD_SCRIPT_SET_CURRENCY_ID, UPLOAD_SCRIPT_SET_LANGUAGE_ID, UPLOAD_SCRIPT_SET_USE_AUTO_BREAKDOWN } from "./actions";

export const OnboardingStep1ClassName = 'script-upload-onboarding-step-1';
export const OnboardingStep2ClassName = 'script-upload-onboarding-step-2';
export const OnboardingStep3ClassName = 'script-upload-onboarding-step-3';

const defaultState: any = {
  actorUnionSelectedValue: null,
  crewUnionSelectedValue: null,
  currencyId: null,
  languageId: null,
  budget: null,
  useAutoBreakdown: true,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Film Budget',
      content: `                
        <p>The film budget is used to determine which crew roles are automatically selected as required.</p>
        <p>It will also apply the correct actor and crew rates for budgeting purposes, if any unions are available and selected. </p>
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Use Smart Breakdown',
      content: `<p>This will use GPT to breakdown your script automatically. Your script will be sent to OpenAI for analysis, but none of the contents will be used for training the GPT model.</p>
                <p>If your script contains anything that GPT deems inappropriate then that part will not be broken down.</p>
                <p>This may take some time, depending on the length and number of scenes in the script.</p>`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Upload',
      content: `Click "Choose a script..." to upload your script file, or drag and drop the file into the dotted area.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "INITIALISE_SCRIPT_UPLOAD": {
      return {
        ...state,
        script: null,
        uploadingStatus: null,
        errors: null,
        progress: null,
        budget: null
      };
    }

    case UPLOAD_SCRIPT_PROGRESS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case UPLOAD_SCRIPT_PROGRESS + "_FULFILLED": {
      return {
        ...state,
        progress: action.payload.data.isInProgress ? { progress: action.payload.data.progress, message: action.payload.data.progressMessage } : null,
        uploadingStatus: action.payload.data.isInProgress ? "uploading" : state.uploadingStatus,
        isInProgress: action.payload.data.isInProgress,
        loading: false
      };
    }

    case UPLOAD_SCRIPT_PROGRESS + "_REJECTED": {
      return {
        ...state,
        script: null,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "UPLOAD_SCRIPT_PENDING": {
      return {
        ...state,
        uploadingStatus: "uploading",
        progress: { progress: null }
      };
    }

    case "UPLOAD_SCRIPT_FULFILLED": {
      return {
        ...state,
        script: action.payload.data
      };
    }

    case "UPLOAD_SCRIPT_REJECTED": {
      return {
        ...state,
        script: null,
        errors: action.payload.response.data.errors,
        uploadingStatus: "failed"
      };
    }

    case UPLOAD_SCRIPT_ERROR: {
      return {
        ...state,
        script: null,
        uploadingStatus: null,
        errors: { "": [action.meta.error] }
      };
    }

    case "SCRIPT_CLEAR_REDIRECT": {
      return {
        ...state,
        redirect: null
      };
    }

    case "UPDATE_SCRIPT_UPLOAD_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case "UPDATE_SCRIPT_UPLOAD_COMPLETED": {
      const filmId = action.meta.filmId;
      return {
        ...state,
        uploadingStatus: "uploaded",
        redirect: { to: `/films/${filmId}/breakdown/overview` }
      }
    }

    case UPDATE_SCRIPT_UPLOAD_ERROR: {
      return {
        ...state,
        uploadingStatus: null,
        errors: { "": action.meta.errors }
      }
    }

    case "UPLOAD_SCRIPT_CHARACTERS_ORDERBY_SCENES": {
      const script = { ...state.script };
      let characters = script.characters;
      characters.sort((a, b) => {
        return b.numberOfScenes - a.numberOfScenes;
      });
      return {
        ...state,
        script: script,
        characterOrderBy: 'Scenes'
      };
    }

    case "UPLOAD_SCRIPT_CHARACTERS_ORDERBY_NAME": {
      const script = { ...state.script };
      let characters = script.characters;
      characters.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      return {
        ...state,
        script: script,
        characterOrderBy: 'Name'
      };
    }

    case UPLOAD_SCRIPT_SET_ACTOR_UNION: {
      return {
        ...state,
        actorUnionSelectedValue: action.meta.actorUnion
      };
    }

    case UPLOAD_SCRIPT_SET_CREW_UNION: {
      return {
        ...state,
        crewUnionSelectedValue: action.meta.crewUnion
      };
    }

    case UPLOAD_SCRIPT_SET_CURRENCY_ID: {
      return {
        ...state,
        currencyId: action.meta.currencyId
      };
    }

    case UPLOAD_SCRIPT_SET_LANGUAGE_ID: {
      return {
        ...state,
        languageId: action.meta.languageId
      };
    }

    case UPLOAD_SCRIPT_SET_BUDGET: {
      return {
        ...state,
        budget: action.meta.budget
      };
    }

    case UPLOAD_SCRIPT_SET_USE_AUTO_BREAKDOWN: {
      return {
        ...state,
        useAutoBreakdown: action.meta.useAutoBreakdown
      };
    }

    default:
      return state;
  }
};

export default reducer;
