export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" {...props}>
    <g id="Group_3976" data-name="Group 3976" transform="translate(23 -6) rotate(90)">
      <g id="Rectangle_18" data-name="Rectangle 18" transform="translate(6 4)" fill="none" strokeWidth="1">
        <rect width="21" height="5" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="20" height="4" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_19" data-name="Rectangle 19" transform="translate(6 11)" fill="none" strokeWidth="1">
        <rect width="21" height="5" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="20" height="4" rx="0.5" fill="none" />
      </g>
      <g id="Rectangle_20" data-name="Rectangle 20" transform="translate(6 18)" fill="none" strokeWidth="1">
        <rect width="21" height="5" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="20" height="4" rx="0.5" fill="none" />
      </g>
    </g>
  </svg>
