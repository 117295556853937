  
import { useEffect } from "react";
import { connect } from "react-redux";
import { useInView } from 'react-intersection-observer';
import {  
  fetchChangeLogs  
} from "./ducks/actions";
import Loader from "../../../shared/loader";
import WhatsNew from "./whats-new"


const WhatsNewContainer = (props) => {  
    const { 
      errors,
      changeLogs,
      fetchChangeLogs,
      continuationToken,
      id,
      loading    
       } = props;

    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {        
        fetchChangeLogs(null, id);      
    }, [id]);

    const onLoadMore = () => {
      fetchChangeLogs(continuationToken, null);
    };

    return (
      <>
        {loading && <Loader/>}
        <WhatsNew ref={ref} changeLogs={changeLogs} moreToLoad={continuationToken != null} loadMore={onLoadMore} id={id} loading={loading}/>
        </>
    )
}


  function mapStateToChangeLogs(state) {
    const { changeLogState, commonState } = state;
    return {
      changeLogs: changeLogState.changeLogs,
      continuationToken: changeLogState.continuationToken,
      errors: changeLogState.errors,    
      loading: changeLogState.loading
    };
  }
  
  export default connect(mapStateToChangeLogs, {
    fetchChangeLogs
  })(WhatsNewContainer);
  