import getApiClient from "../../../shared/api";
export const FETCH_USERS_BY_DATE = "FETCH_USERS_BY_DATE";

export function fetchUsersByDate() {
  return (dispatch) => {
    dispatch({
      type: FETCH_USERS_BY_DATE,
      payload: getApiClient().get(`/admin/usersByDate`)
    });
  };
}
