import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import {
  fetchRiskAssessments,
  uploadRiskAssessment,
  uploadDailyRiskAssessment,
  setUploadError,
  deleteRiskAssessment,
  deleteDailyRiskAssessment
} from "./ducks/actions";
import confirm from "../../../shared/modal/confirm";
import RiskAssessmentForm from "./edit-form";
import Onboarding from "../../../shared/onboarding/onboarding";

function ListShootingDayWeatherContainer(props) {
  const { filmId, shootingDayId } = props.match.params;
  const { fetchRiskAssessments,
    uploadRiskAssessment,
    uploadDailyRiskAssessment,
    loading,
    riskAssessments,
    dailyRiskAssessments,
    errors,
    setUploadError,
    deleteRiskAssessment,
    deleteDailyRiskAssessment,
    onboardingSteps,
    readOnly } = props;

  useEffect(() => {
    fetchRiskAssessments(filmId, shootingDayId);
  }, [filmId, shootingDayId]);

  function renderLoading() {
    return <Loader />;
  }

  const onUploadRiskAssessment = (formData) => {
    uploadRiskAssessment(formData, filmId);
  };

  const onUploadDailyRiskAssessment = (formData) => {
    uploadDailyRiskAssessment(formData, filmId);
  };

  const onDeleteRiskAssessment = (fileId, fileName) => {
    confirm(() => deleteRiskAssessment(filmId, fileId), fileName);
  };

  const onDeleteDailyRiskAssessment = (fileId, fileName) => {
    confirm(() => deleteDailyRiskAssessment(filmId, fileId), fileName);
  };

  function renderShootingDays(riskAssessments, filmId) {
    return (
      <>
        <div className="blade-content wider">
          <p className="menu-label">Risk Assessments</p>
          <RiskAssessmentForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            riskAssessments={riskAssessments}
            dailyRiskAssessments={dailyRiskAssessments}
            uploadRiskAssessment={onUploadRiskAssessment}
            uploadDailyRiskAssessment={onUploadDailyRiskAssessment}
            setUploadError={setUploadError}
            loading={loading}
            deleteRiskAssessment={onDeleteRiskAssessment}
            deleteDailyRiskAssessment={onDeleteDailyRiskAssessment}
            shootingDayId={shootingDayId}
            readOnly={readOnly}
          />
        </div>
        <Onboarding onboardingSteps={onboardingSteps} section="Risk Assessments" />
      </>
    );
  }

  return loading ? renderLoading() : renderShootingDays(riskAssessments, filmId);
}

function mapStateToProps(state) {
  const riskAssessmentState = state.shootingdaysState.riskAssessmentState;
  return {
    riskAssessments: riskAssessmentState.riskAssessments,
    dailyRiskAssessments: riskAssessmentState.dailyRiskAssessments,
    onboardingSteps: riskAssessmentState.onboardingSteps,
    loading: riskAssessmentState.loading,
    errors: riskAssessmentState.errors,
    readOnly: riskAssessmentState.readOnly
  };
}

export default connect(mapStateToProps, {
  fetchRiskAssessments,
  uploadRiskAssessment,
  uploadDailyRiskAssessment,
  setUploadError,
  deleteRiskAssessment,
  deleteDailyRiskAssessment
})(ListShootingDayWeatherContainer);
