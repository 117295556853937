import { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BoxWraper from "../../../account/box-wrapper";
import Loader from "../../../shared/loader";
import auth from "../../../shared/auth";
import {
  fetchFeatureRequest,
  updateFeatureRequest,
  clearRedirect,
  fetchFeatureRequestStatuses,
  fetchFeatureRequestPriorities
} from "../ducks/actions";
import Form from "./form";

const FeatureRequestContainer = (props) => {
  const {
    fetchFeatureRequest,
    featureRequest,
    updateFeatureRequest,
    featureRequestLoading,
    errors,
    loading,
    pageSections,
    redirect,
    clearRedirect,
    fetchFeatureRequestStatuses,
    fetchFeatureRequestPriorities,
    statuses,
    priorities,
    prioritiesLoading,
    readOnly } = props;

  const featureRequestId = props.match.params.featureRequestId;

  useEffect(() => {
    if (auth.isAdmin()) {
      fetchFeatureRequestStatuses();
    }

    fetchFeatureRequestPriorities();
  }, []);

  useEffect(() => {
    fetchFeatureRequest(featureRequestId);
  }, [featureRequestId]);

  if (redirect) {
    if (redirect.to === "view") {
      clearRedirect();
      const url = `/feature-request/${featureRequestId}`;
      return <Redirect to={url} />;
    }
  }

  const handleFeedbackFormSubmit = (featureRequest) => {
    updateFeatureRequest(featureRequest);
  };

  return (
    <BoxWraper width={5}>
      {(featureRequestLoading || loading || prioritiesLoading) && <Loader />}
      {!(featureRequestLoading || loading || prioritiesLoading) && <Form
        featureRequestId={featureRequestId}
        initialValues={featureRequest}
        pageSections={pageSections}
        statuses={statuses}
        priorities={priorities}
        errors={errors}
        readOnly={readOnly}
        isAdmin={auth.isAdmin()}
        onSubmit={handleFeedbackFormSubmit} />
      }
    </BoxWraper>
  );
}

function mapStateToChangeLogs(state) {
  const { featureRequestState, commonState } = state;
  return {
    loading: featureRequestState.loading,
    featureRequestLoading: featureRequestState.featureRequestLoading,
    errors: featureRequestState.featureRequestErrors,
    featureRequest: featureRequestState.featureRequest,
    redirect: featureRequestState.redirect,
    statuses: featureRequestState.statuses,
    priorities: featureRequestState.priorities,
    prioritiesLoading: featureRequestState.prioritiesLoading,
    pageSections: commonState.pageSections,
    readOnly: featureRequestState.readOnly
  };
}

export default connect(mapStateToChangeLogs, {
  fetchFeatureRequest,
  updateFeatureRequest,
  clearRedirect,
  fetchFeatureRequestStatuses,
  fetchFeatureRequestPriorities
})(FeatureRequestContainer);
