import { useEffect } from "react";
import { fetchCharacterCostumes } from "./ducks/actions";
import { connect } from "react-redux";
import CharacterCostumes from "./character-costumes";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

const CharacterCostumesContainer = (props) => {
    const { loading, costumes, fetchCharacterCostumes } = props;
    const characterId = props.characterId || props.match.params.characterId;
    const filmId = props.filmId || props.match.params.filmId;
    useEffect(() => {
        fetchCharacterCostumes(filmId, characterId);
    }, [characterId]);

    return (
        <ScrollIntoView
            className="blade-content"
            path="/films/:filmId/characters/:characterId/costumes"
            loading={loading}>
            <CharacterCostumes characterCostumes={costumes} filmId={filmId} loading={loading} />
        </ScrollIntoView>
    )
}

function mapStateToCostumes(state) {
    const { charactersState } = state;
    const characterCostumesState = charactersState.costumesState;
    return {
        errors: characterCostumesState.errors,
        costumes: characterCostumesState.costumes,
        loading: characterCostumesState.loading

    };
}

export default connect(mapStateToCostumes, {
    fetchCharacterCostumes: fetchCharacterCostumes
})(CharacterCostumesContainer);