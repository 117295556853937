import getApiClient from "../../../shared/api";
import { showInfo, showError, clearNotifications } from "../../../notifications/ducks/actions";
import { connection } from "../../../middleware/collaboratorSignalRMiddleware";
import { HubConnectionState } from "@microsoft/signalr";

export const FETCH_COLLABORTAROR_CAN_ADD = "FETCH_COLLABORTAROR_CAN_ADD";
export const FETCH_COLLABORATORS_OVERVIEW = "FETCH_COLLABORATORS_OVERVIEW";
export const FETCH_COLLABORATOR_ROLES_FOR_COLLABORATOR = "FETCH_COLLABORATOR_ROLES_FOR_COLLABORATOR";

export function fetchCollaborators(filmId, startConnection) {
  if (startConnection && connection.state !== HubConnectionState.Connected) {
    connection.start().then(() => connection.invoke('SubscribeToFilm', filmId));
  }

  return (dispatch) => {
    dispatch({
      type: "FETCH_USERS",
      payload: getApiClient().get(`/film/${filmId}/collaborator`),
    });
  };
}

export function fetchCollaboratorsOverview(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COLLABORATORS_OVERVIEW,
      payload: getApiClient().get(`/film/${filmId}/collaborator/overview`),
    });
  };
}

export function fetchCollaboratorsRoles(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COLLABORATOR_ROLES_FOR_COLLABORATOR,
      payload: getApiClient().get(`/film/${filmId}/collaboratorRole`),
    });
  };
}

export function clearUser() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_USER",
    });
  };
}

export function fetchCollaborator(filmId, userId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_USER",
      payload: getApiClient().get(`/film/${filmId}/collaborator/${userId}`),
    });
  };
}

export function fetchCanAdd(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COLLABORTAROR_CAN_ADD,
      payload: getApiClient().get(`/film/${filmId}/collaborator/canAdd`),
    });
  };
}

export function updateCollaborator(filmId, user) {
  return (dispatch) => {
    let andSent = '';
    if (user.resendInvite) {
      andSent = ' and resent'
    }

    dispatch({
      type: "UPDATE_SINGLE_USER",
      payload: getApiClient().put(`/film/${filmId}/collaborator/${user.id}`, user),
      meta: { user },
    })
      .then((response) => {
        dispatch(showInfo(`Updated${andSent} collaborator ${user.collaboratorFirstName} ${user.collaboratorLastName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating${andSent} collaborator ${user.collaboratorFirstName} ${user.collaboratorLastName}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addCollaborator(filmId, user) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_USER",
      payload: getApiClient().post(`/film/${filmId}/collaborator/`, user),
      meta: { user },
    })
      .then((response) => {
        dispatch(showInfo(`Added collaborator ${user.collaboratorFirstName} ${user.collaboratorLastName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding collaborator ${user.collaboratorFirstName} ${user.collaboratorLastName}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteCollaborator(filmId, user) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_USER",
      payload: getApiClient().delete(`/film/${filmId}/collaborator/${user.id}`),
      meta: { user },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted collaborator ${user.collaboratorFirstName} ${user.collaboratorLastName}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting collaborator ${user.collaboratorFirstName} ${user.collaboratorLastName}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function resendInvite(filmId, user) {
  return (dispatch) => {
    dispatch({
      type: "RESEND_INVITE",
      payload: getApiClient().put(`/film/${filmId}/collaborator/${user.id}/Resend`, {}),
    })
      .then((response) => {
        dispatch(showInfo(`Resent invite to ${user.collaboratorEmailAddress}`));
      })
      .catch((response) => {
        dispatch(showError(`Error resending invite to ${user.collaboratorEmailAddress}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
