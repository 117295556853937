import { CLEAR_SHOOTING_DAY_CATERING_PDF, DOWNLOAD_SHOOTING_DAY_CATERING } from "./actions";

interface ShootingDayCateringState {
  loading: boolean;
  download: any;
  progress: any;
}

const defaultState: ShootingDayCateringState = {
  loading: true,
  download: null,
  progress: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTING_DAY_CATERING_PENDING": {
      return {
        ...state,
        loading: true,
        download: null,
        progress: null,
        errors: null
      };
    }

    case "FETCH_SHOOTING_DAY_CATERING_FULFILLED": {
      return {
        ...state,
        catering: action.payload.data,
        loading: false,
        errors: null,
      };
    }

    case "FETCH_SHOOTING_DAY_CATERING_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors,
      };
    }

    case DOWNLOAD_SHOOTING_DAY_CATERING + "_PENDING": {
      return {
        ...state,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case DOWNLOAD_SHOOTING_DAY_CATERING + "_FULFILLED": {
      return {
        ...state
      };
    }

    case DOWNLOAD_SHOOTING_DAY_CATERING + "_REJECTED": {
      return {
        ...state
      };
    }

    case DOWNLOAD_SHOOTING_DAY_CATERING + "_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case DOWNLOAD_SHOOTING_DAY_CATERING + "_COMPLETE": {
      return {
        ...state,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case DOWNLOAD_SHOOTING_DAY_CATERING + "_ERRORED": {
      return {
        ...state,
        progress: null,
        errors: action.meta.errors
      };
    }

    case CLEAR_SHOOTING_DAY_CATERING_PDF: {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    default:
      return state;
  }
};

export default reducer;
