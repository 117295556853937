import React from "react";
import { useDrag } from "react-dnd";
import { NavLink } from "react-router-dom";
import { ItemTypes } from "./constants";
import CanAccessNavLink from "../../../shared/can-access-nav-link";

function Location(props) {
  const { location, index, filmId, canAccessShootingLocations } = props;

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.LOCATION, location },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  let locationName = location.name;
  if (location.subLocation?.name) {
    locationName += " - " + location.subLocation?.name;
  }

  return (
    <tr
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "grab"
      }}>
      <td>
        <i className="material-icons dark">drag_indicator</i>
        <CanAccessNavLink to={`/films/${filmId}/locations/${location.id}/edit`} canAccess={canAccessShootingLocations}>
          {locationName}
        </CanAccessNavLink>
      </td>
    </tr>
  );
}

export default Location;
