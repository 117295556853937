import OnboardingType from "../../../../../shared/onboarding/onboarding-type";
import { FETCH_COSTUME_OVERVIEW } from "./actions";

interface CharacterOverviewState {
  overview: any;
  loading: boolean;
  readOnly: boolean;
  charactersOnboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'costumes-overview-onboarding-step-1';
export const OnboardingStep2ClassName = 'costumes-overview-onboarding-step-2';
export const OnboardingStep3ClassName = 'costumes-overview-onboarding-step-3';
export const OnboardingStep4ClassName = 'costumes-overview-onboarding-step-4';

const defaultState: CharacterOverviewState = {
  overview: {},
  loading: true,
  readOnly: false,
  charactersOnboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Completed Characters',
      content: `This shows the number of characters with a complete set of information.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_COSTUME_OVERVIEW + "_PENDING": {
      return {
        ...state,
        loading: true,
        overview: null,
        errors: null
      };
    }

    case FETCH_COSTUME_OVERVIEW + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        overview: action.payload.data,
        errors: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
