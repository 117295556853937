import getApiClient from "../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../notifications/ducks/actions";

export const FETCH_USER_COUNTRIES_BY_REGISTRATION_DATE = "FETCH_USER_COUNTRIES_BY_REGISTRATION_DATE";

export function fetchUserCountriesByRegistrationDate() {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_COUNTRIES_BY_REGISTRATION_DATE,
      payload: getApiClient().get(`/admin/UserCountriesByRegisteredDate`)
    });
  };
}
