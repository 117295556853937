import { UPDATE_CALL_SHEET_PERSON_EMAIL_STATUS } from "../../../../middleware/signalRMiddleware";
import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { CANCEL_SHOOTING_DAY } from "./actions";

interface CallSheetState {
  loading: boolean;
  sending: boolean;
  saving: boolean;
  download: any;
  dates: any;
  onboardingSteps: OnboardingType[];
  shootingDaySettings: any;
  progress: any;
  readOnly: boolean;
}

export const OnboardingStep1ClassName = 'shooting-day-settings-onboarding-step-1';
export const OnboardingStep2ClassName = 'shooting-day-settings-onboarding-step-2';
export const OnboardingStep3ClassName = 'shooting-day-settings-onboarding-step-3';
export const OnboardingStep4ClassName = 'shooting-day-settings-onboarding-step-4';
export const OnboardingStep5ClassName = 'shooting-day-settings-onboarding-step-5';
export const OnboardingStep6ClassName = 'shooting-day-settings-onboarding-step-6';
export const OnboardingStep7ClassName = 'shooting-day-settings-onboarding-step-7';

export const ShootingDayActor = "ShootingDayActor";
export const ShootingDayBackgroundActor = "ShootingDayBackgroundActor";
export const ShootingDayCrewMember = "ShootingDayCrewMember";

const defaultState: CallSheetState = {
  loading: false,
  sending: false,
  saving: false,
  download: null,
  dates: null,
  shootingDaySettings: null,
  progress: null,
  readOnly: false,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Call Sheet Sections',
      content: `        
        <p>This allows you choose which sections you would like to include in the generated Call Sheet PDF.</p>
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Weather Sections',
      content: `        
        <p>This allows you to select as much or little details about the weather that is included on the Call Sheet.</p>
      `
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Email Extra Information',
      content: `        
        <p>A standard email will be sent to actors and crew, but you can include extr information specific to this day in the email by entering it into this box. 
        To view what the email will look like, then use the 'Email to yourself' button to get a copy sent to you.</p>
      `
    }, {
      target: '.' + OnboardingStep4ClassName,
      title: 'Include Confirmation',
      content: `        
        <p>This option will include a link button in the email to actors and crew for them to click to confirm reciept. Once clicked this will show in that persons status below.</p>
      `
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Include Script Sides',
      content: `        
        <p>This will include a version of the script in the email, with just the scenes being shot that day.</p>
      `
    },
    {
      target: '.' + OnboardingStep6ClassName,
      title: 'Include Shot List',
      content: `        
      <p>This will include the shot list for crew members.</p>
      `
    },
    {
      target: '.' + OnboardingStep7ClassName,
      title: 'Email Recipients',
      content: `        
        <p>This is a list of all the actors and crew the call sheet emails will be sent to when clicking the 'Email to cast and crew' button. 
        It also shows each persons status, from sent through to confirmed (if using that option). It will also show any email bounces.</p>
        <p>You can also send or resend emails to individuals.</p>        
      `
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    // obsoloete
    case "FETCH_SHOOTING_DAY_CALL_SHEET_DATES_PENDING": {
      return {
        ...state,
        loading: true,
        download: null,
        progress: null
      }
    }

    // obsoloete
    case "FETCH_SHOOTING_DAY_CALL_SHEET_DATES_FULFILLED": {
      return {
        ...state,
        loading: false,
        dates: action.payload.data
      }
    }

    case "FETCH_SHOOTING_DAY_PDF_PENDING": {
      return {
        ...state,
        pdfContent: null,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SHOOTING_DAY_PDF_FULFILLED": {
      return {
        ...state,
        pdfContent: action.payload.data,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SHOOTING_DAY_PDF_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: null
      };
    }

    case "DOWNLOAD_CALL_SHEET_PENDING": {
      return {
        ...state,
        loading: false,
        errors: null,
        downloadProgress: {
          progress: null,
          message: "Queued for generating PDF"
        }
      };
    }

    case "DOWNLOAD_CALL_SHEET_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null
      };
    }

    case "CLEAR_SHOOTING_DAY_PDF": {
      return {
        ...state,
        pdfContent: null,
        loading: false,
        errors: null,
        progress: null
      };
    }

    case "CLEAR_CALL_SHEET_PDF": {
      var download = { ...state.download, downloaded: true };
      return {
        ...state,
        download: download
      };
    }

    case "CLEAR_CALL_SHEET_DOWNLOAD": {
      return {
        ...state,
        download: null,
        progress: null
      };
    }


    case "SEND_SHOOTING_DAY_EMAILS_TO_SELF_PENDING": {
      return {
        ...state,
        sending: true,
        errors: null
      };
    }

    case "SEND_SHOOTING_DAY_EMAILS_TO_SELF_FULFILLED": {
      return {
        ...state,
        sending: false,
        errors: null,
        shootingDaySettings: { ...state.shootingDaySettings, sendToSelf: false, sendToMembers: false, sendToPerson: null },
        progress: { progress: null, message: "Queued for sending" }
      };
    }

    case "SEND_SHOOTING_DAY_EMAILS_TO_SELF_REJECTED": {
      return {
        ...state,
        sending: false,
        errors: action.payload.data.errors
      };
    }

    case "SEND_SHOOTING_DAY_EMAILS_PENDING": {
      const shootingDaySettings = { ...state.shootingDaySettings };

      shootingDaySettings.actors.forEach(actor => {
        actor.sending = true;
      });
      shootingDaySettings.backgroundActors.forEach(backgroundActor => {
        backgroundActor.sending = true;
      });

      shootingDaySettings.crewMembers.forEach(crewMember => {
        crewMember.sending = true;
      });


      return {
        ...state,
        shootingDaySettings,
        sending: true,
        errors: null
      };
    }

    case "SEND_SHOOTING_DAY_EMAILS_FULFILLED": {
      return {
        ...state,
        sending: false,
        errors: null,
        shootingDaySettings: { ...state.shootingDaySettings, sendToSelf: false, sendToMembers: false, sendToPerson: null },
        progress: { progress: null, message: "Queued for sending" }
      };
    }

    case "SEND_SHOOTING_DAY_EMAILS_REJECTED": {
      return {
        ...state,
        sending: false,
        errors: action.payload.data.errors
      };
    }

    case "SEND_SHOOTING_DAY_EMAILS_TO_PERSON_FULFILLED": {
      return {
        ...state,
        sending: false,
        errors: null,
        shootingDaySettings: { ...state.shootingDaySettings, sendToSelf: false, sendToMembers: false, sendToPerson: null },
        progress: { progress: null, message: "Queued for sending" }
      };
    }

    case "SEND_SHOOTING_DAY_EMAILS_TO_PERSON_PENDING": {
      const { personId, personType } = action.meta;
      const shootingDaySettings = { ...state.shootingDaySettings };
      if (personType === ShootingDayActor) {
        shootingDaySettings.actors.forEach((actor: any) => {
          if (actor.id === personId) {
            actor.sending = true;
          } else {
            actor.disabled = true;
          }
        });
        shootingDaySettings.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = true;
        });
        shootingDaySettings.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = true;
        });
      } else if (personType === ShootingDayBackgroundActor) {
        shootingDaySettings.backgroundActors.forEach((backgroundActor: any) => {
          if (backgroundActor.id === personId) {
            backgroundActor.sending = true;
          } else {
            backgroundActor.disabled = true;
          }
        });

        shootingDaySettings.actors.forEach((actor: any) => {
          actor.disabled = true;
        });
        shootingDaySettings.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = true;
        });
      } else if (personType === ShootingDayCrewMember) {
        shootingDaySettings.crewMembers.forEach((crewMember: any) => {
          if (crewMember.id === personId) {
            crewMember.sending = true;
          } else {
            crewMember.disabled = true;
          }
        });

        shootingDaySettings.actors.forEach((actor: any) => {
          actor.disabled = true;
        });
        shootingDaySettings.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = true;
        });
      }

      return {
        ...state,
        shootingDaySettings: shootingDaySettings,
        errors: null
      };
    }

    case "SEND_SHOOTING_DAY_EMAILS_TO_PERSON_REJECTED": {
      return {
        ...state,
        sending: false,
        errors: action.payload.data.errors
      };
    }

    case "UPDATE_SEND_CALL_SHEET_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case "UPDATE_SEND_CALL_SHEET_ERRORED": {
      const shootingDaySettings = { ...state.shootingDaySettings };
      shootingDaySettings.actors.forEach((actor) => { actor.sending = false; actor.disabled = false; });
      shootingDaySettings.backgroundActors.forEach((backgroundActor) => { backgroundActor.sending = false; backgroundActor.disabled = false; });
      shootingDaySettings.crewMembers.forEach((crewMember) => { crewMember.sending = false; crewMember.disabled = false; });
      const progress = { ...state.progress, message: action.meta.message, failed: true }
      return {
        ...state,
        progress,
        sending: false,
        shootingDaySettings
      };
    }

    case UPDATE_CALL_SHEET_PERSON_EMAIL_STATUS: {
      const { personId, personType, status, statusDescription, statusChangedDate } = action.meta;
      const shootingDaySettings = { ...state.shootingDaySettings };
      if (personType === ShootingDayActor) {
        shootingDaySettings.actors.forEach((actor: any) => {
          if (actor.id === personId) {
            actor.statusDescription = statusDescription;
            actor.status = status;
            actor.lastStatusChanged = statusChangedDate;
            actor.sending = false;
          } else {
            actor.disabled = false;
          }
        });

        shootingDaySettings.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = false;
        });
        shootingDaySettings.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = false;
        });
      } else if (personType === ShootingDayBackgroundActor) {
        shootingDaySettings.backgroundActors.forEach((backgroundActor: any) => {
          if (backgroundActor.id === personId) {
            backgroundActor.statusDescription = statusDescription;
            backgroundActor.status = status;
            backgroundActor.lastStatusChanged = statusChangedDate;
            backgroundActor.sending = false;
          } else {
            backgroundActor.disabled = false;
          }
        });

        shootingDaySettings.actors.forEach((actor: any) => {
          actor.disabled = false;
        });
        shootingDaySettings.crewMembers.forEach((crewMember: any) => {
          crewMember.disabled = false;
        });
      } else if (personType === ShootingDayCrewMember) {
        shootingDaySettings.crewMembers.forEach((crewMember: any) => {
          if (crewMember.id === personId) {
            crewMember.statusDescription = statusDescription;
            crewMember.status = status;
            crewMember.lastStatusChanged = statusChangedDate;
            crewMember.sending = false;
          } else {
            crewMember.disabled = false;
          }
        });

        shootingDaySettings.actors.forEach((actor: any) => {
          actor.disabled = false;
        });
        shootingDaySettings.backgroundActors.forEach((backgroundActor: any) => {
          backgroundActor.disabled = false;
        });
      }

      return {
        ...state,
        shootingDaySettings: shootingDaySettings
      };
    }

    case "DOWNLOAD_CALL_SHEET_PROGRESS": {
      return {
        ...state,
        downloadProgress: action.meta.progress
      };
    }

    case "DOWNLOAD_CALL_SHEET_COMPLETE": {
      return {
        ...state,
        loading: false,
        downloadProgress: null,
        download: {
          url: action.meta.url,
          fileName: action.meta.fileName,
          downloaded: false
        }
      };
    }

    case "DOWNLOAD_CALL_SHEET_ERRORED": {
      return {
        ...state,
        errors: action.meta?.errors ?? { "": ["Failed to generate PDF"] },
        downloadProgress: null,
        download: null
      };
    }

    case "FETCH_SHOOTING_DAY_CALL_SHEET_SETTINGS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SHOOTING_DAY_CALL_SHEET_SETTINGS_FULFILLED": {
      return {
        ...state,
        shootingDaySettings: { ...action.payload.data, sendToSelf: false, sendToMembers: false, sendToPerson: null },
        dates: { callSheetSentDateTime: action.payload.data.callSheetSentDateTime, callSheetSend: action.payload.data.callSheetSend },
        hasLocation: action.payload.data.hasLocation,
        readOnly: action.payload.data.readOnly,
        canAccessActors: action.payload.data.canAccessActors,
        canAccessBackgroundActors: action.payload.data.canAccessBackgroundActors,
        canAccessCrew: action.payload.data.canAccessCrew,
        loading: false
      };
    }

    case "FETCH_SHOOTING_DAY_CALL_SHEET_SETTINGS_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "UPDATE_SHOOTING_DAY_CALL_SHEET_SETTINGS_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case "UPDATE_SHOOTING_DAY_CALL_SHEET_SETTINGS_FULFILLED": {
      return {
        ...state,
        shootingDaySettings: action.meta.shootingDaySettings,
        saving: false,
        errors: null,
        pristine: true
      };
    }

    case "UPDATE_SHOOTING_DAY_CALL_SHEET_SETTINGS_REJECTED": {
      return {
        ...state,
        shootingDaySettings: action.meta.shootingDaySettings,
        errors: action.payload.response.data.errors,
        saving: false
      };
    }

    case CANCEL_SHOOTING_DAY + "_PENDING": {
      const shootingDaySettings = { ...state.shootingDaySettings };

      shootingDaySettings.actors.forEach(actor => {
        actor.sending = true;
      });
      shootingDaySettings.backgroundActors.forEach(backgroundActor => {
        backgroundActor.sending = true;
      });

      shootingDaySettings.crewMembers.forEach(crewMember => {
        crewMember.sending = true;
      });

      return {
        ...state,
        shootingDaySettings,
        sending: true,
        errors: null
      };
    }

    case CANCEL_SHOOTING_DAY + "_FULFILLED": {
      const shootingDaySettings = { ...state.shootingDaySettings };

      shootingDaySettings.actors.forEach(actor => {
        actor.sending = false;
      });
      shootingDaySettings.backgroundActors.forEach(backgroundActor => {
        backgroundActor.sending = false;
      });

      shootingDaySettings.crewMembers.forEach(crewMember => {
        crewMember.sending = false;
      });

      return {
        ...state,
        shootingDaySettings,
        sending: false,
        errors: null
      };
    }

    case CANCEL_SHOOTING_DAY + "_REJECTED": {
      debugger;
      return {
        ...state,
        sending: false,
        errors: action.payload.response.data.errors
      };
    }

    default:
      return state;
  }
};

export default reducer;
