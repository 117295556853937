import { connect } from "react-redux";
import CurrencyInput from "./currency-input";

function CurrencyInputContainer(props) {
  const { value, onChange, readOnly, placeholder, currencySymbol, disabled } = props;

  return (
    <CurrencyInput
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      placeholder={placeholder}
      currencySymbol={currencySymbol}
      disabled={disabled} />
  );
}

const mapStateToProps = function (state) {
  return {
    currencySymbol: state.filmsState?.userFilm?.currencySymbol
  };
};

export default connect(mapStateToProps)(CurrencyInputContainer);
