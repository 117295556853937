import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { invitation } from "./ducks/actions";
import Loader from "../../shared/loader";
import Errors from "../../shared/errors";
import BoxWrapper from "../../account/box-wrapper";

function Invitation(props) {
  const { data, referralCode } = props.match.params;
  const { invitation, loading, loggedIn, filmId, errors, sentData } = props;

  useEffect(() => {
    if (!sentData && data && referralCode) {
      invitation(data, referralCode);
    }
  }, [data, sentData, invitation, referralCode]);

  if (loading) {
    return <Loader />;
  }

  return (
    <BoxWrapper width={10}>
      <div className="blade-content wide">
        {errors && <Errors errors={errors} />}
        {!loggedIn && (
          <div>
            <h2 className="menu-label">Film invitation</h2>
            Please <NavLink to={"/login"}>login</NavLink> or{" "}
            <NavLink to={"/register"}>register</NavLink> to access the film.
          </div>
        )}
        {loggedIn && !errors && (
          <div>
            Film has been added to your list. Click{" "}
            <NavLink to={`/films/${filmId}`}>here</NavLink> to go to it.
          </div>
        )}
        {loggedIn &&
          errors &&
          errors[""] &&
          errors[""].indexOf("User already added to film.") !== -1 && (
            <div>
              Click <NavLink to={`/films/${filmId}`}>here</NavLink> to go to it.
            </div>
          )}
      </div>
    </BoxWrapper>
  );
}

function mapStateToProps(state) {
  const { invitationState } = state;
  return {
    loading: invitationState.loading,
    loggedIn: invitationState.loggedIn,
    filmId: invitationState.filmId,
    errors: invitationState.errors,
    sentData: invitationState.sentData
  };
}

export default connect(mapStateToProps, {
  invitation
})(Invitation);
