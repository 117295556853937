import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import AddCrewScheduleEvent from "./add-crew-event-container";
import AddSceneEvent from "./add-scene-event-container";
import {
  deleteShootingDayScheduleEvent,
  fetchShootingDaysScheduleEventList
} from "./ducks/actions";
import EditCrewScheduleEvent from "./edit-crew-event-container";
import EditSceneEvent from "./edit-scene-event-container";
import List from "./list";

function ScheduleContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchShootingDaysScheduleEventList,
    deleteShootingDayScheduleEvent,
    loading,
    shootingDayScheduleEvents,
    totalPages,
    errors
  } = props;
  const tabs = ["Add Crew Event", "Add Scene Event", "Edit Event"];
  const [tabIndex, setTabIndex] = React.useState(1);
  const [currentEvent, setCurrentEvent] = React.useState();

  useEffect(() => {
    fetchShootingDaysScheduleEventList(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function onChangeTab(tabIdx: number) {
    if (tabIdx === 2) return;
    setTabIndex(tabIdx);
  }

  function renderLoading() {
    return <Loader />;
  }

  function onSelectEvent(event) {
    console.log(event);
    setTabIndex(2);
    setCurrentEvent(event);
  }

  function onDeleteEvent(event) {
    deleteShootingDayScheduleEvent(filmId, shootingdayId, event);
  }

  function isCurrentEvent() {
    const ev: any = currentEvent;
    if (typeof ev !== "undefined" && ev !== null) {
      if (ev!.type !== 1) {
        return true;
      }
    }
    return false;
  }

  function isCurrentSceneEvent() {
    const ev: any = currentEvent;
    if (typeof ev !== "undefined" && ev !== null) {
      if (ev!.type === 1) {
        return true;
      }
    }
    return false;
  }

  function renderShootingDays(
    shootingDayScheduleEvents,
    totalPages,
    filmId,
    shootingDayId
  ) {
    return (
      <div className="blade-content wider">
        <List
          shootingDayScheduleEvents={shootingDayScheduleEvents}
          totalPages={totalPages}
          filmId={filmId}
          shootingDayId={shootingDayId}
          onDeleteEvent={onDeleteEvent}
          onSelectEvent={onSelectEvent}
        />

        <br />
        <p className="menu-label">Add to schedule</p>

        <div className="tabs">
          <ul>
            {tabs.map((tab, idx) => {
              const className = tabIndex === idx ? "is-active" : undefined;
              return (
                <li className={className} key={idx}>
                  <a onClick={() => onChangeTab(idx)}>{tab}</a>
                </li>
              );
            })}
          </ul>
        </div>

        {tabIndex === 2 && currentEvent && isCurrentEvent() && (
          <EditCrewScheduleEvent
            filmId={filmId}
            shootingdayId={shootingdayId}
            currentEvent={currentEvent}
          />
        )}
        {tabIndex === 2 && currentEvent && isCurrentSceneEvent() && (
          <EditSceneEvent
            filmId={filmId}
            shootingdayId={shootingdayId}
            currentEvent={currentEvent}
          />
        )}
        {tabIndex === 0 && (
          <AddCrewScheduleEvent filmId={filmId} shootingdayId={shootingdayId} />
        )}
        {tabIndex === 1 && (
          <AddSceneEvent filmId={filmId} shootingdayId={shootingdayId} />
        )}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderShootingDays(
        shootingDayScheduleEvents,
        totalPages,
        filmId,
        shootingdayId
      );
}

function mapStateToProps(state) {
  const { scheduleState } = state.shootingdaysState;
  return {
    shootingDayScheduleEvents: scheduleState.scheduleEventList,
    totalPages: scheduleState.totalPages,
    loading: scheduleState.loading,
    errors: scheduleState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDaysScheduleEventList,
  deleteShootingDayScheduleEvent
})(ScheduleContainer);
