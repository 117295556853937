const ViewFeatures = () => {
    return (
        <div className="view-feature-container-container">
            <div className="view-feature-container">
                <a href="#features" className="view-feature-link">
                    <div className="view-feature">View features</div>
                    <img className="view-feature-image" src="/images/marketing/down-arrow.svg" alt="down arrow" />
                </a>
            </div>
        </div>
    )
}

export default ViewFeatures;