import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../shared/private-route";
import AddBackgroundCharacter from "./add";
import EditBackgroundCharacter from "./edit";
import ListBackgroundCharactersContainer from "./list-container";
import OverviewContainer from "./overview/container";
import BackgroundCharacterMenu from "./menu";
import BackgroundCharacterScenes from "./scenes/scenes";
import BackgroundCharacterProps from "./props/container";
import BackgroundCharacterCostumes from "./costumes/container";
import BackgroundCharacterMakeup from "./makeup/container";

const BackgroundCharacterRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/background-characters"
        component={ListBackgroundCharactersContainer}
      />
      <PrivateRoute path="/films/:filmId/background-characters/:backgroundCharacterId" component={BackgroundCharacterMenu} />
      <PrivateRoute
        exact
        path="/films/:filmId/background-characters/add"
        component={AddBackgroundCharacter}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/background-characters/overview"
        component={OverviewContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/background-characters/:backgroundCharacterId"
        component={EditBackgroundCharacter}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/background-characters/:backgroundCharacterId/edit"
        component={EditBackgroundCharacter}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/background-characters/:backgroundCharacterId/scenes"
        component={BackgroundCharacterScenes}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/background-characters/:backgroundCharacterId/props"
        component={BackgroundCharacterProps}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/background-characters/:backgroundCharacterId/costumes"
        component={BackgroundCharacterCostumes}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/background-characters/:backgroundCharacterId/makeup"
        component={BackgroundCharacterMakeup}
      />
    </>
  );
};

export default BackgroundCharacterRoutes;
