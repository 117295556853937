import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import './nav.scss';

const Nav = ({ tranparent }: any) => {

    const [phoneMenuVisible, setPhoneMenuVisible] = useState(false);

    const burgerClick = () => {
        setPhoneMenuVisible(!phoneMenuVisible);
    };

    const hideMenu = () => {
        setPhoneMenuVisible(false);
    };

    return (
        <nav className={`navbar anonymous-navbar ${(tranparent && !phoneMenuVisible) ? "is-transparent" : "white"} is-fixed-top`}>
            <div className="marketing-navbar-brand navbar-brand">
                <a className="navbar-item" href="/#home">
                    <div className={`nav-logo ${(tranparent && !phoneMenuVisible) ? "invisible" : ""}`}>
                        <img src="/images/logo-white.svg" alt="Filmanize: film production management" />
                        <span className="tag is-purple is-beta">Beta</span>
                    </div>
                </a>

                <div className={`navbar-burger navbar-burger-anonymous ${phoneMenuVisible ? "open" : ""}`} data-target="navbarFilmanize" onClick={burgerClick}>
                    <span className="bar1"></span>
                    <span className="bar2"></span>
                    <span className="bar3"></span>
                </div>
            </div>

            <div id="navbarFilmanize-anonymous" className={`navbar-menu ${phoneMenuVisible ? "is-active" : ""}`}>
                <div className="navbar-start">

                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <NavLink to={`/contact`} className={`button anonymous-hollow-button ${!tranparent || phoneMenuVisible ? "white-button-text" : ""}`} activeClassName="is-active">Contact</NavLink>
                    </div>
                    <div className="navbar-item">
                        <NavLink to={`/register`} className={`button anonymous-hollow-button ${!tranparent || phoneMenuVisible ? "white-button-text" : ""}`} activeClassName="is-active">Try the Beta</NavLink>
                    </div>
                    <div className="navbar-item">
                        <NavLink to={'/login'} className={`button anonymous-hollow-button ${!tranparent || phoneMenuVisible ? "white-button-text" : ""}`} activeClassName="is-active">Login</NavLink>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Nav;