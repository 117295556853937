import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { sortByName } from "../../../../shared/utility";

interface CharacterOverviewState {
  overview: any;
  loading: boolean;
  readOnly: boolean;
  characterOrderBy: string;
  charactersOnboardingSteps: OnboardingType[];
}

export const OnboardingStep1ClassName = 'characters-overview-onboarding-step-1';
export const OnboardingStep2ClassName = 'characters-overview-onboarding-step-2';
export const OnboardingStep3ClassName = 'characters-overview-onboarding-step-3';
export const OnboardingStep4ClassName = 'characters-overview-onboarding-step-4';

const defaultState: CharacterOverviewState = {
  overview: {},
  loading: true,
  readOnly: false,
  characterOrderBy: "Name",
  charactersOnboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Completed Characters',
      content: `This shows the number of characters with a complete set of information.`
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Character Display Order',
      content: `Click here to change the order in which the characters are displayed.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Manage Characters',
      content: `        
        <p>Link duplicate characters (e.g. 'William', 'Bill') by dragging the duplicate character's name onto the preferred character's name to link them.</p>        
        <p>Made a mistake? Click <i class="material-icons">cancel</i> to unassign.</p>
        <p><span class="material-icons">timelapse</span> Indicates characters with incomplete information.</p>
      `
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Assign Actors to Characters',
      content: `<p>Assign actors to characters by dragging the actor's name from the list on the right into the 'Actor Name' column for the character.</p>
                <p>You can also replace the Actor for a Character by dragging the new Actor's name from the list onto the existing Actor Name.</p>`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_CHARCTER_OVERVIEW_PENDING": {
      return {
        ...state,
        loading: true,
        overview: null,
        errors: null
      };
    }

    case "FETCH_CHARCTER_OVERVIEW_FULFILLED": {
      return {
        ...state,
        loading: false,
        overview: action.payload.data,
        characterOrderBy: "Name",
        errors: null
      };
    }

    case "FETCH_CHARCTER_OVERVIEW_REJECTED": {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

    case "CLEAR_CHARACTER_OVERVIEW": {
      return {
        ...state,
        loading: false,
        overview: null,
        errors: null,
        warnings: null
      };
    }

    case "ASSIGN_ACTOR_TO_CHARCTER": {
      const actor = action.meta.actor;
      let characters = [...state.overview.characters];
      const character = characters.find(
        (c) => c.id === action.meta.character.id
      );
      character.actorId = actor.id;
      character.actorName = actor.name;
      character.actorMandyUrl = actor.mandyUrl;
      character.actorStarNowUrl = actor.starNowUrl;

      let overview = { ...state.overview, characters };
      return {
        ...state,
        overview: overview
      };
    }

    case "UNASSIGN_ACTOR_TO_CHARCTER": {
      let characters = [...state.overview.characters];
      const character = characters.find(
        (c) => c.id === action.meta.characterId
      );
      character.actorId = null;
      character.actorName = null;
      character.actorMandyUrl = null;
      character.actorStarNowUrl = null;

      let overview = { ...state.overview, characters };
      return {
        ...state,
        overview: overview
      };
    }

    case "ALIAS_CHARCTER": {
      const aliasCharacter = action.meta.aliasCharacter;
      const mainCharacter = action.meta.mainCharacter;
      let characters = [...state.overview.characters];
      const character = characters.find((c) => c.id === mainCharacter.id);
      const aliasCharacterIndex = characters.findIndex(
        (c) => c.id === aliasCharacter.id
      );

      character.aliases = [
        ...character.aliases,
        aliasCharacter
      ];
      characters[aliasCharacterIndex].aliases = [];

      characters.splice(aliasCharacterIndex, 1);

      let overview = { ...state.overview, characters };
      return {
        ...state,
        overview: overview
      };
    }

    case "UNALIAS_CHARCTER": {
      const aliasCharacter = action.meta.aliasCharacter;
      const mainCharacter = action.meta.mainCharacter;
      let characters = [...state.overview.characters];
      const character = characters.find((c) => c.id === mainCharacter.id);

      const aliasCharacterIndex = character.aliases.findIndex(
        (a) => a.id === aliasCharacter.id
      );
      character.aliases.splice(aliasCharacterIndex, 1);

      characters.push(aliasCharacter);

      sortByName(characters);

      let overview = { ...state.overview, characters };
      return {
        ...state,
        overview: overview
      };
    }

    case "SAVE_UNASSIGN_ACTOR_TO_CHARCTER_PENDING": {
      return {
        ...state,
        errors: null,
        warnings: null
      };
    }

    case "SAVE_UNASSIGN_ACTOR_TO_CHARCTER_FULFILLED": {
      return {
        ...state,
        errors: null,
        warnings: null
      };
    }

    case "SAVE_UNASSIGN_ACTOR_TO_CHARCTER_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }

    case "SAVE_ASSIGN_ACTOR_TO_CHARCTER_PENDING": {
      return state;
    }

    case "SAVE_ASSIGN_ACTOR_TO_CHARCTER_FULFILLED": {
      return {
        ...state,
        warnings: action.payload.data.hasWarnings ? action.payload.data.warnings : null,
        errors: null
      };
    }

    case "SAVE_ASSIGN_ACTOR_TO_CHARCTER_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors
      };
    }

    case "SAVE_ALIAS_CHARACTER": {
      return state;
    }

    case "SAVE_REMOVE_ALIAS_CHARACTER": {
      return state;
    }

    case "CHARACTERS_OVERVIEW_ORDERBY_SCENES": {
      let characters = [...state.overview.characters];
      characters.sort((a, b) => {
        return b.numberOfScenes - a.numberOfScenes;
      });
      let overview = { ...state.overview, characters };
      return {
        ...state,
        overview: overview,
        characterOrderBy: 'Scenes'
      };
    }

    case "CHARACTERS_OVERVIEW_ORDERBY_NAME": {
      let characters = [...state.overview.characters];
      characters.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      let overview = { ...state.overview, characters };
      return {
        ...state,
        overview: overview,
        characterOrderBy: 'Name'
      };
    }

    default:
      return state;
  }
};

export default reducer;
