import * as React from "react";

interface RadioProps {
  checked: boolean;
  onChange: any;
  text?: any;
  value: any;
  id?: any;
  disabled?: boolean;
  required?: boolean;
}

const Radio: React.FC<RadioProps> = (props) => {
  const { checked, onChange, text, value, id, disabled, required } = props;
  return (
    <div
      id={id}
      className="modern-radio-container"
      style={{ cursor: disabled ? "default" : "pointer" }}
      onClick={() => {
        if (!disabled) {
          onChange(value);
        }
      }}>
      <div className={`radio-outer-circle ${!checked && "unselected"}`}>
        <div
          className={`radio-inner-circle ${!checked && "unselected-circle"} ${disabled && "disabled"}`}
        />
      </div>
      {text && <div className="helper-text">{text}{required ? <span className="required">&nbsp;*</span> : null}</div>}
    </div>
  );
};

export default Radio;
