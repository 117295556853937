interface ForgotPasswordState {
  loading: boolean;
  user: any;
  submitted: boolean;
  errors: any;
}

const defaultState: ForgotPasswordState = {
  loading: false,
  submitted: false,
  user: {},
  errors: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "CHANGE_PASSWORD_CLEAR_FORM": {      
      return {
        ...state,
        loading: false,
        errors: null,
        user: null,
        submitted: false
      };
    }

    case "CHANGE_PASSWORD_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null,
        submitted: false
      };
    }
    
    case "CHANGE_PASSWORD_FULFILLED": {        
      return {
        ...state,      
        loading: false,
        submitted: true
      };
    }

    case "CHANGE_PASSWORD_REJECTED": {
      return {
        ...state,
        user: action.meta.user,
        errors: action.payload.response.data.errors,
        loading: false,
        submitted: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
