import React, { useEffect } from "react";
import { connect } from "react-redux";
import Errors from "../../../shared/errors";
import Loader from "../../../shared/loader";
import {
  fetchScenesScriptDays,
  addScriptDay,
  deleteScriptDay,
  updateScenesScriptDays,
  updateDayOrder,
  updateOrder,
  updateSceneOrder,
  toggleSelectionInGroup,
  setDraggingSceneId,
  unselectAll
} from "./ducks/actions";
import DayList from "./list-days";
import { Prompt } from "react-router-dom";
import Onboarding from '../../../shared/onboarding/onboarding';
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ScenesScriptDaysContainer(props) {
  const filmId = props.match.params.filmId;
  const {
    fetchScenesScriptDays,
    updateScenesShootingOrder,
    addScriptDay,
    deleteScriptDay,
    days,
    errors,
    loading,
    updateOrder,
    updateDayOrder,
    updateSceneOrder,
    updateScenesScriptDays,
    selectedSceneIds,
    draggingSceneId,
    toggleSelectionInGroup,
    setDraggingSceneId,
    unselectAll,
    pristine,
    readOnly,
    onboardingSteps,
    canAccessSceneSettings
  } = props;


  useEffect(() => {
    fetchScenesScriptDays(filmId);
  }, []);

  const onUpdateScenesShootingOrder = (scenes) => {
    updateScenesShootingOrder(filmId, scenes);
  };

  const onAddScriptDay = () => {
    addScriptDay();
  };

  const onDeleteScriptDay = (dayNumber) => {
    deleteScriptDay(dayNumber);
  };

  const onUpdateScenesShootingOrderDays = (days) => {
    updateScenesScriptDays(filmId, days);
  };

  function renderLoading() {
    return <Loader />;
  }


  const toggleSelection = (taskId) => {
    /*
    const selectedTaskIds: Id[] = this.state.selectedTaskIds;
    const wasSelected: boolean = selectedTaskIds.includes(taskId);

    const newTaskIds: Id[] = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedTaskIds.length > 1) {
        return [taskId];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    this.setState({
      selectedTaskIds: newTaskIds,
    });
    */
  };

  // This behaviour matches the MacOSX finder selection
  const multiSelectTo = (newSceneId) => {
    /*
    const updated: ?(Id[]) = multiSelect(
      this.state.entities,
      this.state.selectedTaskIds,
      newTaskId,
    );

    if (updated == null) {
      return;
    }

    this.setState({
      selectedTaskIds: updated,
    });
    */
  };

  function renderScenes(days) {
    return (
      <ScrollIntoView
        path="/films/:filmId/scenes/script-days"
        loading={loading}>
        <div className="blade-content widest">
          <p className="menu-label">Script Days</p>
          {errors && <Errors errors={errors} />}
          <Prompt when={!pristine} message="" />
          {days && days.length > 0 && (
            <DayList
              days={days}
              filmId={filmId}
              updateSceneOrder={updateSceneOrder}
              addScriptDay={onAddScriptDay}
              deleteScriptDay={onDeleteScriptDay}
              updateDayOrder={updateDayOrder}
              onUpdateScenesScriptDays={onUpdateScenesShootingOrderDays}
              draggingSceneId={draggingSceneId}
              selectedSceneIds={selectedSceneIds}
              toggleSelection={toggleSelection}
              toggleSelectionInGroup={toggleSelectionInGroup}
              multiSelectTo={multiSelectTo}
              unselectAll={unselectAll}
              readOnly={readOnly}
              canAccessSceneSettings={canAccessSceneSettings}
            />
          )}
          <Onboarding onboardingSteps={onboardingSteps} section="Script Days" />
        </div>
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderScenes(days);
}

function mapStateToProps(state) {
  return {
    days: state.scenesState.scriptDaysState.days,
    readOnly: state.scenesState.scriptDaysState.readOnly,
    canAccessSceneSettings: state.scenesState.scriptDaysState.rcanAccessSceneSettings,
    loading: state.scenesState.scriptDaysState.loading,
    errors: state.scenesState.scriptDaysState.errors,
    selectedSceneIds: state.scenesState.scriptDaysState.selectedSceneIds,
    draggingSceneId: state.scenesState.scriptDaysState.draggingSceneId,
    pristine: state.scenesState.scriptDaysState.pristine,
    onboardingSteps: state.scenesState.scriptDaysState.onboardingSteps
  };
}

export default connect(mapStateToProps, {
  fetchScenesScriptDays,
  addScriptDay,
  deleteScriptDay,
  updateScenesScriptDays,
  updateDayOrder,
  updateOrder,
  updateSceneOrder,
  toggleSelectionInGroup,
  setDraggingSceneId,
  unselectAll
})(ScenesScriptDaysContainer);
