import React from 'react';
import Slider, { SliderTooltip } from 'rc-slider';
import "rc-slider/assets/index.css";
import "./input-range.scss";
import { CSSProperties } from 'react';

const InputSlider = (props: any) => {
    const { value, onChange, maxValue, minValue, unit, disabled } = props;

      const onOnChange = (value) => {
        onChange(value);
      };

      const handleStyle : CSSProperties = {
        height: 16,
        width: 16,
        marginTop: -8,
        backgroundColor: "#947eed",
        border: 0
      };

      const Handle = Slider.Handle;

      const  handle = props => {
        const { value, dragging, index, ...restProps } = props;
        return (
          
            <Handle value={props.value} style={{color: "red"}} {...restProps} />
          
        );
      };

    return (
      <div className="level">
          <div className="level-item slider-value" style={{minWidth: unit ? "2.5rem" : "2rem"}}>{value}{unit}</div>
          <div className="level-item">
            <Slider
              min={minValue}
              max={maxValue}        
              onChange={onOnChange}
              value={value}
              handleStyle={handleStyle}
              railStyle={{backgroundColor: "#040f21"}}
              trackStyle={{backgroundColor: "#947eed"}}
              handle={handle}
              disabled={disabled}
            />
          </div>
      </div>
    )
}

export default InputSlider;