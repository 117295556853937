import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "./../../../../shared/loader";
import { fetchTake } from "./ducks/actions";

function ViewTake(props) {
  const { filmId, sceneId, shotId, takeId } = props.match.params;
  const { fetchTake, loadingTake, take, errors } = props;

  useEffect(() => {
    fetchTake(filmId, sceneId, shotId, takeId);
  }, [filmId, sceneId, shotId, takeId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderTake(take) {
    return (
      <div className="blade-content">
        <p>Id: {take.id}</p>
        <p>audioFileNumber: {take.audioFileNumber}</p>
        <p>cameras: {take.cameras}</p>
        <p>clappedTime: {take.clappedTime}</p>
        <p>filter: {take.filter}</p>
        <p>mos: {take.mos}</p>
        <p>number: {take.number}</p>
        <p>rating: {take.rating}</p>
        <p>success: {take.success}</p>
        <p>timeCode: {take.timeCode}</p>
      </div>
    );
  }

  return take && !loadingTake ? renderTake(take) : renderLoading();
}

function mapStateToProps(state) {
  return {
    take: state.takesState.take,
    loadingTake: state.takesState.loadingTake,

    errors: state.takesState.errors,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchTake,
  })(ViewTake),
);
