import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, matchPath } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Errors from "../../shared/errors";
import FormErrors from "../../shared/form-errors";
import {  
  renderTextField,
  renderTextAreaField,
  renderCheckbox,  
  renderSingleReactSelect,
  renderCkEditorField
} from "../../shared/form-helpers";
import required, { maxDecimalValue } from "../../shared/validation";

let LeaveFeedbackForm = (props: any) => {
   const {
    handleSubmit,
    pristine,
    submitFailed,
    invalid,
    change,
    pageSections,
    fetchFeatureRequests,
    featureRequestSaving,
    featureRequestSent,
    allowShowValue,
    errors,
    priorities,
    prioritiesLoading
  } = props;
  
  const location = useLocation();
  useEffect(() => {    
    pageSections.forEach((pageSection) => {
      if (matchPath(location.pathname, { path: pageSection.path, exact: true })) {       
        change("section", pageSection.name);
        fetchFeatureRequests(pageSection.name);
      }
    });
  }, [location, pageSections]);

  const pritoryOptions = (priorities || []).map((item) => {
    return { value: item.value, label: item.name };
  });

  return (
    <>
    {featureRequestSent && <div>Thank you for submitting a feature request. 
      {allowShowValue && <p>Other users will now be able to vote on it. This will help influence the order in which they are implemented. </p>} 
      {/* You will be notified if anyone votes on your feature request. */}
      {!allowShowValue && <p>Someone from the Filmanize team will be in contact with you shortly.</p>} 
      </div>}
      <Errors errors={errors}/>
      {!featureRequestSent && <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="mb-1">
          Please check the existing feature requests below to make sure you are not duplicating one that already exists. Instead add a vote and/or comment to the existing one to increase it's likelyhood of being implemented.
        </div>
        <Field
          name="section"
          component={renderSingleReactSelect}
          options={pageSections.map((pageSection) => {
            return { value: pageSection.name, label: pageSection.name };
          })}
          label="Section"
          validate={[required]}
          onChange={(value) => fetchFeatureRequests(value)}
          required></Field>

        <Field
          name="title"
          component={renderTextField}
          className="input-white-background"
          label="Title"
          validate={[required]}
          required></Field>

        <Field
          name="description"
          component={renderCkEditorField}
          className="input-white-background"
          label="Description"
          validate={[required]}
          required></Field>

        <Field
          name="priority"
          component={renderSingleReactSelect}
          className="input-white-background"
          options={pritoryOptions}
          label="Priority"          
          required />

        <Field
          name="allowShow"
          component={renderCheckbox}
          label="Are you happy for this to be voted on?" />

        {allowShowValue && <div className="mt-1">
          <Field
            name="anonymous"
            component={renderCheckbox}
            label="Post anonymously?" />
          </div>}

        <div className="buttons mt-2">
          <button
            type="submit"
            className={`button is-primary is-on-white ${featureRequestSaving ? "is-loading" : ""}`}
            disabled={pristine || featureRequestSaving}>
            Submit
          </button>
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>}
    </>
  );
}

LeaveFeedbackForm = reduxForm({
  form: "leaveFeedbackForm",
  enableReinitialize: true
})(LeaveFeedbackForm);

// Decorate with connect to read form values
const selector = formValueSelector('leaveFeedbackForm'); // <-- same as form name
const LeaveFeedbackFormConnect = connect(
  state => {
    // can select values individually
    const allowShowValue = selector(state, 'allowShow');        
    return {
      allowShowValue
    }
  }
)(LeaveFeedbackForm);
  
export default LeaveFeedbackFormConnect