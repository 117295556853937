import getApiClient from "./../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "./../../../notifications/ducks/actions";

export const FETCH_COMMERCIAL_SCRIPT = "FETCH_COMMERCIAL_SCRIPT";
export const FETCH_ALL_CHARACTERS_FOR_COMMERCIAL_SCRIPT = "FETCH_ALL_CHARACTERS_FOR_COMMERCIAL_SCRIPT";
export const COMMERCIAL_SCRIPT_ADD_SHOT_TO_SCENE = "COMMERCIAL_SCRIPT_ADD_SHOT_TO_SCENE";
export const COMMERCIAL_SCRIPT_REMOVE_SHOT_FROM_SCENE = "COMMERCIAL_SCRIPT_REMOVE_SHOT_FROM_SCENE";
export const COMMERCIAL_SCRIPT_ADD_FEILD_TO_SHOT = "COMMERCIAL_SCRIPT_ADD_FEILD_TO_SHOT";
export const COMMERCIAL_SCRIPT_REMOVE_FEILD_FROM_SHOT = "COMMERCIAL_SCRIPT_REMOVE_FEILD_FROM_SHOT";
export const COMMERCIAL_SCRIPT_CHANGE_SCENE_SETTING = "COMMERCIAL_SCRIPT_CHANGE_SCENE_SETTING";
export const COMMERCIAL_SCRIPT_CHANGE_INTERIOR_EXTERIOR = "COMMERCIAL_SCRIPT_CHANGE_INTERIOR_EXTERIOR";
export const COMMERCIAL_SCRIPT_CHANGE_DAY_NIGHT = "COMMERCIAL_SCRIPT_CHANGE_DAY_NIGHT";
export const COMMERCIAL_SCRIPT_CHANGE_SHOT_SIZE = "COMMERCIAL_SCRIPT_CHANGE_SHOT_SIZE";
export const COMMERCIAL_SCRIPT_CHANGE_CAMERA_MOVEMENTS = "COMMERCIAL_SCRIPT_CHANGE_CAMERA_MOVEMENTS";
export const COMMERCIAL_SCRIPT_CHANGE_DURATION = "COMMERCIAL_SCRIPT_CHANGE_DURATION";
export const COMMERCIAL_SCRIPT_CHANGE_DESCRIPTION = "COMMERCIAL_SCRIPT_CHANGE_DESCRIPTION";
export const COMMERCIAL_SCRIPT_CHANGE_FIELD_TEXT = "COMMERCIAL_SCRIPT_CHANGE_FIELD_TEXT";
export const COMMERCIAL_SCRIPT_CHANGE_FIELD_CHARACTER = "COMMERCIAL_SCRIPT_CHANGE_FIELD_CHARACTER";
export const UPDATE_COMMERCIAL_SCRIPT = "UPDATE_COMMERCIAL_SCRIPT";

export const SCRIPT_EDIT_MODE = "SCRIPT_EDIT_MODE";
export const DOWNLOADED_SCRIPT = "DOWNLOADED_SCRIPT";
export const QUEUE_DOWNLOAD_SCRIPT = "QUEUE_DOWNLOAD_SCRIPT";
export const CLEAR_DOWNLOAD_SCRIPT = "CLEAR_DOWNLOAD_SCRIPT";
export const RESET_DOWNLOAD_SCRIPT = "RESET_DOWNLOAD_SCRIPT";

export function fetchCommercialScript(filmId, clear = true) {
  return (dispatch) => {
    dispatch({
      type: FETCH_COMMERCIAL_SCRIPT,
      payload: getApiClient().get(`/film/${filmId}/script/commercial`),
      meta: { clear }
    });
  };
}

export function updateCommercialScript(filmId, scenes) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COMMERCIAL_SCRIPT,
      payload: getApiClient().put(`/film/${filmId}/script/commercial`,
      JSON.stringify({scenes})),
      meta: { scenes }
    })
    .then((response) => {
      dispatch(showInfo(`Updated commercial script`));
    })
    .catch((response) => {
      dispatch(showError(`Error updating commercial script`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
  };
}

export function fetchCharacters(filmId) {
  return (dispatch) => {    
    dispatch({
      type: FETCH_ALL_CHARACTERS_FOR_COMMERCIAL_SCRIPT,
      payload: getApiClient().get(`/film/${filmId}/character`)
    });    
  };
}

export function addShotToScene(sceneId) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_ADD_SHOT_TO_SCENE,
      meta: { sceneId }
    });
  };
}

export function removeShotFromScene(sceneId, shotId) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_REMOVE_SHOT_FROM_SCENE,
      meta: { sceneId, shotId }
    });
  };
}

export function sceneSettingChange(sceneId, sceneSettingId) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_SCENE_SETTING,
      meta: { sceneId, sceneSettingId }
    });
  };
}

export function interiorExteriorChange(sceneId, interiorExterior) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_INTERIOR_EXTERIOR,
      meta: { sceneId, interiorExterior }
    });
  };
}

export function dayNightChange(sceneId, dayNight) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_DAY_NIGHT,
      meta: { sceneId, dayNight }
    });
  };
}

export function addScriptFieldToShot(fieldType, sceneId, shotId) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_ADD_FEILD_TO_SHOT,
      meta: { fieldType, sceneId, shotId }
    });
  };
}

export function removeScriptFieldFromShot(fieldId, sceneId, shotId) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_REMOVE_FEILD_FROM_SHOT,
      meta: { fieldId, sceneId, shotId }
    });
  };
}

export function setShotSize(sceneId, shotId, shotSize) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_SHOT_SIZE,
      meta: { sceneId, shotId, shotSize }
    });
  };
}

export function setCameraMovements(sceneId, shotId, cameraMovements) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_CAMERA_MOVEMENTS,
      meta: { sceneId, shotId, cameraMovements }
    });
  };
}

export function setDuration(sceneId, shotId, duration) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_DURATION,
      meta: { sceneId, shotId, duration }
    });
  };
}

export function setDescription(sceneId, shotId, description) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_DESCRIPTION,
      meta: { sceneId, shotId, description }
    });
  };
}

export function setFieldText(sceneId, shotId, fieldId, text) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_FIELD_TEXT,
      meta: { sceneId, shotId, fieldId, text }
    });
  };
}

export function setFieldCharacter(sceneId, shotId, fieldId, characterId) {
  return (dispatch) => {
    dispatch({
      type: COMMERCIAL_SCRIPT_CHANGE_FIELD_CHARACTER,
      meta: { sceneId, shotId, fieldId, characterId }
    });
  };
}





export function downloadScript(filmId, includeTitle, showMarkup, includeDialog, includeAction, includeCharacter, includeParenthetical, includeTransition, allScenes, selectedSceneIds) {
  return (dispatch) => {
    dispatch({
      type: QUEUE_DOWNLOAD_SCRIPT,
      payload: getApiClient().post(`/film/${filmId}/Script/download`, 
      JSON.stringify({ includeTitle, showMarkup, includeDialog, includeAction, includeCharacter, includeParenthetical, includeTransition, allScenes, selectedSceneIds })),
    });
  };
}

export function pdfDownloaded() {
  return (dispatch) => {
    dispatch({
      type: DOWNLOADED_SCRIPT
    });
  };
}

export function clearPdf() {  
  return (dispatch) => {
    dispatch({
      type: CLEAR_DOWNLOAD_SCRIPT
    });
  };
}

export function resetPdf() {  
  return (dispatch) => {
    dispatch({
      type: RESET_DOWNLOAD_SCRIPT
    });
  };
}