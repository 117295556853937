import {
  clearNotifications,
  showError,
  showInfo
} from "../../../notifications/ducks/actions";
import getApiClient from "../../../shared/api";

export const FETCH_ADMIN_FAQ = "FETCH_ADMIN_FAQ";
export const FETCH_ADMIN_FAQS = "FETCH_ADMIN_FAQS";
export const TRANSLATE_FAQ = "TRANSLATE_FAQ";
export const ADD_FAQ = "ADD_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const CLEAR_FAQ = "CLEAR_FAQ";
export const CLEAR_REDIRECT_FAQ = "CLEAR_REDIRECT_FAQ";
export const FAQ_GET_LANGUAGES = "FAQ_GET_LANGUAGES";
export const FAQ_ADD_LANGUAGE = "FAQ_ADD_LANGUAGE";
export const FAQ_REMOVE_LANGUAGE = "FAQ_REMOVE_LANGUAGE";
export const FAQ_CHANGE_SECTION = "FAQ_CHANGE_SECTION";
export const FAQ_LANGUAGE_CHANGE_LANGUAGE = "FAQ_LANGUAGE_CHANGE_LANGUAGE";
export const FAQ_LANGUAGE_CHANGE_QUESTION = "FAQ_LANGUAGE_CHANGE_QUESTION";
export const FAQ_LANGUAGE_CHANGE_ANSWER = "FAQ_LANGUAGE_CHANGE_ANSWER";

export function fetchFaqs() {
  return (dispatch) => {
    dispatch({
      type: FETCH_ADMIN_FAQS,
      payload: getApiClient().get(`/faq/admin`)
    });
  };
}

export function fetchFaq(id, section) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ADMIN_FAQ,
      payload: getApiClient().get(`/faq/${section}/${id}/admin`)
    });
  };
}

export function getLanguages() {
  return (dispatch) => {
    dispatch({
      type: FAQ_GET_LANGUAGES,
      payload: getApiClient().get(`/lookup/languages`),
    });
  };
}

export function clearFaq() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FAQ      
    });
  };
}

export function translateFaq(englishLanguage, languageCode) {
  const language = {question: englishLanguage.question, answer: englishLanguage.answer, languageCode };

  return (dispatch) => {
    dispatch({
      type: TRANSLATE_FAQ,
      payload: getApiClient().post(`/faq/translate`, language),
      meta: { languageCode }
    });
  };
}

export function addFaq(faq) {
  return (dispatch) => {
    dispatch({
      type: ADD_FAQ,
      payload: getApiClient().post(`/faq/`, faq),
      meta: { faq }
    })
      .then((response) => {
        dispatch(showInfo(`Added FAQ ${faq.question}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding FAQ`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateFaq(faqId, faq, section) {  
  return (dispatch) => {    
    dispatch({
      type: UPDATE_FAQ,
      payload: getApiClient().put(`/faq/${faqId}`, faq),
      meta: { faq }
    })
      .then((response) => {
        dispatch(showInfo(`Updated FAQ ${faq.question}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating FAQ`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteFaq(faqId, faq, section) {
  return (dispatch) => {    
    dispatch({
      type: DELETE_FAQ,
      payload: getApiClient().delete(`/faq/${section}/${faqId}`),      
    })
      .then((response) => {
        dispatch(showInfo(`Deleted FAQ ${faq.question}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting FAQ`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REDIRECT_FAQ
    });
  };
}

export function addLanguage() {
  return (dispatch) => {
    dispatch({
      type: FAQ_ADD_LANGUAGE
    });
  };
}

export function removeLanguage(languageId) {  
  return (dispatch) => {
    dispatch({
      type: FAQ_REMOVE_LANGUAGE,
      meta: { languageId }
    });
  };
}

export function changeSection(section) {
  return (dispatch) => {
    dispatch({
      type: FAQ_CHANGE_SECTION,
      meta: { section }
    });
  };
}

export function changeLanguage(id, languageCode) {
  return (dispatch) => {
    dispatch({
      type: FAQ_LANGUAGE_CHANGE_LANGUAGE,
      meta: { id, languageCode }
    });
  };
}

export function changeLanguageQuestion(id, question) {
  return (dispatch) => {
    dispatch({
      type: FAQ_LANGUAGE_CHANGE_QUESTION,
      meta: { id, question }
    });
  };
}

export function changeLanguageAnswer(id, answer) {
  return (dispatch) => {
    dispatch({
      type: FAQ_LANGUAGE_CHANGE_ANSWER,
      meta: { id, answer }
    });
  };
}


