import React from "react";

const Test = (props) => {
    const { films, loading, fetchFilms, addExampleFilm } = props;
  
    
  
      
    return (


    <div style={{height: "2000px"}}>
        <div className="sticky">Hello</div>
        
    </div>
)
    }

export default Test