import { FETCH_DIETARY_REQUIREMENTS, FETCH_DIETARY_REQUIREMENTS_ENUMS, FETCH_DRINKS_AND_MILK_TYPES, UPDATE_DIETARY_REQUIREMENTS } from "./actions";

interface ConfirmCallSheetState {
  loading: boolean;
  loadingEnums: boolean;
  saving: boolean;
  saved: boolean;
  dietaryRequirements: any;
  dietaryRequirementEnum: any[]
}

const defaultState: ConfirmCallSheetState = {
  loading: true,
  loadingEnums: true,
  saving: false,
  saved: false,
  dietaryRequirements: {},
  dietaryRequirementEnum: []
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_DIETARY_REQUIREMENTS + "_PENDING": {
      return {
        ...state,
        saved: false,
        loading: true
      }
    }

    case FETCH_DIETARY_REQUIREMENTS + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        dietaryRequirements: action.payload.data
      };
    }

    case FETCH_DIETARY_REQUIREMENTS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case FETCH_DIETARY_REQUIREMENTS_ENUMS + "_PENDING": {
      return {
        ...state,
        loadingEnums: true
      }
    }

    case FETCH_DIETARY_REQUIREMENTS_ENUMS + "_FULFILLED": {
      return {
        ...state,
        loadingEnums: false,
        dietaryRequirementEnum: action.payload.data
      };
    }

    case FETCH_DIETARY_REQUIREMENTS_ENUMS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingEnums: false
      };
    }

    case UPDATE_DIETARY_REQUIREMENTS + "_PENDING": {
      return {
        ...state,
        saving: true
      }
    }

    case UPDATE_DIETARY_REQUIREMENTS + "_FULFILLED": {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }

    case UPDATE_DIETARY_REQUIREMENTS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        saving: false
      };
    }

    case FETCH_DRINKS_AND_MILK_TYPES + "_PENDING": {
      return {
        ...state,
        loadingEnums: true
      }
    }

    case FETCH_DRINKS_AND_MILK_TYPES + "_FULFILLED": {
      return {
        ...state,
        loadingEnums: false,
        drinks: action.payload.data.drinks,
        milkTypes: action.payload.data.milkTypes
      };
    }

    case FETCH_DRINKS_AND_MILK_TYPES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingEnums: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
