import PriceIncluded from './price-included';
import { useInView } from "react-intersection-observer";
const Price = ({ pricingPlan, name, description, price, includes, currency, registerUrl }: any) => {

  const options = { threshold: 0.3, triggerOnce: true, rootMargin: "0px 0px 0px 0px" };
  const [ref, inView] = useInView(options);

  let registerWithTypeUrl = registerUrl;
  if (registerUrl.indexOf("?") > -1) {
    registerWithTypeUrl += "&";
  } else {
    registerWithTypeUrl += "?";
  }

  registerWithTypeUrl += "pricingPlan=" + pricingPlan;

  return (
    <div ref={ref} className={`card price-card ${inView ? "price-card-in-view" : ""}`}>
      <div className="price-card-content">
        <span className="tag is-purple price-is-beta">Beta</span>
        <header className="card-header">
          <div className="price-card-header-title is-size-5">
            {name}
          </div>
        </header>
        <div>
          {description}
        </div>
        <div>
          <span className={`price-cost ${price > 0 ? "price-strike-through" : ""}`}><span dangerouslySetInnerHTML={{ __html: currency }}></span>{price}</span>{price > 0 && <span className="price-cost"> <span dangerouslySetInnerHTML={{ __html: currency }}></span>0</span>} Per month{price > 0 && <span>*</span>}
        </div>
        {includes.map((included: any, index) => (
          <PriceIncluded included={included} key={index} />
        ))}
        <a href={`${registerWithTypeUrl}`} className="button price-button">Try now</a>
      </div>

    </div>
  )
}

export default Price;