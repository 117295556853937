import React from "react";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "./../../shared/errors";
import { renderTextField } from "./../../shared/form-helpers";
import required from "./../../shared/validation";

function LoginForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    errors,
    submitFailed,
    invalid,
    submitted
  } = props;

  return (
    <>
        <h2 className="menu-label">Reset Password</h2>
        {errors && <Errors errors={errors} />}

        {submitted && (
          <div>
            Password successfully reset. Please now{" "}
            <NavLink to={"/login"}>login</NavLink>
          </div>
        )}
        {!submitted && (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="password"
              label="New Password"
              type="password"
              component={renderTextField}
              validate={[required]}
              required
            />
            <Field
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              component={renderTextField}
              validate={[required]}
              required
            />
            <div className="buttons">
              <button
                type="submit"
                className={
                  submitting
                    ? "button is-primary is-loading"
                    : "button is-primary"
                }
                disabled={pristine || submitting}>
                Reset Password
              </button>
            </div>

            <FormErrors submitFailed={submitFailed} invalid={invalid} />
          </form>
        )}
    </>
  );
}

export default reduxForm({
  form: "loginForm",
  enableReinitialize: true
})(LoginForm);
