import invitations from "../../../shared/invitations";
import { CHECK_DISCOUNT_CODE, CLEAR_PAYMENT, CLEAR_PAYMENT_REDIRECT, CREATE_PAYMENT_INTENT, FETCH_PRICING_PLANS, PAYMENT_ERROR, PAYMENT_GET_CURRENCIES, PAYMENT_PAYING } from "./actions";

interface RegisterState {
  loadingPricingPlans: boolean;
  loadingCurrencies: boolean;
  paying: boolean;
  user: any;
  currencies: any[];
  pricingPlans: any[];
  selectedCurrencyId: any;
  selectedPricingPlan: any;
  userCountryCode: any;
  noPaymentNeeded: boolean;
  pendingSetupIntent: boolean;
  checkingCode: boolean;
  discountCodeValid: boolean | null;
  discountCodeDescription: string | null,
  firstMonthPrice: number | null;
}

const defaultState: RegisterState = {
  loadingPricingPlans: false,
  loadingCurrencies: false,
  paying: false,
  user: {},
  currencies: [],
  selectedCurrencyId: null,
  pricingPlans: [],
  selectedPricingPlan: null,
  userCountryCode: null,
  noPaymentNeeded: false,
  pendingSetupIntent: false,
  checkingCode: false,
  discountCodeValid: null,
  discountCodeDescription: null,
  firstMonthPrice: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case FETCH_PRICING_PLANS + "_PENDING": {
      return {
        ...state,
        errors: null,
        loadingPricingPlans: true
      };
    }

    case FETCH_PRICING_PLANS + "_FULFILLED": {
      return {
        ...state,
        pricingPlans: action.payload.data.pricingPlans,
        currencies: action.payload.data.billingCurrencies,
        selectedCurrencyId: action.payload.data.userBillingCurrencyId,
        selectedPricingPlan: action.payload.data.pricingPlan,
        userCountryCode: action.payload.data.userCountryCode,
        loadingPricingPlans: false
      };
    }

    case FETCH_PRICING_PLANS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingPricingPlans: false
      };
    }

    case CHECK_DISCOUNT_CODE + "_PENDING": {
      return {
        ...state,
        errors: null,
        checkingCode: true
      };
    }

    case CHECK_DISCOUNT_CODE + "_FULFILLED": {
      return {
        ...state,
        discountCodeValid: action.payload.data.valid,
        discountCodeDescription: action.payload.data.description,
        firstMonthPrice: action.payload.data.firstMonthPrice,
        checkingCode: false
      };
    }

    case CHECK_DISCOUNT_CODE + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        checkingCode: false
      };
    }

    case PAYMENT_GET_CURRENCIES + "_PENDING": {
      return {
        ...state,
        loadingCurrencies: true
      };
    }

    case PAYMENT_GET_CURRENCIES + "_FULFILLED": {
      return {
        ...state,
        currencies: action.payload.data.currencies,
        user: { ...state.user, currencyId: action.payload.data.defaultId },
        defaultCurrencyId: action.payload.data.defaultId,
        loadingCurrencies: false
      };
    }

    case PAYMENT_GET_CURRENCIES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingCurrencies: false
      };
    }

    case CREATE_PAYMENT_INTENT + "_PENDING": {
      return {
        ...state,
        errors: null,
        paying: true
      };
    }

    case CREATE_PAYMENT_INTENT + "_FULFILLED": {
      return {
        ...state,
        clientSecret: action.payload.data.clientSecret,
        noPaymentNeeded: action.payload.data.clientSecret === null,
        pendingSetupIntent: action.payload.data.pendingSetupIntent
      };
    }

    case CREATE_PAYMENT_INTENT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        paying: false
      };
    }

    case CLEAR_PAYMENT_REDIRECT: {
      return {
        ...state,
        redirect: null
      };
    }

    case CLEAR_PAYMENT: {
      return {
        ...state,
        registered: false
      };
    }

    case PAYMENT_ERROR: {
      return {
        ...state,
        errors: { errorMessage: [action.payload] }
      };
    }

    case PAYMENT_PAYING: {
      return {
        ...state,
        paying: action.payload
      };
    }

    default:
      return state;
  }
};

export default reducer;
