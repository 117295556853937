import getApiClient from "./../../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications,
  showWarning
} from "../../../../../notifications/ducks/actions";

const url = "/film";

export const FETCH_SET_DRESSING_OVERVIEW = "FETCH_SET_DRESSING_OVERVIEW";

export function fetchSetDressingOverview(id) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SET_DRESSING_OVERVIEW,
      payload: getApiClient().get(`${url}/${id}/SetDressing/Overview`)
    });
  };
}