
export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="27.97" height="23.835" viewBox="0 0 22 18.72"  {...props}>
    <g id="Group_3831" data-name="Group 3831" transform="translate(0.5 0.5)">
      <rect id="Rectangle_4230" data-name="Rectangle 4230" width="21" height="17.72" fill="none" strokeLinejoin="round" strokeWidth="1" />
    </g>
    <g id="Group_3832" data-name="Group 3832" transform="translate(3.336 3.79)">
      <path id="Path_32837" data-name="Path 32837" d="M18.181,16.16a3.734,3.734,0,0,0-4.619.227" transform="translate(-8.189 -10.335)" fill="none" strokeLinejoin="round" strokeWidth="1" />
      <circle id="Ellipse_65" data-name="Ellipse 65" cx="2.302" cy="2.302" r="2.302" transform="translate(5.488)" fill="none" strokeLinejoin="round" strokeWidth="1" />
      <circle id="Ellipse_66" data-name="Ellipse 66" cx="2.302" cy="2.302" r="2.302" transform="translate(10.125 4.554)" fill="none" strokeLinejoin="round" strokeWidth="1" />
      <circle id="Ellipse_67" data-name="Ellipse 67" cx="2.302" cy="2.302" r="2.302" transform="translate(0.845 4.554)" fill="none" strokeLinejoin="round" strokeWidth="1" />
      <path id="Path_32838" data-name="Path 32838" d="M23.751,20.838A4.576,4.576,0,0,0,15.97,24.1h0a4.575,4.575,0,0,0-7.78-3.265" transform="translate(-8.189 -10.335)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </g>
  </svg>

