import React, { useEffect } from "react";
import { connect } from "react-redux";
import Errors from "../../../shared/errors";
import Loader from "../../../shared/loader";
import {
  fetchScenesOrder,
  updateScenesOrder,
  updateOrder,
  updateSceneOrder,
  toggleSelectionInGroup,
  setDraggingSceneId,
  unselectAll
} from "./ducks/actions";
import OrderList from "./list-order";
import { Prompt } from "react-router-dom";
import Onboarding from '../../../shared/onboarding/onboarding';
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ScenesScriptDaysContainer(props) {
  const filmId = props.match.params.filmId;
  const {
    fetchScenesOrder,
    updateScenesOrder,
    errors,
    loading,
    scenes,
    updateOrder,
    updateSceneOrder,
    selectedSceneIds,
    draggingSceneId,
    toggleSelectionInGroup,
    setDraggingSceneId,
    unselectAll,
    pristine,
    readOnly,
    onboardingSteps,
    canAccessSceneSettings
  } = props;


  useEffect(() => {
    fetchScenesOrder(filmId);
  }, []);

  const onUpdateScenesOrder = (scenes) => {
    updateScenesOrder(filmId, scenes);
  };

  function renderLoading() {
    return <Loader />;
  }


  const toggleSelection = (taskId) => {
    /*
    const selectedTaskIds: Id[] = this.state.selectedTaskIds;
    const wasSelected: boolean = selectedTaskIds.includes(taskId);

    const newTaskIds: Id[] = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedTaskIds.length > 1) {
        return [taskId];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    this.setState({
      selectedTaskIds: newTaskIds,
    });
    */
  };

  // This behaviour matches the MacOSX finder selection
  const multiSelectTo = (newSceneId) => {
    /*
    const updated: ?(Id[]) = multiSelect(
      this.state.entities,
      this.state.selectedTaskIds,
      newTaskId,
    );

    if (updated == null) {
      return;
    }

    this.setState({
      selectedTaskIds: updated,
    });
    */
  };

  function renderScenes(days) {
    return (
      <ScrollIntoView
        path="/films/:filmId/scenes/order"
        loading={loading}>
        <div className="blade-content widest">
          <p className="menu-label">Scenes Order</p>
          {errors && <Errors errors={errors} />}
          <Prompt when={!pristine} message="" />
          {days && days.length > 0 && (
            <OrderList
              scenes={scenes}
              filmId={filmId}
              onUpdateScenesOrder={onUpdateScenesOrder}
              updateSceneOrder={updateSceneOrder}
              draggingSceneId={draggingSceneId}
              selectedSceneIds={selectedSceneIds}
              toggleSelection={toggleSelection}
              toggleSelectionInGroup={toggleSelectionInGroup}
              multiSelectTo={multiSelectTo}
              unselectAll={unselectAll}
              readOnly={readOnly}
              pristine={pristine}
              canAccessSceneSettings={canAccessSceneSettings}
            />
          )}
          <Onboarding onboardingSteps={onboardingSteps} section="Scene Order" />
        </div>
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderScenes(scenes);
}

function mapStateToProps(state) {
  return {
    scenes: state.scenesState.orderState.scenes,
    readOnly: state.scenesState.orderState.readOnly,
    canAccessSceneSettings: state.scenesState.orderState.canAccessSceneSettings,
    loading: state.scenesState.orderState.loading,
    errors: state.scenesState.orderState.errors,
    selectedSceneIds: state.scenesState.orderState.selectedSceneIds,
    draggingSceneId: state.scenesState.orderState.draggingSceneId,
    pristine: state.scenesState.orderState.pristine,
    onboardingSteps: state.scenesState.orderState.onboardingSteps
  };
}

export default connect(mapStateToProps, {
  fetchScenesOrder,
  updateScenesOrder,
  updateOrder,
  updateSceneOrder,
  toggleSelectionInGroup,
  setDraggingSceneId,
  unselectAll
})(ScenesScriptDaysContainer);
