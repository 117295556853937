import React from "react";
import { Link, Prompt } from "react-router-dom";
import Moment from "react-moment";
import Errors from "../../../shared/errors";
import './day-out-of-days.scss';
import CanAccessLink from "../../../shared/can-access-link";

const DayOutOfDaysActorsTable = (props) => {
  const {
    actors,
    days,
    hasDates,
    errors,
    filmId,
    showNonShootingDays,
    canAccessActors,
    scrollWithOffset,
    showNumber,
    sectionLink
  } = props;

  const filteredDays = showNonShootingDays ? days : days.filter((d: any) => !d.isOffDay);

  return (
    <div className="day-out-days-table-container">
      <table className="table day-out-days-table">
        <thead className="day-out-days-table-header-row">
          <tr >
            <th className="day-out-days-table-actor-column-header">Name</th>
            {(filteredDays || []).map((day: any, index: number) => (<th className="has-text-centered" key={index} >{day.number && <Link to={`/films/${filmId}/shooting-days/${day.id}/${sectionLink}`}>Day {day.number}</Link>}{!day.number && <span>Off Day</span>}</th>))}
            {hasDates && <th className="has-text-centered" colSpan={2}>Total</th>}
            {!hasDates && <>
              <th className="has-text-centered">Total Work</th>
              <th className="has-text-centered">Total Hold</th>
            </>}
          </tr>
          {hasDates &&
            <tr>
              <th className="day-out-days-table-actor-column-header"></th>
              {(filteredDays || []).map((day: any, index: number) => (<th className="has-text-centered" key={index}>{new Date(day.date).toLocaleDateString()}</th>))}
              <th className="has-text-centered">Work</th>
              <th className="has-text-centered">Hold</th>
            </tr>
          }
        </thead>
        <tbody>
          {(actors || []).map((actor, index) => {
            return (
              <tr key={index}>
                <td className="day-out-days-table-actor-column">
                  <CanAccessLink to={`/films/${filmId}/${sectionLink}/${actor.actorId}/edit`} canAccess={canAccessActors}>{showNumber && <><div className="day-out-days-table-actor-column-number">{actor.actorNumber}</div>&nbsp;</>}{actor.actorName}</CanAccessLink>                </td>
                {(actor.days.filter((d: any) => showNonShootingDays || d.number) || []).map((day: any, index: number) => (<td className="has-text-centered" key={index}>
                  <CanAccessLink to={`/films/${filmId}/shooting-days/${day.dayId}/${sectionLink}#${actor.actorId}`} canAccess={canAccessActors && day.dayId && !day.hold} scrollWithOffset={el => scrollWithOffset(el)}>{day.start ? "S" : ""}{day.work ? "W" : ""}{day.hold ? "H" : ""}{day.finish ? "F" : ""}</CanAccessLink>
                </td>))}
                <td className="has-text-centered">{actor.totalWorkDays}</td>
                <td className="has-text-centered">{actor.totalHoldDays}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}


export default DayOutOfDaysActorsTable;
