import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import {
  fetchShootingDayActors,
  updateShootingDayActor,
  addShootingDayActor
} from "./ducks/actions";
import EditActorsListForm from "./edit-form";
import './shooting-day-actors.scss';

function ListShootingDayActorsContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchShootingDayActors,
    updateShootingDayActor,
    loading,
    actors,
    allActors,
    errors,
    readOnly,
    canAccessActors,
    addShootingDayActor,
    adding,
    callTime,
    onSetTime,
    noScenes
  } = props;

  useEffect(() => {
    fetchShootingDayActors(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  function renderLoading() {
    return <Loader />;
  }

  function onUpdateShootingDayActors(actors) {
    console.log(actors);
    updateShootingDayActor(filmId, shootingdayId, actors);
  }

  const onAddShootingDayActor = (actor) => {
    addShootingDayActor(filmId, shootingdayId, actor);
  };

  function renderShootingDays(actors, filmId) {
    return (
      <div className="blade-content widest shooting-day-actors">
        <p className="menu-label">Actors</p>
        {noScenes && (
          <div className="notification">No scenes assigned yet</div>
        )}
        {!actors?.length && !noScenes && (
          <div className="notification">No actors assigned yet</div>
        )}
        {loading && <Loader />}
        {!!actors?.length && !noScenes && (
          <EditActorsListForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            initialValues={{ actors: actors }}
            loading={loading}
            onSubmit={onUpdateShootingDayActors}
            readOnly={readOnly}
            canAccessActors={canAccessActors}
            allActors={allActors}
            onAddShootingDayActor={onAddShootingDayActor}
            adding={adding}
            callTime={callTime}
            onSetTime={onSetTime}
            {...props}
          />
        )}
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(actors, filmId);
}

function mapStateToProps(state) {
  return {
    shootingDay: state.shootingdaysState.rootState.shootingday,
    readOnly: state.shootingdaysState.actorsState.readOnly,
    canAccessActors: state.shootingdaysState.actorsState.canAccessActors,
    actors: state.shootingdaysState.actorsState.actors,
    allActors: state.shootingdaysState.actorsState.allActors,
    loading: state.shootingdaysState.actorsState.loading,
    adding: state.shootingdaysState.actorsState.adding,
    errors: state.shootingdaysState.actorsState.errors,
    callTime: state.shootingdaysState.actorsState.callTime,
    onSetTime: state.shootingdaysState.actorsState.onSetTime,
    noScenes: state.shootingdaysState.actorsState.noScenes
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayActors,
  updateShootingDayActor,
  addShootingDayActor
})(ListShootingDayActorsContainer);
