import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { fetchProps } from "./../../accessories/props/ducks/actions";
import {
  addPropToScene,
  clearRedirect
} from "./ducks/actions";
import Form from "./form";
import Loader from "../../../shared/loader";

function AddPropToScene(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    fetchProps,
    addPropToScene,
    clearRedirect,
    errors,
    filmProps,
    sceneProps,
    saving
  } = props;

  const [fromBreakdown, setFromBreakdown] = useState<boolean>(false);
  useEffect(() => {
    setFromBreakdown(props.location?.state?.fromBreakdown);
  }, [props.location?.state?.fromBreakdown]);

  useEffect(() => {
    fetchProps(filmId);
  }, []);

  if (props.redirect) {
    clearRedirect();
    if (props.redirect.to == "list") {
      const url = `/films/${filmId}/scenes/${sceneId}/props`;
      return <Redirect to={url} />;
    } else if (props.redirect.to == "breakdown") {
      const url = `/films/${filmId}/breakdown/scenes/${sceneId}`;
      return <Redirect to={url} />;
    }
  }

  function onAddCharacter(prop) {
    sanitiseDataTypes(prop);
    addPropToScene(filmId, sceneId, prop, fromBreakdown);
  }

  function sanitiseDataTypes(prop) {
  }

  const scenePropIds = sceneProps.map(p => p.id);
  const nonSceneProps = filmProps.filter((prop: any) => !scenePropIds.includes(prop.id));

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/scenes/:sceneId/props/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        characterId={null}
        initialValues={props.prop}
        filmProps={nonSceneProps}
        onSubmit={onAddCharacter}
      />
      {saving && <Loader />}
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  return {
    sceneProps: state.scenesState.propState.props,
    filmProps: state.propsState.props,
    errors: state.scenesState.propState.errors,
    redirect: state.scenesState.propState.redirect,
    saving: state.scenesState.propState.saving
  };
}

export default connect(mapStateToProps, {
  addPropToScene,
  fetchProps,
  clearRedirect
})(AddPropToScene);
