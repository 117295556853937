import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { addCollaboratorRole, clearCollaboratorRole, clearRedirect, fetchSections } from "./ducks/actions";
import Form from "./form";
import Loader from "../../../shared/loader";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import WarningText from "../../../shared/warning-text";
import Onboarding from "../../../shared/onboarding/onboarding";

function AddUser(props) {
  const filmId = props.match.params.filmId;
  const { clearCollaboratorRole, addCollaboratorRole, fetchSections, sections, errors, role, canAdd, clearRedirect, redirect, onboardingSteps } = props;

  useEffect(() => {
    clearCollaboratorRole();
    fetchSections(filmId);
  }, [filmId, clearCollaboratorRole]);

  if (redirect) {
    if (redirect.to === "list") {
      clearRedirect();
      const url = `/films/${filmId}/collaborators/collaborator-roles`;
      return <Redirect to={url} />;
    }
  }

  function onAddCollaboratorRole(role) {
    sanitiseDataTypes(role);
    addCollaboratorRole(filmId, role);
  }

  function sanitiseDataTypes(user) { }

  if (props.loadingUser) {
    return <Loader />;
  }

  return (
    <ScrollIntoView
      className="blade-content medium-wide"
      path="/films/:filmId/collaborators/collaborator-roles/add"
      loading={false}>
      {canAdd &&
        <>
          <Form
            mode="add"
            errors={errors}
            userId={null}
            initialValues={role}
            loading={false}
            onSubmit={onAddCollaboratorRole}
            filmId={filmId}
            sections={sections}
            onboardingSteps={onboardingSteps}
            {...props}
          />
        </>
      }
      {!canAdd && <WarningText>You cannot add any more collaborator roles due to your pricing plan. Head to your <Link to={`/account`}>account</Link> section to upgrade your pricing plan.</WarningText>}
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { collaboratorRolesState, commonState } = state;
  return {
    role: collaboratorRolesState.role,
    sections: collaboratorRolesState.sections,
    loadingUser: collaboratorRolesState.loadingUser,
    errors: collaboratorRolesState.errors,
    redirect: collaboratorRolesState.redirect,
    onboardingSteps: collaboratorRolesState.onboardingSteps,
    userAccessEnum: commonState.userAccessEnum,
    canAdd: true
  };
}

export default connect(mapStateToProps, {
  addCollaboratorRole,
  clearCollaboratorRole,
  clearRedirect,
  fetchSections
})(AddUser);
