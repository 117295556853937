import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import InvlidShootingDayNotification from "./../invalid-shooting-day-notification";
import { fetchShootingDayLocations } from "./ducks/actions";
import ShootingDayLocationsForm from "./edit-form";

function ListShootingDayLocationsContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const { fetchShootingDayLocations, loading, locations, errors } = props;

  useEffect(() => {
    fetchShootingDayLocations(filmId, shootingdayId);
  }, [filmId, shootingdayId]);
  /*
    useEffect(() => {
      const script = document.createElement('script');
      const apiKey = (window as any).googleApiKey;
      const googleScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.src = googleScriptUrl;
      script.async = false;
    
      document.head.appendChild(script);
    
      return () => {
        document.head.removeChild(script);
      }
    }, []);
  */
  function renderLoading() {
    return <Loader />;
  }

  function renderShootingDays(locations, filmId) {
    return (
      <div className="blade-content wide">
        <p className="menu-label">Locations</p>
        <ShootingDayLocationsForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ locations }}
          loading={loading}
          canAccessShootingLocations={locations?.canAccessShootingLocations}
          {...props}
        />
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(locations, filmId);
}

function mapStateToProps(state) {
  const { rootState, locationsState } = state.shootingdaysState;
  return {
    shootingDay: rootState.shootingday,
    locations: locationsState.locations,
    loading: locationsState.loading,
    errors: locationsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayLocations
})(ListShootingDayLocationsContainer);
