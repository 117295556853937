import React from "react";
import NumberFormat from "react-number-format";
import CurrencyDisplay from "../../shared/currency-display";
import Character from "./character";
import { OnboardingStep4ClassName } from './ducks/reducer';

function Characters(props) {
  const {
    budget,
    onChangeCharacterDailyRate,
    charactersVisible,
    showHideCharacters,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <div>
      <div className="columns subtitle is-4">
        <div className="column">
          <h4
            className={`pointer ${OnboardingStep4ClassName}`}
            onClick={() => showHideCharacters(!charactersVisible)}>
            <button className="button is-text is-small">
              {!charactersVisible && <i className="material-icons">add</i>}
              {charactersVisible && <i className="material-icons">remove</i>}
            </button>
            Characters
          </h4>
        </div>
        <div className="column budget-header-number">
          {!charactersVisible && (
            <CurrencyDisplay
              value={budget.charactersTotal}
            />
          )}
        </div>
      </div>

      {charactersVisible && (
        <table className="table budget-table">
          <thead>
            <tr>
              <th>Name</th>
              <th className="budget-days-column">Days</th>
              <th className="budget-daily-rate-column">Daily Rate</th>
              <th className="has-text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {(budget?.characters || []).map((character, i) => {
              return (
                <Character
                  key={i}
                  index={i}
                  character={character}
                  onChangeCharacterDailyRate={onChangeCharacterDailyRate}
                  days={budget.days}
                  totalScenes={budget.totalScenes}
                  readOnly={readOnly}
                  filmId={filmId}
                  saving={saving}
                />
              );
            })}
            <tr>
              <td colSpan={3}></td>
              <td className="has-text-right">
                <CurrencyDisplay
                  value={budget.charactersTotal}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Characters;
