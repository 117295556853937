import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";
import store from "../../../../store";
import { getActionToUploadImagesAndSaveEntity } from "./../../../../shared/image-action-creator";

export function fetchShots(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOTS",
      payload: getApiClient().get(`/film/${filmId}/scene/${sceneId}/Shot`),
    });
  };
}

export function fetchShot(filmId, sceneId, shotId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_SHOT",
      payload: getApiClient().get(`/film/${filmId}/scene/${sceneId}/Shot/${shotId}`),
    });
  };
}

export function updateShot(filmId, sceneId, shot) {
  return (dispatch) => {
    updateShotWithImageUrls(dispatch, shot, filmId, sceneId);
  };
}

function updateShotWithImageUrls(dispatch, shot, filmId, sceneId) {  
  dispatch({
    type: "UPDATE_SINGLE_SHOT",
    payload: getApiClient().put(`/film/${filmId}/scene/${sceneId}/Shot/${shot.id}`, shot),
    meta: { shot },
  })
    .then((response) => {
      dispatch(showInfo(`Updated shot ${shot.number}`));
    })
    .catch((response) => {
      dispatch(showError(`Error updating shot ${shot.number}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function addShot(filmId, sceneId, shot) {
  shot.mainImageUrl = null;
  shot.imageUrls = [];

  return (dispatch) => {
    addShotWithImageUrls(dispatch, shot, filmId, sceneId);
  };
}

function addShotWithImageUrls(dispatch, shot, filmId, sceneId) {
  dispatch({
    type: "ADD_SINGLE_SHOT",
    payload: getApiClient().post(`/film/${filmId}/scene/${sceneId}/Shot`, shot),
    meta: { shot },
  })
    .then((response) => {
      dispatch(showInfo(`Added shot ${shot.number}`));
    })
    .catch((response) => {
      dispatch(showError(`Error adding shot ${shot.number}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export function deleteShot(filmId, sceneId, shot) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_SHOT",
      payload: getApiClient().delete(`/film/${filmId}/scene/${sceneId}/Shot/${shot.id}`),
      meta: { shot },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted shot ${shot.number}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting shot ${shot.number}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchFilmFramesPerSecond(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_FRAMES_PER_SECOND",
      payload: getApiClient().get(`/film/${filmId}`),
    });
  };
}

export function initialliseAddShotForm() {
  return (dispatch) => {
    dispatch({
      type: "INITIALIZE_ADD_SHOT_FORM",
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}

export const uploadImagesAndSaveShot = getActionToUploadImagesAndSaveEntity(
  addShotWithImageUrls,
  updateShotWithImageUrls
);
