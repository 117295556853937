import { combineReducers } from "redux";
import { v4 as uuidv4 } from 'uuid';
import mapPictures from "../../../shared/image-mapper";
import OnboardingType from "../../../shared/onboarding/onboarding-type";
import { sortByName } from "./../../../shared/utility";
import overviewReducer from "../overview/ducks/reducer";
import {
  LOCATION_GET_COUNTRIES,
  LOCATION_CHANGE_DAY_OF_WEEK,
  LOCATION_UPDATE_EVENT,
  LOCATION_ADD_EVENTS,
  LOCATION_DELETE_EVENT,
  LOCATION_DELETE_EVENTS,
  FETCH_LOCATION_DEFAULTS,
  WHAT_THREE_WORDS_LOOKUP
} from "./actions";
import { updateDay } from "../../scheduling/ducks/reducer";
import { GoogleAddressParser } from "../../../shared/Map/google-address-parser";

interface LocationState {
  locations: any[];
  locationsWithSubLocations: any[];
  loading: boolean;
  location: any;
  hospitals: any[];
  hospitalAddress: any;
  showHospitalList: boolean;
  selectedHospital: any;
  carParks: any[];
  showCarParkList: boolean;
  selectedCarPark: any;
  zoomMap: boolean;
  noCarParks: boolean;
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  countries: any[]
  defaultCountryId: any,
  availableDates: any[],
  daysOfWeek: any[]
}

export const OnboardingStep1ClassName = 'location-onboarding-step-1';
export const OnboardingStep2ClassName = 'location-onboarding-step-2';
export const OnboardingStep3ClassName = 'location-onboarding-step-3';
export const OnboardingStep4ClassName = 'location-onboarding-step-4';
export const OnboardingStep5ClassName = 'location-onboarding-step-5';
export const OnboardingStep6ClassName = 'location-onboarding-step-6';

const defaultState: LocationState = {
  locations: [],
  locationsWithSubLocations: [],
  loading: true,
  location: { address: {} },
  hospitals: [],
  hospitalAddress: null,
  showHospitalList: false,
  selectedHospital: null,
  carParks: [],
  showCarParkList: false,
  selectedCarPark: null,
  zoomMap: false,
  noCarParks: false,
  readOnly: false,
  countries: [],
  defaultCountryId: null,
  availableDates: [],
  daysOfWeek: [],
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Address',
      content: `Check the address that has been returned from the Google search. Select whether this location will be interior, exterior or both.`
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Images',
      content: `Images of the location to help crew understand the layout.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Parking',
      content: `Include details of the best place for actors and crew to park their cars, if applicable.`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Hospital',
      content: `The nearest hospital with and A&E department will automatically be selected, but you can choose a different one if more appropriate.`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Sub Locations',
      content: `Sub Locations are things like rooms in a house, or stages at a studio. They all exist at the same overall location.`
    }
    ,
    {
      target: '.' + OnboardingStep6ClassName,
      title: 'Availability',
      content: `Select which dates the location is available for. This can be set before or after LOCATION.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "INITIALIZE_BEFORE_FORM_LOAD": {
      return {
        ...defaultState,
        locations: state.locations,
        loading: false
      };
    }

    case FETCH_LOCATION_DEFAULTS + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case FETCH_LOCATION_DEFAULTS + "_FULFILLED": {

      const availableDates: any[] = [];

      action.payload.data.potentialDates.forEach(d => {
        availableDates.push({ id: d.id, start: new Date(d.start), allDay: true, title: "Potential", backgroundColor: "#00ffce", borderColor: "#00ffce", textColor: "#040f21", editable: false, isPotentialDate: true })
      })

      return {
        ...state,
        daysOfWeek: action.payload.data.daysOfWeek,
        showAvailability: action.payload.data.showAvailability,
        availableDates,
        loading: false
      };
    }

    case "FETCH_LOCATIONS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_LOCATIONS_FULFILLED": {
      return {
        ...state,
        locations: action.payload.data.locations,
        readOnly: action.payload.data.readOnly,
        loading: false
      };
    }

    case "FETCH_LOCATIONS_WITH_SUB_LOCATIONS_FULFILLED": {
      return {
        ...state,
        locationsWithSubLocations: action.payload.data.locations,
        loading: false
      };
    }

    case "FETCH_SINGLE_LOCATION_PENDING": {
      return {
        ...state,
        location: null,
        loadingLocation: true,
        noCarParks: false
      };
    }

    case "FETCH_SINGLE_LOCATION_FULFILLED": {
      let location = action.payload.data;

      mapPictures(location);

      const carParks: any[] = [];
      if (location.parkingAddress) {
        const carPark = {
          place_id: 1,
          position: {
            latitude: location.parkingAddress.latitude,
            longitude: location.parkingAddress.longitude
          }
        };
        carParks.push(carPark);
      }

      const hospitals: any[] = [];
      if (location.hospitalAddress) {
        const hospital = {
          organisationId: 2,
          latitude: location.hospitalAddress.latitude,
          longitude: location.hospitalAddress.longitude
        };
        hospitals.push(hospital);
      }

      const availableDates = location.availableDates || [];
      availableDates.forEach((date) => {
        date.start = new Date(date.start);
      });

      location.potentialDates.forEach(d => {
        availableDates.push({ id: d.id, display: 'background', start: new Date(d.start), allDay: true, backgroundColor: "#00ffce", borderColor: "#00ffce", textColor: "#040f21", editable: false, isPotentialDate: true })
      })

      return {
        ...state,
        location: {
          ...location,
          id: action.meta.locationId,
          hospitals: hospitals
        },
        loadingLocation: false,
        errors: null,
        zoomMap: true,
        selectedHospital: null,
        showCarParkList: false,
        showHospitalList: false,
        hospitals: hospitals,
        carParks: carParks,
        daysOfWeek: location.daysOfWeek,
        availableDates: availableDates,
        showAvailability: location.showAvailability
      };
    }

    case LOCATION_GET_COUNTRIES + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case LOCATION_GET_COUNTRIES + "_FULFILLED": {
      return {
        ...state,
        countries: action.payload.data.countries,
        defaultCountryId: action.payload.data.defaultId,
        loading: false
      };
    }

    case LOCATION_GET_COUNTRIES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "CLEAR_LOCATION": {
      return {
        ...state,
        location: { address: { latitude: 0, longitude: 0 } },
        loadingLocation: false,
        errors: null,
        zoomMap: true,
        selectedHospital: null,
        showCarParkList: false,
        selectedCarPark: null,
        showHospitalList: false,
        hospitals: [],
        carParks: [],
        noCarParks: false
      };
    }

    case "UPDATE_SINGLE_LOCATION_PENDING": {
      return {
        ...state,
        loadingLocation: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_LOCATION_FULFILLED": {
      const location = {
        ...action.meta.location,
        ...action.payload.data.location
      };
      const locations: any = state.locations.map((c: any, index: number) => {
        if (c.id === location.id) {
          return { ...c, ...location };
        } else {
          return c;
        }
      });

      sortByName(locations);
      return {
        ...state,
        location,
        locations,
        loadingLocation: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_LOCATION_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingLocation: false
      };
    }

    case "ADD_SINGLE_LOCATION_PENDING": {
      return {
        ...state,
        errors: null,
        loadingLocation: true
      };
    }

    case "ADD_SINGLE_LOCATION_FULFILLED": {
      const location: any = action.payload.data.location;
      let locations = [location, ...state.locations];
      sortByName(locations);
      return {
        ...state,
        location,
        locations,
        loadingLocation: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_LOCATION_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        location: action.meta.location,
        loadingLocation: false
      };
    }

    case "DELETE_SINGLE_LOCATION_PENDING": {
      return {
        ...state,
        loadingLocation: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_LOCATION_FULFILLED": {
      const location = action.meta.location;
      const locations: any = state.locations.filter((c: any, index: number) => {
        return c.id !== location.id;
      });

      return {
        ...state,
        location: null,
        locations,
        loadingLocation: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_LOCATION_REJECTED": {
      return {
        ...state,
        location: action.meta.location,
        errors: action.payload.response.data.errors,
        loadingLocation: false
      };
    }

    case "SET_LOCATION_LAT_LNG": {
      let location: any = state.location || { address: {} };
      location.address.latitude = action.meta.lat;
      location.address.longitude = action.meta.lng;
      return {
        ...state,
        location: { ...location },
        zoomMap: true
      };
    }

    case "FETCH_HOSPITALS_PENDING": {
      return {
        ...state,
        fetchingHospitalList: true
      };
    }

    case "FETCH_HOSPITALS_FULFILLED": {
      let hospitals = action.payload.data.hospitals;
      let hospitalAddress = state.location.hospitalAddress ? { ...state.location.hospitalAddress } : null;
      if (hospitals && hospitals.length) {

        const currentHospitalAddress = state.location.hospitalAddress;

        let pickFirstOne = true;
        if (currentHospitalAddress) {
          const hospitalPlaceId = currentHospitalAddress.placeId;
          const currentHospital = hospitals.find(h => h.organisationId === hospitalPlaceId);
          if (currentHospital) {
            currentHospital.selected = true;
            pickFirstOne = false;
          }
        }

        if (pickFirstOne) {
          let hospital = hospitals[0];
          hospital.selected = true;

          hospitalAddress = mapHospital(hospital);
        }
      }

      return {
        ...state,
        location: {
          ...state.location,
          hospitals: action.payload.data.hospitals,
          hospitalAddress: hospitalAddress
        },
        zoomMap: true,
        showHospitalList: true,
        fetchingHospitalList: false
      };
    }

    case "FETCH_HOSPITALS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        fetchingHospitalList: false
      };
    }

    case "SET_SELECTED_HOSPITAL": {
      let hospitals = [...state.location.hospitals];
      hospitals.forEach((h) => (h.selected = false));
      let selectedHospital = hospitals.find((hospital) => {
        return hospital.organisationId === action.meta.hospital.organisationId;
      });

      selectedHospital.selected = true;

      const hospitalAddress = mapHospital(selectedHospital);

      return {
        ...state,
        location: { ...state.location, hospitals: hospitals, hospitalAddress }
      };
    }

    case "FETCH_CAR_PARKS_PENDING": {
      return {
        ...state,
        fetchingCarParkList: true
      };
    }

    case "FETCH_CAR_PARKS_REJECTED": {
      return {
        ...state,
        fetchingCarParkList: false
      }
    }

    case "FETCH_CAR_PARKS_FULFILLED": {
      const carParks = action.payload.data.results
        .filter(
          (carPark) =>
            carPark.business_status === "OPERATIONAL" &&
            carPark.types[0] === "parking"
        )
        .map((carPark) => {
          return {
            position: {
              latitude: carPark.geometry.location.lat,
              longitude: carPark.geometry.location.lng
            },
            name: carPark.name,
            place_id: carPark.place_id,
            rating: carPark.rating
          };
        });

      if (carParks && carParks.length) {

        const currentCarParkAddress = state.location.carParkAddress;

        let pickFirstOne = true;
        if (currentCarParkAddress) {
          const carParkName = currentCarParkAddress.addressLines[0];
          const currentCarPark = carParks.find(h => h.organisationName === carParkName);
          if (currentCarPark) {
            currentCarPark.selected = true;
            pickFirstOne = false;
          }
        }

        if (pickFirstOne) {
          let carPark = carParks[0];
          carPark.selected = true;
        }
      }

      return {
        ...state,
        fetchingCarParkList: false,
        carParks,
        zoomMap: true
      };
    }

    case "SET_CAR_PARKS": {
      const carParks = action.meta.carParks;
      let selectedCarPark;
      if (carParks && carParks.length) {

        const currentCarParkAddress = state.location.parkingAddress;
        let pickFirstOne = true;
        if (currentCarParkAddress) {
          const carParkPalceId = currentCarParkAddress.placeId;
          const currentCarPark = carParks.find(h => h.place_id === carParkPalceId);
          if (currentCarPark) {
            currentCarPark.selected = true;
            selectedCarPark = currentCarPark;
            pickFirstOne = false;
          }
        }

        if (pickFirstOne) {
          let carPark = carParks[0];
          carPark.selected = true;
        }
      }

      return {
        ...state,
        carParks,
        showCarParkList: true,
        selectedCarPark,
        zoomMap: true,
        noCarParks: false
      };
    }

    case "SET_CAR_PARKS_NONE_FOUND": {
      return {
        ...state,
        noCarParks: true
      };
    }

    case "SET_SELECTED_CAR_PARK": {
      return {
        ...state,
        selectedCarPark: action.meta.carPark
      };
    }

    case "SET_CAR_PARK_ADDRESS": {
      return {
        ...state,
        location: { ...state.location, parkingAddress: action.meta.address }
      };
    }

    case "RESET_ZOOM_MAP": {
      return {
        ...state,
        zoomMap: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        location: null,
        selectedCarPark: null,
        selectedHospital: null,
        redirect: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingLocation: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingLocation: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingLocation: false
      };
    }

    case LOCATION_CHANGE_DAY_OF_WEEK: {
      var daysOfWeek = Array.from(state.daysOfWeek);
      var dayOfWeek: any = daysOfWeek.find(d => d.dayOfWeek === +action.meta.dayOfWeek);
      if (dayOfWeek) {
        dayOfWeek.selected = action.meta.selected;
      }

      let days = Array.from(state.availableDates);
      if (!action.meta.selected) {
        days = days.filter(d => d.isPotentialDate || d.start.getDay() !== dayOfWeek.dayOfWeek);
      }

      return {
        ...state,
        daysOfWeek: daysOfWeek,
        availableDates: days,
        pristine: false
      };
    }

    case LOCATION_UPDATE_EVENT: {
      const days = updateDay(state, action.plainEventObject.id, action.plainEventObject.start);

      return {
        ...state,
        availableDates: days,
        pristine: false
      };
    }

    case LOCATION_ADD_EVENTS: {
      const availableDates = Array.from(state.availableDates);
      let startDate = action.meta.startDate;
      if (startDate < new Date()) {
        startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
      }
      const endDate = action.meta.endDate;
      for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
        const existingDayIndex = availableDates.findIndex(d => !d.isPotentialDate && d.start.getTime() == day.getTime());
        if (existingDayIndex == -1) {

          const dayOfWeek = state.daysOfWeek.find(d => d.dayOfWeek == day.getDay());
          if (dayOfWeek && dayOfWeek.selected) {
            availableDates.push({ id: uuidv4(), start: new Date(day), title: "Available", allDay: true });
          }
        }
      }
      return {
        ...state,
        availableDates: availableDates,
        pristine: false
      };
    }

    case LOCATION_DELETE_EVENT: {
      const availableDates = Array.from(state.availableDates);
      const existingDayIndex = availableDates.findIndex(d => d.id === action.meta.dayId);
      if (existingDayIndex !== -1) {
        availableDates.splice(existingDayIndex, 1);
      }

      return {
        ...state,
        availableDates: availableDates,
        pristine: false
      };
    }

    case LOCATION_DELETE_EVENTS: {
      let availableDates = Array.from(state.availableDates);
      const startDate = action.meta.startDate;
      const endDate = action.meta.endDate;
      availableDates = availableDates.filter(d => d.isPotentialDate || d.start < startDate || d.start > endDate);

      return {
        ...state,
        availableDates: availableDates,
        pristine: false
      };
    }

    case WHAT_THREE_WORDS_LOOKUP + "_PENDING": {

      return {
        ...state,
        searchingWhatThreeWrods: true
      };
    }

    case WHAT_THREE_WORDS_LOOKUP + "_FULFILLED": {
      debugger;
      const result = action.payload.data.googleResult;

      const address = new GoogleAddressParser(
        result.formattedAddress,
        result.addressComponents,
        action.payload.data.latitude,
        action.payload.data.longitude,
        result.placeId
      ).result();


      return {
        ...state,
        latitue: action.payload.data.latitude,
        longitude: action.payload.data.longitude,
        searchingWhatThreeWrods: false
      };
    }

    case WHAT_THREE_WORDS_LOOKUP + "_REJECTED": {
      debugger;
      return {
        ...state,
        searchingWhatThreeWrods: false
      };
    }

    default:
      return state;
  }
};

const mapHospital: any = (hospital) => {
  const hospitalAddressLines: string[] = [];
  hospitalAddressLines.push(hospital.organisationName);
  hospitalAddressLines.push(hospital.address1);
  if (hospital.address2) {
    hospitalAddressLines.push(hospital.address2);
  }
  if (hospital.address3) {
    hospitalAddressLines.push(hospital.address3);
  }
  hospitalAddressLines.push(hospital.city);
  hospitalAddressLines.push(hospital.county);

  const hospitalAddress = {
    addressLines: hospitalAddressLines,
    postCode: hospital.postcode,
    country: hospital.country,
    latitude: hospital.latitude,
    longitude: hospital.longitude,
    placeId: hospital.organisationId
  }

  return hospitalAddress;
}

export default combineReducers({
  rootState: reducer,
  overviewState: overviewReducer
});

