import React from "react";
import { Switch, Route } from "react-router-dom";
import RoleMenu from "./menu";
import ListRolesContainer from "./list-container";
import AddRole from "./add";
import EditRole from "./edit";

import MemberRoutes from "./members/routes";
import { PrivateRoute } from "../../../../shared/private-route";

const RoleRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/departments/:departmentId/roles"
        component={ListRolesContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/departments/:departmentId/roles/add"
        component={AddRole}
      />
      <PrivateRoute
        path="/films/:filmId/departments/:departmentId/roles/:roleId"
        component={RoleMenu}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/departments/:departmentId/roles/:roleId/edit"
        component={EditRole}
      />
      <MemberRoutes />
    </>
  );
};

export default RoleRoutes;
