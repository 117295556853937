import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../../../shared/errors";
import { renderSelectField, renderSingleReactSelect } from "./../../../../../shared/form-helpers";

function CrewMemberForm(props) {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    allMembers,
    errors,
    mode,
    loading
  } = props;
  const thisMember = props.initialValues || {};

  const membersOptions = (allMembers || []).map((item) => { return { value: item.id, label: item.name } });

  return (
    <>
      <Prompt when={!pristine} message="" />
      <h2 className="menu-label">Add Existing Crew Member</h2>
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="memberId"
          label="Select Member"
          component={renderSingleReactSelect}
          options={membersOptions}
        >
        </Field>

        <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export default reduxForm({
  form: "addExistingCrewMemberForm",
  enableReinitialize: true
})(CrewMemberForm);
