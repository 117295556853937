import React from "react";
import { NavLink } from "react-router-dom";

const EquipmentCategoriesList = ({ equipments, filmId, categoryId, readOnly }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Equipment</p>
      <ul className="menu-list">
        {!readOnly && <> <li>
          <NavLink
            to={`/films/${filmId}/equipment-category/${categoryId}/edit`}
            activeClassName="is-active"
            isActive={(match, location) => {
              return location.pathname.startsWith(
                `/films/${filmId}/equipment-category/${categoryId}/edit`
              );
            }}>
            Edit Category
          </NavLink>
        </li>
          <hr />
        </>}

        {(equipments || []).map((equipment, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/equipment-category/${categoryId}/equipment/${equipment.id}/edit`}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/equipment-category/${categoryId}/equipment/${equipment.id}/`
                  );
                }}>
                {equipment.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default EquipmentCategoriesList;
