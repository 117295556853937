export default (props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="33.187" height="23.361" viewBox="0 0 25.742 18.742"  {...props}>
    <g id="Group_3854" data-name="Group 3854" transform="translate(0.5 0.5)">
      <g id="Group_3853" data-name="Group 3853" transform="translate(1.717 3.063)">
        <path id="Path_32869" data-name="Path 32869" d="M25.305,14.121l-21.16-2.14a.328.328,0,0,0-.361.3l-1.2,11.878a.331.331,0,0,0,.3.362l21.159,2.14a.329.329,0,0,0,.362-.3l1.2-11.878A.331.331,0,0,0,25.305,14.121Z" transform="translate(-2.58 -11.979)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_32870" data-name="Path 32870" d="M6.228,22.737a.31.31,0,0,0,.208.111L20.884,24.31a.314.314,0,0,0,.227-.067l1.621-1.307a.313.313,0,0,0,.115-.211l.5-4.991a.316.316,0,0,0-.068-.227l-1.3-1.6a.31.31,0,0,0-.21-.113L7.3,14.327a.313.313,0,0,0-.226.067L5.45,15.7a.309.309,0,0,0-.115.211L4.83,20.9a.312.312,0,0,0,.07.229Z" transform="translate(-2.58 -11.979)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <g id="Group_3852" data-name="Group 3852" transform="translate(4.244 4.58)">
          <path id="Path_32871" data-name="Path 32871" d="M9.262,18.83a1.222,1.222,0,1,0-1.339,1.093h0A1.224,1.224,0,0,0,9.262,18.83Z" transform="translate(-6.824 -16.559)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32872" data-name="Path 32872" d="M20.013,21.146a1.222,1.222,0,1,0-1.093-1.339h0A1.224,1.224,0,0,0,20.013,21.146Z" transform="translate(-6.824 -16.559)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
          <path id="Path_32873" data-name="Path 32873" d="M13.813,22.064a2.76,2.76,0,1,0-2.468-3.023h0A2.763,2.763,0,0,0,13.813,22.064Z" transform="translate(-6.824 -16.559)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        </g>
      </g>
      <path id="Path_32874" data-name="Path 32874" d="M22.46,11.586V8.916H1.193a.33.33,0,0,0-.33.33V21.185" transform="translate(-0.863 -8.916)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    </g>
  </svg>
