import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import {
  deleteShootingDayScheduleEvent,
  fetchShootingDaysShotListSchedule,
  updateShotOrder,
  addTimeSlot,
  addCrewTimeSlot,
  deleteTimeSlot,
  updateTimeSlotOrder,
  updateShotListSchedule,
  deleteShot,
  selectShots,
  selectAllShots,
  selectNoShots,
  unselectAll,
  startTimeChange,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  setDraggingShotId,
  downloadShotList,
  clearPdf,
  clearDownload,
  timeSlotStartTimeChanged,
  emailShotList,
  crewEventDescriptionChanged,
  mealTimeSlotChanged,
  includeImagesChanged,
  includeEquipmentChanged
} from "./ducks/actions";
import List from "./list";
import Onboarding from '../../../shared/onboarding/onboarding';
import { Prompt } from "react-router-dom";

function ShotListScheduleContainer(props) {
  const { filmId, shootingdayId } = props.match.params;
  const {
    fetchShootingDaysShotListSchedule,
    updateShotListSchedule,
    deleteShot,
    deleteTimeSlot,
    scenes,
    updateShotOrder,
    addTimeSlot,
    addCrewTimeSlot,
    loading,
    shotListSchedule,
    selectedShotIds,
    draggingShotId,
    toggleSelectionInGroup,
    unselectAll,
    errors,
    sceneSettings,
    crewCallHour,
    selectShots,
    selectAllShots,
    selectNoShots,
    estimatedWrapHour,
    estimatedWrapTime,
    toggleSelection,
    multiSelectTo,
    setDraggingShotId,
    progress,
    download,
    clearPdf,
    clearDownload,
    downloadShotList,
    readOnly,
    timeSlotStartTimeChanged,
    emailShotList,
    onboardingSteps,
    crewEventDesciption,
    mealTimeSlot,
    crewEventDescriptionChanged,
    mealTimeSlotChanged,
    includeImages,
    includeImagesChanged,
    includeEquipment,
    includeEquipmentChanged,
    pristine,
    saving,
    canAccessScenes,
    canAccessShots
  } = props;

  const [addTimeSlotTabIndex, setAddTimeSlotTabIndex] = useState<number | null>(null);

  useEffect(() => {
    clearDownload();
    fetchShootingDaysShotListSchedule(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  const onUpdateShotListSchedule = () => {
    updateShotListSchedule(shotListSchedule, filmId, shootingdayId, includeImages, includeEquipment);
  };

  const onDownloadFile = () => {
    downloadShotList(filmId, shootingdayId, includeImages, includeEquipment);
  }

  const onEmailShotList = () => {
    emailShotList(filmId, shootingdayId, includeImages, includeEquipment);
  }

  function renderLoading() {
    return <Loader />;
  }

  const joyrideCallbackFunctions = {};

  joyrideCallbackFunctions[7] = () => {
    setAddTimeSlotTabIndex(1);
  }

  joyrideCallbackFunctions[9] = () => {
    setAddTimeSlotTabIndex(0);
  }

  function renderShootingDays(shotListSchedule, filmId, shootingDayId) {
    console.log("draggingShotId", draggingShotId);
    return (
      <div className="blade-content widest">
        <p className="menu-label">Shot List Schedule</p>
        <Prompt when={!pristine} message="" />
        {saving && <Loader />}
        <List
          shotListSchedule={shotListSchedule}
          scenes={scenes}
          filmId={filmId}
          shootingdayId={shootingdayId}
          updateShotOrder={updateShotOrder}
          addTimeSlot={addTimeSlot}
          addCrewTimeSlot={addCrewTimeSlot}
          deleteTimeSlot={deleteTimeSlot}
          updateTimeSlotOrder={updateTimeSlotOrder}
          onUpdateShotListSchedule={onUpdateShotListSchedule}
          timeSlotStartTimeChanged={timeSlotStartTimeChanged}
          selectedShotIds={selectedShotIds}
          draggingShotId={draggingShotId}
          toggleSelection={toggleSelection}
          toggleSelectionInGroup={toggleSelectionInGroup}
          selectShots={selectShots}
          selectAllShots={selectAllShots}
          selectNoShots={selectNoShots}
          multiSelectTo={multiSelectTo}
          setDraggingShotId={setDraggingShotId}
          unselectAll={unselectAll}
          deleteShot={deleteShot}
          sceneSettings={sceneSettings}
          crewCallHour={crewCallHour}
          estimatedWrapHour={estimatedWrapHour}
          estimatedWrapTime={estimatedWrapTime}
          errors={errors}
          progress={progress}
          onDownloadFile={onDownloadFile}
          download={download}
          clearPdf={clearPdf}
          onEmailShotList={onEmailShotList}
          readOnly={readOnly}
          addTimeSlotTabIndex={addTimeSlotTabIndex}
          crewEventDesciption={crewEventDesciption}
          mealTimeSlot={mealTimeSlot}
          crewEventDescriptionChanged={crewEventDescriptionChanged}
          mealTimeSlotChanged={mealTimeSlotChanged}
          includeImages={includeImages}
          includeImagesChanged={includeImagesChanged}
          includeEquipment={includeEquipment}
          includeEquipmentChanged={includeEquipmentChanged}
          pristine={pristine}
          canAccessScenes={canAccessScenes}
          canAccessShots={canAccessShots}></List>
        <Onboarding onboardingSteps={onboardingSteps} section="Shot List Schedule" callbackFunctions={joyrideCallbackFunctions} />
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderShootingDays(shotListSchedule, filmId, shootingdayId);
}

function mapStateToProps(state) {
  return {
    shotListSchedule:
      state.shootingdaysState.shotListScheduleState.shotListSchedule,
    scenes: state.shootingdaysState.shotListScheduleState.scenes,
    selectedShotIds:
      state.shootingdaysState.shotListScheduleState.selectedShotIds,
    draggingShotId:
      state.shootingdaysState.shotListScheduleState.draggingShotId,
    totalPages: state.shootingdaysState.shotListScheduleState.totalPages,
    loading: state.shootingdaysState.shotListScheduleState.loading,
    errors: state.shootingdaysState.shotListScheduleState.errors,
    sceneSettings: state.shootingdaysState.shotListScheduleState.sceneSettings,
    crewCallHour: state.shootingdaysState.shotListScheduleState.crewCallHour,
    estimatedWrapHour:
      state.shootingdaysState.shotListScheduleState.estimatedWrapHour,
    estimatedWrapTime:
      state.shootingdaysState.shotListScheduleState.estimatedWrapTime,
    progress: state.shootingdaysState.shotListScheduleState.progress,
    download: state.shootingdaysState.shotListScheduleState.download,
    readOnly: state.shootingdaysState.shotListScheduleState.readOnly,
    onboardingSteps: state.shootingdaysState.shotListScheduleState.onboardingSteps,
    crewEventDesciption: state.shootingdaysState.shotListScheduleState.crewEventDesciption,
    mealTimeSlot: state.shootingdaysState.shotListScheduleState.mealTimeSlot,
    includeImages: state.shootingdaysState.shotListScheduleState.includeImages,
    includeEquipment: state.shootingdaysState.shotListScheduleState.includeEquipment,
    pristine: state.shootingdaysState.shotListScheduleState.pristine,
    saving: state.shootingdaysState.shotListScheduleState.saving,
    canAccessScenes: state.shootingdaysState.shotListScheduleState.canAccessScenes,
    canAccessShots: state.shootingdaysState.shotListScheduleState.canAccessShots
  };
}

export default connect(mapStateToProps, {
  fetchShootingDaysShotListSchedule,
  addTimeSlot,
  addCrewTimeSlot,
  updateShotOrder,
  deleteShootingDayScheduleEvent,
  updateShotListSchedule,
  deleteShot,
  deleteTimeSlot,
  startTimeChange,
  selectShots,
  selectAllShots,
  selectNoShots,
  toggleSelection,
  unselectAll,
  multiSelectTo,
  toggleSelectionInGroup,
  setDraggingShotId,
  downloadShotList,
  clearPdf,
  clearDownload,
  timeSlotStartTimeChanged,
  emailShotList,
  crewEventDescriptionChanged,
  mealTimeSlotChanged,
  includeImagesChanged,
  includeEquipmentChanged
})(ShotListScheduleContainer);
