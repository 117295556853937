import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../../shared/loader";
import { fetchCollaboratorRoles } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import Onboarding from '../../../shared/onboarding/onboarding';
import { OnboardingStep1ClassName } from './ducks/reducer';
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListUsersContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchCollaboratorRoles, loading, roles, errors, readOnly, onboardingSteps } = props;

  useEffect(() => {
    fetchCollaboratorRoles(filmId, true);
  }, [filmId, fetchCollaboratorRoles]);

  function renderLoading() {
    return <Loader />;
  }

  function renderRoles(roles, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/collaborators/collaborator-roles"
        loading={loading}>
        {loading && <Loader />}
        <List users={roles} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && (
          <>
            <NavLink
              to={`/films/${filmId}/collaborators/collaborator-roles/add`}
              activeClassName={
                "is-active " + window.location.pathname.endsWith("add")
                  ? "disabled"
                  : ""
              }
              className={`button is-transparent is-add`}>
              <AddIcon className="white-icon" />
              Add Collaborator Role
            </NavLink>
          </>
        )}

      </ScrollIntoView>
    );
  }

  return renderRoles(roles, filmId);
}

function mapStateToProps(state) {
  const { collaboratorRolesState } = state;
  return {
    roles: collaboratorRolesState.roles,
    readOnly: collaboratorRolesState.readOnly,
    loading: collaboratorRolesState.loading,
    errors: collaboratorRolesState.errors,
    onboardingSteps: collaboratorRolesState.onboardingSteps
  };
}

export default connect(mapStateToProps, {
  fetchCollaboratorRoles
})(ListUsersContainer);
