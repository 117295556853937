import React, { useState } from "react";
import Social from "./social";
import InstagramIcon from "../shared/icons/socials/instagram";
import YouTubeIcon from "../shared/icons/socials/youtube";
import TwitterIcon from "../shared/icons/socials/twitter";
import LinkedInIcon from "../shared/icons/socials/linkedin";
import DiscordIcon from "../shared/icons/socials/discord";
import FacebookIcon from "../shared/icons/socials/facebook";
import './socials.scss';

const Socials = () => {

    return (
        <div className="is-centered socials">
            <div className="socials-container">
                <Social link="https://www.instagram.com/filmanize/" title="Follow us on Instagram" Icon={InstagramIcon} />
                <Social link="https://www.youtube.com/@filmanize" title="Subscribe to our YouTube channel" Icon={YouTubeIcon} />
                <Social link="https://x.com/filmanize" title="Follow us on X" Icon={TwitterIcon} />
                <Social link="https://www.linkedin.com/company/film-tools" title="Follow us on LinkedIn" Icon={LinkedInIcon} />
                <Social imageName="discord" link="https://discord.gg/4G4E87Y5E4" title="Join us on Discord" Icon={DiscordIcon} />
                <Social link="https://www.facebook.com/groups/filmanize" title="Follow us on Facebook" Icon={FacebookIcon} />
            </div>
        </div>
    )
}

export default Socials;