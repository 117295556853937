import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { NavLink } from "react-router-dom";
import { ItemTypes } from "./constants";
import CanAccessNavLink from "../../../shared/can-access-nav-link";

function SceneSettingLocation(props) {
  const {
    sceneSetting,
    location,
    index,
    filmId,
    assignLocation,
    unassignLocation,
    replaceLocation,
    aliasSceneSetting,
    unaliasSceneSetting,
    readOnly,
    canAccessShootingLocations
  } = props;

  const hasAliases = (sceneSetting.aliases || []).length > 0;

  const dropLocationOnAdd = (item) => {
    assignLocation(filmId, item, sceneSetting);
  };

  const sceneSettingDropped = (alias) => {
    aliasSceneSetting(filmId, alias, sceneSetting);
  };

  const dropLocationOnExisting = (newLocation) => {
    replaceLocation(filmId, newLocation, location, sceneSetting);
  };

  const canDropLocation = (item, monitor) => {
    const existingLocation = sceneSetting.locations?.find(
      (l) =>
        l.id === item.location.id &&
        l.subLocation?.id === item.location.subLocation?.id
    );
    console.log("canDropLocation", !existingLocation);
    return !existingLocation;
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.LOCATION,
    drop: (item: any) => dropLocationOnExisting(item.location),
    canDrop: (item, monitor) => canDropLocation(item, monitor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  const getItemStyle = (isDragging) => ({
    background: isDragging ? "#947eed" : "#040f21"
  });

  let locationName = location.name;
  if (location.subLocation?.name) {
    locationName += " - " + location.subLocation.name;
  }

  return (
    <>
      <div ref={drop} style={getItemStyle(isOver)}>
        <CanAccessNavLink to={`/films/${filmId}/locations/${location.id}/edit`} canAccess={canAccessShootingLocations}>
          {locationName}
        </CanAccessNavLink>
        &nbsp;
        {!readOnly && (
          <a onClick={() => unassignLocation(filmId, sceneSetting, location)}>
            <i className="material-icons">cancel</i>
          </a>
        )}
      </div>
    </>
  );
}

export default SceneSettingLocation;
