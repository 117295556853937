import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../../shared/loader";
import { fetchTakes } from "./ducks/actions";
import List from "./list";

function ListTakesContainer(props) {
  const { filmId, sceneId, shotId } = props.match.params;
  const { fetchTakes, loading, takes, errors } = props;

  useEffect(() => {
    fetchTakes(filmId, sceneId, shotId);
  }, [filmId, sceneId, shotId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderTakes(takes, filmId, sceneId, shotId) {
    return (
      <div className="blade-menu">
        <List takes={takes} filmId={filmId} sceneId={sceneId} shotId={shotId} />
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderTakes(takes, filmId, sceneId, shotId);
}

function mapStateToProps(state) {
  const { takes, loading, errors } = state.takesState;
  return { takes, loading, errors };
}

export default connect(mapStateToProps, {
  fetchTakes
})(ListTakesContainer);
