import React, { useEffect } from "react";
import { connect } from "react-redux";
import UserCommunicationPreferencesForm from "./form";
import { fetchUserCommunicationPreferences, updateUserCommunicationPreferences } from "./ducks/actions";
import Loader from "../../shared/loader";
import Onboarding from '../../shared/onboarding/onboarding';
import BoxWraper from "../../account/box-wrapper";

const UserCommunicationPreferences = (props: any) => {
  const {
    fetchUserCommunicationPreferences,
    updateUserCommunicationPreferences,
    userCommunicationPreferences,
    loading,
    errors,
    onboardingSteps,
    weightUnitEnum
  } = props;

  useEffect(() => {
    fetchUserCommunicationPreferences();
  }, [fetchUserCommunicationPreferences]);

  function onUpdateUserCommunicationPreferences(userCommunicationPreferences) {
    updateUserCommunicationPreferences(userCommunicationPreferences);
  }

  const notificationsGranted = Notification?.permission === "granted";
  const notificationsDefault = Notification?.permission === "default";
  
  const onEnableNotifications = (event) => {
    event.preventDefault();
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  };

  return (
    <>
      <BoxWraper>
        {loading && <Loader />}
        {!loading && (
          <UserCommunicationPreferencesForm
            initialValues={userCommunicationPreferences}
            userCommunicationPreferences={userCommunicationPreferences}
            onSubmit={onUpdateUserCommunicationPreferences}
            errors={errors}
            onboardingSteps={onboardingSteps}
            weightUnitEnum={weightUnitEnum}
            notificationsGranted={notificationsGranted}
            notificationsDefault={notificationsDefault}
            onEnableNotifications={onEnableNotifications}
            {...props}
          />
        )}
      </BoxWraper>
    </>
  );
};

const mapStateToProps = function (state) {
  const { userCommunicationPreferencesState, commonState } = state;

  return {
    userCommunicationPreferences: userCommunicationPreferencesState.userCommunicationPreferences,
    errors: userCommunicationPreferencesState.errors,
    loading: userCommunicationPreferencesState.loading,
    onboardingSteps: userCommunicationPreferencesState.onboardingSteps    
  };
};

export default connect(mapStateToProps, {
  fetchUserCommunicationPreferences,
  updateUserCommunicationPreferences
})(UserCommunicationPreferences);
