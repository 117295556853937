const defaultState = {
  actors: [],
  loading: true,
  readOnly: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTING_DAY_EQUIPMENT_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_SHOOTING_DAY_EQUIPMENT_FULFILLED": {      
      return {
        ...state,
        equipmentCategories: action.payload.data.equipmentCategories,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "UPDATE_SHOOTING_DAY_EQUIPMENT_PENDING": {
      return {
        ...state,
        errors: null
      };
    }

    case "UPDATE_SHOOTING_DAY_EQUIPMENT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "UPDATE_SHOOTING_DAY_EQUIPMENT_FULFILLED": {
      return {
        ...state,
        equipmentCategories: action.meta.equipmentCategories.equipmentCategories,
        errors: null,
        loading: false,
        saved: true
      };
    }

    case "RESET_SHOOTING_DAY_EQUIPMENT": {
      return {
        ...state,
        saved: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
