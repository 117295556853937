interface ActionState {
  loading: boolean;
  character: any;
  readOnly: boolean;
  scenes: any[];
  findingInScript: boolean;
  searchingScenes: boolean;
}

const defaultState: ActionState = {
  loading: true,
  character: {},
  readOnly: false,
  scenes: [],
  findingInScript: false,
  searchingScenes: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_ALL_SCENES_FOR_BACKGROUND_CHARACTER_FULFILLED": {
      const scenes = action.payload.data.scenes.map((scene) => {
        return { ...scene, name: `${scene.number}. ${scene.settingName}` };
      });
      return {
        ...state,
        scenes,
        loading: false,
        errors: null
      };
    }

    case "SET_SCENE_ASSIGNED_TO_BACKGROUND_CHARACTER": {
      return {
        ...state,
        selectedSceneIds: [action.meta.sceneId]
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_BACKGROUND_CHARACTER_FULFILLED": {
      return {
        ...state,
        selectedSceneIds: action.payload.data.sceneIds,
        loading: false,
        errors: null
      };
    }

    case "ASSIGN_SCENES_TO_BACKGROUND_CHARACTER_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "FETCH_ALL_SCENE_SETTINGS_FOR_BACKGROUND_CHARACTER_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case "SEARCH_SCENES_FOR_BACKGROUND_CHARACTER_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case "SEARCH_SCENES_FOR_BACKGROUND_CHARACTER_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        searchingScenes: false,
        errors: null
      };
    }

    case "SEARCH_SCENES_FOR_BACKGROUND_CHARACTER_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "FETCH_SCRIPT_DAYS_FOR_BACKGROUND_CHARACTER_FULFILLED": {
      return {
        ...state,
        scriptDays: action.payload.data.days,
        loading: false,
        errors: null
      };
    }

    case "BACKGROUND_CHARACTER_SEARCH_SCRIPT_PENDING": {
      return {
        ...state,
        findingInScript: true,
        errors: null
      };
    }

    case "BACKGROUND_CHARACTER_SEARCH_SCRIPT_FULFILLED": {
      let searchedScenes = action.payload.data.scenes;
      const sceneIds = state.scenes.map(s => s.id);
      const filteredScenes = searchedScenes.filter(s => sceneIds.indexOf(s) > -1);
      return {
        ...state,
        searchedScenes: filteredScenes.map((id) => { return { id } }),
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        findingInScript: false,
        errors: null
      };
    }

    case "BACKGROUND_CHARACTER_SEARCH_SCRIPT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
        findingInScript: false,
      };
    }

    case "CLEAR_SEARCH_SCENES_FOR_BACKGROUND_CHARACTER": {
      return {
        ...state,
        searchedScenes: [],
        loading: false,
        errors: null
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
