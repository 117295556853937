import getApiClient from "./../../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../../notifications/ducks/actions";

export function clearRole() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_ROLE"      
    });
  };
}

export function fetchRoles(filmId, departmentId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_ROLES",
      payload: getApiClient().get(`/film/${filmId}/CrewDepartment/${departmentId}/CrewRole`),
    });
  };
}

export function fetchRole(filmId, departmentId, roleId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_ROLE",
      payload: getApiClient().get(`/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${roleId}`),
    });
  };
}

export function updateRole(filmId, departmentId, role) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_ROLE",
      payload: getApiClient().put(`/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${role.id}`, role),
      meta: { role },
    })
      .then((response) => {
        dispatch(showInfo(`Updated role ${role.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating role ${role.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addRole(filmId, departmentId, role) {
  role.mainImageUrl = null;
  role.imageUrls = [];

  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_ROLE",
      payload: getApiClient().post(`/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/`, role),
      meta: { role },
    })
      .then((response) => {
        dispatch(showInfo(`Added role ${role.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding role`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteRole(filmId, departmentId, role) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_ROLE",
      payload: getApiClient().delete(`/film/${filmId}/CrewDepartment/${departmentId}/CrewRole/${role.id}`),
      meta: { role },
    })
      .then((response) => {
        dispatch(showInfo(`Deleted role ${role.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting role ${role.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT",
    });
  };
}
