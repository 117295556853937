import { useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import Moment from 'react-moment';
import { select, curveMonotoneY } from 'd3';
import * as d3 from 'd3';
import FormErrors from "../../shared/form-errors";
import Errors from "../../shared/errors";

function UsersForm(props) {
  const {
    handleSubmit,
    errors,
    submitFailed,
    invalid,
    dates,
    refresh,
    error
  } = props;

  const csvData = dates?.map((country: any) => {
    return [country.date, country.userCount];
  });

  const svgRef: any = useRef();

  type DateValue = {
    date: string;
    userCount: number;
  }

  type DateCumulative = {
    date: Date;
    userCount: number;
    userTotal: number
  }

  let runningTotal = 0;
  const cumulativeDates: DateCumulative[] = [];
  dates.forEach((date: any) => {
    const originalCount = date.userCount;
    runningTotal += originalCount;
    cumulativeDates.push({ date: new Date(date.date), userCount: date.userCount, userTotal: date.userCount + runningTotal });
  });

  const fontSize = 30;

  const drawPie = (dates: DateValue[]) => {
    // set the dimensions and margins of the graph
    var width = 1200,
      height = 700;


    var margin = { top: 30, right: 10, bottom: 70, left: 80 };

    const svg: any = select(svgRef.current!);




    //const extend = d3.extent(cumulativeDates, function (d: DateValue) { return d.date; })
    //const yExtend: [number, number] = d3.extent(cumulativeDates, function (d: DateCumulative) { return d.userTotal; })
    const maxUserTotal = cumulativeDates[cumulativeDates.length - 1].userTotal;

    const xScale = d3.scaleTime()
      .domain([new Date('2022-09-01'), new Date()])
      .range([margin.left, width - margin.right]);


    //const boundsWidth = width - MARGIN.right - MARGIN.left;
    const boundsHeight = height - 30;

    const yScale = d3
      .scaleLinear()
      .domain([0, maxUserTotal])
      .range([height - margin.top - margin.bottom, margin.top])
      .nice();

    const lineBuilder = d3
      .line()
      .curve(curveMonotoneY)
      .x((d: any) => xScale(d.date))
      .y((d: any) => yScale(d.userTotal));


    const path = svg.append("path")
      .datum(cumulativeDates)
      .attr("fill", "none")
      .attr("stroke", "#947eed")
      .attr("stroke-width", 4)
      .attr("d", lineBuilder)

    const pathLength = path.node().getTotalLength();

    const transitionPath = d3
      .transition()
      .ease(d3.easeSin)
      .duration(4000);

    path
      .attr("stroke-dashoffset", pathLength)
      .attr("stroke-dasharray", pathLength)
      .transition(transitionPath)
      .attr("stroke-dashoffset", 0);


    svg
      .append("g")
      .attr("transform", "translate(0, " + (height - margin.bottom - margin.top) + ")")      // This controls the vertical position of the Axis
      .attr("stroke-width", "3px")
      .style("font-size", `${fontSize}px`)
      .style("font-family", "manrope")
      .call(d3.axisBottom(xScale).tickSizeInner(15).tickSizeOuter(0))
      .selectAll("text")
      .attr("transform", "translate(0," + 10 + ")");

    svg
      .append("g")
      .attr("transform", "translate(" + margin.left + ", " + 0 + ")")      // This controls the vertical position of the Axis
      .attr("stroke-width", "3px")
      .style("font-size", `${fontSize}px`)
      .style("font-family", "manrope")
      .call(d3.axisLeft(yScale).tickSizeInner(10).tickSizeOuter(0))
      .selectAll("text")
      .attr("transform", "translate(-5,0)");


  }

  useEffect(() => {
    if (dates.length) {
      drawPie(dates);
    }
  }, [dates?.length]);
  //transform="translate(600 200)"
  return (
    <>
      <h2 className="menu-label">Users</h2>
      <svg width={1920} height={1080}>
        <g >
          <g ref={svgRef}>
          </g>

        </g>
      </svg>
      {errors && <Errors errors={errors} />}
      <button className="button mb-1 mt-2" onClick={refresh}>Refresh</button>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Date</th>
                <th>User Count</th>
                <th>User Total</th>
              </tr>
            </thead>
            <tbody>
              {(cumulativeDates || []).map((date, idx, records) => {
                return (
                  <tr key={idx}>
                    <td><Moment date={date.date} format="DD/MM/yyyy" /></td>
                    <td>{date.userCount}</td>
                    <td>{date.userTotal}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {csvData && <CSVLink className="button mb-3 mt-1" data={csvData} filename={`User Dates ${convertDateToUTCISO(new Date())}`}>Export</CSVLink>}
          {error && <div className="error">{error}</div>}
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export const convertDateToUTCISO = (date: Date) => {
  var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(),
    date.getDate(), 0,
    0, 0));
  return utcDate.toISOString().substring(0, 10);
}

export default UsersForm;