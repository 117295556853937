import React from "react";

const address = (props) => {
  if (!props.address) {
    return null;
  }

  return (
    <div>
      <label>
        <div>
          {props.address?.addressLines?.map((addressLine, index) => (
            <p key={index}>{addressLine}</p>
          ))}
          <p>{props.address.postCode}</p>
          <p>{props.address.country}</p>
        </div>
      </label>
    </div>
  );
};

export default address;
