import React from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import { renderTextField } from "./../../../shared/form-helpers";
import required from "./../../../shared/validation";

function CrewDepartmentForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    readOnly
  } = props;
  const thisDepartment = props.initialValues || {};

  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="name"
          label="Department Name"
          component={renderTextField}
          validate={[required]}
          required
          readOnly={readOnly}
        />

        {!readOnly && <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>

          {mode === "edit" && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !props.initialValues}
              onClick={() => handleDelete(thisDepartment)}>
              Delete
            </button>
          )}
        </div>}
      </form>
    </>
  );
}

export default reduxForm({
  form: "crewDepartmentForm",
  enableReinitialize: true
})(CrewDepartmentForm);
