import { clearNotifications, showError, showInfo } from "../../../../notifications/ducks/actions";
import { getAnnonymousApiClient } from "./../../../../shared/api";

export const FETCH_DIETARY_REQUIREMENTS = "FETCH_DIETARY_REQUIREMENTS";
export const FETCH_DIETARY_REQUIREMENTS_ENUMS = "FETCH_DIETARY_REQUIREMENTS_ENUMS";
export const UPDATE_DIETARY_REQUIREMENTS = "UPDATE_DIETARY_REQUIREMENTS";
export const FETCH_DRINKS_AND_MILK_TYPES = "FETCH_DRINKS_AND_MILK_TYPES";

export function fecthDietaryRequirements(token) {
  return (dispatch) => {
    dispatch({
      type: FETCH_DIETARY_REQUIREMENTS,
      payload: getAnnonymousApiClient().get(`/DietaryRequirements?token=${token}`),
    });
  };
}

export function fetchDietaryRequirementsEnum() {
  return (dispatch) => {
    dispatch({
      type: FETCH_DIETARY_REQUIREMENTS_ENUMS,
      payload: getAnnonymousApiClient().get(`/enums/DietaryRequirement`),
    });
  };
}

export function fetchDrinksAndMilkTypes() {
  return (dispatch) => {
    dispatch({
      type: FETCH_DRINKS_AND_MILK_TYPES,
      payload: getAnnonymousApiClient().get(`/lookup/drinksAndMilkTypes`),
    });
  };
}

export function updateDietaryRequirements(token, dietaryRequirements) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIETARY_REQUIREMENTS,
      payload: getAnnonymousApiClient().put(`/DietaryRequirements`, { ...dietaryRequirements, token }),
    }).then((response) => {
      dispatch(showInfo(`Updated dietary requirements`));
    })
      .catch((response) => {
        dispatch(
          showError(`Error updating dietary requirements`)
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}