import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../../shared/loader";
import { fetchMakeups } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";

function ListMakeupsContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchMakeups, loading, makeups, errors, readOnly } = props;

  useEffect(() => {
    fetchMakeups(filmId);
  }, [filmId, fetchMakeups]);

  function renderLoading() {
    return <Loader />;
  }

  function renderMakeups(makeups, filmId) {
    return (
      <ScrollIntoView
      className="blade-menu"
      path="/films/:filmId/makeup">
        <List makeups={makeups} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && (
          <NavLink
            to={`/films/${filmId}/makeup/add`}
            activeClassName={
              "is-active " + window.location.pathname.endsWith("add")
                ? "disabled"
                : ""
            }
            className="button is-transparent is-add">
            <AddIcon className="white-icon" />
            Add Makeup
          </NavLink>
        )}
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderMakeups(makeups, filmId);
}

function mapStateToProps(state) {
  const { makeups, loading, errors, readOnly } = state.makeupState;
  return { makeups, loading, errors, readOnly };
}

export default connect(mapStateToProps, {
  fetchMakeups
})(ListMakeupsContainer);
