import React from "react";
import NumberFormat from "react-number-format";

export default function CurrencyInput(props) {
  const { value, onChange, readOnly, placeholder, currencySymbol, disabled } = props;

  const onValChanged = (event) => {
    if (event.floatValue === undefined) {
      onChange(null);
    } else {
      onChange(event.floatValue);
    }
  };

  const MAX_VAL = 10000000000;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value < MAX_VAL) {
      return inputObj;
    }
  };

  return (
    <>
      {!readOnly && (
        <NumberFormat
          className="input"
          value={value}
          placeholder={placeholder}
          thousandSeparator={true}
          fixedDecimalScale={true}
          prefix={currencySymbol}
          decimalScale={2}
          isNumericString={true}
          onValueChange={onValChanged}
          isAllowed={withValueLimit}
          disabled={disabled}
        />
      )}
      {readOnly && (
        <span>
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={currencySymbol}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </span>
      )}
    </>
  );
}