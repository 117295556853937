import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

function CurrencyDisplay(props) {
  const { value, currencySymbol, className } = props;

  return (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={currencySymbol}
            decimalScale={2}
            fixedDecimalScale={true}
            className={className}
          />
  );
}

const mapStateToProps = function (state) {  
  return {    
    currencySymbol: state.filmsState?.userFilm?.currencySymbol
  };
};

export default connect(mapStateToProps)(CurrencyDisplay);
