import AvailabilityActorsTable from './actors-table';
import './availability.scss';
import Moment from "react-moment";

const Availability = ({
  filmId,
  dates,
  datesTotals,
  actorsAvailability,
  backgroundActorsAvailability,
  crewMemberAvailability,
  locationsAvailability,
  canAccessActors,
  canAccessBackgroundActors,
  canAccessCrew,
  readOnly }) => {

  const filteredDates = dates;

  const crewMemberLinkGenerator = (crewMember) => {
    return `/films/${filmId}/departments/${crewMember.crewDepartmentId}/roles/${crewMember.crewRoleId}/members/${crewMember.id}/edit`;
  }

  return (
    <section>
      <div className="day-out-days-table-container">
        <table className="table day-out-days-table">
          <thead className="day-out-days-table-header-row">
            <tr>
              <th className="day-out-days-table-actor-column-header">Date</th>
              <th></th>
              {(filteredDates || []).map((day: any, index: number) => (<th className="has-text-centered" key={index}><Moment format="ddd">{day}</Moment><br /><Moment format="DD/MM/YYYY">{day}</Moment></th>))}
            </tr>
            <tr>
              <th className="day-out-days-table-actor-column-header">Total</th>
              <th></th>
              {(datesTotals || []).map((total: any, index: number) => (<th className="has-text-centered" key={index}>{total}</th>))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="day-out-days-table-actor-column has-text-weight-bold">Actors</th><td colSpan={filteredDates.length + 1}></td>
            </tr>
            <AvailabilityActorsTable
              dates={dates}
              people={actorsAvailability}
              filmId={filmId}
              sectionLink="actors"
              canAccess={canAccessActors} />
            {backgroundActorsAvailability?.length > 0 && <> <tr>
              <th className="day-out-days-table-actor-column has-text-weight-bold">Background Actors</th><td colSpan={filteredDates.length + 1}></td>
            </tr>
              <AvailabilityActorsTable
                dates={dates}
                people={backgroundActorsAvailability}
                filmId={filmId}
                sectionLink="background-actors"
                canAccess={canAccessBackgroundActors} />
            </>
            }
            <tr>
              <th className="day-out-days-table-actor-column has-text-weight-bold">Crew</th><td colSpan={filteredDates.length + 1}></td>
            </tr>
            <AvailabilityActorsTable
              dates={dates}
              people={crewMemberAvailability}
              filmId={filmId}
              sectionLink="crew"
              canAccess={canAccessCrew}
              linkGenerator={crewMemberLinkGenerator} />
            {locationsAvailability?.length > 0 && <> <tr>
              <th className="day-out-days-table-actor-column has-text-weight-bold">Locations</th><td colSpan={filteredDates.length + 1}></td>
            </tr>
              <AvailabilityActorsTable
                dates={dates}
                people={locationsAvailability}
                filmId={filmId}
                sectionLink="locations"
                canAccess={canAccessBackgroundActors} />
            </>
            }
          </tbody>
        </table>
      </div>

    </section>
  );
};



export default Availability;
