import React from "react";
import { NavLink } from "react-router-dom";

const MembersList = ({ members, filmId, departmentId, roleId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Members</p>
      <ul className="menu-list">
        {(members || []).map((member, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/departments/${departmentId}/roles/${roleId}/members/${member.id}/edit`}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/departments/${departmentId}/roles/${roleId}/members/${member.id}`
                  );
                }}
              >
                {member.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default MembersList;
