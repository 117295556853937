interface AddressComponent {
    long_name: string;
    short_name: string;
    types: Array<string>;
  }
  
  export interface Address {
    addressLines?: string[];    
    postCode?: string;
    country?: string;
    countryCode?: string;
    latitude?: number;
    longitude?: number;
    placeId?: string;
  }
  
  export class GoogleAddressParser {
    address: Address = {};
  
    constructor(private name: string, private address_components: Array<AddressComponent>, latitude: number, longitude: number, private placeId: string) {
      this.parseAddress();
      this.address.latitude = latitude;
      this.address.longitude = longitude;
    }
  
    private parseAddress() {
      let addressLines : string[] = [];

      if (!Array.isArray(this.address_components)) {
        throw Error('Address Components is not an array');
      }
  
      if (!this.address_components.length) {
        throw Error('Address Components is empty');
      }

      let hadStreetNumber = false;
      for (let i = 0; i < this.address_components.length; i++) {
        const component: AddressComponent = this.address_components[i];
  
        if (this.isStreetNumber(component)) {
          addressLines.push(component.long_name);
          hadStreetNumber = true;
        } 
        else if (this.isStreetName(component)) {
          if (hadStreetNumber) {
            addressLines[0] = addressLines[0] + " " + component.long_name;
          } else {
            addressLines.push(component.long_name);
          }
        }  
        else if (this.isCountry(component)) {          
          this.address.country = component.long_name;
          this.address.countryCode = component.short_name;
        }
        else if (this.isPostalCode(component)) {
          this.address.postCode = component.long_name;
        }
        else {
          if (addressLines[addressLines.length - 1] != component.long_name) {
            addressLines.push(component.long_name);
          }
        }
      }

      if (this.name && this.name != addressLines[0]) {
        addressLines.unshift(this.name);
      }

      this.address.addressLines = addressLines;
      this.address.placeId = this.placeId;
    }
  
    private isStreetNumber(component: AddressComponent): boolean {
      return component.types.includes('street_number');
    }
  
    private isStreetName(component: AddressComponent): boolean {
      return component.types.includes('route');
    }
  
    private isAddress2(component): boolean {
      return component.types.includes('sublocality_level_2');
    }

    private isAddress3(component): boolean {
      return component.types.includes('sublocality_level_1');
    }

    private isCity(component): boolean {
      return component.types.includes('locality') || component.types.includes('postal_town');
    }
      
    private isState(component): boolean {
      return component.types.includes('administrative_area_level_2');
    }
  
    private isCountry(component): boolean {
      return component.types.includes('country');
    }
  
    private isPostalCode(component): boolean {
      return component.types.includes('postal_code');
    }
  
    result(): Address {
      return this.address;
    }
  }