import React from "react";
import { NavLink } from "react-router-dom";

const TakesList = ({ takes, filmId, sceneId, shotId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Takes</p>
      <ul className="menu-list">
        {!takes?.length && <div className="notification">No takes yet</div>}
        {!!takes?.length &&
          takes.map((take, idx) => {
            return (
              <li key={idx}>
                <NavLink
                  to={`/films/${filmId}/scenes/${sceneId}/shots/${shotId}/takes/${take.id}/view`}
                  activeClassName="is-active"
                >
                  {take.number}-{take.takeSizeDescription}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </aside>
  );
};

export default TakesList;
