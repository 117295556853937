import React from "react";
import CurrencyDisplay from "../../shared/currency-display";
import VisualEffect from "./visual-effect";

function VisualEffects(props) {
  const {
    budget,
    onChangeVisualEffectBuget,
    onChangeVisualEffectDays,
    showHideVisualEffects,
    visualEffectsVisible,
    readOnly,
    filmId,
    saving
  } = props;

  return (
    <div>
      <div className="columns subtitle is-4 mt-1">
        <div className="column">
          <h4
            className="subtitle is-4 pointer"
            onClick={() => showHideVisualEffects(!visualEffectsVisible)}>
            <button className="button is-text is-small">
              {!visualEffectsVisible && <i className="material-icons">add</i>}
              {visualEffectsVisible && <i className="material-icons">remove</i>}
            </button>
            Visual Effects
          </h4>
        </div>
        <div className="column budget-header-number">
          {!visualEffectsVisible && (
            <CurrencyDisplay
              value={budget.visualEffectsTotal}
            />
          )}
        </div>
      </div>
      {visualEffectsVisible && (
        <>
          <table className="table budget-table">
            <thead>
              <tr>
                <th>Name</th>
                <th className="budget-daily-rate-column">Budget</th>
                <th className="has-text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {(budget?.visualEffects || []).map((visualEffect, i) => {
                return (
                  <VisualEffect
                    key={i}
                    visualEffect={visualEffect}
                    onChangeVisualEffectBuget={onChangeVisualEffectBuget}
                    onChangeVisualEffectDays={onChangeVisualEffectDays}
                    maxDays={budget.days}
                    totalScenes={budget.totalScenes}
                    hasShootingDays={budget.hasShootingDays}
                    readOnly={readOnly}
                    filmId={filmId}
                    saving={saving}
                  />
                );
              })}
              <tr>
                <td colSpan={4}></td>
                <td className="has-text-right">
                  <CurrencyDisplay
                    value={budget.visualEffectsTotal}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default VisualEffects;
