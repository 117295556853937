import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import TimelineHeader from "./timeline-header";
import TimelineItem from "./timeline-item";
import './timeline.scss';

const Timeline = () => {

    const [showAll, setShowAll] = useState(false);

    //const options = { threshold: 1, rootMargin: "-150px 0px -150px 0px", triggerOnce: true };
    //const [ref, inView] = useInView(options);

    const onShowMore = () => {
        setShowAll(!showAll);
    }

    return (
        <div className="marketing-timeline-container">
            <div className="marketing-titles">Planned Features</div>


            <div className="marketing-get-started">
                <div className="marketing-timeline-box">
                    <div className="marketing-timeline-content">
                        <p>This is not a fixed list, but is based on current user feedback. Once you have an account, you will be able to add and vote on feature requests to influence the order these additional functionalities are developed.</p>
                    </div>
                </div>
            </div>

            <div className={`timeline is-centered marketing-timeline ${showAll ? "marketing-timeline-all" : "marketing-timeline-half"}`}>
                <TimelineHeader title="2024" />
                <TimelineItem
                    title="Comments"
                    description="Add comments to any item in Filmanize" />
                <TimelineItem
                    title="Gobal lists"
                    description="Find actors, crew, equipment etc from previous films" />
                <TimelineItem
                    title="Commercial"
                    description="Extra script section more orientated towards commercial projects" />
                <TimelineItem
                    last={true}
                    title="Episodic"
                    description="Add a series of scripts and plan shooing days across them all" />
                <TimelineHeader title="Future" />
                <TimelineItem
                    title="Daily Budget"
                    description="See the budget for each shooting day" />
                <TimelineItem
                    title="Top Sheet"
                    description="View the top sheet for your budget" />
                <TimelineItem
                    first={false}
                    title="Smart Days V2.0"
                    description="Uses the time to travel between locations to aim to reduce the number of shooting days" />
                <TimelineItem
                    title="Vehicles"
                    description="Add vehicles to the breakdown" />
                <TimelineItem
                    title="Special Effects"
                    description="Add special effects to the breakdown" />
            </div>
            <div className="marketing-timeline-button-conatiner is-centerd">

                <button className="button timeline-button hollow-button" onClick={onShowMore}>Show {showAll ? "less" : "more"}</button>
            </div>
        </div>
    )
}

export default Timeline;