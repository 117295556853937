import { NavLink } from "react-router-dom";

function Costume(props) {
  const {
    costume,
    filmId
  } = props;

  return (
    <>
      <tr>
        <td>
          <NavLink to={`/films/${filmId}/costumes/${costume.id}/edit`}>
            {costume.name}
          </NavLink>
        </td>
        <td>
          {costume.acquisitionMethodDescription}
        </td>
        <td>
          {costume.acquired ? "Yes" : "No"}
        </td>
      </tr>
    </>
  );
}

export default Costume;
