export default (props) => 
<svg xmlns="http://www.w3.org/2000/svg" width="55.03" height="55.03" viewBox="0 0 55.03 55.03">
  <g id="Group_4059" data-name="Group 4059" transform="translate(-1263 -8332.522)">
    <path id="Path_32956" data-name="Path 32956" d="M22.975,54.733A27.558,27.558,0,0,1,27.515,0a27.558,27.558,0,0,1,4.54,54.733l-1.513-1.238H24.488Z" transform="translate(1263 8332.522)" fill="#222e44"/>
    <path id="Path_32957" data-name="Path 32957" d="M35.591,34.268l1.338-8.327H29.048v-5.8c0-2.379.892-4.163,4.461-4.163h3.866V8.395A48.63,48.63,0,0,0,30.833,7.8c-6.84,0-11.6,4.163-11.6,11.6v6.543H11.8v8.327h7.435V55.233a27.211,27.211,0,0,0,9.814,0V34.268Z" transform="translate(1254 8322) scale(1.5 1.5)" />
  </g>
</svg>



