import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../shared/loader";
import { fetchCollaborators } from "./ducks/actions";
import AddIcon from "../icons/add";
import List from "./list";
import Onboarding from '../../shared/onboarding/onboarding';
import { OnboardingStep1ClassName } from './ducks/reducer';
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListUsersContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchCollaborators, loading, users, errors, readOnly, onboardingSteps } = props;

  useEffect(() => {
    fetchCollaborators(filmId, true);
  }, [filmId, fetchCollaborators]);

  function renderLoading() {
    return <Loader />;
  }

  function renderUsers(users, filmId) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/films/:filmId/collaborators"
        loading={loading}>
        {loading && <Loader />}
        <List users={users} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("collaborators/add") && (
          <>
            <NavLink
              to={`/films/${filmId}/collaborators/add`}
              activeClassName={
                "is-active " + window.location.pathname.endsWith("add")
                  ? "disabled"
                  : ""
              }
              className={`button is-transparent is-add ${OnboardingStep1ClassName}`}>
              <AddIcon className="white-icon" />
              Add Collaborator
            </NavLink>
            <Onboarding onboardingSteps={onboardingSteps} section="Collaborators" />
          </>
        )}
      </ScrollIntoView>
    );
  }

  return renderUsers(users, filmId);
}

function mapStateToProps(state) {
  return {
    users: state.usersState.users,
    readOnly: state.usersState.readOnly,
    loading: state.usersState.loading,
    errors: state.usersState.errors,
    onboardingSteps: state.usersState.onboardingSteps
  };
}

export default connect(mapStateToProps, {
  fetchCollaborators
})(ListUsersContainer);
