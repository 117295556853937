import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const ADD_PROP_TO_SCENE = "ADD_PROP_TO_SCENE";
export const FETCH_PROPS_FOR_ADDING_TO_SCENE = "FETCH_PROPS_FOR_ADDING_TO_SCENE";
export const CLEAR_SCENE_PROPS_REDIRECT = "CLEAR_SCENE_PROPS_REDIRECT";

export function fetchPropsInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PROPS_IN_SCENE",
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/props`),
    });
  };
}

export function fetchProps(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PROPS_FOR_ADDING_TO_SCENE,
      payload: getApiClient().get(`/film/${filmId}/props`),
    });
  };
}

export function addPropToScene(filmId, sceneId, prop, fromBreakdown) {
  return (dispatch) => {
    dispatch({
      type: ADD_PROP_TO_SCENE,
      payload: getApiClient().post(`/film/${filmId}/scene/${sceneId}/prop/${prop.propId}`),
      meta: { fromBreakdown }
    })
      .then((response) => {
        dispatch(showInfo(`Added prop to scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding prop to scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SCENE_PROPS_REDIRECT
    });
  };
}
