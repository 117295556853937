import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import { renderTextField } from "./../../../shared/form-helpers";
import required from "./../../../shared/validation";

function EquipmentCategoryForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode
  } = props;
  const thisEquipmentCategory = props.initialValues || {};
  var readOnly = thisEquipmentCategory.readOnly;
  return (
    <>
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Field
          name="name"
          label="Name"
          component={renderTextField}
          validate={[required]}
          required
          readOnly={readOnly}
        />

        {!readOnly && <div className="buttons">
          <button
            type="submit"
            className="button is-primary"
            disabled={pristine || submitting}>
            Save
          </button>

          {mode === "edit" && !thisEquipmentCategory.isBuiltIn && (
            <button
              type="button"
              className="button is-text"
              disabled={submitting || !thisEquipmentCategory}
              onClick={() => handleDelete(thisEquipmentCategory)}>
              Delete
            </button>
          )}
        </div>}
      </form>
    </>
  );
}

export default reduxForm({
  form: "equipmentCategoryForm",
  enableReinitialize: true
})(EquipmentCategoryForm);
