import { Link } from "react-router-dom";

const HelpPanelLink = ({
  buttonIcon = null,
  buttonText = null,
  buttonLink = null,
  onButtonClick = () => {},
  children = null
}: {
  buttonIcon?: any;
  buttonText?: any;
  buttonLink?: any;
  onButtonClick?: () => void;
  children?: any;
}) => {
  return (
    <div className="help-panel-button">
      
        <a href={buttonLink} target="_blank" onClick={onButtonClick}>
          {buttonIcon ? (
            <div className="help-panel-button__icon">{buttonIcon}</div>
          ) : null}
          {buttonText}
        </a>        
    </div>
  );
};
  export default HelpPanelLink;