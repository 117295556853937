import React from "react";
import { connect } from "react-redux";
import FilmalityCircularProgress from "../../../../shared/filmality-circular-progress";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SetDressing from "./prop";
import Errors from "../../../../shared/errors";
import {
  OnboardingStep1ClassName,
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName
} from "./ducks/reducer";
import Onboarding from '../../../../shared/onboarding/onboarding';

function Overview(props) {
  const {
    overview,
    filmId,
    charactersAssignedPercentage,
    assignActor,
    unassignActor,
    aliasCharacter,
    unaliasCharacter,
    errors,
    warnings,
    characterOrderBy,
    charactersOnboardingSteps
  } = props;

  const readOnly = overview.readOnly;

  function Charts() {
    return (
      <div className="columns">
        <div
          className={`${OnboardingStep1ClassName} circular-progress column`}>
          <FilmalityCircularProgress
            percentage={overview.setDressingsAcquiredPercentage}
            title="Set Dressings Acquired"
          />
        </div>
      </div>
    );
  }

  function PropsList() {
    return (
      <table className={`${OnboardingStep3ClassName} table`}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Quantity</th>
            <th>Quantity Acquired</th>
            <th>Acquisition Method</th>
          </tr>
        </thead>
        <tbody>
          {(overview.setDressings || []).map((setDressing, index) => {
            return (
              <SetDressing
                key={index}
                index={index}
                prop={setDressing}
                filmId={filmId}
                readOnly={readOnly} />
            );
          })}
        </tbody>
      </table>
    );
  }


  return (
    <div>
      <Charts />
      {/* <RenderHelpText /> */}
      {errors && <Errors errors={errors} />}
      {warnings && <Errors errors={warnings} isWarning={true} />}
      <div className="buttons">
        {characterOrderBy == "Name" && (
          <button
            className={`${OnboardingStep2ClassName} button`}
            onClick={props.orderCharactersByScenes}>
            Order By Number Of Scenes
          </button>
        )}

        {characterOrderBy == "Scenes" && (
          <button
            className={`${OnboardingStep2ClassName} button`}
            onClick={props.orderCharactersByName}>
            Order By Name
          </button>
        )}
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="columns">
          <div className="column">
            <PropsList />
          </div>
        </div>
      </DndProvider>

      <Onboarding onboardingSteps={charactersOnboardingSteps} section="Characters" />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    charactersOnboardingSteps:
      state.charactersState.overviewState.charactersOnboardingSteps
  };
}

export default connect(mapStateToProps, {
})(Overview);
