import React from "react";
import PageToEighth from "./eighth";

const Pages = (props) => {
  return (
      <>
        <PageToEighth page={props.start}></PageToEighth>
        {props.start ? "-" : null}  
        <PageToEighth page={props.end}></PageToEighth>
      </>
    );
  };

  export default Pages;