import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications,
} from "../../../../notifications/ducks/actions";

export function fetchShootingDaysScheduleEventList(filmId, shootingdayId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTINGDAY_SCHEDULE_EVENT_LIST",
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingdayId}/Schedule`
      ),
    });
  };
}

export function fetchScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTINGDAY_SCENES",
      payload: getApiClient().get(`/film/${filmId}/Scene`),
    });
  };
}

export function fetchSceneDetails(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SHOOTINGDAY_SCENE_DETAILS",
      payload: getApiClient().get(
        `/film/${filmId}/Scene/${sceneId}/WithLocationAndActors`
      ),
    });
  };
}

// /api/film/{filmId}/Scene/{id}/WithLocationAndActors

// export function fetchShootingDayScheduleEvent(filmId, shootingdayId, eventId) {
//   return (dispatch) => {
//     dispatch({
//       type: "FETCH_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT_LIST",
//       payload: getApiClient().get(`/film/${filmId}/ShootingDay/${shootingdayId}/ScheduleEvent`),
//     });
//   };
// }

export function updateShootingDayScheduleEvent(
  filmId,
  shootingdayId,
  scheduleEvent
) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT",
      payload: getApiClient().put(
        `/film/${filmId}/ShootingDay/${shootingdayId}/Schedule/Event/${scheduleEvent.id}`,
        scheduleEvent
      ),
      meta: { scheduleEvent },
    })
      .then((response) => {
        dispatch(
          showInfo(`Updated shooting day event @ ${scheduleEvent.time}`)
        );
      })
      .catch((response) => {
        dispatch(
          showError(`Error updating shooting day event @ ${scheduleEvent.time}`)
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addShootingDayScheduleEvent(
  filmId,
  shootingdayId,
  scheduleEvent
) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT",
      payload: getApiClient().post(
        `/film/${filmId}/ShootingDay/${shootingdayId}/Schedule/Event`,
        scheduleEvent
      ),
      meta: { scheduleEvent },
    })
      .then((response) => {
        dispatch(showInfo(`Added shootingday event at ${scheduleEvent.time}`));

        if (scheduleEvent.type === 1) {
          dispatch({
            type: "SET_SHOOTING_DAY_SCHEDULE_VALID",
          });
        }
      })
      .catch((response) => {
        dispatch(showError(`Error adding shootingday event`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteShootingDayScheduleEvent(
  filmId,
  shootingdayId,
  scheduleEvent
) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_SHOOTINGDAY_SCHEDULE_EVENT",
      payload: getApiClient().delete(
        `/film/${filmId}/ShootingDay/${shootingdayId}/Schedule/Event/${scheduleEvent.id}`
      ),
      meta: { shootingdayId, scheduleEvent },
    })
      .then((response) => {
        dispatch(
          showInfo(`Deleted shooting day event @ ${scheduleEvent.time}`)
        );
      })
      .catch((response) => {
        dispatch(
          showError(
            `Error deleting  shooting day event @ ${scheduleEvent.time}`
          )
        );
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}
