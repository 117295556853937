import { reduxForm } from "redux-form";
import FormErrors from "../../shared/form-errors";
import Errors from "../../shared/errors";
import { Link } from "react-router-dom";
import getConfig from "../../app/config";
import auth from "../../shared/auth";

function UsersForm(props) {
  const {
    handleSubmit,
    exportFilm,
    downloadScript,
    pristine,
    reset,
    submitting,
    errors,
    submitFailed,
    invalid,
    films
  } = props;

  const onExport = (film) => {
    exportFilm(film);
  };

  const onDownloadScript = (film) => {
    downloadScript(film);
  };

  const config = getConfig();

  return (
    <>
      <h2 className="menu-label">User Films</h2>
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <table className="table">
          <tr>
            <th>Name</th>
            <th>Modified</th>
            <th>Export</th>
            <th>Script</th>
          </tr>


          {(films || []).map((film) =>
            <tr>
              <td>{film.name}</td>
              <td>{film.lastModified}</td>
              <td><button className="button" onClick={() => onExport(film)}>Export</button></td>
              <td><a href={`${config.apiUrl}admin/Download?url=${film.scriptUrl}&t=${auth.getToken()}`} target="_blank">Download</a>
                {/* <button className="button" onClick={() => onDownloadScript(film)}>Download</button> */}
              </td>
            </tr>)}

        </table>
        <div className="buttons mt-1 sticky-buttons">
          <button
            type="submit"
            className={
              submitting ? "button is-primary is-loading" : "button is-primary"
            }
            disabled={pristine || submitting}>
            Update
          </button>

          <Link className="ml-1" to="/admin/users">Users</Link>
        </div>
        <FormErrors submitFailed={submitFailed} invalid={invalid} />
      </form>
    </>
  );
}

export default reduxForm({
  form: "registerForm",
  enableReinitialize: true
})(UsersForm);
